import { useEffect, useState } from "react";
import { sumbitAtuh } from "./api";
import { useAppSelector } from "@/state/hooks";
import { getUserInfo, getProcessInfo, getKycInfo } from "./api";
import { useDispatch } from "react-redux";
import { updateKycInfo } from "@/state/kyc";
import {
  updateBankInfo,
  updateUserInfo,
  updateUserKycInfo,
} from "@/state/user/reducer";
import { S3_AWS_KMS_KEY_ID } from "@/config";
import axios from "axios";
import { getS3PutUrl } from "./api";
import { getPageQuery } from "@/utils";
import {
  getBankAccountCreate,
  getBankAccountUpdate,
} from "@/pages/legalTender/api";
import { useNavigate } from "react-router-dom";

const useCommonHooks = () => {
  const dispatch = useDispatch();

  const fetchGetProcessInfo = async () => {
    const res = await getProcessInfo({});
    if (res.code === 0) {
      dispatch(updateKycInfo(res.data.process));
    }
  };

  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };

  useEffect(() => {
    fetchGetProcessInfo();
    getUserInfoFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useLiceseHooks = () => {
  const [filePath, setFilePath] = useState(""); // 文件地址
  const [fileName, setFileName] = useState(""); // 文件name
  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading
  const [validFile, setValidFile] = useState(false); // 文件类型校验
  const [fileType, setFileType] = useState(""); // 文件类型
  const [viewStatus, setViewStatus] = useState("");
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [checked, setChcked] = useState(false);
  const [errorType, setErrorType] = useState("");

  useEffect(() => {
    if (kycInfo?.length > 0) {
      const currProcess = kycInfo[7];
      if (currProcess.status === "PENDING") {
        setViewStatus("PENDING");
      } else {
        setViewStatus("INIT");
      }
    }
  }, [kycInfo]);

  const handleFile = (e) => {
    const file = e.target.files[0];

    if (file) {
      const { size, name } = file;
      const fileNameSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (
        size / 1000000 < 5 &&
        (fileNameSuffix === "pdf" || fileNameSuffix === "jpg")
      ) {
        setFileType(fileNameSuffix);
        setValidFile(true);
        uploadFn(file, fileNameSuffix);
      } else {
        setValidFile(false);
        setFilePath("error");
      }
      setFileName(name);
    }
  };

  const uploadFn = async (file, fileNameSuffix) => {
    setUploadLoading(true);
    const res = await getS3PutUrl({
      prefix: "authentication_info",
      file_type: fileNameSuffix,
    });
    if (res.code === 0) {
      const putUrl = res?.data?.put_url;
      const filePath = res?.data?.file_path;
      axios({
        url: putUrl,
        method: "put",
        headers: {
          "X-Amz-Server-Side-Encryption": "aws:kms",
          "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
        },
        data: file,
      }).then((uploadRes) => {
        if (uploadRes.status === 200) {
          setFilePath(filePath);
          setUploadLoading(false);
        }
      });
    }
  };

  const onSubmit = async () => {
    setErrorType("");
    if (filePath === "error") {
      return;
    }
    if (!checked && !filePath) {
      setErrorType("empty_error");
      return;
    }

    if (checked && filePath) {
      setErrorType("only_error");
      return;
    }
    if (isSubmiting) return;

    setIsSubmiting(true);
    const params = {
      documents: {
        document_type: fileType || "",
        document_path: filePath || "",
      },
      auth_mode: checked ? "face2face" : "online",
    };
    try {
      const res = await sumbitAtuh(params);
      if (res.code === 0) {
        setViewStatus(res.data.status);
        setIsSubmiting(false);
      }
    } catch {
      setIsSubmiting(false);
    }
  };
  return {
    onSubmit,
    handleFile,
    viewStatus,
    validFile,
    uploadLoading,
    fileName,
    filePath,
    checked,
    setChcked,
    errorType,
  };
};

const useBankVerifyHooks = () => {
  const urlParams = getPageQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filePath, setFilePath] = useState(""); // 文件地址
  const [fileName, setFileName] = useState(""); // 文件name
  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading
  const [validFile, setValidFile] = useState(false); // 文件类型校验
  const [fileType, setFileType] = useState(""); // 文件类型
  const [viewStatus, setViewStatus] = useState("");
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);
  const addBankInfo = useAppSelector((state) => state.user.addBankInfo);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [errorType, setErrorType] = useState("");

  useEffect(() => {
    if (kycInfo?.length > 0) {
      const currProcess = kycInfo[7];
      if (currProcess.status === "PENDING") {
        setViewStatus("PENDING");
      } else {
        setViewStatus("INIT");
      }
    }
  }, [kycInfo]);

  useEffect(() => {
    getKycInfoFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getKycInfoFn = async () => {
    const res = await getKycInfo({});
    if (res.code === 0) {
      dispatch(updateUserKycInfo({ info: res.data }));
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];

    if (file) {
      const { size, name } = file;
      const fileNameSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (
        size / 1000000 < 5 &&
        (fileNameSuffix === "pdf" || fileNameSuffix === "jpg")
      ) {
        setFileType(fileNameSuffix);
        setValidFile(true);
        uploadFn(file, fileNameSuffix);
      } else {
        setValidFile(false);
        setFilePath("error");
      }
      setFileName(name);
    }
  };

  const uploadFn = async (file, fileNameSuffix) => {
    setUploadLoading(true);
    const res = await getS3PutUrl({
      prefix: urlParams?.path === "add" ? "fiat_info" : "authentication_info",
      file_type: fileNameSuffix,
    });
    if (res.code === 0) {
      const putUrl = res?.data?.put_url;
      const filePath = res?.data?.file_path;
      axios({
        url: putUrl,
        method: "put",
        headers: {
          "X-Amz-Server-Side-Encryption": "aws:kms",
          "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
        },
        data: file,
      }).then((uploadRes) => {
        if (uploadRes.status === 200) {
          setFilePath(filePath);
          setUploadLoading(false);
        }
      });
    }
  };

  const onSubmit = async () => {
    setErrorType("");
    if (filePath === "error") {
      return;
    }
    if (!filePath) {
      setErrorType("empty_error");
      return;
    }

    if (isSubmiting) return;

    setIsSubmiting(true);
    const params = {
      documents: {
        document_type: fileType || "",
        document_path: filePath || "",
      },
      auth_mode: "bank_transfer",
    };
    try {
      // 添加银行账号
      if (urlParams?.path === "add") {
        return addBankAccount();
      }
      const res = await sumbitAtuh(params);
      if (res.code === 0) {
        setViewStatus(res.data.status);
        setIsSubmiting(false);
      }
    } catch {
      setIsSubmiting(false);
    }
  };

  const addBankAccount = async () => {
    const params = {
      ...addBankInfo,
    };
    if (filePath) {
      params.bank_transfer_receipt = filePath;
    }
    let res;
    if (params?.uuid) {
      res = await getBankAccountUpdate(params);
    } else {
      res = await getBankAccountCreate(params);
    }
    setIsSubmiting(false);
    if (res?.code === 0 && res?.msg === "OK") {
      dispatch(updateBankInfo({ bankInfo: {} }));
      navigate("/trade/legal/tender");
    }
  };
  return {
    onSubmit,
    handleFile,
    viewStatus,
    validFile,
    uploadLoading,
    fileName,
    filePath,
    errorType,
  };
};

export { useLiceseHooks, useCommonHooks, useBankVerifyHooks };
