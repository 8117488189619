import { Box, Text } from "@chakra-ui/react";

const EnDoc = () => {
  return (
    <Box>
      <Text fontSize="28px" fontWeight={"bold"} mt="30px">
        Privacy Policy
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        Last updated: 5 January 2024
      </Text>
      <Text mt="30px">
        The following terms and conditions constitute the Privacy Policy of
        www.bixin.com (together with its App for iOS, Android or other systems,
        hereinafter referred to as the "website" or “Bixin”) which is operated
        by NewBX Limited (hereinafter referred to as “the Company”). Unless
        otherwise specified, references to the Company herein shall also include
        those to NewBX Treasures Limited, a wholly-owned subsidiary of the
        Company. Please read this Privacy Policy carefully and confirm your
        consent to it before you become a user of Bixin (hereinafter referred to
        as “You” or “User”). The Company takes great care of Usersʼ private
        information, nevertheless, as part of normal operations of service, the
        Company may collect, utilize and (in certain circumstances) disclose
        your personal information to third parties. This Privacy Policy, as a
        part of the User Agreement of the Company, will take effect immediately
        after your registration as a User of Bixin and bind both you and the
        Company
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        A. Restrictions on Userʼs identity
      </Text>
      <Text mt={"10px"}>
        Any individual or entity (“Person”) with full capacity is qualified to
        register on Bixin and use the services provided by the Company. Any User
        who registers, logs in and uses the website on behalf of an entity
        represents and warrants that he/she is acting on behalf of such entity
        with full authorization.
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        B. Information
      </Text>
      <Text mt={"10px"}>
        The main purpose of collecting your personal information by the Company
        is to provide a smooth, effective and tailored service experience to
        User. The Company only collects your information that it deems necessary
        for achieving foresaid purpose. You fully understand and agree that, the
        Company shall be entitled to collect your personal information through
        the approaches including but not limited to: collecting additional
        information from public or private sources besides the information
        provided by you voluntarily, authenticating the information via
        telephone or video, so as to know you better, provide tailored service,
        solve disputes and ensure the safety of your investment on the website.
      </Text>
      <Text mt={"10px"}>
        The Company may collect your personal information including without
        limitation to:
      </Text>
      <Text mt={"10px"}>
        i . the personal information you provide in the registration process,
        including without limitation to the information of Passport, ID Card,
        Driver License, and photo, username, password, etc.;
      </Text>
      <Text mt={"10px"}>
        ii. personal information you provide or disclose to the third party
        through the Company;
      </Text>
      <Text mt={"10px"}>
        iii. the time, length, user records, system logs and behavior data of
        your login and utilization on website;
      </Text>
      <Text mt={"10px"}>
        iv. brand, model, IP address and software versions of the computer
        and/or mobile devices you use to login and use the website;
      </Text>{" "}
      <Text mt={"10px"}>
        v. other personal information or privacy automatically collected through
        cookies or other methods by the website. You may change the browser
        settings to refuse cookies, which may cause incomplete experience of the
        Company’s services;
      </Text>
      <Text mt={"10px"}>
        vi. when you login or use the Company’s services through mobile devices
        with location function, the Company may collect your geological location
        via GPS or WiFi. You can shut down this function in settings of mobile
        devices, however, your user experience may be affected thereafter;
      </Text>
      <Text mt={"10px"}>
        vii. When you login the website through a link on the third-party
        platform, the Company may collect your personal information stored in
        such third-party platform;
      </Text>
      <Text mt={"10px"}>
        viii. other information provided or disclosed to the Company out of
        utilizing the functions of the webiste by you.
      </Text>
      <Text mt={"10px"}>
        The Company will track the above personal information automatically
        according to your behaviour on the website. The Company will use the
        above information to process internal research on the Usersʼ demographic
        statistics, interests, and behaviour, to know you better and provide
        better services to you and other Users.
      </Text>
      <Text mt={"10px"}>
        If you deliver personal communication information (such as text
        messages, emails or letters) to the Company, or if other Users or third
        parties send to the Company your communication information relating your
        activity or login on the website, the Company shall be entitled to
        collect such data in your exclusive profile.
      </Text>
      <Text mt={"10px"}>
        The User can access to and amend its personal data collected by the
        Company in the section “Personal Information” on the website.
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        C. Utilization of Your Information
      </Text>
      <Text mt="10px">
        The Company takes great care of Usersʼ private information. The Company
        will adopt security technologies and programs to store and protect
        Userʼs private information, so as to prevent unauthorized visit,
        utilization, reproduction or disclosure. The Company will not
        maliciously disclose your personal information for any profitable
        purpose. Nevertheless, you approve and authorize the Company to use or
        disclose your personal information within the scope below:
      </Text>
      <Text mt="10px">
        i. The Company shall be entitled to use your personal information to
        settle or mediate disputes, so as to ensure the safety of your
        investment and implementation the User Agreement of the Company. The
        Company may investigate several Users to identify the problems or settle
        disputes, especially, and it may investigate your information to
        identify a User using different usernames or alias. To protect the
        Company from fraudulent, illegal or other criminal activities, the
        Company is entitled to evaluate your personal information through manual
        or automatic procedures.
      </Text>
      <Text mt="10px">
        ii. Subject to the requirements of Personal Data (Privacy) Ordinance
        (Cap. 486 of Hong Kong), the Company shall be entitled to use your
        personal information to improve its marketing and promotion, analyze its
        usage, refine its contents and promotion forms, and improve its
        contents, designs and services to satisfy the Users better.
      </Text>
      <Text mt="10px">
        iii. The Company shall be entitled to use your information to contact
        you and (in some cases) delivering information you may be interested in,
        such as service offerings, administrative notices and newsletters of
        your usage of the website. Your acceptance of the User Agreement and
        this Privacy Policy shall be deemed as your express consent to receive
        such information.
      </Text>
      <Text mt="10px">
        iv. The Company shall be entitled to provide your personal information
        to a third-party platform or service provider to facilitate the service
        provision by such third-party when you click on a link of such
        third-party displayed on the website and according to the following
        scope and purpose, such third-party including:
      </Text>
      <Text mt="10px">
        a. Jumio （https://www.jumio.com/）Face Recognition Service: For KYC
        (Know Your User regulation) and AML (Anti-Money Laundering regulation)
        purposes, real name authentication is required for customers of this
        website, so the information verification service provided by this
        service provider is used, i.e. through network verification, simple
        portrait comparison, triple portrait comparison of person, card and
        public security, ID card OCR, bank card OCR, bank card authentication,
        live detection and face quality analysis, usersʼ identity information is
        used to confirm the authenticity and validity of the User's identity and
        information for the purpose of safeguarding the security of our business
        and Users' transactions. The service involves the disclosure of Users'
        personal information including: Users' personal ID card information,
        passport information, document information, driver's licence
        information, bank card information, mobile phone number information and
        portrait personal information.
      </Text>
      <Text mt="10px">
        b. ComplyAdvantage (https://complyadvantage.com/): Name screening
        service: For the purpose of preventing and controlling the
        money-laundering and terrorist financing risks, the Company has
        outsourced the name screening tool to ComplyAdvantage. The Company’s
        Platform is connected to ComplyAdvantage through API that enables
        automatic name screening. The service involves the disclosure of the
        User’s personal ID card information, passport information, document
        information.
      </Text>
      <Text mt="10px">
        c. YunPian （https://www.yunpian.com/）: SMS notification service: For
        the purpose of notifying Users, it is necessary to send SMS
        notifications to users' mobile phone numbers. Therefore, we use the SMS
        notification service provided by the service provider, i.e. by sending
        SMS messages to users' mobile phone numbers to help Users successfully
        complete registration on this website, keep abreast of the progress of
        transactions, receive service information, etc. The service involves the
        disclosure of the User's personal information including: the User's
        mobile phone number information.
      </Text>
      <Text mt="10px">
        d. Twilio （https://www.twilio.com/）: SMS notification service: For the
        purpose of notifying Users, it is necessary to send SMS notifications to
        Users' mobile phone numbers. Therefore, we use the SMS notification
        service provided by the service provider, i.e. by sending SMS messages
        to Users' mobile phone numbers to help Users successfully complete
        registration on this website, keep abreast of the progress of
        transactions, receive service information, etc. The service involves the
        disclosure of the User's personal information including: the User's
        mobile phone number information.
      </Text>
      <Text mt="10px">
        v. You may authorize the Company to assist you modifying the personal
        information you fill on the website. If you breach the User Agreement,
        the Privacy Policy, or any other rules of the Company or applicable
        laws, the Company is entitled to delete your personal information from
        the Company’s database, close your user account or restrict your usage
        of the website after notice via email, telephone or text message.
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        D. Disclosure of Your Information
      </Text>
      <Text mt="10px">
        technical restrictions and force majeure, the Company may not be able to
        ensure your personal communication or other information not being
        disclosed through approaches not mentioned in this Privacy Policy. The
        Company shall not be liable for such unexpected disclosure.
      </Text>
      <Text mt="10px">
        The Company shall be entitled to provide your personal information to
        judicial/regulatory authorities and other governmental departments,
        social organizations or groups, or other third-party service providers
        or partners as per the requirements of applicable laws and regulation,
        the websiteʼs risk control policy, and relevant agreements. If you fail
        to fulfill your obligations under the User Agreement, Privacy Policy, or
        other rules of the Company, or the laws, the Company shall be entitled
        to disclose your personal information legally and make decisions at its
        sole discretion.
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        E. Usage of the Other Usersʼ Information
      </Text>
      <Text mt="10px">
        You shall not require the Company to provide other Userʼs personal
        information unless you have applied to the court or competent authority,
        and such court or competent authority requests the Company to provide
        relevant judicial assistance in accordance with the laws.
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        F. Email
      </Text>
      <Text mt="10px">
        When you use the Company’s service, the Company may use your personal
        information to send e-mails, news or push notifications to your device.
        If you do not intend to receive such information, you can cancel the
        subscription on the device according to the Company’s instructions.
      </Text>
      <Text mt="10px">
        You shall not use the Company’s service of or other email forwarding
        service to send spam or other contents which may impact the Company’s
        system operation or breach the Company’s User Agreement or Privacy
        Policy.
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        G. Security of the Password
      </Text>
      <Text mt="10px">
        You shall take responsibility for all actions in relation to your use of
        username and password. Thus, the Company recommends that you shall not
        disclose your username or password on the website to any third party.
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        H. Consent and Authorisation
      </Text>
      <Text mt="10px">
        By accepting the User Agreement and the Privacy Policy or using the
        software and services, you are deemed to have given express consent or
        authorization to the Company to process the User's personal information
        and private data in accordance with the applicable laws and regulations
        in the relevant countries or jurisdictions on the protection of personal
        information and privacy (regardless of how it is phrased), including but
        not limited to: collection, storage, use, processing, transmission,
        provision, disclosure, deletion, etc.
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        I. Modification of Rules
      </Text>
      <Text mt="10px">
        The Company may modify the Privacy Policy from time to time according to
        your suggestions and the needs of the Company, to reflect the Company’s
        information collection and disclosure rules accurately. Any amendment to
        this Privacy Policy shall come into force automatically upon publication
        of the same or in the date specified in the amendment. Your continual
        usage of the Company’s service shall be deemed as your express consent
        to the amended Privacy Policy.
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        J. Governing Law; Dispute Resolution
      </Text>
      <Text mt="10px">
        Interpretation of this Privacy Policy and any dispute arising out of
        this Privacy Policy in connection with the website shall be governed by
        the laws of the Hong Kong Special Administrative Region. Any dispute,
        conflict or claim arising out of or in connection with this Privacy
        Policy shall be settled by arbitration to be administered by the Hong
        Kong International Arbitration Centre (“HKIAC”) in accordance with the
        rules of the HKIAC in force then.
      </Text>
    </Box>
  );
};

export default EnDoc;
