import request from "@/hooks/useRequest";
import {
    BANK_ACCOUNT_CREATE,
    BANK_ACCOUNT_DELETE,
    BANK_ACCOUNT_LIST,
    BANK_ACCOUNT_UPDATE,
    BANK_ACCOUNT_GET,
} from "@/helpers/constants/requestApi";

export const getBankAccountCreate = (body) => {
    return request(BANK_ACCOUNT_CREATE, { body });
};
export const getBankAccountDelete = (body) => {
    return request(BANK_ACCOUNT_DELETE, { body });
};

export const getBankAccountList = () => {
    return request(BANK_ACCOUNT_LIST, { params: {} });
};

export const getBankAccountUpdate = (body) => {
    return request(BANK_ACCOUNT_UPDATE, { body });
};

export const getBankAccountGet = (params) => {
    return request(BANK_ACCOUNT_GET, { params });
};
