// 打开区块链浏览器相关
const BLOCKCHAIN_EXPLORER_URLS =  {
    "BTC": "https://btc.com/", //https://btc.com/{txid} // https://www.oklink.com/explorer/btc/trade-info/
    "ETH": "https://cn.etherscan.com/tx/", //https://etherscan.io/tx/{txid} // https://etherscan.io/tx/
    "DOGE": "https://dogechain.info/tx/", //https://dogechain.info/tx/{txid}
    "ENU": "http://enumivo.qsx.io/transactions/", //http://enumivo.qsx.io/transactions/{txid}
    "DASH": "https://explorer.dash.org/tx/", //https://explorer.dash.org/tx/{txid}
    "LTC": "https://ltc.tokenview.com/tx/", //http://explorer.litecoin.net/tx/{txid}
    "USDT": "https://omniexplorer.info/tx/", //https://omniexplorer.info/tx/{txid}
    "XX2ETH": "https://cn.etherscan.com/tx/", //https://etherscan.io/tx/{txid}
    "TRX": "https://trx.tokenview.com/cn/tx/", //https://tronscan.org/#/transaction/{txid}
    "EOS": "https://eosflare.io/tx/", //https://eosflare.io/tx/{txid}
    "BTM": "https://blockmeta.com/tx/", //https://blockmeta.com/tx/{txid}
    "GRIN": "",
    "MBYTE": "https://explorer.byteball.org/#/", //https://explorer.byteball.org/#/{txid} ,Byteball 中的TX 应该称为 unit
    "ERC20": ["AE", "PIN", "ELF", "READ", "AVH", "VNT", "FGC", "KAN", "BQT", "PAI", "IDT", "MPC", "OLE", "PAX", "TUSD", "PNS", "RDN", "VEN", "ZEC", "HPB", "DAI", "USDS"],
    "EOS20": ["EOSDAC", "EOS", "ELF"],
    "KDA": "https://explorer.chainweb.com/mainnet/tx/"
}

// 账单状态 - 对应翻译key
const statusKeysMap = {
    NONE: "NONE",
    ADMIN_REQUIRED: "Approving",
    ADMIN: "Approving",
    ADMIN_DENIED: "Approval rejected (refunded)",
    SUCCESS: "Finished",
    FINISH: "Finished",
    FINISHED: "Finished",
    SENT: "Finished",
    PENDING: "Pending",
    SENDING: "Pending",
    WALLET_ERROR: "Pending",
    UNSIGNED: "Pending",
    SIGNED: "Pending",
    RELEASE_TO_HOLD: "Pending",
    RELEASE_TO_CASH: "Pending",
    GOING: "Processing",
    SOLVED: "Solved",
    CANCELED: "Cancel",
    CANCEL: "Cancel",
    DISPUTED: "Disputed",
    ERROR: "FAIL",
    FAIL: "FAIL",
    REFUND: "REFUND",
    PAIED: "PAIED",
    PAYED: "PAYED",
    BATCHWAITING: "To be sent by the platform",
    CC_EXCHANGE_ERROR: "Exchange failed, Cryptos are returned to wallet"
}

export { BLOCKCHAIN_EXPLORER_URLS, statusKeysMap };