import { Box, Flex, Text, Input, Button, Image } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useI18n } from "@/hooks/useI18n";
import "./personal.scss";
import ModalComponent from "@/components/ui/modal";
import { useMedia } from "@/hooks/useMedia";
import { useAppSelector } from "@/state/hooks";
import BXLoading from "@/components/ui/pay-loading";

const SecondPage = ({
  memoPEP,
  memoWealthSource,
  memoEstimatedNetWorth,
  memoFundSource,
  memoAnnualIncome,
  memoAnticipatedMonthlyTxFrequency,
  memoPEPContact,
  setInputParams,
  inputParams,
  setCurrentPage,
  handleMultipleCheck,
  inputOnChange,
  onSumbit,
  memoAnticipatedMonthlyTxAmount,
  setStep,
  crsFileInfo,
  handleUpLoadCRSFile,
  uploadLoading,
  documents,
}) => {
  const { t } = useI18n();
  const [showTipModal, setShowModalTip] = useState(false);
  const lang = useAppSelector((state) => state.user.language);
  const [crsDownLink, setCRSDownLink] = useState("");
  const { isMobile } = useMedia();

  useEffect(() => {
    const maps = {
      zh: "https://file.bixin.com/kyc/information/FATCA_and_CRS_Individual_Self-Certification_Form_sc.pdf",
      tw: "https://file.bixin.com/kyc/information/FATCA_and_CRS_Individual_Self-Certification_Form_tc.pdf",
      en: "https://file.bixin.com/kyc/information/FATCA_and_CRS_Individual_Self-Certification_Form.pdf",
    };
    setCRSDownLink(maps[lang]);
  }, [lang]);

  return (
    <Flex
      width={["95%", "400px"]}
      flexDirection={"column"}
      margin={"0 auto"}
      mt={"50px"}
    >
      {/* 财富来源 多选 */}
      <Flex mt={"40px"} flexDirection={"column"}>
        <Text fontWeight={500}>{t("lfjjwhqpvFBikfhkifrN")}</Text>
        <Flex flexWrap={"wrap"} mt={"10px"}>
          {memoWealthSource.map((d, i) => {
            return (
              <Flex mr={"20px"} mb={"15px"} key={i}>
                <Image
                  cursor={"pointer"}
                  onClick={() => handleMultipleCheck(d, "wealth_source")}
                  src={`../../wwwsite/images/kyc/${
                    inputParams?.wealth_source?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "checked"
                      : "no_check"
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {inputParams?.wealth_source?.options?.findIndex((d) => d.value === 6) >
          -1 && (
          <Input
            h="44px"
            fontSize="12px"
            color="t.100"
            borderRadius={"12px"}
            placeholder={t("WjPizXTxbdPnvLWMql")}
            mt="5px"
            maxLength={100}
            value={inputParams?.wealth_source?.description}
            onChange={(e) => inputOnChange(e.target.value, "wealth_source")}
          />
        )}
      </Flex>
      {/* 预估净值 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontWeight={500}> {t("kYGDyxESdKUNNTdG")}</Text>
        <Flex flexWrap={"wrap"} mt={"10px"}>
          {memoEstimatedNetWorth.map((d, i) => {
            return (
              <Flex mr={"20px"} mb={"15px"} key={i}>
                <Image
                  onClick={() => {
                    setInputParams((prev) => {
                      const currObj = prev.net_worth;
                      return Object.assign({}, prev, {
                        net_worth: {
                          ...currObj,
                          options: [d],
                        },
                      });
                    });
                  }}
                  src={`../../wwwsite/images/kyc/radio${
                    inputParams?.net_worth?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "_checked"
                      : ""
                  }.svg`}
                  cursor={"pointer"}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      {/* 资金来源 多选*/}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontWeight={500}>{t("DSFAJhsSefQQEdBBlUyt")}</Text>
        <Flex flexWrap={"wrap"} mt={"10px"}>
          {memoFundSource.map((d, i) => {
            return (
              <Flex mr={"20px"} mb={"15px"} key={i}>
                <Image
                  cursor={"pointer"}
                  onClick={() => handleMultipleCheck(d, "fund_source")}
                  src={`../../wwwsite/images/kyc/${
                    inputParams?.fund_source?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "checked"
                      : "no_check"
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {inputParams?.fund_source?.options?.findIndex((d) => d.value === 5) >
          -1 && (
          <Input
            h="44px"
            fontSize="12px"
            color="t.100"
            borderRadius={"12px"}
            placeholder={`${t("WjPizXTxbdPnvLWMql")}  (${t(
              "idqLvJqjeiEmQbpa"
            )})`}
            mt="5px"
            maxLength={100}
            value={inputParams?.fund_source?.description}
            onChange={(e) => inputOnChange(e.target.value, "fund_source")}
          />
        )}
      </Flex>
      {/* 年收入 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontWeight={500}> {t("fjjnxDoEsAfrEGSbNUL")}</Text>
        <Flex flexWrap={"wrap"} mt={"10px"}>
          {memoAnnualIncome.map((d, i) => {
            return (
              <Flex mr={"20px"} mb={"15px"} key={i}>
                <Image
                  onClick={() => {
                    setInputParams((prev) => {
                      const currObj = prev.annual_income;
                      return Object.assign({}, prev, {
                        annual_income: {
                          ...currObj,
                          options: [d],
                        },
                      });
                    });
                  }}
                  src={`../../wwwsite/images/kyc/radio${
                    inputParams?.annual_income?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "_checked"
                      : ""
                  }.svg`}
                  cursor={"pointer"}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          placeholder={t("uuluQlsJBdDsslsERZX")}
          maxLength={300}
          mt="5px"
          value={inputParams?.annual_income?.description}
          onChange={(e) => {
            setInputParams((prev) => {
              const currObj = prev.annual_income;
              return Object.assign({}, prev, {
                annual_income: {
                  ...currObj,
                  description: e.target.value,
                },
              });
            });
          }}
        />
      </Flex>
      {/* 预估月交易额 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontWeight={500}> {t("ZCRfudjxgEkJLycN")}</Text>
        <Flex flexWrap={"wrap"} mt={"10px"}>
          {memoAnticipatedMonthlyTxAmount.map((d, i) => {
            return (
              <Flex mr={"20px"} mb={"15px"} key={i}>
                <Image
                  onClick={() => {
                    setInputParams((prev) => {
                      const currObj = prev.monthly_turnover;
                      return Object.assign({}, prev, {
                        monthly_turnover: {
                          ...currObj,
                          options: [d],
                        },
                      });
                    });
                  }}
                  src={`../../wwwsite/images/kyc/radio${
                    inputParams?.monthly_turnover?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "_checked"
                      : ""
                  }.svg`}
                  cursor={"pointer"}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      {/* 预估月均交易频率 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontWeight={500}> {t("JJAtBenOLOlFaFaP")}</Text>
        <Flex mt={"10px"} flexDirection={"column"}>
          {memoAnticipatedMonthlyTxFrequency.map((d, i) => {
            const imgSrc = `../../wwwsite/images/kyc/radio${
              inputParams?.monthly_frequency?.options?.findIndex(
                (x) => x.value === d.value
              ) > -1
                ? "_checked"
                : ""
            }.svg`;
            return (
              <Flex flexDirection={"column"} key={i}>
                <Flex mb={"10px"}>
                  <Image
                    onClick={() => {
                      setInputParams((prev) => {
                        const currObj = prev.monthly_frequency;
                        return Object.assign({}, prev, {
                          monthly_frequency: {
                            ...currObj,
                            options: [d],
                          },
                        });
                      });
                    }}
                    cursor={"pointer"}
                    src={imgSrc}
                  />
                  <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                    {d.label}
                  </Text>
                </Flex>
                {inputParams?.monthly_frequency?.options?.findIndex(
                  (d) => d.value === 1
                ) > -1 &&
                  i === 0 && (
                    <Input
                      h="44px"
                      fontSize="12px"
                      color="t.100"
                      borderRadius={"12px"}
                      placeholder={`${t("WjPizXTxbdPnvLWMql")}  (${t(
                        "idqLvJqjeiEmQbpa"
                      )})`}
                      mt="5px"
                      mb={"15px"}
                      maxLength={100}
                      value={inputParams?.monthly_frequency?.description}
                      onChange={(e) => {
                        setInputParams((prev) => {
                          const currObj = prev.monthly_frequency;
                          return Object.assign({}, prev, {
                            monthly_frequency: {
                              ...currObj,
                              description: e.target.value,
                            },
                          });
                        });
                      }}
                    />
                  )}
              </Flex>
            );
          })}
        </Flex>
      </Flex>

      {/* 您或任何关联方     是否是政治公众人物？ */}
      <Flex mt={"40px"} flexDirection={"column"}>
        <Flex color={"#010313"} alignItems={"center"} flexWrap={"wrap"}>
          <Text fontSize="14px" fontWeight={400}>
            {t("hYWPDZZNtYYwiYMfUfjX")}
          </Text>
          <Image
            src={"../../wwwsite/images/kyc/gray-info-circle.svg"}
            cursor={"pointer"}
            margin={"0 3px"}
            onClick={() => setShowModalTip(true)}
          />
          <Text fontSize="14px" fontWeight={400}>
            {t("NIxMpJepTIBNynamgjj")}
          </Text>
        </Flex>
        <Flex mt={"10px"}>
          {memoPEP.map((d, i) => {
            const imgSrc = `../../wwwsite/images/kyc/radio${
              inputParams?.pep?.options?.findIndex((x) => x.value === d.value) >
              -1
                ? "_checked"
                : ""
            }.svg`;
            return (
              <Flex ml={i === 1 && "40px"} key={i}>
                <Image
                  onClick={() => {
                    setInputParams((prev) => {
                      const currObj = prev.pep;
                      return Object.assign(
                        {},
                        prev,
                        d === 1
                          ? {
                              pep: {
                                ...currObj,
                                options: [d],
                              },
                            }
                          : {
                              pep: {
                                options: [d],
                              },
                            }
                      );
                    });
                  }}
                  cursor={"pointer"}
                  src={imgSrc}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {/* PEP官方职位 在职时间 */}
        {inputParams?.pep?.options?.findIndex((d) => d.value === 1) > -1 && (
          <Flex mt={"20px"}>
            <Flex flexDirection={"column"} flex={"1"}>
              <Text fontSize="14px" fontWeight={400}>
                {t("nkENghGiqpNyJlRpBv")}
              </Text>
              <Input
                h="44px"
                fontSize="12px"
                color="t.100"
                borderRadius={"12px"}
                placeholder={t("idqLvJqjeiEmQbpa")}
                mt="10px"
                maxLength={100}
                value={inputParams.pep?.position}
                onChange={(e) => {
                  setInputParams((prev) => {
                    const currObj = prev.pep;
                    return Object.assign({}, prev, {
                      pep: {
                        ...currObj,
                        position: e.target.value,
                      },
                    });
                  });
                }}
              />
            </Flex>
            <Flex flexDirection={"column"} flex={"1"} ml={"20px"}>
              <Text fontSize="14px" fontWeight={400}>
                {t("eEBDsqLdNoLOJxjVc")}
              </Text>
              <Input
                h="44px"
                fontSize="12px"
                color="t.100"
                borderRadius={"12px"}
                placeholder={t("idqLvJqjeiEmQbpa")}
                mt="10px"
                maxLength={100}
                value={inputParams.pep?.position_time}
                onChange={(e) => {
                  setInputParams((prev) => {
                    const currObj = prev.pep;
                    return Object.assign({}, prev, {
                      pep: {
                        ...currObj,
                        position_time: e.target.value,
                      },
                    });
                  });
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      {/* 您或任何关联方     是否与政治公众人物（PEP）有关？ */}
      <Flex mt={"40px"} flexDirection={"column"}>
        <Flex color={"#010313"} flexWrap={"wrap"}>
          <Text fontSize="14px" fontWeight={400}>
            {t("hYWPDZZNtYYwiYMfUfjX")}
          </Text>
          <Image
            src={"../../wwwsite/images/kyc/gray-info-circle.svg"}
            cursor={"pointer"}
            margin={"0px 3px"}
            onClick={() => setShowModalTip(true)}
          />
          <Text fontSize="14px" fontWeight={400}>
            {t("onaIGydLoNFhYUGyo")}
          </Text>
        </Flex>
        <Flex mt={"10px"}>
          {memoPEPContact.map((d, i) => {
            const imgSrc = `../../wwwsite/images/kyc/radio${
              inputParams?.pep_relation?.options?.findIndex(
                (x) => x.value === d.value
              ) > -1
                ? "_checked"
                : ""
            }.svg`;

            return (
              <Flex ml={i === 1 && "40px"} key={i}>
                <Image
                  onClick={() => {
                    setInputParams((prev) => {
                      const currObj = prev.pep;
                      return Object.assign(
                        {},
                        prev,
                        d === 1
                          ? {
                              pep_relation: {
                                ...currObj,
                                options: [d],
                              },
                            }
                          : {
                              pep_relation: {
                                options: [d],
                              },
                            }
                      );
                    });
                  }}
                  cursor={"pointer"}
                  src={imgSrc}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {/* PEP官方职位 在职时间 */}
      </Flex>
      {inputParams?.pep_relation?.options?.findIndex((d) => d.value === 1) >
        -1 && (
        <Flex mt={"20px"} flexDirection={"column"}>
          <Flex flexDirection={"column"}>
            <Text fontSize="14px" fontWeight={400}>
              {t("SJBETCfUKQwBPbOwXhs")}
            </Text>
            <Input
              h="44px"
              fontSize="12px"
              color="t.100"
              borderRadius={"12px"}
              placeholder={t("idqLvJqjeiEmQbpa")}
              mt="10px"
              maxLength={100}
              value={inputParams.pep_relation?.description}
              onChange={(e) => {
                setInputParams((prev) => {
                  const currObj = prev.pep_relation;
                  return Object.assign({}, prev, {
                    pep_relation: {
                      ...currObj,
                      description: e.target.value,
                    },
                  });
                });
              }}
            />
          </Flex>
          <Flex mt={"20px"} justifyContent={"space-between"} w={"100%"}>
            <Flex flexDirection={"column"} flex={"1"}>
              <Text fontSize="14px" fontWeight={400}>
                {t("nkENghGiqpNyJlRpBv")}
              </Text>
              <Input
                h="44px"
                fontSize="12px"
                color="t.100"
                borderRadius={"12px"}
                placeholder={t("idqLvJqjeiEmQbpa")}
                mt="10px"
                maxLength={100}
                value={inputParams.pep_relation?.position}
                onChange={(e) => {
                  setInputParams((prev) => {
                    const currObj = prev.pep_relation;
                    return Object.assign({}, prev, {
                      pep_relation: {
                        ...currObj,
                        position: e.target.value,
                      },
                    });
                  });
                }}
              />
            </Flex>
            <Flex flexDirection={"column"} flex={"1"} ml={"20px"}>
              <Text fontSize="14px" fontWeight={400}>
                {t("eEBDsqLdNoLOJxjVc")}
              </Text>
              <Input
                h="44px"
                fontSize="12px"
                color="t.100"
                borderRadius={"12px"}
                placeholder={t("idqLvJqjeiEmQbpa")}
                mt="10px"
                maxLength={100}
                onChange={(e) => {
                  setInputParams((prev) => {
                    const currObj = prev.pep_relation;
                    return Object.assign({}, prev, {
                      pep_relation: {
                        ...currObj,
                        position_time: e.target.value,
                      },
                    });
                  });
                }}
                value={inputParams.pep_relation?.position_time}
              />
            </Flex>
          </Flex>
        </Flex>
      )}

      {/* CRS 证明表格*/}
      <Flex alignItems={"center"} mt={"40px"}>
        <Text color={"#000"} fontSize={"16px"} fontWeight={500}>
          {t("DrmqiYKPSoxMBWXT")}
        </Text>
        <Flex
          ml={"30px"}
          border={"1px solid rgba(32, 26, 85, 0.05)"}
          borderRadius={"8px"}
          padding={"5px 10px"}
          alignItems={"center"}
        >
          <Image src="../../wwwsite/images/kyc/certificate/pdf.svg" />
          <a target="_blank" rel="noreferrer" href={crsDownLink}>
            <Box
              bg={"#EDEFFC"}
              borderRadius={"8px"}
              ml={"10px"}
              cursor={"pointer"}
              padding={"3px 10px"}
            >
              <Text color={"#333BF5"} fontSize={"12px"}>
                {t("ctaxFEnYnZIrAtOaYc")}
              </Text>
            </Box>
          </a>
        </Flex>
      </Flex>
      <Box position="relative">
        <Flex justifyContent={"space-between"} mt="10px" alignItems={"center"}>
          <Input
            w="80px"
            type="file"
            opacity={"0"}
            position={"absolute"}
            right="0"
            zIndex={"2"}
            cursor="pointer"
            fontSize="0"
            onChange={(e) => {
              handleUpLoadCRSFile(e);
            }}
          />
          <Input
            w={["220px", "310px"]}
            disabled
            height={"44px"}
            borderRadius={"12px"}
            _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
            value={
              crsFileInfo?.fileName ||
              inputParams?.documents?.[0]?.document_path
            }
          />
          <Button
            w="80px"
            h="30px"
            fontSize="12px"
            color="#fff"
            borderRadius={"20px"}
            bg="blue.100"
            textAlign={"center"}
            _hover={{ bg: "blue.80" }}
            ml={isMobile ? "10px" : ""}
          >
            {t("NqgOQEXQaHsramCnbX")}
          </Button>
        </Flex>
        <Text
          color={crsFileInfo?.error ? "red.100" : "#7B7C8F"}
          fontSize={"12px"}
          mt={"5px"}
        >
          {t("tMlPwcyXKotAImFCGfQ")}
        </Text>
      </Box>
      {/* 按钮 */}

      <Flex justifyContent={"center"} mt="30px" mb={"50px"}>
        <Button
          mr="10px"
          fontSize="14px"
          padding="0 30px"
          w={["100%", "100px"]}
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => {
            setCurrentPage(1);
          }}
        >
          {t("WbPTckCFMKtPHgAlL")}
        </Button>
        <Button
          fontSize="14px"
          padding="0 30px"
          w={["100%", "200px"]}
          bg="blue.100"
          color="#fff"
          fontWeight={"400"}
          borderRadius={"30px"}
          _hover={{ bg: "blue.80" }}
          h="44px"
          cursor={"pointer"}
          onClick={() => {
            onSumbit(setStep);
          }}
        >
          {t("tOaRBVUJHpnSgDBrGON")}
        </Button>
      </Flex>
      {/* PEP modal */}
      <ModalComponent isShow={showTipModal}>
        <Box px={"33px"} py={"50px"}>
          <Box color={"#010313"} fontSize={14}>
            <Flex justifyContent={"space-between"}>
              <Text fontSize={16} fontWeight={600}>
                {t("IKImbOtCOwEOtcDsMLv")}
              </Text>
              <Image
                boxSize="24px"
                src="../../wwwsite/images/bill/close.svg"
                cursor={"pointer"}
                onClick={() => setShowModalTip(false)}
              />
            </Flex>
            <Text
              mt={"20px"}
              dangerouslySetInnerHTML={{ __html: t("woYYXxIEUCPOPTbYi") }}
            ></Text>
          </Box>
        </Box>
      </ModalComponent>

      {uploadLoading && <BXLoading />}
    </Flex>
  );
};

export default SecondPage;
