import PageWrap from "@/components/PageWrap";
import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useMedia } from "@/hooks/useMedia";
import ModalComponent from "@/components/ui/modal";
import { updateKyc } from "@/state/kyc";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useI18n } from "@/hooks/useI18n";
import { ArrowBackIcon } from "@chakra-ui/icons";

const KycAccount = () => {
  const { isMobile } = useMedia();
  const navigate = useNavigate();
  const { t } = useI18n();
  const dispatch = useDispatch();
  const [showTipModal, setShowTipModal] = useState(false);
  const handleSelectKycType = (key) => {
    if (key === 0) {
      dispatch(updateKyc("person"));
      navigate("/trade/personKyc/certificate");
    } else {
      dispatch(updateKyc("company"));
      navigate("/trade/companyKyc");
    }
  };
  const renderAccounts = useMemo(() => {
    return [
      {
        imgPath: "../wwwsite/images/kyc_person.svg",
        label: t("nXPQNJCVxPkQKidfjnn"),
        desc1: t("swSisSeZXWyUyMvIu"),
        desc2: t("GCxKMtkKAZPoOHsZIDh"),
      },
      {
        imgPath: "../wwwsite/images/kyc_company.svg",
        label: t("HryFxrLzCqTGOVNrpI"),
        // desc1: t("QqwNZKCLuiPIswItY"),
        desc1: "",
      },
    ].map((d, i) => {
      return (
        <Box
          _hover={{
            cursor: "pointer",
            border: "1px solid #333BF5",
            boxShadow: "0px 4px 23px 0px rgba(74, 61, 230, 0.24)",
          }}
          bg={"#FFF"}
          flex={1}
          key={i}
          w={["100%", "323px"]}
          style={{
            marginLeft: i === 1 && !isMobile && "20px",
            marginTop: i === 1 && isMobile && "20px",
          }}
          borderRadius={"16px"}
          padding="50px 30px"
          border="1px solid #E3E4F1"
          onClick={() => handleSelectKycType(i)}
        >
          <img src={d.imgPath} alt="" />
          <Text
            fontSize={"18px"}
            fontWeight={600}
            mt={"30px"}
            color={"#010313"}
          >
            {d.label}
          </Text>
          <Box height={"54px"}>
            <Text
              fontSize={"14px"}
              fontWeight={400}
              color={"#7B7C8F"}
              padding="3px 0px"
            >
              {d.desc1}
            </Text>
            {i === 0 && (
              <Flex>
                <Text
                  fontSize={"14px"}
                  fontWeight={400}
                  color={"#7B7C8F"}
                  padding="3px 0px"
                >
                  {d?.desc2}
                </Text>
                <img
                  src="../wwwsite/images/kyc/kyc_tip.svg"
                  style={{ marginLeft: "5px", display: "inline-block" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowTipModal(true);
                  }}
                />
              </Flex>
            )}
          </Box>

          <Button
            borderRadius={"30px"}
            w={["100%", "200px"]}
            h="44px"
            fontSize="14px"
            bg={"blue.100"}
            mt={"30px"}
            color="#fff"
            _hover={{ bg: "blue.80" }}
            onClick={() => {}}
          >
            {t("gqfClfSZIJlpPGmuBjum")}
          </Button>
        </Box>
      );
    });
  }, [t, isMobile]);
  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        pt={"60px"}
      >
        {isMobile && (
          <Box
            mt="30px"
            mb="20px"
            w="112px"
            h="40px"
            bg="rgba(32, 26, 85, 0.05)"
            borderRadius={"0px 32px 32px 0px"}
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              w="112px"
              h="40px"
            >
              <ArrowBackIcon />
              <Text fontSize="14px" onClick={() => navigate("/trade/wallet")}>
                {t("WbPTckCFMKtPHgAlL")}
              </Text>
            </Flex>
          </Box>
        )}

        {!isMobile && (
          <Box position={"absolute"} left={"10%"} top={"100px"}>
            <Box
              w="112px"
              h="40px"
              bg="rgba(32, 26, 85, 0.05)"
              borderRadius={"32px"}
              cursor={"pointer"}
              _hover={{ background: "#E2E8F0" }}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                w="112px"
                h="40px"
                onClick={() => navigate("/trade/wallet")}
              >
                <ArrowBackIcon />
                <Text fontSize="14px">{t("WbPTckCFMKtPHgAlL")}</Text>
              </Flex>
            </Box>
          </Box>
        )}
        <Flex
          justifyContent={"center"}
          mt={["100px", "100px"]}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Box w={["80%", "652px"]} textAlign={"center"}>
            <Text fontSize={"32px"} fontWeight={600}>
              {t("UXOpXybkQBmLEHTAgw")}
            </Text>
          </Box>
          <Flex
            padding={"10px 20px"}
            mt={"40px"}
            w={isMobile && "100%"}
            justifyContent={"space-between"}
            flexDirection={isMobile ? "column" : "row"}
          >
            {renderAccounts}
          </Flex>
        </Flex>
      </Box>

      <ModalComponent isShow={showTipModal}>
        <Box px={"33px"} py={"50px"}>
          <Box>
            <Flex justifyContent={"space-between"}>
              <Text color="#010313" fontSize={16} fontWeight={600}>
                {t("GCxKMtkKAZPoOHsZIDh")}
              </Text>
              <Image
                boxSize="24px"
                src="../wwwsite/images/bill/close.svg"
                cursor={"pointer"}
                onClick={() => setShowTipModal(false)}
              />
            </Flex>
            <Text fontSize={14} color={"#010313"} mt={"20px"}>
              {t("FaQaEHvLdVELQbCZrFN")}
            </Text>

            <span
              style={{
                color: "#333BF5",
                fontSize: "13px",
                marginTop: "10px",
                display: "inline-block",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  "https://www.elegislation.gov.hk/hk/cap571D!en-sc",
                  "_target"
                )
              }
            >
              {t("xxgsgyEjhfwrMflwMr")}
            </span>
          </Box>
        </Box>
      </ModalComponent>
    </PageWrap>
  );
};

export default KycAccount;
