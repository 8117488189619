import { Box, Text, Image } from "@chakra-ui/react";

const AccountTypeItem = ({ text, isActive, style, onClick }) => {
  return (
    <Box
      border={"1px solid #E3E4F1"}
      borderRadius={"12px"}
      height={"44px"}
      style={style}
      display={"flex"}
      alignItems={"center"}
      padding={"0px 20px"}
      justifyContent={"space-between"}
      cursor={"pointer"}
      onClick={onClick}
    >
      <Text fontSize={"14px"} color={"#010313"}>
        {text}
      </Text>
      <Image
        src={
          isActive
            ? "../../wwwsite/images/checked.svg"
            : "../../wwwsite/images/no-checked.svg"
        }
      />
    </Box>
  );
};

export default AccountTypeItem;
