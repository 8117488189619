import { Box, Flex, Text, Image, Input, Button } from "@chakra-ui/react";
import Progress from "./Progress";
import { useI18n } from "@/hooks/useI18n";
import { useEffect, useMemo, useState } from "react";
// import { postUpload } from "../baseInfo/api";
import { useNavigate } from "react-router-dom";
import { postPIEvaluation } from "./api";
import { useAppSelector } from "@/state/hooks";
import BXLoading from "@/components/ui/pay-loading";
import { getS3PutUrl } from "./api";
import axios from "axios";
import { S3_AWS_KMS_KEY_ID } from "@/config";

const PiEvaluation = () => {
  const { t } = useI18n();
  const [filePath, setFilePath] = useState(""); // 文件地址
  const [fileName, setFileName] = useState(""); // 文件name
  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading
  const [validFile, setValidFile] = useState(false); // 文件合法校验
  const [fileType, setFileType] = useState(""); // 文件类型
  const [viewStatus, setViewStatus] = useState("INIT");
  const navigate = useNavigate();
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const lang = useAppSelector((state) => state.user.language);
  const [downLink, setDownLink] = useState("");

  useEffect(() => {
    const maps = {
      zh: "https://file.bixin.com/kyc/PI_CN.pdf",
      tw: "https://file.bixin.com/kyc/PI_HK.pdf",
      en: "https://file.bixin.com/kyc/PI_HK.pdf",
    };
    setDownLink(maps[lang]);
  }, [lang]);

  useEffect(() => {
    if (kycInfo?.length > 0) {
      const currProcess = kycInfo[6];
      if (currProcess.status === "PENDING") {
        setViewStatus("PENDING");
      }
    }
  }, [kycInfo]);

  const canSubmit = useMemo(() => {
    if (validFile && filePath !== "error") {
      return true;
    }
    return false;
  }, [validFile, filePath]);

  const handleFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const { name, size } = file;
      const fileNameSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (fileNameSuffix === "pdf" && size / 1000000 < 15) {
        setFileType(fileNameSuffix);
        setValidFile(true);
        uploadFn(file, fileNameSuffix);
      } else {
        setFilePath("error");
        setValidFile(false);
      }
      setFileName(name);
    }
  };

  const uploadFn = async (file, fileNameSuffix) => {
    setUploadLoading(true);
    const res = await getS3PutUrl({
      prefix: "pi_evaluation",
      file_type: fileNameSuffix,
    });
    if (res.code === 0) {
      const putUrl = res?.data?.put_url;
      const filePath = res?.data?.file_path;
      axios({
        url: putUrl,
        method: "put",
        headers: {
          "X-Amz-Server-Side-Encryption": "aws:kms",
          "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
        },
        data: file,
      }).then((uploadRes) => {
        if (uploadRes.status === 200) {
          setFilePath(filePath);
          setUploadLoading(false);
        }
      });
    }
  };

  const onSubmit = async () => {
    if (isSubmiting) return;
    setIsSubmiting(true);
    const params = {
      documents: {
        document_type: fileType,
        document_path: filePath,
      },
    };
    try {
      const res = await postPIEvaluation(params);
      if (res.code === 0) {
        setViewStatus("PENDING");
        setIsSubmiting(false);
      }
    } catch (e) {
      setIsSubmiting(false);
    }
  };

  const renderPendingView = () => {
    return (
      <Box
        w={["100%", "400px"]}
        margin={"0 auto"}
        mt={"60px"}
        borderRadius={"12px"}
        textAlign={"center"}
        fontWeight={"bold"}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src={`../../wwwsite/images/kyc/certificate/pending.svg`}
            width={"40px"}
            height={"40px"}
            alt=""
          ></Image>
          <Text
            fontSize={"24px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {t("KzFzOytWXScqDRKFwg")}
          </Text>
          <Text
            fontSize={"14px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {t("YXrqAGUqprWfOCeiSGX")}
          </Text>
        </Flex>
        <Button
          mt="40px"
          fontSize="14px"
          padding="0 30px"
          w="100px"
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => navigate("/trade/personKyc/certificate")}
        >
          {t("FrzWGSiDSDzTfNgZH")}
        </Button>
      </Box>
    );
  };

  const renderInitView = () => {
    return (
      <>
        <Flex alignItems={"center"} mt={"60px"}>
          <Text fontSize={"16px"} fontWeight={500} color={"#000"}>
            {t("ObVBBALqXaIzgsSPVQO")}
          </Text>
          <Flex
            ml={"30px"}
            border={"1px solid rgba(32, 26, 85, 0.05)"}
            borderRadius={"8px"}
            padding={"5px 10px"}
            alignItems={"center"}
          >
            <Image src="../../wwwsite/images/kyc/certificate/pdf.svg" />
            <a href={downLink} target="_blank" rel="noreferrer">
              <Box
                bg={"#EDEFFC"}
                borderRadius={"8px"}
                ml={"10px"}
                padding={"3px 10px"}
              >
                <Text color={"#333BF5"} fontSize={"12px"}>
                  {t("ctaxFEnYnZIrAtOaYc")}
                </Text>
              </Box>
            </a>
          </Flex>
        </Flex>
        <Box position="relative">
          <Flex
            justifyContent={"space-between"}
            mt="10px"
            alignItems={"center"}
          >
            <Input
              w="80px"
              type="file"
              opacity={"0"}
              position={"absolute"}
              right="0"
              zIndex={"2"}
              cursor="pointer"
              fontSize="0"
              onChange={(e) => {
                handleFile(e);
              }}
            />
            <Input
              w={["220px", "310px"]}
              disabled
              height={"44px"}
              borderRadius={"12px"}
              _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
              value={fileName}
            />
            <Button
              w="80px"
              h="30px"
              fontSize="12px"
              color="#fff"
              borderRadius={"20px"}
              bg="blue.100"
              textAlign={"center"}
              _hover={{ bg: "blue.80" }}
            >
              {t("NqgOQEXQaHsramCnbX")}
            </Button>
          </Flex>
          <Text
            // color={validFile ? "#D04457" : "#7B7C8F"}
            color={filePath === "error" ? "red.100" : "t.80"}
            fontSize={"12px"}
            mt={"5px"}
          >
            {t("tMlPwcyXKotAImFCGfQ")}
          </Text>
        </Box>
        {/* 底部按钮 */}
        <Flex justifyContent={"center"} mt="60px" mb={"50px"}>
          <Button
            mr="10px"
            fontSize="14px"
            padding="0 30px"
            w={["30%", "100px"]}
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              navigate("/trade/personKyc/certificate");
            }}
          >
            {t("FrzWGSiDSDzTfNgZH")}
          </Button>
          <Button
            fontSize="14px"
            padding="0 30px"
            w={["60%", "200px"]}
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            bg={canSubmit ? "blue.100" : "t.50"}
            _hover={{ bg: canSubmit ? "blue.80" : "t.50" }}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              if (canSubmit) {
                onSubmit();
              }
            }}
          >
            {t("QFbYEdxUgXzRwcPFQDis")}
          </Button>
        </Flex>
      </>
    );
  };

  return (
    <Flex justifyContent={"center"}>
      <Box w={["80%", "460px"]} mt={"30px"}>
        <Progress step="7" />
        {viewStatus === "INIT" && renderInitView()}
        {viewStatus === "PENDING" && renderPendingView()}
      </Box>
      {uploadLoading && <BXLoading />}
    </Flex>
  );
};

export default PiEvaluation;
