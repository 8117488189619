import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Button,
  useMediaQuery,
  Link,
  Image,
} from "@chakra-ui/react";

import AssetsTable from "@/pages/wallet/component/assetsTable";
import ModalComponent from "@/components/ui/modal";
import CommonBtnDom from "@/pages/deposit/component/commonBtn";
import { useAppSelector } from "@/state/hooks";
import { useMedia } from "@/hooks/useMedia";
import { updateUserInfo } from "@/state/user/reducer";
import { useDispatch } from "react-redux";
import { getUserInfo } from "./api";
import HibernateModal from "./component/HibernateModal";
import NotKycModal from "./component/NotKycModal";

import {
  BIXIN_WALLET_DEPOSIT,
  BIXIN_WALLET_WITHDRAW,
  BIXIN_EXCHANGE,
  BIXIN_AGREEMENT,
  BIXIN_PRIVACYPLICE,
  BIXIN_TRANSFER,
} from "@/helpers/constants/routeName";

import {
  getAssetInfo,
  getIsShowPrivacy,
  postConfirmPrivacy,
  getTransAccount,
  getMarketFex,
  getExchangeRate,
} from "@/pages/wallet/api";
import { useI18n } from "@/hooks/useI18n";
import { usePolling } from "@/pages/wallet/helpers/func/polling";
import { times, plus, div, toFixed } from "@/utils/bigNumber";
import PageWrap from "@/components/PageWrap";
const BTN_TYPE_TOPUP = "deposit";
const BTN_TYPE_TRANSFER = "withdraw";
const BTN_TRANSFER = "transfer";
const BTN_TYPE_EXCHANGE = "exchange";

const WalletPage = () => {
  const { t } = useI18n();
  const btnList = useMemo(() => {
    return [
      {
        name: t("gTYMvovCwafJTrjdlKg"),
        type: BTN_TYPE_TOPUP,
      },
      {
        name: t("PczYwMSUlifZlPdkNj"),
        type: BTN_TYPE_TRANSFER,
      },
      {
        name: t("eDwDkAgbfaPsnwOb"),
        type: BTN_TYPE_EXCHANGE,
      },
      {
        name: t("tjsCbqUvgNvcdRqdDp"),
        type: BTN_TRANSFER,
      },
    ];
  }, [t]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };

  useEffect(() => {
    getUserInfoFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const primary_currency =
    useAppSelector((state) => state?.user?.userInfo?.primary_currency) || "USD";
  const userInfo = useAppSelector((state) => state.user.userInfo);
  const [walletTotalBalance, setWalletTotalBalance] = useState({});
  const [walletSpotsTotalBalance, setWalletSpotsTotalBalance] = useState({});
  const [totalBalance, setTotalBalance] = useState({});
  const [currencyAssetList, setCurrencyAssetList] = useState([]);
  const [currencyTransList, setCurrencyTransList] = useState([]);

  const [showAuthrizationAlert, setShowAuthrizationAlert] = useState(false);
  const { isMobile } = useMedia();
  // eslint-disable-next-line no-unused-vars
  const [allBalance, setAllBalance] = useState({});
  const [showC3Modal, setShowC3Modal] = useState(false); // 已kyc
  const [showHibernateModal, setShowHibernateModal] = useState(false);

  // 小于739 大于等于900
  const [isLargerThan900] = useMediaQuery("(min-width: 900px)");
  const calcCoinToBTC = (amount, price, usd_btc) => {
    //拿当前币转换成u，再除btc价格
    const u = times(amount, price);
    const btc = div(u, usd_btc);
    return toFixed(btc, 6);
  };
  const InitAsset = async () => {
    const [res, res1, transAccount, marketFex, exchangeRate] =
      await Promise.all([
        getAssetInfo(),
        getIsShowPrivacy(),
        getTransAccount(),
        getMarketFex(),
        getExchangeRate({
          baseCurrencies: "BTC,USDT,ETH,USDC,USD,HKD",
          quoteCurrencies: primary_currency,
        }),
      ]);
    console.log("资产信息", res, res1);
    if (res.code === 0 && res1.code === 0) {
      const { total_balance, currency_asset_List } = res.data;
      setWalletTotalBalance(total_balance);
      setAllBalance(total_balance);
      setCurrencyAssetList(currency_asset_List);
      if (res1.data.is_push) {
        setShowAuthrizationAlert(true);
        // endPolling();
        // return false;
      } else {
        // return true;
      }
    }
    // const [transAccount,marketFex,exchangeRate] = await Promise.all([getTransAccount(),getMarketFex(),getExchangeRate({baseCurrencies:"BTC,USDT,ETH",quoteCurrencies:"USDT"})]);

    const { currency_asset_List, total_balance } = res.data;
    const transListArr = [];
    const transKeys = Object.keys(transAccount.data);
    marketFex.data.exchanges["USD_USD"] = 1;
    const transFiatBalance = [];
    const transFiatAmount = [];
    transKeys.map((item) => {
      let newObject = {};
      newObject.name = item;
      newObject.availableAmount = transAccount.data[item][0];
      newObject.frozenAmount = transAccount.data[item][1];
      newObject.fiat_currency = currency_asset_List[0]?.fiat_currency;
      newObject.amount = plus(
        transAccount.data[item][0],
        transAccount.data[item][1]
      );
      newObject.icon = `https://prod.bixin.info/currency/icon/${item}_1.png`;
      newObject.fiat_price = exchangeRate.data[`${item}_${primary_currency}`]; //代币价格
      newObject.fiat_balance = times(
        newObject.amount,
        exchangeRate.data[`${item}_${primary_currency}`]
      );
      transFiatBalance.push(newObject.fiat_balance);
      transFiatAmount.push(
        calcCoinToBTC(
          newObject.amount,
          exchangeRate.data[`${item}_${primary_currency}`],
          exchangeRate.data[`BTC_${primary_currency}`]
        )
      );
      return transListArr.push(newObject);
    });
    setCurrencyTransList(transListArr);

    const spotsBalance =
      transFiatBalance.length > 0
        ? transFiatBalance.reduce((prev, next) => {
            return plus(prev, next);
          })
        : 0;
    const spotsTotalAmount =
      transFiatAmount.length > 0
        ? transFiatAmount.reduce((prev, next) => {
            return plus(prev, next);
          })
        : 0;

    const spots = {
      balance: spotsBalance,
      amount: spotsTotalAmount,
    };
    const total = {
      balance: plus(spotsBalance, total_balance.fiat_balance),
      amount: plus(spotsTotalAmount, total_balance.balance),
    };
    setWalletSpotsTotalBalance(spots);
    setTotalBalance(total);

    return true;
  };

  const preparePolling = () => {
    return InitAsset().then((res) => {
      // console.log('定时器-返回的参数', res);
      // do some 数据过滤 or 组件状态改变 or 业务操作......
      return res;
    });
  };

  const [startPolling] = usePolling(preparePolling, 10);

  useEffect(() => {
    InitAsset();
    startPolling();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBtnClick = useCallback(
    (type) => {
      if (!userInfo?.kyc_type) return;
      switch (type) {
        case BTN_TYPE_TOPUP:
          if (userInfo?.kyc_level === "C1") {
            setShowHibernateModal(true);
            return;
          }
          if (userInfo?.kyc_type === "UNAUTHORIZED") {
            setShowC3Modal(true);
            return;
          }
          navigate(BIXIN_WALLET_DEPOSIT);
          break;
        case BTN_TYPE_TRANSFER:
          navigate(BIXIN_WALLET_WITHDRAW);
          break;
        case BTN_TYPE_EXCHANGE:
          window.open(BIXIN_EXCHANGE, "_self");
          break;
        case BTN_TRANSFER:
          if (userInfo?.kyc_level === "C1") {
            setShowHibernateModal(true);
            return;
          }
          if (userInfo?.kyc_type === "UNAUTHORIZED") {
            setShowC3Modal(true);
            return;
          }
          navigate(BIXIN_TRANSFER);
          break;
        default:
          break;
      }
    },
    [userInfo, navigate]
  );

  // 按钮展示区
  // const c3NoPassed = (getValue) => {
  //   return btnShowList.includes(getValue);
  // };

  const toReadPrivacy = async () => {
    setShowAuthrizationAlert(false);
    startPolling();
    // eslint-disable-next-line no-unused-vars
    const { code, data } = await postConfirmPrivacy();
  };

  const noDataReturnDom = (value) => {
    return value || "-";
  };

  const checkZero = (val) => {
    if (val === "0.000000") {
      return "0.00";
    }
    return val;
  };

  const tatolBanlance = () => {
    return (
      <Flex
        justifyContent={"space-between"}
        flexDirection={isLargerThan900 ? "row" : "column"}
        w="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          color="#B3B4C8"
          fontSize="14px"
          mr={"40px"}
          fontWeight={400}
        >
          <Text>
            {t("bqNjZcEbCrAzOJJpJDn").replace(
              "BTC",
              noDataReturnDom(walletTotalBalance.currency)
            )}
          </Text>
          <Box>
            <Text fontSize={40} fontWeight={700} color="#FFFFFF">
              {totalBalance.amount &&
                checkZero(toFixed(totalBalance.amount, 6))}
            </Text>
            <Text>
              ≈ {totalBalance.balance && toFixed(totalBalance.balance, 2)}{" "}
              {walletTotalBalance.fiat_currency || ""}
            </Text>
          </Box>
        </Box>
        <Box mt={["30px", "30px", "0px"]}>
          <Flex overflowX={"scroll"}>
            <Box
              w={["300px", "300px", "332px"]}
              display="flex"
              minWidth={"195px"}
              flexDirection="column"
              justifyContent="space-between"
              color="#B3B4C8"
              fontSize="14px"
              bg="rgba(105, 94, 234, 0.1)"
              borderRadius={"24px"}
              padding="20px 30px"
              border={"1px solid rgba(105, 94, 234, 0.1)"}
              fontWeight={400}
              mr="30px"
            >
              <div>
                <Text>
                  {t("NqoduOnqIqJtrjajas").replace(
                    "BTC",
                    noDataReturnDom(walletTotalBalance.currency)
                  )}
                </Text>
                <Text fontSize={40} fontWeight={700} color="#FFFFFF">
                  {walletTotalBalance.balance &&
                    toFixed(walletTotalBalance.balance, 2)}
                </Text>
              </div>

              <Text fontSize={"15px"}>
                ≈{" "}
                {walletTotalBalance.fiat_balance &&
                  toFixed(walletTotalBalance.fiat_balance, 2)}{" "}
                {walletTotalBalance.fiat_currency}
              </Text>
            </Box>
            <Box
              w={["300px", "300px", "332px"]}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              color="#B3B4C8"
              fontSize="14px"
              minWidth={"195px"}
              bg="rgba(105, 94, 234, 0.1)"
              borderRadius={"24px"}
              padding="20px 30px"
              border={"1px solid rgba(105, 94, 234, 0.1)"}
              fontWeight={400}
            >
              <div>
                <Text>
                  {t("vLUFWGhkoIBWnOMA").replace(
                    "BTC",
                    noDataReturnDom(walletTotalBalance.currency)
                  )}
                </Text>
                <Text fontSize={40} fontWeight={700} color="#FFFFFF">
                  {walletSpotsTotalBalance.amount &&
                    toFixed(walletSpotsTotalBalance.amount, 2)}
                </Text>
              </div>
              <Flex alignItems={"center"}>
                <Text mr="10px" fontSize={"15px"}>
                  ≈{" "}
                  {walletSpotsTotalBalance.balance &&
                    toFixed(walletSpotsTotalBalance.balance, 2)}{" "}
                  {walletTotalBalance.fiat_currency}
                </Text>
              </Flex>
              {/* </Box> */}
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  };
  const btnArea = () => {
    return (
      <Box
        display="flex"
        flexDirection={isLargerThan900 && "row"}
        flexWrap={!isLargerThan900 && "wrap"}
        alignItems={isLargerThan900 ? "flex-end" : "center"}
        color="#010313"
        w={!isLargerThan900 && "100%"}
        fontSize={14}
        fontWeight={400}
        mt="45px"
      >
        {btnList.map((item, index) => {
          return (
            <Button
              w={["45%", "120px"]}
              mr={isMobile ? (index === 0 || index === 2) && "20px" : "20px"}
              borderRadius="30px"
              bgColor="#EDEFFC"
              color="#010313"
              fontSize={14}
              fontWeight={400}
              key={item.type}
              mb={!isLargerThan900 && "20px"}
              onClick={() => handleBtnClick(item.type)}
              position={"unset"}
            >
              {item.name}
            </Button>
          );
        })}
      </Box>
    );
  };
  const jumpUrlDom = (name, routeName) => {
    // console.log('获取到的页面链接', `${window.location.protocol}//${window.location.host}${routeName}`)
    return (
      <Link
        color="blue.100"
        href={`${window.location.protocol}//${window.location.host}${routeName}`}
        isExternal
      >
        {name}
      </Link>
    );
  };

  // const changeLang = (lang) => {
  //   dispatch(updateLanguage({ language: lang || "tw" }));
  // };

  return (
    <PageWrap>
      <div className="wallet-page">
        {/* {Header && <Header changeLanguage={changeLang} />} */}
        <Box
          w="100%"
          bgGradient="linear-gradient(180deg, #242426 0%, #1C1C1E 100%)"
          borderBottomRadius="40px"
          pt={isLargerThan900 ? "40px" : "30px"}
          pb={isLargerThan900 ? "40px" : "40px"}
        >
          {isLargerThan900 ? (
            <Box w="70%" h="100%" m={[0, "auto"]}>
              <Flex justify="space-between" color="#B3B4C8" fontSize="14px">
                {tatolBanlance()}
              </Flex>
              <Flex justifyContent={"flex-end"}>{btnArea()}</Flex>
            </Box>
          ) : (
            <Box px="30px">
              {tatolBanlance()}
              <Box mt="30px">{btnArea()}</Box>
            </Box>
          )}
        </Box>
        <AssetsTable
          assetList={currencyAssetList}
          transList={currencyTransList}
        />
        <ModalComponent isShow={showAuthrizationAlert}>
          <Box px={"33px"} py={"50px"}>
            <Text
              textAlign={"center"}
              fontSize={"16px"}
              fontWeight={500}
              color={"#010313"}
            >
              {`${t("gMjyScxabRhqehsX")}`}
              {jumpUrlDom(t("UtQJNokaxJhxqqTQDiO"), BIXIN_AGREEMENT)}
              {t("TFAtjCZbFqacobpGxct")}
              {jumpUrlDom(t("EokACCilfAZfuGcXD"), BIXIN_PRIVACYPLICE)}
            </Text>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CommonBtnDom
                btnText={t("AQrCocFqoKoYieUnT")}
                noCanClick={false}
                btnW={"200px"}
                marginTop="40px"
                clickEvent={toReadPrivacy}
              />
            </Box>
          </Box>
        </ModalComponent>
        {/* 未c3弹窗 */}
        <NotKycModal
          show={showC3Modal}
          onClose={() => setShowC3Modal(false)}
        ></NotKycModal>
        {/* 休眠账户弹窗 */}
        <HibernateModal
          show={showHibernateModal}
          onClose={() => setShowHibernateModal(false)}
        />
      </div>
    </PageWrap>
  );
};

export default WalletPage;
