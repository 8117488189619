import {useEffect, useState} from "react";
import {getBankAccountCreate, getBankAccountGet, getBankAccountUpdate} from "../api";
import {useNavigate} from "react-router-dom";
import {getPageQuery} from "@/utils";
import {toastTip} from "@/components/ui/toast";
import {useI18n} from "@/hooks/useI18n";
import {usePersonalHooks} from "@/pages/accountManager/hooks";
import {useDispatch} from "react-redux";
import { updateBankInfo } from "@/state/user/reducer";
import {useAppSelector} from "@/state/hooks";
import {useCommonHooks} from "@/pages/register/hooks";

const useLegalTender = () => {
    const navigate = useNavigate();
    const {
        userInfo,
    } = usePersonalHooks();
    const { allCountryList, sanctionsCountryList, noCompliantCountryList } =
        useCommonHooks();
    const { t } = useI18n();
    const dispatch = useDispatch();
    const urlParams = getPageQuery();
    const uuid = urlParams.uuid;
    const [addParams, setAddParams] = useState({
        countryArea: '852', // 国家区号 852 中国香港
        bankName: '', // 银行名称
        payeeName: '', // 收款人名称
        accountNumber: '', // 账户号码
        bankCode: '', // 银行编号
        swiftCode: '', // SWIFT代码
        bankAddress: '', // 银行地址
        isKycTransfer: '', // 是否完成KYC银行转账验证
        bankTransferReceipt: '', // KYC银行转账验证凭证
    });

    const addBankInfo = useAppSelector((state) => state.user.addBankInfo);

    const [addLoading, setAddLoading] = useState(false);
    const [isShowBankTransferRadio, setIsShowBankTransferRadio] = useState(false);

    const [areaError, setAreaError] = useState({});

    useEffect(() => {
        uuid && JSON.stringify(userInfo) !== "{}" && allCountryList.length && getInitAddParams();
    }, [uuid, userInfo, allCountryList]);

    useEffect(() => {
        if(JSON.stringify(addBankInfo) !== "{}" && allCountryList.length) {
            setAddParams(prev => {
                const obj = {
                    ...prev,
                    bankName: addBankInfo.bank_name,
                    payeeName: addBankInfo.account_name,
                    accountNumber: addBankInfo.account_no,
                    bankCode: addBankInfo.bank_code,
                    swiftCode: addBankInfo.swift_code,
                    bankAddress: addBankInfo.bank_addr,
                    countryArea: getCountryDialCode(addBankInfo.country_area),
                };
                if(addBankInfo.is_kyc_bank_transfer_verified !== '') {
                    obj.isKycTransfer = addBankInfo.is_kyc_bank_transfer_verified ? 'yes' : 'no';
                }
                return obj;
            })
        }
    }, [addBankInfo, allCountryList]);

    useEffect(() => {
        setIsShowBankTransferRadio(userInfo.auth_mode === 'bank_transfer');
    }, [userInfo.auth_mode]);

    const getCountryDialCode = isoCode2 => {
        const currentDialCodeData = allCountryList.filter((item) => item.iso_code2 === isoCode2);
        console.log(currentDialCodeData[0]?.dial_code, 'currentDialCodeData[0]?.dial_code');
        return currentDialCodeData[0]?.dial_code;
    };

    const getInitAddParams = async () => {
        const params = {
            uuid
        };
        const res = await getBankAccountGet(params);
        if(res.code === 0 && res.msg === "OK") {
            const data = res?.data;
            setAddParams(prev => {
                const params = {
                    ...prev,
                    bankName: data.bank_name,
                    payeeName: data.account_name,
                    accountNumber: data.account_no,
                    bankCode: data.bank_code,
                    swiftCode: data.swift_code,
                    bankAddress: data.bank_addr,
                    countryArea: getCountryDialCode(data.country_area),
                };
                if(data.is_kyc_bank_transfer_verified !== '' && userInfo.auth_mode === 'bank_transfer') {
                    params.isKycTransfer = data.is_kyc_bank_transfer_verified ? 'yes' : 'no';
                }
                if(data.bank_transfer_receipt) {
                    params.bankTransferReceipt = data.bank_transfer_receipt;
                }
                return params;
            });
        }
    };

    const addParamsHandler = (key, val, max) => {
        if(val?.length > max && max) {
            return false;
        }
        // 国家 countryArea
        if(key === "countryArea") {
            setAreaError({});
            // 在制裁区
            if (sanctionsCountryList.indexOf(val.iso_code2) > -1) {
                setAreaError({ san: true });
                return;
            }
            // 非合规区
            if (noCompliantCountryList.indexOf(val.iso_code2) > -1) {
                setAddParams((prev) => {
                    return {
                        ...prev,
                        [key]: val.dial_code,
                    }
                })
                setAreaError({ noComp: true });
                return;
            }
            setAddParams((prev) => {
                return {
                    ...prev,
                    [key]: val.dial_code,
                }
            })
            return false;
        }
        // 其他
        setAddParams((prev) => {
            return {
                ...prev,
                [key]: val,
            }
        })
    };

    const onSubmit = async () => {
        console.log(addParams);
        if(areaError?.san || areaError?.noComp || !addParams.countryArea || !addParams.bankName || !addParams.payeeName || !addParams.accountNumber || !addParams.bankCode || !addParams.swiftCode || !addParams.bankAddress) {
            return toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
        }
        if(isShowBankTransferRadio && addParams.isKycTransfer === '') {
            return toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
        }
        const currentIsoCode2Data = allCountryList.filter((item) => item.dial_code === addParams.countryArea);
        const params = {
            bank_name: addParams.bankName,
            account_name: addParams.payeeName,
            account_no: addParams.accountNumber,
            bank_code: addParams.bankCode,
            swift_code: addParams.swiftCode,
            bank_addr: addParams.bankAddress,
            country_area: currentIsoCode2Data[0]?.iso_code2,
        };
        console.log(params, 'paramsparams');
        if(addParams.isKycTransfer) {
            params.is_kyc_bank_transfer_verified = addParams.isKycTransfer === 'yes';
        }
        if(addParams.bankTransferReceipt) {
            params.bank_transfer_receipt = addParams.bankTransferReceipt;
        }
        if(addParams.isKycTransfer === 'no') {
            if(uuid) {
                params.uuid = uuid;
            }
            dispatch(updateBankInfo({bankInfo: params}));
            return navigate('/trade/personKyc/verify?type=bank&path=add');
        }
        setAddLoading(true);
        let res;
        if(uuid) {
            params.uuid = uuid;
            res = await getBankAccountUpdate(params);
        } else {
            res = await getBankAccountCreate(params);
        }
        dispatch(updateBankInfo({bankInfo: {}}));
        setAddLoading(false);
        console.log(res, 'resres');
        if(res?.code === 0 && res?.msg === "OK") {
            navigate('/trade/legal/tender');
        }
    };

    return {
        addParams,
        addLoading,
        isShowBankTransferRadio,
        allCountryList,
        areaError,
        addParamsHandler,
        onSubmit
    };
};

export default useLegalTender;