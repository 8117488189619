import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { BIXIN_ACCOUNTMANAGER } from "@/helpers/constants/routeName";
import { postGaBind } from "./api";
import { throttle } from "lodash";
import MiliCodeInput from "@/components/ui/mili-codeinput/mili-codeinput.component";
import { toastTip } from "@/components/ui/toast";
import { useI18n } from "@/hooks/useI18n";
const GaStepThree = (props) => {
  const { getStep } = props;
  const { t } = useI18n();
  const navigate = useNavigate();
  const passWordInputRef = useRef(null);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [nextError, setNextError] = useState(false);
  const passwordInput = (val) => {
    if (val) {
      setCode(val);
      setCodeError(false);
    } else {
      setCodeError(true);
    }
  };

  const toAccountSafe = throttle(async () => {
    if (!code) {
      return setCodeError(true);
    }
    setNextError(true);
    const res = await postGaBind({
      code,
    });
    if (res.code === 0) {
      setNextError(false);
      toastTip(t("rQgPydQgDVYqtWJXCky"));
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } else {
      setNextError(false);
    }
  }, 5000);
  return (
    <div>
      <Flex justifyContent={"center"} mt="200px">
        <Box w={["80%", "400px"]}>
          <Flex justifyContent={"center"}>
            <img src="../wwwsite/images/GoogleAuth.svg" alt="ga" />
            <Text fontSize="32px" fontWeight={"600"} color="t.100" ml="10px">
              {t("nSdDtaHzknwZAXJHzrGP")}
            </Text>
          </Flex>
          <Text
            mt="100px"
            fontSize="16px"
            color="t.100"
            mb="10px"
            fontWeight={"500"}
          >
            {t("wINMfXpJczhYZOnUXWp")}
          </Text>
          <MiliCodeInput
            ref={passWordInputRef}
            onChange={passwordInput}
            type="password"
            codeboxItemADDClassName="changeBackGround"
          />
          {codeError && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {t("wINMfXpJczhYZOnUXWp")}
            </Text>
          )}
          <Flex justifyContent={"center"} mt="100px">
            {!nextError ? (
              <Button
                ml="10px"
                fontSize="14px"
                padding="0 30px"
                w="200px"
                bg="blue.100"
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                _hover={{ bg: "blue.80" }}
                h="44px"
                cursor={"pointer"}
                onClick={toAccountSafe}
              >
                {t("WzcUSFqDMdwkVtGoj")}
              </Button>
            ) : (
              <Button
                ml="10px"
                fontSize="14px"
                padding="0 30px"
                w="200px"
                bg="t.50"
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                _hover={{ bg: "t.50" }}
                h="44px"
                cursor={"pointer"}
              >
                {t("WzcUSFqDMdwkVtGoj")}
              </Button>
            )}

            <Button
              ml="10px"
              fontSize="14px"
              padding="0 30px"
              w="100px"
              bg="rgba(32, 26, 85, 0.05)"
              color="t.100"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={"pointer"}
              onClick={() => {
                getStep(2);
              }}
            >
              {t("WbPTckCFMKtPHgAlL")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
};

export default GaStepThree;
