import request from "@/hooks/useRequest";
import {
  CREATE_WHITELIST_ADDRESS,
  RENEW_WHITELIST_ADDRESS,
  DELETE_WHITELIST_ADDRESS,
  GET_WHITELIST_ADDRESS,
  PERSONAL_SIGN_MSG,
  GET_WHITELIST_ADDRESS_UUID,
  WHITELIST_VASP_LIST,
} from "@/helpers/constants/requestApi";

export const createWhiteListAddress = (body) => {
  return request(CREATE_WHITELIST_ADDRESS, { body });
};
export const renewWhiteListAddress = (body) => {
  return request(RENEW_WHITELIST_ADDRESS, { body });
};

export const deleteWhiteListAddress = (body) => {
  return request(DELETE_WHITELIST_ADDRESS, { body });
};

export const getWhiteListAddress = () => {
  return request(GET_WHITELIST_ADDRESS, { params: {} });
};

export const getWhiteListAddressWithUUId = (uuid) => {
  return request(GET_WHITELIST_ADDRESS_UUID, { params: { uuid } });
};

export const generatePersonalSignMessage = (body) => {
  return request(PERSONAL_SIGN_MSG, { body });
};

// metamask 验证
export const whiteListAddressSignVerify = (body) => {
  return request("/web-wallet/api/v1/whitelist/sign/verify", { body });
};

// 转账验证
export const whiteListAddressTransferCreate = (body) => {
  return request("/web-wallet/api/v1/whitelist/transfer/create", { body });
};

export const getWhiteListVaspList = () => {
  return request(WHITELIST_VASP_LIST, { params: {} });
};

// 交易所 白名单验证
export const verifyCEXWhiteListCreate = (body) => {
  return request("/web-wallet/api/v1/whitelist/hosted/create", { body });
};

// s3 put url
export const getS3PutUrl = (params) => {
  return request("/web-wallet/api/v1/s3/get-private-put-url", { params });
};

// 非托管钱包验证获取交易信息
export const getTransferInfo = (body) => {
  return request("/web-wallet/api/v1/whitelist/transfer/info", { body });
};
