import { Flex, Box, Text, Input, Center } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import PageWrap from "@/components/PageWrap";
import { getCompanyInfoApi } from "./api";
import { useEffect, useState } from "react";

const CompanyKycInfo = () => {
  const { t } = useI18n();
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    getCompanyInfo();
  }, []);

  const getCompanyInfo = async () => {
    // setCompanyInfo({
    //   legal_name: "111",
    //   registered_address: "222",
    //   is_financial: true,
    //   business_address: "33",
    //   mailing_address: "321321",
    //   country: "321",
    //   tel_no: "312",
    // });
    const res = await getCompanyInfoApi();
    if (res.code === 0) {
      setCompanyInfo(res.data);
    }
  };

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
      >
        <Flex justifyContent={"center"} mt={["20px", "80px"]} mb="140px">
          <Box w={["80%", "400px"]}>
            <Center>
              <Text fontSize="32px" fontWeight={"600"} color="t.100" ml="13px">
                {t("HMclPwyZgRMKnvBRCr")}
              </Text>
            </Center>
            {/* 公司法定全名 */}
            <Text fontSize="14px" color="t.100" mt="20px" borderRadius={"12px"}>
              {t("kjqqAmIhgKSjlBVQx")}
            </Text>
            <Input
              value={companyInfo?.legal_name}
              fontSize="12px"
              borderRadius={"12px"}
              mt="10px"
              h="44px"
              color={"t.50"}
              disabled={true}
              _disabled={{
                opacity: "1",
                color: "#010313",
                bgColor: "rgba(32, 26, 85, 0.05)",
                border: "1px solid #E3E4F1",
              }}
            />

            {/* 公司註冊地址 */}
            <Text fontSize="14px" color="t.100" mt="30px" borderRadius={"12px"}>
              {t("BEfhHOirsmwpDEilDbq")}
            </Text>
            <Input
              fontSize="12px"
              borderRadius={"12px"}
              mt="10px"
              h="44px"
              value={companyInfo?.registered_address}
              color={"t.50"}
              disabled={true}
              _disabled={{
                opacity: "1",
                color: "#010313",
                bgColor: "rgba(32, 26, 85, 0.05)",
                border: "1px solid #E3E4F1",
              }}
            />
            {/* 是否金融机构 */}
            <Text fontSize="14px" color="t.100" mt="30px" borderRadius={"12px"}>
              {t("pPViUnFnexhWKoFMx")}
            </Text>
            <Flex mt="15px">
              {[
                {
                  key: 1,
                  label: t("NltNaOnxuMeUAbBeZ"),
                },
                {
                  key: 2,
                  label: t("HmXYHUAhkQnrZSfKu"),
                },
              ].map((d) => {
                return (
                  <Flex key={d.key} ml={d.key === 2 ? "50px" : "0px"}>
                    <img
                      alt=""
                      src={`../../wwwsite/images/kyc/radio${
                        (companyInfo?.is_financial && d.key === 1) ||
                        (d.key === 2 && !companyInfo?.is_financial)
                          ? "_checked"
                          : ""
                      }.svg`}
                    />
                    <Text ml="5px">{t(d.label)}</Text>
                  </Flex>
                );
              })}
            </Flex>

            {/* 公司主要营业地址 */}
            <Text fontSize="14px" color="t.100" mt="30px" borderRadius={"12px"}>
              {t("WzIAmgVLQnPRHGRfGw")}
            </Text>
            <Input
              fontSize="12px"
              borderRadius={"12px"}
              mt="10px"
              h="44px"
              value={companyInfo?.business_address}
              disabled={true}
              color="t.100"
              _disabled={{
                opacity: "1",
                color: "#010313",
                bgColor: "rgba(32, 26, 85, 0.05)",
                border: "1px solid #E3E4F1",
              }}
            />
            {/* 邮寄地址 */}
            <Text fontSize="14px" color="t.100" mt="30px" borderRadius={"12px"}>
              {t("PufPEQRHcFlNqsAG")}
            </Text>
            <Input
              fontSize="12px"
              value={companyInfo?.mailing_address}
              borderRadius={"12px"}
              mt="10px"
              h="44px"
              disabled={true}
              color="t.100"
              _disabled={{
                opacity: "1",
                color: "#010313",
                bgColor: "rgba(32, 26, 85, 0.05)",
                border: "1px solid #E3E4F1",
              }}
            />

            {/* 所在国家和地区 */}
            <Text fontSize="14px" color="t.100" mt="30px" borderRadius={"12px"}>
              {t("zcamcMLRNetHERtiy")}
            </Text>
            <Input
              fontSize="12px"
              value={companyInfo?.country}
              borderRadius={"12px"}
              mt="10px"
              h="44px"
              disabled={true}
              color="t.100"
              _disabled={{
                opacity: "1",
                color: "#010313",
                bgColor: "rgba(32, 26, 85, 0.05)",
                border: "1px solid #E3E4F1",
              }}
            />

            {/* 电话号码 */}
            <Text fontSize="14px" color="t.100" mt="30px" borderRadius={"12px"}>
              {t("NStCDznwhsZPGYrJAq")}
            </Text>
            <Input
              fontSize="14px"
              value={companyInfo?.tel_no}
              borderRadius={"12px"}
              mt="10px"
              h="44px"
              disabled={true}
              color="t.100"
              _disabled={{
                opacity: "1",
                color: "#010313",
                bgColor: "rgba(32, 26, 85, 0.05)",
                border: "1px solid #E3E4F1",
              }}
            />
          </Box>
        </Flex>
      </Box>
    </PageWrap>
  );
};

export default CompanyKycInfo;
