import { Box, Flex, Text, Center, Button, Image } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import Progress from "../Progress";
import { useMemo } from "react";
import { useAppSelector } from "@/state/hooks";
import Jumio from "./JumioSDK";
import { useI18n } from "@/hooks/useI18n";
import "dayjs/locale/zh-cn";
import { useVerifyIdentity } from "../verifyHooks";

const VerifyIdentity = (props) => {
  const { setStep, allCountryList } = props;
  const { t } = useI18n();
  const _lang = useAppSelector((state) => state.user.language);

  const {
    memoCardList,
    verifyIdentityStep,
    setVerifyIdentityStep,
    identityData,
  } = useVerifyIdentity();

  const memoCountry = useMemo(() => {
    if (identityData?.country && allCountryList) {
      const item = allCountryList.filter(
        (d) => d.iso_code2 === identityData.country
      )[0];
      if (_lang === "zh") return item?.zh_name;
      return item?.en_name;
    }
  }, [identityData, allCountryList, _lang]);

  return (
    // 这里要去获取 认证卡类型以及证件发行地
    <Flex justifyContent={"center"}>
      {verifyIdentityStep !== "JUMIO" && (
        <Box w={["80%", "400px"]} mt={"30px"}>
          <Progress step="2" />
          {/* 选择认证卡片类型 */}
          {verifyIdentityStep === "SELECT" && (
            <Box mt={["31px", "61px"]}>
              <Center>
                <Text color={"t.80"} fontSize={"14px"} wordBreak={"break-word"}>
                  {t("AsfYVtpYWMjfUopLa")}
                </Text>
                <Text color={"#010313"} fontSize={"14px"} fontWeight={500}>
                  {memoCountry}
                </Text>
              </Center>
              <Box
                mt={"15px"}
                width={"100%"}
                background={"rgba(32, 26, 85, 0.05)"}
                borderRadius={"12px"}
                padding={"10px 10px"}
              >
                <Flex alignItems={"center"}>
                  <Image
                    src={"../../wwwsite/images/whitelist/vector.svg"}
                    w={"16px"}
                    h={"16px"}
                  />
                  <Text fontSize={"12px"} fontWeight={"400"} ml={"10px"}>
                    {t("TbviyxwhVLMOhttn")}
                  </Text>
                </Flex>
              </Box>
              <Box mt={["31px", "30px"]}>
                {memoCardList.map((item) => (
                  <Box
                    border="1px solid #E3E4F1"
                    h="44px"
                    mb="10px"
                    borderRadius={"12px"}
                    padding="0 20px"
                    key={item.value}
                    background={
                      item.value === identityData?.id_type
                        ? "rgba(32, 26, 85, 0.05)"
                        : "#fff"
                    }
                  >
                    <Flex
                      justifyContent={"space-between"}
                      h="44px"
                      alignItems={"center"}
                    >
                      <Text fontSize="14px">{item.name}</Text>
                      <ChevronRightIcon boxSize={6} />
                    </Flex>
                  </Box>
                ))}
              </Box>
              {memoCountry && (
                <Flex justifyContent={"center"} mt="60px" mb={"50px"}>
                  <Button
                    mr="10px"
                    fontSize="14px"
                    padding="0 30px"
                    w={["100%", "100px"]}
                    bg="rgba(32, 26, 85, 0.05)"
                    color="t.100"
                    fontWeight={"400"}
                    borderRadius={"30px"}
                    h="44px"
                    cursor={"pointer"}
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    {t("FrzWGSiDSDzTfNgZH")}
                  </Button>
                  <Button
                    fontSize="14px"
                    padding="0 30px"
                    w={["100%", "200px"]}
                    bg="blue.100"
                    color="#fff"
                    fontWeight={"400"}
                    borderRadius={"30px"}
                    _hover={{ bg: "blue.80" }}
                    h="44px"
                    cursor={"pointer"}
                    onClick={() => setVerifyIdentityStep("JUMIO")}
                  >
                    {t("tOaRBVUJHpnSgDBrGON")}
                  </Button>
                </Flex>
              )}
            </Box>
          )}
        </Box>
      )}
      {verifyIdentityStep === "JUMIO" && (
        <Box flexDirection={"column"} justifyContent={"center"}>
          <Box w={["80%", "400px"]} margin={"0 auto"} mt={"30px"}>
            <Progress step="2" />
          </Box>
          <Jumio
            setVerifyIdentityStep={setVerifyIdentityStep}
            identityData={identityData}
            setStep={setStep}
          />
        </Box>
      )}
    </Flex>
  );
};

export default VerifyIdentity;
