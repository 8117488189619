import { Box, Flex } from "@chakra-ui/react";
import Progress from "../Progress";
import "./personal.scss";
import usePersonalHooks from "./hooks";
import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";

const PersonalInfo = ({
  setStep,
  allCountryList,
  sanctionsCountryList,
  noCompliantCountryList,
}) => {
  const {
    memoGender,
    memoCardType,
    inputParams,
    setInputParams,
    memoPEP,
    memoWealthSource,
    memoEstimatedNetWorth,
    memoFundSource,
    memoAnnualIncome,
    memoAnticipatedMonthlyTxFrequency,
    memoPEPContact,
    handleSignleCheck,
    currentPage,
    setCurrentPage,
    memoTradeTargets,
    handleMultipleCheck,
    memoNeedProdServices,
    inputOnChange,
    toNextPage,
    onSumbit,
    memoAnticipatedMonthlyTxAmount,
    handleUpLoadCRSFile,
    crsFileInfo,
    uploadLoading,
    memoHasOtherCountryCard,
    memoOtherCountryCardType,
    areaScanError,
    areaCompliantError,
    setAreaCompliantError,
    setAreaScanError,
    hasOtherIdCard,
    otherCountryAreaError,
    setOtherCountryAreaError,
    handleUploadOtherCountryIdCardFile,
    otherCountryIdCardFrontFileInfo,
    otherCountryIdCardOppositetFileInfo,
  } = usePersonalHooks({ noCompliantCountryList, sanctionsCountryList });

  return (
    <Flex justifyContent={"center"} width={"100%"}>
      <Box mt={"30px"} width={"100%"} mb={"60px"}>
        <Progress step="1" />
        {currentPage === 1 && (
          <FirstPage
            inputOnChange={inputOnChange}
            memoGender={memoGender}
            memoTradeTargets={memoTradeTargets}
            memoCardType={memoCardType}
            inputParams={inputParams}
            setInputParams={setInputParams}
            setCurrentPage={setCurrentPage}
            memoNeedProdServices={memoNeedProdServices}
            handleMultipleCheck={handleMultipleCheck}
            toNextPage={toNextPage}
            allCountryList={allCountryList}
            sanctionsCountryList={sanctionsCountryList}
            noCompliantCountryList={noCompliantCountryList}
            memoHasOtherCountryCard={memoHasOtherCountryCard}
            memoOtherCountryCardType={memoOtherCountryCardType}
            areaScanError={areaScanError}
            areaCompliantError={areaCompliantError}
            setAreaCompliantError={setAreaCompliantError}
            setAreaScanError={setAreaScanError}
            hasOtherIdCard={hasOtherIdCard}
            otherCountryAreaError={otherCountryAreaError}
            setOtherCountryAreaError={setOtherCountryAreaError}
            handleUploadOtherCountryIdCardFile={
              handleUploadOtherCountryIdCardFile
            }
            otherCountryIdCardFrontFileInfo={otherCountryIdCardFrontFileInfo}
            otherCountryIdCardOppositetFileInfo={
              otherCountryIdCardOppositetFileInfo
            }
          />
        )}
        {currentPage === 2 && (
          <SecondPage
            inputOnChange={inputOnChange}
            memoPEP={memoPEP}
            memoWealthSource={memoWealthSource}
            memoEstimatedNetWorth={memoEstimatedNetWorth}
            memoFundSource={memoFundSource}
            memoAnnualIncome={memoAnnualIncome}
            memoAnticipatedMonthlyTxFrequency={
              memoAnticipatedMonthlyTxFrequency
            }
            handleUpLoadCRSFile={handleUpLoadCRSFile}
            uploadLoading={uploadLoading}
            crsFileInfo={crsFileInfo}
            memoPEPContact={memoPEPContact}
            setInputParams={setInputParams}
            inputParams={inputParams}
            handleMultipleCheck={handleMultipleCheck}
            handleSignleCheck={handleSignleCheck}
            setCurrentPage={setCurrentPage}
            onSumbit={onSumbit}
            setStep={setStep}
            memoAnticipatedMonthlyTxAmount={memoAnticipatedMonthlyTxAmount}
          />
        )}
      </Box>
    </Flex>
  );
};

export default PersonalInfo;
