import request from "@/hooks/useRequest";
import {
    GETEXCHANGESUPPORTEDSYMBOLS,
    GETCANSELECTCURRENCINFO,
    GETEXCHANGEORDERBOOK,
    GETEXCHANGESYMBOLINFO,
    GETUSERREMAININGTRADEAMOUNT,
    POSTEXCHANGEPRECREATEORDER,
    POSTEXCHANGECREATEORDER,
    POST_EXCHANGE_CREATE_PAY_ORDER,
} from "@/helpers/constants/requestApi";

// 获取支持的交易对
export const getSupportSymbols = () => {
    return request(GETEXCHANGESUPPORTEDSYMBOLS, {});
};

// 获取可选择的币种列表信息
export const getCanSelectedCurrencyList = () => {
    return request(GETCANSELECTCURRENCINFO, {});
};

// 获取用户剩余额度
export const getRemainAmount = () => {
    return request(GETUSERREMAININGTRADEAMOUNT, {});
}

// 获取OrderBook
export const getOrderBook = params => {
    return request(GETEXCHANGEORDERBOOK, { params });
};

// 获取交易对信息
export const getSymbolInfo = params => {
    return request(GETEXCHANGESYMBOLINFO, { params });
}

// 预创建订单
export const requestPreCreateOrder = body => {
    return request(POSTEXCHANGEPRECREATEORDER, { body });
}

// 创建支付订单
export const requestCreatePayOrder = body => {
    return request(POST_EXCHANGE_CREATE_PAY_ORDER, { body });
}

// 创建订单
export const requestCreateOrder = body => {
    return request(POSTEXCHANGECREATEORDER, { body });
}