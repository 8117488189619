import request from "@/hooks/useRequest";
import {
  POSTEMAILVERIFY,
  POSTLOGINPASSWORDRESET,
  POSTEMAILCODEGET,
  POSTVALIDATIONITEMS,
  POSTSENDCODE,
  POSTGAVERIFY,
  POSTPAYMENTPASSWORDRESET,
  POSTRESETPHONE,
  POSTVERIFYPHONE,
  GETUSERINFO,
  POSTAUTHPHONE,
  GETAREALIST,
  POSTGEEVALIDATE,
} from "@/helpers/constants/requestApi";
import { debounce } from "lodash";
import { toastTip } from "@/components/ui/toast";
//邮箱发送验证码
export const postSendEmail = (body) => {
  return request(POSTEMAILCODEGET, { body });
};

//发送手机验证码
export const postSendPhone = (body) => {
  return request(  POSTSENDCODE,{ body });
};

//验证手机
export const postVerifyPhone = (body) => {
  return request(POSTVERIFYPHONE,{ body });
}
//重置手机验证
export const postAuthPhone = (body)=>{
  return request(POSTAUTHPHONE,{ body });
} 

//重置手机
export const postResetPhone = (body) => {
  return request(POSTRESETPHONE,{ body });
}

//验证邮箱code码
export const postEmailVerify = (body) => {
  return request(POSTEMAILVERIFY, { body });
};

//重置登录密码
export const postResetPwd = (body) => {
  return request(POSTLOGINPASSWORDRESET, { body });
};

//重置支付密码
export const postResetPayPwd = (body) => {
  return request(POSTPAYMENTPASSWORDRESET, { body });
};

//需要的安全要素
export const postValidationItemFunc = (body) => {
  return request(POSTVALIDATIONITEMS, { body });
};

// GA验证
export const requestGoogleAuthVerify = body => {
  return request(POSTGAVERIFY, { body });
}

//获取用户信息
export const getUserInfo = (params) => {
  return request(GETUSERINFO,{params})
}

//获取国家和区号
export const getAreaList = (params)=>{
  return request(GETAREALIST,{params})
}


const LANG_MAP = {
  en: "eng",
  zh: "zho",
  tw: "zho-tw",
};

const getLang = () => {
  const currentLan = localStorage.getItem("bx_curLang");
  return LANG_MAP[currentLan];
};

const getParamsFromValueType = (val) => {
  const _type = typeof val;

  if (_type === "string") return { email: val };
  if (val && _type === "object") return val;

  return {};
};

const secondVerifyRequest = (
  { lot_number, captcha_output, pass_token, gen_time },
  { val }
) => {
  console.log("data_from_captcha", {
    lot_number,
    captcha_output,
    pass_token,
    gen_time,
  });
  const body = {
    lot_number,
    captcha_output,
    pass_token,
    gen_time,
    ...getParamsFromValueType(val),
  };

  return request(POSTGEEVALIDATE, { body }); //调后端接口验证
};

const secondVerify = (captchaObj, initData, afterVerified) => {
  captchaObj
    .onReady(() => {
      captchaObj.showCaptcha();
      window.geetest_captchaObj = captchaObj;
    })
    .onSuccess(async () => {
      const res = await secondVerifyRequest(captchaObj.getValidate(), initData);
      window.onGeetestting = false;

      if (res.data.ok) {
        afterVerified();
      } else {
        toastTip("验证失败，请重新验证或刷新页面重试", {
          type: "error",
        });
        captchaObj.reset();
      }
    })
    .onError((error) => {
      toastTip("出错啦，请刷新页面重试", { type: "error" });
      console.log("jeetest error", error);
      captchaObj.reset();
      window.onGeetestting = false;
    })
    .onClose(() => {
      window.onGeetestting = false;
    });
};

export const geetestFlow = debounce(
  async (val, afterVerified) => {
   
     if (window.onGeetestting) return;
    window.onGeetestting = true;


    window.initGeetest4(
      {
        captchaId: "9d84819e6f01b70ab17851a3250626a2",
        product: "bind",
        next_width: "252px",
        language: getLang(),
      },
      (captchaObj) => {
        secondVerify(captchaObj, { val }, afterVerified);
      }
    );
  },
  1000,
  {
    leading: true,
    trailing: false,
  }
);
