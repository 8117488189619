import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  kycType: "", // 个人或机构 person / company
  kycInfo: [],
};

const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    updateKyc(state, action) {
      state.kycType = action.payload;
    },
    updateKycInfo(state, action) {
      // state.kycInfo = [
      //   {
      //     step: 1,
      //     name: "information",
      //     status: "APPROVED",
      //     reason: "",
      //   },
      //   {
      //     step: 2,
      //     name: "identification",
      //     status: "APPROVED",
      //     reason: "",
      //   },
      //   {
      //     step: 3,
      //     name: "proof_address",
      //     status: "APPROVED",
      //     reason: "",
      //   },
      //   {
      //     step: 4,
      //     name: "financial_asset",
      //     status: "REQUIRED",
      //     reason: "",
      //   },
      //   {
      //     step: 5,
      //     name: "risk_questionnaire",
      //     status: "APPROVED",
      //     reason: "",
      //   },
      //   {
      //     step: 6,
      //     name: "asset_questionnaire",
      //     status: "APPROVED",
      //     reason: "",
      //   },
      //   {
      //     step: 7,
      //     name: "pi_evaluation",
      //     status: "APPROVED",
      //     reason: "",
      //   },
      //   {
      //     step: 8,
      //     name: "authentication",
      //     status: "REQUIRED",
      //     reason: "",
      //   },
      // ];
      state.kycInfo = action.payload;
    },
    // updateSelectedAccountInfo(state, { payload: { accontInfo } }) {
    //   state.transfer.inner.accontInfo = accontInfo;
    // },
    // updateSelectedCurrentAccountType(
    //   state,
    //   { payload: { currentAccountType } }
    // ) {
    //   state.transfer.inner.currentAccountType = currentAccountType;
    // },
  },
});

export const { updateKyc, updateKycInfo } = kycSlice.actions;

export default kycSlice.reducer;
