import request from "@/hooks/useRequest";
import { GETUSERINFO, GETPROCESSINFO } from "@/helpers/constants/requestApi";

// 投资风险测评问卷
export const getQuestionnaire = (type) => {
  return request("/web-wallet/api/v1/kyc/questionnaire/get", {
    method: "POST",
    body: {
      category: type, // RISK投资风险 ASSET_KNOWLEDGE虚拟知识
    },
  });
};

// 答题
export const postQuestionAnswer = (data) => {
  return request("/web-wallet/api/v1/kyc/questionnaire/answer", {
    method: "POST",
    body: data,
  });
};

// PI
export const postPIEvaluation = (data) => {
  return request("/web-wallet/api/v1/kyc/pi_evaluation/create", {
    method: "POST",
    body: data,
  });
};

//获取用户信息
export const getUserInfo = (params) => {
  return request(GETUSERINFO, { params });
};

//获取kyc进程信息
export const getProcessInfo = (params) => {
  return request(GETPROCESSINFO, { params });
};

export const getS3PutUrl = (params) => {
  return request("/web-wallet/api/v1/s3/get-private-put-url", { params });
};
