import { Box, Flex, Text, Input, Checkbox, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { postEmailCheckFunc, getIpCheckFunc } from "./api";
import { toastTip } from "@/components/ui/toast";
import { useDispatch } from "react-redux";
import { updateRegisterMsg } from "@/state/user/reducer";
import {
  BIXIN_LOGIN,
  BIXIN_PRIVACYPLICE,
  BIXIN_AGREEMENT,
} from "@/helpers/constants/routeName";
import { useMedia } from "@/hooks/useMedia";
import { useNavigate } from "react-router-dom";
import { useI18n } from "@/hooks/useI18n";
import { emailValid, passwordValid } from "@/utils/regexp";
import useVPNCheck from "@/hooks/useVPNCheck";

const Ready = (props) => {
  const { setRegisterStep } = props;
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [rePwd, setRePwd] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidPwd, setIsInvalidPwd] = useState(false);
  const [isInvalidRePwd, setIsInvalidRePwd] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [isIPAllow, setIPIsAllow] = useState(true);
  const [isOpenVPn, setIsOpenVpn] = useState(false);
  const { isMobile } = useMedia();
  let local_lang = localStorage.getItem("bx_curLang");
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { t } = useI18n();
  const { checkVPN } = useVPNCheck();

  // eslint-disable-next-line no-useless-escape
  const vpnCheck = async () => {
    const isOpenVPN = await checkVPN();
    if (isOpenVPN) {
      toastTip(t("npetoxnFYOYxRjXRN"), { type: "error" });
      setIsOpenVpn(true);
    } else {
      setIsOpenVpn(false);
    }
  };
  const checkIPAllowed = async () => {
    const res = await getIpCheckFunc({});
    setIPIsAllow(res.data.ok);
  };

  useEffect(() => {
    checkIPAllowed();
    vpnCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailChange = async (event) => {
    const email = event.target.value;
    if (email) {
      if (emailValid(email)) {
        const checkEmail = await postEmailCheckFunc({ email: email });
        if (checkEmail.code === 0) {
          setIsInvalidEmail(false);
          setEmail(email);
        } else if (checkEmail.code === 500) {
          setEmail("");
          setIsInvalidEmail(true);
          setEmailErrorText(checkEmail.message);
        } else if (checkEmail.code === 400) {
          setEmail("");
          setIsInvalidEmail(true);
          setEmailErrorText(t("sydmFvhfSgzJVhtAud"));
        }
      } else {
        setEmailErrorText(t("PIxkcsUxBzUPVVFfu"));
        setIsInvalidEmail(true);
      }
    } else {
      setIsInvalidEmail(false);
    }
  };

  const pwdChange = (event) => {
    const pwd = event.target.value;
    setPwd(pwd);
    if (pwd) {
      if (passwordValid(pwd)) {
        setIsInvalidPwd(false);
      } else {
        setIsInvalidPwd(true);
      }
    } else {
      setIsInvalidPwd(true);
    }
  };

  const rePwdChange = (event) => {
    const rePwd = event.target.value;
    setRePwd(rePwd);
    if (rePwd) {
      if (rePwd === pwd) {
        setIsInvalidRePwd(false);
      } else {
        setIsInvalidRePwd(true);
      }
    } else {
      setIsInvalidRePwd(true);
    }
  };

  const checkboxChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);
  };

  const submitRegister = () => {
    if (isChecked) {
      if (!email) {
        return setIsInvalidEmail(true);
      }
      if (!pwd || isInvalidPwd) {
        return setIsInvalidPwd(true);
      }
      if (!rePwd || isInvalidRePwd) {
        return setIsInvalidRePwd(true);
      }
      if (pwd !== rePwd) {
        return setIsInvalidRePwd(true);
      }
      setRegisterStep("1");
      dispatch(
        updateRegisterMsg({
          registerMsg: {
            email,
            pwd,
          },
        })
      );
    } else {
      toastTip(t("aYdhyaHCqMJLweGFD"), { type: "error" });
    }
  };

  const toLogin = () => {
    navigator(BIXIN_LOGIN);
  };

  return (
    <div>
      <Flex justifyContent={"center"} mt={["100px", "200px"]} mb={"50px"}>
        <Box w={["80%", "400px"]}>
          <Text fontSize="32px" fontWeight={"600"} color="t.100">
            {t("wjeaGDSVTuLLjJGehN")}
          </Text>
          <Box mt="50px">
            <Text fontSize={"14px"}>{t("TVTktEvtpSXRbnxIB")}</Text>
            <Input
              placeholder={t("leXQdnNvhrYWetVTXjE")}
              mt="5px"
              fontSize="12px"
              h="44px"
              type="email"
              isInvalid={isInvalidEmail}
              bg={isInvalidEmail ? "red.50" : "none"}
              errorBorderColor="red.100"
              _placeholder={{ color: "t.50" }}
              onBlur={emailChange}
            />
          </Box>
          {isInvalidEmail && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {emailErrorText}
            </Text>
          )}

          <Box mt="20px">
            <Text fontSize={"14px"}>{t("txfROteTwdaWdTnlkif")}</Text>
            <Input
              placeholder={t("KbSjSRobgQmXjHChGKJ")}
              mt="5px"
              fontSize="12px"
              h="44px"
              _placeholder={{ color: "t.50" }}
              type="password"
              isInvalid={isInvalidPwd}
              bg={isInvalidPwd ? "red.50" : "none"}
              errorBorderColor="red.100"
              onBlur={pwdChange}
            />
          </Box>

          {isInvalidPwd && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {t("SMEPVJNtMGWMrEceo")}
            </Text>
          )}
          <Box mt="20px">
            <Text fontSize={"14px"}>{t("TAFoyaQLwVzluFioqh")}</Text>
            <Input
              placeholder={t("eChfcikkuQFNcZULZuP")}
              mt="5px"
              fontSize="12px"
              h="44px"
              type="password"
              isInvalid={isInvalidRePwd}
              bg={isInvalidRePwd ? "red.50" : "none"}
              errorBorderColor="red.100"
              _placeholder={{ color: "t.50" }}
              onBlur={rePwdChange}
            />
          </Box>
          {isInvalidRePwd && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {t("jgXHbpNLgJMKuuZER")}
            </Text>
          )}
          {isMobile && local_lang === "en" ? (
            <Box>
              <Checkbox
                mt="20px"
                borderColor={"t.100"}
                borderRadius={"6px"}
                onChange={checkboxChange}
              >
                <Text fontSize="12px">{t("JWDRcPebwmpIhvJhVrJe")}</Text>
              </Checkbox>
              <Flex
                fontSize="12px"
                color="t.100"
                justifyContent={"flex-start"}
                mt="10px"
              >
                <Text
                  color="blue.100"
                  onClick={() => {
                    window.open(BIXIN_AGREEMENT, "_blank");
                  }}
                >
                  {t("UtQJNokaxJhxqqTQDiO")}
                </Text>
                {t("TFAtjCZbFqacobpGxct")}
                <Text
                  color="blue.100"
                  onClick={() => {
                    window.open(BIXIN_PRIVACYPLICE, "_blank");
                  }}
                >
                  {t("EokACCilfAZfuGcXD")}
                </Text>
              </Flex>
            </Box>
          ) : (
            <Box>
              <Checkbox
                mt="20px"
                borderColor={"t.100"}
                borderRadius={"6px"}
                onChange={checkboxChange}
              >
                <Flex
                  fontSize="12px"
                  color="t.100"
                  justifyContent={"flex-start"}
                >
                  {t("JWDRcPebwmpIhvJhVrJe")}
                  <Text
                    color="blue.100"
                    onClick={() => {
                      window.open(BIXIN_AGREEMENT, "_blank");
                    }}
                  >
                    {t("UtQJNokaxJhxqqTQDiO")}
                  </Text>
                  {t("TFAtjCZbFqacobpGxct")}
                  <Text
                    color="blue.100"
                    onClick={() => {
                      window.open(BIXIN_PRIVACYPLICE, "_blank");
                    }}
                  >
                    {t("EokACCilfAZfuGcXD")}
                  </Text>
                </Flex>
              </Checkbox>
            </Box>
          )}

          <Box mt="50px">
            <Button
              borderRadius={"20px"}
              w={["100%", "400px"]}
              h="44px"
              fontSize="14px"
              bg={isIPAllow && !isOpenVPn ? "blue.100" : "t.50"}
              color="#fff"
              _hover={{ bg: isIPAllow && !isOpenVPn ? "blue.80" : "" }}
              onClick={() => {
                if (isIPAllow && !isOpenVPn) {
                  submitRegister();
                }
              }}
            >
              {t("wjeaGDSVTuLLjJGehN")}
            </Button>
            {!isIPAllow && (
              <Text
                color="red.100"
                fontSize="12px"
                mt="15px"
                textAlign={"center"}
              >
                {t("TzvXfzahEvyWlLOZQE")}
              </Text>
            )}
          </Box>
          <Flex
            justifyContent={"center"}
            mt="30px"
            alignItems={"center"}
            fontSize="12px"
          >
            <Text color="t.100">{t("UXURgENTpgxogbFAD")}</Text>
            <Text color="blue.100" cursor={"pointer"} onClick={toLogin}>
              {t("ElbSFPkaecjnmBToovei")}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
};

export default Ready;
