import { useAppSelector } from "@/state/hooks";
import { useState, useEffect, useMemo } from "react";
import { useI18n } from "@/hooks/useI18n";
import { getAreaList } from "./api";
import {
  // postUpload,
  postProofOfAddress,
  getUserInfo,
  getProcessInfo,
  postUploadFinancial,
  getS3PutUrl,
} from "./api";
import { useDispatch } from "react-redux";
import { updateKycInfo } from "@/state/kyc";
import { updateUserInfo } from "@/state/user/reducer";
import { toastTip } from "@/components/ui/toast";
import { getKycPerosonalInfoApi } from "./PersonalInfo/api";
import { S3_AWS_KMS_KEY_ID } from "@/config";
import axios from "axios";

const useCommonHooks = () => {
  const [allCountryList, setAllCountryList] = useState([]); // 所有地区
  const [sanctionsCountryList, setSanctionsCountryList] = useState([]); // 制裁地区
  const [noCompliantCountryList, setNoCompliantCountryList] = useState([]); // 非合规
  const dispatch = useDispatch();

  const getAllAreaList = async () => {
    const res = await getAreaList();
    if (res.code === 0) {
      const countryList = [...res.data.areas];
      countryList.forEach((item, idx) => {
        item.selectName = item.iso_code2;
        item.value = idx;
      });
      setAllCountryList(countryList);
      setSanctionsCountryList(res.data.sanction_areas);
      setNoCompliantCountryList(res.data.non_compliant_areas);
    }
  };

  const fetchGetProcessInfo = async () => {
    const res = await getProcessInfo({});
    if (res.code === 0) {
      dispatch(updateKycInfo(res.data.process));
    }
  };

  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };

  useEffect(() => {
    getAllAreaList();
    fetchGetProcessInfo();
    getUserInfoFn();
  }, []);

  return {
    allCountryList,
    sanctionsCountryList,
    noCompliantCountryList,
  };
};

// 身份证明
const useVerifyIdentity = () => {
  const { t } = useI18n();
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);
  const [verifyIdentityStep, setVerifyIdentityStep] = useState("SELECT"); //  SELECT  JUMIO
  const [isSameAddress, setIsSameAddress] = useState(true);
  const [reAddressError, setReAddressError] = useState({});
  const [identityData, setIdentityData] = useState(null);
  const dispatch = useDispatch();

  const fetchGetProcessInfo = async () => {
    const res = await getProcessInfo({});
    if (res.code === 0) {
      dispatch(updateKycInfo(res.data.process));
    }
  };

  const getPersonalInfo = async () => {
    const res = await getKycPerosonalInfoApi();
    if (res.code === 0) {
      const {
        country,
        id_type,
        first_name,
        former_name,
        last_name,
        birth_date,
      } = res.data;

      setIdentityData({
        country,
        id_type,
        first_name,
        former_name,
        last_name,
        birth_date,
      });
    }
  };

  useEffect(() => {
    fetchGetProcessInfo();
    getPersonalInfo();
  }, []);

  useEffect(() => {
    if (kycInfo?.length > 0) {
      const status = kycInfo[1]?.status;
      if (status === "REQUIRED") {
        setVerifyIdentityStep("SELECT");
      } else {
        setVerifyIdentityStep("JUMIO");
      }
    }
  }, [kycInfo]);

  const memoCardList = useMemo(() => {
    return [
      { name: t("puvbUtnTezlmHPfbVAqn"), value: "PASSPORT" },
      { name: t("HOpNrycyzpeOCvcWPwAM"), value: "ID_CARD" },
    ];
  }, [t]);

  const resetForm = () => {
    setVerifyIdentityStep("SELECT");
  };

  return {
    memoCardList,
    kycInfo,
    verifyIdentityStep,
    setVerifyIdentityStep,
    resetForm,
    isSameAddress,
    setIsSameAddress,
    reAddressError,
    setReAddressError,
    identityData,
  };
};

// 地址证明
const useVerifyAddress = () => {
  const { t } = useI18n();
  const [checkList, setCheckList] = useState([1, 2]); // 默认勾选常居地一致 和 电子结算单
  const [emailAddress, setEmailAddress] = useState(""); // 邮寄地址
  const [reAddress, setReAddress] = useState(""); // 常居地址
  const [errorMap, setErrorMap] = useState({});
  const [filePath, setFilePath] = useState(""); // 文件地址
  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading
  const [validFileType, setValidFileType] = useState(true); // 文件类型校验
  const [fileName, setFileName] = useState(""); // 上传文件名
  const [viewStatus, setViewStatus] = useState("INIT"); // INIT PENDING PREVIEW
  const [showTipModal, setShowModalTip] = useState(false); //
  const [country, setCountry] = useState(""); // 常居地
  const [otherCountry, setOtherCountry] = useState(""); // 常居地
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [idType, setIDType] = useState("");
  const dispatch = useDispatch();

  const getPersonalInfo = async () => {
    const res = await getKycPerosonalInfoApi();
    if (res.code === 0) {
      const { country, id_type, other_country } = res.data;
      setCountry(country);
      setOtherCountry(other_country);
      setIDType(id_type);
    }
  };

  const fetchGetProcessInfo = async () => {
    const res = await getProcessInfo({});
    if (res.code === 0) {
      dispatch(updateKycInfo(res.data.process));
    }
  };

  useEffect(() => {
    fetchGetProcessInfo();
    getPersonalInfo();
  }, []);

  useEffect(() => {
    if (checkList.indexOf(1) === -1) {
      setEmailAddress("");
    } else {
      if (reAddress) {
        setEmailAddress(reAddress);
      }
    }
  }, [checkList, reAddress]);

  useEffect(() => {
    if (kycInfo?.length > 0) {
      const status = kycInfo[2]?.status;
      if (status === "PENDING") {
        setViewStatus("PENDING");
      } else if (status === "APPROVED") {
        setViewStatus("APPROVED");
      }
    }
  }, [kycInfo]);

  const canSubmit = useMemo(() => {
    if (
      Object.keys(errorMap)?.length === 0 &&
      validFileType &&
      filePath &&
      emailAddress &&
      country
    ) {
      return true;
    }
    return false;
  }, [errorMap, validFileType, filePath, emailAddress, country]);

  const memoEmailAddressList = useMemo(() => {
    return [
      {
        text: t("lvvtaxnLzNOtFCZllw"),
        type: 1,
      },
      {
        text: t("XUWTeclWoXtMrQkJzuuq"),
        type: 2,
      },
    ];
  }, [t]);

  const handleFile = (e) => {
    const file = e.target.files[0];
    const { size, name } = file;
    const fileNameSuffix = name
      .split(".")
      [name.split(".").length - 1].toLocaleLowerCase();
    if (
      size / 1000000 < 5 &&
      (fileNameSuffix === "pdf" || fileNameSuffix === "jpg")
    ) {
      setValidFileType(true);
      uploadFn(file, fileNameSuffix);
    } else {
      setValidFileType(false);
    }

    setFileName(name);
  };

  const uploadFn = async (file, fileNameSuffix) => {
    setUploadLoading(true);
    const res = await getS3PutUrl({
      prefix: "proof_address",
      file_type: fileNameSuffix,
    });
    if (res.code === 0) {
      const putUrl = res?.data?.put_url;
      const filePath = res?.data?.file_path;
      axios({
        url: putUrl,
        method: "put",
        headers: {
          "X-Amz-Server-Side-Encryption": "aws:kms",
          "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
        },
        data: file,
      }).then((uploadRes) => {
        if (uploadRes.status === 200) {
          setFilePath(filePath);
          setUploadLoading(false);
        }
      });
    }
  };

  const onSubmit = async () => {
    setErrorMap({});
    if (!reAddress) {
      setErrorMap({ reAddress: true });
      return;
    }
    if (!emailAddress) {
      setErrorMap({ emailAddress: true });
      return;
    }

    if (!validFileType) {
      return;
    }

    if (isSubmiting) return;

    setIsSubmiting(true);
    const params = {
      country: otherCountry || country,
      address: reAddress,
      mailing_address: emailAddress,
      document_path: filePath,
      // 测试的文件地址  "https://apixdev.bixin.com/proof_address/2024/01/22/SJZJPImVguQkNDR_2023_11_15_bx_u7342161293.pdf",
      is_electronic_bill: checkList.indexOf(2) > -1 ? true : false,
    };
    try {
      const res = await postProofOfAddress(params);
      if (res.code === 0) {
        setViewStatus("PENDING");
        setIsSubmiting(false);
      }
    } catch (e) {
      setIsSubmiting(false);
    }
  };

  return {
    memoEmailAddressList,
    checkList,
    setCheckList,
    emailAddress,
    setEmailAddress,
    setReAddress,
    reAddress,
    errorMap,
    setErrorMap,
    filePath,
    uploadLoading,
    handleFile,
    onSubmit,
    fileName,
    validFileType,
    viewStatus,
    showTipModal,
    setShowModalTip,
    setCountry,
    country,
    canSubmit,
    setViewStatus,
    idType,
    otherCountry,
  };
};

// 财务资料
export const useAssetsHooks = () => {
  const { t } = useI18n();
  const fileObj = {
    fileName: "",
    error: false,
    filePath: "",
    fileType: {
      text: t("mWaNsXTgUIcnWSphpgB"),
      value: 1,
    },
    fileTypeValue: 1,
  };
  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading
  const [upLoadList, setUpLodList] = useState([fileObj]);
  const [viewStaus, setViewStatus] = useState("INIT");
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const canSubmit = useMemo(() => {
    if (
      upLoadList.length > 0 &&
      upLoadList[0]?.filePath &&
      upLoadList.every((d) => !d.error)
    ) {
      return true;
    }
    return false;
  }, [upLoadList]);

  const memoFileType = useMemo(() => {
    return [
      {
        text: t("mWaNsXTgUIcnWSphpgB"),
        value: 1,
      },
      {
        text: t("GxjDaNmTVZaIEmLIO"),
        value: 2,
      },
      {
        text: t("xdBmFzKPmVQfqVSOyFY"),
        value: 3,
      },
      {
        text: t("KSyKpOAjkLSpCGns"),
        value: 4,
      },
    ];
  }, [t]);

  useEffect(() => {
    if (kycInfo?.length > 0) {
      const status = kycInfo[3]?.status;
      if (status === "PENDING") {
        setViewStatus("PENDING");
      }
    }
  }, [kycInfo]);

  const handleAssetsFile = async (type, e, index) => {
    const file = e.target.files[0];

    if (file) {
      const { size, name } = file;
      const fileTypeSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (type === "assets") {
        if (
          size / 1000000 < 15 &&
          (fileTypeSuffix === "pdf" || fileTypeSuffix === "jpg")
        ) {
          await uploadAssetsFn(type, file, index, fileTypeSuffix);
          setUpLodList((prev) => {
            const list = [...prev];
            const item = list[index];
            item.error = false;
            list[index] = item;
            return list;
          });
        } else {
          setUpLodList((prev) => {
            const list = [...prev];
            const item = list[index];
            item.error = true;
            list[index] = item;
            return list;
          });
        }

        setUpLodList((prev) => {
          const list = [...prev];
          const item = list[index];
          item.fileName = name;
          list[index] = item;
          return list;
        });
      }
    }
  };

  const uploadAssetsFn = async (type, file, index, fileTypeSuffix) => {
    setUploadLoading(true);
    const res = await getS3PutUrl({
      prefix: "financial_asset",
      file_type: fileTypeSuffix,
    });
    if (res.code === 0) {
      const putUrl = res?.data?.put_url;
      const filePath = res?.data?.file_path;
      axios({
        url: putUrl,
        method: "put",
        headers: {
          "X-Amz-Server-Side-Encryption": "aws:kms",
          "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
        },
        data: file,
      }).then((uploadRes) => {
        if (uploadRes.status === 200) {
          setUploadLoading(false);
          if (type === "assets") {
            setUpLodList((prev) => {
              const currList = [...prev];
              const item = currList[index];
              item.filePath = filePath;
              currList[index] = item;
              return currList;
            });
          }
        }
      });
    }
  };

  const fileTypeChange = (i, e) => {
    const _value = e.target.value;
    const newUpList = [...upLoadList];
    const item = newUpList[i];
    item.fileType = memoFileType.filter(
      (d) => String(d.value) === String(_value)
    )[0];

    item.fileTypeValue = _value;
    setUpLodList(newUpList);
  };

  const delOrAddFileItem = (type, index) => {
    setUpLodList((pre) => {
      const currList = [...upLoadList];
      if (type === "add") {
        currList.push(fileObj);
        return currList;
      } else if (type === "delete") {
        if (pre.length > 1) {
          currList.splice(index, 1);
          return currList;
        }
        return currList;
      }
    });
  };

  const onSubmit = async () => {
    if (!canSubmit) return;
    if (isSubmiting) return;
    setIsSubmiting(true);

    if (upLoadList?.length > 0) {
      const fileTypeValueArr = upLoadList.map((d) => d.fileTypeValue);
      if (!fileTypeValueArr.some((d) => Number(d) < 4)) {
        toastTip(t("xdSXcpFurEbSenWcxPn"), { type: "error" });
        setIsSubmiting(false);
        return;
      }
    }

    const params = upLoadList
      .filter((d) => d.filePath)
      .map((d) => {
        const fileNameArr = d.fileName.split(".");
        return {
          document_path: d.filePath,
          document_type:
            fileNameArr[fileNameArr.length - 1].toLocaleLowerCase(),
          document_type_comment: d.fileType?.text,
        };
      });

    const postParams = {
      documents: params,
    };

    try {
      const res = await postUploadFinancial(postParams);
      if (res.code === 0) {
        setViewStatus("PENDING");
        setIsSubmiting(false);
      }
    } catch (e) {
      setIsSubmiting(false);
    }
  };

  return {
    onSubmit,
    setUpLodList,
    upLoadList,
    handleAssetsFile,
    memoFileType,
    fileTypeChange,
    delOrAddFileItem,
    uploadLoading,
    viewStaus,
    canSubmit,
  };
};

export { useVerifyIdentity, useVerifyAddress, useCommonHooks };
