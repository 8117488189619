import {
  Box,
  Flex,
  Text,
  Button,
  Select,
  Image,
  Input,
} from "@chakra-ui/react";
import { ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toastTip } from "@/components/ui/toast";
import BXLoading from "@/components/ui/pay-loading";
import { useI18n } from "@/hooks/useI18n";
import { toFixed } from "@/utils/bigNumber";
import { useMedia } from "@/hooks/useMedia";
import PageWrap from "@/components/PageWrap";

import {
  getTransferAccounts,
  getAssetsInfo,
  postBx2SpotsTransferBxConfirm,
  postBx2SpotsTransferSpotsConfirm,
  postSpots2BxTransfer,
  postBx2SpotsTransfer,
  getMeta,
} from "./api";

const Transfer = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const [leftValue, setLeftValue] = useState("fund");
  const [rightValue, setRightValue] = useState("spots");
  const [coinBalance, setCoinBalance] = useState("");
  const [hasBalance, setHasBalance] = useState("-");
  const [hasBalanceSpots, setHasBalanceSpots] = useState("-");
  const [showSelect, setShowSelect] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const selectRef = useRef(null);
  const [coinList, setCoinList] = useState([]);
  const { isMobile } = useMedia();

  const handleSelect = () => {
    if (showSelect) {
      setShowSelect(false);
    } else {
      setShowSelect(true);
    }
  };

  const getDecimalPlaces = (num) => {
    const match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
      return 0; // 如果不是有效的数字，小数点位数为0
    }
    return Math.max(
      0,
      (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
    );
  };

  const changeCoinSelect = (obj) => {
    if (coinList.length === 0) return;
    const newCoinList = [...coinList];
    newCoinList.forEach((item) => {
      if (obj.coinName === item.coinName) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    getBalance();
    getSpotsBalance();
    setCoinList(newCoinList);
    setCoinBalance("");
    setShowSelect(false);
  };
  const leftSelectHandle = (e) => {
    if (e.target.value === "fund") {
      setRightValue("spots");
      getBalance();
    } else {
      setRightValue("fund");
      getSpotsBalance();
    }
    setLeftValue(e.target.value);
    setCoinBalance("");
  };

  const rightSelectHandle = (e) => {
    if (e.target.value === "fund") {
      setLeftValue("spots");
      getSpotsBalance();
    } else {
      setLeftValue("fund");
      getBalance();
    }
    setRightValue(e.target.value);
    setCoinBalance("");
  };

  const exchangeSelect = () => {
    if (leftValue === "fund") {
      setLeftValue("spots");
      setRightValue("fund");
      getSpotsBalance();
      setCoinBalance("");
    } else {
      setLeftValue("fund");
      setRightValue("spots");
      getBalance();
      setCoinBalance("");
    }
  };

  const currentCoinName = () => {
    if (coinList.length === 0) return;
    const currentCoin = coinList.filter((item) => {
      return item.checked === true;
    });
    return currentCoin[0].coinName || "BTC";
  };
  const currentCoinIcon = () => {
    if (coinList.length === 0) return;
    const currentCoin = coinList.filter((item) => {
      return item.checked === true;
    });
    return currentCoin[0].coinIcon;
  };

  // const delayChange = debounce((value)=>{
  //   console.log(value)
  //   if(value >= 0){
  //     setCoinBalance(value);
  //   }
  // },500)

  const handleInput = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setCoinBalance(value);
    } else {
      setCoinBalance("");
    }
  };
  const handleOnFocus = () => {
    setIsFocus(true);
  };
  const handleBlurInput = (e) => {
    try {
      setIsFocus(false);
      const value = e.target.value;
      if (value >= 0) {
        if (leftValue === "fund") {
          if (JSON.parse(value) <= JSON.parse(hasBalance)) {
            setCoinBalance(value);
          } else {
            toastTip(t("MOtoFwFvpTtTEDVUD"), { type: "error" });
            setCoinBalance(hasBalance);
          }
        } else {
          if (JSON.parse(value) <= JSON.parse(hasBalanceSpots)) {
            setCoinBalance(value);
          } else {
            toastTip(t("MOtoFwFvpTtTEDVUD"), { type: "error" });
            setCoinBalance(hasBalanceSpots);
          }
        }
      }
    } catch (e) {
      setCoinBalance("");
    }
  };

  const allBalance = () => {
    if (leftValue === "fund") {
      if (hasBalance > 0) {
        setCoinBalance(hasBalance);
      }
    } else {
      if (hasBalanceSpots > 0) {
        setCoinBalance(hasBalanceSpots);
      }
    }
  };

  const getBalance = async () => {
    //调接口获取fund余额
    const fundRes = await getAssetsInfo({});
    const coin = currentCoinName();
    console.log("select-coin====", coin);
    if (fundRes.code === 0) {
      fundRes.data?.currency_asset_List.forEach((item) => {
        if (item.name === coin) {
          const amount =
            getDecimalPlaces(item.amount) > 8
              ? toFixed(item.amount, 8)
              : item.amount;
          setHasBalance(amount);
        }
      });
    }
    console.log("fundRes===", fundRes);
  };

  const getSpotsBalance = async () => {
    //获取spots余额
    const spotsRes = await getTransferAccounts({});
    const coin = currentCoinName();
    if (spotsRes.ok) {
      if (spotsRes.data[coin]) {
        const amount =
          getDecimalPlaces(spotsRes.data[coin][0]) > 8
            ? toFixed(spotsRes.data[coin][0], 8)
            : spotsRes.data[coin][0];
        setHasBalanceSpots(amount);
      } else {
        setHasBalanceSpots(0);
      }
    }
    console.log("spotsRes===", spotsRes);
  };

  const transferConfirm = async () => {
    if (coinBalance <= 0)
      return toastTip(t("UheALJaSjOHgmVnoCrI"), { type: "error" });
    console.log("coinBalance", coinBalance);
    const coin = currentCoinName();
    setPayLoading(true);
    if (leftValue === "fund") {
      const bx2SpotsTransfer = postBx2SpotsTransfer({
        sourceId: JSON.stringify(+new Date()),
        currency: coin,
        amount: coinBalance,
        exType: "SPOTS",
      });
      bx2SpotsTransfer.then((res) => {
        if (res.data?.transactionId) {
          handleBxConfirm(res);
        } else {
          toastTip(res.message, { type: "error" });
        }
        setPayLoading(false);
      });
    } else {
      //spots2bx
      const spots2bx = postSpots2BxTransfer({
        sourceId: JSON.stringify(+new Date()),
        currency: coin,
        amount: coinBalance,
        transferFrom: "SPOTS",
        transferTo: "WALLET",
      });
      spots2bx.then((res) => {
        if (res.data?.transferId) {
          toastTip(t("fNFAnFLpEoAIoPfbh"));
          getBalance();
          getSpotsBalance();
          setCoinBalance("");
        } else {
          toastTip(res.message, { type: "error" });
        }
        setPayLoading(false);
      });
      console.log("spots2bx====", spots2bx);
    }
  };

  //bx 二次确认
  const handleBxConfirm = async (firstRes) => {
    const bxConfirm = await postBx2SpotsTransferBxConfirm({
      transactionId: firstRes.data?.transactionId,
    });
    // bxConfirm.then((bxConfirmRes) => {
    console.log("**bxConfirm**", bxConfirm);
    if (bxConfirm?.data?.status === "SUCCESS") {
      transfer2Confirm(firstRes);
    }
    // });
  };

  //递归调用划转spots二次确认
  const transfer2Confirm = async (firstRes) => {
    const bx2SpotsTransferConfirm = await postBx2SpotsTransferSpotsConfirm({
      transactionId: firstRes.data.transactionId,
      sourceId: firstRes.data.sourceId,
      currency: firstRes.data.currency,
      amount: firstRes.data.amount,
    });
    if (bx2SpotsTransferConfirm.data.status === "PENDING_CONFIRM") {
      toastTip(t("fNFAnFLpEoAIoPfbh"));
      getBalance();
      getSpotsBalance();
      setCoinBalance("");
      setPayLoading(false);
    } else {
      setPayLoading(false);
      return transfer2Confirm(firstRes);
    }
  };

  const getMetaInfo = async () => {
    const res = await getMeta();
    console.log("getMetaInfo", res);
    if (res.ok) {
      const newCoinList = [];

      res.data.currencies.forEach((item) => {
        const obj = {};
        obj["coinName"] = item.name;
        obj["checked"] = item.name === "BTC" ? true : false;
        obj["coinIcon"] = item.iconUrl;
        newCoinList.push(obj);
      });
      setCoinList(newCoinList);
      getBalance();
      getSpotsBalance();
    }
  };

  useEffect(() => {
    getMetaInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinList.length]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShowSelect(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <PageWrap>
      <Flex alignItems={"center"} justifyContent={"center"} mt="40px">
        <Box w={["100%", "1200px"]} minHeight={1000}>
          <Button
            borderRadius={32}
            bg="rgba(32, 26, 85, 0.05)"
            padding="0 20px"
            fontSize="14px"
            fontWeight={"normal"}
            ml="10px"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
            {t("WbPTckCFMKtPHgAlL")}
          </Button>
          <Flex justifyContent={"center"} mt="60px">
            <Box w={["90%", "500px"]} textAlign={"center"}>
              <Text fontSize={32} fontWeight={600} mb="40px">
                {t("tjsCbqUvgNvcdRqdDp")}
              </Text>
              <Flex justifyContent={"space-between"}>
                <Select
                  w={["150px", "200px"]}
                  h={"60px"}
                  borderRadius={"12px"}
                  value={leftValue}
                  onChange={leftSelectHandle}
                >
                  <option value="fund">{t("udmfKDblDABqFbQPK")}</option>
                  <option value="spots">{t("AysRHzzIQTbjLUdgdaq")}</option>
                </Select>
                <Image
                  src="../wwwsite/images/transfer/exchange.svg"
                  cursor={"pointer"}
                  onClick={exchangeSelect}
                />
                <Select
                  w={["150px", "200px"]}
                  h={"60px"}
                  borderRadius={"12px"}
                  value={rightValue}
                  onChange={rightSelectHandle}
                >
                  <option value="fund">{t("udmfKDblDABqFbQPK")}</option>
                  <option value="spots">{t("AysRHzzIQTbjLUdgdaq")}</option>
                </Select>
              </Flex>
              {isMobile ? (
                <>
                  <Box>
                    {" "}
                    <Flex justifyContent={"flex-start"} mt="10px">
                      <Flex fontSize={"16px"} color="#010313">
                        <Text color="#7B7C8F" whiteSpace={"nowrap"}>
                          {t("udmfKDblDABqFbQPK")}：
                        </Text>
                        <Text fontWeight={"bold"}>
                          {hasBalance}
                          {currentCoinName()}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                  <Box>
                    {" "}
                    <Flex justifyContent={"flex-start"} mt="10px">
                      <Flex fontSize={"16px"} color="#010313">
                        <Text color="#7B7C8F" whiteSpace={"nowrap"}>
                          {t("AysRHzzIQTbjLUdgdaq")}：
                        </Text>
                        <Text fontWeight={"bold"}>
                          {hasBalanceSpots}
                          {currentCoinName()}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                </>
              ) : (
                <Flex mt="15px">
                  <Box w="50%">
                    {" "}
                    <Flex justifyContent={"flex-start"} mt="10px">
                      <Flex fontSize={"16px"} color="#010313">
                        <Text color="#7B7C8F">{t("HYTGqldMNoESWJswz")}：</Text>
                        <Text fontWeight={"bold"}>
                          {leftValue === "fund" ? hasBalance : hasBalanceSpots}{" "}
                          {currentCoinName()}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                  <Box w="50%">
                    {" "}
                    <Flex justifyContent={"flex-end"} mt="10px">
                      <Flex fontSize={"16px"} color="#010313">
                        <Text color="#7B7C8F">{t("HYTGqldMNoESWJswz")}：</Text>
                        <Text fontWeight={"bold"}>
                          {rightValue === "fund" ? hasBalance : hasBalanceSpots}
                          {currentCoinName()}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              )}

              <Box
                mt="15px"
                lineHeight={"60px"}
                border={isFocus ? "1px solid #4A3DE6" : "1px solid #e2e8f0"}
                borderRadius={"12px"}
              >
                <Flex alignItems={"center"}>
                  <Box
                    // w={["30%", "110px"]}
                    h={"60px"}
                    border="none"
                    position="relative"
                    fontSize={"14px"}
                  >
                    <Box pl="20px" ref={selectRef} mr="20px">
                      <Flex
                        cursor="pointer"
                        alignItems={"center"}
                        onClick={handleSelect}
                      >
                        <Image src={currentCoinIcon()} mr="5px" w="20px" />
                        {` ${currentCoinName()}`}
                        <ChevronDownIcon />
                      </Flex>
                    </Box>
                    {showSelect && (
                      <Box
                        padding="10px 5px"
                        bg="#fff"
                        borderRadius={"12px"}
                        position="absolute"
                        zIndex="1"
                      >
                        {coinList.map((item, idx) => {
                          return (
                            <Flex
                              value={item?.coinName}
                              key={idx}
                              cursor="pointer"
                              fontSize={"14px"}
                              h="40px"
                              padding="10px"
                              alignItems={"center"}
                              mb="10px"
                              borderRadius={"10px"}
                              _hover={{
                                bg: "rgba(32, 26, 85, 0.05)",
                              }}
                              onClick={() => {
                                changeCoinSelect(item);
                              }}
                            >
                              <Image src={item?.coinIcon} mr="5px" w="20px" />
                              {item?.coinName}
                            </Flex>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                  <Box color="#dfe0ee" fontSize={"20px"} fontWeight={"normal"}>
                    |
                  </Box>
                  <Input
                    w={["40%", "280px"]}
                    h={"60px"}
                    textAlign={"right"}
                    fontWeight={"bold"}
                    fontSize={"16px"}
                    border="0"
                    placeholder="0"
                    _focusVisible={{ boxShadow: "none" }}
                    value={coinBalance}
                    onChange={handleInput}
                    onBlur={handleBlurInput}
                    onFocus={handleOnFocus}
                  />
                  <Text ml="10px" mr="10px" w={["15%", "auto"]}>
                    {currentCoinName()}
                  </Text>
                  <Text
                    mr="10px"
                    color="#333BF5"
                    cursor={"pointer"}
                    onClick={allBalance}
                    w={["15%"]}
                  >
                    {t("SywvArMjCuuGzTEtlJ")}
                  </Text>
                </Flex>
              </Box>

              <Button
                w="350px"
                mt="40px"
                h="44px"
                lineHeight={"44px"}
                fontSize="14px"
                bg="#333BF5"
                color="#fff"
                borderRadius={"30px"}
                _hover={{ bg: "#4B5DFF" }}
                onClick={transferConfirm}
              >
                {t("AQrCocFqoKoYieUnT")}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
      {payLoading && <BXLoading />}
    </PageWrap>
  );
};

export default Transfer;
