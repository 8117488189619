import { useState, useEffect } from 'react'

/**
 * 请求改轮询(组件销毁会自动停止轮询,调用endPolling也可主动停止轮询)
 * @param cb 要进行轮询的请求,cb函数返回true继续轮询,返回false或throw error停止轮询
 * @param time 轮询间隔时间 s
 * @returns [startPolling, endPolling] 轮询开始函数，轮询停止函数
*/
const usePolling = (cb, time = 3) => {
    // 倒计时秒数
    const [seconds, setSeconds] = useState(-1);

    useEffect(() => {
        if (seconds >= 0) {
            const intervalId = setInterval(() => {
                // console.log('定时器-轮询中');
                setSeconds(seconds - 1);
            }, 1000);
            if (seconds === 0) {
                clearInterval(intervalId)
                onTimeout();
            }
            return () => clearInterval(intervalId);
        } else {
            // console.log('定时器-轮询暂停');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);

    const onTimeout = () => {
        // console.log('订定时器-时间到');
        // pollingOrderInfo();
        cb().then(res => {
            // console.log('订定时器-时间到', res);
            if (res) {
                startPolling();
            } else {
                endPolling();
            }
        })
    };
    const startPolling = () => setSeconds(time);
    const endPolling = () => setSeconds(-1);
    return [startPolling, endPolling];
}

export { usePolling };