
import enCommon from "./common/en.json";
import zhCommon from "./common/zh.json";
import twCommon from './common/tw.json';
import enBill from './bill/en.json';
import zhBill from './bill/zh.json';
import twBill from './bill/tw.json';

const en = {
    ...enCommon,
    ...enBill,
}
const zh = {
    ...zhCommon,
    ...zhBill,
}
const tw = {
    ...twCommon,
    ...twBill,
}

export { en, zh, tw };
