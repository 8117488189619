import PropTypes from "prop-types";
import { useAppSelector } from "@/state/hooks";
import { useRef } from "react";
import { Image, Text, Box, useMediaQuery, Button } from "@chakra-ui/react";
import TableUnit from "@/pages/historyBill/component/ui/tableUnit";
import PaginationDom from "@/components/ui/pagination";
import { useI18n } from "@/hooks/useI18n";
import {
  getTitle,
  getTime,
  getAmount,
} from "@/pages/historyBill/helpers/func/billShowData";
import { useNavigate } from "react-router-dom";

// FROZEN 冻结验证地址 待验证地址
// FROZEN_CONFIRMED  充值失败彻底冻结
// ADMIN_REQUIRED  审核中
const BillList = (props) => {
  const { t } = useI18n();
  const _lang = useAppSelector((state) => state.user.language);
  const {
    billList,
    currentPage,
    currentTotalPage,
    getDetailEvent,
    turnPageEvent,
  } = props;
  // 小于739
  const [isSmaller] = useMediaQuery("(max-width: 739px)");
  const billListRef = useRef(null);
  const navigate = useNavigate();

  // 展示订单详情
  const clickViewDetail = (getObject) => {
    getDetailEvent(getObject);
  };

  const turnThePage = (getValue) => {
    turnPageEvent && turnPageEvent(getValue);
  };

  const leftContentDom = (item) => {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Image
          borderRadius="full"
          boxSize="30px"
          src={item.icon_url}
          fallbackSrc="../wwwsite/images/transfer/header_icon.svg"
          alt="currencyIcon"
          mr="10px"
        />
        <Text>{getTitle(item, _lang, t)}</Text>
        {/* 待验证地址 */}
        {item.status === "FROZEN" && (
          <Button
            h="30px"
            flexShrink={0}
            fontSize="12px"
            color="#fff"
            borderRadius={"20px"}
            bg="blue.100"
            ml={["10px", "30px"]}
            padding={"4px 10px"}
            textAlign={"center"}
            _hover={{ bg: "blue.80" }}
            mt="5px"
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `/trade/verify/txAddress?address=${item.transaction_id}`
              );
            }}
          >
            {t("BbqJaDHRCDmiNwNVcGB")}
          </Button>
        )}
        {/* 充值 失败 */}
        {item.status === "FROZEN_CONFIRMED" &&
          item.transfer_status === "in" && (
            <Button
              h="30px"
              flexShrink={0}
              fontSize="12px"
              color="#D04457"
              borderRadius={"6px"}
              bg="#FCEDEF"
              ml={["5px", "10px"]}
              padding={"4px 10px"}
              textAlign={"center"}
              _hover={{ bg: "#FCEDEF" }}
              mt="5px"
            >
              {t("KzgGszjJLuRsOKsdkH")}
            </Button>
          )}
      </Box>
    );
  };
  const AmountDom = (item) => {
    return (
      <Text
        textAlign="right"
        color={
          item.transfer_status === "out"
            ? "red.100"
            : item.status === "FROZEN" || item.status === "ADMIN_REQUIRED"
            ? "#F89753"
            : ""
        }
      >
        {item?.status === "FROZEN_CONFIRMED" && item.transfer_status === "in"
          ? "-"
          : getAmount(item)}{" "}
        {item?.status !== "FROZEN_CONFIRMED" && item.currency}
      </Text>
    );
  };
  return (
    <Box w="100%" h={`calc(100% - 74px)`}>
      {!isSmaller && (
        <TableUnit
          py="30px"
          TableStyle={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#7B7C8F",
            borderWidth: "1px",
            borderTopRadius: "30px",
          }}
          noHover={true}
          tableContentArr={[
            {
              name: t("dTLgwTGGXjpsnLhrpr"),
              style: {
                flex: 1,
              },
            },
            {
              name: t("iuswsIRQwNAIuOzoFX"),
              style: {
                w: "30%",
                textAlign: "right",
              },
            },
            {
              name: t("NOIPqSfkgPcsNJnyAFA"),
              style: {
                w: "30%",
                textAlign: "right",
              },
            },
          ]}
        />
      )}
      <div
        className={`scroll-table-style ${
          billList.length === 0 ? "scroll-table-style-content-center" : ""
        }`}
      >
        {billList.length > 0 ? (
          <Box ref={billListRef}>
            {billList.map((item) => {
              return (
                <Box key={item.uuid}>
                  <TableUnit
                    keyId={item.uuid}
                    TableStyle={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#232325",
                    }}
                    tableContentArr={[
                      {
                        name: leftContentDom(item),
                        style: {
                          flex: 1,
                        },
                      },
                      !isSmaller && {
                        name: getTime(
                          item.bill_created_at,
                          "YYYY-MM-DD HH:mm:ss"
                        ),
                        style: {
                          w: "30%",
                          textAlign: "right",
                          color: "#7B7C8F",
                        },
                      },
                      {
                        name: AmountDom(item),
                        style: {
                          w: isSmaller ? "40%" : "30%",
                          textAlign: "right",
                        },
                      },
                    ]}
                    clickEvent={() => clickViewDetail(item)}
                  />
                </Box>
              );
            })}
            {currentTotalPage > 1 && (
              <Box w="100%" py="20px">
                <PaginationDom
                  page={currentPage}
                  totalPage={currentTotalPage}
                  onChange={turnThePage}
                  containerRef={billListRef}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <Text
              fontSize={"14px"}
              fontWeight={400}
              color={"#010313"}
              textAlign={"center"}
              my={"100px"}
            >
              {t("omUetmzxCLiTloCSalU")}
            </Text>
          </Box>
        )}
      </div>
    </Box>
  );
};

BillList.propTypes = {
  billList: PropTypes.array,
  currentPage: PropTypes.number,
  currentTotalPage: PropTypes.number,
  getDetailEvent: PropTypes.func,
  turnPageEvent: PropTypes.func,
};

export default BillList;
