import {
  Flex,
  Box,
  Text,
  Input,
  Center,
  Button,
  Image,
} from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { useMemo, useState, useEffect } from "react";
import PageWrap from "@/components/PageWrap";
import { useNavigate } from "react-router-dom";
import { useMedia } from "@/hooks/useMedia";
import { postCompanyInfo, getS3PutUrl, getCompanyProcessInfoApi } from "./api";
import { useAppSelector } from "@/state/hooks";
import BXLoading from "@/components/ui/pay-loading";
import { toastTip } from "@/components/ui/toast";
import "./index.scss";
import axios from "axios";
import { S3_AWS_KMS_KEY_ID } from "@/config";

const CreateCompanyAccount = () => {
  const { t } = useI18n();
  const navigator = useNavigate();
  const [downLink, setDownLink] = useState("");
  const { isMobile } = useMedia();
  const [status, setStatus] = useState("INIT");
  const lang = useAppSelector((state) => state.user.language);
  // INIT PENDING FAILED SUCCESS
  const [fileInfo, setFileInfo] = useState({
    open: {},
    crs: {},
  });
  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading
  // downlink
  const [techLink, setTechLink] = useState("");
  // crs link
  const [crsLink, setCrsLink] = useState("");

  useEffect(() => {
    // 开户表格
    const maps = {
      zh: "https://file.bixin.com/kyc/information/C_V1.3_S.pdf",
      tw: "https://file.bixin.com/kyc/information/C_V1.3_T.pdf",
      en: "https://file.bixin.com/kyc/information/C_V1.3_T.pdf",
    };
    // 教程
    const tech_maps = {
      zh: "https://support.bixin.com/hc/zh-hk/articles/25552461493779-%E5%A6%82%E4%BD%95%E9%80%B2%E8%A1%8C%E6%B3%95%E5%9C%98-%E6%A9%9F%E6%A7%8B%E8%BA%AB%E4%BB%BD%E8%AA%8D%E8%AD%89",
      tw: "https://support.bixin.com/hc/zh-hk/articles/25552461493779-%E5%A6%82%E4%BD%95%E9%80%B2%E8%A1%8C%E6%B3%95%E5%9C%98-%E6%A9%9F%E6%A7%8B%E8%BA%AB%E4%BB%BD%E8%AA%8D%E8%AD%89",
      en: "https://support.bixin.com/hc/en-hk/articles/25552655748499-How-to-Perform-Corporation-Institution-Verification",
    };

    // crs表格
    const crs_maps = {
      zh: "https://file.bixin.com/kyc/information/FATCA_and_CRS_Entity_Self-Certification_Form_sc.pdf",
      tw: "https://file.bixin.com/kyc/information/FATCA_and_CRS_Entity_Self-Certification_Form_tc.pdf",
      en: "https://file.bixin.com/kyc/information/FATCA_and_CRS_Entity_Self-Certification_Form.pdf",
    };
    setDownLink(maps[lang]);
    setTechLink(tech_maps[lang]);
    setCrsLink(crs_maps[lang]);
  }, [lang]);

  const getCompanyProcessInfo = async () => {
    const res = await getCompanyProcessInfoApi();
    if (res.code === 0) {
      const maps = {
        REQUIRED: "INIT",
        PENDING: "PENDING",
        DENIED: "FAILED",
        APPROVED: "SUCCESS",
      };
      setStatus(maps[res.data.status]);
    }
  };

  useEffect(() => {
    getCompanyProcessInfo();
  }, []);

  const canSubmit = useMemo(() => {
    const { crs, open } = fileInfo;
    if (
      crs?.document_path &&
      open?.document_path &&
      !crs?.error &&
      !open?.error
    ) {
      return true;
    }
    return false;
  }, [fileInfo]);

  const handleFile = async (e, type) => {
    // type: open, crs
    const file = e.target.files[0];
    if (file) {
      const { name, size } = file;
      const fileTypeSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (fileTypeSuffix === "pdf" && size / 1000000 < 15) {
        // setFileInfo({
        //   fileName: name,
        // });

        setFileInfo((prev) => {
          return Object.assign({}, prev, {
            [type]: {
              fileName: name,
            },
          });
        });
        await uploadAssetsFn(file, name, fileTypeSuffix, type);
      } else {
        setFileInfo((prev) => {
          return Object.assign({}, prev, {
            [type]: {
              fileName: name,
              error: true,
            },
          });
        });
        // setFileInfo({ error: true, fileName: name });
      }
    }
  };

  const uploadAssetsFn = async (file, name, fileTypeSuffix, type) => {
    setUploadLoading(true);
    const res = await getS3PutUrl({
      prefix: "company_v2",
      file_type: fileTypeSuffix,
    });
    if (res.code === 0) {
      const putUrl = res?.data?.put_url;
      const filePath = res?.data?.file_path;
      axios({
        url: putUrl,
        method: "put",
        headers: {
          "X-Amz-Server-Side-Encryption": "aws:kms",
          "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
        },
        data: file,
      }).then((uploadRes) => {
        if (uploadRes.status === 200) {
          setFileInfo((prev) => {
            return Object.assign({}, prev, {
              [type]: {
                document_path: filePath,
                error: false,
                fileName: name,
              },
            });
          });
          setUploadLoading(false);
        } else {
          // setFileInfo({ error: false });
          setFileInfo((prev) => {
            return Object.assign({}, prev, {
              [type]: {
                error: false,
              },
            });
          });
        }
      });
    }
  };

  const onSubmit = async () => {
    const params = {
      documents: [
        {
          document_type_comment: "opening",
          document_path: fileInfo?.open?.document_path,
        },
        {
          document_type_comment: "fatca_crs",
          document_path: fileInfo?.crs?.document_path,
        },
      ],
    };
    const res = await postCompanyInfo(params);
    if (res.code === 0) {
      toastTip(t("nodIQIicrpcVpCqYey"), { type: "success" });
      setStatus("PENDING");
      // navigator("/trade/kyc");
    }
  };

  const renderInitView = () => {
    return (
      <>
        {uploadLoading && <BXLoading />}
        <Flex
          background={"rgba(235, 237, 245, 1)"}
          borderRadius={"12px"}
          padding={"10px"}
          mt={"40px"}
          alignItems={"center"}
        >
          <Text
            fontSize={"12px"}
            color={"#333BF5"}
            cursor={"pointer"}
            textDecoration={"underline"}
            onClick={() => window.open(techLink, "_blank")}
          >
            {t("qsVtkTgbKcuLSPDXf")}
          </Text>
          <Text fontSize={"12px"} ml={"3px"} color={"t.10"}>
            {t("OARZuIqYLXtlrvaEsC")}
          </Text>
        </Flex>
        {/* 法团/机构开户表格 */}
        <Flex alignItems={"center"} mt={"40px"}>
          <Text color={"#000"} fontSize={"16px"} fontWeight={500}>
            {t("NhBzijnKrdihICUwgi")}
          </Text>
          <Flex
            ml={"30px"}
            border={"1px solid rgba(32, 26, 85, 0.05)"}
            borderRadius={"8px"}
            padding={"5px 10px"}
            alignItems={"center"}
          >
            <Image src="../../wwwsite/images/kyc/certificate/pdf.svg" />
            <a href={downLink} target="_blank" rel="noreferrer">
              <Box
                bg={"#EDEFFC"}
                borderRadius={"8px"}
                ml={"10px"}
                cursor={"pointer"}
                padding={"3px 10px"}
              >
                <Text color={"#333BF5"} fontSize={"12px"}>
                  {t("ctaxFEnYnZIrAtOaYc")}
                </Text>
              </Box>
            </a>
          </Flex>
        </Flex>
        <Box position="relative">
          <Flex
            justifyContent={"space-between"}
            mt="10px"
            alignItems={"center"}
          >
            <Input
              w="80px"
              type="file"
              opacity={"0"}
              position={"absolute"}
              right="0"
              zIndex={"2"}
              cursor="pointer"
              fontSize="0"
              border={"1px solid red"}
              onChange={(e) => {
                handleFile(e, "open");
              }}
            />
            <Input
              w={["220px", "310px"]}
              disabled
              height={"44px"}
              borderRadius={"12px"}
              _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
              value={fileInfo?.open?.fileName}
            />
            <Button
              w="80px"
              h="30px"
              fontSize="12px"
              color="#fff"
              borderRadius={"20px"}
              bg="blue.100"
              textAlign={"center"}
              _hover={{ bg: "blue.80" }}
              ml={isMobile ? "10px" : ""}
            >
              {t("NqgOQEXQaHsramCnbX")}
            </Button>
          </Flex>
          <Text
            color={fileInfo?.open?.error ? "red.100" : "#7B7C8F"}
            fontSize={"12px"}
            mt={"5px"}
          >
            {t("tMlPwcyXKotAImFCGfQ")}
          </Text>
        </Box>

        {/* FATCA和CRS自我证明表格 */}
        <Flex alignItems={"center"} mt={"40px"}>
          <Text color={"#000"} fontSize={"16px"} fontWeight={500}>
            {t("DrmqiYKPSoxMBWXT")}
          </Text>
          <Flex
            ml={"30px"}
            border={"1px solid rgba(32, 26, 85, 0.05)"}
            borderRadius={"8px"}
            padding={"5px 10px"}
            alignItems={"center"}
          >
            <Image src="../../wwwsite/images/kyc/certificate/pdf.svg" />
            <a href={crsLink} target="_blank" rel="noreferrer">
              <Box
                bg={"#EDEFFC"}
                borderRadius={"8px"}
                ml={"10px"}
                cursor={"pointer"}
                padding={"3px 10px"}
              >
                <Text color={"#333BF5"} fontSize={"12px"}>
                  {t("ctaxFEnYnZIrAtOaYc")}
                </Text>
              </Box>
            </a>
          </Flex>
        </Flex>
        <Box position="relative">
          <Flex
            justifyContent={"space-between"}
            mt="10px"
            alignItems={"center"}
          >
            <Input
              w="80px"
              type="file"
              opacity={"0"}
              position={"absolute"}
              right="0"
              zIndex={"2"}
              cursor="pointer"
              fontSize="0"
              onChange={(e) => {
                handleFile(e, "crs");
              }}
            />
            <Input
              w={["220px", "310px"]}
              disabled
              height={"44px"}
              borderRadius={"12px"}
              _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
              value={fileInfo?.crs?.fileName}
            />
            <Button
              w="80px"
              h="30px"
              fontSize="12px"
              color="#fff"
              borderRadius={"20px"}
              bg="blue.100"
              textAlign={"center"}
              _hover={{ bg: "blue.80" }}
              ml={isMobile ? "10px" : ""}
            >
              {t("NqgOQEXQaHsramCnbX")}
            </Button>
          </Flex>
          <Text
            color={fileInfo?.crs?.error ? "red.100" : "#7B7C8F"}
            fontSize={"12px"}
            mt={"5px"}
          >
            {t("tMlPwcyXKotAImFCGfQ")}
          </Text>
        </Box>

        {/* 底部按钮 */}
        <Flex justifyContent={"center"} mt="60px" mb={"50px"}>
          <Button
            mr="10px"
            fontSize="14px"
            padding="0 30px"
            w={["30%", "100px"]}
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              navigator("/trade/kyc");
            }}
          >
            {t("FrzWGSiDSDzTfNgZH")}
          </Button>
          <Button
            fontSize="14px"
            padding="0 30px"
            w={["60%", "200px"]}
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            _hover={{ bg: canSubmit ? "blue.80" : "t,50" }}
            cursor={canSubmit ? "pointer" : "not-allowed"}
            bg={canSubmit ? "blue.100" : "t.50"}
            h="44px"
            onClick={() => {
              if (canSubmit) {
                onSubmit();
              }
            }}
          >
            {t("YChcfaxDaLscdSPiRQz")}
          </Button>
        </Flex>
      </>
    );
  };

  const renderResultView = () => {
    const maps = {
      FAILED: {
        imgPath: "../../wwwsite/images/kyc/certificate/denied.svg",
        text: t("LDNvolWeNGwNyvpTMnL"),
      },
      SUCCESS: {
        imgPath: "../../wwwsite/images/kyc/certificate/done.svg",
        text: t("AHMRWIOaHFIWiIPqUx"),
      },
      PENDING: {
        imgPath: "../../wwwsite/images/kyc/certificate/pending.svg",
        text: t("KzFzOytWXScqDRKFwg"),
      },
    };
    const info = maps[status];
    return (
      <Box
        w={["100%", "400px"]}
        margin={"0 auto"}
        mt={"60px"}
        borderRadius={"12px"}
        textAlign={"center"}
        fontWeight={"bold"}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src={info.imgPath}
            width={"40px"}
            height={"40px"}
            alt=""
          ></Image>
          <Text
            fontSize={"24px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {info.text}
          </Text>
          {status === "PENDING" && (
            <Text
              fontSize={"14px"}
              fontWeight={600}
              color={"#010313"}
              mt={"10px"}
            >
              {t("MkfqKEzRwLTbzJEOeWg")}
            </Text>
          )}

          {status === "FAILED" && (
            <span
              className="company-kyc-result-xadkpt"
              style={{ marginTop: "40px" }}
              dangerouslySetInnerHTML={{ __html: t("uYLqhJrdDgRVWcNvDpr") }}
            ></span>
          )}

          <Button
            mr="10px"
            fontSize="14px"
            padding="0 30px"
            w={["50%", "100px"]}
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            mt={"40px"}
            cursor={"pointer"}
            onClick={() => {
              navigator("/trade/kyc");
            }}
          >
            {t("FrzWGSiDSDzTfNgZH")}
          </Button>
        </Flex>
      </Box>
    );
  };

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        pt={"60px"}
      >
        <Flex justifyContent={"center"}>
          <Box w={["80%", "400px"]}>
            <Center>
              <img src="../wwwsite/images/user-square.svg" alt="icon" />
              <Text fontSize="32px" fontWeight={"600"} color="t.100" ml="13px">
                {t("HryFxrLzCqTGOVNrpI")}
              </Text>
            </Center>
            {status === "INIT" && renderInitView()}
            {status !== "INIT" && renderResultView()}
          </Box>
        </Flex>
      </Box>
    </PageWrap>
  );
};

export default CreateCompanyAccount;
