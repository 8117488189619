// KYC
export const KYC_TYPE = {
  CORPORATION: "CORPORATION", // 法团
  PERSONAL: "PERSONAL", // 个人 => 普通认证
  PI: "PI", // 个人 => 高级认证
  UNAUTHORIZED: "UNAUTHORIZED", // 未认证
  INSTITUTION: "INSTITUTION", // 机构
  INSTITUTION_PI: "INSTITUTION_PI",
  CORPORATION_PI: "CORPORATION_PI",
};

// 账号风险灯具

export const RISK_LEVEL = {
  HIGH: "High",
  MID: "Mid",
  LOW: "Low",
};
