import PageWrap from "@/components/PageWrap";
import Start from "./Start";
import { usePerodicHooks } from "./hooks";
import InfoNoChange from "./nochange/index";
import { useAppSelector } from "@/state/hooks";
import InfoChange from "./change/index";
import { KYC_TYPE } from "@/constants/kyc";
import { useMemo, useEffect, useState } from "react";
import { getPerodicStatusApi } from "@/api/perodic";
import { updateUserInfo } from "@/state/user/reducer";
import { useDispatch } from "react-redux";
import { getUserInfoApi } from "@/api/common";
import { useSearchParams } from "react-router-dom";

const Perodic = () => {
  const { optionValue, setOptionValue } = usePerodicHooks();
  const [searchParams] = useSearchParams();
  const reviewUUID = searchParams.get("review_uuid");
  const { kyc_type, risk_level } = useAppSelector(
    (state) => state.user.userInfo
  );
  const dispatch = useDispatch();
  const [canPeriodic, setCanPeriodic] = useState(false); // 能否审阅 true才可以审阅 false代表已提交或者其他

  const getUserInfo = async () => {
    const res = await getUserInfoApi();
    if (res.code === 0) {
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };

  // 公司还是个人账号
  const accountType = useMemo(() => {
    if (kyc_type === KYC_TYPE.PERSONAL || kyc_type === KYC_TYPE.PI) {
      return "PERSONAL";
    } else if (
      kyc_type === KYC_TYPE.CORPORATION_PI ||
      kyc_type === KYC_TYPE.CORPORATION ||
      kyc_type === KYC_TYPE.INSTITUTION ||
      kyc_type === KYC_TYPE.INSTITUTION_PI
    ) {
      return "COMPANY";
    }
    return;
  }, [kyc_type]);

  const getPerodicStatus = async (reviewUUID) => {
    const res = await getPerodicStatusApi({ review_uuid: reviewUUID });
    if (res.code === 0) {
      setCanPeriodic(res?.data?.can_review);
    }
  };

  useEffect(() => {
    if (reviewUUID) {
      getPerodicStatus(reviewUUID);
    }
  }, [reviewUUID]);

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <PageWrap>
      {!optionValue && (
        <Start setOptionValue={setOptionValue} canPeriodic={canPeriodic} />
      )}
      {/* 无变更 */}
      {optionValue === 1 && (
        <InfoNoChange
          accountType={accountType}
          riskLevel={risk_level}
          canPeriodic={canPeriodic}
          reviewUUID={reviewUUID}
        />
      )}
      {/* 更新资料 */}
      {optionValue === 2 && (
        <InfoChange
          accountType={accountType}
          riskLevel={risk_level}
          canPeriodic={canPeriodic}
          reviewUUID={reviewUUID}
        />
      )}
    </PageWrap>
  );
};

export default Perodic;
