import { Box, Flex, Button, Image, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMedia } from "@/hooks/useMedia";
import { useI18n } from "@/hooks/useI18n";
import { postGetJumioUrl, postIdentification } from "../api";
import { jsUuid } from "@/helpers/func/stringPro";
import { useAppSelector } from "@/state/hooks";
import "./jumio.scss";
import { JUMIO_CALLBACK_URL } from "@/config/index";

const Jumio = (props) => {
  const { identityData, setStep, setVerifyIdentityStep } = props;

  // const [statusProcessJumio, setStatusProcessJumio] = useState("");
  const { isMobile } = useMedia();
  const [redirect_url, setRedirect_url] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [flag, setFlag] = useState(true);
  const request_id = jsUuid();
  const { t } = useI18n();
  const _lang = useAppSelector((state) => state.user.language);
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);

  useEffect(() => {
    if (kycInfo?.length > 0) {
      const idenInfo = kycInfo[1];
      // if (idenInfo.status === "PENDING") {
      //   setStatusProcessJumio("PENDING");
      // }
      // if (idenInfo.status === "DENIED") {
      //   setStatusProcessJumio("DENIED");
      // }
      if (idenInfo.status === "REQUIRED") {
        setShowIframe(true);
        getJumioUrl();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycInfo]);

  const submitIdentification = async (jumio_refer) => {
    if (identityData.first_name && jumio_refer) {
      console.log("[[identityData]]", identityData);
      const res = await postIdentification({
        id_type: identityData.id_type,
        first_name: identityData.first_name,
        last_name: identityData.last_name,
        birthday: identityData.birth_date,
        country: identityData.country,
        former_name: identityData.former_name,
        // residence_address: identityData.country,
        jumio_refer,
      });
      if (res.code === 0) {
        if (res.data.status === "PENDING") {
          setShowIframe(false);
          // setStatusProcessJumio("PENDING");
        }
      }
    }
  };

  const getJumioUrl = async () => {
    console.log("[[identityData]]", identityData);
    const res = await postGetJumioUrl({
      request_id,
      success_url: JUMIO_CALLBACK_URL,
      callback_url: JUMIO_CALLBACK_URL,
      country: identityData.country, // 证件发行地
      id_type: identityData.id_type,
    });
    if (res.code === 0) {
      const _src = res.data.redirect_url.split("&")[0];
      const src = `${_src}&locale=${_lang === "en" ? "en" : "zh-CN"}`;
      setRedirect_url(src);
    }
    window.addEventListener(
      "message",
      (e) => {
        receiveMessage(e, res.data.jumio_refer);
      },
      false
    );
  };

  const receiveMessage = (event, jumio_refer) => {
    if (event.data) {
      try {
        var data = window.JSON.parse(event.data);
        if (
          data.payload.value === "success" &&
          flag &&
          data.customerInternalReference === request_id
        ) {
          setShowIframe(false);
          submitIdentification(jumio_refer);
          setFlag(false);
        }
      } catch (e) {
        console.log("解析报错", e);
      }
    }
  };

  // const renderDeniedStatus = () => {
  //   return (
  //     <Box
  //       w={["100%", "400px"]}
  //       margin={"0 auto"}
  //       mt={"60px"}
  //       borderRadius={"12px"}
  //       textAlign={"center"}
  //       fontWeight={"bold"}
  //     >
  //       <Flex
  //         flexDirection={"column"}
  //         justifyContent={"center"}
  //         alignItems={"center"}
  //       >
  //         <Image
  //           src={`../../wwwsite/images/kyc/certificate/denied.svg`}
  //           width={"40px"}
  //           height={"40px"}
  //           alt=""
  //         ></Image>
  //         <Text
  //           fontSize={"24px"}
  //           fontWeight={600}
  //           color={"#010313"}
  //           mt={"10px"}
  //         >
  //           {t("gyneoIYPeiHgfaCY")}
  //         </Text>
  //         <span
  //           className="jumio-deny-text"
  //           style={{ marginTop: "40px" }}
  //           dangerouslySetInnerHTML={{ __html: t("FlbUChtSXKPaYQGW") }}
  //         ></span>
  //         <Button
  //           mr="10px"
  //           fontSize="14px"
  //           padding="0 30px"
  //           w={["50%", "100px"]}
  //           bg="rgba(32, 26, 85, 0.05)"
  //           color="t.100"
  //           fontWeight={"400"}
  //           borderRadius={"30px"}
  //           h="44px"
  //           mt={"40px"}
  //           cursor={"pointer"}
  //           onClick={() => {
  //             navigate("/trade/personKyc/certificate");
  //           }}
  //         >
  //           {t("FrzWGSiDSDzTfNgZH")}
  //         </Button>
  //       </Flex>
  //     </Box>
  //   );
  // };

  const renderPendingStatus = () => {
    return (
      <Box
        w={["100%", "400px"]}
        margin={"0 auto"}
        mt={"60px"}
        borderRadius={"12px"}
        textAlign={"center"}
        fontWeight={"bold"}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src={`../../wwwsite/images/kyc/certificate/pending.svg`}
            width={"40px"}
            height={"40px"}
            alt=""
          ></Image>
          <Text
            fontSize={"24px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {t("KzFzOytWXScqDRKFwg")}
          </Text>

          <Text
            fontSize={"14px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {t("YXrqAGUqprWfOCeiSGX")}
          </Text>

          <Flex justifyContent={"center"} mt="60px" mb={"50px"}>
            <Button
              mr="10px"
              fontSize="14px"
              padding="0 30px"
              w={["100%", "100px"]}
              bg="rgba(32, 26, 85, 0.05)"
              color="t.100"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={"pointer"}
              onClick={() => {
                setStep(1);
              }}
            >
              {t("FrzWGSiDSDzTfNgZH")}
            </Button>
            <Button
              fontSize="14px"
              padding="0 30px"
              w={["100%", "200px"]}
              bg="blue.100"
              color="#fff"
              fontWeight={"400"}
              borderRadius={"30px"}
              _hover={{ bg: "blue.80" }}
              h="44px"
              cursor={"pointer"}
              onClick={() => setStep(3)}
            >
              {t("tOaRBVUJHpnSgDBrGON")}
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  };

  const renderMain = () => {
    if (showIframe) {
      return (
        <Flex flexDirection={"column"} alignItems={"center"} mb={"30px"}>
          <iframe
            title="jumio"
            src={redirect_url}
            width={isMobile ? "100%" : "900px"}
            height="634px"
            allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
            allowFullScreen
          ></iframe>
          <Button
            mr="10px"
            fontSize="14px"
            padding="0 30px"
            w={["50%", "100px"]}
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            mt={"40px"}
            cursor={"pointer"}
            onClick={() => {
              setVerifyIdentityStep("SELECT");
            }}
          >
            {t("FrzWGSiDSDzTfNgZH")}
          </Button>
        </Flex>
      );
    }
    // if (!showIframe && statusProcessJumio === "DENIED") {
    //   return renderDeniedStatus();
    // }
    // if (!showIframe && statusProcessJumio === "PENDING") {
    //   return renderPendingStatus();
    // }
    if (!showIframe) {
      return renderPendingStatus();
    }
  };

  return <div>{renderMain()}</div>;
};

export default Jumio;
