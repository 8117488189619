import { useState, useEffect } from "react";

const Countdown = ({ onTimeout,secondsInit }) => {
  const [seconds, setSeconds] = useState(secondsInit);

  useEffect(() => {
    if (seconds === 0) {
      onTimeout();
    }

    const intervalId = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds, onTimeout]);

  return seconds;
}

export default Countdown;
