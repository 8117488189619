import { useEffect } from "react";
import { Box, Text, Flex, Button, useClipboard } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import PageWrap from "@/components/PageWrap";
import {
  BIXIN_AMOUNTAPPLY,
  BIXIN_ACCOUNTMANAGER,
  BIXIN_KYC,
  BIXIN_LOGIN,
} from "@/helpers/constants/routeName";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/state/hooks";
import { toastTip } from "@/components/ui/toast";
import { postLoginOutFunc } from "../api";
import { useMedia } from "@/hooks/useMedia";
import { useI18n } from "@/hooks/useI18n";
const User = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const uid = useAppSelector((state) => state.user.userInfo?.uid);
  const hasKyc = useAppSelector((state) => state.user.userInfo?.has_kyc);
  const trade_limit_status = useAppSelector(
    (state) => state.user.userInfo.trade_limit_status
  );
  const available_trade_limit = useAppSelector(
    (state) => state.user.userInfo?.available_trade_limit
  );
  const trade_limit = useAppSelector(
    (state) => state.user.userInfo?.trade_limit
  );
  const _lang = useAppSelector((state) => state.user.language);
  const { onCopy, setValue } = useClipboard("");
  const { isMobile } = useMedia();

  useEffect(() => {
    setValue(uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);
  const toAmount = () => {
    if (hasKyc) {
      navigate(BIXIN_AMOUNTAPPLY);
    } else {
      navigate(BIXIN_KYC);
    }
  };
  const toSetting = () => {
    navigate(`${BIXIN_ACCOUNTMANAGER}?current=3`);
  };
  const toAccountSafe = () => {
    navigate(`${BIXIN_ACCOUNTMANAGER}?current=1`);
  };
  const toPersonalInfo = () => {
    navigate(`${BIXIN_ACCOUNTMANAGER}?current=2`);
  };
  const toKyc = () => {
    navigate(BIXIN_KYC);
  };
  const loginOut = async () => {
    const res = await postLoginOutFunc({});
    if (res.code === 0) {
      localStorage.setItem("bx_loginStep", 1);
      localStorage.removeItem("token");
      navigate(BIXIN_LOGIN);
    }
  };
  const toHelp = () => {
    if (_lang === "en") {
      window.open("https://support.bixin.com/hc/en-hk");
    } else {
      window.open("https://support.bixin.com/hc/zh-hk");
    }
  };

  return (
    <PageWrap>
      <Box
        w={["100%", "300px"]}
        h={["100vh", "527px"]}
        bg="#fff"
        boxShadow={"0px 10px 30px rgba(0, 0, 0, 0.1);"}
        borderRadius={["0", "20px"]}
        position={"fixed"}
        right={["0", "10px"]}
        top="60px"
        pl={["0", "20px"]}
        pr={["0", "20px"]}
      >
        {isMobile && (
          <Box
            mt="30px"
            mb="20px"
            w="140px"
            h="40px"
            bg="rgba(32, 26, 85, 0.05)"
            borderRadius={"0px 32px 32px 0px"}
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              w="140px"
              h="40px"
            >
              <ArrowBackIcon />
              <Text
                fontSize="14px"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t("WbPTckCFMKtPHgAlL")}
              </Text>
            </Flex>
          </Box>
        )}
        {/* 账号和认证 */}
        <Box pl={["30px", "0px"]} pr={["30px", "0px"]}>
          {isMobile && (
            <Text color="#000" fontWeight={"600"} fontSize="24px">
              {t("YMfvOKmAFnfMwTXPfI")}
            </Text>
          )}
          <Flex justifyContent={"flex-start"} mt={["35px", "40px"]}>
            <Text color={"t.80"} fontSize={"12px"} lineHeight={"27px"}>
              {t("EOmmDCycYoHFOZOV")}：
            </Text>
            <Box
              bg="rgba(32, 26, 85, 0.05)"
              borderRadius={"6px"}
              h="27px"
              w={[_lang === "en" ? "186px" : "206px", "139px"]}
            >
              <Flex justifyContent={["space-between", "flex-start"]}>
                <Text
                  fontSize={"12px"}
                  lineHeight={"27px"}
                  pl="10px"
                  fontWeight={"600"}
                >
                  {uid}
                </Text>
                <Box
                  w="27px"
                  h="27px"
                  _hover={{ bg: "rgba(32, 26, 85, 0.05)" }}
                  borderRadius={"6px"}
                  pt="2px"
                >
                  <img
                    src="../wwwsite/images/copy.svg"
                    alt="copy"
                    onClick={() => {
                      onCopy();
                      toastTip(t("gXbirwakdsaUKBglwK"));
                    }}
                  />
                </Box>
              </Flex>
            </Box>
            <Box
              padding="0 10px"
              h="27px"
              bg={hasKyc ? "green.50" : "rgba(32, 26, 85, 0.05)"}
              borderRadius={"6px"}
              lineHeight={"27px"}
              textAlign={"center"}
              ml="17px"
            >
              {hasKyc ? (
                <Text fontSize={"12px"} color="green.100">
                  {t("ysjFKMhSQlIqXgPlz")}
                </Text>
              ) : (
                <>
                  <Text fontSize={"12px"} color="t.50" onClick={toKyc}>
                    {t("TNkXPjSKdzQcBLZPsWj")}
                  </Text>
                </>
              )}
            </Box>
          </Flex>
          {/* 额度和交易总额 */}
          <Box
            w={["100%", "260px"]}
            h="190px"
            bg="rgba(32, 26, 85, 0.05)"
            borderRadius={"12px"}
            padding="20px"
            mt="20px"
          >
            <Box>
              <Text color="t.80" fontSize={"12px"}>
                {t("FMUUecQmwAheUKwpq")}
              </Text>
              <Text color="t.100" fontWeight={"600"} fontSize="14px" mt="8px">
                {available_trade_limit?.primary_currency}{" "}
                {available_trade_limit?.amount}
              </Text>
            </Box>
            <Box h="1px" borderBottom={"1px solid #E3E4F1"} mt="10px"></Box>
            <Box position="relative">
              <Text color="t.80" fontSize={"12px"} mt="10px">
                {t("KSxzKkrfZaaHIOptelk")}
              </Text>
              <Text color="t.100" fontWeight={"600"} fontSize="14px" mt="8px">
                {trade_limit?.primary_currency} {trade_limit?.amount} /
                {t("YEPMktdEzwhrMMYKefX")}
              </Text>
            </Box>
            {trade_limit_status === "PENDING" ? (
              <Button
                mt={"10px"}
                color="#fff"
                fontSize={"12px"}
                bg="t.50"
                h="30px"
                _hover={{ bg: "t.50" }}
                borderRadius={"20px"}
              >
                {t("KzFzOytWXScqDRKFwg")}
              </Button>
            ) : (
              <Button
                mt={"10px"}
                color="#fff"
                fontSize={"12px"}
                bg="blue.100"
                h="30px"
                _hover={{ bg: "blue.80" }}
                borderRadius={"20px"}
                onClick={toAmount}
              >
                {t("IurrXFgATIlGAlejcf")}
              </Button>
            )}
          </Box>
          {/* 其他信息 */}
          <Box mt="20px">
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Flex
                w="100%"
                flexDirection={"column"}
                alignItems={"center"}
                padding="20px"
                onClick={toAccountSafe}
                bg={"rgba(32, 26, 85, 0.05)"}
                borderRadius={"12px"}
              >
                <img src="../wwwsite/images/account-safe.svg" alt="account" />
                <Text
                  mt="10px"
                  fontSize={"12px"}
                  fontWeight={"600"}
                  _hover={{ color: "blue.100" }}
                  whiteSpace={"nowrap"}
                >
                  {t("POdaGgvMxQRsisLbVcll")}
                </Text>
              </Flex>
              <Flex
                w="100%"
                ml="10px"
                flexDirection={"column"}
                alignItems={"center"}
                padding="20px"
                onClick={toPersonalInfo}
                bg={"rgba(32, 26, 85, 0.05)"}
                borderRadius={"12px"}
              >
                <img src="../wwwsite/images/person.svg" alt="person" />
                <Text
                  mt="10px"
                  fontSize={"12px"}
                  fontWeight={"600"}
                  _hover={{ color: "blue.100" }}
                  whiteSpace={"nowrap"}
                >
                  {t("qRoYPOkmAoqOZnZawkD")}
                </Text>
              </Flex>
            </Flex>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              mt="10px"
            >
              <Flex
                w="100%"
                flexDirection={"column"}
                alignItems={"center"}
                padding="20px"
                onClick={toSetting}
                bg={"rgba(32, 26, 85, 0.05)"}
                borderRadius={"12px"}
              >
                <img src="../wwwsite/images/set.svg" alt="set" />
                <Text
                  mt="10px"
                  fontSize={"12px"}
                  fontWeight={"600"}
                  _hover={{ color: "blue.100" }}
                >
                  {t("SgWXitfFwQHiWicT")}
                </Text>
              </Flex>
              <Flex
                w="100%"
                ml="10px"
                flexDirection={"column"}
                alignItems={"center"}
                padding="20px"
                bg={"rgba(32, 26, 85, 0.05)"}
                borderRadius={"12px"}
                onClick={toHelp}
              >
                <img src="../wwwsite/images/help.svg" alt="help" />
                <Text
                  mt="10px"
                  fontSize={"12px"}
                  fontWeight={"600"}
                  _hover={{ color: "blue.100" }}
                >
                  {t("LcqaZGQKcdkxNrnIcK")}
                </Text>
              </Flex>
            </Flex>
          </Box>
          {/* 退出登录 */}
          <Flex w="100%" justifyContent={"center"} mt={["50px", "0"]}>
            <Button
              w="108px"
              h="40px"
              borderRadius={"20px"}
              border="1px solid #7B7C8F"
              fontSize={"12px"}
              color="t.80"
              bg="#fff"
              _hover={{
                borderColor: "t.100",
                color: "t.100",
              }}
              onClick={loginOut}
            >
              {t("erLerUcELvDAcoUWi")}
            </Button>
          </Flex>
        </Box>
      </Box>
    </PageWrap>
  );
};

export default User;
