import HighRisk from "./highRisk";
import CompanyResult from "../../common/CompanyResult";
import { RISK_LEVEL } from "@/constants/kyc";

const CompanyInfoNoChange = ({ riskLevel, canPeriodic, reviewUUID }) => {
  return (
    <>
      {riskLevel === RISK_LEVEL.HIGH && canPeriodic && (
        <HighRisk riskLevel={riskLevel} reviewUUID={reviewUUID} />
      )}
      {((riskLevel !== RISK_LEVEL.HIGH && canPeriodic) || !canPeriodic) && (
        <CompanyResult
          canPeriodic={canPeriodic}
          isUpdate={canPeriodic}
          reviewUUID={reviewUUID}
        />
      )}
    </>
  );
};

export default CompanyInfoNoChange;
