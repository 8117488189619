import { useState, useEffect } from "react";

import PageWrap from "@/components/PageWrap";
import { Box, Text } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import ComponentBodyUnit from "@/pages/deposit/component/componentBodyUnit";
import CommonUnitStyle from "@/pages/deposit/component/commonUnitStyle";
import CommonBtnDom from "@/pages/deposit/component/commonBtn";
import AddressDetail from "@/pages/deposit/component/addressDetail";
import { getCurrencyInfoList, getReceiptInfo } from "@/pages/deposit/api";
import { useI18n } from "@/hooks/useI18n";
import FiatDepositDetail from "@/pages/deposit/component/fiatDepositDetail";

const DepositPage = () => {
  const { t } = useI18n();
  const [currenyList, setCurrenyList] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState("");
  // 充值信息列表
  const [depositInfoList, setDepositInfoList] = useState([]);
  // 当前选中的充值信息
  const [currentDepositInfo, setCurrentDepositInfo] = useState({});
  // 充值网络
  const [networkName, setNetworkName] = useState("");
  // 充值地址
  const [address, setAddress] = useState("");
  // 充值额度
  const [depositeAvailable, setAvailableLimit] = useState("");
  // 法币充值
  const [fiatDepositFlag, setFiatDepositFlag] = useState(false);

  // 法币充值支持的币种
  const fiatCurrencyList = ['HKD', 'USD'];

  // 是否是法币充值
  const isFiatDeposit = fiatCurrencyList.includes(currentCurrency);

  useEffect(() => {
    InitCurrencyList();
  }, []);
  const InitCurrencyList = async () => {
    const { code, data, msg } = await getCurrencyInfoList({ scene: "deposit" });
    console.log("代币信息列表返回", code, data, msg);
    if (code === 0 && msg === "OK") {
      let newArr = [];
      data.currency_list.forEach((item) => {
        newArr.push({
          ...item,
          name: item.name,
          selected_key: item.name,
        });
      });
      setCurrenyList(newArr);
    }
  };

  useEffect(() => {
    if(!isFiatDeposit) {
      requestReceiptInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCurrency]);
  const requestReceiptInfo = async () => {
    if (currentCurrency) {
      const { code, data, msg } = await getReceiptInfo({
        currency: currentCurrency,
      });
      console.log("收款信息列表", code, data, msg);
      if (code === 0 && msg === "OK") {
        let newArr = [];
        data.currency_network_info.forEach((item) => {
          newArr.push({
            ...item,
            name: item.network_name,
            selected_key: item.network_name,
          });
        });
        setDepositInfoList(newArr);
        setAvailableLimit(data?.available_limit);
      }
    }
  };

  useEffect(() => {
    const { network_name } = currentDepositInfo;
    setNetworkName(network_name || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDepositInfo]);

  const selectedCurrency = (getName) => {
    setCurrentCurrency(getName);
    setDepositInfoList([]);
    setCurrentDepositInfo({});
    setNetworkName("");
  };

  const selectedNet = (getName) => {
    if (getName) {
      const newArr = depositInfoList.filter((item) => {
        return getName === item.name;
      });
      console.log("获取到的新数组", newArr);
      setCurrentDepositInfo(newArr[0]);
    } else {
      setCurrentDepositInfo({});
    }
  };

  const clickResetEdit = () => {
    setAddress("");
  };

  const clickNext = () => {
    if (canNext()) {
      if(isFiatDeposit) {
        setFiatDepositFlag(true);
      }
      setAddress(currentDepositInfo.address);
    }
  };
  const canNext = () => {
    if(isFiatDeposit) {
      return Boolean(currentCurrency);
    }
    return Boolean(networkName && currentCurrency);
  };

  const depositContentDom = () => {
    if(fiatDepositFlag) {
      return (
          <FiatDepositDetail
              currentCurrency={currentCurrency}
          />
      );
    }
    if(address) {
      return (
          <AddressDetail
              currentCurrency={currentCurrency}
              networkName={networkName}
              address={address}
              getAddressInfo={currentDepositInfo}
              clickResetEdit={clickResetEdit}
              depositeAvailable={depositeAvailable}
          />
      );
    }
    return (
        <Box>
          <CommonUnitStyle
              title={t("WYhIabGPkCnbVBIPcwW")}
              selectContent={currenyList}
              selectPlaceholder={t("LFipKWcCwIamrrato")}
              selectedValue={currentCurrency}
              selectedEvent={selectedCurrency}
              leftLineBg={isFiatDeposit ? "transparent" : "#333BF5"}
          />
          {!isFiatDeposit && (
              <CommonUnitStyle
                  title={t("RFoMDsIwGyJlPkuuxrON")}
                  selectContent={depositInfoList}
                  selectPlaceholder={t("bpvYuERgedcoMzzLNl")}
                  selectedValue={networkName}
                  leftLineHeight={"44px"}
                  leftLineBg="transparent"
                  selectedEvent={selectedNet}
              />
          )}
          <Box pl={"10px"}>
            {canNext() && !isFiatDeposit && (
                <Box
                    borderRadius="12px"
                    bgColor="rgba(32, 26, 85, 0.05)"
                    display="flex"
                    alignItems="center"
                    p="10px"
                    mt="10px"
                >
                  <WarningIcon mr="3px"></WarningIcon>
                  <Text fontSize="12px">{t("NsvJYqLAVHAaWNUXAvz")}</Text>
                </Box>
            )}
            <CommonBtnDom
                marginTop={isFiatDeposit ? "0" : "50px"}
                btnText={t("tOaRBVUJHpnSgDBrGON")}
                noCanClick={!canNext()}
                clickEvent={clickNext}
            />
          </Box>
        </Box>
    );
  };

  return (
    <div className="deposit-page">
      <PageWrap>
        <ComponentBodyUnit bodyWidth={"400px"}>
          <>
            <Text
              fontSize={["24px", "32px"]}
              fontWeight={"600"}
              textAlign={"center"}
            >
              {fiatDepositFlag ? t("UrkVGOIalMNhKwpIEzK") : t("gTYMvovCwafJTrjdlKg")}
            </Text>
            <Box w="100%" mt={fiatDepositFlag ? ["20px", "40px"] : ["20px", "53px"]} pb="80px">
              {depositContentDom()}
            </Box>
          </>
        </ComponentBodyUnit>
      </PageWrap>
    </div>
  );
};

export default DepositPage;
