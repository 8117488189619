import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import AddressTypeItem from "./components/AddressTypeItem";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import LabelText from "./components/LabelText";
import { toastTip } from "@/components/ui/toast";
import QRCode from "qrcode.react";
import copyToClipboard from "copy-to-clipboard";
import StatusCard from "./components/StatusCard";
import { isMobile } from "react-device-detect";
import VerifyCEX from "./VerifyCEX";
import {
  WHITELIST_AUTH_TYPE,
  WHITELIST_CATEGORY,
  WHITELIST_STEPS,
} from "../consts";
import {
  generatePersonalSignMessage,
  getWhiteListVaspList,
  whiteListAddressSignVerify,
  verifyCEXWhiteListCreate,
  whiteListAddressTransferCreate,
  getTransferInfo,
} from "../api";
import PasswordVerify from "./components/PasswordVerify";
import { postPaymentPwdVerify } from "@/pages/bindGA/api";
import "./verify.scss";

const Veriify = ({
  addressName,
  setStep,
  verifyMethods,
  verifyType,
  setVerifyType,
  network,
  whitelistUuid,
  address,
  addressType,
  whitelistVaspDid,
  setWhitelistVaspDid,
  currency,
  usedKeyList,
}) => {
  const { t } = useI18n();
  const navigator = useNavigate();
  const [connected, setConnected] = useState(false);
  const [agreeTransfer, setAgreeTransfer] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [innerhtml, setInnerhtml] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [authTypes, setAuthTypes] = useState(verifyMethods);
  const [transferInfo, setTransferInfo] = useState({});
  const [vaspList, setVaspList] = useState([]);
  const [connecting, setConnecting] = useState(false);
  const [statusCardData, setStatusCardData] = useState({
    visible: false,
    status: "sign_error",
  });
  const [verifyPwdData, setVerifyData] = useState({
    visible: false,
    type: "",
  });
  const [signVerifyParams, setSignVerifyParams] = useState("");

  useEffect(() => {
    if (network === "BTC") {
      setAuthTypes([verifyMethods[1]]);
      setVerifyType(WHITELIST_AUTH_TYPE.TRANSFER);
    }
    return () => setVerifyType(WHITELIST_AUTH_TYPE.SIGN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, verifyMethods]);

  useEffect(() => {
    if (addressType === WHITELIST_CATEGORY.HOSTED) {
      getWhiteListVaspList().then((res) => {
        if (res?.data?.vasp_list) {
          setVaspList(res.data.vasp_list);
          setWhitelistVaspDid(res.data.vasp_list[0]?.did);
        }
      });
    }
    //非托管 && 转账
    if (
      addressType === WHITELIST_CATEGORY.UNHOSTED &&
      verifyType === WHITELIST_AUTH_TYPE.TRANSFER &&
      currency
    ) {
      getTransferInfo({ currency: currency, network }).then((res) => {
        if (res.code === 0 && res?.data) {
          setTransferInfo(res?.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressType, verifyType, currency]);

  useEffect(() => {
    if (addressType === WHITELIST_CATEGORY.HOSTED) {
      setCanSubmit(!!whitelistVaspDid);
    } else {
      if (verifyType === WHITELIST_AUTH_TYPE.SIGN) {
        setCanSubmit(!!walletAddress);
      } else {
        setCanSubmit(agreeTransfer && !!transferInfo.amount);
      }
    }
  }, [
    whitelistVaspDid,
    addressType,
    verifyType,
    agreeTransfer,
    walletAddress,
    transferInfo,
  ]);

  useEffect(() => {
    const innerhtml = t("DjgcgdUJtoNmqshNSb")
      .replace("$s", transferInfo.amount || "--")
      .replace("$s", transferInfo.currency || "");
    setInnerhtml(innerhtml);
  }, [transferInfo, t]);

  const connectStatus = useMemo(() => {
    if (!connected) {
      return {
        text: t("NuZslGbiHniUKgycjhmv"),
        color: "#fff",
        bgColor: "#333BF5",
        hoverColor: "blue.80",
      };
    }
    return {
      text: t("AdLFmhSQGMGYrTgXdUH"),
      color: "#4B5DFF",
      bgColor: "rgba(51, 59, 245, 0.1)",
      hoverColor: "rgba(51, 59, 245, 0.05)",
    };
  }, [connected, t]);

  const handleConnect = async () => {
    if (!window.ethereum) {
      window.open(
        "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=zh-CN&utm_source=ext_sidebar",
        "_blank"
      );
      return;
    }
    setConnecting(true);
    try {
      const res = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      if (res && res.length) {
        setWalletAddress(res[0]);
        setConnected(true);
        setConnecting(false);
      }
    } catch (e) {
      setConnecting(false);
    }
  };

  // 验证密码
  const verifyPwdFunc = async ({ pwd, params }) => {
    const res = await postPaymentPwdVerify({
      request_id: whitelistUuid,
      business: verifyPwdData.type,
      payment_password: pwd,
    });
    if (res.code === 0) {
      setVerifyData(
        Object.assign({}, verifyPwdData, {
          visible: false,
        })
      );
      // 通过后
      if (verifyPwdData.type === WHITELIST_AUTH_TYPE.TRANSFER) {
        handleTransferAuthSubmit();
      } else if (verifyPwdData.type === WHITELIST_CATEGORY.HOSTED) {
        handleVaspAuthSubmit(params);
      } else if (verifyPwdData.type === WHITELIST_AUTH_TYPE.SIGN) {
        const verifyRes = await whiteListAddressSignVerify(signVerifyParams);
        if (verifyRes?.code === 0) {
          toastTip(t("sSrzMRNSGNzrJsidBnYz"), { type: "success" });
          navigator(`/trade/whiteAddress`);
        } else {
          toastTip(t("SBLHeXGlsYVIdiBNRe"), { type: "error" });
        }
      }
    }
  };

  // metamask
  const handlePersonalSignAuthSubmit = async () => {
    const res = await generatePersonalSignMessage({
      currency: currency,
      address: walletAddress,
    });
    if (!res.code) {
      try {
        setStatusCardData({ visible: true, status: "wait_sign" });
        const signText = res.data.message;
        const signRes = await window.ethereum.request({
          method: "personal_sign",
          params: [walletAddress, signText],
        });
        const verifyParams = {
          name: addressName,
          currency: currency,
          network: network,
          address: address,
          wallet: "MetaMask",
          sign: signRes,
          message: signText,
          is_deposit: usedKeyList.indexOf(1) > -1 ? true : false,
          is_withdraw: usedKeyList.indexOf(2) > -1 ? true : false,
        };
        setStatusCardData({ visible: true, status: "sign_success" });
        setSignVerifyParams(verifyParams);
      } catch (e) {
        setStatusCardData({ visible: true, status: "sign_error" });
      }
    }
  };

  // 提交转账验证
  const handleTransferAuthSubmit = async () => {
    const params = {
      name: addressName,
      currency: currency,
      network: network,
      address: address,
      amount: transferInfo.amount,
      transfer_address: transferInfo.transfer_address,
      is_deposit: usedKeyList.indexOf(1) > -1 ? true : false,
      is_withdraw: usedKeyList.indexOf(2) > -1 ? true : false,
    };
    const submitRes = await whiteListAddressTransferCreate(params);
    if (submitRes.code === 0) {
      toastTip(t("sSrzMRNSGNzrJsidBnYz"), { type: "success" });
      navigator(`/trade/whiteAddress`);
    }
  };

  // 交易所
  const handleVaspAuthSubmit = async (params) => {
    const res = await verifyCEXWhiteListCreate(params);
    if (res?.code === 0) {
      toastTip(t("sSrzMRNSGNzrJsidBnYz"), { type: "success" });
      navigator(`/trade/whiteAddress`);
    }
  };

  const renderButtons = () => {
    return (
      <Flex justifyContent={"center"} mt="40px" mb={"50px"}>
        <Button
          mr="10px"
          fontSize="14px"
          padding="0 30px"
          w={["100%", "100px"]}
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => setStep(WHITELIST_STEPS.ADD)}
        >
          {t("FrzWGSiDSDzTfNgZH")}
        </Button>
        <Button
          fontSize="14px"
          padding="0 30px"
          w={["100%", "200px"]}
          color="#fff"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          bg={canSubmit ? "blue.100" : "t.50"}
          _hover={{ bg: canSubmit ? "blue.80" : "t.50" }}
          onClick={() => {
            if (canSubmit) {
              if (addressType === WHITELIST_CATEGORY.HOSTED) {
                // 交易所 地址验证
                setVerifyData({
                  visible: true,
                  type: WHITELIST_CATEGORY.HOSTED,
                });
                // handleVaspAuthSubmit();
              } else {
                if (verifyType === WHITELIST_AUTH_TYPE.TRANSFER) {
                  // 小额转账验证
                  setVerifyData({
                    visible: true,
                    type: WHITELIST_AUTH_TYPE.TRANSFER,
                  });
                  // handleTransferAuthSubmit();
                } else {
                  // 签名认证
                  // setVerifyData({
                  //   visible: true,
                  //   type: WHITELIST_AUTH_TYPE.SIGN,
                  // });
                  handlePersonalSignAuthSubmit();
                }
              }
            }
          }}
        >
          {verifyType === WHITELIST_AUTH_TYPE.TRANSFER ||
          addressType === WHITELIST_CATEGORY.HOSTED
            ? t("YChcfaxDaLscdSPiRQz")
            : t("rQiWsAGGRmEQCtfaIK")}
        </Button>
      </Flex>
    );
  };
  // 钱包签名
  const renderWalletSign = () => {
    return (
      <>
        {!isMobile && (
          <Flex
            alignItems={"center"}
            mt={"30px"}
            width={"100%"}
            flexDirection={"column"}
          >
            <Flex alignItems={"center"} mt={"30px"} width={"100%"}>
              <Flex
                border={"1px solid #E3E4F1"}
                borderRadius={"12px"}
                display={"flex"}
                alignItems={"center"}
                padding={"10px 20px"}
                justifyContent={"space-between"}
                cursor={"pointer"}
                width={"calc(100% - 100px)"}
              >
                <Flex alignItems={"center"}>
                  <Image
                    src={"../../wwwsite/images/metamask.svg"}
                    w={"46px"}
                    h={"46px"}
                  />
                  <Text fontSize={"14px"} fontWeight={"400"} ml={"10px"}>
                    MetaMask
                  </Text>
                </Flex>
                {connecting ? (
                  <Image
                    src={"../../wwwsite/images/whitelist/loading.svg"}
                    className="metamask-connecting-loading "
                  />
                ) : (
                  <Image src={"../../wwwsite/images/checked.svg"} />
                )}
              </Flex>
              <Button
                borderRadius={"30px"}
                background={connectStatus?.bgColor}
                ml={"20px"}
                minWidth={"80px"}
                padding={"0px 10px"}
                _hover={{ bg: connectStatus.hoverColor }}
                onClick={() => {
                  if (!connected) {
                    handleConnect();
                  }
                }}
              >
                {
                  <Text
                    color={connectStatus?.color}
                    fontSize={"12px"}
                    fontWeight={500}
                  >
                    {connectStatus?.text}
                  </Text>
                }
              </Button>
            </Flex>
            <Box mt={"20px"} width={"100%"}>
              <Text fontSize="14px" mb={"10px"}>
                {t("AocjyYzlYgRsGzlavU")}
              </Text>
              <LabelText>{walletAddress}</LabelText>
            </Box>
          </Flex>
        )}

        {isMobile && (
          <Text
            fontWeight={600}
            fontSize={"14px"}
            color={"red.100"}
            mt={"20px"}
          >
            {t("JWctZqDXJyyraLbOe")}
          </Text>
        )}
        {statusCardData.visible && (
          <StatusCard
            status={statusCardData.status}
            onRetry={handlePersonalSignAuthSubmit}
            onClose={() => {
              setStatusCardData({
                visible: false,
                status: "",
              });
            }}
            onOk={() => {
              setStatusCardData({ visible: false, status: "" });
              setVerifyData({
                visible: true,
                type: WHITELIST_AUTH_TYPE.SIGN,
              });
            }}
          />
        )}
      </>
    );
  };
  const renderQrcode = () => {
    return (
      <Box
        w="180px"
        h="180px"
        border="1px"
        borderColor="#232325"
        p="10px"
        borderRadius="12px"
        mx={"auto"}
      >
        <QRCode value={transferInfo.transfer_address} size={160} id="qrCode" />
      </Box>
    );
  };
  const clickCopy = () => {
    copyToClipboard(transferInfo?.transfer_address);
    toastTip(t("gXbirwakdsaUKBglwK"), { type: "success" });
  };
  // 转账验证
  const renderTransferVerify = () => {
    return (
      <Flex alignItems={"center"} flexDirection={"column"} w={["90%", "500px"]}>
        <Box
          mt={"30px"}
          width={"100%"}
          background={"rgba(32, 26, 85, 0.05)"}
          borderRadius={"12px"}
          padding={"10px 20px"}
          height={"44px"}
        >
          <Text fontSize={"16px"} fontWeight={500}>
            {`${currency}-${currency === "BTC" ? "BTC" : "ERC20"}`}
          </Text>
        </Box>
        <Box
          mt={"10px"}
          width={"100%"}
          background={"rgba(32, 26, 85, 0.05)"}
          borderRadius={"12px"}
          padding={"10px 20px"}
          height={"44px"}
          display={"flex"}
          alignItems={"center"}
        >
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            w={"100%"}
          >
            <Text fontSize={"12px"} fontWeight={400}>
              {transferInfo?.transfer_address}
            </Text>
            <Box onClick={clickCopy}>
              <Image
                src={"../../wwwsite/images/whitelist/copy.svg"}
                w={"15px"}
                h={"15px"}
                cursor={"pointer"}
              />
            </Box>
          </Flex>
        </Box>
        <Box mt={"10px"} width={"100%"}>
          {renderQrcode()}
        </Box>
        <Box
          mt={"10px"}
          width={"100%"}
          background={"rgba(32, 26, 85, 0.05)"}
          borderRadius={"12px"}
          padding={"10px 20px"}
        >
          <Flex alignItems={"center"}>
            <Image
              src={"../../wwwsite/images/whitelist/vector.svg"}
              w={"24px"}
              h={"24px"}
            />
            <Text
              fontSize={"14px"}
              fontWeight={"400"}
              ml={"10px"}
              className="white-list-exchange-deposite-text"
              dangerouslySetInnerHTML={{ __html: innerhtml }}
            ></Text>
          </Flex>
        </Box>
        <Flex
          alignItems={"center"}
          padding={"10px 0px"}
          mt={"5px"}
          justifyContent={"flex-start"}
          width={"100%"}
        >
          <Image
            src={
              agreeTransfer
                ? "../../wwwsite/images/whitelist/check-selected.svg"
                : "../../wwwsite/images/whitelist/check-normal.svg"
            }
            w={"18px"}
            h={"18px"}
            onClick={() => {
              setAgreeTransfer(!agreeTransfer);
            }}
          />
          <Text fontSize={"14px"} fontWeight={"400"} ml={"10px"}>
            {t("FxlLNwkBHAbChJrnM")}
          </Text>
        </Flex>
      </Flex>
    );
  };

  // 非托管钱包
  const renderUnHostedAddress = () => {
    return (
      <>
        <Flex
          alignItems={"center"}
          flexDirection={"column"}
          w={["90%", "500px"]}
          justifyContent={"flex-start"}
          margin={"0 auto"}
        >
          <Text fontSize={"24px"} fontWeight={600}>
            {t("BbqJaDHRCDmiNwNVcGB")}
          </Text>
          {/* 地址验证类型 */}
          <Box mt={"40px"} width={"100%"}>
            <Flex width={"100%"}>
              {authTypes.map((d, i) => {
                return (
                  <AddressTypeItem
                    onClick={() => setVerifyType(d.id)}
                    style={{
                      flex: 1,
                      marginLeft: i === 1 && "10px",
                    }}
                    isActive={d.id === verifyType}
                    text={d.text}
                    key={d.id}
                  />
                );
              })}
            </Flex>
          </Box>
          {verifyType === WHITELIST_AUTH_TYPE.SIGN && renderWalletSign()}
          {verifyType === WHITELIST_AUTH_TYPE.TRANSFER &&
            renderTransferVerify()}
          {renderButtons()}
        </Flex>
        {verifyPwdData.visible && (
          <PasswordVerify
            onClose={() =>
              setVerifyData({
                visible: false,
                type: "",
              })
            }
            onOk={({ pwd }) => {
              verifyPwdFunc({ pwd });
            }}
          />
        )}
      </>
    );
  };

  // 交易所验证
  const renderHostedAddress = () => {
    return (
      <VerifyCEX
        network={network}
        address={address}
        currency={currency}
        addressName={addressName}
        vaspList={vaspList}
        setWhitelistVaspDid={setWhitelistVaspDid}
        verifyPwdData={verifyPwdData}
        setVerifyData={setVerifyData}
        verifyPwdFunc={verifyPwdFunc}
        setCanSubmit={setCanSubmit}
        canSubmit={canSubmit}
        setStep={setStep}
      />
    );
  };

  if (addressType === WHITELIST_CATEGORY.UNHOSTED) {
    return renderUnHostedAddress();
  }
  return renderHostedAddress();
};

export default Veriify;
