import PageWrap from "@/components/PageWrap";
import { Box } from "@chakra-ui/react";
import Add from "./Add";
import Verify from "./Veriify";
import { useAdd } from "./hooks";
import { WHITELIST_STEPS } from "../consts";

const AddAddress = () => {
  const {
    addressType,
    setAddressType,
    addressName,
    setAddressName,
    currency,
    setCurrency,
    network,
    setNetwork,
    address,
    setAddress,
    currencyOptions,
    netWorkOptions,
    addressTypeOptions,
    canToNext,
    setStep,
    step,
    verifyMethods,
    verifyType,
    setVerifyType,
    whitelistUuid,
    setWhitelistUuid,
    whitelistVaspDid,
    setWhitelistVaspDid,
    memoWhiteUsed,
    handleWhiteUsed,
    usedKeyList,
    showUsedError,
    setUsedKeyList,
  } = useAdd();
  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        pt={"60px"}
      >
        {step === WHITELIST_STEPS.ADD && (
          <Add
            addressType={addressType}
            setAddressType={setAddressType}
            addressName={addressName}
            setAddressName={setAddressName}
            currency={currency}
            setCurrency={setCurrency}
            network={network}
            setNetwork={setNetwork}
            address={address}
            setAddress={setAddress}
            currencyOptions={currencyOptions}
            netWorkOptions={netWorkOptions}
            addressTypeOptions={addressTypeOptions}
            canToNext={canToNext}
            setStep={setStep}
            setWhitelistUuid={setWhitelistUuid}
            setWhitelistVaspDid={setWhitelistVaspDid}
            memoWhiteUsed={memoWhiteUsed}
            handleWhiteUsed={handleWhiteUsed}
            usedKeyList={usedKeyList}
            showUsedError={showUsedError}
            setUsedKeyList={setUsedKeyList}
          />
        )}
        {step === WHITELIST_STEPS.VERIFY && (
          <Verify
            verifyMethods={verifyMethods}
            verifyType={verifyType}
            network={network}
            setStep={setStep}
            address={address}
            addressType={addressType}
            setVerifyType={setVerifyType}
            whitelistUuid={whitelistUuid}
            whitelistVaspDid={whitelistVaspDid}
            setWhitelistVaspDid={setWhitelistVaspDid}
            currency={currency}
            addressName={addressName}
            usedKeyList={usedKeyList}
          />
        )}
      </Box>
    </PageWrap>
  );
};

export default AddAddress;
