import { Box, Text } from "@chakra-ui/react";

const ZHDoc = () => {
  return (
    <Box>
      <Text fontSize="28px" fontWeight={"bold"} mt="30px">
        用户协议
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        最后更新时间：2024年1月5日
      </Text>
      <Text mt="30px">
        NewBX
        Limited（CE编号: BUY737）（一家在香港注册成立的公司，注册地址为香港上环禧利街27号富辉商业中心7楼702室，以下简称“公司”）开发和运营币信软件（以下简称“本软件”）及服务。请参阅公司网站
        bixin.com
        了解我们的牌照状态，如果我们的牌照状态发生变化，我们将通知您。除非另有说明，本文中提及的公司还包括NewBX
        Treasures Limited（一家在香港注册成立的公司，注册地址为香港上环禧利街 27
        号富辉商业中心 7 楼 702
        室），其由香港公司注册处颁发的信托或公司服务提供商牌照编号为TC009090（“联系实体”）。
      </Text>
      <Text mt="30px">
        为了使用本软件及服务，您（以下简称“您”或“用户”）必须阅读并同意遵守公司网站的《用户协议》（以下简称“本协议”）和《隐私条款》。本协议、《隐私条款》、《反洗钱和反代币洗钱公告》、《风险披露声明》、《托管安排相关信息》（以不时修订、补充或修改版本为准），应构成双方之间关于使用本软件和服务的完整协议（“协议”）。本协议与其他协议不一致的，以本协议为准。如果协议的英文版本和中文版本之间存在任何不一致，则以英文版本为准。请您仔细阅读、充分理解本协议各条款的条款，特别是免除或限制责任的条款，并选择接受或不接受。您的下载、安装、使用、申请、获取账号、登录等行为均视为您已阅读并同意接受本协议的约束。本公司保留不时对协议条款进行任何修改和补充并在软件和服务中公布的权利。协议条款的修改和补充将在发布后立即生效。您继续访问和使用软件和服务将被视为受修订后的协议的约束。否则，您有权通过停止登录、拒绝使用本软件及服务的方式终止本协议。
      </Text>
      <Text mt="30px">
        您有义务遵守所有适用的法律、法规、规则、指引和其他政府规定，不扰乱数字资产（包括BTC、ETH等本公司提供服务相关的所有数字资产）的正常交易秩序、交换和托管，且不利用该软件和服务组织参与洗钱、赌博等犯罪活动。若因违规行为导致发生任何法律后果，您应自行承担一切相应的法律责任。若用户有违法犯罪行为，或本公司有相关证据合理怀疑该用户可能有违法犯罪行为，或违反本协议约定的，本公司有权暂停或终止该用户的服务或停止向用户提供服务，在公司网站和互联网（如适用）上公布该用户的违法行为或违约行为，并向有关当局报告并配合其调查活动，包括但不限于披露所有用户信息、交易记录以及有关部门要求的其他信息。本公司可实施上述任何及全部行为，由此产生的后果、风险和责任均由用户承担。用户接受本协议即视为用户理解并接受本条款并永久且不可撤销地授权本公司披露相关信息并采取相应措施。
      </Text>
      <Text mt="30px">
        协议内容包括协议正文以及公司已发布或将来可能发布的各类规则、声明和指示。所有规则、声明和指示均为协议不可分割的部分，与协议文本具有同等法律效力。
      </Text>
      <Text mt="30px">
        如果您代表某个机构而非您注册、注册使用、使用本软件及服务，您将被视为已获得充分授权，代表相关机构同意本协议及本协议的条款协议不时修改和补充。
      </Text>
      <Text mt="30px">
        您承诺并同意，如果您的全名、地址以及为提供软件和服务而向公司提供的任何其他重要信息发生任何重大变更，请立即通知公司。否则可能会导致服务终止或暂停。
      </Text>
      <Text mt="30px">
        公司承诺并同意以书面形式或通过提请您注意公司网站上发布的更新的方式通知您以下方面的任何重大变更：
      </Text>
      <Text mt="10px">(i) 公司业务的全名和地址；</Text>
      <Text mt="10px">(ii) 公司在证监会的发牌状况及其CE编号；</Text>
      <Text mt="10px">(iii) 向您提供的软件和服务的性质；</Text>
      <Text mt="10px">(iv) 您应支付的报酬和任何费用的描述；和</Text>
      <Text mt="10px">(v) 与所提供的软件和服务相关的任何其他重要信息。</Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        一、软件及服务
      </Text>

      <Text mt="10px">
        1、本软件和服务使您能够通过本公司运营的平台获取或处置虚拟资产。公司还可以提供平台外服务，包括场外交易（“OTC”）交易台和询价（“RFQ”）服务。此外，该公司还提供托管服务，通过我们的关联实体托管您的资产。
        详细服务范围还载于公司网站。
      </Text>
      <Text mt="10px">
        2、为了使用本软件及服务，您需要通过网站（{" "}
        <a href="https://bixin.com/" style={{ color: "#3370ff" }}>
          bixin.com
        </a>
        ）或公司认可的其他渠道下载软件客户端。公司授予您个人的、不可转让的、非独占的使用许可。您只能出于自己的目的访问和使用软件和服务。
      </Text>
      <Text mt="10px">
        3、
        软件和服务可能不时具有不同的功能，每个功能的使用及其限制将受该功能的具体业务规则的约束。公司有权不时制定、修改这些具体业务规则并予以公布。您使用或继续使用此类功能即表示您同意并接受此类规则和修订。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        二、註冊和帳戶獲取
      </Text>
      <Text mt="10px">
        1、您在使用本软件及服务前需要注册币信账号。币信账户是用手机号码和/或邮箱地址（视具体版本而定）注册的。我们将根据您注册时提供的手机号码和/或电子邮件地址为您创建币信账户。如果您之前已使用相同的手机号码和/或电子邮件地址在公司网站上注册为用户，则
        使用手机号码和/或电子邮件地址注册的币信账户将自动与您使用手机号码和/或电子邮件地址在公司网站注册的账户关联。
      </Text>
      <Text mt="10px">
        2、币信账户的所有权归公司所有。用户完成注册程序后，仅获得币信账户的使用权，且该使用权仅属于注册人本人。币信账户注册人不得以任何方式向第三方有偿或无偿提供账户使用权，包括但不限于转让、赠与、租赁、出借、许可等。币信账户注册人违反上述约定的，其后果和责任由注册人自行承担。给公司造成损失的，应当向公司全额赔偿。公司有权收回该账户的使用权。
      </Text>
      <Text mt="10px">
        3、您应妥善保管您的币信账号和密码。准确输入这些信息将是您登录并使用本软件及服务的关键环节。您承认并同意，您账户下发生的任何行为将被视为或已获得您的合法授权，并承担一切法律后果。如果您发现有人未经授权使用您的账户，您应立即更改密码并向我们反馈。尽管有前述规定，在任何情况下，本公司均不对因您的账号、密码、登录信息错误、丢失或其他因您未能确保币信账户信息安全而造成的损失或损害承担任何责任。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        三、用户备注
      </Text>
      <Text mt="10px">
        1、您理解并同意，为了保障您的数据存储安全、分散风险，您授权本公司按照一定的规则和比例（按本公司隐私条款的规定）将您的数据转移给第三方，并授权本公司将您的数据转移给第三方。公司委托第三方办理相关事宜，在本协议第一条约定的服务范围内。
      </Text>
      <Text mt="10px">
        2、您理解并同意，本公司将采取合理的商业努力来保护您在软件和服务中存储的数据，但本公司不向您提供任何形式或性质的保证，包括但不限于以下内容：
      </Text>
      <Text mt="10px">
        (1) 本公司对软件及服务中相关数据的删除或存储不承担任何责任；
      </Text>
      <Text mt="10px">
        (2)
        公司有权根据实际情况确定个人用户数据在软件和服务中的最长存储期限，并为该数据在服务器上分配最大存储空间。您可以根据自己的需要备份本软件及服务中的相关数据，并自行承担相应的风险；
      </Text>
      <Text mt="10px">
        (3)
        受限于《个人资料（私隐）条例》（香港法例第486章）（“PDPO”）的规定，如果您停止使用本软件且本服务或服务被终止或取消（包括账户永久关闭），本公司可能会从服务器上永久删除您的数据；服务停止、终止或取消后，本公司没有义务向您返还任何资料。
      </Text>
      <Text mt="10px">
        3、基于互联网的开放性，您知悉用户上传至互联网的图片及其他数据可能会被其他组织或个人复制、转载、修改或以其他方式使用。您必须充分意识到此类风险存在。您明确同意自行承担使用该软件和服务的风险；您使用该软件和服务所产生的所有后果均由您承担。
      </Text>
      <Text mt="10px">
        4、当用户使用本软件及服务的特定功能时，本公司可能会调用第三方系统或通过第三方支持用户的使用或访问。使用或访问的结果由第三方提供（包括但不限于币信公众号提供的服务，或通过开放平台访问的内容等），本公司不做任何承诺或承担对于提供服务和内容的第三方的安全性、准确性、有效性和其他事项的任何责任以及相关的风险和责任均由用户承担。您在使用这些第三方产品或服务时，除遵守本协议条款外，还必须遵守第三方用户协议。因本软件使用第三方软件或技术而产生的任何争议均由第三方解决，本公司不承担任何责任。公司不为第三方软件或技术提供客户服务支持。如果您需要支持，请直接联系第三方。
      </Text>
      <Text mt="10px">
        5、您理解并同意，在任何情况下，本公司均不对网络连接故障、电脑、通讯或其他系统故障、停电、罢工、劳资纠纷、骚乱、起义、骚乱、火灾或水灾、风暴、爆炸、不可抗力、战争、政府行为、国际和国内法院的命令、第三方不作为或公司无法合理控制的任何原因、软件和服务无法访问、信息和数据延迟、停滞、或错误、未能提供或延迟提供服务。
      </Text>
      <Text mt="10px">
        6、如因信息网络连接故障、计算机、通讯、电力或其他系统故障、工作人员故障等原因，导致本公司向用户重复支付或多付数字资产的，用户有义务向本公司返还立即支付额外款项或数字资产，无论何种情况，用户均应在收到公司合理通知和提醒之日起10天内完成返还。您还同意
        在上述 10
        天到期后，公司有权自行决定直接从您的账户中扣除相同金额的法定或数字资产，或额外付款或数字资产。
      </Text>
      <Text mt="10px">
        7、用户之间转移的数字资产如出现记账错误，须由用户自行解决。本公司不负责帮助撤销或冻结相应操作所涉及的数字资产或采取任何其他措施，也不就此承担任何责任。
      </Text>
      <Text mt="10px">
        8、如果用户因网上交易与其他用户发生纠纷，用户必须向有关司法、政府和/或行政机关申请向本公司索取相关信息。本公司不接受其他方式获取申请，也不负责通过其他渠道提供相关信息。
      </Text>
      <Text mt="10px">
        9、您理解并同意本公司有权决定将本软件及服务用于商业目的。受限于《个人资料保护条例》的要求，本公司承诺在推广过程中按照约定保护您的个人信息，您也可以根据系统设置选择屏蔽或拒绝。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        四、软件更新、变更和服务中断、终止
      </Text>
      <Text mt="10px">
        1、为了改善用户体验和完善服务内容，公司将不断开发新的功能或版本，并不时为您提供软件更新（这些更新可能采取软件替换、修改、增强的形式）
        、版本升级等。
      </Text>
      <Text mt="10px">
        2、为了保证软件和服务的安全性和功能的一致性，本公司保留在不通知您的情况下更新软件，或不时更改或限制部分软件功能的权利。本软件新版本发布后，旧版本软件可能无法使用。请随时查看并下载最新版本。未能使用最新版本下载并使用最新版本的风险将由您自行承担。如果您继续使用该软件和服务，即表示您同意接受更新后的软件和服务。如果您拒绝接受更新后的软件和服务，您应立即停止登录和使用该软件和服务；在这种情况下，您仍然需要对您停止使用此类软件和服务之前发生的行为承担全部责任。
      </Text>
      <Text mt="10px">
        3、您理解并同意，本公司保留因软件、服务维护、升级或其他原因暂停服务、调整服务功能或终止服务的权利。公司将提前在公司网站或软件中进行公告或通知。您同意本公司不会因此而对您承担任何责任。
      </Text>
      <Text mt="10px">
        4、若本公司终止本软件及服务，或本公司依据本协议或相关司法管辖区法律、法规的规定终止向用户提供本软件及服务，本公司有权终止向用户提供本软件及服务。有权按照原路或根据相关司法管辖区的相关法律、规则和/或法规或相关监管机构和/或机构的其他命令返还用户账户中的数字资产。法规的规定均已处理。若因用户原因未能退回或有其他清关障碍的，一切风险、损失和责任均由用户自行承担。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        五、用户行为规范
      </Text>
      <Text mt="10px">（一）遵守当地法律</Text>
      <Text mt="10px">
        用户有责任遵守相关司法管辖区有关使用软件和服务的当地法律和其他适用的法律法规。用户还必须根据相关法律、法规和/或条例考虑各方面的税务事项，包括但不限于向其适当的税务机关代扣代缴、征收、报告和汇款。该软件和服务的所有用户保证并声明其资金来自合法来源，并非源自非法活动；用户同意本公司将根据有关法律或政府命令要求其提供或以其他方式收集必要的信息和资料，以核实其资金来源和用途的合法性。该公司与全球执法机构合作，将毫不犹豫地扣押、冻结、吊销许可证、终止或调查用户的账户和资金。
      </Text>
      <Text mt="10px">（二）信息内容合法性规范</Text>
      <Text mt="10px">
        资料的头像、姓名、用户描述等信息，或文字、语音、图片等的发送和回复）的合法性。以及因使用币信账号或本软件及服务而产生的其他内容）在使用本软件及服务过程中制作、复制、发布、传播的内容。用户承诺不利用币信账户或本软件及服务进行以下创作、复制、发布、传播等干扰本公司正常经营及侵犯其他用户或第三方合法权利的行为，包括但不限于：
      </Text>
      <Text mt="10px">1、传播、传送、传播、储存相关法律法规禁止的内容。</Text>
      <Text mt="10px">
        2、传播、传送、传播、储存侵犯他人名誉权、肖像权、知识产权、商业秘密等的内容。
      </Text>
      <Text mt="10px">3、涉及他人隐私、个人信息或者信息的。</Text>
      <Text mt="10px">
        4、
        发布、传送、传送骚扰信息、广告信息、过度营销信息和垃圾信息或包含任何性或性暗示信息。
      </Text>
      <Text mt="10px">
        5、其他违反法律、法规、政策和公共道德、公共道德或公司正常经营，侵犯其他用户或第三方合法权益的信息。
      </Text>
      <Text mt="10px">（三）软件使用合法性规范</Text>
      <Text mt="10px">
        1、用户使用本软件及服务必须尊重并维护公司知识产权和信息的正常、稳定运行。用户
        承诺不从事任何可能侵犯公司知识产权或影响公司正常经营的活动，包括但不限于
      </Text>
      <Text mt="10px"> （1）删除软件的版权信息；</Text>
      <Text mt="10px">
        (2) 逆向工程、逆向汇编、逆向编译或者以其他方式试图查找该软件的源代码；
      </Text>
      <Text mt="10px">
        (3)
        对公司自主知识产权内容进行使用、出租、出借、复制、模仿、修改、转载、编译、出版、建立镜像网站等；
      </Text>
      <Text mt="10px">
        (4)
        复制、修改、添加、删除本软件或服务运行过程中释放到任意终端内存的数据、软件运行过程中客户端与服务器端的交互数据以及软件运行过程中所必需的系统数据。运营本软件，从事运营或创作任何衍生作品，包括但不限于使用插件、插件或未经授权的第三方工具/服务访问本软件及相关系统
      </Text>
      <Text mt="10px">
        (5)
        修改或伪造软件运行中的指令和数据，增加、删除、改变软件的功能或者运行效果，或者运营、宣传用于上述目的的软件和方法，无论这些行为是否属于商业用途；
      </Text>
      <Text mt="10px">
        (6)
        登录或使用本软件及服务，或通过非本公司开发、授权的第三方软件、插件、插件、系统创建、发布、传播上述工具；
      </Text>
      <Text mt="10px">
        (7) 自行或授权他人或第三方软件干扰本软件及其组件、模块和数据。
      </Text>
      <Text mt="10px">2、用户在使用本软件及服务过程中不得发生以下行为：</Text>
      <Text mt="10px">(1) 提交、发布虚假信息，或者冒充、利用他人名义的；</Text>
      <Text mt="10px">(2) 诱导其他用户点击链接页面或者分享信息；</Text>
      <Text mt="10px">(3) 捏造事实、隐瞒真相，误导、欺骗他人的；</Text>
      <Text mt="10px">
        (4) 侵犯他人名誉权、肖像权、知识产权、商业秘密等合法权利；
      </Text>
      <Text mt="10px">
        (5) 以任何方式或出于任何商业目的向第三方提供币信账户或其任何功能；
      </Text>
      <Text mt="10px">(6) 利用币信账户或软件、服务从事任何违法犯罪活动；</Text>
      <Text mt="10px">
        (7)
        制作、发布与上述行为相关的方法或工具，或者运营或传播此类方法或工具，无论这些行为是否出于商业目的；
      </Text>
      <Text mt="10px">
        (8)
        其他违反法律法规、侵犯其他用户合法权益、干扰软件和服务正常运行的行为。
      </Text>

      <Text mt="10px">（四）違約情況</Text>

      <Text mt="10px">
        1、
        若本公司发现或收到他人举报或投诉用户违反本协议的行为，本公司保留随时删除或屏蔽相关用户而不另行通知的权利，包括但不限于根据行为的具体情况，对违规帐户进行警告和限制，禁止使用部分或全部功能，关闭账户，取消处罚，并公布结果。
      </Text>
      <Text mt="10px">
        2、
        您理解并同意，本公司有权根据合理判断对违反有关法律法规或本协议规定的行为进行处罚，对任何违反法律法规的用户采取适当的法律行动，并保存相关信息依照法律、法规的规定向有关部门报告。举报等所产生的一切法律责任由其自行承担。
      </Text>
      <Text mt="10px">
        3、
        您理解并同意，因您违反本协议或相关服务条款而导致第三方索赔而引起的任何索赔、要求或损失，您应当自行承担责任，并承担以下责任：公司遭受的任何及全部损失。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        六、知识产权
      </Text>

      {/* 30 */}
      <Text mt="10px">
        1、
        软件和服务中包含的所有智力成果包括但不限于网站徽标、数据库、网站设计、文本和图形、软件、照片、软件编译、源代码、应用程序和应用程序编程接口的版权，与软件和服务相关的任何其他版权和其他知识产权均归公司所有。用户不得复制、更改、拷贝、模仿、传播或使用任何前述材料或内容。您不得为任何目的实施、使用、转让或许可他人实施上述知识产权。任何未经授权使用本软件及服务的行为均属违法行为，本公司将追究相关法律责任。
      </Text>
      <Text mt="10px">
        2、软件及服务名称中包含的一切权益（包括但不限于商誉、商标、标识）均归本公司所有。
      </Text>

      {/* 32 */}
      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        七、隐私条款
      </Text>
      <Text mt="10px">
        1、
        为了提供本软件及服务，用户同意本公司登记用户信息（包括用户的个人识别信息）、用户使用本软件的权限以及用户的IP地址，例如本公司的IP地址。用户收到服务函、用户交易数据、用户交易数据。其他通过合法途径获取的用户信息被记录、保存和使用。公司不会向任何无关第三方提供、出售、出租、分享和交易用户的个人信息。但为了方便使用本软件及服务，用户同意对用户的身份和信息进行全面统计。本公司不会泄露任何可能用于识别用户个人身份的信息。但本公司不承担任何分析责任。
        用户的个人识别信息来自用户的用户名或者其他可以公开的信息。
      </Text>
      <Text mt="10px">
        2、
        用户同意本公司可以披露或使用用户的个人信息来识别和确认用户的身份、解决争议，或保证软件和服务的安全，防止或限制违法犯罪活动。用户同意，为了保护用户的生命、财产安全或防止严重侵犯他人合法权益或公共利益的需要，本公司可以披露或使用用户的个人信息。用户同意本公司可以披露或使用用户的个人信息来改进软件和服务，使软件和服务更加符合用户的要求，从而使用户在使用软件和服务时获得更好的体验。用户同意本公司使用用户的个人信息与用户进行沟通并向用户提供用户可能感兴趣的信息，例如：介绍和推广服务或优惠的商业短信。
      </Text>
      <Text mt="10px">
        3、
        当公司迫于法律强制或根据政府、法院或权利人的要求提供用户的信息以查明涉嫌侵权人的身份时，公司将善意地披露用户的数据。当公司需要保护公司、其代理商、客户、用户和其他人的权利和财产，包括执行公司的协议、政策和使用条款时，公司将善意地披露用户信息。
      </Text>
      <Text mt="10px">
        4、
        用户同意，若本公司拟进行企业并购、重组、增资或出售全部或部分股份和/或资产，本公司有权披露用户的资料，以协助当事人交易。
      </Text>
      <Text mt="10px">
        5、本公司不对本公司以外的第三方的隐私声明或其他内容负责。
      </Text>
      <Text mt="10px">6、公司其他隐私条款详见《隐私条款》。</Text>
      <Text mt="10px">
        7、用户接受本协议及隐私条款或使用本软件及服务，即视为明确同意或授权本公司按照本公司适用的法律法规处理用户的个人信息和隐私数据。有关国家或司法管辖区对个人信息和隐私的保护（无论如何表述），包括但不限于：收集、存储、使用、处理、传输、提供、披露、删除等。
      </Text>

      {/* 39 */}
      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        八、風險提示
      </Text>
      <Text mt="10px">
        1、
        互联网是全球公共网络。它是去中心化的，不受任何机构控制。数据通过互联网传输的方式并不完全确定，可能会受到非法干扰或入侵。通过互联网传输的数据可能会被一些未经授权的个人、团体或机构通过某些渠道获取或篡改。互联网上的数据传输可能会因流量过大或其他原因（例如中断、暂停或数据不完整）而延迟，
        数据错误等，可能导致交易出现错误、延迟、中断或暂停。由于用户自身的疏忽，用户的身份可能会被泄露、被假冒或泄露，从而给用户造成损失。用户的计算机可能因性能缺陷、质量问题、计算机病毒、硬件故障等原因影响用户的交易登录、交易时间或交易数据，给用户造成损失。上述风险造成的损失或责任由用户自行承担，本公司对此不承担任何责任。尽管如此，本着对用户负责的态度，公司将采用先进的网络产品和技术措施，最大限度地保护用户数据和使用安全。
      </Text>
      <Text mt="10px">
        2、
        数字资产交易存在极高的风险。数字资产市场是新的、未经证实的，并且可能不会增长。目前数字资产主要被投机者使用，零售和商业市场使用相对较少。因此，数字资产价格往往会波动，进而对数字资产投资产生不利影响。数字资产市场没有涨跌幅限制，交易24小时开放。由于数字资产筹码数量较少，价格容易受到庄家的控制，可能会出现暴涨或暴跌的情况。
      </Text>
      <Text mt="10px">
        3、
        参与数字资产交易，用户应控制自身风险，评估数字资产的投资价值和投资风险，并承担损失全部投资的经济风险。因国家法律、法规、规范性文件的制定或修改，导致数字资产交易被暂停或禁止，由此造成的经济损失均由用户自行承担。用户一旦向本公司注册，即视为已充分了解并了解交易风险，并同意承担可能发生的风险或损失。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        九、爭議解決
      </Text>
      <Text mt="10px">
        1、
        因本公司提供的软件和服务引起的争议，适用香港特别行政区法律，并由香港国际仲裁中心（“HKIAC”）按照《中华人民共和国仲裁法》的规定通过仲裁解决。并适用当时有效的
        HKIAC 规则。
      </Text>
      <Text mt="10px">
        2、
        本公司不承担用户与本公司其他用户或其他网站或平台用户之间因数字资产而产生的任何法律关系及法律纠纷，也不承担任何法律责任。
      </Text>

      {/* 44 */}
      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        十、协议的效力及解释
      </Text>
      <Text mt="10px">
        1、
        本协议自用户点击本公司注册页面同意注册并完成注册程序并获得账号和密码时生效，对本公司和用户均具有约束力。
      </Text>
      <Text mt="10px">
        2、
        如果本协议的部分条款被认定为无效或不可执行，则本协议的其他条款仍然有效。
      </Text>
      <Text mt="10px">
        3、
        公司发布的任何与本软件和服务的任何功能相关的附加服务条款、使用规则或说明与本协议文本不一致，则该等附加服务条款、使用规则或说明说明应优先适用于该特定功能。
      </Text>
      <Text mt="10px">4、 本协议最终解释权归本公司所有。</Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        十一、投诉咨询
      </Text>
      <Text mt="10px">
        公司拥有专业的客户服务团队，为用户及时提供故障排除和投诉反馈。如果您在使用本软件及服务过程中有任何疑问、投诉和咨询，可以随时联系客服（币信账号ID：bixin）。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        十二、适当性
      </Text>
      <Text mt={"10px"}>
        在进行相关活动时，如果公司向您招揽销售或推荐任何包括虚拟资产在内的产品，该产品必须在考虑您的财务状况、投资经验和投资目标后合理适合您。本协议的其他条款或我们可能要求您签署的任何其他文件以及我们可能要求您做出的任何声明均不会减损本条款。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        十三、定价指南
      </Text>

      <Text mt="10px">
        限额及费用：请参阅公司网站
        <a
          href="https://support.bixin.com/hc/en-hk/articles/16737561979795"
          style={{ color: "#3370ff" }}
        >
          bixin.com
        </a>
      </Text>
    </Box>
  );
};

export default ZHDoc;
