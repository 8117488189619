import ModalComponent from "@/components/ui/modal";
import { Flex, Image, Text, chakra } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { BXButton } from "@/styles";
import { useMedia } from "@/hooks/useMedia";

const ConactText = chakra(Text, {
  baseStyle: {
    fontSize: "16px",
    color: "#010313",
    mt: "10px",
    b: {
      color: "#333BF5",
    },
  },
});

const HibernateModal = ({ onClose, show }) => {
  const { t } = useI18n();
  const { isMobile } = useMedia();
  return (
    <ModalComponent
      isShow={show}
      style={{ width: !isMobile ? "400px" : "calc(100% - 30px)" }}
    >
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        p={["50px 20px"]}
      >
        <Image
          src={`../../wwwsite/images/hibernate.svg`}
          width={"40px"}
          height={"40px"}
          alt=""
        />
        <Text mt={"20px"} fontWeight={600} fontSize={"16px"}>
          {t("LuuhHZgOxbTJDvnm")}
        </Text>
        <ConactText
          dangerouslySetInnerHTML={{ __html: t("sqteSBzbeJvwJeJSWZk") }}
        ></ConactText>
        <BXButton
          w={"180px"}
          mt={"20px"}
          onClick={() => {
            onClose && onClose();
          }}
        >
          {t("hdPRTcCCnKbkxbRwgv")}
        </BXButton>
      </Flex>
    </ModalComponent>
  );
};

export default HibernateModal;
