import { toast } from "react-toastify";

const customId = "custom-id-yes";
const toastTip = (text, setObject = {}) => {
  const getTextLength = text?.length;
  let autoClose = "";
  if (getTextLength <= 10) {
    autoClose = 2000;
  } else if (getTextLength > 10 && getTextLength <= 15) {
    autoClose = 3000;
  } else if (getTextLength > 15) {
    autoClose = 4000;
  }
  // autoClose = 600000000;
  const { type } = setObject;
  const getToastSetObject = {
    autoClose,
    toastId: customId,
    ...setObject,
  };

  const ErrorIcon = () => {
    return (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L4.58578 6L0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L6 7.41421L10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L7.41421 6L11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L6 4.58578L1.70711 0.292893Z"
          fill="#C26060"
        />
      </svg>
    );
  };

  const SuccessIcon = () => {
    return (
      <svg
        width="12"
        height="9"
        viewBox="0 0 12 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7071 0.292889C12.0976 0.683411 12.0976 1.31658 11.7071 1.7071L4.81653 8.59777C4.36561 9.0487 3.63455 9.04866 3.18366 8.59778L0.292904 5.70712C-0.0976264 5.3166 -0.0976357 4.68343 0.292883 4.2929C0.683401 3.90237 1.31657 3.90236 1.7071 4.29288L4.00007 6.58579L10.2929 0.292898C10.6834 -0.0976291 11.3166 -0.097633 11.7071 0.292889Z"
          fill="#60C2B0"
        />
      </svg>
    );
  };

  // eslint-disable-next-line default-case
  switch (type || "success") {
    case "success":
      toast.success(text, {
        icon: () => (
          // <img src="https://obstatic.243096.com/mili/images/toast/successToastIcon.png" />
          <SuccessIcon />
        ),
        ...getToastSetObject,
      });
      break;
    case "warn":
      toast.warn(text, {
        icon: () => (
          // <img
          //   src="https://obstatic.243096.com/mili/images/toast/errorToastIcon.png"
          //   className="error-icon-style"
          // />
          <ErrorIcon />
        ),
        ...getToastSetObject,
      });
      break;
    case "error":
      toast.error(text, {
        icon: () => (
          // <img
          //   src="https://obstatic.243096.com/mili/images/toast/errorToastIcon.png"
          //   className="error-icon-style"
          // />
          <ErrorIcon />
        ),
        ...getToastSetObject,
      });
      break;
  }
};

export { toastTip };
