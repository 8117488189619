import { Box, Flex, Text, Input, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { throttle } from "lodash";
import { requestGoogleAuthVerify } from "@/pages/resetAccountInfo/api";
import { useI18n } from "@/hooks/useI18n";
const Ga = (props) => {
  const { getStep, request_id } = props;
  const { t } = useI18n();
  const codeRule = /^\d{6}$/;
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [nextError, setNextError] = useState(false);
  const [codeText, setCodeText] = useState("");
  const checkCode = throttle(async () => {
    if (code) {
      setNextError(true);
      const res = await requestGoogleAuthVerify({
        request_id,
        code: code,
      });
      if (res.code === 0) {
        setNextError(false);
        getStep(4);
      } else {
        setNextError(false);
      }
    } else {
      setCodeError(true);
    }
  }, 5000);

  const codeChange = (e) => {
    const codeVal = e.target.value;
    if (codeVal) {
      setCodeError(false);

      if (codeRule.test(codeVal)) {
        setCode(codeVal);
        setCodeError(false);
      } else {
        setCodeText(t("yCCsuXAjuRzOLNxo"));
        setCodeError(true);
      }
    } else {
      setCodeText(t("LuigfaXzmPRCftdFOV"));
      setCodeError(true);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Flex justifyContent={"center"} mt={["100px", "200px"]}>
        <Box w={["80%", "450px"]}>
          <Text fontWeight={"600"} color="t.100" fontSize={"25px"}>
            {t("sZVlKBQCGGTVChpa")}
          </Text>
          <Text
            fontSize="16px"
            fontWeight={"400"}
            color="t.100"
            mt="50px"
            mb="20px"
          ></Text>
          <Text fontSize="16px" color="t.100">
            {t("OXrwZMwkWAjoSDDALzow")}
          </Text>
          <Flex mt="5px" w={["100%", "400px"]}>
            <Input
              h="44px"
              borderRadius={"12px"}
              placeholder={t("cTlSqXgGENkCAQQJCHx")}
              fontSize="12px"
              isInvalid={codeError}
              bg={codeError ? "red.50" : "none"}
              errorBorderColor="red.100"
              _placeholder={{ color: "t.50" }}
              onBlur={codeChange}
            />
          </Flex>
          {codeError && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {codeText}
            </Text>
          )}
          <Box>
            {!nextError ? (
              <Button
                borderRadius={"20px"}
                w={["100%", "400px"]}
                h="44px"
                fontSize="14px"
                bg="blue.100"
                color="#fff"
                _hover={{ bg: "blue.80" }}
                mt="50px"
                onClick={checkCode}
              >
                {t("tOaRBVUJHpnSgDBrGON")}
              </Button>
            ) : (
              <Button
                borderRadius={"20px"}
                w={["100%", "400px"]}
                _hover={{ bg: "t.50" }}
                disabled="true"
                h="44px"
                cursor={"not-allowed"}
                fontSize="14px"
                bg="t.50"
                color="#fff"
                mt="50px"
              >
                {t("tOaRBVUJHpnSgDBrGON")}
              </Button>
            )}
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

export default Ga;
