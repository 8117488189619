import { DatePicker } from "antd";
import { Button, Flex, Box } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { useAppSelector } from "@/state/hooks";
import ZHlocale from "antd/es/date-picker/locale/zh_CN";
import { useState } from "react";
import { getDayStatement } from "./api";
import { toastTip } from "@/components/ui/toast";

const DayOrder = () => {
  const { t } = useI18n();
  const _lang = useAppSelector((state) => state.user.language);
  const [date, setDate] = useState("");
  const [isFound, setIsFound] = useState(false);
  const [downLinkUrl, setDownLinkUrl] = useState("");

  const handleGetStatement = async (date) => {
    const res = await getDayStatement(date);
    if (res.code === 0) {
      setIsFound(true);
      setDownLinkUrl(res.data.url);
    } else if (res.code === 404) {
      toastTip(t("RsjGGXbMJbrislSlWM"), { type: "error" });
    }
  };

  return (
    <Flex flexDirection={"column"} padding={"30px"} minHeight={"312px"}>
      <Flex alignItems={"flex-start"}>
        <DatePicker
          style={{
            width: "280px",
            color: "#b3b4c8",
            height: "44px",
            borderRadius: "12px",
          }}
          placeholder={t("cXhPoUARLvRqvesHQqeu")}
          onChange={(date, dateString) => {
            if (dateString) {
              setDate(dateString);
            }
          }}
          locale={_lang === "en" ? "" : ZHlocale}
        />
        <Button
          fontSize="14px"
          w="150px"
          ml={"10px"}
          bg="#333BF5"
          color="#FFF"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => {
            if (date) {
              handleGetStatement(date);
            }
          }}
          _hover={{ bg: "blue.80" }}
        >
          {t("tBDAaKFwVBeiyQnnTDIA")}
        </Button>
      </Flex>
      {isFound && (
        <Box
          mt={"20px"}
          borderRadius={"12px"}
          height={"44px"}
          placeSelf={"flex-start"}
          padding={"0px 15px"}
          textAlign={"center"}
          lineHeight={"44px"}
          cursor={"pointer"}
          color={"#010313"}
          fontSize={"14px"}
          border={"1px solid #E3E4F1"}
          onClick={() => {
            window.open(downLinkUrl, "_target");
          }}
          _hover={{
            backgroundColor: "#EDEFFC",
            color: "#333BF5",
          }}
        >
          {date && `${date.replace(/-/g, "/")} ${t("xDfYpxodvMVDQJKav")}`}
        </Box>
      )}
    </Flex>
  );
};

export default DayOrder;
