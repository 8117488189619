// 低风险
import { useI18n } from "@/hooks/useI18n";
import { Flex, Image, Box, Text } from "@chakra-ui/react";
import BXNSelect from "@/components/ui/WSelect";
import { BXInput } from "@/styles";
import { useMedia } from "@/hooks/useMedia";
import useConstantsHooks from "../../common/constantsHooks";
import BXUpload from "@/components/ui/BXUpload";

const FirstPage = ({
  setUpdateInfoListOption,
  OPTION_TYPE,
  delOrAddMulUploadList,
  updateInfoList,
  delOrAddUpdasteList,
  MEMO_SERVICE_OPTIONS,
  serviceInputValue,
  setServiceInputValue,
  monthTxFrequencyInputValue,
  setMonthTxFrequencyInputValue,
  foundSourceInputValue,
  setFoundSourceInputValue,
  serviceOtherDescInputValue,
  setServiceOtherDescInputValue,
  handleUploadFile,
  resetMulUploadList,
  memoUpdateFilterOptions,
  resetOtherKeyValue,
  MEMO_SELECT_OPTIONS,
}) => {
  const { t } = useI18n();

  const {
    memoAnticipatedMonthlyTxAmount,
    memoAnticipatedMonthlyTxFrequency,
    memoCompanyFoundSource,
  } = useConstantsHooks();

  const { isMobile } = useMedia();

  const renderSelectItem = (item) => {
    console.log("[[renderSelectItem]]", item);
    return (
      <Flex alignItems={"center"}>
        <Text fontSize="12px">{item.label}</Text>
      </Flex>
    );
  };

  // 渲染上传文件类型
  const renderMulFileTypeCore = (index) => {
    const curr = updateInfoList[index];
    const mul_upload_file_list = curr.mul_upload_file_list;
    return (
      <Box>
        {mul_upload_file_list.map((d, i) => {
          return (
            <Box key={i}>
              <Text mt={"10px"} fontSize={"14px"} fontWeight={600}>
                {t("oWOQzlGOqirSVhUhQX")}
              </Text>
              <BXUpload
                style={{ marginTop: "5px" }}
                handleUploadChange={(e) => {
                  handleUploadFile({
                    uploadType: "MUL_FILE",
                    event_core: e,
                    faIndex: index,
                    currIndex: i,
                  });
                }}
                error={d.error}
                fileName={d.fileName}
              ></BXUpload>
              {i !== 0 && (
                <Text
                  onClick={() => delOrAddMulUploadList("delete", index, i)}
                  color={"#D04457"}
                  fontSize={"12px"}
                  textAlign={"right"}
                  mt={"2px"}
                  cursor={"pointer"}
                >
                  {t("eUvzxeVvXrffrpEOrFrP")}
                </Text>
              )}
            </Box>
          );
        })}
        <Flex
          mt={"20px"}
          onClick={() => delOrAddMulUploadList("add", index)}
          cursor={"pointer"}
        >
          <Image
            src={`../../wwwsite/images/kyc/add.png`}
            width={"24px"}
            height={"24px"}
          />
          <Text fontSize={"14px"} color={"#333BF5"} cursor={"pointer"}>
            {t("KOycuFuoXkNBrUCLtCVL")}
          </Text>
        </Flex>
      </Box>
    );
  };

  // 渲染业务性质选项
  const renderServiceTypeCore = (index) => {
    const curr = updateInfoList[index];
    return (
      <Box mt={"20px"}>
        <Text fontSize={"12px"} color={"#010313"}>
          {t("fxjdlTQeJFVyjFXmhlnI")}
        </Text>
        {MEMO_SERVICE_OPTIONS.map((d, i) => {
          return (
            <Flex mt={"17px"} key={i}>
              <Image
                onClick={() => {
                  setUpdateInfoListOption(index, d, "service_option");
                  if (d.value !== 6) {
                    setServiceOtherDescInputValue("");
                  }
                }}
                cursor={"pointer"}
                src={`../../wwwsite/images/kyc/radio${
                  curr?.service_option?.value === d.value ? "_checked" : ""
                }.svg`}
              />
              <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                {d.label}
              </Text>
            </Flex>
          );
        })}
        {/* 选择其他时候有一个说明文字框 */}
        {curr?.service_option?.value === 6 && (
          <BXInput
            placeholder={t("WjPizXTxbdPnvLWMql")}
            w={"calc(100% - 40px)"}
            ml={"40px"}
            maxLength={100}
            value={serviceOtherDescInputValue}
            onChange={(e) => {
              setServiceOtherDescInputValue(e.target.value);
            }}
          />
        )}
        <BXInput
          placeholder={t("aVbJYJuVEhKXAqlbWgUE")}
          value={serviceInputValue}
          onChange={(e) => {
            const val = e.target.value;
            if (val?.length <= 300) {
              setServiceInputValue(val);
            }
          }}
        />
      </Box>
    );
  };

  // 渲染月估计交易额
  const renderMonthTxAmountCore = (index) => {
    const curr = updateInfoList[index];
    return (
      <Box mt={"20px"}>
        {memoAnticipatedMonthlyTxAmount.map((d, i) => {
          return (
            <Flex mb={"17px"} key={i}>
              <Image
                onClick={() =>
                  setUpdateInfoListOption(index, d, "monthly_tx_amount_option")
                }
                cursor={"pointer"}
                src={`../../wwwsite/images/kyc/radio${
                  curr?.monthly_tx_amount_option?.value === d.value
                    ? "_checked"
                    : ""
                }.svg`}
              />
              <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                {d.label}
              </Text>
            </Flex>
          );
        })}
      </Box>
    );
  };
  // 渲染月估交易频率
  const renderMonthTxFrequencyCore = (index) => {
    const curr = updateInfoList[index];
    return (
      <Box mt={"20px"}>
        {memoAnticipatedMonthlyTxFrequency.map((d, i) => {
          return (
            <Flex mb={"17px"} key={i}>
              <Image
                onClick={() =>
                  setUpdateInfoListOption(
                    index,
                    d,
                    "monthly_tx_frequency_option"
                  )
                }
                cursor={"pointer"}
                src={`../../wwwsite/images/kyc/radio${
                  curr?.monthly_tx_frequency_option?.value === d.value
                    ? "_checked"
                    : ""
                }.svg`}
              />
              <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                {d.label}
              </Text>
            </Flex>
          );
        })}
        {curr?.monthly_tx_frequency_option?.value === 1 && (
          <BXInput
            placeholder={t("WjPizXTxbdPnvLWMql")}
            value={monthTxFrequencyInputValue}
            maxLength={100}
            onChange={(e) => {
              setMonthTxFrequencyInputValue(e.target.value);
            }}
          />
        )}
      </Box>
    );
  };

  // 渲染公司资金来源
  const renderFoundSource = (index) => {
    const curr = updateInfoList[index];
    return (
      <Box mt={"20px"}>
        <Text fontSize={"12px"} color={"#010313"}>
          {t("fxjdlTQeJFVyjFXmhlnI")}
        </Text>
        {memoCompanyFoundSource.map((d, i) => {
          return (
            <Flex mt={"17px"} key={i}>
              <Image
                onClick={() =>
                  setUpdateInfoListOption(index, d, "found_source_option")
                }
                cursor={"pointer"}
                src={`../../wwwsite/images/kyc/${
                  curr?.found_source_option?.findIndex(
                    (x) => x.value === d.value
                  ) > -1
                    ? "checked"
                    : "no_check"
                }.svg`}
              />
              <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                {d.label}
              </Text>
            </Flex>
          );
        })}
        <BXInput
          placeholder={t("aVbJYJuVEhKXAqlbWgUE")}
          value={foundSourceInputValue}
          maxLength={300}
          onChange={(e) => {
            const val = e.target.value;
            if (val?.length <= 300) {
              setFoundSourceInputValue(val);
            }
          }}
        />
      </Box>
    );
  };

  return (
    <>
      {updateInfoList?.length > 0 &&
        updateInfoList.map((d, i) => {
          return (
            <Flex flexDirection={"column"} key={i}>
              <Flex
                mb={"10px"}
                fontWeight={500}
                fontSize={"14px"}
                mt={"50px"}
                justifyContent={"space-between"}
              >
                <Text>{t("HbkwQxpXzqUXEvkxVmz")}</Text>
                {i !== 0 && (
                  <Text
                    color={"#D04457"}
                    cursor={"pointer"}
                    onClick={() => delOrAddUpdasteList("delete", i)}
                  >
                    {t("eUvzxeVvXrffrpEOrFrP")}
                  </Text>
                )}
              </Flex>
              <BXNSelect
                key={i}
                options={memoUpdateFilterOptions}
                defaultOpt={MEMO_SELECT_OPTIONS.find(
                  (opt) => opt.value === d?.selected_option?.value
                )}
                handleSelect={(item) => {
                  setUpdateInfoListOption(i, item, "selected_option");
                  if (item.type === OPTION_TYPE.MUL_TYPE) {
                    resetMulUploadList(i, item.type);
                  }
                  resetOtherKeyValue(i, item.type);
                }}
              ></BXNSelect>
              {d.selected_option?.type === OPTION_TYPE.MUL_TYPE &&
                renderMulFileTypeCore(i)}
              {d.selected_option?.type === OPTION_TYPE.SERVICE_TYPE &&
                renderServiceTypeCore(i)}
              {d.selected_option?.type === OPTION_TYPE.MONTHLY_TX_AMOUNT &&
                renderMonthTxAmountCore(i)}
              {d.selected_option?.type === OPTION_TYPE.MOTNHLY_TX_FREQUENCY &&
                renderMonthTxFrequencyCore(i)}
              {d.selected_option?.type === OPTION_TYPE.FOUND_SOURCE &&
                renderFoundSource(i)}
            </Flex>
          );
        })}

      <Flex
        borderTop={"1px solid rgba(227, 228, 241, 1)"}
        paddingTop={"20px"}
        mt={"20px"}
      >
        <Flex
          color={"rgba(51, 59, 245, 1)"}
          onClick={() => delOrAddUpdasteList("add")}
          w={"100%"}
          cursor={"pointer"}
          alignItems={"center"}
          justifyContent={"center"}
          border={"1px solid rgba(51, 59, 245, 1)"}
          borderRadius={"12px"}
          h={"44px"}
        >
          <Image
            src={`../../wwwsite/images/kyc/add.png`}
            width={"24px"}
            height={"24px"}
          />
          <Text fontSize={"14px"}>{t("yYxnWdRQrNPhFUhmKp")}</Text>
        </Flex>
      </Flex>
    </>
  );
};

export default FirstPage;
