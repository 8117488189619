import { Box, Text } from "@chakra-ui/react";

const EnDoc = () => {
  return (
    <Box>
      <Text fontSize="28px" fontWeight={"bold"} mt="30px">
        User Agreement
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        Last updated: 5 January 2024
      </Text>
      <Text mt="30px">
        Welcome to the Bixin software (hereinafter referred to as “the
        software”) and services developed and operated by NewBX Limited (CE number: BUY737) (a
        company incorporated in Hong Kong with registered address at Room 702,
        7/F., Fu Fai Commercial Centre, 27 Hillier Street, Sheung Wan, Hong
        Kong, hereinafter referred to as “the company”). Please refer to the
        company’s website at bixin.com for our licensing status, and we will
        notify you if there is a change to our licensing status. Unless
        otherwise specified, references to the company herein also include those
        to NewBX Treasures Limited (a company incorporated in Hong Kong with
        registered address at Room 702, 7/F., Fu Fai Commercial Centre, 27
        Hillier Street, Sheung Wan, Hong Kong and Trust or Company Service
        Provider Licence No. TC009090 issued by Hong Kong Companies Registry)
        ("Associated Entity”).
      </Text>
      <Text mt="30px">
        In order to use the software and services, you (hereinafter referred to
        as “you” or “user” ) must read and agree to abide by the “User
        Agreement” (hereinafter referred to as “this agreement”) and “Privacy
        Policy” of the companyʼs website. This agreement, the “Privacy Policy”,
        the “Anti-Money Laundering and Anti-Token Laundering Announcement”, the
        “Risk Disclosure Statement”, “Information in Relation to Custodian
        Arrangements”, as amended, supplemented or modified from time to time,
        shall constitute an entire agreement (“the agreements”) between both
        parties regarding the use of this software and services. If this
        agreement is inconsistent with other agreements, this agreement shall
        prevail. If there are any inconsistencies between the English versions
        and Chinese versions of agreements, the English versions shall prevail.
        Please read carefully and fully understand the terms of each clause of
        the agreements, especially the terms of exemption or limitation of
        liability, and choose to accept or not. Your acts of downloading,
        installing, using, applying for, obtaining an account, logging in, etc.
        shall be regarded as you have read and agree to be bound by the
        agreements. The company reserves the right to make any amendments and
        additions to the terms of the agreements from time to time and publish
        it in the software and services. Modifications and additions to the
        terms of the agreements will take effect immediately upon publication.
        Your continued access to and use of the software and services are deemed
        to be subject to the revised agreements. Otherwise, you have the right
        to terminate the agreements by ceasing to login and refusing to use the
        software and services.
      </Text>
      <Text mt="30px">
        You are obliged to comply with all applicable laws, regulations, rules,
        guidelines and other government regulations, without disrupting the
        normal trading order, exchange and custody of digital assets (including
        all digital assets relating to which services are provided by the
        company such as BTC, ETH, etc. from time to time), and not using the
        software and services to organize to participate in criminal activities
        such as money laundering or gambling. If any violation results in the
        occurrence of any legal consequences, you are solely responsible for all
        corresponding legal liabilities. If the user commits an illegal or
        criminal act, or if the company has relevant evidence to reasonably
        suspect that the user may be committing an illegal act or a crime, or in
        violation of the agreements, the company shall be entitled to suspend or
        terminate the service to the user, to publish such user's illegal act or
        default on the company's website and the internet (if appropriate), and
        to report to the relevant authorities and cooperate with them on the
        investigation activities, including but not limited to the disclosure of
        all user information, transaction records and other information as
        required by such relevant authorities. The company may implement any and
        all of the above actions, of which the resulting consequences, risks and
        responsibilities shall be borne by the user. The acceptance of the
        agreements by the user is deemed that the user understands and accepts
        the terms and permanently and irrevocably authorizes the company to
        disclose relevant information and take corresponding measures.
      </Text>
      <Text mt="30px">
        The contents of the agreements include the text of the agreements and
        various rules, statements and instructions that the company issued or
        may publish in the future. All rules, statements and instructions are
        inseparable parts of the agreements and have the same legal effect as
        the context of the agreements.
      </Text>
      <Text mt="30px">
        If you sign up for, register to use, and use the software and services
        on behalf of an institution and not you, you will be deemed to be fully
        authorized to represent the relevant institution’s agreement to the
        terms of this agreement and the terms of this agreement from time to
        time as modified and supplemented.
      </Text>
      <Text mt="30px">
        You undertake and agree to notify the company immediately of any
        material change to your full name, address, and any other material
        information provided to the company for the provision of software and
        services. Failure to do so may result in the termination or suspension
        of services.
      </Text>
      <Text mt="30px">
        The company undertakes and agrees to notify you, either in writing or by
        drawing your attention to updates posted on the company’s website, of
        any material change to:
      </Text>
      <Text mt="10px">
        (i) the full name and address of the company’s business;
      </Text>
      <Text mt="10px">
        (ii) the company’s licensing status with the SFC and its CE number;
      </Text>
      <Text mt="10px">
        (iii) the nature of software and services provided to you;
      </Text>
      <Text mt="10px">
        (iv) the description of remuneration and any fees to be paid by you; and
      </Text>
      <Text mt="10px">
        (v) any other material information in relation to the software and
        services provided
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        I. The software and services
      </Text>

      <Text mt="10px">
        1. This software and service enable you to acquire or dispose of virtual
        assets through the platform operated by the company. The company may
        also provide off-platform service, which consists of the
        over-the-counter (“OTC”) trading desk and the request-for-quote (“RFQ”)
        services. In addition, the company provides custody services that it
        holds your assets on trust through our Associated Entity. (the detailed
        service scope is also set out on the company‘s website).
      </Text>
      <Text mt="10px">
        2. For the purpose of using the software and services, you need to
        download the software client through the website （
        <a href="https://bixin.com/" style={{ color: "#3370ff" }}>
          bixin.com
        </a>
        ）or other channels approved by the company. The company grants you
        personal, non-transferable and non-exclusive use permission. You may
        only access and use the Software and Services for your own use.
      </Text>
      <Text mt="10px">
        3. The software and services may have different features from time to
        time, and the use of each feature and its limitations will be subject to
        the specific business rules for that feature. The company has the right
        to formulate and revise these specific business rules from time to time
        and publish such rules. Your use or continued use of such features
        constitutes your agreement and acceptance of such rules and amendments.
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        II. Registration and account acquisition
      </Text>
      <Text mt="10px">
        1. You need to register a Bixin account before using the software and
        services. The Bixin account is registered with the mobile phone number
        and /or email address (depending on the specific version). We will
        create a Bixin account for you based on the mobile phone number and/or
        email address you provide for registration. If you have previously
        registered as a user on the companyʼs website using the same mobile
        phone number and/or email address , the Bixin account registered using
        the mobile phone number and/or email address will be automatically
        associated with the account you have registered on the companyʼs website
        using the mobile phone number and/or email address .
      </Text>
      <Text mt="10px">
        2. The ownership of the Bixin account belongs to the company. After the
        user completes the registration procedure, he only obtains the right to
        use the Bixin account, and the right to use only belongs to the
        registrant himself. The registrant of a Bixin account shall not in any
        way provide the right to use the account with or without compensation to
        a third party, including but not limited to transfer, gift, lease, loan,
        license. If the registrant of the Bixin account violates the
        above-mentioned agreement, the consequences and responsibilities thereof
        shall be borne by the registrant themselves. If the loss is caused to
        the company, full compensation shall be paid to the company. The company
        shall have the right to recover the right to use the account.
      </Text>
      <Text mt="10px">
        3. You should keep your Bixin account ID and password properly. Entering
        this information accurately will be a key link for you to log in and use
        the software and services. You acknowledge and agree that any act that
        occurs under your account will be deemed to be or have been legally
        authorized by you and will bear all legal consequences. If you find that
        someone has used your account without authorization, you should change
        your password immediately and give us feedback. Despite the foregoing
        stipulations, under no circumstances will the company assume any
        responsibility for any loss or damage caused by your account number,
        password, login information error, loss, or other circumstances caused
        by your failure to ensure the security of Bixin account information.
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        III. The user notes
      </Text>
      <Text mt="10px">
        1. You understand and agree that in order to protect your data storage
        security and diversify risks, you authorize the Company to transfer your
        data to third parties in accordance with certain rules and proportions
        (as specified in the company’s Privacy Policy), and authorize the
        Company to entrust the third party to handle related matters, within the
        scope of the agreed services at the first in this agreement.
      </Text>
      <Text mt="10px">
        2. You understand and agree that the company will use reasonable
        commercial efforts to safeguard your data storage in the software and
        services, however, the company does not provide you with any guarantee
        in any form or nature, including but not limited to the following:
      </Text>
      <Text mt="10px">
        (1) the company will not bear any responsibility for the deletion or
        storage of relevant data in the software and services;
      </Text>
      <Text mt="10px">
        (2) the company has the right to determine the maximum storage period of
        individual usersʼ data in the software and services according to the
        actual situation, and allocate the maximum storage space for the data on
        the server. You can back up the relevant data in this software and
        service according to your own needs, and bear the corresponding risks;
      </Text>
      <Text mt="10px">
        (3) if you stop using the software and the service or service is
        terminated or cancelled (including permanent account closure), the
        company may permanently delete your data from the server. After the
        service is stopped, terminated or cancelled, the company is not obliged
        to return any data to you, subject to the requirement of the Personal
        Data (Privacy) Ordinance (Cap. 486 of Hong Kong) ("PDPO”).
      </Text>
      <Text mt="10px">
        3. Based on the open nature of the Internet, you are aware that users
        uploading pictures and other data to the Internet may be copied,
        reproduced, modified or otherwise used by other organizations or
        individuals. You must be fully aware of such risks. exist. You expressly
        agree that the use of the software and services will be entirely at your
        own risk; all consequences of your use of the software and services are
        your responsibility.
      </Text>
      <Text mt="10px">
        4. When the user uses the specific functions of the software and
        services, the company may call a third-party system or support the
        userʼs use or access through a third party. The results of the use or
        visit are provided by the third party (including but not limited to the
        service provided by the Bixin public account, or the content accessed
        through the open platform, etc.), the company does not make any
        commitment or assume any responsibility for the security, accuracy,
        validity and other matters of the third party providing the service and
        content, and the related risks and the responsibility lie with the user.
        When you use these third-party products or services, in addition to
        complying with the terms of this agreement, you must also comply with
        third-party user agreements. Any dispute arising from third-party
        software or technology used in this software shall be resolved by the
        third party, and the company shall not assume any responsibility. The
        company does not provide customer service support for third party
        software or technology. If you need support, please contact the third
        party directly.
      </Text>
      <Text mt="10px">
        5. You understand and agree that under no circumstances whatsoever the
        company shall be liable for failures of the Internet connection,
        computer, communication or other system failures, power failures,
        strikes, labour disputes, riots, uprisings, riots, fires, or floods,
        storms, explosions, force majeure, wars, government actions, orders from
        international and domestic courts, third-party inaction or any reason
        that cannot be reasonably controlled by the company, inaccessibility of
        the software and services, delays in information and data, stagnation,
        or errors, failure to provide or delay in providing services.
      </Text>
      <Text mt="10px">
        6. If the information network connection fails, computer, communication,
        power or other system failures, staff failures, etc., cause the company
        to make double payment or pay more digital assets to the users, the user
        is obligated to return to the company the extra payment or digital
        assets immediately, in any case, the user shall complete the return
        within 10 days from the date of receiving reasonable notice and remind
        from the company. You also agree that the company has the right to
        deduct the same amount directly from your account, in fiat or digital
        assets, or the extra payment or digital assets, at its absolute
        discretion upon expiry of the said 10 days.
      </Text>
      <Text mt="10px">
        7. If any digital assets transferred between users are wrongly accounted
        for, they must be resolved by the users. The company is not responsible
        for helping to revoke or freeze the digital assets involved in the
        corresponding operations or take any other measures, nor does it take
        any responsibility in this regard.
      </Text>
      <Text mt="10px">
        8. If a user has a dispute with other users due to online transactions,
        the user must apply to the relevant judicial, governmental and/or
        administrative authority(ies) to obtain relevant information from the
        company. The company will not accept other methods of obtaining the
        application and is not responsible for providing relevant information
        through other channels
      </Text>
      <Text mt="10px">
        9. You understand and agree that the company has the right to decide to
        use the software and services for commercial purposes. Subject to the
        requirement of the PDPO, the company promises to protect your personal
        information in accordance with the agreement in the promotion process,
        and you can also choose to block or reject according to the system
        settings.
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        IV. Software updates, changes and service interruption, termination
      </Text>
      <Text mt="10px">
        1. In order to improve the user experience and improve the service
        content, the company will continue to develop new features or versions,
        and provide you with software updates from time to time (these updates
        may take the form of software replacements, modifications, enhancements,
        version upgrades, etc.).
      </Text>
      <Text mt="10px">
        2. In order to ensure the consistency of the security and functionality
        of the software and services, the company reserves the right to update
        the software without notice to you, or to change or limit the
        functionality of some of the software from time to time. After the
        release of the new version of this software, the old version of the
        software may not be available. Please check and download the latest
        version at any time. The failure to use the latest version to download
        and use the latest version will be at your own risk. If you continue to
        use the software and services, you agree to accept the updated software
        and services. If you refuse to accept the updated software and services,
        you should immediately stop logging in and use the software and
        services; in such cases, you still need to take full responsibility for
        your actions that occurred before you stopped using such software and
        services.
      </Text>
      <Text mt="10px">
        3. You understand and agree that the company reserves the right to
        suspend services, adjust service features, or terminate services due to
        software, service maintenance, upgrades, or other reasons. The company
        will make public announcements or notices in advance on the company
        website or in the software. You agree that the company will not be
        liable to you for this.
      </Text>
      <Text mt="10px">
        4. If the company terminates the software and services, or if the
        company terminates the provision of the software and services to users
        pursuant to the stipulations of this agreement or laws, rules and
        regulations of the relevant jurisdiction(s), the company has the right
        to return the digital assets in the user account via the original route
        or according to relevant laws, rules and/or regulations of the relevant
        jurisdiction or such other order from the relevant regulator(s) and/or
        authority(ies). The provisions of the regulations are dealt with. If you
        fail to return due to user reasons or have other clearing obstacles, all
        risks, losses and liabilities shall be borne by the user.
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        V. The user behaviour specification
      </Text>
      <Text mt="10px">(I) Compliance with Local Laws</Text>
      <Text mt="10px">
        It is the users' responsibility to abide by local laws and other
        applicable laws and regulations in relation to the use of the software
        and services in the relevant jurisdiction(s). The users must also
        consider taxation matters in all respects in accordance with the
        relevant laws, rules and/or regulations including but not limited to the
        withholding, collection, reporting and remittance to their appropriate
        tax authorities. ALL users of the software and the services warrant and
        represent that their funds come from legitimate sources and do not
        originate from illegal activities; users agree that the company will
        require them to provide or otherwise collect the necessary information
        and materials in accordance with the relevant laws or government orders
        to verify the legality of the sources and use of their funds. The
        company cooperates with law enforcement authorities globally and will
        not hesitate to seize, freeze, revoke license, terminate or investigate
        the usersʼ accounts and funds.
      </Text>
      <Text mt="10px">
        (II) Specification of legality of information content{" "}
      </Text>
      <Text mt="10px">
        The user promises to ensure the legality of any content (including but
        not limited to the information such as the head image, name, user
        description, etc. of Bixin account profiles, or the sending and reply of
        text, voice, picture, etc., as well as other content generated by the
        use of the Bixin account or the software and services) produced, copied,
        released and disseminated in the process of using the software and
        services. The user promises not to use Bixin account or the software and
        services to create, reproduce, publish, or disseminate the following as
        part of the normal operations of interfering operations of the company
        and infringement of the legal rights of other users or third parties,
        including but not limited to:
      </Text>
      <Text mt="10px">
        1. Distribute, transmit, disseminate, store contents prohibited by
        relevant laws and regulations.
      </Text>
      <Text mt="10px">
        2. Distribute, transmit, disseminate, and store content that infringes
        other parties’ right to reputation, portrait rights, intellectual
        property rights, trade secrets, etc.
      </Text>
      <Text mt="10px">
        3. Involving other parties’ privacy, personal information or
        information.
      </Text>
      <Text mt="10px">
        4. Post, transmit, transmit harassment, advertisement information,
        over-marketing information and spam or contain any sexually or sexually
        suggestive information.
      </Text>
      <Text mt="10px">
        5. Other information that violates laws, regulations, policies and
        public morals, public morality, or the normal operation of the company
        and infringes the legitimate rights and interests of other users or
        third parties.
      </Text>
      <Text mt="10px">(III) Specification of legality of software use</Text>
      <Text mt="10px">
        1. The userʼs use of the software and services must respect and
        safeguard the normal and stable operation of the companyʼs intellectual
        property and information. The user promises not to commit any activities
        that may infringe the intellectual property rights of the company or
        affect the normal operation of the company, including but not limited
        to:{" "}
      </Text>
      <Text mt="10px">
        (1) Delete the copyright information on the software;
      </Text>
      <Text mt="10px">
        (2) Reverse engineer, reverse assemble, reverse compile, or otherwise
        try to find the source code of the software;
      </Text>
      <Text mt="10px">
        (3) To use, rent, lend, copy, imitate, modify, reproduce, compile,
        publish, and establish mirror sites for the companyʼs own intellectual
        property content;
      </Text>
      <Text mt="10px">
        (4) Copy, modify, add, and delete the data released to any terminal
        memory during running of the software or the services, the interaction
        data between the client and the server during the running of the
        software, and the system data necessary for the operation of the
        software, engage in the operation or creation of any derivative works,
        including but not limited to the use of plug-ins, plug-ins, or
        unauthorized third-party tools/services to access the software and
        related systems;
      </Text>
      <Text mt="10px">
        (5) By modifying or falsifying the instructions and data in the software
        operation, adding, deleting, changing the function or operating effect
        of the software, or operating or publicizing the software and methods
        used for the above purpose, regardless of whether these actions are
        Business purpose;
      </Text>
      <Text mt="10px">
        (6) Log in or use the software and services, or create, publish, and
        disseminate the above mentioned tools through third-party software,
        plug-ins, plug-ins, and systems not developed and authorized by the
        company;
      </Text>
      <Text mt="10px">
        (7) Interfere with the software and its components, modules, and data on
        its own or by authorizing others or third-party software.
      </Text>
      <Text mt="10px">
        2. The following actions must not occur during the use of the software
        and service by users:
      </Text>
      <Text mt="10px">
        (1) submitting, posting false information, or impersonating or
        exploiting another party’s name;
      </Text>
      <Text mt="10px">
        (2) inducing other users to click on the link page or share information;
      </Text>
      <Text mt="10px">
        (3) falsifying facts and concealing the truth to mislead or deceive
        others;
      </Text>
      <Text mt="10px">
        (4) infringement of other parties’ legal rights such as reputation
        rights, portrait rights, intellectual property rights, trade secrets,
        etc.;
      </Text>
      <Text mt="10px">
        (5) to provide the Bixin account or any of its functions to third
        parties in any way, or for any commercial purpose;
      </Text>
      <Text mt="10px">
        (6) to engage in any illegal or criminal activities using the Bixin
        account or the software and services;
      </Text>
      <Text mt="10px">
        (7) produce and publish methods or tools related to the above actions,
        or operate or disseminate such methods or tools, whether or not these
        actions are for commercial purposes;
      </Text>
      <Text mt="10px">
        (8) other violations of laws and regulations, infringement of the
        legitimate rights and interests of other users, and interference with
        the normal operation of the software and services.
      </Text>

      <Text mt="10px">(IV) Breach of contract</Text>

      <Text mt="10px">
        1. If the company discovers or receives reports from others or complains
        that the user has violated this agreement, the company reserves the
        right to delete or block the relevant user(s) at any time without
        notice, and to include, but is not limited to, warnings and restrictions
        on the offending account, depending on the circumstances of the act. It
        is forbidden to use some or all of the functions, account closure, and
        cancellation of the penalty, and announce the results.
      </Text>
      <Text mt="10px">
        2. You understand and agree that the company has the right to punish
        violations of relevant laws and regulations or the provisions of this
        agreement in accordance with reasonable judgment, take appropriate legal
        action against any user who violates the law and regulations, and save
        relevant information in accordance with laws and regulations to relevant
        departments. The report, etc., shall be solely responsible for all legal
        liabilities arising therefrom.
      </Text>
      <Text mt="10px">
        3. You understand and agree that you shall be solely liable for any
        claims, demands or losses caused by or arising out of any third party
        claim arising out of your breach of this agreement or related terms of
        service, and you shall also be liable for any and all loss incurred by
        the company.xxx
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        VI. Intellectual property rights
      </Text>

      {/* 30 */}
      <Text mt="10px">
        1. All intellectual achievements included in the software and services
        include, but are not limited to, copyrights for website logos,
        databases, website designs, text and graphics, software, photos,
        software compilations, source code, applications, and application
        programming interfaces, and any other copyrights and other intellectual
        property rights related to the software and services are owned by the
        company. Users may not copy, alter, copy, imitate, transmit or use any
        of the aforementioned materials or content. You may not implement, use,
        transfer or license others to implement the above-mentioned intellectual
        property for any purpose. Any unauthorized use of the software and
        services is an illegal act and the company will investigate relevant
        legal liabilities.
      </Text>
      <Text mt="10px">
        2. All rights and interests (including but not limited to goodwill,
        trademarks, and logos) contained in the names of the software and
        services are owned by the company.
      </Text>

      {/* 32 */}
      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        VII. Privacy Policy
      </Text>
      <Text mt="10px">
        1. In order to provide the software and services, the user agrees with
        the companyʼs registration of user information (including personal
        identification information of the user), the userʼs access to the
        software and the userʼs IP address, such as the IP address of the user
        receiving the service letter, data of the userʼs transaction, and the
        userʼs transaction data. Other user information obtained through legal
        methods is recorded, saved, and used. The company will not provide,
        sell, rent, share and trade personal information of users to any
        unrelated third party. However, to facilitate the use of the software
        and services, the user agrees to conduct comprehensive statistics on the
        userʼs identity and information. The company will not disclose any
        information that may be used to identify the userʼs personal identity.
        However, the company does not assume any responsibility for the analysis
        of the userʼs personal identification information from the userʼs user
        name or other information that can be disclosed.
      </Text>
      <Text mt="10px">
        2. The user agrees that the company may disclose or use the userʼs
        personal information to identify and confirm the userʼs identity,
        resolve disputes, or to ensure the security of the software and
        services, and prevent or limit illegal or criminal activities. The user
        agrees that the company may disclose or use the userʼs personal
        information to protect the userʼs life and property or to prevent
        serious infringement of the legal rights of others or the needs of the
        public interest. The user agrees that the company may disclose or use
        the userʼs personal information to improve the software and services,
        and make the software and services more in line with the userʼs
        requirements, thereby enabling users to get a better experience when
        using the software and services. The user agrees that the company uses
        the userʼs personal information to communicate with the user and
        provides the user with information that may be of interest to the user,
        such as: commercial short messages to introduce and promote services or
        offers.
      </Text>
      <Text mt="10px">
        3. When the company is forced by law or provides information about the
        user according to the governmentʼs, court’s or the rights holderʼs
        request for identifying the alleged infringer, the company will disclose
        the userʼs data in good faith. When the company needs to protect the
        rights and property of the company, its agents, customers, users and
        others, including the implementation of the companyʼs agreements,
        policies and terms of use, the company will be a good faith disclosure
        of user information.
      </Text>
      <Text mt="10px">
        4. The user agrees that if the company intends to conduct corporate
        mergers and acquisitions, reorganization, capital increase or sale of
        all or part of the shares and/or assets, the company has the right to
        disclose the userʼs data to assist the parties in the transaction(s).
      </Text>
      <Text mt="10px">
        5. The company is not responsible for the privacy statements or other
        content of third parties other than the company.
      </Text>
      <Text mt="10px">
        6. Other privacy policies of the company are detailed in the Privacy
        Policy.
      </Text>
      <Text mt="10px">
        7. By accepting this agreement and the Privacy Policy or using the
        software and services, the user is deemed to have given express consent
        or authorization to the company to process the user's personal
        information and private data in accordance with the applicable laws and
        regulations in the relevant country or jurisdiction on the protection of
        personal information and privacy (regardless of how it is phrased),
        including but not limited to: collection, storage, use, processing,
        transmission, provision, disclosure, deletion, etc.
      </Text>
      {/* 39 */}
      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        VIII. Risk Warning
      </Text>
      <Text mt="10px">
        1. The Internet is a global public network. It is decentralized and is
        not controlled by any institution. The way the data is transmitted over
        the Internet is not entirely certain and may be subject to illegal
        interference or intrusion. Data transmitted over the Internet may be
        obtained or tampered with by some unauthorized individuals, groups or
        agencies through certain channels. Data transmission over the Internet
        may be delayed due to heavy traffic or other reasons such as
        interruptions, pauses, or incomplete data, data errors, etc., which may
        cause transactions to experience errors, delays, interruptions, or
        pauses. The userʼs identity may be revealed, counterfeited or leaked due
        to the userʼs own negligence, which may cause loss to the user. The
        userʼs computer may affect the userʼs transaction login, transaction
        time, or transaction data due to performance deficiencies, quality
        problems, computer viruses, hardware failures, and other causes, causing
        losses to the user. The loss or liability caused by the above risks
        shall be borne by the user, and the company shall not bear any
        responsibility for this. In spite of this, with the attitude of being
        responsible to users, the company will adopt advanced network products
        and technical measures to maximize the protection of user data and use
        safety.
      </Text>
      <Text mt="10px">
        2. There is a very high risk of digital asset transactions. The digital
        asset market is new, unconfirmed, and may not grow. At present, digital
        assets are mainly used by speculators, and retail and commercial markets
        are relatively less used. As a result, digital asset prices tend to
        fluctuate, which in turn adversely affects digital asset investments.
        The digital asset market has no restrictions on the price limit, trading
        is open 24 hours. Because of the small number of chips in the digital
        assets, the price is vulnerable to the control of the dealer, which may
        cause sudden rise or crash.
      </Text>
      <Text mt="10px">
        3. To participate in digital asset transactions, users should control
        their own risks, evaluate the value of investment in digital assets and
        investment risks, and bear the economic risks of losing all investments.
        Due to the formulation or revision of national laws, regulations, and
        regulatory documents, the transaction of digital assets is suspended or
        prohibited, and the economic losses caused by this are all borne by
        users. Once a user registers with the company, he or she is deemed to
        have fully understood and understood the risks of the transaction and
        agrees to assume the risks or losses that may occur.
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        IX. Dispute Resolution
      </Text>
      <Text mt="10px">
        1. The disputes arising from the software and services provided by the
        company shall be governed by the laws of the Hong Kong Special
        Administrative Region and shall be settled by arbitration to be
        administered by the Hong Kong International Arbitration Centre (“HKIAC”)
        in accordance with the rules of the HKIAC in force then.
      </Text>
      <Text mt="10px">
        2. The company is not responsible for any legal relationship and legal
        disputes arising from digital assets between users and other users of
        the company or other websites or users of the platform, nor is it liable
        for any legal liability.
      </Text>

      {/* 44 */}
      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        X. Effectiveness and Interpretation of the Agreement{" "}
      </Text>
      <Text mt="10px">
        1. This agreement shall be effective when the user clicks on the
        registration page of the company to agree to register and complete the
        registration process and obtain the account and password, which is
        binding on both the company and the user.
      </Text>
      <Text mt="10px">
        2. If some of the terms of this agreement are found to be invalid or
        unenforceable, the other terms in this agreement are still valid.
      </Text>
      <Text mt="10px">
        3. If any additional terms of service, rules of use, or descriptions
        published by the company in relation to any of the functions of the
        software and services are inconsistent with the text of this Agreement,
        such additional terms of service, rules of use or instructions shall
        apply preferentially to that particular feature.
      </Text>
      <Text mt="10px">
        4. The final interpretation right of this agreement belongs to the
        company.
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        XI. Complaints and consultations
      </Text>
      <Text mt="10px">
        The company has a professional customer service team to provide users
        with timely troubleshooting and complaint feedback. If you have any
        questions, complaints and inquiries during the use of this software and
        services, you can contact customer service (Bixin account ID: bixin) at
        any time.
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        XII. Suitability
      </Text>
      <Text mt={"10px"}>
        In conducting the relevant activities, if the company solicits the sale
        of or recommend any product including any virtual assets to you, the
        product must be reasonably suitable for you having regard to your
        financial situation, investment experience and investment objectives. No
        other provision of this agreement or any other document we may ask you
        to sign and no statement we may ask you to make derogates from this
        clause.
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        XIII. Pricing Guide
      </Text>

      <Text mt="10px">
        Limits and Fees:
        <a
          href="https://support.bixin.com/hc/en-hk/articles/16737561979795"
          style={{ color: "#3370ff" }}
        >
          bixin.com
        </a>
      </Text>
    </Box>
  );
};

export default EnDoc;
