import {
  BLOCKCHAIN_EXPLORER_URLS,
  statusKeysMap,
} from "@/pages/historyBill/helpers/constants/BlockchainBrowser";
import {
  TRANSFER_CC_EXCHANGE,
  TRANSFER_FLASH_ESCROW,
} from "@/pages/historyBill/helpers/constants/categoryName";
let moment = require("moment");

// 账单描述
const getTitle = (item, _lang, t, noShowType) => {
  let platform_extra = item.platform_extra;
  if (platform_extra) {
    //额外字段 开放平台增加字段标识vender信息:{vender:{zh:"",en:"",icon:""}}
    platform_extra = JSON.parse(platform_extra);
  }
  let title = t(item.title);
  if (title === item.title) {
    let vendors = item.title.split("-");
    let vendorsCount = vendors.length;
    title = t(vendors[0]);
    if (platform_extra.vendor) {
      if (_lang === "en") {
        title = platform_extra.vendor.en;
      } else {
        title = platform_extra.vendor.zh;
      }
    }
    if (vendorsCount > 1) {
      title = `${title}-${t(vendors[1])}`;
    }
  }
  if (!noShowType) {
    let types = item.type.split("_");
    let address = item.address;
    if (
      ["transfer", "redpacket", "escrow"].indexOf(types[0]) !== -1 &&
      types.indexOf("refund") === -1 &&
      address
    ) {
      let transferType = "";
      if (item.transfer_status === "in") {
        transferType = t("bill_transfer.from");
        title = title + transferType + "  " + address;
      } else {
        transferType = t("bill_transfer.to");
        title = title + transferType + "  " + address;
      }
    }
  }
  return title;
};

// 时间
const getTime = (timeString, formatString) => {
  let date = moment(timeString);
  let formatDate = date.format(formatString || "MM-DD HH:mm:ss");
  return formatDate;
};

// 金额
const getAmount = (item, keyName, noTransfer) => {
  var transfer = "+";
  if (item.transfer_status === "in") {
    transfer = "+";
  } else {
    transfer = "-";
  }
  let amount = item.amount;
  if (keyName) {
    amount = item[keyName];
  }
  const regexp = /(?:\.0*|(\.\d+?)0+)$/;
  amount = amount.replace(regexp, "$1");
  if (noTransfer) {
    return amount;
  } else {
    return transfer + amount;
  }
};

// 说明
const getNote = (getInfo, t) => {
  const { note, category, target_action_params } = getInfo;
  let detail = note;
  // ("A small deposit fee of 5BTC has been deducted");
  // ("扣除%s小额存币处理费");
  let detail_front = "A small deposit fee of ";
  let detail_back = " has been deducted";
  if (detail.startsWith(detail_front)) {
    //小额处理费
    let details_backs = detail.split(detail_front)[1];
    let amount = details_backs.split(detail_back)[0];
    let key = detail_front + "%s" + detail_back;
    let tmpDetail = t(key);
    detail = tmpDetail.replace("%s", amount);
  }
  if (category === TRANSFER_CC_EXCHANGE) {
    if (target_action_params != null && target_action_params !== "") {
      let cions = target_action_params.split("-");
      detail = `${cions[0]}${t("exchange")}${cions[1]}`;
    }
  }
  if (category === TRANSFER_FLASH_ESCROW) {
    detail = t("Advertisers pay the transaction fee.");
  }
  return detail;
};

// 浏览器链接
const browserUrl = (item) => {
  const { platform_extra, transaction_id, currency } = item;
  if (platform_extra) {
    const { browser_url, cloud_name } = JSON.parse(platform_extra);
    let openBlockChainExploreUrls = BLOCKCHAIN_EXPLORER_URLS;
    let url = "";
    if (browser_url) {
      url = browser_url;
      return url;
    }
    if (transaction_id) {
      // let platform_extra = this.billDetail.platform_extra;
      if (cloud_name === "trc20.usdt") {
        url = `${openBlockChainExploreUrls["TRX"]}${transaction_id}`;
        return url;
      }

      //---判断五个币种是否转向ETH
      if (["BTC", "USDT", "EOS", "LTC", "TRX"].includes(currency)) {
        if (transaction_id.slice(0, 2) === "0x") {
          let tmpCurrency = "XX2ETH";
          url = `${openBlockChainExploreUrls[tmpCurrency]}${transaction_id}`;
          return url;
        }
      }

      if (openBlockChainExploreUrls.ERC20.indexOf(currency) !== -1) {
        url = `${openBlockChainExploreUrls.ETH}${transaction_id}`;
      } else if (openBlockChainExploreUrls.EOS20.indexOf(currency) !== -1) {
        url = `${openBlockChainExploreUrls.EOS}${transaction_id}`;
      } else {
        url = `${openBlockChainExploreUrls[currency]}${transaction_id}`;
      }
    }
    return url;
  }
};

// 状态描述
const getStatusString = (item, t) => {
  const { status, type } = item;
  var statusKey = statusKeysMap[status];
  // var statusText = t(statusKey);
  let statusText = "";
  if (statusKey) {
    statusText = t(`bill_status.${statusKey}`);
  } else {
    statusText = t("bill_status.Unknown");
  }

  if (status === "SUCCESS" && type === "cc_exchange_send") {
    statusText = `${t("Pay")} ${statusText}`;
  } else if (status === "SUCCESS" && type === "cc_exchange_receive") {
    statusText = `${t("Collect")} ${statusText}`;
  } else if (status === "FROZEN") {
    statusText = `${t("wgbBNytjmVvMvqRkRL")}`;
  } else if (status === "ADMIN_REQUIRED") {
    statusText = `${t("KzFzOytWXScqDRKFwg")}`;
  } else if (status === "FROZEN_CONFIRMED") {
    statusText = `${t("pXHPsbEpWSnCyJGH")}`;
  }
  return statusText;
};

export { getTitle, getTime, getAmount, getNote, browserUrl, getStatusString };
