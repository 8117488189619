import { Text, Button, Image } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { useNavigate } from "react-router-dom";
import {updateBankInfo} from "@/state/user/reducer";
import {useDispatch} from "react-redux";

const AddBtn = () => {
  const { t } = useI18n();
  const navigator = useNavigate();
  const dispatch = useDispatch();

  return (
    <Button
      h="44px"
      bg="blue.100"
      color="#fff"
      _hover={{ bg: "blue.80" }}
      fontSize="12px"
      minW={"140px"}
      borderRadius={"30px"}
      onClick={() => {
        dispatch(updateBankInfo({bankInfo: {}}));
        navigator("/trade/legal/tender/add");
      }}
    >
      <Image
        boxSize="12px"
        src="../../wwwsite/images/add.svg"
        cursor={"pointer"}
        mr={"10px"}
      />
      <Text color={"#fff"} fontSize={"14px"}>
          {t("fXTeVLzGkVECTSGgtm")}
      </Text>
    </Button>
  );
};

export default AddBtn;
