import { Box, Flex, Text, Input, Button } from "@chakra-ui/react";
import { useState } from "react";
import { postUserCreateFunc } from "./api";
import { useAppSelector } from "@/state/hooks";
import { useDispatch } from "react-redux";
import { updateRegisterMsg } from "@/state/user/reducer";
import { throttle } from "lodash";
import { useI18n } from "@/hooks/useI18n";
import StepDesc from "./components/StepDesc";

const SetPayPwd = (props) => {
  const { t } = useI18n();
  const { setRegisterStep } = props;
  const [pwd, setPwd] = useState("");
  const [rePwd, setRePwd] = useState("");
  const [isInvalidPayPwd, setIsInvalidPayPwd] = useState(false);
  const [isInvalidRePayPwd, setIsInvalidRePayPwd] = useState(false);
  const [nextError, setNextError] = useState(false);
  const dispatch = useDispatch();
  const registerMsg = useAppSelector((state) => state.user.registerMsg);
  const registerPhone = useAppSelector((state) => state.user.registerPhone);
  const payPwdRule = /^\d{6}$/;
  const payPwdChange = (event) => {
    const payPwd = event.target.value;
    if (payPwdRule.test(payPwd)) {
      setIsInvalidPayPwd(false);
      setPwd(payPwd);
    } else {
      setPwd("");
      setIsInvalidPayPwd(true);
    }
  };

  const rePayPwdChange = (event) => {
    const rePwd = event.target.value;
    if (rePwd === pwd) {
      setRePwd(rePwd);
      setIsInvalidRePayPwd(false);
    } else {
      setRePwd("");
      setIsInvalidRePayPwd(true);
    }
  };

  const createAccount = throttle(async () => {
    if (!pwd) {
      return setIsInvalidPayPwd(true);
    }
    if (!rePwd) {
      return setIsInvalidRePayPwd(true);
    }
    if (pwd !== rePwd) {
      return setIsInvalidRePayPwd(true);
    }
    setNextError(true);
    const registerRes = await postUserCreateFunc({
      email: registerMsg.email,
      loginPassword: registerMsg.pwd,
      paymentPassword: pwd,
      phone_code: registerPhone.phoneCode,
      phone_number: registerPhone.phoneNumber,
    });
    if (registerRes.data) {
      const user = registerRes.data;
      setNextError(false);
      localStorage.setItem("token", user.token);
      dispatch(
        updateRegisterMsg({
          registerMsg: {
            rePwd,
          },
        })
      );
      setRegisterStep("SUCCESS");
    } else {
      setNextError(false);
    }
  }, 5000);

  return (
    <div>
      <Flex justifyContent={"center"} mt={["100px", "200px"]} mb={"60px"}>
        <Box w={["80%", "400px"]}>
          <Text fontSize="32px" fontWeight={"600"} color="t.100">
            {t("wjeaGDSVTuLLjJGehN")}
          </Text>

          <StepDesc active={3} />
          <Text
            fontSize="16px"
            fontWeight={"500"}
            color="t.100"
            mt="50px"
            mb="20px"
          >
            {t("FClYxqPqiIjfTGZZthn")}
          </Text>
          <Box mt="20px">
            <Text fontSize="14px" color="t.100">
              {t("sjqwCBQBKOIAKZymEnSd")}
            </Text>
            <Box mt="5px">
              <Input
                h="44px"
                borderRadius={"12px"}
                type="password"
                isInvalid={isInvalidPayPwd}
                bg={isInvalidPayPwd ? "red.50" : "none"}
                errorBorderColor="red.100"
                _placeholder={{ color: "t.50" }}
                onBlur={payPwdChange}
                placeholder={t("cTlSqXgGENkCAQQJCHx")}
                fontSize="12px"
              />
            </Box>
            {isInvalidPayPwd && (
              <Text color="red.100" fontSize="12px" mt="5px">
                {t("cNmmUqRZfZDUxgWTQeXm")}
              </Text>
            )}
          </Box>
          <Box mt="20px">
            <Text fontSize="14px" color="t.100">
              {t("lKLpdmEuElAKKvltT")}
            </Text>
            <Box mt="5px">
              <Input
                h="44px"
                borderRadius={"12px"}
                type="password"
                placeholder={t("coPPHMNHpJdMYeKPskDY")}
                isInvalid={isInvalidRePayPwd}
                bg={isInvalidRePayPwd ? "red.50" : "none"}
                errorBorderColor="red.100"
                _placeholder={{ color: "t.50" }}
                onBlur={rePayPwdChange}
                fontSize="12px"
              />
            </Box>
            {isInvalidRePayPwd && (
              <Text color="red.100" fontSize="12px" mt="5px">
                {t("jgXHbpNLgJMKuuZER")}
              </Text>
            )}
          </Box>
          <Box>
            {!nextError ? (
              <Button
                borderRadius={"20px"}
                w={["100%", "400px"]}
                h="44px"
                fontSize="14px"
                bg="blue.100"
                color="#fff"
                _hover={{ bg: "blue.80" }}
                mt="50px"
                onClick={createAccount}
              >
                {t("tOaRBVUJHpnSgDBrGON")}
              </Button>
            ) : (
              <Button
                borderRadius={"20px"}
                w={["100%", "400px"]}
                _hover={{ bg: "t.50" }}
                disabled="true"
                h="44px"
                cursor={"not-allowed"}
                fontSize="14px"
                bg="t.50"
                color="#fff"
                mt="50px"
              >
                {t("tOaRBVUJHpnSgDBrGON")}
              </Button>
            )}
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

export default SetPayPwd;
