import PersonNoChange from "./personal";
import CompanyNoChange from "./company";

const InfoNoChange = ({ accountType, riskLevel, canPeriodic, reviewUUID }) => {
  return (
    <>
      {accountType === "PERSONAL" && (
        <PersonNoChange
          canPeriodic={canPeriodic}
          reviewUUID={reviewUUID}
          riskLevel={riskLevel}
        />
      )}
      {accountType === "COMPANY" && (
        <CompanyNoChange
          canPeriodic={canPeriodic}
          reviewUUID={reviewUUID}
          riskLevel={riskLevel}
        />
      )}
    </>
  );
};

export default InfoNoChange;
