import { useState, useEffect } from "react";
import { getAreaList } from "./api";

const useCommonHooks = () => {
  const [allCountryList, setAllCountryList] = useState([]); // 所有地区
  const [sanctionsCountryList, setSanctionsCountryList] = useState([]); // 制裁地区
  const [noCompliantCountryList, setNoCompliantCountryList] = useState([]); // 非合规

  const getAllAreaList = async () => {
    const res = await getAreaList();
    if (res.code === 0) {
      const countryList = [...res.data.areas];
      countryList.forEach((item, idx) => {
        item.selectName = item.iso_code2;
        item.value = idx;
      });
      setAllCountryList(countryList);
      setSanctionsCountryList(res.data.sanction_areas);
      setNoCompliantCountryList(res.data.non_compliant_areas);
    }
  };

  useEffect(() => {
    getAllAreaList();
  }, []);

  return {
    allCountryList,
    sanctionsCountryList,
    noCompliantCountryList,
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export { useCommonHooks };
