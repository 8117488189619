import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

// 公共分页器
const PaginationDom = (props) => {
  const { page, totalPage, onChange, containerRef } = props;
  const [leftStepper, setLeftStepper] = useState(false); //*左侧的省略号
  const [rightStepper, setRightStepper] = useState(false); //*右侧的省略号
  const [$node, $setNode] = useState([]); //*节点渲染数组

  const updateNode = (_now) => {
    
      const _num = 3;

      // 当页码大于等于6条的情况
      setLeftStepper(_now - _num > 0 && totalPage >= 6);
      setRightStepper(_now < totalPage - 2 && totalPage >= 6);
      let newArr = []
      if (totalPage === 1) {
        newArr = [];
      } else if (totalPage === 2) {
        newArr = [];
      } else if (totalPage === 3) {
        newArr = [2];
      } else if (totalPage === 4) {
        newArr = [2, 3];
      } else if (_now < _num) {
        newArr = [2, 3, 4];
      } else if (_now >= totalPage - 1) {
        newArr = [totalPage - 3, totalPage - 2, totalPage - 1];
      } else {
        newArr = [_now - 1, _now, _now + 1];
      }
      $setNode(newArr);
  };

  const watcher = _now => {
    updateNode(_now);
  };

  const pageChanging = num => {
    updatePage(num);
    containerRef?.current?.scrollIntoView();
  };
  const updatePage = num => {
    onChange && onChange(num);
  }

  const onPrev = () => {
    if (page - 1 < 1) return;
    pageChanging(page - 1);
  };

  const onNext = () => {
    if (page + 1 > totalPage) return;
    pageChanging(page + 1);
  };

  const onSetStep = distance => {
    if (page + distance < 1) {
      pageChanging(1);
    } else if (page + distance > totalPage) {
      pageChanging(totalPage);
    } else {
      pageChanging(page + distance);
    }
  };

  useEffect(() => {
    watcher(page);
    if (page > totalPage && totalPage >= 1) {
      updatePage(totalPage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPage, page]);

  return totalPage > 1 &&  (
    <ul
      className="mili-pagination-root"
    >
      {/* pre arrow */}
      <li
        className={`mili-pagination-arrow  ${page !== 1 ? 'active' : ''}`}
        onClick={onPrev}
      >
        <ChevronLeftIcon />
      </li>
      <li
        onClick={pageChanging.bind(null, 1)}
        className={`mili-pagination-item ${page === 1 && 'mili-pagination-actived'}`}
      >
        1
      </li>
      {leftStepper && (
        <li
          className="mili-pagination-item mili-pagination-point-prev"
          onClick={() => onSetStep(-5)}
        >
          <span className="mili-pagination-left"></span>
        </li>
      )}
      {$node.map(function (num, index) {
        return (
          <li
            className={
              page === num
                ? 'mili-pagination-item mili-pagination-actived'
                : 'mili-pagination-item'
            }
            key={index}
            onClick={pageChanging.bind(null, num)}
          >
            {num}
          </li>
        );
      })}
      {rightStepper && (
        <li
          className="mili-pagination-item mili-pagination-point-next"
          onClick={() => onSetStep(5)}
        >
          <span className="mili-pagination-right"></span>
        </li>
      )}
      {totalPage > 1 && (
        <li
          onClick={pageChanging.bind(null, totalPage)}
          className={
            page === totalPage
              ? 'mili-pagination-item mili-pagination-actived'
              : 'mili-pagination-item'
          }
        >
          {totalPage}
        </li>
      )}
      {/* next arrow */}
      <li
        className={`mili-pagination-arrow ${
          page !== totalPage ? 'active' : ''
        }`}
        onClick={onNext}
      >
        <ChevronRightIcon />
      </li>
    </ul>
  );
};

PaginationDom.propTypes = {
  // 总页数（注意不是不是总条数，根据分页接口来，一般是 Math.ceil(total/limit)）
  totalPage: PropTypes.number.isRequired,
  // 眺码函数
  onChange: PropTypes.func.isRequired,
  // 当前的页数
  page: PropTypes.number.isRequired,
  // 跳码时需要滚动到顶部的外部容器
  containerRef: PropTypes.any,
};

export default PaginationDom;
