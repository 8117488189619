import PageWrap from "@/components/PageWrap";
import Email from "./email";
import Phone from "./phone";
import Ga from "./ga";
import ResetPwd from "./resetPwd";
import ResetPhone from "./resetPhone";
import ResetPayPwd from "./resetPayPwd";
import { useEffect, useState } from "react";
import { useAppSelector } from "@/state/hooks";
import { useDispatch } from "react-redux";
import { updateResetPwdValidation } from "@/state/user/reducer";
import { postValidationItemFunc } from "./api";
import { getPageQuery } from "@/utils";
const ResetPwdIndex = () => {
  const userInfo = useAppSelector((state) => state.user.userInfo);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const urlParams = getPageQuery();
  const urlType = urlParams.type;
  const [request_id,setRequest_id] = useState("");
  const postValidationItem = async () => {
    //scene => CREATE ｜ UPDATE ｜DELETE
    //securityElem => GOOGLE_AUTH
    let securityElem = "";
    if (urlType === "pwd") {
      securityElem = "LOGIN_PASSWORD";
    } else if (urlType === "paypwd") {
      securityElem = "PAYMENT_PASSWORD";
    } else {
      securityElem = "PHONE";
    }

    const res = await postValidationItemFunc({
      securityElem,
      email: userInfo.email,
      phone: userInfo.phone,
      scene: "UPDATE",
    });
    if (res.code === 0) {
      dispatch(
        updateResetPwdValidation({
          resetPwdValidation: res.data,
        })
      );
      setRequest_id(res.data.request_id)
       if (res.data.security_elems[0] === "PHONE") {
        setStep(2);
       }
      if (res.data.security_elems[0] === "EMAIL") {
        setStep(1);
      }
      if (res.data.security_elems[0] === "GOOGLE_AUTH") {
        setStep(3);
        console.log("跳转到谷歌验证码");
      }
    }}
    const getStep = (value) => {
      setStep(value);
    };
    useEffect(() => {
      postValidationItem();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <PageWrap>
        {step === 1 && <Email request_id={request_id} getStep={getStep} />}
        {step === 2 && <Phone request_id={request_id} getStep={getStep} />}
        {step === 3 && <Ga request_id={request_id} getStep={getStep} />}
        {step === 4 && <ResetPwd getStep={getStep} />}
        {step === 5 && <ResetPhone getStep={getStep} />}
        {step === 6 && <ResetPayPwd getStep={getStep} />}
      </PageWrap>
    );
  };

export default ResetPwdIndex;
