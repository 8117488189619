import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class MiliCodeInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    keybordUp: PropTypes.bool,
    inputValue: PropTypes.string,
    type: PropTypes.string,
    isFocus: PropTypes.bool,
    width: PropTypes.number,
    codeboxItemADDClassName: PropTypes.string,
  };

  static defaultProps = {
    type: 'password', // 默认展示圆形 // normal正常展示
  };

  state = {
    inputValue: '',
    keybordUp: false,
    isFocus: false,
  };

  inputFocus = () => {
    this.textInput.focus();
  };

  inputBlur = () => {
    this.textInput.blur();
    this.setState({ keybordUp: false });
  };

  handleInput = e => {
    const { onChange } = this.props;
    let { value } = e.target;
    if (!/^\d+$/.test(value) && value !== '') {
      return;
    }
    if (value.length > 6) {
      return;
    }
    onChange?.(value);
    this.setState({
      inputValue: value,
      keybordUp: true,
    });
  };

  inputE = b => {
    this.setState({ isFocus: b });
  };

  initCodeInputValue = () => {
    this.setState({ inputValue: '' });
    // !isMobile && this.inputFocus();
  };

  componentDidUpdate(_, prevState) {
    if (prevState.inputValue !== this.state.inputValue) {
      this.state.inputValue.length === 6 &&
        this.state.keybordUp &&
        this.inputBlur();
    }
  }

  render() {
    const { inputValue, isFocus } = this.state;
    const { type, width, codeboxItemADDClassName } = this.props;
    const codeBoxs = [];
    const len = inputValue.toString().length;
    const valArr = inputValue.split('');
    for (let i = 1; i <= 6; i++) {
      const item = (
        <div
          className={`codebox-item ${codeboxItemADDClassName || ''}`}
          key={i}
        >
          {type === 'password' ? (
            <div className={len >= i ? 'code-content' : ''}>
              <div className={len + 1 === i && isFocus ? 'focus_item' : ''} />
            </div>
          ) : (
            <div
              className={len + 1 === i && isFocus ? 'focus_item' : 'fill_item'}
            >
              {valArr[i - 1]}
            </div>
          )}
        </div>
      );
      codeBoxs.push(item);
    }

    return (
      <div
        className="milicodeinput-banner"
        onClick={this.inputFocus}
        style={{ width: width ? `${width}px` : '100%' }}
      >
        <div className="milicodeinput-boxs">{codeBoxs}</div>
        <input
          ref={input => (this.textInput = input)}
          value={inputValue}
          id="codeinput"
          autoFocus={false}
          onChange={this.handleInput}
          autoComplete="off"
          type={'number'}
          pattern={'[0-9]*'}
          onKeyPress={
            e => {
              const invalidChars = ['-', '+', 'e', '.', 'E']
              if(invalidChars.indexOf(e.key) !== -1){
                e.preventDefault()
              }
            }
          }
          onBlur={() => this.inputE(false)}
          onFocus={() => this.inputE(true)}
        />
      </div>
    );
  }
}
