import request from "@/hooks/useRequest";
import {
  POSTUPDATEUSERINFO,
  GETUSERINFO,
  GETAREALIST,
  GETPROCESSINFO,
} from "@/helpers/constants/requestApi";

//获取用户信息
export const getUserInfo = (params) => {
  return request(GETUSERINFO, { params });
};

//修改用户信息
export const postUpdateUserInfo = (body) => {
  return request(POSTUPDATEUSERINFO, { body });
};

//获取国家和区号
export const getAreaList = (params) => {
  return request(GETAREALIST, { params });
};

//获取kyc进程信息
export const getProcessInfo = (params) => {
  return request(GETPROCESSINFO, { params });
};
