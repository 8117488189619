import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const dynamicBg = (props) => ({
  bg: mode("#fff", "#24242A")(props),
  "--chakra-colors-bg-8": mode(
    "rgba(130, 129, 140, 0.08)",
    "rgba(125, 122, 153, 0.08)"
  )(props),
  "--chakra-colors-bg-100": mode("#34343D", "#FFFFFF")(props),
  "--chakra-colors-bg-200": mode("#FAFAFA", "#2B2B33")(props),
  "--chakra-colors-bg-300": mode("#F5F5F7", "#24242A")(props),
  "--chakra-colors-bg-400": mode("#EBEBEF", "#1E1E23")(props),
  "--chakra-colors-bg-500": mode("#E2E2E8", "#161619")(props),
  "--chakra-colors-bg-600": mode("#DADAE2", "#0D0D0F")(props),
  "--chakra-colors-bg-700": mode("rgba(255,255,255,0.8)", "rgba(0,0,0,0.8)")(props),
  "--chakra-colors-t-2": mode("#8A889D", "#9593AB")(props),
  "--chakra-colors-t-100": mode("#3E3D4D", "#EBEDF5")(props),
  "--chakra-colors-t-80": mode("#8B8A99", "rgba(235, 237, 245, 0.8)")(props),
  "--chakra-colors-t-50": mode("#B3B4C8", "#B3B4C8")(props),
  "--chakra-colors-blue-500": mode("#4A3DE6", "#695EEA")(props),
  "--chakra-colors-gray-300":mode("#E3E4F1", "#E3E4F1")(props),
});

const theme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: mode("#fff", "#24242A")(props),
      },
      ".chakra-portal": dynamicBg(props),
      "#root": {
        width: "100%",
        minHeight: "100vh",
        ...dynamicBg(props),
      },
    }),
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  breakpoints: {
    sm: "600px",
    md: "1080px",
    lg: "1500px",
    xl: "1800px",
    "2xl": "2100px",
  },
  components: {
    Text: {
      variants: {
        title: {
          fontFamily: "Poppins",
          fontSize: "30px",
          fontWeight: 700,
          lineHeight: "36px",
        },
        title1: {
          fontFamily: "Poppins",
          fontSize: "32px",
          fontWeight: 500,
          lineHeight: "40px",
        },
        body: {
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "20px",
        },
        body1: {
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "20px",
        },
        body2: {
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
        },
        subtitle: {
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "24px",
        },
        subtitle1: {
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "30px",
        },
        subtitle2: {
          fontFamily: "Poppins",
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "30px",
        },
        subtitle3: {
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "30px",
        },
        subtitle4: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: 700,
          lineHeight: "20px",
        },
        cap: {
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "18px",
        },
        toast: {
          fontFmily: "PingFang SC",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "18px",
          lineHeight: "24px",
        },
      },
    },
  },
  colors: {
    blue: {
      50:"#EDEFFC",
      80:"#4950F4",
      100:"#333BF5",
      500: "#4A3DE6",
    },
    red: {
      50:"#FCEDEF",
      100:"#D04457",
      400: "#A53939",
    },
    green:{
      50:"#F0FCED",
      100:"#4FBE45",
    },
    t: {
      2: "8A889D",
      50: "#B3B4C8",
      80: "#7B7C8F",
      100: "#222227",
      hover: "rgba(125, 122, 153, 0.08)",
    },
    line: {
      200: "#8b8a994d",
    },
    bg: {
      8: "rgba(125, 122, 153, 0.08)",
      50: "#F3F5F7",
      100:"#232325",
      200: "#FAFAFA",
      300: "#F5F5F7",
      400: "#EBEBEF",
      500: "#E2E2E8",
      600: "#DADAE2",
      700: "rgba(255,255,255,0.8)",
      
    },
    bd:{
      50:"#E3E4F1"
    }
  },
  background: {
    blue: {
      500: "#EBEDF5",
    },
  },
});

// console.log("theme", theme);

export default theme;
