import request from "@/hooks/useRequest";

// 月结单
export const getMonthStatement = (date) => {
  return request(
    `/web-wallet/api/v1/e-statement/monthly-statement-search?date=${date}`,
    {
      needToast: false,
    }
  );
};

// 日结单
export const getDayStatement = (date) => {
  return request(
    `/web-wallet/api/v1/e-statement/daily-statement-search?date=${date}`,
    {
      needToast: false,
    }
  );
};
