import { useAppSelector } from "@/state/hooks";
import { useCallback, useEffect, useState } from "react";
import { getUserInfo, getProcessInfo } from "./api";
import { useDispatch } from "react-redux";
import { updateKycInfo } from "@/state/kyc";
import { updateUserInfo } from "@/state/user/reducer";
import { usePersonalHooks } from "@/pages/accountManager/hooks";

const useCommonHooks = () => {
  const dispatch = useDispatch();

  const fetchGetProcessInfo = async () => {
    const res = await getProcessInfo({});
    if (res.code === 0) {
      dispatch(updateKycInfo(res.data.process));
    }
  };

  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };

  useEffect(() => {
    fetchGetProcessInfo();
    getUserInfoFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useCertificate = () => {
  const { userInfo } = usePersonalHooks();
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);
  const [processData, setProcessData] = useState([]);
  const [baseBtnInfo, setBaseBtnInfo] = useState();
  const [evaBtnInfo, setEvaBtnInfo] = useState();
  const [verifyBtnInfo, sertVerifyBtnInfo] = useState();
  const [verifyType, setVerifyType] = useState("");
  // const [c3Process, setC3Process] = useState([]);

  useEffect(() => {
    if (kycInfo?.length) {
      // console.log("[[kycInfo]]", kycInfo);
      // c3中 只要有一个状态时DENIED 整个流程都停了
      // setC3Process([
      //   kycInfo[0],
      //   kycInfo[1],
      //   kycInfo[2],
      //   kycInfo[4],
      //   kycInfo[5],
      //   kycInfo[7],
      // ]);
      getBaseBtnInfo(
        kycInfo[0],
        kycInfo[1],
        kycInfo[2],
        kycInfo[3],
        kycInfo[7]
      );
      getEvalBtnInfo(kycInfo[2], kycInfo[5], kycInfo[6], kycInfo[7]);
      getVerifyBtnInfo(kycInfo[2], kycInfo[5], kycInfo[7]);

      const result = [];
      for (let i = 0; i < kycInfo.length; i++) {
        if (i === 0) {
          const { status } = kycInfo[0];
          result.push({
            ...kycInfo[0],
            textColor:
              status === "REQUIRED"
                ? getTextColor("gray")
                : status === "APPROVED"
                ? getTextColor("blue")
                : getTextColor("black"),
            // textColor:
            //   status === "APPROVED"
            //     ? getTextColor("blue")
            //     : getTextColor("black"),
            imgActive: true,
          });
        }

        if (i === 4 || i === 7) {
          // 看第三步状态
          const stepThreeStatus = kycInfo[i === 4 ? 2 : 5];
          const currStatus = kycInfo[i];
          let pararms = {};
          if (currStatus.status === "APPROVED") {
            pararms = {
              textColor: getTextColor("blue"),
              imgActive: true,
            };
          } else {
            if (
              stepThreeStatus.status === "APPROVED" ||
              stepThreeStatus.status === "PENDING"
            ) {
              pararms = {
                textColor: getTextColor("black"),
                imgActive: true,
              };
            }
          }
          result.push({
            ...kycInfo[i],
            ...pararms,
          });
        }

        if (i === 3 || i === 6 || i === 8) {
          // 4 7 9
          // 选填的只看当前状态
          const currStatus = kycInfo[i];
          let pararms = {};
          if (currStatus.status === "APPROVED") {
            pararms = {
              textColor: getTextColor("blue"),
              imgActive: true,
            };
          } else if (currStatus.status === "REQUIRED") {
            pararms = {
              textColor: getTextColor("gray"),
              imgActive: false,
            };
          } else {
            pararms = {
              textColor: getTextColor("black"),
              imgActive: false,
            };
          }
          result.push({
            ...kycInfo[i],
            ...pararms,
          });
        }

        if (i === 1 || i === 2 || i === 5) {
          // 2、3、6步 需要看前一个状态
          let pararms = {};
          const prevStatus = kycInfo[i - 1];
          const currStatus = kycInfo[i];
          if (currStatus.status === "APPROVED") {
            pararms = {
              textColor: getTextColor("blue"),
              imgActive: true,
            };
          } else {
            if (prevStatus.status === "APPROVED") {
              pararms = {
                imgActive: true,
                textColor: getTextColor("black"),
              };
            } else {
              pararms = {
                imgActive: false,
                textColor: getTextColor("gray"),
              };
            }
          }
          result.push({
            ...kycInfo[i],
            ...pararms,
          });
        }
      }
      setProcessData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycInfo]);

  useEffect(() => {
    if (userInfo.auth_mode) {
      setVerifyType(userInfo.auth_mode === "bank_transfer" ? "bank" : "other");
    }
  }, [userInfo.auth_mode]);

  useEffect(() => {
    // 默认
    if (verifyBtnInfo?.isActive) {
      setVerifyType("bank");
    }
  }, [verifyBtnInfo?.isActive]);

  const getTextColor = (type) => {
    const maps = {
      blue: "#333BF5",
      black: "#010313",
      gray: "#7B7C8F",
    };
    return maps[type];
  };

  const getSelfBtnInfo = useCallback((type) => {
    const maps = {
      DENIED: {
        text: "gyneoIYPeiHgfaCY",
        isActive: false,
      },
      APPROVED: {
        text: "zZXyblLWbONMNIjPmBN",
        isActive: true,
      },
      PENDING: {
        text: "hfBBkSOAXqfEsLVFs",
        isActive: false,
      },
      REQUIRED: {
        text: "dpnyHVmjzJjiqtQIfpD",
        isActive: false,
      },
    };
    return maps[type];
  }, []);

  const getBaseBtnInfo = (
    stepOneInfo,
    stepTwoInfo,
    setpThreeInfo,
    stepFourInfo,
    eightInfo
  ) => {
    const text = getSelfBtnInfo(setpThreeInfo.status).text;

    let isActive;

    if (stepOneInfo.status === "REQUIRED") {
      isActive = true;
    } else if (stepTwoInfo.status === "REQUIRED") {
      isActive = true;
    } else if (
      setpThreeInfo.status === "DENIED" ||
      eightInfo.status === "DENIED"
    ) {
      isActive = false;
    } else if (
      setpThreeInfo.status !== "DENIED" &&
      stepFourInfo.status === "REQUIRED"
    ) {
      isActive = true;
    } else if (
      setpThreeInfo.status !== "DENIED" &&
      stepFourInfo.status !== "REQUIRED"
    ) {
      isActive = false;
    }

    setBaseBtnInfo({
      isActive,
      text,
    });
  };

  const getEvalBtnInfo = (threeInfo, sixInfo, sevenInfo, eightInfo) => {
    const text =
      sixInfo.status === "REQUIRED"
        ? getSelfBtnInfo("REQUIRED").text
        : sixInfo.status === "APPROVED"
        ? getSelfBtnInfo("APPROVED").text
        : "";

    let isActive = "";
    if (
      threeInfo.status === "DENIED" ||
      threeInfo.status === "REQUIRED" ||
      eightInfo.status === "DENIED"
    ) {
      isActive = false;
    }
    if (threeInfo.status === "APPROVED" || threeInfo.status === "PENDING") {
      isActive = true;
      if (sevenInfo.status !== "REQUIRED") {
        isActive = false;
      }
    }

    setEvaBtnInfo({
      isActive,
      text,
    });
  };

  const getVerifyBtnInfo = (threeInfo, sixInfo, eightInfo) => {
    let text = getSelfBtnInfo(eightInfo.status).text;
    let isActive = false;

    if (
      threeInfo.status === "DENIED" ||
      threeInfo.status === "REQUIRED" ||
      sixInfo.status === "DENIED" ||
      sixInfo.status === "REQUIRED"
    ) {
      isActive = false;
    }
    if (threeInfo.status === "APPROVED" && sixInfo.status === "APPROVED") {
      isActive = true;

      if (
        eightInfo.status === "APPROVED" ||
        eightInfo.status === "DENIED" ||
        eightInfo.status === "PENDING"
      ) {
        isActive = false;
      }
    }

    sertVerifyBtnInfo({
      isActive,
      text,
    });
  };

  const getVerifyType = (type) => {
    if (verifyBtnInfo?.isActive && type !== verifyType) {
      setVerifyType(type);
    }
  };

  return {
    baseBtnInfo,
    verifyBtnInfo,
    evaBtnInfo,
    processData,
    verifyType,
    getTextColor,
    getVerifyType,
  };
};

export { useCertificate, useCommonHooks };
