import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import userReducer from "./user/reducer";
import payReducer from "./pay/reducer";
import kycReducer from "./kyc";

const persistConfigs = {
  key: "root",
  storage,
  blacklist: [],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  user: userReducer,
  pay: payReducer,
  kyc: kycReducer,
});
const persistedReducer = persistReducer(persistConfigs, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
const persistor = persistStore(store);
window.consoleRedux = () => store.getState();
export { store, persistor };
