import ModalComponent from "@/components/ui/modal";
import { Flex, Box, Image, Text, Button } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { useNavigate } from "react-router-dom";
import { useMedia } from "@/hooks/useMedia";

const NotKycModal = ({ show, onClose }) => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { isMobile } = useMedia();
  return (
    <ModalComponent
      isShow={show}
      style={{ width: !isMobile ? "400px" : "calc(100% - 30px)" }}
    >
      <Box p={["50px 30px"]}>
        <Flex flexDirection={"column"} alignItems={"center"}>
          <Image src={"../wwwsite/images/kyc/info-circle.svg"} />
          <Text color={"#000"} fontSize={"16px"} fontWeight={"500"} mt={"10px"}>
            {t("RZgUzTVRaXNflgjshVf")}
          </Text>
          <Flex mt={"20px"}>
            <Button
              mr="10px"
              fontSize="14px"
              padding="0 30px"
              w={["40%", "100px"]}
              bg="rgba(32, 26, 85, 0.05)"
              color="t.100"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={"pointer"}
              onClick={() => onClose()}
            >
              {t("UNForxvjDLCZdGrmWkX")}
            </Button>
            <Button
              fontSize="14px"
              padding="0 30px"
              w={["60%", "200px"]}
              bg="blue.100"
              color="#fff"
              fontWeight={"400"}
              borderRadius={"30px"}
              _hover={{ bg: "blue.80" }}
              h="44px"
              cursor={"pointer"}
              onClick={() => {
                navigate("/trade/kyc");
              }}
            >
              {t("AQrCocFqoKoYieUnT")}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </ModalComponent>
  );
};

export default NotKycModal;
