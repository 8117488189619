import { Box, Flex, Text, Input, Button, Select } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import PageWrap from "@/components/PageWrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  postIncreasingLimit,
  getIncreasingLimitPage,
  getS3PutUrl,
} from "./api";
import { BIXIN_WALLET } from "@/helpers/constants/routeName";
import { throttle } from "lodash";
import { toastTip } from "@/components/ui/toast";
import { useMedia } from "@/hooks/useMedia";
import { useI18n } from "@/hooks/useI18n";
import axios from "axios";
import { S3_AWS_KMS_KEY_ID } from "@/config";
const AmountApply = () => {
  // eslint-disable-next-line no-unused-vars
  const { t } = useI18n();
  const navigate = useNavigate();
  const [types, setTypes] = useState([]);
  const [nextError, setNextError] = useState(false);
  const { isMobile } = useMedia();
  const [availableTrandeAmount, setAvailableTrandeAmount] = useState({});
  const [trandeAmount, setTrandeAmount] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [uploadList, setUploadList] = useState([
    {
      hasDelete: false,
      typeIsThree: false,
      typeIsFour: false,
      isFormatRight: true,
      fileName: "-",
      id: Math.random(),
      inputName: [t("QZchoinPLbiohHfdnLU"), t("gnOvqPVwTsPuWvBTXzI")],
      uploadType: [
        {
          name: "工资单（3个月内）",
          value: 1,
          checked: true,
        },
        {
          name: "税单（最近一年）",
          value: 2,
          checked: false,
        },
        {
          name: "由合资格金融机构开具的资产证明",
          value: 3,
          checked: false,
        },
        {
          name: "其他文件",
          value: 4,
          checked: false,
        },
      ],
    },
  ]);

  const [updateTyps, setUploadTypes] = useState([]);
  const typesChange = (e) => {
    const value = e.target.value;
    const newTypes = [...types];
    newTypes.forEach((item) => {
      if (value === item.flag) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    setTypes(newTypes);
  };

  const rangeChange = (e) => {
    const value = e.target.value;
    const newTypes = [...types];
    newTypes.forEach((item) => {
      item.range.forEach((obj) => {
        if (obj.flag === value) {
          obj.checked = true;
        } else {
          obj.checked = false;
        }
      });
    });
    setTypes(newTypes);
  };
  const uploadFn = async (file, newUploadList, list, fileTypeSuffix) => {
    // const formData = new FormData();
    // formData.append("file", file);
    // formData.append("file_type", "proof_increase_trade_limit");

    // const upLoadRes = await postUpload(formData);
    // if (upLoadRes.code === 0) {
    // newUploadList.forEach((item) => {
    //   if (list.id === item.id) {
    //     item.path = upLoadRes.data.path;
    //   }
    // });
    // }

    const res = await getS3PutUrl({
      prefix: "proof_increase_trade_limit",
      file_type: fileTypeSuffix.toLocaleLowerCase(),
    });
    if (res.code === 0) {
      const putUrl = res?.data?.put_url;
      const filePath = res?.data?.file_path;
      axios({
        url: putUrl,
        method: "put",
        headers: {
          "X-Amz-Server-Side-Encryption": "aws:kms",
          "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
        },
        data: file,
      }).then((uploadRes) => {
        if (uploadRes.status === 200) {
          newUploadList.forEach((item) => {
            if (list.id === item.id) {
              item.path = filePath;
            }
          });
        }
      });
    }
  };
  const fileChange = async (e, list) => {
    const file = e.target.files[0];
    console.log("file", file);
    const { size, name } = file;
    const newUploadList = [...uploadList];
    newUploadList.forEach((item) => {
      if (list.id === item.id) {
        item.fileName = name;
        const fileTypeSuffix = name
          .split(".")
          [name.split(".").length - 1].toUpperCase();
        if (
          size / 1000000 < 5 &&
          (fileTypeSuffix === "PDF" || fileTypeSuffix === "JPG")
        ) {
          item.isFormatRight = true;
          uploadFn(file, newUploadList, list, fileTypeSuffix);
        } else {
          item.isFormatRight = false;
        }
      }
    });
    setUploadList(newUploadList);
  };

  const updateTypesChange = (e, list) => {
    const value = e.target.value;
    const newUploadList = [...uploadList];
    console.log("updateTypesChange===", value, list.id);
    newUploadList.forEach((item, index) => {
      if (list.id === item.id) {
        item.uploadTypeSelect = value;
        if (value === "doc-type3") {
          item.typeIsThree = true;
          item.typeIsFour = false;
        } else if (value === "doc-type4") {
          item.typeIsThree = false;
          item.typeIsFour = true;
        } else {
          item.typeIsThree = false;
          item.typeIsFour = false;
        }
        return;
      }
    });
    console.log("change之后", newUploadList);
    setUploadList(newUploadList);
  };

  const commmetChange = (e, list) => {
    const value = e.target.value;
    const newUploadList = [...uploadList];
    newUploadList.forEach((item) => {
      if (list.id === item.id) {
        console.log(value);
        if (value) {
          item.comment = value;
          item.isCommentFormartRight = false;
        } else {
          item.isCommentFormartRight = true;
        }
      }
    });
    setUploadList(newUploadList);
  };

  const addUploadList = () => {
    const newUploadList = [...uploadList];
    const newList = {
      hasDelete: true,
      typeIsThree: false,
      typeIsFour: false,
      fileName: "-",
      id: Math.random(),
      inputName: [t("QZchoinPLbiohHfdnLU"), t("gnOvqPVwTsPuWvBTXzI")],
      uploadType: updateTyps,
    };
    newUploadList.push(newList);
    setUploadList(newUploadList);
    console.log("======uploadList", uploadList);
  };

  const deleteUploadList = (id) => {
    const newUploadList = [...uploadList];
    if (newUploadList.length > 1) {
      newUploadList.splice(id, 1);
    }
    setUploadList(newUploadList);
    console.log("======uploadList", uploadList);
  };

  const getInitConfig = async () => {
    const res = await getIncreasingLimitPage();
    if (res.code === 0) {
      let initTypes = res.data.proof_type;
      let newUpdateTypes = res.data.document_type;
      let newUpdateList = [...uploadList];
      setAvailableTrandeAmount(res.data.available_trade_amount);
      setTrandeAmount(res.data.trade_amount);
      // eslint-disable-next-line array-callback-return
      initTypes.map((item, idx) => {
        if (idx === 0) {
          item.checked = true;
        } else {
          item.checked = false;
        }

        item.range.forEach((obj, index) => {
          if (index === 0) {
            obj.checked = true;
          } else {
            obj.checked = false;
          }
        });
      });
      // eslint-disable-next-line array-callback-return
      newUpdateTypes.map((item, idx) => {
        if (idx === 0) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
      // eslint-disable-next-line array-callback-return
      newUpdateList.map((item) => {
        item.uploadType = newUpdateTypes;
      });

      setTypes(initTypes);
      setUploadTypes(newUpdateTypes);
      setUploadList(newUpdateList);
    }
  };

  useEffect(() => {
    getInitConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTypes = () => {
    const currentType = types.filter((item) => item.checked);
    return currentType[0].flag;
  };

  const getRanges = () => {
    const currentType = types.filter((item) => item.checked);
    const currentRange = currentType[0].range.filter((item) => item.checked);
    return currentRange[0].flag;
  };

  const getDocuments = () => {
    const docArr = [];

    uploadList.forEach((item) => {
      let document;
      document = {
        document_type: item.uploadTypeSelect || "doc-type1",
        document_type_comment: item.comment,
        document_path: item.path,
      };
      docArr.push(document);
    });
    console.log(uploadList);
    return docArr;
  };

  const submitApply = throttle(async () => {
    //验证表单
    let newUpdateList = [...uploadList];
    let flag = true;
    newUpdateList.forEach((item) => {
      if (!item.path) {
        item.isFormatRight = false;
        flag = false;
      }
      if (item.typeIsFour) {
        if (!item.comment) {
          item.isCommentFormartRight = true;
          flag = false;
        }
      }
      if (item.typeIsThree) {
        if (!item.comment) {
          item.isCommentFormartRight = true;
          flag = false;
        }
      }
    });
    setUploadList(newUpdateList);

    console.log("flag====", flag);

    const data = {
      proof_type: getTypes(),
      proof_range: getRanges(),
      documents: getDocuments(),
    };
    console.log(data);
    if (flag) {
      setNextError(true);
      console.log("submit data", data);
      const res = await postIncreasingLimit(data);
      if (res.code === 0) {
        setNextError(false);
        toastTip(res.data.reason);
        setTimeout(() => {
          toHome();
        }, 2000);
      } else {
        setNextError(false);
      }
    }
  }, 5000);

  const toHome = () => {
    navigate(BIXIN_WALLET);
  };

  return (
    <PageWrap>
      <Flex justifyContent={"center"} mt={["20px", "140px"]}>
        <Box w={["90%", "auto"]}>
          <Flex justifyContent={["flex-start", "center"]}>
            <Text fontSize={["24px", "32px"]} color="#000" fontWeight={"600"}>
              {t("TGYvVRioXBfDBtmCBpH")}
            </Text>
          </Flex>
          <Box>
            {isMobile ? (
              <Box mt="20px" w="100%">
                <Box
                  w="350px"
                  h="70px"
                  bg="rgba(32, 26, 85, 0.05)"
                  borderRadius={"12px"}
                  overflow={"hidden"}
                >
                  <Text mt="12px" ml="20px" color="t.80" fontSize="12px">
                    {t("FMUUecQmwAheUKwpq")}1
                  </Text>
                  <Text ml="20px" mt="9px" fontSize="14px" fontWeight={"600"}>
                    {availableTrandeAmount?.primary_currency}{" "}
                    {availableTrandeAmount.amount}
                  </Text>
                </Box>
                <Box
                  w="350px"
                  h="70px"
                  bg="rgba(32, 26, 85, 0.05)"
                  borderRadius={"12px"}
                  mt="20px"
                  overflow={"hidden"}
                >
                  <Text mt="12px" ml="20px" color="t.80" fontSize="12px">
                    {t("KSxzKkrfZaaHIOptelk")}
                  </Text>
                  <Text ml="20px" mt="9px" fontSize="14px" fontWeight={"600"}>
                    {trandeAmount?.primary_currency} {trandeAmount.amount}
                    {/* {t("YEPMktdEzwhrMMYKefX")} */}
                  </Text>
                </Box>
              </Box>
            ) : (
              <Flex justifyContent={"space-between"} mt="20px" w="720px">
                <Box
                  w="350px"
                  h="70px"
                  bg="rgba(32, 26, 85, 0.05)"
                  borderRadius={"12px"}
                >
                  <Text mt="12px" ml="20px" color="t.80" fontSize="12px">
                    {t("domCttddTWzCfimSWOq")}
                  </Text>
                  <Text ml="20px" mt="9px" fontSize="14px" fontWeight={"600"}>
                    {availableTrandeAmount?.primary_currency}{" "}
                    {availableTrandeAmount.amount}
                  </Text>
                </Box>
                <Box
                  w="350px"
                  h="70px"
                  bg="rgba(32, 26, 85, 0.05)"
                  borderRadius={"12px"}
                >
                  <Text mt="12px" ml="20px" color="t.80" fontSize="12px">
                    {t("dqLNRujTTFLifcFaybP")}
                  </Text>
                  <Text ml="20px" mt="9px" fontSize="14px" fontWeight={"600"}>
                    {trandeAmount?.primary_currency} {trandeAmount.amount}
                    {/* {t("YEPMktdEzwhrMMYKefX")} */}
                  </Text>
                </Box>
              </Flex>
            )}

            <Box
              bg="bg.100"
              textAlign={["left", "center"]}
              color="#fff"
              fontSize={"14px"}
              borderRadius={"12px"}
              mt="20px"
              padding={["10px", "0"]}
              lineHeight={["20px", "30px"]}
              w={"100%"}
            >
              {t("cQKfBbUkEhBhPtPCx")}
            </Box>
            {isMobile ? (
              <Box mt="40px">
                <Box>
                  <Text fontSize="14px" color="t.80">
                    {t("SHjOXbqLUjZRUKsWMzD")}
                  </Text>
                  <Select w="350px" fontSize="14px" onChange={typesChange}>
                    {types?.length > 0 &&
                      types.map((item, idx) => (
                        <option key={idx} value={item.flag}>
                          {item.name}
                        </option>
                      ))}
                  </Select>
                </Box>
                <Box mt="20px">
                  <Text fontSize="14px" color="t.80">
                    {t("mUuMBlRDyzHtFgLsivV")}
                  </Text>
                  {types?.length > 0 && (
                    <Select w="350px" fontSize="14px" onChange={rangeChange}>
                      {types[0].checked &&
                        types[0]?.range?.map((item, idx) => (
                          <option key={idx} value={item.flag}>
                            {item.name}
                          </option>
                        ))}
                      {types[1].checked &&
                        types[1].range.map((item, idx) => (
                          <option key={idx} value={item.flag}>
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  )}
                </Box>
              </Box>
            ) : (
              <Flex mt="40px" justifyContent={"space-between"}>
                <Box>
                  <Text fontSize="14px" color="t.80">
                    {t("SHjOXbqLUjZRUKsWMzD")}
                  </Text>
                  <Select w="350px" fontSize="14px" onChange={typesChange}>
                    {types?.length > 0 &&
                      types.map((item, idx) => (
                        <option key={idx} value={item.flag}>
                          {item.name}
                        </option>
                      ))}
                  </Select>
                </Box>
                <Box>
                  <Text fontSize="14px" color="t.80">
                    {t("mUuMBlRDyzHtFgLsivV")}
                  </Text>
                  {types?.length > 0 && (
                    <Select w="350px" fontSize="14px" onChange={rangeChange}>
                      {types[0].checked &&
                        types[0]?.range?.map((item, idx) => (
                          <option key={idx} value={item.flag}>
                            {item.name}
                          </option>
                        ))}
                      {types[1].checked &&
                        types[1].range.map((item, idx) => (
                          <option key={idx} value={item.flag}>
                            {item.name}
                          </option>
                        ))}
                    </Select>
                  )}
                </Box>
              </Flex>
            )}

            <Text mt={["40px", "20px"]} fontSize="16px" fontWeight={"500"}>
              {t("ISJVpXHVtpoxunGygBGu")}
            </Text>
            {uploadList.map((listItem, idx) => (
              <Box key={idx}>
                <Flex justifyContent={"space-between"} mt="20px">
                  <Text fontSize="14px" color="t.80">
                    {t("VGmDUnWBzMKfFbMKXyl")}
                  </Text>
                  {listItem.hasDelete && (
                    <Text
                      fontSize="14px"
                      color="red.100"
                      cursor={"pointer"}
                      onClick={() => {
                        deleteUploadList(idx);
                      }}
                    >
                      {t("eUvzxeVvXrffrpEOrFrP")}
                    </Text>
                  )}
                </Flex>

                {isMobile ? (
                  <Box mt="5px">
                    <Select
                      w="350px"
                      fontSize="14px"
                      onChange={(e) => {
                        updateTypesChange(e, listItem);
                      }}
                    >
                      {listItem.uploadType.map((item, idx) => (
                        <option key={idx} value={item.flag}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                    <Box w="350px" position="relative" mt="20px">
                      <Flex justifyContent={"space-between"}>
                        <Input
                          w="80px"
                          type="file"
                          opacity={"0"}
                          position={"absolute"}
                          right="0"
                          zIndex={"2"}
                          cursor="pointer"
                          fontSize="0"
                          onChange={(e) => {
                            fileChange(e, listItem);
                          }}
                        />
                        <Input
                          w="260px"
                          disabled
                          _disabled={{
                            borderColor: "bd.50",
                            cursor: "no-drop",
                          }}
                          value={listItem.fileName}
                        />
                        <Button
                          w="80px"
                          h="30px"
                          fontSize="12px"
                          color="#fff"
                          borderRadius={"20px"}
                          bg="blue.100"
                          textAlign={"center"}
                          _hover={{ bg: "blue.80" }}
                          mt="5px"
                        >
                          {t("NqgOQEXQaHsramCnbX")}
                        </Button>
                      </Flex>
                      <Text
                        color={listItem.isFormatRight ? "t.80" : "red.100"}
                        fontSize="12px"
                        mt="5px"
                      >
                        {t("YoNUkubiEWSyPsgRUR")}
                      </Text>
                    </Box>
                  </Box>
                ) : (
                  <Flex justifyContent={"space-between"} mt="5px">
                    <Select
                      w="350px"
                      fontSize="14px"
                      onChange={(e) => {
                        updateTypesChange(e, listItem);
                      }}
                    >
                      {listItem.uploadType.map((item, idx) => (
                        <option key={idx} value={item.flag}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                    <Box w="350px" position="relative">
                      <Flex justifyContent={"space-between"}>
                        <Input
                          w="80px"
                          type="file"
                          opacity={"0"}
                          position={"absolute"}
                          right="0"
                          zIndex={"2"}
                          cursor="pointer"
                          fontSize="0"
                          onChange={(e) => {
                            fileChange(e, listItem);
                          }}
                        />
                        <Input
                          w="260px"
                          disabled
                          _disabled={{
                            borderColor: "bd.50",
                            cursor: "no-drop",
                          }}
                          value={listItem.fileName}
                        />
                        <Button
                          w="80px"
                          h="30px"
                          fontSize="12px"
                          color="#fff"
                          borderRadius={"20px"}
                          bg="blue.100"
                          textAlign={"center"}
                          _hover={{ bg: "blue.80" }}
                          mt="5px"
                        >
                          {t("NqgOQEXQaHsramCnbX")}
                        </Button>
                      </Flex>
                      <Text
                        color={listItem.isFormatRight ? "t.80" : "red.100"}
                        fontSize="12px"
                        mt="5px"
                      >
                        {t("YoNUkubiEWSyPsgRUR")}
                      </Text>
                    </Box>
                  </Flex>
                )}

                {listItem.typeIsThree && (
                  <Box mt={["20px", "0px"]}>
                    <Input
                      placeholder={t("QZchoinPLbiohHfdnLU")}
                      w="350px"
                      mt="-10px"
                      fontSize="14px"
                      isInvalid={listItem.isCommentFormartRight}
                      bg={listItem.isCommentFormartRight ? "red.50" : "none"}
                      onBlur={(e) => {
                        commmetChange(e, listItem);
                      }}
                    />
                  </Box>
                )}

                {listItem.typeIsFour && (
                  <Box mt={["20px", "0px"]}>
                    <Input
                      placeholder={t("gnOvqPVwTsPuWvBTXzI")}
                      w="350px"
                      mt="-10px"
                      fontSize="14px"
                      isInvalid={listItem.isCommentFormartRight}
                      bg={listItem.isCommentFormartRight ? "red.50" : "none"}
                      onBlur={(e) => {
                        commmetChange(e, listItem);
                      }}
                    />
                  </Box>
                )}
                <Text
                  color={listItem.isCommentFormartRight ? "red.100" : "t.80"}
                  fontSize="12px"
                  mt="5px"
                >
                  {listItem.typeIsThree && t("wGSnhUtsHuueniKdYTwM")}
                  {listItem.typeIsFour && t("krMQcSEJDOnglqhrHgu")}
                </Text>
              </Box>
            ))}
          </Box>
          <Flex
            alignItems={"center"}
            cursor={"pointer"}
            mt="32px"
            onClick={addUploadList}
          >
            <AddIcon w="14px" color="blue.100" />
            <Text fontSize="14px" color="blue.100" ml="8px">
              {t("BggwtUciWOTxbdTdDg")}
            </Text>
          </Flex>
          <Flex
            justifyContent={["space-between", "flex-start"]}
            mt="52px"
            mb="80px"
            w="100%"
          >
            <Button
              w={["130px", "100px"]}
              h="44px"
              fontSize="14px"
              color="t.100"
              borderRadius={"30px"}
              bg="rgba(32, 26, 85, 0.05);"
              textAlign={"center"}
              mr="10px"
              _hover={{ bg: "rgba(32, 26, 85, 0.05);" }}
              onClick={toHome}
            >
              {t("UNForxvjDLCZdGrmWkX")}
            </Button>
            {!nextError ? (
              <Button
                w="200px"
                h="44px"
                fontSize="14px"
                color="#fff"
                borderRadius={"30px"}
                bg="blue.100"
                textAlign={"center"}
                _hover={{ bg: "blue.80" }}
                onClick={submitApply}
              >
                {t("QFbYEdxUgXzRwcPFQDis")}
              </Button>
            ) : (
              <Button
                w="200px"
                h="44px"
                fontSize="14px"
                color="#fff"
                borderRadius={"30px"}
                bg="t.50"
                textAlign={"center"}
                _hover={{ bg: "t.50" }}
                mr="10px"
                cursor={"not-allowed"}
              >
                {t("QFbYEdxUgXzRwcPFQDis")}
              </Button>
            )}
          </Flex>
        </Box>
      </Flex>
    </PageWrap>
  );
};

export default AmountApply;
