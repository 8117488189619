import axios from "axios";
import { S3_AWS_KMS_KEY_ID } from "@/config";
import { getS3PutUrlApi } from "@/api/common";

const handleS3UploadFile = async ({ prefix, file_type, file }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await getS3PutUrlApi({
        prefix: prefix,
        file_type: file_type,
      });
      if (res.code === 0) {
        const putUrl = res?.data?.put_url;
        const filePath = res?.data?.file_path;
        axios({
          url: putUrl,
          method: "put",
          headers: {
            "X-Amz-Server-Side-Encryption": "aws:kms",
            "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
          },
          data: file,
        }).then((uploadRes) => {
          if (uploadRes.status === 200) {
            resolve({ filePath: filePath, status: "success" });
          }
        });
      } else {
        resolve({ status: "success" });
      }
    } catch {
      resolve({ status: "success" });
    }
  });
};

export { handleS3UploadFile };
