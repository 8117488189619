import { Box, Flex, Text, Button, Input, Image } from "@chakra-ui/react";
import BXNSelect from "@/components/ui/WSelect";
import { useI18n } from "@/hooks/useI18n";
import PasswordVerify from "./components/PasswordVerify";
import { useEffect, useMemo, useState } from "react";
import AccountTypeItem from "./components/AccountTypeItem";
import { ACCOUNT_TYPE } from "../consts";
import { getS3PutUrl } from "../api";
import axios from "axios";
import { WHITELIST_CATEGORY, WHITELIST_STEPS } from "../consts";
import { S3_AWS_KMS_KEY_ID } from "@/config";

//  交易所验证
const VerifyCEX = ({
  vaspList,
  verifyPwdData,
  setVerifyData,
  verifyPwdFunc,
  network,
  address,
  addressName,
  currency,
  setStep,
}) => {
  const { t } = useI18n();

  const [verifyParams, setVerifyParams] = useState({
    firstName: "",
    lastName: "",
    whitelistVaspDid: "",
  });

  const [companyName, setCompanyName] = useState("");
  const [accountType, setAccountType] = useState(ACCOUNT_TYPE.PERSONAL);
  const [checked, setChecked] = useState(false);

  const [firstFileInfo, setFirstFileInfo] = useState({
    name: "",
    fullName: "",
    error: false,
  });

  const [secondFileInfo, setSecondFileInfo] = useState({
    name: "",
    fullName: "",
    error: false,
  });

  const verifyEles = useMemo(() => {
    return [
      {
        text: t("sMvNZNODlnAIiuzFMR"),
        errorText: t("RrEKjSeFQrtvLrZIO"),
      },
      {
        text: t("QnjpooahLXRwxGqJIVt"),
        errorText: t("RrEKjSeFQrtvLrZIO"),
      },
    ];
  }, [t]);

  const accountTypeOptions = useMemo(() => {
    return [
      {
        text: t("nXPQNJCVxPkQKidfjnn"),
        id: ACCOUNT_TYPE.PERSONAL,
      },
      {
        text: t("HryFxrLzCqTGOVNrpI"),
        id: ACCOUNT_TYPE.COMPANY,
      },
    ];
  }, [t]);

  useEffect(() => {
    if (vaspList && vaspList.length > 0) {
      mergeParams({ whitelistVaspDid: vaspList[0].did });
    }
  }, [vaspList]);

  // 能否提交
  const canSubmit = useMemo(() => {
    const { firstName, lastName, whitelistVaspDid } = verifyParams;

    if (
      accountType === ACCOUNT_TYPE.PERSONAL &&
      firstName &&
      lastName &&
      whitelistVaspDid &&
      firstFileInfo.name &&
      secondFileInfo.name &&
      checked
    ) {
      return true;
    }
    if (
      accountType === ACCOUNT_TYPE.COMPANY &&
      whitelistVaspDid &&
      firstFileInfo.name &&
      secondFileInfo.name &&
      companyName &&
      checked
    ) {
      return true;
    }
    return false;
  }, [
    verifyParams,
    firstFileInfo,
    secondFileInfo,
    companyName,
    accountType,
    checked,
  ]);

  const mergeParams = (param) => {
    setVerifyParams((prev) => {
      return Object.assign({}, prev, param);
    });
  };

  // 上传
  const hanleFileUpload = async (e, i) => {
    const file = e.target.files[0];
    const validTypes = ["jpg", "png", "pdf"];
    if (file) {
      const { size, name } = file;
      const fileNameSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (validTypes.indexOf(fileNameSuffix) < 0) {
        if (i === 0) {
          setFirstFileInfo((prev) => {
            console.log("prev", prev);
            return Object.assign({}, prev, { error: true });
          });
        } else {
          setSecondFileInfo((prev) => {
            return Object.assign({}, prev, { error: true });
          });
        }
        return;
      }
      if (size / 1000000 < 5) {
        const res = await getS3PutUrl({
          prefix: "whitelist",
          file_type: fileNameSuffix,
        });
        if (res.code === 0) {
          const putUrl = res?.data?.put_url;
          const filePath = res?.data?.file_path;
          axios({
            url: putUrl,
            method: "put",
            data: file,
            headers: {
              "X-Amz-Server-Side-Encryption": "aws:kms",
              "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
            },
          }).then((uploadRes) => {
            if (uploadRes.status === 200) {
              if (i === 0) {
                setFirstFileInfo({
                  name: filePath,
                  fullName: name,
                  errors: false,
                });
              } else if (i === 1) {
                setSecondFileInfo({
                  name: filePath,
                  fullName: name,
                  errors: false,
                });
              }
            }
          });
        }
      }
    }
  };

  const renderCexItem = (cexobj) => {
    return (
      <Flex alignItems={"center"}>
        <Image
          src={cexobj.logo}
          width={"20px"}
          height={"20px"}
          mr={"10px"}
          borderRadius={"5px"}
        />
        <Text fontSize="14px">{cexobj.name}</Text>
      </Flex>
    );
  };

  return (
    <>
      <Flex
        alignItems={"center"}
        flexDirection={"column"}
        w={["90%", "500px"]}
        justifyContent={"flex-start"}
        margin={"0 auto"}
      >
        <Text fontSize={"24px"} fontWeight={600}>
          {t("BbqJaDHRCDmiNwNVcGB")}
        </Text>
        {/* 账户类型 */}
        <Box mt={"20px"} width={"100%"}>
          <Text fontSize="14px">{t("iuOLeRpygvfPFDnf")}</Text>
          <Flex mt={"10px"}>
            {accountTypeOptions.map((d) => {
              return (
                <AccountTypeItem
                  onClick={() => setAccountType(d.id)}
                  style={{
                    flex: 1,
                    marginLeft: d.id === ACCOUNT_TYPE.COMPANY && "10px",
                  }}
                  isActive={d.id === accountType}
                  text={d.text}
                  key={d.id}
                />
              );
            })}
          </Flex>
        </Box>

        {/* 交易所名称 */}
        <Box mt={"30px"} width={"100%"}>
          <Text fontSize="14px">{t("mDlqsQlmoApWZXHKLq")}</Text>
          <BXNSelect
            style={{ marginTop: "10px" }}
            options={vaspList}
            defaultOpt={vaspList[0]}
            handleSelect={({ did }) => {
              mergeParams({ whitelistVaspDid: did });
            }}
            renderLabel={(item) => renderCexItem(item)}
            renderItem={(item) => renderCexItem(item)}
          />
        </Box>
        {/* 公司全名 */}
        {accountType === ACCOUNT_TYPE.COMPANY && (
          <Box mt={"30px"} width={"100%"}>
            <Text color={"#010313"} fontSize={"14px"}>
              {t("kjqqAmIhgKSjlBVQx")}
            </Text>
            <Input
              _placeholder={{ fontSize: "12px" }}
              fontSize={"14px"}
              borderRadius={"12px"}
              padding={"0px 20px"}
              placeholder={t("idqLvJqjeiEmQbpa")}
              mt={"10px"}
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            />
          </Box>
        )}

        {/* 姓 名 */}
        {accountType === ACCOUNT_TYPE.PERSONAL && (
          <Flex
            justifyContent={"flex-start"}
            alignItems={"center"}
            mt={"40px"}
            w={"100%"}
          >
            {/* 姓*/}
            <Flex flexDirection={"column"} flex={1}>
              <Text fontSize="14px" w={["60px", "80px"]}>
                {t("msvgVjHCdtHxFKkrS")}
              </Text>
              <Input
                value={verifyParams.lastName}
                bg="#fff"
                fontSize="14px"
                mt={"10px"}
                padding={"0px 20px"}
                placeholder={t("idqLvJqjeiEmQbpa")}
                border={"1px solid #E3E4F1"}
                onChange={(e) => mergeParams({ lastName: e.target.value })}
              />
            </Flex>

            {/* 名 */}
            <Flex flexDirection={"column"} flex={1} ml={"20px"}>
              <Text fontSize="14px" textAlign={"left"}>
                {t("SUObcbdMwQrjwarfaMi")}
              </Text>
              <Input
                value={verifyParams.firstName}
                padding={"0px 20px"}
                placeholder={t("idqLvJqjeiEmQbpa")}
                mt={"10px"}
                bg="#fff"
                onChange={(e) => mergeParams({ firstName: e.target.value })}
                fontSize="14px"
              />
            </Flex>
          </Flex>
        )}

        {/* 请确保与您交易所账户的姓名一致 */}
        <Text
          color={"#7B7C8F"}
          fontSize={"12px"}
          textAlign={"left"}
          mt={"10px"}
          w={"100%"}
        >
          {t("nFlxQWOJCGHgwwHQhP")}
        </Text>
        {/* 上传交易所账户所有权证明 */}
        <Flex w={"100%"} flexDirection={"column"} mt={"30px"}>
          <Text size={"16px"} fontWeight={"bold"}>
            {t("gapTtWntRtJcQzRtBz")}
          </Text>
          {verifyEles.map((d, i) => {
            return (
              <Box position="relative" key={i} mt={i === 1 && "30px"}>
                <Text
                  fontSize={"14px"}
                  color={"#010313"}
                  mt={i === 0 && "10px"}
                >
                  {d.text}
                </Text>
                <Flex justifyContent={"space-between"} mt="10px">
                  <Input
                    w="80px"
                    type="file"
                    opacity={"0"}
                    position={"absolute"}
                    right="0"
                    mt={"20px"}
                    zIndex={"2"}
                    cursor="pointer"
                    fontSize="0"
                    onChange={(e) => {
                      hanleFileUpload(e, i);
                    }}
                  />
                  <Input
                    w={["220px", "410px"]}
                    disabled
                    borderRadius={"12px"}
                    _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
                    value={
                      i === 0 ? firstFileInfo.fullName : secondFileInfo.fullName
                    }
                  />
                  <Button
                    w="80px"
                    h="30px"
                    fontSize="12px"
                    padding={"4px 10px"}
                    color="#fff"
                    borderRadius={"20px"}
                    bg="blue.100"
                    textAlign={"center"}
                    _hover={{ bg: "blue.80" }}
                    mt="5px"
                  >
                    {t("NqgOQEXQaHsramCnbX")}
                  </Button>
                </Flex>
                <Text
                  fontSize={"12px"}
                  color={
                    (i === 0 && firstFileInfo.error) ||
                    (i === 1 && secondFileInfo.error)
                      ? "red.100"
                      : "t.80"
                  }
                  mt={"5px"}
                >
                  {t("RrEKjSeFQrtvLrZIO")}
                </Text>
              </Box>
            );
          })}
        </Flex>
        {/* 责任声明 */}
        <Flex mt={"50px"} alignItems={"flex-start"}>
          <Image
            mt={"3px"}
            cursor={"pointer"}
            onClick={() => {
              setChecked(!checked);
            }}
            src={
              checked
                ? "../../wwwsite/images/kyc/checked.svg"
                : "../../wwwsite/images/kyc/no_check.svg"
            }
          />
          <Text fontSize={"12px"} color={"t.100"} fontWeight={400} ml={"5px"}>
            {t("qZljkUmjmSBKpnUsYfp")}
          </Text>
        </Flex>
        <Flex justifyContent={"center"} mt="40px" mb={"50px"}>
          <Button
            mr="10px"
            fontSize="14px"
            padding="0 30px"
            w={["100%", "100px"]}
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => setStep(WHITELIST_STEPS.ADD)}
          >
            {t("FrzWGSiDSDzTfNgZH")}
          </Button>
          <Button
            fontSize="14px"
            padding="0 30px"
            w={["100%", "200px"]}
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            bg={canSubmit ? "blue.100" : "t.50"}
            _hover={{ bg: canSubmit ? "blue.80" : "t.50" }}
            onClick={() => {
              if (canSubmit) {
                // 交易所 地址验证
                setVerifyData({
                  visible: true,
                  type: WHITELIST_CATEGORY.HOSTED,
                });
              }
            }}
          >
            {t("YChcfaxDaLscdSPiRQz")}
          </Button>
        </Flex>
      </Flex>
      {verifyPwdData.visible && (
        <PasswordVerify
          onClose={() =>
            setVerifyData({
              visible: false,
              type: "",
            })
          }
          onOk={({ pwd }) => {
            if (canSubmit) {
              const params = {
                name: addressName,
                currency: currency,
                network: network,
                address: address,
                file1: `${firstFileInfo.name}`,
                file2: `${secondFileInfo.name}`,
                user_type: accountType,
                agreement: true,
                vasp_id: verifyParams.whitelistVaspDid,
              };
              if (accountType === ACCOUNT_TYPE.PERSONAL) {
                params.first_name = verifyParams.firstName;
                params.last_name = verifyParams.lastName;
              }
              if (accountType === ACCOUNT_TYPE.COMPANY) {
                params.company_name = companyName;
              }
              verifyPwdFunc({ pwd, params });
            }
          }}
        />
      )}
    </>
  );
};

export default VerifyCEX;
