import { useAppSelector } from "@/state/hooks";
import {
  Image,
  Box,
  Text,
  Link,
  useMediaQuery,
  Button,
} from "@chakra-ui/react";
import copyToClipboard from "copy-to-clipboard";
import { toastTip } from "@/components/ui/toast";

import {
  getAmount,
  getNote,
  browserUrl,
  getTitle,
  getTime,
  getStatusString,
} from "@/pages/historyBill/helpers/func/billShowData";
import { useI18n } from "@/hooks/useI18n";
import { useNavigate } from "react-router-dom";
import "./index.scss";

import { BILL_APPROVING } from "@/pages/historyBill/helpers/constants/billStatusName";

const BillDetail = (props) => {
  // 小于739
  const [isSmaller] = useMediaQuery("(max-width: 739px)");

  const { t } = useI18n();
  const navigate = useNavigate();

  const _lang = useAppSelector((state) => state.user.language);

  const { showAlert, getInfo, closeEvent } = props;

  const fiatArr = ["fiat_deposit", "fiat_withdraw"];

  const closeAlert = () => {
    closeEvent();
  };
  // 复制
  const clickCopy = (getValue) => {
    copyToClipboard(getValue);
    toastTip(t("gXbirwakdsaUKBglwK"), { type: "success" });
  };

  const detailUnitDom = (getObject) => {
    return (
      <Box
        mt={getObject.mt}
        display={"flex"}
        justifyContent={"space-between"}
        fontSize={"14px"}
        fontWeight={400}
      >
        <Text color={"#7B7C8F"} mr="17px">
          {getObject.title}
        </Text>
        {getObject?.content && (
          <Text
            color={"#010313"}
            flex={1}
            wordBreak="break-word"
            textAlign={"right"}
          >
            {getObject.content}
          </Text>
        )}
        {getObject?.url && (
          <Link color="blue.100" href={getObject?.url} isExternal>
            {t("YrkgVnAQcYRhGGRex")}
          </Link>
        )}
        {getObject?.canCopy && (
          <Box
            flex={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Text
              color={"#010313"}
              flex={1}
              wordBreak="break-word"
              textAlign={"right"}
            >
              {getObject.copyContent}
            </Text>
            <Box
              p="3px"
              backgroundColor={"rgba(32, 26, 85, 0.05)"}
              borderRadius="6px"
              ml="10px"
            >
              <Image
                src="../wwwsite/images/copy.svg"
                alt="Dan Abramov"
                cursor={"pointer"}
                onClick={() => {
                  clickCopy(getObject.copyContent);
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };
  return (
    showAlert && (
      <div
        className={`bill-detail-page ${isSmaller ? "bill-detail-h5-page" : ""}`}
        onClick={closeAlert}
      >
        <div className="bill-detail-alert" onClick={(e) => e.stopPropagation()}>
          <div className="bill-detail-alert-header">
            <Image
              boxSize="24px"
              src="../wwwsite/images/bill/close.svg"
              cursor={"pointer"}
              onClick={closeAlert}
            />
          </div>
          <Box>
            <Box
              color={"#010313"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text
                fontSize={"32px"}
                fontWeight={600}
                color={getInfo.status === "FROZEN_CONFIRMED" && "red.100"}
              >
                {getAmount(getInfo)}
              </Text>
              <Text
                fontSize={"12px"}
                fontWeight={400}
                ml="5px"
                color={getInfo.status === "FROZEN_CONFIRMED" && "red.100"}
              >
                {getInfo.currency}
              </Text>
            </Box>
            <Box
              mt="10px"
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Text
                color={
                  getInfo.status === "FROZEN_CONFIRMED" ||
                  getInfo.status === "FROZEN"
                    ? "red.100"
                    : getInfo.status === "ADMIN_REQUIRED"
                    ? "#F89753"
                    : "blue.100"
                }
              >
                {getStatusString(getInfo, t)}
              </Text>
              {/* 冻结 */}
              {getInfo?.status === "FROZEN" && (
                <Button
                  h="30px"
                  fontSize="12px"
                  justifyContent={"center"}
                  color="#fff"
                  borderRadius={"20px"}
                  bg="blue.100"
                  padding={"4px 10px"}
                  textAlign={"center"}
                  _hover={{ bg: "blue.80" }}
                  mt="15px"
                  onClick={() =>
                    navigate(
                      `/trade/verify/txAddress?address=${getInfo?.transaction_id}`
                    )
                  }
                >
                  {t("BbqJaDHRCDmiNwNVcGB")}
                </Button>
              )}
              {/* 充值失败 */}
              {getInfo?.status === "FROZEN_CONFIRMED" &&
                getInfo?.transfer_status === "in" && (
                  <Text
                    color={"#010313"}
                    className="frozen_confirmed"
                    fontSize={"14px"}
                    mt={"25px"}
                    dangerouslySetInnerHTML={{
                      __html: t("CRbVirgSccJqKzSUSEau"),
                    }}
                  ></Text>
                )}
            </Box>

            {getInfo?.address && (
              <Box
                w="100%"
                bgColor="rgba(32, 26, 85, 0.05)"
                borderRadius="12px"
                mt="30px"
                px="10px"
                py="10px"
                display="flex"
                alignItems="center"
                justifyContent={
                  getInfo.is_external ? "space-between" : "center"
                }
              >
                {getInfo.is_external ? (
                  <>
                    <Text
                      color="#010313"
                      fontSize="12px"
                      fontWeight="400"
                      wordBreak="break-word"
                    >
                      {getInfo.address}
                    </Text>
                    <Image
                      src="../wwwsite/images/copy.svg"
                      alt="Dan Abramov"
                      cursor={"pointer"}
                      onClick={() => {
                        clickCopy(getInfo.address);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Image
                      borderRadius="full"
                      boxSize="30px"
                      src={getInfo.icon_url}
                      fallbackSrc="../wwwsite/images/transfer/header_icon.svg"
                      alt="currencyIcon"
                      mr="10px"
                    />
                    <Text className="text-overflow">{t(getInfo.address)}</Text>
                  </>
                )}
              </Box>
            )}
          </Box>
          <Box>
            {getInfo?.service_fee &&
              Number(getInfo?.service_fee) !== 0 &&
              detailUnitDom({
                title: t("xUNmcszdZBWMTcxnI"),
                content: `${getAmount(getInfo, "service_fee", true)} ${
                  getInfo.currency
                }`,
                mt: "30px",
              })}
            {getNote(getInfo, t) &&
              detailUnitDom({
                title: t("gZgeimlXmokhLdRziw"),
                content: getNote(getInfo, t),
                mt: "20px",
              })}
            {getInfo?.is_external && !fiatArr.includes(getInfo.type) &&
              browserUrl(getInfo) &&
              detailUnitDom({
                title: t("moirunBAysHLMEDtBxUt"),
                content: "",
                mt: "20px",
                url: browserUrl(getInfo),
              })}
            <div className="bill-detail-alert-line"></div>
            {detailUnitDom({
              title: t("iuOLeRpygvfPFDnf"),
              content: getTitle(getInfo, _lang, t, true),
              mt: "20px",
            })}
            {detailUnitDom({
              title: t("iuswsIRQwNAIuOzoFX"),
              content: getTime(getInfo.bill_created_at, "YYYY-MM-DD HH:mm:ss"),
              mt: "20px",
            })}
            {getInfo?.transaction_id &&
              detailUnitDom({
                title: getInfo?.is_external && !fiatArr.includes(getInfo?.type)
                  ? t("sBFILAqpJhAnTLlpQ")
                  : t("xqhbTjIzOYuCUiHEUmd"),
                mt: "20px",
                canCopy: true,
                copyContent: getInfo.transaction_id,
              })}
            {getInfo.status === "SUCCESS" &&
              getInfo?.updated_at &&
              detailUnitDom({
                title: t("FgrxZUNDleeDGneKmwd"),
                content: getTime(getInfo?.updated_at, "YYYY-MM-DD HH:mm:ss"),
                mt: "20px",
              })}
          </Box>
          {getInfo?.is_big_deal && getInfo?.status === BILL_APPROVING && (
            <Text textAlign={"center"} fontSize={"14px"} mt="20px">
              {t("bbQJHoMWXkEHQuVRSsO")}
            </Text>
          )}
        </div>
      </div>
    )
  );
};

export default BillDetail;
