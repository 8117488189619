import { Box, Flex, Text, Input, Button } from "@chakra-ui/react";
import Countdown from "@/components/countdonw";
import { postEmailCodeGetFunc, postEmailCodeEvalidationFunc } from "./api";
import { useEffect, useState } from "react";
import { useAppSelector } from "@/state/hooks";
import { throttle } from "lodash";
import { useI18n } from "@/hooks/useI18n";
import StepDesc from "./components/StepDesc";
import { geetestFlow } from "@/pages/resetAccountInfo/api";
import { verifyCodeVaild } from "@/utils/regexp";

const VerifyEmail = (props) => {
  const { setRegisterStep } = props;
  const { t } = useI18n();
  const [reSend, setReSend] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [codeText, setCodeText] = useState("");
  const registerMsg = useAppSelector((state) => state.user.registerMsg);

  const codeChange = (e) => {
    const codeVal = e.target.value;
    setCode(codeVal);
    if (codeVal) {
      setCodeError(false);
      if (verifyCodeVaild(codeVal)) {
        setCodeError(false);
      } else {
        setCodeText(t("yCCsuXAjuRzOLNxo"));
        setCodeError(true);
      }
    } else {
      setCodeText(t("LuigfaXzmPRCftdFOV"));
      setCodeError(true);
    }
  };
  const handleTimeout = () => {
    setReSend(true);
  };

  const sendMsgFunc = throttle(async () => {
    setReSend(true);
    const geetestFlowParams = {
      email: registerMsg.email,
      request_id: String(+new Date()),
    };
    geetestFlow(geetestFlowParams, async () => {
      const sendMsg = await postEmailCodeGetFunc({
        email: registerMsg.email,
        scene: "REGISTER",
      });
      if (sendMsg?.data?.ok) {
        setReSend(false);
      } else {
        setReSend(true);
      }
    });
  }, 5000);

  const checkCode = throttle(async () => {
    if (!code) {
      setCodeText(t("LuigfaXzmPRCftdFOV"));
      setCodeError(true);
      return;
    }
    if (!verifyCodeVaild(code)) {
      setCodeText(t("yCCsuXAjuRzOLNxo"));
      setCodeError(true);
      return;
    }
    const res = await postEmailCodeEvalidationFunc({
      email: registerMsg.email,
      code: code,
      scene: "REGISTER",
    });
    if (res.code === 0) {
      setRegisterStep("2");
    }
  }, 5000);

  useEffect(() => {
    sendMsgFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Flex justifyContent={"center"} mt={["100px", "200px"]}>
        <Box w={["80%", "400px"]}>
          <Text fontSize="32px" fontWeight={"600"} color="t.100">
            {t("wjeaGDSVTuLLjJGehN")}
          </Text>
          <StepDesc active={1} />

          <Text
            fontSize="16px"
            fontWeight={"500"}
            color="t.100"
            mt="50px"
            mb="20px"
          >
            {t("phfuULxmHCuLNyotJ")}
          </Text>
          <Text fontSize="14px" color="t.100">
            {t("OXrwZMwkWAjoSDDALzow")}
          </Text>
          <Flex mt="5px">
            <Input
              h="44px"
              borderRadius={"12px"}
              isInvalid={codeError}
              bg={codeError ? "red.50" : "none"}
              errorBorderColor="red.100"
              onBlur={codeChange}
              placeholder={t("cTlSqXgGENkCAQQJCHx")}
            />

            {reSend ? (
              <Button
                ml="10px"
                fontSize="14px"
                padding="0 30px"
                bg="blue.100"
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                _hover={{ bg: "blue.80" }}
                h="44px"
                cursor={"pointer"}
                onClick={sendMsgFunc}
              >
                {t("fxHeYqwgtTcKAdCKPyVe")}
              </Button>
            ) : (
              <Button
                ml="10px"
                fontSize="14px"
                padding="0 30px"
                bg="t.50"
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                _hover={{ bg: "t.50" }}
                disabled="true"
                h="44px"
                cursor={"not-allowed"}
              >
                {t("fxHeYqwgtTcKAdCKPyVe")}(
                {<Countdown onTimeout={handleTimeout} secondsInit="60" />})
              </Button>
            )}
          </Flex>
          {codeError && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {codeText}
            </Text>
          )}
          <Box>
            <Button
              borderRadius={"20px"}
              w={["100%", "400px"]}
              h="44px"
              fontSize="14px"
              bg="blue.100"
              color="#fff"
              _hover={{ bg: "blue.80" }}
              mt="50px"
              onClick={checkCode}
            >
              {t("tOaRBVUJHpnSgDBrGON")}
            </Button>
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

export default VerifyEmail;
