import { useI18n } from "@/hooks/useI18n";
import "./list.scss";
import { useMemo, useEffect, useState, useCallback } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { deleteWhiteListAddress, getWhiteListAddress } from "../api";
import { WHITELIST_CATEGORY, WHITELIST_STATUS } from "../consts";
import { useNavigate } from "react-router-dom";
import { useMedia } from "@/hooks/useMedia";
import AlertDom from "@/components/ui/alert";
const AddressList = () => {
  const { t } = useI18n();
  const [addressList, setAddressList] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [deleteUUId, setDeleteUUId] = useState("");
  const navigator = useNavigate();
  const { isMobile } = useMedia();
  const memoTexts = useMemo(() => {
    return [
      t("mrdEVkqkBIuEBVzzL"),
      t("hQRkbssUXUGGLhtxc"),
      t("dGRvusBQRnbHUIYQRit"),
      t("AocjyYzlYgRsGzlavU"),
      t("iuOLeRpygvfPFDnf"),
      t("rKSrOqgimgcTrDutYUA"),
      t("wQEsyNYfqlFrkqUvaDx"),
      t("rbefbFgPVyawmaktVYF"),
    ];
  }, [t]);

  useEffect(() => {
    getWhiteList();
  }, []);

  const getWhiteList = () => {
    getWhiteListAddress().then((res) => {
      if (res.data?.WhiteListAddressList) {
        setAddressList(res.data.WhiteListAddressList);
      }
    });
  };
  const deleteWhiteList = () => {
    if (deleteUUId) {
      deleteWhiteListAddress({ uuid: deleteUUId }).then((res) => {
        if (res.data?.ok) {
          getWhiteList();
          setShowDeleteAlert(false);
        }
      });
    }
  };

  const editWhiteList = (item) => {
    navigator(`/trade/whiteAddress/add?uuid=${item.uuid}`);
  };

  const canEdit = useCallback((item) => {
    if (
      item.status === WHITELIST_STATUS.PENDING ||
      item.status === WHITELIST_STATUS.REVIEW
    ) {
      return false;
    }
    return true;
  }, []);

  return (
    <div
      className="white-address-list"
      style={{ minHeight: addressList.length === 0 ? "200px" : "0px" }}
    >
      <div className="header">
        {memoTexts.map((d, i) => {
          return (
            <Text fontSize={"14px"} color={"#7B7C8F"} key={i}>
              {d}
            </Text>
          );
        })}
      </div>
      <div className="body">
        {addressList.map((item, index) => {
          let statusObj = {};
          switch (item.status) {
            case WHITELIST_STATUS.REVIEW:
            case WHITELIST_STATUS.PENDING:
              statusObj = {
                color: "#4B5DFF",
                text: t("hfBBkSOAXqfEsLVFs"),
                bgColor: "rgba(51, 59, 245, 0.10)",
              };
              break;
            case WHITELIST_STATUS.SUCCESS:
              statusObj = {
                color: "#4FBE45",
                text: t("knoGdfgXpoVeAMqHCXu"),
                bgColor: "#F0FCED",
              };
              break;
            case WHITELIST_STATUS.FAILED:
              statusObj = {
                color: "#D04457",
                text: t("SBLHeXGlsYVIdiBNRe"),
                bgColor: "#FCEDEF",
              };
              break;
            default:
              break;
          }
          return (
            <Flex
              fontSize={"14px"}
              color={"#7B7C8F"}
              // borderBottom={ index !== "1px solid rgba(139, 138, 153, 0.10)"}
              _hover={{
                cursor: "pointer",
                backgroundColor: "rgba(32, 26, 85, 0.05) !important",
              }}
              key={item.uuid}
              className="row"
            >
              <Text color={"#010313"} fontSize={"14px"}>
                {item.name}
              </Text>
              <Text color={"#010313"} fontSize={"14px"}>
                {item.currency}
              </Text>
              <Text color={"#010313"} fontSize={"14px"}>
                {item.network}
              </Text>
              <Text color={"#010313"} fontSize={"14px"}>
                {item.address}
              </Text>
              <Text color={"#010313"} fontSize={"14px"}>
                {item.category === WHITELIST_CATEGORY.HOSTED
                  ? t("IIbmROzHEYCycsCyk")
                  : t("mJjedBCPidtXUCYHus")}
              </Text>
              {/* 用途 */}
              <Text color={"#010313"} fontSize={"14px"}>
                {item?.is_deposit && t("gTYMvovCwafJTrjdlKg")}{" "}
                {item?.is_withdraw && t("PczYwMSUlifZlPdkNj")}
              </Text>

              <Box className="status">
                <Box
                  color={statusObj.color}
                  fontSize={"12px"}
                  fontWeight={500}
                  background={statusObj.bgColor}
                  borderRadius={"6px"}
                  padding={"5px 10px"}
                >
                  {statusObj.text}
                </Box>
              </Box>
              {/* </Text> */}
              <Flex
                gap={"15px"}
                backgroundColor={isMobile ? "#fff" : ""}
                className="last"
              >
                {item.status !== WHITELIST_STATUS.SUCCESS ? (
                  <Image
                    cursor={"pointer"}
                    src={
                      item.status === WHITELIST_STATUS.SUCCESS ||
                      item.status === WHITELIST_STATUS.FAILED
                        ? "../../wwwsite/images/whitelist/edit2.svg"
                        : "../../wwwsite/images/whitelist/edit.svg"
                    }
                    w={"24px"}
                    h={"24px"}
                    onClick={() => {
                      if (!canEdit(item)) {
                        return;
                      }
                      editWhiteList(item);
                    }}
                  />
                ) : (
                  <Box w={"24px"} h={"24px"}></Box>
                )}
                <Image
                  cursor={"pointer"}
                  src={
                    item.status === WHITELIST_STATUS.SUCCESS ||
                    item.status === WHITELIST_STATUS.FAILED
                      ? "../../wwwsite/images/whitelist/trash2.svg"
                      : "../../wwwsite/images/whitelist/trash.svg"
                  }
                  w={"24px"}
                  h={"24px"}
                  onClick={() => {
                    if (!canEdit(item)) {
                      return;
                    }
                    setShowDeleteAlert(true);
                    setDeleteUUId(item.uuid);
                  }}
                />
              </Flex>
            </Flex>
          );
        })}
        {!addressList.length && (
          <Text color={"#010313"} fontSize={"14px"} className="no-data">
            {t("BVmyvtMVCbAJXhwSpT")}
          </Text>
        )}
      </div>
      {showDeleteAlert && (
        <AlertDom>
          <Flex
            px="30px"
            textAlign={"center"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={"50px 0"}
            gap={"30px"}
          >
            <Text
              fontWeight={500}
              fontSize={"16px"}
              color={"#000000"}
              w="48%"
              mx={"auto"}
              mt="10px"
            >
              {t("NRUPVKxKVtYaCDVPq")}
            </Text>
            <Flex justifyContent={"center"}>
              <Button
                mr="10px"
                fontSize="14px"
                padding="0 30px"
                w={["100%", "100px"]}
                bg="rgba(32, 26, 85, 0.05)"
                color="t.100"
                fontWeight={"400"}
                borderRadius={"30px"}
                h="44px"
                cursor={"pointer"}
                onClick={() => {
                  setShowDeleteAlert(false);
                }}
              >
                {t("UNForxvjDLCZdGrmWkX")}
              </Button>
              <Button
                fontSize="14px"
                padding="0 30px"
                w={["100%", "200px"]}
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                h="44px"
                cursor={"pointer"}
                bg={"blue.100"}
                _hover={{ bg: "blue.80" }}
                onClick={() => {
                  deleteWhiteList();
                }}
              >
                {t("AQrCocFqoKoYieUnT")}
              </Button>
            </Flex>
          </Flex>
        </AlertDom>
      )}
    </div>
  );
};

export default AddressList;
