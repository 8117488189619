import PropTypes from 'prop-types';

import { 
    Box, 
    Text, 
    Image,
} from "@chakra-ui/react";
import copyToClipboard from 'copy-to-clipboard';
import { toastTip } from '@/components/ui/toast';

import { useI18n } from "@/hooks/useI18n";

const CopyTextStyle = (props) => {
    const { t } = useI18n();

    const { canCopyText, children } = props;

    // 复制
    const clickCopy = () => {
        copyToClipboard(canCopyText);
        toastTip(t('gXbirwakdsaUKBglwK'), { type: "success" });
    }

    return (
        <Box 
            w="100%"
            bgColor="rgba(32, 26, 85, 0.05)" 
            borderRadius="12px" 
            px="10px" 
            py="10px"
            mt="5px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            >
            {children ? children : <Text color="#010313" fontSize="12px" fontWeight="400" wordBreak="break-word">{canCopyText}</Text>}
            <Image src='../wwwsite/images/copy.svg' alt='Dan Abramov' onClick={clickCopy}/>
        </Box>
    )
}

CopyTextStyle.propTypes = {
    canCopyText: PropTypes.string,
    children: PropTypes.object,
};

export default CopyTextStyle;
