import { useI18n } from "@/hooks/useI18n";
import { useMemo, useState } from "react";
import { handleS3UploadFile } from "@/utils/upload";
import { RISK_LEVEL } from "@/constants/kyc";
import { submitCompanyUpdateInfoApi } from "@/api/perodic";

const MAX_UPDATE_INFO_COUNT = 14;
const UPLOAD_FILE_PREFIX = "company_updates";
const useHooks = ({ riskLevel, reviewUUID }) => {
  const { t } = useI18n();

  const mul_file_info = {
    fileName: "",
    error: false,
    filePath: "",
  };

  const update_info = {
    selected_option: null, // MEMO_SELECT_OPTIONS的 type
    service_option: null, //业务选项  MEMO_SELECT_OPTIONS === OPTION_TYPE.SERVICE_TYPE
    monthly_tx_amount_option: null, // 月均交易额选项 MEMO_SELECT_OPTIONS === OPTION_TYPE.MONTHLY_TX_AMOUNT
    monthly_tx_frequency_option: null, // 月均交易额选项 MEMO_SELECT_OPTIONS === OPTION_TYPE.MOTNHLY_TX_FREQUENCY
    found_source_option: null, // 资金来源选项 MEMO_SELECT_OPTIONS === FOUND_SOURCE
    mul_upload_file_list: [mul_file_info], // MEMO_SELECT_OPTIONS === OPTION_TYPE.MUL_TYPE 多文件类型上传列表
  };

  // 公司财富来源证明文件信息
  const rich_approve_file_info = {
    fileName: "",
    error: false,
    filePath: "",
    inputValue: "", // 其他说明
    selected_option: "",
  };

  // 实益拥有人财富来源证明
  const really_owner_rich_approve_file_info = {
    fileName: "",
    error: false,
    filePath: "",
    selected_option: "",
    inputValue: "", // 其他说明
    ownerInputValue: "",
  };

  const PAGE_STATUS = {
    FIRST: "FIRST",
    SECOND: "SECOND",
    RESULT: "RESULT",
  };

  const [updateInfoList, setUpdateInfoList] = useState([update_info]); // 信息类型
  const [serviceInputValue, setServiceInputValue] = useState(); //业务选项的input输入
  const [serviceOtherDescInputValue, setServiceOtherDescInputValue] =
    useState(""); //业务选项为其他时的说明input输入
  const [monthTxFrequencyInputValue, setMonthTxFrequencyInputValue] =
    useState(""); //交易频率选项的input输入
  const [foundSourceInputValue, setFoundSourceInputValue] = useState(""); // 财富来源选项的input输入
  const [richApproveList, setRichApproveList] = useState([
    rich_approve_file_info,
  ]);
  const [reallyOwnerRichApproveList, setReallyOwnerRichApproveList] = useState([
    really_owner_rich_approve_file_info,
  ]);
  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading

  const [currentPage, setCurrentPage] = useState(PAGE_STATUS.FIRST);
  const [showTipModal, setShowTipModal] = useState(false);
  const OPTION_TYPE = {
    MUL_TYPE: "MUL_TYPE",
    SERVICE_TYPE: "SERVICE_TYPE",
    MONTHLY_TX_AMOUNT: "MONTH_TX_AMOUNT",
    MOTNHLY_TX_FREQUENCY: "MOTNHLY_TX_FREQUENCY",
    FOUND_SOURCE: "FOUND_SOURCE",
  };

  const MEMO_RICH_APPROVE_LIST = useMemo(() => {
    return [
      {
        label: t("xGdbGnjcDCabqvFJX"),
        value: 1,
        key: "business_revenue",
      },
      {
        label: t("olJXBlENebamalqAmC"),
        value: 2,
        key: "funds_from_investors",
      },
      {
        label: t("lnaNMhKSbkHzoYmMHp"),
        value: 3,
        key: "fund_from_shareholders",
      },
      {
        label: t("MFJyykUbSXUkUqHxlJ"),
        value: 4,
        key: "business_loan",
      },
      {
        label: t("UsVahXMYImjwSrxR"),
        value: 5,
        key: "sale_of_property",
      },
      {
        label: t("FxzVGfjClbeUZeEero"),
        value: 6,
        key: "other",
      },
    ];
  }, [t]);

  const MEMO_SELECT_OPTIONS = useMemo(() => {
    return [
      {
        label: t("xbTIXxCJIOnbLImHUPI"),
        type: OPTION_TYPE.MUL_TYPE,
        value: "annual_return",
      },
      {
        label: t("VSmzJHKoDZuNenetubvZ"),
        type: OPTION_TYPE.MUL_TYPE,
        value: "certificate_of_incorporation",
      },
      {
        label: t("YJlyfJbAZRZhGensD"),
        type: OPTION_TYPE.MUL_TYPE,
        value: "certificate_of_change_of_name",
      },
      {
        label: t("SuSDXCILmprzPXIonL"),
        type: OPTION_TYPE.MUL_TYPE,
        value: "business_registration_certificate",
      },
      {
        label: t("FnrHWNqUvXlpfQLAfT"),
        type: OPTION_TYPE.MUL_TYPE,
        value: "memorandum_of_association",
      },
      {
        label: t("uPrPwsvUYglduYxnfgb"),
        type: OPTION_TYPE.MUL_TYPE,
        value:
          "certificate_of_incumbency_issued_by_registered_agent_within_6_months",
      },
      {
        label: t("BvERvFYtRmpkINLVdX"),
        type: OPTION_TYPE.MUL_TYPE,
        value: "instrument_of_transfer",
      },

      {
        label: t("MSaPBxOSKeCTtUMPq"),
        type: OPTION_TYPE.MUL_TYPE,
        value: "ownership_chart",
      },
      {
        label: t("olGkjZMWDZBQwjnVK"),
        type: OPTION_TYPE.MUL_TYPE,
        value: "id_document_of_director",
      },
      {
        label: t("PmdVNRJcWUxjWfFSA"),
        type: OPTION_TYPE.MUL_TYPE,
        value: "proof_of_address_of_directors",
      },
      {
        label: t("gCLhGVwztVSCPNZUgqQN"),
        type: OPTION_TYPE.SERVICE_TYPE,
        value: "nature_of_business",
      },
      {
        label: t("ZCRfudjxgEkJLycN"),
        type: OPTION_TYPE.MONTHLY_TX_AMOUNT,
        value: "anticipated_monthly_transaction_volume",
      },
      {
        label: t("JJAtBenOLOlFaFaP"),
        type: OPTION_TYPE.MOTNHLY_TX_FREQUENCY,
        value: "anticipated_monthly_transaction_frequency",
      },
      {
        label: t("DSFAJhsSefQQEdBBlUyt"),
        type: OPTION_TYPE.FOUND_SOURCE,
        value: "source_of_fund",
      },
    ];
  }, [t]);

  const MEMO_SERVICE_OPTIONS = useMemo(() => {
    return [
      {
        label: t("FDynUJSlxYFxtAhrQN"),
        value: 1,
        key: "banking_institution",
      },
      {
        label: t("UBCBvFlsnzzgcIOHTmN"),
        value: 2,
        key: "licensed_money_lender",
      },
      {
        label: t("MERzsdKkxudpOnlaF"),
        value: 3,
        key: "licensed_money_changer",
      },
      {
        label: t("XtpeIeGyrDDKSkgyCiL"),
        value: 4,
        key: "fund_management",
      },
      {
        label: t("LxcfZSEIROwQeVCok"),
        value: 5,
        key: "licensed_payment_service_provider",
      },
      {
        label: t("FxzVGfjClbeUZeEero"),
        value: 6,
        key: "other",
      },
    ];
  }, [t]);

  // index是updateInfoList的索引
  // i updateInfoList的索引[index]里的mul_upload_file_list索引
  const delOrAddMulUploadList = (type, index, i) => {
    setUpdateInfoList((prev) => {
      const currList = [...prev];
      const currMulList = currList[index].mul_upload_file_list;
      if (type === "add") {
        // 限制每个最多只能传10个
        if (currMulList.length === 10) return currList;
        currMulList.push(mul_file_info);
        return currList;
      } else if (type === "delete") {
        if (currMulList.length > 1) {
          currMulList.splice(i, 1);
          return currList;
        }
        return currList;
      }
    });
  };

  const resetMulUploadList = (index) => {
    setUpdateInfoList((prev) => {
      const currList = [...prev];
      currList[index].mul_upload_file_list = [mul_file_info];
      return currList;
    });
  };

  const resetOtherKeyValue = (index, key) => {
    const allKeys = [
      "service_option",
      "monthly_tx_amount_option",
      "monthly_tx_frequency_option",
      "found_source_option",
    ];
    const unsetKeys = allKeys.filter((d) => d !== key);
    const nullObj = {};
    unsetKeys.forEach((d) => {
      nullObj[d] = null;
    });
    setUpdateInfoList((prev) => {
      const currList = [...prev];
      let item = currList[index];
      item = Object.assign({}, item, nullObj);
      currList[index] = item;
      return currList;
    });
  };

  // 首页信息
  const delOrAddUpdasteList = (type, index) => {
    setUpdateInfoList((pre) => {
      const currList = [...pre];
      if (type === "add") {
        if (currList.length === MAX_UPDATE_INFO_COUNT) return currList;
        currList.push(update_info);
        return currList;
      } else if (type === "delete") {
        if (currList.length > 1) {
          currList.splice(index, 1);
          return currList;
        } else {
          return currList;
        }
      }
    });
  };

  // 第二页财富证明
  const delOrAddRichApproveList = (listType, type, index) => {
    if (listType === "COMPANY_RICH") {
      setRichApproveList((pre) => {
        const currList = [...pre];
        if (type === "add") {
          if (currList.length === 20) return currList;
          currList.push(rich_approve_file_info);
          return currList;
        } else if (type === "delete") {
          if (pre.length > 1) {
            currList.splice(index, 1);
            return currList;
          }
          return currList;
        }
      });
    } else if (listType === "REALLY_OWNER_RICH") {
      setReallyOwnerRichApproveList((pre) => {
        const currList = [...pre];
        if (type === "add") {
          if (currList.length === 20) return currList;
          currList.push(really_owner_rich_approve_file_info);
          return currList;
        } else if (type === "delete") {
          if (pre.length > 1) {
            currList.splice(index, 1);
            return currList;
          }
          return currList;
        }
      });
    }
  };

  // 首页更新信息
  const setUpdateInfoListOption = (index, item, key) => {
    setUpdateInfoList((prev) => {
      const currList = [...prev];
      const curr = currList[index];
      if (key === "found_source_option") {
        const currObj = currList[index][key];
        if (!currObj) {
          curr[key] = [item];
          return currList;
        } else {
          const opts = currList[index][key];
          const findIndex = opts.findIndex((d) => d.value === item.value);
          if (findIndex > -1) {
            opts.splice(findIndex, 1);
          } else {
            opts.push(item);
          }

          curr[key] = opts;
          return currList;
        }
      } else {
        curr[key] = item;
      }
      return currList;
    });
  };

  // 更新财富证明/实益人财富证明列表
  // type
  // index 更新的列表的索引

  const setUpdateRichList = ({ type, index, key, value }) => {
    if (type === "COMPANY_RICH") {
      setRichApproveList((prev) => {
        const currList = [...prev];
        currList[index] = Object.assign({}, currList[index], {
          [key]: value,
        });
        return currList;
      });
    } else if (type === "REALLY_OWNER_RICH") {
      setReallyOwnerRichApproveList((prev) => {
        const currList = [...prev];
        currList[index] = Object.assign({}, currList[index], {
          [key]: value,
        });
        return currList;
      });
    }
  };
  // 已经选过的更新信息类型
  // 需要过滤掉
  const memoUpdateFilterOptions = useMemo(() => {
    const list = [];
    const alreadySelected = updateInfoList.map(
      (d) => d?.selected_option?.value
    );
    for (let i = 0; i < MEMO_SELECT_OPTIONS.length; i++) {
      if (alreadySelected.indexOf(MEMO_SELECT_OPTIONS[i].value) < 0) {
        list.push(MEMO_SELECT_OPTIONS[i]);
      }
    }
    return list;
  }, [updateInfoList, MEMO_SELECT_OPTIONS]);

  // 上传文件
  // uploadType:  上传的裂隙
  // file: 文件,
  // preifx: s3的前缀
  // fileTypeSuffix 文件类型后缀
  const uploadFileFn = async ({
    file,
    uploadType,
    fileTypeSuffix,
    preifx,
    faIndex,
    currIndex,
    fileName,
  }) => {
    setUploadLoading(true);
    const uploadRes = await handleS3UploadFile({
      prefix: preifx,
      file_type: fileTypeSuffix,
      file,
    });
    if (uploadRes.status !== "error") {
      setUploadLoading(false);
      const { filePath } = uploadRes;
      if (uploadType === "MUL_FILE") {
        setUpdateInfoList((prev) => {
          const list = [...prev];
          const item = list?.[faIndex]?.mul_upload_file_list?.[currIndex];
          item.filePath = filePath;
          item.error = false;
          item.fileName = fileName;
          return list;
        });
      }

      if (uploadType === "COMPANY_RICH") {
        setRichApproveList((prev) => {
          const list = [...prev];
          const item = list?.[currIndex];
          item.error = false;
          item.filePath = filePath;
          item.fileName = fileName;
          return list;
        });
      }

      if (uploadType === "REALLY_OWNER_RICH") {
        setReallyOwnerRichApproveList((prev) => {
          const list = [...prev];
          const item = list?.[currIndex];
          item.error = false;
          item.filePath = filePath;
          item.fileName = fileName;
          return list;
        });
      }
    } else {
      setUploadLoading(false);
    }
  };

  // 上传文件
  const handleUploadFile = async ({
    uploadType,
    event_core,
    faIndex,
    currIndex,
  }) => {
    const file = event_core.target.files[0];
    if (file) {
      const { size, name } = file;
      const fileTypeSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (
        (fileTypeSuffix === "pdf" || fileTypeSuffix === "jpg") &&
        size / 1000000 < 5
      ) {
        uploadFileFn({
          file,
          uploadType: uploadType,
          fileTypeSuffix,
          preifx: UPLOAD_FILE_PREFIX,
          faIndex,
          currIndex,
          fileName: name,
        });
      } else {
        if (uploadType === "MUL_FILE") {
          setUpdateInfoList((prev) => {
            const list = [...prev];
            const item = list?.[faIndex]?.mul_upload_file_list?.[currIndex];
            item.error = true;
            item.fileName = name;
            return list;
          });
        }

        if (uploadType === "COMPANY_RICH") {
          setRichApproveList((prev) => {
            const list = [...prev];
            const item = list?.[currIndex];
            item.error = true;
            item.fileName = name;
            return list;
          });
        }

        if (uploadType === "REALLY_OWNER_RICH") {
          setReallyOwnerRichApproveList((prev) => {
            const list = [...prev];
            const item = list?.[currIndex];
            item.error = true;
            item.fileName = name;
            return list;
          });
        }
      }
    }
  };

  // 已经选过的更新信息类型
  // 需要过滤掉
  // const memoCompayRichApproveList = useMemo(() => {
  //   const list = [];
  //   const alreadySelected = richApproveList.map(
  //     (d) => d?.selected_option?.value
  //   );
  //   for (let i = 0; i < MEMO_RICH_APPROVE_LIST.length; i++) {
  //     if (alreadySelected.indexOf(MEMO_RICH_APPROVE_LIST[i].value) < 0) {
  //       list.push(MEMO_RICH_APPROVE_LIST[i]);
  //     }
  //   }
  //   return list;
  // }, [richApproveList, MEMO_RICH_APPROVE_LIST]);

  // // 已经选过的更新信息类型
  // // 需要过滤掉
  // const memoReallyOwnerichApproveList = useMemo(() => {
  //   const list = [];
  //   const alreadySelected = reallyOwnerRichApproveList.map(
  //     (d) => d?.selected_option?.value
  //   );
  //   for (let i = 0; i < MEMO_RICH_APPROVE_LIST.length; i++) {
  //     if (alreadySelected.indexOf(MEMO_RICH_APPROVE_LIST[i].value) < 0) {
  //       list.push(MEMO_RICH_APPROVE_LIST[i]);
  //     }
  //   }
  //   return list;
  // }, [reallyOwnerRichApproveList, MEMO_RICH_APPROVE_LIST]);

  // 首页能否提交
  // 所有都必须要提交
  const firstPageCanSubmit = useMemo(() => {
    let flag = true;
    if (updateInfoList?.length > 0) {
      for (let i = 0; i < updateInfoList.length; i++) {
        const currItem = updateInfoList[i];
        const selected_option_type = updateInfoList[i].selected_option?.type;
        // 无选项
        if (!selected_option_type) {
          flag = false;
          break;
        }

        // 上传文件类型
        if (selected_option_type === OPTION_TYPE.MUL_TYPE) {
          const mulList = currItem.mul_upload_file_list;
          for (let j = 0; j < mulList.length; j++) {
            if (!mulList[j].fileName || !mulList[j].filePath) {
              flag = false;
              break;
            }
          }
        }
        // 业务类型
        if (selected_option_type === OPTION_TYPE.SERVICE_TYPE) {
          if (!currItem.service_option || !serviceInputValue) {
            flag = false;
            break;
          }

          if (
            currItem?.service_option?.value === 6 &&
            (!serviceInputValue || !serviceOtherDescInputValue)
          ) {
            flag = false;
            break;
          }
        }

        // 交易额类型
        if (selected_option_type === OPTION_TYPE.MONTHLY_TX_AMOUNT) {
          if (!currItem.monthly_tx_amount_option) {
            flag = false;
            break;
          }
        }

        // 交易频率类型
        if (selected_option_type === OPTION_TYPE.MOTNHLY_TX_FREQUENCY) {
          if (!currItem.monthly_tx_frequency_option) {
            flag = false;
            break;
          }

          if (
            currItem.monthly_tx_frequency_option?.value === 1 &&
            !monthTxFrequencyInputValue
          ) {
            flag = false;
            break;
          }
        }

        // 资金来源
        if (selected_option_type === OPTION_TYPE.FOUND_SOURCE) {
          if (
            !currItem.found_source_option ||
            currItem?.found_source_option?.length === 0 ||
            !foundSourceInputValue
          ) {
            flag = false;
            break;
          }
        }
      }
    } else {
      flag = false;
    }
    return flag;
  }, [
    updateInfoList,
    monthTxFrequencyInputValue,
    serviceOtherDescInputValue,
    serviceInputValue,
    foundSourceInputValue,
  ]);

  // richApproveList、reallyOwnerRichApproveList 都至少要有一个
  const secondPageCanSubmit = useMemo(() => {
    let flag = true;
    if (richApproveList.length > 0 && reallyOwnerRichApproveList.length > 0) {
      for (let i = 0; i < richApproveList.length; i++) {
        const curr = richApproveList[i];
        const { fileName, filePath, inputValue, selected_option, error } = curr;
        if (!fileName || !filePath || !selected_option || error) {
          flag = false;
          break;
        }

        if (selected_option?.value === 6) {
          if (!inputValue) {
            flag = false;
            break;
          }
        }
      }

      for (let i = 0; i < reallyOwnerRichApproveList.length; i++) {
        const curr = reallyOwnerRichApproveList[i];
        const {
          fileName,
          filePath,
          inputValue,
          selected_option,
          ownerInputValue,
          error,
        } = curr;
        if (
          !fileName ||
          !filePath ||
          !selected_option ||
          !ownerInputValue ||
          error
        ) {
          flag = false;
          break;
        }

        if (selected_option?.value === 6) {
          if (!inputValue) {
            flag = false;
            break;
          }
        }
      }
    } else {
      flag = false;
    }

    return flag;
  }, [richApproveList, reallyOwnerRichApproveList]);

  const handleSubmit = async () => {
    let params = {
      is_updated: true,
      review_uuid: reviewUUID,
    };

    let info_documents = [];

    for (let i = 0; i < updateInfoList.length; i++) {
      const currItem = updateInfoList[i];
      const selected_option = updateInfoList[i].selected_option;
      const selected_option_type = updateInfoList[i].selected_option?.type;

      if (currItem?.selected_option?.type === OPTION_TYPE?.MUL_TYPE) {
        const objList = currItem.mul_upload_file_list?.map((d) => {
          return {
            document_type: selected_option?.value,
            document_type_comment: selected_option?.value,
            document_path: d.filePath,
          };
        });
        info_documents.push(...objList);
      }

      // 业务类型
      if (selected_option_type === OPTION_TYPE.SERVICE_TYPE) {
        params.nature_of_business = {
          options: [
            {
              value: currItem?.service_option?.value,
              label: currItem?.service_option?.label,
            },
          ],
          description: serviceInputValue,
        };

        if (currItem?.service_option?.value === 6) {
          params.nature_of_business.other_description =
            serviceOtherDescInputValue;
        }
      }

      // 交易额类型
      if (selected_option_type === OPTION_TYPE.MONTHLY_TX_AMOUNT) {
        params.monthly_turnover = {
          options: [
            {
              value: currItem?.monthly_tx_amount_option?.value,
              label: currItem?.monthly_tx_amount_option?.label,
            },
          ],
        };
      }

      // 交易频率类型
      if (selected_option_type === OPTION_TYPE.MOTNHLY_TX_FREQUENCY) {
        params.monthly_frequency = {
          options: [
            {
              value: currItem?.monthly_tx_frequency_option?.value,
              label: currItem?.monthly_tx_frequency_option?.label,
            },
          ],
          description: monthTxFrequencyInputValue,
        };
      }

      // 资金来源
      if (selected_option_type === OPTION_TYPE.FOUND_SOURCE) {
        params.fund_source = {
          options: currItem.found_source_option,
          description: foundSourceInputValue,
        };
      }
    }
    params.info_documents = info_documents;

    if (riskLevel === RISK_LEVEL.HIGH) {
      const wealth_source_documents = [];
      for (let i = 0; i < richApproveList.length; i++) {
        const item = richApproveList[i];
        wealth_source_documents.push({
          document: {
            document_type: "source_of_wealth_proof_of_company",
            document_type_comment: item?.selected_option?.key,
            document_path: item?.filePath,
          },
          other_description: item?.inputValue,
        });
      }
      params.wealth_source_documents = wealth_source_documents;

      const ubo_wealth_source = [];
      for (let i = 0; i < reallyOwnerRichApproveList.length; i++) {
        const item = reallyOwnerRichApproveList[i];
        ubo_wealth_source.push({
          ubo_wealth_source_documents: [
            {
              document: {
                document_type:
                  "source_of_wealth_proof_of_ultimate_beneficial_owner",
                document_type_comment: item?.selected_option?.key,
                document_path: item?.filePath,
              },
              other_description: item?.inputValue,
            },
          ],
          ubo_names: item?.ownerInputValue,
        });
      }
      params.ubo_wealth_source = ubo_wealth_source;
    }

    console.log("params", params);

    const submitRes = await submitCompanyUpdateInfoApi(params);

    if (submitRes.code === 0 && submitRes?.data?.ok) {
      setShowTipModal(false);
      setCurrentPage(PAGE_STATUS.RESULT);
    } else {
      setShowTipModal(false);
    }
  };

  return {
    MEMO_SELECT_OPTIONS,
    OPTION_TYPE,
    delOrAddMulUploadList,
    updateInfoList,
    delOrAddUpdasteList,
    setUpdateInfoListOption,
    MEMO_SERVICE_OPTIONS,
    serviceInputValue,
    setServiceInputValue,
    monthTxFrequencyInputValue,
    setMonthTxFrequencyInputValue,
    foundSourceInputValue,
    setFoundSourceInputValue,
    serviceOtherDescInputValue,
    setServiceOtherDescInputValue,
    richApproveList,
    MEMO_RICH_APPROVE_LIST,
    reallyOwnerRichApproveList,
    setReallyOwnerRichApproveList,
    firstPageCanSubmit,
    delOrAddRichApproveList,
    handleUploadFile,
    setUpdateRichList,
    uploadLoading,
    secondPageCanSubmit,
    resetMulUploadList,
    memoUpdateFilterOptions,
    handleSubmit,
    // memoCompayRichApproveList,
    // memoReallyOwnerichApproveList,
    setCurrentPage,
    currentPage,
    PAGE_STATUS,
    showTipModal,
    setShowTipModal,
    resetOtherKeyValue,
  };
};

export { useHooks };
