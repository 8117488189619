import { Box, Flex, Text, Center, Input, Button } from "@chakra-ui/react";
import BxSelect from "@/components/ui/select";
import Countdown from "@/components/countdonw";
import MiliCodeInput from "@/components/ui/mili-codeinput/mili-codeinput.component";
import { useAppSelector } from "@/state/hooks";
import { useState, useRef, useEffect } from "react";
import { BIXIN_ACCOUNTMANAGER } from "@/helpers/constants/routeName";
import { toastTip } from "@/components/ui/toast";
import { geetestFlow } from "./api";

import {
  postSendPhone,
  postAuthPhone,
  postResetPhone,
  getUserInfo,
  getAreaList,
} from "./api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { throttle } from "lodash";
import { updateUserInfo, updateCurrentCountry } from "@/state/user/reducer";
import { useI18n } from "@/hooks/useI18n";
const ResetPhone = () => {
  const navigate = useNavigate();
  const { t } = useI18n();
  const [reSend, setReSend] = useState(false);
  const [showCheckCode, setShowCheckCode] = useState(false);
  const [phoneInfo, setPhoneInfo] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [country, setCountry] = useState([]);
  const [code, setCode] = useState("");
  const [nextError, setNextError] = useState(false);
  // const [request_id, setRequest_id] = useState("");
  const codeInputRef = useRef(null);
  const dispatch = useDispatch();
  const _lang = useAppSelector((state) => state.user.language);
  const phoneCode =
    useAppSelector((state) => state.user.userInfo.phone_code) || "852";
  const { request_id } = useAppSelector(
    (state) => state.user.resetPwdValidation
  );

  const getCountry = async () => {
    const res = await getAreaList({});
    if (res.code === 0) {
      const newCountry = [...res.data.areas];
      newCountry.forEach((item, idx) => {
        item.selectName = item.iso_code2;
        item.value = idx;
      });
      setCountry(newCountry);
      // setCountry(res.data.areas);
    }
  };
  const getSelectValue = (value) => {
    console.log(value);
    setPhoneInfo(value);
    dispatch(updateCurrentCountry({ currentCountry: value }));
  };

  const codeInputChange = (val) => {
    if (val) {
      setCode(val);
      setCodeError(false);
    } else {
      setCodeError(true);
    }
  };

  const changePhone = (event) => {
    const phone = event.target.value;
    if (phone) {
      setPhoneNumber(phone);
      setPhoneError(false);
    } else {
      setPhoneError(true);
    }
  };

  const handleTimeout = () => {
    //时间到
    setReSend(false);
  };

  const sendMsgFunc = throttle(async () => {
    if (phoneNumber) {
      // setReSend(true);
      const geetestFlowParams = {
        phone_code:phoneInfo.dial_code || phoneCode,
        phone_number: phoneNumber,
        phone: phoneNumber,
        request_id: request_id,
      };
      geetestFlow(geetestFlowParams, async () => {
        const sendMsg = await postSendPhone({
          phoneCode: phoneInfo.dial_code || phoneCode,
          phoneNumber: phoneNumber,
          scene: "UPDATE",
          request_id,
        });
        if (sendMsg.code === 0) {
          setReSend(true);
          setShowCheckCode(true);
        } else {
          setReSend(false);
          setShowCheckCode(false);
        }
      });
    } else {
      setPhoneError(true);
    }
  }, 5000);

  const verifyPhoneCode = throttle(async () => {
    if (!phoneNumber) {
      return setPhoneError(true);
    }
    if (!code) {
      setShowCheckCode(true);
      return setCodeError(true);
    }
    setNextError(true);
    const res = await postAuthPhone({
      phoneCode: phoneInfo.dial_code || phoneCode,
      phoneNumber: phoneNumber,
      code,
      request_id,
    });
    if (res.code === 0) {
      setNextError(false);
      const resetPhoneRes = await postResetPhone({
        phone: `+${phoneInfo.dial_code || phoneCode}${phoneNumber}`,
      });
      if (resetPhoneRes.code === 0) {
        const userRes = await getUserInfo({});
        if (userRes.code === 0) {
          // loginRes.data.user = { ...loginRes.data.user, ...userRes.data };
          dispatch(updateUserInfo({ userInfo: userRes.data }));
          toastTip(t("MThZOMtyoBvwpuodWEa"));
          setTimeout(() => {
            navigate(`${BIXIN_ACCOUNTMANAGER}?current=1`);
          }, 2000);
        }
      }
    } else {
      setNextError(false);
    }
  });

  useEffect(() => {
    getCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Flex justifyContent={"center"} mt="140px" mb="140px">
        <Box w={["80%", "400px"]}>
          <Center>
            <Text fontSize="32px" fontWeight={"600"} color="t.100" ml="13px">
              {t("FxLhtNEexnBoJElMC")}
            </Text>
          </Center>
          <Text fontSize="14px" color="t.100" mt="60px">
            {t("KEEUdFUfkYDFEeiUhk")}
          </Text>
          <Box>
            {country.length > 0 && (
              <BxSelect
                list={country}
                country={country}
                initName={phoneCode || "852"}
                onGetValue={getSelectValue}
                type="phone"
              ></BxSelect>
            )}
            <Flex justifyContent={"flex-start"} mt="10px">
              <Input
                placeholder={t("GBvqxVjZVWwZtAPZgH")}
                type="number"
                fontSize="12px"
                h="44px"
                borderRadius={"12px"}
                isInvalid={phoneError}
                bg={phoneError ? "red.50" : "none"}
                errorBorderColor="red.100"
                onBlur={changePhone}
              />
              {!reSend ? (
                <Button
                  ml="10px"
                  fontSize={_lang === "en" ? "12px" : "14px"}
                  padding="0 30px"
                  w={_lang === "en" ? "250px" : "150px"}
                  bg="blue.100"
                  color="#fff"
                  fontWeight={"400"}
                  borderRadius={"30px"}
                  _hover={{ bg: "blue.80" }}
                  h="44px"
                  cursor={"pointer"}
                  onClick={sendMsgFunc}
                >
                  {t("BQbWzLQtSHQVHdIDAgJ")}
                </Button>
              ) : (
                <Button
                  ml="10px"
                  fontSize={_lang === "en" ? "12px" : "14px"}
                  padding="0 30px"
                  w={_lang === "en" ? "250px" : "150px"}
                  bg="t.50"
                  color="#fff"
                  fontWeight={"400"}
                  borderRadius={"30px"}
                  _hover={{ bg: "t.50" }}
                  disabled="true"
                  h="44px"
                  cursor={"not-allowed"}
                >
                  {t("fxHeYqwgtTcKAdCKPyVe")}(
                  {<Countdown onTimeout={handleTimeout} secondsInit="60" />})
                </Button>
              )}
            </Flex>
            {phoneError && (
              <Text color="red.100" fontSize="12px" mt="5px">
                {t("KVLFziUaVDlFbnJzh")}
              </Text>
            )}
          </Box>
          {showCheckCode && (
            <Box>
              <Text fontSize="14px" color="t.100" mt="40px">
                {t("OXrwZMwkWAjoSDDALzow")}
              </Text>
              <MiliCodeInput
                ref={codeInputRef}
                onChange={codeInputChange}
                type="text"
                codeboxItemADDClassName="changeBackGround"
              />
              {codeError && (
                <Text color="red.100" fontSize="12px" mt="5px">
                  {t("LuigfaXzmPRCftdFOV")}
                </Text>
              )}
            </Box>
          )}
          <Flex justifyContent={"center"} mt="60px">
            {!nextError ? (
              <Button
                ml="10px"
                fontSize="14px"
                padding="0 30px"
                w="200px"
                bg="blue.100"
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                _hover={{ bg: "blue.80" }}
                h="44px"
                cursor={"pointer"}
                onClick={verifyPhoneCode}
              >
                {t("tOaRBVUJHpnSgDBrGON")}
              </Button>
            ) : (
              <Button
                ml="10px"
                fontSize="14px"
                padding="0 30px"
                w="200px"
                bg="t.50"
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                _hover={{ bg: "t.50" }}
                h="44px"
                cursor={"pointer"}
              >
                {t("tOaRBVUJHpnSgDBrGON")}
              </Button>
            )}

            <Button
              ml="10px"
              fontSize="14px"
              padding="0 30px"
              w="100px"
              bg="rgba(32, 26, 85, 0.05)"
              color="t.100"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={"pointer"}
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("WbPTckCFMKtPHgAlL")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
};

export default ResetPhone;
