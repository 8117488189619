import { useI18n } from "@/hooks/useI18n";
import { useMemo } from "react";

const useConstantsHooks = () => {
  const { t } = useI18n();

  // 证件类型
  const memoCardType = useMemo(() => {
    return [
      {
        label: t("MTiCVGmtBjEPWzsw"),
        value: "ID_CARD",
      },
      {
        label: t("bRDTmhggLlBbrbdtfhM"),
        value: "PASSPORT",
      },
    ];
  }, [t]);

  // 预估月均交易额
  const memoAnticipatedMonthlyTxAmount = useMemo(() => {
    return [
      {
        label: t("tdxlCkYgrpQOxYYJZiF"),
        value: 1,
      },
      {
        label: t("RZiGRHwDUpYgJcRLRnxa"),
        value: 2,
      },
      {
        label: t("aXbxDDurboZtvnPno"),
        value: 3,
      },
      {
        label: t("OUDeIeQIjCliBQIHe"),
        value: 4,
      },
      {
        label: t("AbrPzuZLAZwUuIBnbx"),
        value: 5,
      },
      {
        label: t("pucEyENFLLeSzsJUK"),
        value: 6,
      },
    ];
  }, [t]);

  // 预估月均交易频率
  const memoAnticipatedMonthlyTxFrequency = useMemo(() => {
    return [
      {
        label: t("YWCMpmGuparutCzjXpp"),
        value: 1,
      },
      {
        label: t("aCYhhkYhhIrzUQvFB"),
        value: 2,
      },
      {
        label: t("sVTrHBZPprcyXLgcE"),
        value: 3,
      },
      {
        label: t("oGHfMwDNMhRcKszx"),
        value: 4,
      },
    ];
  }, [t]);

  // 财富来源
  const memoWealthSource = useMemo(() => {
    return [
      {
        label: t("gGrAIKLFJuNxbjhnZz"),
        value: 1,
        key: "employment_income",
        i18nKey: "gGrAIKLFJuNxbjhnZz",
      },
      {
        label: t("trJoqkSnMVAvbRgAY"),
        value: 2,
        key: "business_earnings",
        i18nKey: "trJoqkSnMVAvbRgAY",
      },
      {
        label: t("CyNhoDlSuMvcxydtF"),
        value: 3,
        key: "inheritance_gifts",
        i18nKey: "CyNhoDlSuMvcxydtF",
      },
      {
        label: t("dpvvLNDzKxLZIuixW"),
        value: 4,
        key: "rental_income",
        i18nKey: "dpvvLNDzKxLZIuixW",
      },
      {
        label: t("cYSzpXPrPUQxaAGTihK"),
        value: 5,
        key: "sale_of_property_investment",
        i18nKey: "cYSzpXPrPUQxaAGTihK",
      },
      {
        label: t("KSyKpOAjkLSpCGns"),
        value: 6,
        key: "other",
        i18nKey: "KSyKpOAjkLSpCGns",
      },
    ];
  }, [t]);

  // 其他国家/地区的证件类型
  const memoOtherCountryCardType = useMemo(() => {
    return [
      {
        label: t("TeRrrjzubYVYnBhsdpD"),
        value: "FOREIGN_RESIDENT_ID_CARD",
      },
      {
        label: t("xwQqYnxnxmeJPqRlQ"),
        value: "VISA",
      },
    ];
  }, [t]);

  // 其他国家/地区身份证件
  const memoHasOtherCountryCard = useMemo(() => {
    return [
      {
        label: t("NltNaOnxuMeUAbBeZ"),
        value: 1,
      },
      {
        label: t("HmXYHUAhkQnrZSfKu"),
        value: 2,
      },
    ];
  }, [t]);

  // 资金来源
  const memoFundSource = useMemo(() => {
    return [
      {
        label: t("aaKDUKbsmRiynWiqlS"),
        value: 1,
      },
      {
        label: t("JLIvWEpTgjlkpsZBM"),
        value: 2,
      },
      {
        label: t("eDeADqoJRnEUGotYxzY"),
        value: 3,
      },
      {
        label: t("CDwWwMbhHZXYRMZHKnpO"),
        value: 4,
      },
      {
        label: t("KSyKpOAjkLSpCGns"),
        value: 5,
      },
    ];
  }, [t]);

  // 公司资金来源
  const memoCompanyFoundSource = useMemo(() => {
    return [
      {
        label: t("yicaYCvBFspqWpPqS"),
        value: 1,
      },
      {
        label: t("YLfvFQfagZeMpkzlO"),
        value: 2,
      },
      {
        label: t("fsjyqgrUVSYOXDUxw"),
        value: 3,
      },
      {
        label: t("WQsiZpVbrxKoaVkQ"),
        value: 4,
      },
      {
        label: t("KpLdefqDhhhdklLzxOgC"),
        value: 5,
      },
      {
        label: t("KSyKpOAjkLSpCGns"),
        value: 6,
      },
    ];
  }, [t]);

  return {
    memoCardType,
    memoFundSource,
    memoHasOtherCountryCard,
    memoAnticipatedMonthlyTxFrequency,
    memoWealthSource,
    memoAnticipatedMonthlyTxAmount,
    memoOtherCountryCardType,
    memoCompanyFoundSource,
  };
};

export default useConstantsHooks;
