import { Box, Flex, Text, Input, Button } from "@chakra-ui/react";
import BxSelect from "@/components/ui/select";
import Countdown from "@/components/countdonw";
import MiliCodeInput from "@/components/ui/mili-codeinput/mili-codeinput.component";
import { useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { throttle } from "lodash";
import { jsUuid } from "@/helpers/func/stringPro";
import { useI18n } from "@/hooks/useI18n";
import { useAppSelector } from "@/state/hooks";
import { geetestFlow } from "@/pages/resetAccountInfo/api";
import { useCommonHooks } from "../register/hooks";
import { useAppDispatch } from "@/state/hooks";
import { updateUserInfo } from "@/state/user/reducer";
import {
  // checkPhone,
  verifyPhoneCode,
  sendPhoneCodeApi,
  postLoginFunc,
} from "./api";
import { toastTip } from "@/components/ui/toast";
import { BIXIN_WALLET } from "@/helpers/constants/routeName";
import { verifyCodeVaild } from "@/utils/regexp";

const INIT_PHONE_CODE = "852";

const phoneInputError = {
  EXISTED: "EXISTED",
  NOT_INPUT: "NOT_INPUT",
};

const BindPhone = ({ password, emailInfo }) => {
  const { t } = useI18n();
  const [reSend, setReSend] = useState(false);
  const [showCheckCode, setShowCheckCode] = useState(false);
  const [phoneCodeInfo, setPhoneCodeInfo] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [code, setCode] = useState("");
  const [areaError, setAreaError] = useState({});
  const codeInputRef = useRef(null);
  const _lang = useAppSelector((state) => state.user.language);
  const navigator = useNavigate();
  const dispatch = useAppDispatch();

  const { allCountryList, sanctionsCountryList, noCompliantCountryList } =
    useCommonHooks();

  // 能否发送手机号
  const canSendCode = useMemo(() => {
    if (areaError?.san) {
      return false;
    }
    return true;
  }, [areaError]);

  // 获取区号
  const getSelectCountry = (value) => {
    setAreaError({});
    // 在制裁区
    if (sanctionsCountryList.indexOf(value.iso_code2) > -1) {
      setAreaError({ san: true });
      return;
    }
    // 非合规区
    if (noCompliantCountryList.indexOf(value.iso_code2) > -1) {
      setPhoneCodeInfo(value);
      setAreaError({ noComp: true });
      return;
    }
    setPhoneCodeInfo(value);
  };

  // 验证码
  const codeInputChange = (val) => {
    if (val) {
      setCode(val);
      setCodeError(false);
    } else {
      setCodeError(true);
    }
  };

  //  电话号码
  const changePhone = (event) => {
    const phone = event.target.value;
    if (phone) {
      setPhoneNumber(phone);
      setPhoneError("");
    } else {
      setPhoneError(phoneInputError.NOT_INPUT);
    }
  };

  // 发送验证码
  const getCodeWithPhone = throttle(async () => {
    if (!phoneNumber) {
      return setPhoneError(phoneInputError.NOT_INPUT);
    }

    const params = {
      phoneCode: phoneCodeInfo.dial_code || INIT_PHONE_CODE,
      phoneNumber,
    };

    setReSend(false);
    const geetestFlowParams = {
      phone_code: params.phoneCode,
      phone_number: params.phoneNumber,
      phone: phoneNumber,
      request_id: `LOGIN_BIND_PHONE:${jsUuid()}`,
    };
    geetestFlow(geetestFlowParams, async () => {
      const sendMsgRes = await sendPhoneCodeApi({
        request_id: "LOGIN",
        ...params,
      });
      if (sendMsgRes.code === 0) {
        setReSend(true);
        setShowCheckCode(true);
      } else {
        setReSend(false);
        setShowCheckCode(false);
        if (
          sendMsgRes.reason === "INVALID_PHONE" ||
          sendMsgRes.reason === "PHONE_HAS_BEEN_OCCUPIED"
        ) {
          toastTip(sendMsgRes.message, { type: "error" });
        }
      }
    });
  }, 5000);

  // 验证手机号和短信码
  const verifyPhoneCodeFunc = throttle(async () => {
    if (!phoneNumber) {
      return setPhoneError(phoneInputError.NOT_INPUT);
    }

    if (!code || !verifyCodeVaild(code)) {
      setShowCheckCode(true);
      return setCodeError(true);
    }

    if (!canSendCode) {
      return;
    }

    const params = {
      phoneCode: phoneCodeInfo.dial_code || INIT_PHONE_CODE,
      phoneNumber,
    };
    // 1094daff5ee546dc8f5252ec71ccdadc

    const res = await verifyPhoneCode({
      code,
      request_id: "LOGIN",
      ...params,
    });
    if (res.code === 0) {
      const loginRes = await postLoginFunc({
        email: emailInfo,
        loginPassword: password,
        phone_code: params.phoneCode,
        phone_number: params.phoneNumber,
      });

      if (loginRes.code !== 0) {
        toastTip(loginRes?.message, { type: "error" });
      } else if (loginRes.code === 0) {
        localStorage.setItem("token", loginRes.data.token);
        dispatch(updateUserInfo({ userInfo: loginRes.data.user }));
        navigator(BIXIN_WALLET);
      }
    }
  });

  return (
    <Flex justifyContent={"center"} mt={["100px", "200px"]} mb="50px">
      <Box w={["80%", "400px"]}>
        <Text fontSize="32px" fontWeight={"600"} color="t.100">
          {t("OivHVusJobtDPuGMSbDN")}
        </Text>
        <Text fontSize={"16px"} color="t.100" fontWeight={500} mt={"50px"}>
          {t("NgUCFdNkwlZfzWkAiv")}
        </Text>
        <Text fontSize={"14px"} color="t.100" mt={"20px"}>
          {t("sdgZycbHzMUKHwVH")}
        </Text>
        <Box>
          {allCountryList.length > 0 && (
            <BxSelect
              style={{ height: "100px" }}
              list={allCountryList}
              country={allCountryList}
              initName={INIT_PHONE_CODE}
              onGetValue={getSelectCountry}
              hasError={areaError?.san || areaError?.noComp ? true : false}
              type="phone"
            ></BxSelect>
          )}
          {areaError && (
            <Text color={"#D04457"} fontSize={"14px"}>
              {areaError?.san
                ? t("mzWsFtJNWfexuuUgYctv")
                : areaError?.noComp
                ? t("CtiOEevtmgSCxLlIqb")
                : ""}
            </Text>
          )}

          <Flex justifyContent={"flex-start"} mt="10px">
            <Input
              placeholder={t("GBvqxVjZVWwZtAPZgH")}
              type="number"
              fontSize="12px"
              h="44px"
              borderRadius={"12px"}
              isInvalid={phoneError}
              bg={phoneError ? "red.50" : "none"}
              errorBorderColor="red.100"
              onBlur={changePhone}
            />
            {!reSend ? (
              <Button
                ml="10px"
                fontSize={_lang === "en" ? "12px" : "14px"}
                padding="0 30px"
                w={_lang === "en" ? "250px" : "150px"}
                bg={canSendCode ? "blue.100" : "t.50"}
                _hover={{ bg: canSendCode ? "blue.80" : "" }}
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                h="44px"
                cursor={canSendCode ? "pointer" : "not-allowed"}
                onClick={() => {
                  if (canSendCode) {
                    getCodeWithPhone();
                  }
                }}
              >
                {t("BQbWzLQtSHQVHdIDAgJ")}
              </Button>
            ) : (
              <Button
                ml="10px"
                fontSize={_lang === "en" ? "12px" : "14px"}
                padding="0 30px"
                w={_lang === "en" ? "250px" : "150px"}
                bg="t.50"
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                _hover={{ bg: "t.50" }}
                disabled="true"
                h="44px"
                cursor={"not-allowed"}
              >
                {t("fxHeYqwgtTcKAdCKPyVe")}(
                {
                  <Countdown
                    onTimeout={() => setReSend(false)}
                    secondsInit="60"
                  />
                }
                )
              </Button>
            )}
          </Flex>
          {phoneError && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {phoneError === phoneInputError.NOT_INPUT
                ? t("KVLFziUaVDlFbnJzh")
                : t("wfRBtzUHBPZjVDxUc")}
            </Text>
          )}
        </Box>
        {showCheckCode && (
          <Box>
            <Text fontSize="14px" color="t.100" mt="40px">
              {t("OXrwZMwkWAjoSDDALzow")}
            </Text>
            <MiliCodeInput
              ref={codeInputRef}
              onChange={codeInputChange}
              type="text"
              codeboxItemADDClassName="changeBackGround"
            />
            {codeError && (
              <Text color="red.100" fontSize="12px" mt="5px">
                {t("LuigfaXzmPRCftdFOV")}
              </Text>
            )}
          </Box>
        )}
        <Button
          ml="10px"
          fontSize="14px"
          padding="0 30px"
          w="100%"
          mt={"40px"}
          bg="blue.100"
          color="#fff"
          fontWeight={"400"}
          borderRadius={"30px"}
          _hover={{ bg: "blue.80" }}
          h="44px"
          cursor={"pointer"}
          onClick={verifyPhoneCodeFunc}
        >
          {t("YChcfaxDaLscdSPiRQz")}
        </Button>
      </Box>
    </Flex>
  );
};

export default BindPhone;
