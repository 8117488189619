import request from "@/hooks/useRequest";
import {
  POSTIDENTIFICATION,
  POSTSENDCODE,
  POSTVERIFY,
  GETPROCESSINFO,
  POSTPROOFOFADDRESS,
  GETSUMMARYINFO,
  GETUSERINFO,
  GETAREALIST,
  POSTUPLOAD,
  POSTGETJUMIOURL,
} from "@/helpers/constants/requestApi";

//kyc身份证明信息提交
export const postIdentification = (body) => {
  return request(POSTIDENTIFICATION, { body });
};

//kyc手机号-发送验证码
export const postSendCode = (body) => {
  return request(POSTSENDCODE, { body });
};

//kyc手机号-验证（绑定）
export const postPhoneVerify = (body) => {
  return request(POSTVERIFY, { body });
};

//获取kyc进程信息
export const getProcessInfo = (params) => {
  return request(GETPROCESSINFO, { params });
};

//kyc地址证明信息提交
export const postProofOfAddress = (body) => {
  return request(POSTPROOFOFADDRESS, { body });
};

//获取用户kyc概要信息
export const getSummaryInfo = (params) => {
  return request(GETSUMMARYINFO, { params });
};

//获取用户信息
export const getUserInfo = (params) => {
  return request(GETUSERINFO, { params });
};

//获取国家和区号
export const getAreaList = (params) => {
  return request(GETAREALIST, { params });
};

//上传文件
export const postUpload = (body) => {
  return request(POSTUPLOAD, { body });
};

//获取jumio地址
export const postGetJumioUrl = (body) => {
  return request(POSTGETJUMIOURL, { body });
};

// 财务资料和资产证明
export const postUploadFinancial = (body) => {
  return request("/web-wallet/api/v1/kyc/financial/create", { body });
};

// s3 put url
export const getS3PutUrl = (params) => {
  return request("/web-wallet/api/v1/s3/get-private-put-url", { params });
};
