import PageWrap from "@/components/PageWrap";
import { useEffect } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { getProcessInfo } from "./api";
import { updateKycInfo } from "@/state/kyc";
import { useDispatch } from "react-redux";
import LicesedPerson from "./LicesedPerson";
import BankTransfer from "./BankTransfer";
import { getPageQuery } from "@/utils";

const Verify = () => {
  const dispatch = useDispatch();
  const urlParams = getPageQuery();

  const fetchGetProcessInfo = async () => {
    const res = await getProcessInfo({});
    if (res.code === 0) {
      dispatch(updateKycInfo(res.data.process));
    }
  };

  useEffect(() => {
    fetchGetProcessInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        pt={"60px"}
      >
        <Flex
          w={urlParams.type === "bank" ? ["80%", "400px"] : ["80%", "460px"]}
          flexDirection={"column"}
          margin="0 auto"
          alignItems={"center"}
        >
          {urlParams.type === "bank" && <BankTransfer />}
          {urlParams.type === "other" && <LicesedPerson />}
        </Flex>
      </Box>
    </PageWrap>
  );
};

export default Verify;
