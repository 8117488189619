import { useNavigate } from "react-router-dom";

import {Image, Text, Box, Flex, Button} from "@chakra-ui/react";
import CommonBtnDom from '@/pages/deposit/component/commonBtn';

import { BIXIN_WALLET } from '@/helpers/constants/routeName';
import { PAYSUCCESS, ADMINREQUIRED } from '@/pages/withdraw/helpers/constants/payStatusName';

import { useI18n } from "@/hooks/useI18n";
import {WITHDRAWBANK} from "@/pages/withdraw/helpers/constants/pageTypeName";

const WithdrawResultPage = (props) => {
    const navigate = useNavigate();

    const { t } = useI18n();

    const { payamount, currency, status, withdrawType } = props;

    const isBankWithDrawFlag = withdrawType === WITHDRAWBANK;

    const clickBackWallet = () => {
        navigate(BIXIN_WALLET);
    }

    const renderViewDom = () => {

        if(isBankWithDrawFlag) {
            return (
                <Box
                    w={["100%", "350px"]}
                    margin={"0 auto"}
                    mt={"60px"}
                    borderRadius={"12px"}
                    textAlign={"center"}
                    fontWeight={"bold"}
                >
                    <Flex
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Image
                            src={`../../wwwsite/images/kyc/certificate/pending.svg`}
                            width={"40px"}
                            height={"40px"}
                            alt=""
                        />
                        <Text
                            fontSize={"24px"}
                            fontWeight={600}
                            color={"#010313"}
                            mt={"10px"}
                        >
                            {t("KzFzOytWXScqDRKFwg")}
                        </Text>
                        <Text
                            fontSize={"14px"}
                            fontWeight={400}
                            color={"#010313"}
                            mt={"10px"}
                        >
                            {t("TNXdbUElFLwiyRVKuJ")}
                        </Text>
                    </Flex>
                    <Button
                        mt="53px"
                        fontSize="14px"
                        padding="0 30px"
                        w="100px"
                        bg="rgba(32, 26, 85, 0.05)"
                        color="t.100"
                        fontWeight={"400"}
                        borderRadius={"30px"}
                        h="44px"
                        cursor={"pointer"}
                        onClick={clickBackWallet}
                    >
                        {t("FrzWGSiDSDzTfNgZH")}
                    </Button>
                </Box>
            );
        }
        return (
            <>
                <Image
                    borderRadius='full'
                    boxSize='120px'
                    src='../wwwsite/images/transfer/success.svg'
                    alt='success icon'
                    mx={'auto'}
                />
                <Text mt="50px" fontSize="24px" fontWeight={600} color="#010313">
                    {status === PAYSUCCESS && t('xrYEaiWUOcaNRQMIf')}
                    {status === ADMINREQUIRED && t('bksAzarViFUYGzwAOk')}
                </Text>
                {status === PAYSUCCESS && <Text mt="10px" fontSize="14px" fontWeight={400} color="#D04457">-{payamount} {currency}</Text>}
                {status === ADMINREQUIRED &&
                <Text mt="10px" fontSize="14px">
                    {t('EOLVYvzyNXSKoGmX')}
                </Text>
                }
                <Box>
                    <CommonBtnDom
                        btnText={t('CKVRBnYMLalEUVhYrqI')}
                        marginTop="80px"
                        btnBG="#010313"
                        btnHoverBG="text.80"
                        clickEvent={clickBackWallet}
                    />
                </Box>
            </>
        );
    };

    return (
        <div className="withdraw-result-page">
            {renderViewDom()}
        </div>
    )
}

export default WithdrawResultPage;