import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./state";
import { I18nProvider } from "@/components/I18nProvider/I18nProvider";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateLanguage } from "@/state/user/reducer";

function AppInit() {
  const dispatch = useDispatch();
  const language = localStorage.getItem("bx_curLang");
  useEffect(() => {
    dispatch(updateLanguage({ language: language }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  return <></>;
}

export const AppProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nProvider>
          <AppInit />
          {children}
        </I18nProvider>
      </PersistGate>
    </Provider>
  );
};
