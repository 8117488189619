import { useMedia } from "@/hooks/useMedia";
import "./index.scss";
import { Image, Flex, Text } from "@chakra-ui/react";
import ModalComponent from "@/components/ui/modal";

const CurrencyDetail = ({ data, onClose }) => {
  const { isMobile } = useMedia();
  return (
    <div className="currency-detial-mask">
      {!isMobile && (
        <div className="currency-detail-pc-wrap">
          <div className="currency-detail-pc-header">
            <Image
              _hover={{ cursor: "pointer" }}
              boxSize="24px"
              src="../wwwsite/images/close-rect.svg"
              onClick={() => {
                onClose();
              }}
            />
          </div>

          <div className="currency-detail-pc-content">
            <Flex alignItems={"center"} mb={"20px"}>
              <Image boxSize="40px" src={data?.icon} />
              <Flex ml={"10px"} flexDirection={"column"}>
                <Text size={"20px"} color={"#000"} fontWeight={600}>
                  {data?.name}
                </Text>
                <Text size={"13px"} color={"#2A2B30"}>
                  {data?.fullname}
                </Text>
              </Flex>
            </Flex>
            <Text pb={"10px"} pt={"10px"} fontWeight={600} fontSize={"20px"}>
              {data?.description.title}
            </Text>
            <Text
              pb={"40px"}
              color={"#7B7C8F"}
              size={"12px"}
              dangerouslySetInnerHTML={{
                __html: data?.description?.content.replace(/\n/g, "<br>"),
              }}
            ></Text>
          </div>
        </div>
      )}
      {isMobile && (
        <ModalComponent
          isShow={true}
          className="currency-detail-m-modal"
          style={{ width: "320px", "overflow-y": "scroll" }}
        >
          <div className="currency-detail-m-wrap">
            <div className="currency-detail-pc-header">
              <Image
                _hover={{ cursor: "pointer" }}
                boxSize="24px"
                src="../wwwsite/images/close-rect.svg"
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div className="currency-detail-pc-content">
              <Flex alignItems={"center"} mb={"20px"}>
                <Image boxSize="40px" src={data?.icon} />
                <Flex ml={"10px"} flexDirection={"column"}>
                  <Text size={"20px"} color={"#000"} fontWeight={600}>
                    {data?.name}
                  </Text>
                  <Text size={"13px"} color={"#2A2B30"}>
                    {data?.fullname}
                  </Text>
                </Flex>
              </Flex>
              <Text pb={"10px"} pt={"10px"} fontWeight={600} fontSize={"20px"}>
                {data?.description.title}
              </Text>
              <Text
                pb={"40px"}
                color={"#7B7C8F"}
                size={"12px"}
                dangerouslySetInnerHTML={{
                  __html: data?.description?.content.replace(/\n/g, "<br>"),
                }}
              ></Text>
            </div>
          </div>
        </ModalComponent>
      )}
    </div>
  );
};

export default CurrencyDetail;
