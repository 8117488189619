import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  language: "",
  bgColor: "red",
  registerStep: 1,
  loginStep: 1,
  resetPwdValidation: {},
  currentCountry: {},
  kyc: {},
  registerMsg: {
    email: "",
    pwd: "",
    payPwd: "",
  },
  registerPhone: {
    phoneCode: "",
    phoneNumber: "",
  },
  loginMsg: {
    email: "",
    pwd: "",
  },
  userInfo: {},
  addBankInfo: {},
  userKycInfo: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateBgColor(state, { payload: { bgColor } }) {
      state.bgColor = bgColor;
    },
    updateLanguage(state, { payload: { language } }) {
      state.language = language;
    },
    updateRegisterStep(state, { payload: { registerStep } }) {
      state.registerStep = registerStep;
    },
    updateRegisterMsg(state, { payload: { registerMsg } }) {
      state.registerMsg = registerMsg;
    },
    updateRegisterPhone(state, { payload }) {
      state.registerPhone = payload;
    },
    updateUserInfo(state, { payload: { userInfo } }) {
      console.log("[[userInfo]]", userInfo);
      state.userInfo = userInfo;
    },
    updateLoginStep(state, { payload: { loginStep } }) {
      state.loginStep = loginStep;
    },
    updateLoginMsg(state, { payload: { loginMsg } }) {
      state.loginMsg = loginMsg;
    },
    updateResetPwdValidation(state, { payload: { resetPwdValidation } }) {
      state.resetPwdValidation = resetPwdValidation;
    },
    updateCurrentCountry(state, { payload: { currentCountry } }) {
      state.currentCountry = currentCountry;
    },
    updateKyc(state, { payload: { kyc } }) {
      state.kyc = kyc;
    },
    updateBankInfo(state, { payload: { bankInfo } }) {
      state.addBankInfo = bankInfo;
    },
    updateUserKycInfo(state, { payload: { info } }) {
      state.userKycInfo = info;
    },
  },
});

export const {
  updateLanguage,
  updateBgColor,
  updateRegisterStep,
  updateRegisterMsg,
  updateUserInfo,
  updateLoginStep,
  updateResetPwdValidation,
  updateLoginMsg,
  updateCurrentCountry,
  updateKyc,
  updateRegisterPhone,
  updateBankInfo,
  updateUserKycInfo,
} = userSlice.actions;

export default userSlice.reducer;
