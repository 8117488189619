import {
  Box,
  Text,
  Flex,
  Image,
  Center,
  Switch,
  Button,
} from "@chakra-ui/react";
import BXNSelect from "@/components/ui/WSelect";
import { getUserInfo, postUpdateUserInfo } from "./api";
import { useState, useEffect } from "react";
import { toastTip } from "@/components/ui/toast";
import { updateUserInfo } from "@/state/user/reducer";
import { useDispatch } from "react-redux";
import { useI18n } from "@/hooks/useI18n";
import ModalComponent from "@/components/ui/modal";
import { useMedia } from "@/hooks/useMedia";
const Setting = () => {
  const { t } = useI18n();
  const { isMobile } = useMedia();
  const [hasAgreeClose, setHasAgreeClose] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const list = [
    { label: "HKD", value: 1 },
    { label: "USD", value: 2 },
    { label: "CNY", value: 3 },
    { label: "JPY", value: 4 },
    { label: "KRW", value: 5 },
  ];
  const [userinfo, setUserInfo] = useState({});
  const dispatch = useDispatch();
  const [theme, setTheme] = useState("");
  const [upColor, setUpColor] = useState("");
  const [primary_currency, setPrimary_currency] = useState("HKD");

  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      setUserInfo(res.data);
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };
  const getSelectVal = async (val) => {
    setPrimary_currency(val.selectName);
    const res = await postUpdateUserInfo({ primary_currency: val.label });
    if (res.code === 0 && res.data.ok) {
      toastTip(t("MThZOMtyoBvwpuodWEa"));
      await getUserInfoFn();
    }
    localStorage.setItem(
      "bx-exchange-settings",
      JSON.stringify({
        primary_currency: val.label,
        theme,
        color: upColor,
      })
    );
  };
  const initSave = () => {
    const localData = JSON.parse(localStorage.getItem("bx-exchange-settings"));
    if (localData) {
      setUpColor(localData.color);
      setTheme(localData.theme);
    }
  };
  useEffect(() => {
    getUserInfoFn();
    initSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getThemes = (e) => {
    setTheme(e.target.value);
    localStorage.setItem(
      "bx-exchange-settings",
      JSON.stringify({
        primary_currency,
        theme: e.target.value,
        color: upColor,
      })
    );
  };
  const getUpColors = (e) => {
    console.log("e.target.value", e.target.value);
    setUpColor(e.target.value);
    localStorage.setItem(
      "bx-exchange-settings",
      JSON.stringify({
        primary_currency,
        theme,
        color: e.target.value,
      })
    );
  };

  const handleCloseOrder = async () => {
    const res = await postUpdateUserInfo({
      is_trade_push: userinfo.is_trade_push ? "false" : "true",
    });
    if (res.code === 0) {
      toastTip(t("MThZOMtyoBvwpuodWEa"));
      setShowModal(false);
      await getUserInfoFn();
    }
  };
  return (
    <Center>
      <Box w={["320px", "600px"]} mt={["40px", "80px"]} mb={"30px"}>
        <Text fontWeight="600" color="t.100" fontSize="24px" mb="53px">
          {t("zQsiOHqCsFIqBzNsf")}
        </Text>
        <Text mt="80px" fontSize="14px" mb={"5px"}>
          {t("lNGEpmcaWzCMRfZFypa")}
        </Text>
        {userinfo?.primary_currency && (
          <BXNSelect
            options={list}
            handleSelect={(item) => {
              getSelectVal(item);
            }}
            defaultOpt={
              list.filter((d) => d.label === userinfo.primary_currency)[0]
            }
          />
        )}
        <Box mt="50px">
          <Text mb="5px" fontSize={"14px"}>
            {t("pfVyzGgyXmkqtJpGH")}
          </Text>
          <label htmlFor="lightId">
            <Flex
              justifyContent={"space-between"}
              cursor={"pointer"}
              h="44px"
              borderRadius={"12px"}
              border="1px solid #E3E4F1"
              padding="0 20px"
            >
              <Text lineHeight="44px" fontSize={"14px"}>
                {t("KRzStRHbmbrDbAUMnU")}
              </Text>
              <input
                type="radio"
                id="lightId"
                name="themes"
                onChange={(e) => {
                  getThemes(e);
                }}
                checked={theme === "light"}
                value="light"
              />
            </Flex>
          </label>
          <label htmlFor="darkId">
            <Flex
              justifyContent={"space-between"}
              mt="5px"
              cursor={"pointer"}
              h="44px"
              borderRadius={"12px"}
              border="1px solid #E3E4F1"
              padding="0 20px"
            >
              <Text lineHeight="44px" fontSize={"14px"}>
                {t("lfLIpxDvOAXOplowzN")}
              </Text>
              <input
                type="radio"
                id="darkId"
                name="themes"
                value="dark"
                checked={theme === "dark"}
                onChange={(e) => {
                  getThemes(e);
                }}
              />
            </Flex>
          </label>
        </Box>
        <Box mt="50px">
          <Text mb="5px" fontSize={"14px"}>
            {t("BiqzWayxveHNZGxaBa")}
          </Text>
          <label htmlFor="greenId">
            <Flex
              justifyContent={"space-between"}
              h="44px"
              cursor={"pointer"}
              borderRadius={"12px"}
              border="1px solid #E3E4F1"
              padding="0 20px"
            >
              <Flex>
                <Text lineHeight="44px" fontSize={"14px"}>
                  {t("CvvaFnujrEUnFHnWNb")}
                </Text>
                <Image
                  src="../wwwsite/images/transfer/green_up.svg"
                  alt="arrow"
                  w="20px"
                  ml="10px"
                />
              </Flex>
              <input
                type="radio"
                id="greenId"
                name="colors"
                value="g"
                checked={upColor === "g"}
                onChange={getUpColors}
              />
            </Flex>
          </label>
          <label htmlFor="redId">
            <Flex
              justifyContent={"space-between"}
              mt="5px"
              cursor={"pointer"}
              h="44px"
              borderRadius={"12px"}
              border="1px solid #E3E4F1"
              padding="0 20px"
            >
              <Flex>
                <Text lineHeight="44px" fontSize={"14px"}>
                  {t("CNmFZyGboWXdpYPQDvxV")}
                </Text>
                <Image
                  alt="arrow"
                  w="20px"
                  ml="10px"
                  src="../wwwsite/images/transfer/red_up.svg"
                />
              </Flex>

              <input
                type="radio"
                id="redId"
                name="colors"
                value="r"
                checked={upColor === "r"}
                onChange={getUpColors}
              />
            </Flex>
          </label>
        </Box>

        <Box mt="50px">
          <Text mb="5px" fontSize={"14px"}>
            {t("ivAXIbjvUUQpQqpmXz")}
          </Text>
          <label htmlFor="openDealNotice">
            <Flex
              justifyContent={"space-between"}
              h="44px"
              cursor={"pointer"}
              borderRadius={"12px"}
              border="1px solid #E3E4F1"
              padding="0 20px"
              alignItems={"center"}
            >
              <Text lineHeight="44px" fontSize={"14px"}>
                {t("qltXLGplQJNtHWdY")}
              </Text>
              <Switch
                align="center"
                size="md"
                id="openDealNotice"
                isChecked={userinfo?.is_trade_push}
                onChange={() => {
                  if (userinfo?.is_trade_push) {
                    setShowModal(true);
                  } else {
                    handleCloseOrder();
                  }
                }}
              />
            </Flex>
          </label>
        </Box>
      </Box>

      <ModalComponent
        isShow={showModal}
        style={!isMobile && { width: "700px" }}
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          w={["100%", "700px"]}
        >
          <Box
            padding={isMobile ? "40px 30px 0px 30px" : "50px 30px 50px"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Image
              src="../wwwsite/images/close-warning.svg"
              width={"48px"}
              height={"48px"}
              textAlign={"center"}
            />
            <Text
              fontSize={isMobile ? "16px" : "24px"}
              color={"#3E3D4D"}
              mt={"20px"}
            >
              {t("vamuznzHAJqilfEEP")}
            </Text>
            <Text
              fontSize={isMobile ? "13px" : "16px"}
              mt={isMobile ? "20px" : "30px"}
              dangerouslySetInnerHTML={{
                __html: t("oGNuorHWcxXehehzCno"),
              }}
            ></Text>
            <Flex
              mt={isMobile ? "40px" : "30px"}
              alignItems={isMobile ? "flex-start" : "center"}
            >
              <Image
                onClick={() => setHasAgreeClose(!hasAgreeClose)}
                style={{ cursor: "pointer" }}
                mt={isMobile && "4px"}
                src={`../wwwsite/images/kyc/${
                  hasAgreeClose ? "checked.svg" : "no_check.svg"
                }`}
              />
              <Text
                ml={"5px"}
                fontWeight={500}
                fontSize={isMobile ? "13px" : "16px"}
                dangerouslySetInnerHTML={{
                  __html: t("pFjSwsuOrRtiguvbEc"),
                }}
              ></Text>
            </Flex>
          </Box>
          <Flex
            borderTop={isMobile ? "" : "1px solid rgba(139, 138, 153, 0.10)"}
            padding={isMobile ? "40px  30px" : "30px 50px"}
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <Button
              h="44px"
              bg="t.50"
              color="#fff"
              w="112px"
              _hover={{ bg: "t.50" }}
              fontSize="12px"
              borderRadius={"30px"}
              onClick={() => setShowModal(false)}
            >
              {t("UNForxvjDLCZdGrmWkX")}
            </Button>
            <Button
              ml={"30px"}
              h="44px"
              color="#fff"
              fontSize="12px"
              borderRadius={"30px"}
              w="164px"
              _hover={{ bg: hasAgreeClose ? "blue.80" : "t,50" }}
              cursor={hasAgreeClose ? "pointer" : "not-allowed"}
              bg={hasAgreeClose ? "blue.100" : "t.50"}
              onClick={() => {
                if (hasAgreeClose) {
                  handleCloseOrder();
                }
              }}
            >
              {t("AQrCocFqoKoYieUnT")}
            </Button>
          </Flex>
        </Flex>
      </ModalComponent>
    </Center>
  );
};

export default Setting;
