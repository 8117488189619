import PropTypes from "prop-types";
import { useState, useEffect, useMemo, useCallback } from "react";

import CommonUnitStyle from "@/pages/deposit/component/commonUnitStyle";
import CommonBtnDom from "@/pages/deposit/component/commonBtn";

import { getArrInTheObjectAboutKey } from "@/helpers/func/arraryPro";
import { Dec } from "@/helpers/func/decimalCal";
import { jsUuid } from "@/helpers/func/stringPro";
import {
  requestTrasnferCreate,
  getWhiteListAddress,
} from "@/pages/withdraw/api";
import { useI18n } from "@/hooks/useI18n";
import { Box, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "./index.scss";

import { WITHDRAWCHAIN } from "@/pages/withdraw/helpers/constants/pageTypeName";
import {
  MEMOFORMAT,
  PUBKEYFORMAT,
} from "@/pages/withdraw/helpers/constants/formatName";

const ChainWithDrawPage = (props) => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const [whiteAddressList, setWhiteAddressList] = useState([]);

  const {
    currency,
    address,
    networkList,
    currentNetwork,
    netAboutInfo: { min_amount, max_amount, format, required },
    amount,
    balance,
    fee,
    memo,
    pubkey,
    chainNumber,
    getPubkeyEvent,
    getChainNumberEvent,
    selectedNetEvent,
    updateAddressEvent,
    updateMountEvent,
    backEvent,
    showLoadingEvent,
    getPayId,
    getMemoEvent,
    withdrawLimit,
  } = props;

  const chainNumberList = [
    {
      name: "0",
      selected_key: "0",
    },
    {
      name: "1",
      selected_key: "1",
    },
    {
      name: "2",
      selected_key: "2",
    },
    {
      name: "3",
      selected_key: "3",
    },
    {
      name: "4",
      selected_key: "4",
    },
    {
      name: "5",
      selected_key: "5",
    },
    {
      name: "6",
      selected_key: "6",
    },
    {
      name: "7",
      selected_key: "7",
    },
    {
      name: "8",
      selected_key: "8",
    },
    {
      name: "9",
      selected_key: "9",
    },
  ];

  const WHITE_CURRENCY = ["BTC", "ETH", "USDC"];

  const [uuid, setUuid] = useState("");

  // 获取白名单
  const getWhiteList = async () => {
    const res = await getWhiteListAddress();
    if (res.code === 0) {
      const data =
        res.data.WhiteListAddressList.filter((d) => d.status === "SUCCESS") ||
        [];
      setWhiteAddressList(data);
    }
  };

  const formatSelectAddressList = useMemo(() => {
    return whiteAddressList
      .map((d) => {
        return {
          ...d,
          selected_key: d.address,
        };
      })
      .filter((d) => d.currency === currency && d.is_withdraw);
  }, [whiteAddressList, currency]);

  useEffect(() => {
    if (formatSelectAddressList.length > 0) {
      updateAddressEvent(formatSelectAddressList[0].address);
    }
  }, [formatSelectAddressList]);

  useEffect(() => {
    setUuid(jsUuid());
    getWhiteList();
  }, []);

  const clickBack = () => {
    backEvent(WITHDRAWCHAIN);
  };

  const getChainAddress = (getValue) => {
    updateAddressEvent(getValue);
  };

  const selectedNetwork = (getKey) => {
    selectedNetEvent({
      net: getKey,
      getCurrentNetInfo: getArrInTheObjectAboutKey(
        networkList,
        "selected_key",
        getKey
      ),
    });
  };

  const getMemo = (getValue) => {
    getMemoEvent(getValue);
  };

  const getPubkey = (getValue) => {
    getPubkeyEvent(getValue);
  };

  const selectedChainNumber = (getKey) => {
    getChainNumberEvent(getKey);
  };

  const getInputAmount = (getValue) => {
    updateMountEvent(getValue);
  };

  const clickAllbtn = () => {
    let allAmount = Dec(balance, fee || "0", "-");
    if (max_amount && Number(max_amount) < Number(allAmount)) {
      allAmount = max_amount;
    }
    updateMountEvent(allAmount);
  };

  const clickNext = async () => {
    if (canNext()) {
      showLoadingEvent(true);
      let body = {
        request_id: uuid,
        currency: currency,
        amount: amount,
        is_outside: true,
        address: address,
        fee_amount: fee,
        network: currentNetwork,
      };
      if (format === MEMOFORMAT) {
        body.address_extra = {
          memo: memo,
        };
      }
      if (format === PUBKEYFORMAT) {
        body.address_extra = {
          chain: chainNumber,
          pubkey: pubkey,
        };
      }
      const { code, data, msg } = await requestTrasnferCreate(body);
      if (code === 0 && msg === "OK") {
        const { pay_id } = data;
        getPayId(pay_id);
      } else {
        showLoadingEvent(false);
        setUuid(jsUuid());
      }
    }
  };

  const canNext = () => {
    if (required) {
      let newArr = [];
      required.forEach((item) => {
        newArr.push(Boolean(props[item]));
      });
      const isRequired = !newArr.includes(false);
      return Boolean(isRequired && amount && !amountInputError());
    } else {
      if (format === MEMOFORMAT) {
        return Boolean(
          address && currentNetwork && amount && !amountInputError()
        );
      } else {
        return Boolean(
          address && currentNetwork && amount && !amountInputError()
        );
      }
    }
  };
  const amountInputError = () => {
    let errorText = "";
    if (amount) {
      if (min_amount) {
        if (Number(amount) < Number(min_amount)) {
          errorText = `${t("iRiLJudagJVXZozmnz")} ${min_amount}`;
        }
      }
      if (max_amount) {
        if (Number(amount) > Number(max_amount)) {
          errorText = `${t("laRzOEpYMTvnZYRBZMnj")} ${max_amount}`;
        }
      }
      if (balance) {
        if (fee) {
          if (Number(fee) > Number(Dec(balance, amount, "-"))) {
            errorText = t("OKOogywrWeAjtQWF");
          }
        }
        if (Number(amount) > Number(balance)) {
          errorText = t("MOtoFwFvpTtTEDVUD");
        }
      }
    }
    return errorText;
  };

  const renderTipBox = useCallback(() => {
    if (
      formatSelectAddressList.length === 0 &&
      WHITE_CURRENCY.indexOf(currency) > -1
    ) {
      return (
        <Box
          padding={"10px"}
          width={"100%"}
          borderRadius={"12px"}
          backgroundColor={"#FCEDEF"}
          alignItems={"center"}
          display={"flex"}
          margin={"10px 0px"}
        >
          <Image
            src="../wwwsite/images/warning.svg"
            width={"16px"}
            height={"16px"}
          />
          <Text
            className="withdraw-tip-text"
            fontSize={"12px"}
            color={"#D04457"}
            ml={"10px"}
            onClick={(e) => {
              if (e.target.nodeName === "B") {
                navigate("/trade/whiteAddress");
              }
            }}
            dangerouslySetInnerHTML={{ __html: t("cuKtQbYgXOwLPEbN") }}
          ></Text>
        </Box>
      );
    }
    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatSelectAddressList, t, navigate, currency]);

  return (
    <>
      <CommonUnitStyle
        title={t("ZdMOAZGAMXotVGVlKJ")}
        showText={t("FNNzRxnZqrKRYwIte").replace("%S%", currency)}
        showTitleRightBtn={true}
        TitleRightBtnText={t("WbPTckCFMKtPHgAlL")}
        rightBtnEvent={clickBack}
      />
      {/* 链上地址 输入框*/}
      {WHITE_CURRENCY.indexOf(currency) < 0 && (
        <CommonUnitStyle
          title={t("SultIciVtbYeZaII")}
          showInput={true}
          textInputPlaceholder={t("rEpbPaWEEsiOSTjOiSGL")}
          inputValue={address}
          textInputOnchange={getChainAddress}
          renderTipBox={() => {
            return renderTipBox();
          }}
        />
      )}
      {/* 链上地址 选择框*/}
      {WHITE_CURRENCY.indexOf(currency) > -1 && (
        <CommonUnitStyle
          title={t("SultIciVtbYeZaII")}
          selectContent={formatSelectAddressList}
          selectedValue={address}
          selectedEvent={(value) => {
            updateAddressEvent(value);
          }}
          formatSelect={(item) => {
            return (
              <>
                <span>{item.name}_</span>
                <span>
                  {item.address.slice(0, 6) + "..." + item.address.slice(-6)}
                </span>
              </>
            );
          }}
          renderTipBox={() => {
            return renderTipBox();
          }}
        />
      )}

      <CommonUnitStyle
        title={t("dGRvusBQRnbHUIYQRit")}
        selectContent={networkList}
        selectPlaceholder={t("bJwUxepDjVzlNzKuI")}
        selectedValue={currentNetwork}
        selectedEvent={selectedNetwork}
      />
      {format === MEMOFORMAT && (
        <CommonUnitStyle
          title={t("nzLBdKgvNCXQTgYI")}
          showPasteInput={true}
          textInputPlaceholder="Enter the Memo tag"
          inputValue={memo}
          textInputOnchange={getMemo}
        />
      )}
      {format === PUBKEYFORMAT && (
        <>
          <CommonUnitStyle
            title="Pubkey"
            showPasteInput={true}
            textInputPlaceholder={t("SWVaRdViGfCBJhJNg")}
            inputValue={pubkey}
            textInputOnchange={getPubkey}
          />
          <CommonUnitStyle
            title={t("lrCgiXeJcIwkuDsyc")}
            selectContent={chainNumberList}
            selectedValue={chainNumber}
            selectedEvent={selectedChainNumber}
          />
        </>
      )}
      <CommonUnitStyle
        title={t("HlrghIZaaySLZjESwU")}
        leftLineBg="#FFFFFF"
        showNumberInput={true}
        numberTopDesc={`${balance} ${currency}`}
        numberBottomDesc={fee ? `${fee} ${currency}` : ""}
        numberInputPlaceholder={t("TCuuXTtXBYeAldQkZp").replace(
          "%S%",
          min_amount || ""
        )}
        inputValue={amount}
        inputContentUnit={currency}
        numberInputOnchange={getInputAmount}
        numberErrortext={amountInputError()}
        fillInAll={clickAllbtn}
        withdrawLimit={withdrawLimit}
      />
      <CommonBtnDom
        btnText={t("tOaRBVUJHpnSgDBrGON")}
        noCanClick={!canNext()}
        marginTop="53px"
        clickEvent={clickNext}
      />
    </>
  );
};

ChainWithDrawPage.propTypes = {
  currency: PropTypes.string,
  address: PropTypes.string,
  networkList: PropTypes.array,
  currentNetwork: PropTypes.string,
  netAboutInfo: PropTypes.object,
  amount: PropTypes.string,
  balance: PropTypes.string,
  fee: PropTypes.string,
  memo: PropTypes.string,
  pubkey: PropTypes.string,
  chainNumber: PropTypes.string,
  selectedNetEvent: PropTypes.func,
  updateAddressEvent: PropTypes.func,
  updateMountEvent: PropTypes.func,
  showLoadingEvent: PropTypes.func,
  getPayId: PropTypes.func,
  getMemoEvent: PropTypes.func,
  getPubkeyEvent: PropTypes.func,
  getChainNumberEvent: PropTypes.func,
};

export default ChainWithDrawPage;
