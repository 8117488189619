import { createSlice } from "@reduxjs/toolkit";
import { EMAIL } from '@/pages/withdraw/helpers/constants/accountTypeName';

export const initialState = {
    verifyStep: [],
    transfer: {
        inner: {
            accontInfo: {},
            currentAccountType: EMAIL
        }
    }
};

const paySlice = createSlice({
    name: "pay",
    initialState,
    reducers: {
        updateVerifyStep(state, { payload: { verifyStep } }) {
            state.verifyStep = verifyStep;
        },
        updateSelectedAccountInfo(state, { payload: { accontInfo } }) {
            state.transfer.inner.accontInfo = accontInfo;
        },
        updateSelectedCurrentAccountType(state, { payload: { currentAccountType } }) {
            state.transfer.inner.currentAccountType = currentAccountType;
        },
    },
});

export const {
    updateVerifyStep,
    updateSelectedAccountInfo,
    updateSelectedCurrentAccountType,
} = paySlice.actions;

export default paySlice.reducer;