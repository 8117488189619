import BXNSelect from "@/components/ui/WSelect";
import { useI18n } from "@/hooks/useI18n";
import { useEffect, useMemo, useState } from "react";
import { getS3PutUrl, getWhiteListVaspList, verifyTxCreate } from "./api";
import { Box, Flex, Text, Button, Input, Image } from "@chakra-ui/react";
import axios from "axios";
import PageWrap from "@/components/PageWrap";
import { useMedia } from "@/hooks/useMedia";
import { useSearchParams, useNavigate } from "react-router-dom";
import { BIXIN_WALLET_BILL } from "@/helpers/constants/routeName";
import { S3_AWS_KMS_KEY_ID } from "@/config";

const VerifyTxAddress = () => {
  const { t } = useI18n();
  const { isMobile } = useMedia();
  const [searchParams] = useSearchParams();
  const [checked, setChecked] = useState(false);
  const [whitelistVaspDid, setWhitelistVaspDid] = useState("");
  const [txHashValue, setTxHashValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [vaspList, setVaspList] = useState([]);
  const _address = searchParams.get("address");
  const navigate = useNavigate();

  const [firstFileInfo, setFirstFileInfo] = useState({
    name: "",
    fullName: "",
    error: false,
  });

  const [secondFileInfo, setSecondFileInfo] = useState({
    name: "",
    fullName: "",
    error: false,
  });

  useEffect(() => {
    if (_address) {
      setTxHashValue(_address);
      setDisabled(true);
    }
  }, [_address]);

  useEffect(() => {
    getWhiteListVaspList().then((res) => {
      if (res?.data?.vasp_list) {
        setVaspList(res.data.vasp_list);
        setWhitelistVaspDid(res.data.vasp_list[0]?.did);
      }
    });
  }, []);

  // 上传
  const verifyEles = useMemo(() => {
    return [
      {
        text: t("sMvNZNODlnAIiuzFMR"),
        error: false,
        errorText: t("RrEKjSeFQrtvLrZIO"),
      },
      {
        text: t("LiwpXDoMAXANnTRZ"),
        error: false,
        errorText: t("RrEKjSeFQrtvLrZIO"),
      },
    ];
  }, [t]);

  // 能否提交
  const canSubmit = useMemo(() => {
    if (
      whitelistVaspDid &&
      firstFileInfo.name &&
      secondFileInfo.name &&
      checked &&
      txHashValue
    ) {
      return true;
    }
    return false;
  }, [firstFileInfo, secondFileInfo, checked, txHashValue, whitelistVaspDid]);

  // 上传
  const hanleFileUpload = async (e, i) => {
    const file = e.target.files[0];
    const validTypes = ["jpg", "png", "pdf"];
    if (file) {
      const { size, name } = file;
      const fileNameSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (validTypes.indexOf(fileNameSuffix) < 0) {
        if (i === 0) {
          setFirstFileInfo((prev) => {
            console.log("prev", prev);
            return Object.assign({}, prev, { error: true });
          });
        } else {
          setSecondFileInfo((prev) => {
            return Object.assign({}, prev, { error: true });
          });
        }
        return;
      }
      if (size / 1000000 < 5) {
        const res = await getS3PutUrl({
          prefix: "whitelist",
          file_type: fileNameSuffix,
        });
        if (res.code === 0) {
          const putUrl = res?.data?.put_url;
          const filePath = res?.data?.file_path;
          console.log(" process.env", process.env);
          axios({
            url: putUrl,
            method: "put",
            headers: {
              "X-Amz-Server-Side-Encryption": "aws:kms",
              "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
            },
            data: file,
          }).then((uploadRes) => {
            if (uploadRes.status === 200) {
              if (i === 0) {
                setFirstFileInfo({
                  name: filePath,
                  fullName: name,
                  errors: false,
                });
              } else if (i === 1) {
                setSecondFileInfo({
                  name: filePath,
                  fullName: name,
                  errors: false,
                });
              }
            }
          });
        }
        // setFileType(fileName);
        // setValidFile(true);
        // uploadFn(file);
      } else {
        // setValidFile(false);
        // setFilePath("error");
      }
      // setFileName(name);
    }
  };

  const submit = async () => {
    if (!canSubmit) {
      return false;
    }
    const params = {
      tx_id: txHashValue,
      vasp_id: whitelistVaspDid,
      file1: firstFileInfo.name,
      file2: secondFileInfo.name,
      agreement: true,
    };

    const res = await verifyTxCreate(params);
    if (res.code === 0) {
      navigate(BIXIN_WALLET_BILL);
    }
  };

  const renderCexItem = (cexobj) => {
    return (
      <Flex alignItems={"center"}>
        <Image
          src={cexobj.logo}
          width={"20px"}
          height={"20px"}
          mr={"10px"}
          borderRadius={"5px"}
        />
        <Text fontSize="14px">{cexobj.name}</Text>
      </Flex>
    );
  };

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        pt={!isMobile ? "30px" : "0"}
      >
        <Flex
          alignItems={"center"}
          flexDirection={"column"}
          w={["90%", "500px"]}
          justifyContent={"flex-start"}
          margin={"0 auto"}
        >
          <Text fontSize={"24px"} fontWeight={600}>
            {t("BbqJaDHRCDmiNwNVcGB")}
          </Text>
          {/* 交易所名称 */}
          <Box mt={"30px"} width={"100%"}>
            <Text fontSize="14px">{t("mDlqsQlmoApWZXHKLq")}</Text>
            <BXNSelect
              style={{ marginTop: "10px" }}
              options={vaspList}
              defaultOpt={vaspList[0]}
              handleSelect={({ did }) => {
                setWhitelistVaspDid(did);
              }}
              renderLabel={(item) => renderCexItem(item)}
              renderItem={(item) => renderCexItem(item)}
            />
          </Box>
          {/* txhash  */}
          <Box mt={"30px"} width={"100%"}>
            <Text color={"#010313"} fontSize={"14px"}>
              {t("elXgEtyDNXczWYbDHV")}
            </Text>
            <Input
              _placeholder={{ fontSize: "12px" }}
              fontSize={"14px"}
              borderRadius={"12px"}
              padding={"0px 20px"}
              placeholder={t("sBFILAqpJhAnTLlpQ")}
              mt={"10px"}
              value={txHashValue}
              onChange={(e) => {
                setTxHashValue(e.target.value);
              }}
              disabled={disabled}
            />
          </Box>

          {/* 上传交易所账户所有权证明 */}
          <Flex w={"100%"} flexDirection={"column"} mt={"30px"}>
            <Text size={"16px"} fontWeight={"bold"}>
              {t("gapTtWntRtJcQzRtBz")}
            </Text>
            {verifyEles.map((d, i) => {
              return (
                <Box position="relative" key={i} mt={i === 1 && "30px"}>
                  <Text
                    fontSize={"14px"}
                    color={"#010313"}
                    mt={i === 0 && "10px"}
                  >
                    {d.text}
                  </Text>
                  <Flex justifyContent={"space-between"} mt="10px">
                    <Input
                      w="80px"
                      type="file"
                      opacity={"0"}
                      position={"absolute"}
                      right="0"
                      mt={"20px"}
                      zIndex={"2"}
                      cursor="pointer"
                      fontSize="0"
                      onChange={(e) => {
                        hanleFileUpload(e, i);
                      }}
                    />
                    <Input
                      w={["220px", "410px"]}
                      disabled
                      borderRadius={"12px"}
                      _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
                      value={
                        i === 0
                          ? firstFileInfo.fullName
                          : secondFileInfo.fullName
                      }
                    />
                    <Button
                      w="80px"
                      // onClick={() => submit()}
                      h="30px"
                      fontSize="12px"
                      padding={"4px 10px"}
                      color="#fff"
                      borderRadius={"20px"}
                      bg="blue.100"
                      textAlign={"center"}
                      _hover={{ bg: "blue.80" }}
                      mt="5px"
                    >
                      {t("NqgOQEXQaHsramCnbX")}
                    </Button>
                  </Flex>
                  <Text
                    fontSize={"12px"}
                    color={
                      (i === 0 && firstFileInfo.error) ||
                      (i === 1 && secondFileInfo.error)
                        ? "red.100"
                        : "t.80"
                    }
                    mt={"5px"}
                  >
                    {t("RrEKjSeFQrtvLrZIO")}
                  </Text>
                </Box>
              );
            })}
          </Flex>

          {/* 责任声明 */}
          <Flex mt={"50px"} alignItems={"flex-start"}>
            <Image
              mt={"3px"}
              cursor={"pointer"}
              onClick={() => {
                setChecked(!checked);
              }}
              src={
                checked
                  ? "../../wwwsite/images/kyc/checked.svg"
                  : "../../wwwsite/images/kyc/no_check.svg"
              }
            />
            <Text fontSize={"12px"} color={"t.100"} fontWeight={400} ml={"5px"}>
              {t("qZljkUmjmSBKpnUsYfp")}
            </Text>
          </Flex>
          {/* 底部按钮 */}
          <Flex justifyContent={"center"} mt="40px" mb={"50px"}>
            <Button
              mr="10px"
              fontSize="14px"
              padding="0 30px"
              w={["100%", "100px"]}
              bg="rgba(32, 26, 85, 0.05)"
              color="t.100"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={"pointer"}
              onClick={() => navigate(-1)}
            >
              {t("FrzWGSiDSDzTfNgZH")}
            </Button>
            <Button
              fontSize="14px"
              padding="0 30px"
              w={["100%", "200px"]}
              color="#fff"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={"pointer"}
              bg={canSubmit ? "blue.100" : "t.50"}
              _hover={{ bg: canSubmit ? "blue.80" : "t.50" }}
              onClick={() => {
                submit();
              }}
            >
              {t("YChcfaxDaLscdSPiRQz")}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </PageWrap>
  );
};

export default VerifyTxAddress;
