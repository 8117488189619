import { useNavigate } from "react-router-dom";
import { useI18n } from "@/hooks/useI18n";
import {
  Box,
  Center,
  Text,
  Image,
  ListItem,
  UnorderedList,
  Flex,
  Input,
  Button,
} from "@chakra-ui/react";
import copyToClipboard from "copy-to-clipboard";
import BXLoading from "@/components/ui/pay-loading";
import { toastTip } from "@/components/ui/toast";
import { useBankVerifyHooks } from "./hooks";
import { useAppSelector } from "@/state/hooks";

const BankTransfer = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const {
    onSubmit,
    handleFile,
    viewStatus,
    uploadLoading,
    fileName,
    filePath,
    errorType,
  } = useBankVerifyHooks();
  const userKycInfo = useAppSelector((state) => state.user.userKycInfo);
  const _lang = useAppSelector((state) => state.user.language);

  const bankInfo = [
    {
      label: "PlIgEPTbgrrwkSuHc",
      value: "ZA Bank Limited",
      id: 1,
      fontWeight: 600,
    },
    {
      label: "NqhjvGQWWHnmAzDH",
      value: "NewBX Treasures Limited",
      id: 2,
      fontWeight: 600,
    },
    {
      label: "QNEAoNoQfTpYIxJvjdM",
      value: "868016175887",
      id: 3,
      fontWeight: 600,
    },
    {
      label: "MhWCMBfnPEmyPhdBJC",
      value: "387",
      id: 4,
      fontWeight: 600,
    },
    {
      label: "MmdyZCvMXusVhfjsV",
      value: "747",
      id: 5,
      fontWeight: 600,
    },
    {
      label: "rcbvwVBVHsJazGKrQv",
      value: "AABLHKHH",
      id: 6,
      fontWeight: 600,
    },
    {
      label: "HsYTxsCouoyySItw",
      value: "Core F, Cyberport 3, 100 Cyberport Road, Hong Kong",
      id: 7,
      fontWeight: 400,
    },
  ];

  const clickCopy = (item) => {
    copyToClipboard(item.value);
    toastTip(t("gXbirwakdsaUKBglwK"), { type: "success" });
  };

  const renderInitView = () => {
    return (
      <Box mt={"40px"}>
        <Flex flexDirection={"column"} mt={"40px"}>
          <Text fontSize={"16px"} color={"#000"} fontWeight={500}>
            {t("CNXhqxsuJtAEOpHCSI")}
          </Text>
          <Text fontSize={"14px"} color={"#010313"} mt={"5px"} fontWeight={400}>
            {t("iQYmhTkvoynVhdKfkY")}
          </Text>
          <Box position="relative">
            <Flex justifyContent={"space-between"} mt="20px">
              <Input
                w="80px"
                type="file"
                opacity={"0"}
                position={"absolute"}
                right="0"
                zIndex={"2"}
                cursor="pointer"
                fontSize="0"
                onChange={(e) => handleFile(e)}
              />
              <Input
                w={["220px", "310px"]}
                disabled
                borderRadius={"12px"}
                _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
                value={fileName}
              />
              <Button
                w="80px"
                h="30px"
                fontSize="12px"
                color="#fff"
                borderRadius={"20px"}
                bg="blue.100"
                textAlign={"center"}
                _hover={{ bg: "blue.80" }}
                mt="5px"
              >
                {t("NqgOQEXQaHsramCnbX")}
              </Button>
            </Flex>
            <Text
              fontSize="12px"
              color={filePath === "error" ? "red.100" : "t.80"}
              mt="10px"
            >
              {t("ZAhuzRDlLUTXhKrhhx")}
            </Text>
          </Box>
        </Flex>
        <Text color={"#D04457"} mt="20px" textAlign={"center"}>
          {errorType === "empty_error" ? t("wZZutIhpaOoppafvh") : ""}
        </Text>
        {/* 底部按钮 */}
        <Flex justifyContent={"center"} mt="40px" mb={"40px"}>
          <Button
            mr="10px"
            fontSize="14px"
            padding="0 30px"
            w={["30%", "100px"]}
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("FrzWGSiDSDzTfNgZH")}
          </Button>
          <Button
            fontSize="14px"
            padding="0 30px"
            w={["60%", "200px"]}
            bg={"blue.100"}
            _hover={{ bg: "blue.80" }}
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              onSubmit();
            }}
          >
            {t("QFbYEdxUgXzRwcPFQDis")}
          </Button>
        </Flex>
      </Box>
    );
  };

  const renderPendingView = () => {
    return (
      <Box
        w={["100%", "400px"]}
        margin={"0 auto"}
        mt={"60px"}
        borderRadius={"12px"}
        textAlign={"center"}
        fontWeight={"bold"}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src={`../../wwwsite/images/kyc/certificate/pending.svg`}
            width={"40px"}
            height={"40px"}
            alt=""
          />
          <Text
            fontSize={"24px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {t("KzFzOytWXScqDRKFwg")}
          </Text>
          <Text
            fontSize={"14px"}
            fontWeight={400}
            color={"#010313"}
            mt={"10px"}
          >
            {t("YXrqAGUqprWfOCeiSGX")}
          </Text>
        </Flex>
        <Button
          mt="40px"
          fontSize="14px"
          padding="0 30px"
          w="100px"
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => navigate("/trade/personKyc/certificate")}
        >
          {t("FrzWGSiDSDzTfNgZH")}
        </Button>
      </Box>
    );
  };

  return (
    <Box w={"100%"} margin="0 auto">
      {viewStatus === "INIT" && (
        <>
          <Center alignItems={"flex-start"} justifyContent={"center"}>
            <Image mt={"5px"} src="../../wwwsite/images/kyc/verify.svg" />
            <Text maxWidth={"260px"} fontSize={32} fontWeight={700} ml={"10px"}>
              {t("LJPMiQOhqtJqlBRBCHX")}
            </Text>
          </Center>
          <Box
            fontSize={12}
            fontWeight={400}
            mt={"40px"}
            bg="#FEF5EE"
            p={"15px"}
            borderRadius={"12px"}
            border={"0.5px solid #F89753"}
          >
            <Text
              dangerouslySetInnerHTML={{
                __html: t("dQRnMUSMOEdAwdfMqW").replace(
                  "$s $s",
                  `<b style="color: #333BF5">${userKycInfo.last_name} ${userKycInfo.first_name}</b>`
                ),
              }}
            />
            <Text
              mt={"20px"}
              color="#333BF5"
              cursor={"pointer"}
              onClick={() => {
                if (_lang === "en") {
                  window.open(
                    "https://www.sfc.hk/en/Rules-and-standards/Account-opening/Acceptable-account-opening-approaches",
                    "_target"
                  );
                } else if (_lang === "zh") {
                  window.open(
                    "https://sc.sfc.hk/TuniS/www.sfc.hk/TC/Rules-and-standards/Account-opening/Acceptable-account-opening-approaches",
                    "_target"
                  );
                } else {
                  window.open(
                    "https://www.sfc.hk/TC/Rules-and-standards/Account-opening/Acceptable-account-opening-approaches",
                    "_target"
                  );
                }
              }}
            >
              {t("xxgsgyEjhfwrMflwMr")}
            </Text>
          </Box>
          <Box
            fontSize={12}
            fontWeight={400}
            mt={"15px"}
            p={"15px"}
            borderRadius={"12px"}
            border={"1px solid #E3E4F1"}
          >
            <Text fontWeight={500} color="#010313">
              {t("yDGXBsHUIoIkgqfepe")}
            </Text>
            <UnorderedList
              mt={"10px"}
              gap={"10px"}
              display={"flex"}
              flexDirection={"column"}
            >
              <ListItem
                style={{ listStyle: "unset" }}
                color="#010313"
                fontWeight={400}
              >
                {t("lsdjJQPYPEOcAzVOth")}
              </ListItem>
              <ListItem
                style={{ listStyle: "unset" }}
                color="#010313"
                fontWeight={400}
              >
                {t("rhXOjsQATKgJNizniW")}
              </ListItem>
              <ListItem
                style={{ listStyle: "unset" }}
                color="#010313"
                fontWeight={400}
              >
                {t("pKuaKUWJHuajbExMUs")}
              </ListItem>
              <ListItem
                style={{ listStyle: "unset" }}
                color="#010313"
                fontWeight={400}
              >
                {t("nkcDnQvbZpDfYMepAc")}
              </ListItem>
            </UnorderedList>
            <Flex mt={"20px"} alignItems={"center"}>
              <Image
                src="../../wwwsite/images/kyc/bank_warning.svg"
                width={"20px"}
                height={"20px"}
                style={{ marginRight: "10px" }}
              />
              <Text color={"#010313"}>
                <span>{t("tglvvZIzjMuxIoXEYs")}</span>
                <span
                  color="#333BF5"
                  style={{ cursor: "pointer", color: "#333BF5" }}
                  onClick={() => {
                    if (_lang === "en") {
                      window.open(
                        "https://support.bixin.com/hc/en-hk/articles/29750863955219-Supported-Countries-Regions-for-Bank-Transfers",
                        "_target"
                      );
                    } else {
                      window.open(
                        "https://support.bixin.com/hc/zh-hk/articles/28728626132115-%E6%94%AF%E6%8C%81%E9%8A%80%E8%A1%8C%E8%BD%89%E8%B3%AC%E7%9A%84%E5%9C%8B%E5%AE%B6-%E5%9C%B0%E5%8D%80",
                        "_target"
                      );
                    }
                  }}
                >
                  &nbsp;{t("VXwjemLYMJiNKvXlZ")}
                </span>
              </Text>
            </Flex>
          </Box>
          <Box
            mt={"15px"}
            bg="#F5F5F7"
            p={"15px"}
            borderRadius={"12px"}
            color="#010313"
          >
            {bankInfo.map((cur) => {
              return (
                <Flex
                  alignItems={cur.id === 7 ? "flex-start" : "center"}
                  justifyContent={"space-between"}
                  key={cur.id}
                  p={"10px 5px"}
                >
                  <Text maxWidth={"108px"} fontSize={14} fontWeight={400}>
                    {t(cur.label)}
                  </Text>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                  >
                    <Text
                      maxWidth={"205px"}
                      textAlign={"right"}
                      fontWeight={cur.fontWeight}
                    >
                      {cur.value}
                    </Text>
                    <Image
                      cursor={"pointer"}
                      src="../../wwwsite/images/copy.svg"
                      alt={cur.value}
                      onClick={() => clickCopy(cur)}
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Box>
        </>
      )}
      {viewStatus === "INIT" && renderInitView()}
      {viewStatus === "PENDING" && renderPendingView()}
      {uploadLoading && <BXLoading />}
    </Box>
  );
};

export default BankTransfer;
