import { Box, Tooltip } from "@chakra-ui/react";
const TableUnit = (props) => {
  const {
    noHover,
    TableStyle,
    tableContentArr,
    py,
    keyId,
    clickEvent,
    noShowborder,
  } = props;
  return (
    <Box
      display={"flex"}
      justifyContent="space-between"
      alignItems={"center"}
      _hover={
        !noHover && {
          backgroundColor: "rgba(32, 26, 85, 0.05)",
        }
      }
      px={"30px"}
      {...TableStyle}
      onClick={() => {
        if (clickEvent) clickEvent();
      }}
      borderBottomWidth={!noShowborder && "1px"}
      borderBottomColor={"#E3E4F1"}
    >
      {tableContentArr.map((item, index) => {
        return (
          <Box
            key={`${keyId}${index}`}
            py={py || "20px"}
            cursor={"pointer"}
            {...item.style}
          >
            {item.name}{" "}
            {item.desc && (
              <Box
                display={"inline-block"}
                w={"20px"}
                h="20px"
                textAlign="center"
                lineHeight="17px"
                borderRadius={"6px"}
                _hover={{ bg: "rgba(130, 129, 140, 0.08)" }}
              >
                <Tooltip
                  label={item.desc}
                  placement="top-start"
                  hasArrow
                  // arrowPadding={20}
                  borderRadius={"8px"}
                  maxW={"200px"}
                  // w={"200px"}
                  padding={"8px 10px"}
                  // lineHeight={"35px"}
                  // h="39px"
                >
                  {item.icon}
                </Tooltip>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default TableUnit;
