import request from "@/hooks/useRequest";
import {
  POSTLOGIN,
  POSTEMAILCODEGET,
  POSTUPDATEUSERINFO,
  POSTEMAILCODEVALIDATION,
  POSTEMAILCHECK,
  GETUSERINFO,
  POSTLOGINPASSWORDRESET,
} from "@/helpers/constants/requestApi";

//登录
export const postLoginFunc = (body) => {
  return request(POSTLOGIN, { body });
};

//发送邮件
export const postEmailCodeGetFunc = (body) => {
  return request(POSTEMAILCODEGET, { body });
};

//验证邮件
export const postEmailCodeEvalidationFunc = (body) => {
  return request(POSTEMAILCODEVALIDATION, { body });
};

//邮箱验证
export const postEmailCheckFunc = (body) => {
  return request(POSTEMAILCHECK, { body });
};

//获取用户信息
export const getUserInfo = (params) => {
  return request(GETUSERINFO, { params });
};
//重置登录密码
export const postResetPwd = (body) => {
  return request(POSTLOGINPASSWORDRESET, { body });
};

//修改用户信息
export const postUpdateUserInfo = (body) => {
  return request(POSTUPDATEUSERINFO, { body });
};

// 邮箱登录
export const postEmailLogin = (body) => {
  return request(`/web-wallet/api/v1/login/check`, { body });
};

// 检查手机号是否存在
// export const checkPhone = (body) => {
//   return request("/web-wallet/api/v1/phone/check", { body });
// };

// 发送手机验证码
export const sendPhoneCodeApi = (body) => {
  return request("/web-wallet/api/v1/phone/send-code", { body });
};

// 验证手机号验证码
export const verifyPhoneCode = (body) => {
  return request("/web-wallet/api/v1/phone/auth/validation", {
    body,
  });
};

// 检查邮箱是否存在
export const verifyEmailExistedApi = (body) => {
  return request("/web-wallet/api/v1/email/check", {
    body,
  });
};
