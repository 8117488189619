import { GETIP } from "@/helpers/constants/requestApi";
import request from "@/hooks/useRequest";

const useVPNCheck = () => {
  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

  const findIP = (onNewIP) => {
    var myPeerConnection =
      window.RTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.webkitRTCPeerConnection;
    var pc = new myPeerConnection({
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      }),
      noop = function () {},
      localIPs = {},
      ipRegex =
        /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
      key;

    function ipIterate(ip) {
      if (!localIPs[ip]) return onNewIP(ip);
      localIPs[ip] = true;
    }

    pc.createDataChannel("");

    pc.createOffer(function (sdp) {
      sdp.sdp.split("\n").forEach(function (line) {
        if (line.indexOf("candidate") < 0) return;
        line.match(ipRegex).forEach(ipIterate);
      });
      pc.setLocalDescription(sdp, noop, noop);
    }, noop);

    pc.onicecandidate = function (ice) {
      if (
        !ice ||
        !ice.candidate ||
        !ice.candidate.candidate ||
        !ice.candidate.candidate.match(ipRegex)
      )
        return;
      ice.candidate.candidate.match(ipRegex).forEach(ipIterate);
    };
  };

  const checkVPN = () => {
    return new Promise(async (resolve, reject) => {
      let list = [];
      let site_ip = "";
      // [xx.xxxx.xxx.xx, 0.0.0.0]
      await findIP((ip) => {
        list.push(ip);
      });
      const res = await request(GETIP, {});
      if (res.code === 0) {
        site_ip = res?.data?.ip;
      }

      // 等待5s为了保证list数据全
      await sleep(1500);

      if (list.length === 0) {
        return resolve(false);
      } else {
        if (list.indexOf(site_ip) > -1) {
          return resolve(false);
        } else if (list.indexOf(site_ip) < 0) {
          return resolve(true);
        }
      }

      // if (
      //   (list.length > 0 && list.indexOf(site_ip) > -1) ||
      //   list.length === 0
      // ) {
      //   resolve(false);
      // } else {
      //   resolve(true);
      // }
      // resolve(false);
    });
  };

  return {
    checkVPN,
  };
};

export default useVPNCheck;
