import { Box, Flex, Text, Button, useClipboard } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { toastTip } from "@/components/ui/toast";
import { postCreateGaSecret } from "./api";
import { useI18n } from "@/hooks/useI18n";
import QRCode from "qrcode.react";

const GaStepTwo = (props) => {
  const { t } = useI18n();
  const { getStep } = props;
  const { onCopy, setValue } = useClipboard("");
  const [qrCode, setQrCode] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [ga, setGa] = useState("");
  const createGa = async () => {
    const res = await postCreateGaSecret({});
    if (res.code === 0) {
      setGa(res.data.secret);
      setValue(res.data.secret);
      setQrCode(res?.data?.qr_string);
    }
  };
  useEffect(() => {
    createGa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Flex justifyContent={"center"} mt="200px">
        <Box w={["80%", "400px"]}>
          <Flex justifyContent={"center"}>
            <img src="../wwwsite/images/GoogleAuth.svg" alt="ga" />
            <Text fontSize="32px" fontWeight={"600"} color="t.100" ml="10px">
              {t("nSdDtaHzknwZAXJHzrGP")}
            </Text>
          </Flex>
          <Text
            mt="100px"
            fontSize="16px"
            color="t.100"
            mb="10px"
            fontWeight={"500"}
          >
            {t("HfjMmDJQuBZXqFJUw")}
          </Text>
          <Text fontSize="18px" color="t.80" mb="10px" fontWeight={"500"}>
            {t("SVTrmHtEOHifBpgPNCY")}
          </Text>
          {/* <Text
            cursor={"pointer"}
            color="blue.100"
            fontSize="16px"
            fontWeight={"500"}
          >
            {t("LmRHxJOIEHRnUwFSxEm")}
          </Text> */}
          <Box
            w={["80%", "400px"]}
            h="47px"
            bg="rgba(32, 26, 85, 0.05)"
            borderRadius={"12px"}
            fontSize="12px"
            color="t.100"
            padding="0 10px"
            mt="5px"
            lineHeight={"47px"}
          >
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>{ga}</Text>
              <Box
                cursor={"pointer"}
                onClick={() => {
                  onCopy();
                  toastTip(t("gXbirwakdsaUKBglwK"));
                }}
              >
                <img src="../wwwsite/images/copy.svg" alt="copy" />
              </Box>
            </Flex>
          </Box>
          <Text
            mt="50px"
            fontSize="16px"
            color="t.100"
            mb="10px"
            fontWeight={"500"}
          >
            {t("gbkxEEkMTXuhYuUCZTJ")}
          </Text>
          <Text
            mt="10px"
            fontSize="18px"
            color="#7B7C8F"
            mb="10px"
            fontWeight={"500"}
          >
            {t("YLJxFxxsgqpWcZWy")}
          </Text>
          <Box
            w="200px"
            h="200px"
            border="1px"
            borderColor="#000"
            p="10px"
            borderRadius="12px"
            mx={"auto"}
            mt={"20px"}
          >
            <QRCode value={qrCode} size={180} id="qrCode" />
          </Box>
          <Flex justifyContent={"center"} mt="100px">
            <Button
              ml="10px"
              fontSize="14px"
              padding="0 30px"
              w="200px"
              bg="blue.100"
              color="#fff"
              fontWeight={"400"}
              borderRadius={"30px"}
              _hover={{ bg: "blue.80" }}
              h="44px"
              cursor={"pointer"}
              onClick={() => {
                getStep(3);
              }}
            >
              {t("tOaRBVUJHpnSgDBrGON")}
            </Button>
            <Button
              ml="10px"
              fontSize="14px"
              padding="0 30px"
              w="100px"
              bg="rgba(32, 26, 85, 0.05)"
              color="t.100"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={"pointer"}
              onClick={() => {
                getStep(1);
              }}
            >
              {t("WbPTckCFMKtPHgAlL")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
};

export default GaStepTwo;
