import { useMemo } from "react";
import { Flex, Box, Text, Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useI18n } from "@/hooks/useI18n";
import { useInvestmentRiskHooks } from "./hooks";
import "./assets.scss";
import Progress from "./Progress";

const InvestmentRisk = ({ setStep }) => {
  const navigate = useNavigate();
  const { t } = useI18n();

  const {
    isDisabled,
    dataSource,
    handleSelect,
    onSubmit,
    viewStatus,
    resultInfo,
    levelMaps,
    reTry,
  } = useInvestmentRiskHooks();

  const MemeQues = useMemo(() => {
    if (dataSource.questions?.length > 0) {
      return dataSource.questions.map((d, i) => {
        return (
          <Box key={i}>
            <Text
              fontSize={"14px"}
              fontWeight={400}
              color={"#010313"}
              mt={"30px"}
            >
              {d.content}
            </Text>
            {d.options.map((dd, ii) => {
              return (
                <Flex key={ii} alignItems={"flex-start"} mt={"10px"}>
                  <Image
                    width={"18px"}
                    height={"18px"}
                    onClick={() => handleSelect(i, ii)}
                    cursor={"pointer"}
                    src={`../../wwwsite/images/kyc/${
                      dd.checked ? "checked.svg" : "no_check.svg"
                    }`}
                  ></Image>
                  <Text ml={"10px"} fontSize={"14px"}>
                    {dd.content}
                  </Text>
                </Flex>
              );
            })}
          </Box>
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const renderInitView = () => {
    return (
      <Flex direction={"column"} alignItems={"center"} mt={"60px"}>
        <Text color={"#010313"} fontSize={"24px"} fontWeight={600}>
          {dataSource?.title}
        </Text>
        <Text color={"#010313"} fontSize={"14px"} mt={"20px"}>
          {dataSource?.description}
        </Text>
        <Box textAlign={"left"} width={"100%"} fontSize={"14px"}>
          <Text mt={"10px"}>{t("BslmwdHcTinuPaihULw")}</Text>
          <Flex mt={"10px"} alignItems={"center"}>
            <div className="kyc-assets_blue_circle"></div>
            <Text
              ml={"10px"}
              dangerouslySetInnerHTML={{ __html: t("jvnfqZRQbFEGTCMGbvT") }}
            ></Text>
          </Flex>
          <Flex mt={"10px"} alignItems={"center"}>
            <div className="kyc-assets_blue_circle"></div>
            <Text
              ml={"10px"}
              dangerouslySetInnerHTML={{ __html: t("NsZKEKPRgLQfWeSixA") }}
            ></Text>
          </Flex>
          <Flex mt={"10px"} alignItems={"center"}>
            <div className="kyc-assets_blue_circle"></div>
            <Text
              ml={"10px"}
              dangerouslySetInnerHTML={{ __html: t("icMsBwEbBUQztvmOv") }}
            ></Text>
          </Flex>

          <Flex mt={"10px"} alignItems={"center"} ml={"20px"}>
            <div className="kyc-assets_blue_light_circle"></div>
            <Text
              ml={"10px"}
              dangerouslySetInnerHTML={{ __html: t("dXXdlICxETXaAzDyY") }}
            ></Text>
          </Flex>

          <Flex mt={"10px"} alignItems={"center"} ml={"20px"}>
            <div className="kyc-assets_blue_light_circle"></div>
            <Text
              ml={"10px"}
              dangerouslySetInnerHTML={{ __html: t("zhFTdPuxrgMfcNWoA") }}
            ></Text>
          </Flex>

          <Text mt={"10px"}>{t("pjPGNrHukGWKUheCaX")}</Text>
          <Text mt={"10px"}>{t("uglsZRLSLmmcqItl")}</Text>
          <Text mt={"10px"}>{t("nPqFzGTyChvJyTQw")}</Text>

          <Flex mt={"10px"} alignItems={"flex-start"}>
            <div
              className="kyc-assets_blue_circle"
              style={{ marginTop: "7px" }}
            ></div>
            <Text
              ml={"10px"}
              dangerouslySetInnerHTML={{ __html: t("mEPBGXtDbaIrisefS") }}
            ></Text>
          </Flex>

          <Flex mt={"10px"} alignItems={"flex-start"}>
            <div
              className="kyc-assets_blue_circle"
              style={{ marginTop: "7px" }}
            ></div>
            <Text
              ml={"10px"}
              dangerouslySetInnerHTML={{ __html: t("dBZfEFBQmkatfptbV") }}
            ></Text>
          </Flex>
          <Text mt={"10px"}>{t("jYUScsXfUCTPNPqSAR")}</Text>
          <Text mt={"10px"}>{t("OJjDmIeMWmMfkDihQu")}</Text>
          <Text
            mt={"10px"}
            color="red.100"
            dangerouslySetInnerHTML={{ __html: t("JoPUjgxywPfIQlnDrzx") }}
          ></Text>
          <Text mt={"10px"}>{t("ZVRKVLGoMmmrOxLzg")}</Text>
        </Box>

        <Box>{MemeQues}</Box>
        <Flex justifyContent={"center"} mt="40px">
          <Button
            ml="10px"
            fontSize="14px"
            padding="0 30px"
            w="100px"
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("WbPTckCFMKtPHgAlL")}
          </Button>
          <Button
            ml="10px"
            fontSize="14px"
            padding="0 30px"
            w="200px"
            bg={isDisabled ? "t.50" : "blue.100"}
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            _hover={{ bg: !isDisabled && "blue.80" }}
            h="44px"
            disabled={isDisabled}
            cursor={"pointer"}
            onClick={() => {
              if (!isDisabled) {
                onSubmit();
              }
            }}
          >
            {t("YChcfaxDaLscdSPiRQz")}
          </Button>
        </Flex>
      </Flex>
    );
  };

  const renderResultView = () => {
    return (
      <Flex
        mt={"60px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Image
          w={"40px"}
          height={"40px"}
          src={`../../wwwsite/images/kyc/${
            resultInfo?.is_pass ? "success.svg" : "failed.svg"
          }`}
        />
        <Text
          fontSize={"24px"}
          fontWeight={600}
          mt={"10px"}
          dangerouslySetInnerHTML={{
            __html: `${
              resultInfo?.is_pass
                ? t("yEMtEIJyJomPsTPTv")
                : t("YyrFTDoECNUvGnXVt")
            }`.replace("$s", levelMaps?.[resultInfo.right_count]),
          }}
        ></Text>
        <Flex justifyContent={"center"} mt="40px">
          <Button
            ml="10px"
            fontSize="14px"
            padding="0 30px"
            w="100px"
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              navigate("/trade/personKyc/certificate");
            }}
          >
            {t("WbPTckCFMKtPHgAlL")}
          </Button>
          <Button
            ml="10px"
            fontSize="14px"
            padding="0 30px"
            w="200px"
            bg={"blue.100"}
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            _hover={{ bg: "blue.80" }}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              if (resultInfo?.is_pass) {
                setStep(6);
              } else {
                reTry();
                // navigate("/trade/personKyc/certificate");
              }
            }}
          >
            {resultInfo?.is_pass
              ? t("tOaRBVUJHpnSgDBrGON")
              : t("leIEPguvvNFtRsXGFI")}
          </Button>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex justifyContent={"center"} width={"100%"}>
      <Box mt={"30px"} width={"100%"} mb={"60px"}>
        <Progress />
        {viewStatus === "INIT" && dataSource && renderInitView()}
        {viewStatus === "RESULT" && renderResultView()}
      </Box>
    </Flex>
  );
};

export default InvestmentRisk;
