// 结单
import { useMemo, useState } from "react";
import MonthOrder from "./MonthOrder";
import DayOrder from "./DayOrder";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import "./style.scss";
import { useMedia } from "@/hooks/useMedia";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import PageWrap from "@/components/PageWrap";

const Statement = () => {
  const [currTab, setCurrTab] = useState(1);
  const navigate = useNavigate();
  const { isMobile } = useMedia();
  const { t } = useI18n();

  const tabs = useMemo(() => {
    return [
      {
        key: 1,
        text: t("lzbnQluepXRurDUf"),
      },
      {
        key: 2,
        text: t("xDfYpxodvMVDQJKav"),
      },
    ];
  }, [t]);

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        mb={"30px"}
        position={"relative"}
      >
        {isMobile && (
          <Box
            mt="30px"
            mb="20px"
            w="112px"
            h="40px"
            bg="rgba(32, 26, 85, 0.05)"
            borderRadius={"0px 32px 32px 0px"}
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              w="112px"
              h="40px"
            >
              <ArrowBackIcon />
              <Text fontSize="14px" onClick={() => navigate("/trade/history")}>
                {t("WbPTckCFMKtPHgAlL")}
              </Text>
            </Flex>
          </Box>
        )}

        {isMobile && (
          <Flex
            w={["80%", "880px"]}
            justifyContent={"flex-start"}
            margin={"0 auto"}
          >
            <Text fontSize={"32px"} fontWeight={"600"} color={"#000"}>
              {t("ZbUUOchcpSOpwpIk")}
            </Text>
          </Flex>
        )}

        {!isMobile && (
          <Box position={"absolute"} left={"10%"} top={"40px"}>
            <Box
              w="112px"
              h="40px"
              bg="rgba(32, 26, 85, 0.05)"
              borderRadius={"32px"}
              _hover={{ background: "#E2E8F0" }}
              cursor={"pointer"}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                w="112px"
                h="40px"
              >
                <ArrowBackIcon />
                <Text
                  fontSize="14px"
                  onClick={() => navigate("/trade/history")}
                >
                  {t("WbPTckCFMKtPHgAlL")}
                </Text>
              </Flex>
            </Box>
          </Box>
        )}

        {!isMobile && (
          <Box
            w={["80%", "880px"]}
            justifyContent={"center"}
            margin={"0 auto"}
            textAlign={"center"}
            mt={"40px"}
            // mt={("50px", "15px")}
          >
            <Text fontSize={"32px"} fontWeight={"600"} color={"#000"}>
              {t("ZbUUOchcpSOpwpIk")}
            </Text>
          </Box>
        )}

        <Box
          w={["80%", "880px"]}
          justifyContent={"center"}
          margin={"0 auto"}
          mt={["15px", "50px"]}
        >
          <Flex>
            {tabs.map((d, i) => {
              return (
                <Text
                  borderBottom={currTab === d.key ? "3px solid #010313" : ""}
                  paddingBottom={"10px"}
                  mr={i === 0 ? "10px" : "0px"}
                  onClick={() => setCurrTab(d.key)}
                  cursor={"pointer"}
                  key={d.key}
                  color={currTab === d.key ? "#3E3D4D" : "#A9A8B2"}
                  fontSize={"24px"}
                  fontWeight={currTab === d.key ? 600 : 400}
                >
                  {d.text}
                </Text>
              );
            })}
          </Flex>
          <Box
            border={"1px solid #E3E4F1"}
            borderRadius={"16px"}
            mt={"20px"}
            boxSizing={"borderBox"}
          >
            {currTab === 1 && <MonthOrder />}
            {currTab === 2 && <DayOrder />}
          </Box>
          <Center mt={"40px"}>
            {currTab === 1 && (
              <p
                dangerouslySetInnerHTML={{ __html: t("XBrHgyrvVacGhRNJco") }}
                className="order-statement-tip"
              ></p>
            )}
            {currTab === 2 && (
              <p
                dangerouslySetInnerHTML={{ __html: t("PuqWAcBQEKXlfTuxDuD") }}
                className="order-statement-tip"
              ></p>
            )}
          </Center>
        </Box>
      </Box>
    </PageWrap>
  );
};

export default Statement;
