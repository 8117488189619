// 邮箱
export const emailValid = (email) => {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

// 验证码
export const verifyCodeVaild = (code) => {
  const reg = /^\d{6}$/;
  return reg.test(code);
};

// 密码
export const passwordValid = (code) => {
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,25}$/;
  return reg.test(code);
};
