import { Box, Flex, Text, Button, Input, Image } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import usePersonalHooks from "../PersonalInfo/hooks";
import { useAppSelector } from "@/state/hooks";
import { useCommonHooks } from "../verifyHooks";
import { useMemo, useState } from "react";
import ModalComponent from "@/components/ui/modal";

const Preview = ({
  reAddress,
  emailAddress,
  checkList,
  fileName,
  memoEmailAddressList,
  setViewStatus,
  sanctionsCountryList,
  noCompliantCountryList,
}) => {
  const { t } = useI18n();
  const _lang = useAppSelector((state) => state.user.language);
  const [showTipModal, setShowModalTip] = useState(false);
  const {
    memoGender,
    memoCardType,
    inputParams,
    memoPEP,
    memoWealthSource,
    memoEstimatedNetWorth,
    memoFundSource,
    memoAnnualIncome,
    memoAnticipatedMonthlyTxAmount,
    memoAnticipatedMonthlyTxFrequency,
    memoPEPContact,
    memoTradeTargets,
    memoNeedProdServices,
    memoHasOtherCountryCard,
    memoOtherCountryCardType,
  } = usePersonalHooks({ sanctionsCountryList, noCompliantCountryList });

  const { allCountryList } = useCommonHooks();

  const memoCountry = useMemo(() => {
    if (allCountryList.length > 0 && inputParams.country) {
      const item = allCountryList.filter(
        (d) => d.iso_code2 === inputParams.country
      )[0];
      if (_lang === "zh") return item?.zh_name;
      return item?.en_name;
    }
  }, [allCountryList, inputParams, _lang]);

  const memoOtherCountry = useMemo(() => {
    if (allCountryList.length > 0 && inputParams.other_country) {
      const item = allCountryList.filter(
        (d) => d.iso_code2 === inputParams.other_country
      )[0];
      if (_lang === "zh") return item?.zh_name;
      return item?.en_name;
    }
  }, [allCountryList, inputParams, _lang]);

  // const memoNationality = useMemo(() => {
  //   if (allCountryList.length > 0 && inputParams.nationality) {
  //     let name = "";
  //     const item = allCountryList.filter(
  //       (d) => d.iso_code2 === inputParams.nationality
  //     )[0];
  //     if (_lang === "zh") {
  //       name = item?.zh_name;
  //     } else {
  //       name = item?.en_name;
  //     }
  //     return `${name} (+${item.dial_code})`;
  //   }
  // }, [allCountryList, inputParams]);

  const memoDialCodeCountry = useMemo(() => {
    if (allCountryList.length > 0) {
      if (inputParams.other_country) {
        let name = "";
        const item = allCountryList.filter(
          (d) => d.iso_code2 === inputParams.other_country
        )[0];
        if (_lang === "zh") {
          name = item?.zh_name;
        } else {
          name = item?.en_name;
        }
        return `${name} (+${item.dial_code})`;
      } else if (!inputParams?.other_country && inputParams?.country) {
        let name = "";
        const item = allCountryList.filter(
          (d) => d.iso_code2 === inputParams.country
        )[0];
        if (_lang === "zh") {
          name = item?.zh_name;
        } else {
          name = item?.en_name;
        }
        return `${name} (+${item.dial_code})`;
      }
    }
  }, [allCountryList, inputParams, _lang]);

  const showOtherCountryInfoCard = useMemo(() => {
    // 如果是香港居民身份证
    if (inputParams?.id_type === "ID_CARD") {
      return false;
    }
    // 合规地区的护照
    if (
      inputParams.id_type === "PASSPORT" &&
      sanctionsCountryList?.indexOf(inputParams.country) < 0 &&
      noCompliantCountryList?.indexOf(inputParams.country) < 0
    ) {
      return false;
    }
    return true;
  }, [inputParams, sanctionsCountryList, noCompliantCountryList]);

  const renderOtherCountryInfo = () => {
    return (
      <Box
        bg={"rgba(250, 250, 250, 1)"}
        borderRadius={"12px"}
        border={"1px solid rgba(227, 228, 241, 1)"}
        padding={"20px 15px"}
        mt={"10px"}
      >
        <Flex flexDirection={"column"}>
          <Text fontWeight={400} fontSize={"14px"}>
            {t("zLnEBdSfUXdqbRqAohY")}
          </Text>
          <Flex mt={"10px"}>
            {memoHasOtherCountryCard.map((d, i) => {
              return (
                <Flex ml={i === 1 && "40px"}>
                  <Image
                    src={`../../wwwsite/images/kyc/radio${
                      Number(inputParams.has_other_country_id_card?.value) ===
                      Number(d.value)
                        ? "_checked"
                        : ""
                    }.svg`}
                  />
                  <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                    {d.label}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
          {Number(inputParams.has_other_country_id_card?.value) === 2 && (
            <Text color={"#D04457"} fontSize={"14px"} mt={"15px"}>
              {t("mzWsFtJNWfexuuUgYctv")}
            </Text>
          )}
        </Flex>
        {/* 其他国家证件发行地 */}
        {Number(inputParams.has_other_country_id_card?.value) === 1 &&
          inputParams?.other_country && (
            <Flex mt={"30px"} flexDirection={"column"}>
              <Text fontSize="14px" fontWeight={400}>
                {t("xjuVMsJZTRgjPgAzSV")}
              </Text>
              <Input
                h="44px"
                fontSize="12px"
                color="t.100"
                borderRadius={"12px"}
                mt="10px"
                disabled
                _disabled={{
                  opacity: "1",
                  color: "#010313",
                  bgColor: "rgba(32, 26, 85, 0.05)",
                  border: "1px solid #E3E4F1",
                }}
                value={memoOtherCountry}
              />
            </Flex>
          )}
        {/* 其他国家证件类型 */}
        {Number(inputParams.has_other_country_id_card?.value) === 1 &&
          inputParams?.other_country_card_type && (
            <Flex mt={"30px"} flexDirection={"column"}>
              <Text fontSize="14px" fontWeight={400}>
                {t("VALtZMIyzODbkpPUOsxG")}
              </Text>
              <Input
                h="44px"
                fontSize="12px"
                color="t.100"
                borderRadius={"12px"}
                mt="10px"
                disabled
                _disabled={{
                  opacity: "1",
                  color: "#010313",
                  bgColor: "rgba(32, 26, 85, 0.05)",
                  border: "1px solid #E3E4F1",
                }}
                value={
                  memoOtherCountryCardType.filter(
                    (d) =>
                      String(d.value) ===
                      String(inputParams?.other_country_card_type)
                  )[0]?.label
                }
              />
            </Flex>
          )}

        {/* 其他国家证件正面地址 */}

        <Text mt={"30px"} color={"rgba(1, 3, 19, 1)"} fontSize={"14px"}>
          {t("oSDxsXgENbIeNMLv")}
        </Text>

        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          mt="10px"
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
          value={
            inputParams?.other_country_card_front_file?.[0]?.document_path ||
            `${t("DrmqiYKPSoxMBWXT")}`
          }
        />
        {/* 其他国家证件反面文件地址 */}
        <Text mt={"30px"} color={"rgba(1, 3, 19, 1)"} fontSize={"14px"}>
          {t("aoAIqdHabiquSjWWz")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          mt="10px"
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
          value={
            inputParams?.other_country_card_opposite_file?.[0]?.document_path ||
            ""
          }
        />
      </Box>
    );
  };

  return (
    <Box>
      {/* 名 姓 */}
      <Flex mt={"40px"}>
        <Flex flexDirection={"column"} flex={"1"}>
          <Text fontSize="14px" fontWeight={400}>
            {t("THMxZAxLcuuIfPErpGb")}
          </Text>
          <Input
            h="44px"
            fontSize="12px"
            borderRadius={"12px"}
            mt="10px"
            value={inputParams.first_name}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        </Flex>
        <Flex flexDirection={"column"} flex={"1"} ml={"20px"}>
          <Text fontSize="14px" fontWeight={400}>
            {t("bTWntlsiiBougPgd")}
          </Text>
          <Input
            h="44px"
            fontSize="12px"
            borderRadius={"12px"}
            mt="10px"
            value={inputParams.last_name}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        </Flex>
      </Flex>
      {/* 曾用名 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("HmoCuzIHcMwWJJxszus")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          borderRadius={"12px"}
          mt="10px"
          value={inputParams.former_name}
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
        />
      </Flex>

      {/* 性别 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={600}>
          {t("oMgGPGaGAlElFewgOv")}
        </Text>
        <Flex mt={"10px"}>
          {memoGender.map((d, i) => {
            return (
              <Flex ml={i === 1 && "40px"}>
                <Image
                  src={`../../wwwsite/images/kyc/radio${
                    inputParams.gender === d.value ? "_checked" : ""
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Flex>

      {/* 证件类型 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={600}>
          {t("VALtZMIyzODbkpPUOsxG")}
        </Text>
        <Flex mt={"10px"}>
          {memoCardType.map((d, i) => {
            return (
              <Flex ml={i === 1 && "40px"}>
                <Image
                  src={`../../wwwsite/images/kyc/radio${
                    inputParams.id_type === d.value ? "_checked" : ""
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Flex>

      {/* 证件号码 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("IzXQAIEgQLMYmZwrzP")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          mt="10px"
          value={inputParams.id_number}
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
        />
      </Flex>

      {/* 证件发行地 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("xjuVMsJZTRgjPgAzSV")}
        </Text>
        <Box mt={"15px"}>
          <Input
            h="44px"
            fontSize="12px"
            color="t.100"
            borderRadius={"12px"}
            mt="10px"
            value={memoCountry}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        </Box>
      </Flex>

      {showOtherCountryInfoCard && renderOtherCountryInfo()}

      {/* 国籍 */}
      {/* <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("WACiElgvWMUDhQMxa")}
        </Text>
        <Box mt={"15px"}>
          <Input
            h="44px"
            fontSize="12px"
            color="t.100"
            borderRadius={"12px"}
            mt="10px"
            value={memoNationality}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        </Box>
      </Flex> */}

      {/* 出生地 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("USZqODoQgzwaggnbb")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          borderRadius={"12px"}
          mt="10px"
          disabled
          value={inputParams.birth_place}
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
        />
      </Flex>
      {/* 出生日期 */}
      <Box>
        <Text fontSize="14px" mt="20px">
          {t("KChGPohSmJORAMDUqSs")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          borderRadius={"12px"}
          mt="10px"
          disabled
          value={inputParams.birth_date}
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
        />
      </Box>

      {/* 职业 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("oGZZfIIocJcraogK")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          borderRadius={"12px"}
          mt="10px"
          value={inputParams.occupation}
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
        />
      </Flex>

      {/* 就职行业 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("pAYXQPFeRbuYruZa")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          borderRadius={"12px"}
          mt="10px"
          value={inputParams.occupation_industry}
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
        />
      </Flex>

      {/* 就职公司名 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("aaOdxWUHHLynEVSoWTM")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          borderRadius={"12px"}
          mt="10px"
          value={inputParams.employer_name}
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
        />
      </Flex>

      {/* 交易目的 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={600}>
          {t("jSQVlHGDwxAZujRJNiSh")}
        </Text>
        <Flex mt={"10px"}>
          {memoTradeTargets.map((d, i) => {
            return (
              <Flex ml={i > 0 && "40px"}>
                <Image
                  src={`../../wwwsite/images/kyc/${
                    inputParams?.transaction_purpose?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "checked"
                      : "no_check"
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {inputParams.transaction_purpose?.description && (
          <Input
            h="44px"
            fontSize="12px"
            borderRadius={"12px"}
            mt="10px"
            value={inputParams.transaction_purpose?.description}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        )}
      </Flex>

      {/* 所需产品服务 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={600}>
          {t("ACweJQrKoWgpyYspyEl")}
        </Text>
        <Flex mt={"10px"}>
          {memoNeedProdServices.map((d, i) => {
            return (
              <Flex ml={i > 0 && "40px"}>
                <Image
                  cursor={"pointer"}
                  src={`../../wwwsite/images/kyc/${
                    inputParams?.products_services?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "checked"
                      : "no_check"
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {inputParams.products_services?.description && (
          <Input
            h="44px"
            fontSize="12px"
            borderRadius={"12px"}
            mt="10px"
            value={inputParams.products_services?.description}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        )}
      </Flex>

      {/* 财富来源 */}
      <Flex mt={"40px"} flexDirection={"column"}>
        <Text fontWeight={500}>{t("lfjjwhqpvFBikfhkifrN")}</Text>
        <Flex flexWrap={"wrap"} mt={"10px"}>
          {memoWealthSource.map((d, i) => {
            return (
              <Flex mr={"20px"} mb={"15px"}>
                <Image
                  src={`../../wwwsite/images/kyc/${
                    inputParams?.wealth_source?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "checked"
                      : "no_check"
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {inputParams.wealth_source?.description && (
          <Input
            h="44px"
            fontSize="12px"
            borderRadius={"12px"}
            mt="10px"
            value={inputParams.wealth_source?.description}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        )}
      </Flex>
      {/* 预估净值 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontWeight={500}> {t("kYGDyxESdKUNNTdG")}</Text>
        <Flex flexWrap={"wrap"} mt={"10px"}>
          {memoEstimatedNetWorth.map((d, i) => {
            return (
              <Flex mr={"20px"} mb={"15px"}>
                <Image
                  src={`../../wwwsite/images/kyc/radio${
                    inputParams?.net_worth?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "_checked"
                      : ""
                  }.svg`}
                  cursor={"pointer"}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {inputParams.net_worth?.description && (
          <Input
            h="44px"
            fontSize="12px"
            borderRadius={"12px"}
            mt="10px"
            value={inputParams.net_worth?.description}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        )}
      </Flex>
      {/* 资金来源 多选*/}
      <Flex mt={"40px"} flexDirection={"column"}>
        <Text fontWeight={500}>{t("DSFAJhsSefQQEdBBlUyt")}</Text>
        <Flex flexWrap={"wrap"} mt={"10px"}>
          {memoFundSource.map((d, i) => {
            return (
              <Flex mr={"20px"} mb={"15px"}>
                <Image
                  src={`../../wwwsite/images/kyc/${
                    inputParams?.fund_source?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "checked"
                      : "no_check"
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {inputParams.fund_source?.description && (
          <Input
            h="44px"
            fontSize="12px"
            borderRadius={"12px"}
            mt="10px"
            value={inputParams.fund_source?.description}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        )}
      </Flex>
      {/* 年收入 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontWeight={500}> {t("fjjnxDoEsAfrEGSbNUL")}</Text>
        <Flex flexWrap={"wrap"} mt={"10px"}>
          {memoAnnualIncome.map((d, i) => {
            return (
              <Flex mr={"20px"} mb={"15px"}>
                <Image
                  src={`../../wwwsite/images/kyc/radio${
                    inputParams?.annual_income?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "_checked"
                      : ""
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {inputParams.annual_income?.description && (
          <Input
            h="44px"
            fontSize="12px"
            borderRadius={"12px"}
            mt="10px"
            value={inputParams.annual_income?.description}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        )}
      </Flex>
      {/* 预估月均交易额 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontWeight={500}> {t("ZCRfudjxgEkJLycN")}</Text>
        <Flex mt={"10px"} flexWrap={"wrap"}>
          {memoAnticipatedMonthlyTxAmount.map((d, i) => {
            const imgSrc = `../../wwwsite/images/kyc/radio${
              inputParams?.monthly_turnover?.options?.findIndex(
                (x) => x.value === d.value
              ) > -1
                ? "_checked"
                : ""
            }.svg`;
            return (
              <Flex mr={"20px"} mb={"15px"}>
                <Image src={imgSrc} />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      {/* 预估月均交易频率 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontWeight={500}> {t("ZCRfudjxgEkJLycN")}</Text>
        <Flex mt={"10px"} flexDirection={"column"}>
          {memoAnticipatedMonthlyTxFrequency.map((d, i) => {
            const imgSrc = `../../wwwsite/images/kyc/radio${
              inputParams?.monthly_frequency?.options?.findIndex(
                (x) => x.value === d.value
              ) > -1
                ? "_checked"
                : ""
            }.svg`;
            return (
              <Flex flexDirection={"column"}>
                <Flex mb={"10px"}>
                  <Image src={imgSrc} />
                  <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                    {d.label}
                  </Text>
                </Flex>
                {inputParams?.monthly_frequency?.options?.findIndex(
                  (d) => d.value === 1
                ) > -1 &&
                  i === 0 && (
                    <Input
                      h="44px"
                      fontSize="12px"
                      color="t.100"
                      borderRadius={"12px"}
                      placeholder={`${t("WjPizXTxbdPnvLWMql")}  (${t(
                        "idqLvJqjeiEmQbpa"
                      )})`}
                      mt="5px"
                      mb={"15px"}
                      disabled
                      _disabled={{
                        opacity: "1",
                        color: "#010313",
                        bgColor: "rgba(32, 26, 85, 0.05)",
                        border: "1px solid #E3E4F1",
                      }}
                      value={inputParams?.monthly_frequency?.description}
                    />
                  )}
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      {/* 您或任何关联方     是否是政治公众人物？ */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Flex color={"#010313"}>
          <Text fontSize="14px" fontWeight={400}>
            {t("hYWPDZZNtYYwiYMfUfjX")}
          </Text>
          <Image
            src={"../../wwwsite/images/kyc/gray-info-circle.svg"}
            cursor={"pointer"}
            margin={"0 3px"}
            onClick={() => setShowModalTip(true)}
          />
          <Text fontSize="14px" fontWeight={400}>
            {t("NIxMpJepTIBNynamgjj")}
          </Text>
        </Flex>
        <Flex mt={"10px"}>
          {memoPEP.map((d, i) => {
            const imgSrc = `../../wwwsite/images/kyc/radio${
              inputParams?.pep?.options?.findIndex((x) => x.value === d.value) >
              -1
                ? "_checked"
                : ""
            }.svg`;
            return (
              <Flex ml={i === 1 && "40px"}>
                <Image src={imgSrc} />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {/* PEP官方职位 在职时间 */}
        {inputParams?.pep?.options?.findIndex((d) => d.value === 1) > -1 && (
          <Flex mt={"20px"}>
            <Flex flexDirection={"column"} flex={"1"}>
              <Text fontSize="14px" fontWeight={400}>
                {t("nkENghGiqpNyJlRpBv")}
              </Text>
              <Input
                h="44px"
                fontSize="12px"
                borderRadius={"12px"}
                mt="10px"
                value={inputParams.pep?.position}
                disabled
                _disabled={{
                  opacity: "1",
                  color: "#010313",
                  bgColor: "rgba(32, 26, 85, 0.05)",
                  border: "1px solid #E3E4F1",
                }}
              />
            </Flex>
            <Flex flexDirection={"column"} flex={"1"} ml={"20px"}>
              <Text fontSize="14px" fontWeight={400}>
                {t("eEBDsqLdNoLOJxjVc")}
              </Text>
              <Input
                h="44px"
                fontSize="12px"
                color="t.100"
                borderRadius={"12px"}
                mt="10px"
                value={inputParams.pep?.position_time}
                disabled
                _disabled={{
                  opacity: "1",
                  color: "#010313",
                  bgColor: "rgba(32, 26, 85, 0.05)",
                  border: "1px solid #E3E4F1",
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      {/* 您或任何关联方     是否与政治公众人物（PEP）有关？ */}
      <Flex mt={"40px"} flexDirection={"column"}>
        <Flex color={"#010313"}>
          <Text fontSize="14px" fontWeight={400}>
            {t("hYWPDZZNtYYwiYMfUfjX")}
          </Text>
          <Image
            src={"../../wwwsite/images/kyc/gray-info-circle.svg"}
            cursor={"pointer"}
            margin={"0px 3px"}
            onClick={() => setShowModalTip(true)}
          />
          <Text fontSize="14px" fontWeight={400}>
            {t("onaIGydLoNFhYUGyo")}
          </Text>
        </Flex>
        <Flex mt={"10px"}>
          {memoPEPContact.map((d, i) => {
            const imgSrc = `../../wwwsite/images/kyc/radio${
              inputParams?.pep_relation?.options?.findIndex(
                (x) => x.value === d.value
              ) > -1
                ? "_checked"
                : ""
            }.svg`;

            return (
              <Flex ml={i === 1 && "40px"}>
                <Image src={imgSrc} />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {/* PEP官方职位 在职时间 */}
        {inputParams?.pep_relation?.options?.findIndex((d) => d.value === 1) >
          -1 && (
          <Flex mt={"20px"} flexDirection={"column"}>
            <Flex flexDirection={"column"}>
              <Text fontSize="14px" fontWeight={400}>
                {t("SJBETCfUKQwBPbOwXhs")}
              </Text>
              <Input
                h="44px"
                fontSize="12px"
                borderRadius={"12px"}
                mt="10px"
                value={inputParams.pep_relation?.description}
                color="t.100"
                disabled
                _disabled={{
                  opacity: "1",
                  color: "#010313",
                  bgColor: "rgba(32, 26, 85, 0.05)",
                  border: "1px solid #E3E4F1",
                }}
              />
            </Flex>
            <Flex mt={"20px"} justifyContent={"space-between"} w={"100%"}>
              <Flex flexDirection={"column"} flex={"1"}>
                <Text fontSize="14px" fontWeight={400}>
                  {t("nkENghGiqpNyJlRpBv")}
                </Text>
                <Input
                  h="44px"
                  fontSize="12px"
                  color="t.100"
                  borderRadius={"12px"}
                  mt="10px"
                  value={inputParams.pep_relation?.position}
                  disabled
                  _disabled={{
                    opacity: "1",
                    color: "#010313",
                    bgColor: "rgba(32, 26, 85, 0.05)",
                    border: "1px solid #E3E4F1",
                  }}
                />
              </Flex>
              <Flex flexDirection={"column"} flex={"1"} ml={"20px"}>
                <Text fontSize="14px" fontWeight={400}>
                  {t("eEBDsqLdNoLOJxjVc")}
                </Text>
                <Input
                  h="44px"
                  fontSize="12px"
                  color="t.100"
                  borderRadius={"12px"}
                  mt="10px"
                  value={inputParams.pep_relation?.position_time}
                  disabled
                  _disabled={{
                    opacity: "1",
                    color: "#010313",
                    bgColor: "rgba(32, 26, 85, 0.05)",
                    border: "1px solid #E3E4F1",
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
      {/* crs文件证明 */}
      <Box mt={"40px"}>
        <Text fontSize="14px" color="t.100" mt="20px" borderRadius={"12px"}>
          {t("DrmqiYKPSoxMBWXT")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          mt="10px"
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
          value={
            inputParams?.documents?.[0]?.document_path ||
            `${t("DrmqiYKPSoxMBWXT")}`
          }
        />
      </Box>

      {/* 地址证明 */}

      {/* 常居地 */}
      <Box mt={"20px"}>
        <Text fontSize="14px" color="t.100" mt="20px" borderRadius={"12px"}>
          {t("gYpQegnebgwwccEuM")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          mt="10px"
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
          value={memoDialCodeCountry}
        />
      </Box>
      {/* 常居地址*/}
      <Box>
        <Text fontSize="14px" color="t.100" mt="20px" borderRadius={"12px"}>
          {t("HQSjnUOWYQPuvvTpu")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          mt="10px"
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
          value={reAddress}
        />
      </Box>

      {/* 邮寄地址*/}
      <Box>
        <Text fontSize="14px" color="t.100" mt="20px" borderRadius={"12px"}>
          {t("PufPEQRHcFlNqsAG")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          mt="10px"
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
          value={emailAddress}
        />
        <Flex flexDirection={"column"}>
          {memoEmailAddressList.map((d) => {
            return (
              <Flex key={d.type} mt={"10px"} alignItems={"flex-start"}>
                <Image
                  width={"14px"}
                  height={"14px"}
                  mt={"3px"}
                  src={`../../wwwsite/images/kyc/${
                    checkList.indexOf(d.type) > -1
                      ? "checked.svg"
                      : "no_check.svg"
                  }`}
                />
                <Text fontSize={"14px"} ml={"5px"}>
                  {d.text}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Box>

      {/* 上传住址证明*/}
      <Box>
        <Text fontSize="14px" color="t.100" mt="20px" borderRadius={"12px"}>
          {t("NoqAHzFdypmIlmXmyM")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          mt="10px"
          disabled
          _disabled={{
            opacity: "1",
            color: "#010313",
            bgColor: "rgba(32, 26, 85, 0.05)",
            border: "1px solid #E3E4F1",
          }}
          value={fileName}
        />
      </Box>

      {/* 按钮 */}
      <Flex justifyContent={"center"} mt="60px" mb={"60px"}>
        <Button
          mr="10px"
          fontSize="14px"
          padding="0 30px"
          w="100px"
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => setViewStatus("INIT")}
        >
          {t("FrzWGSiDSDzTfNgZH")}
        </Button>
      </Flex>

      {/* PEP modal */}
      <ModalComponent isShow={showTipModal}>
        <Box px={"33px"} py={"50px"}>
          <Box color={"#010313"} fontSize={14}>
            <Flex justifyContent={"space-between"}>
              <Text fontSize={16} fontWeight={600}>
                {t("IKImbOtCOwEOtcDsMLv")}
              </Text>
              <Image
                boxSize="24px"
                src="../../wwwsite/images/bill/close.svg"
                cursor={"pointer"}
                onClick={() => setShowModalTip(false)}
              />
            </Flex>
            <Text
              mt={"20px"}
              dangerouslySetInnerHTML={{ __html: t("woYYXxIEUCPOPTbYi") }}
            ></Text>
          </Box>
        </Box>
      </ModalComponent>
    </Box>
  );
};

export default Preview;
