import { Box, Text } from "@chakra-ui/react";

const TwDoc = () => {
  return (
    <Box>
      <Text fontSize="28px" fontWeight={"bold"} mt="30px">
        隱私條款
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        最後更新時間：2024年1月5日
      </Text>
      <Text mt="30px">
        以下條款和條件構成由NewBX Limited（以下簡稱“公司”）運營的www.bixin.com
        （連同其iOS、Android或其他系統的應用程序，以下簡稱“網站”或“幣信”）的隱私條款。除非另有說明，本文中提及的公司還應包括公司的全資子公司NewBX
        Treasures
        Limited。在您成為幣信用戶（以下簡稱「您」或「用戶」）之前，請仔細閱讀本隱私條款並確認同意。本公司非常重視用戶的私人信息，然而，作為正常服務運營的一部分，本公司可能會收集、使用和（在某些情況下）向第三方披露您的個人資訊。本隱私條款作為公司用戶協議的一部分，將在您註冊成為幣信用戶後立即生效，對您和本公司均具有約束力。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        A. 使用者身分限制
      </Text>
      <Text mt={"10px"}>
        任何具有完全行為能力的個人或實體（「人」）都有資格在幣信註冊並使用本公司提供的服務。任何代表某個實體註冊、登入和使用本網站的使用者均聲明並保證他/她代表該實體行事並獲得充分授權。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        B. 訊息
      </Text>
      <Text mt={"10px"}>
        本公司收集您的個人資訊的主要目的是為使用者提供流暢、有效和量身定制的服務體驗。本公司僅收集其認為為實現前述目的所必需的您的資訊。您充分瞭解並同意，本公司有權透過包含但不限於以下方式收集您的個人資訊：除您自願提供的資訊外，請從公開或私人來源額外收集資訊、透過電話或影片等方式驗證資訊真實性、以便更了解您，為您提供量身訂做的服務，解決糾紛，保障您在網站的投資安全。
      </Text>
      <Text mt={"10px"}>本公司可能收集您的個人信息，包括但不限於：</Text>
      <Text mt={"10px"}>
        i.
        您在註冊過程中提供的個人資訊，包括但不限於護照、身分證、駕照以及照片、使用者名稱、密碼等資訊；
      </Text>
      <Text mt={"10px"}>
        ii. 您提供或揭露的個人資訊透過本公司提供給第三方；
      </Text>
      <Text mt={"10px"}>
        iii.您登入和使用網站的時間、時長、使用者記錄、系統日誌和行為資料；
      </Text>
      <Text mt={"10px"}>
        iv.您用於登入和使用本網站的電腦和/或行動裝置的品牌、型號、IP位址和軟體版本；
      </Text>{" "}
      <Text mt={"10px"}>
        v.
        本網站透過cookies或其他方式自動收集的其他個人資訊或隱私權。您可能會變更瀏覽器設定拒絕cookies，這可能導致本公司服務體驗不完整；
      </Text>
      <Text mt={"10px"}>
        vi.當您透過具有定位功能的行動裝置登入或使用本公司服務時，本公司可能會透過GPS或WiFi收集您的地理位置。您可以在行動裝置的設定中關閉該功能，但此後您的使用者體驗可能會受到影響；
      </Text>
      <Text mt={"10px"}>
        vii.當您透過第三方平台上的連結登入本網站時，本公司可能會收集您儲存在該第三方平台上的個人資訊；
      </Text>
      <Text mt={"10px"}>
        viii. 您在使用網站功能時向本公司提供或揭露的其他資訊。
      </Text>
      <Text mt={"10px"}>
        本公司將根據您在網站上的行為自動追蹤上述個人資訊。本公司將使用上述資訊對使用者的人口統計、興趣和行為進行內部研究，以更好地了解您並為您和其他使用者提供更好的服務。
      </Text>
      <Text mt={"10px"}>
        如果您向本公司提供個人通訊資訊（例如簡訊、電子郵件或信件），或其他使用者或第三方向本公司發送與您在網站上的活動或登入有關的通訊訊息，本公司有權收集您的專屬個人資料中的此類數據。
      </Text>
      <Text mt={"10px"}>
        使用者可以在網站的「個人資訊」部分查閱和修改本公司收集的個人資料。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        C. 您資訊的使用
      </Text>
      <Text mt="10px">
        本公司非常重視用戶的隱私資訊。本公司將採用安全技術和程序來儲存和保護使用者的私人信息，以防止未經授權的存取、使用、複製或披露。本公司不會出於任何營利目的而惡意披露您的個人資訊。儘管如此，您同意並授權本公司在以下範圍內使用或揭露您的個人資訊：
      </Text>
      <Text mt="10px">
        i.
        本公司有權使用您的個人資訊來解決或調解糾紛，以確保您的投資安全和履行本公司的用戶協議。公司可能會調查多個使用者以識別問題或解決爭議，特別是它可能會調查您的資訊以識別使用不同使用者名稱或別名的使用者。為了保護公司免受詐欺、非法或其他犯罪活動的侵害，本公司有權透過手動或自動程序評估您的個人資訊。
      </Text>
      <Text mt="10px">
        ii.
        在符合《個人資料（私隱）條例》（香港法例第486章）的規定下，本公司有權使用您的個人資料以改善其營銷及推廣、分析其用途、完善其內容及推廣形式，以及改善其行銷及推廣活動。其內容、設計和服務更能滿足使用者。
      </Text>
      <Text mt="10px">
        iii.本公司有權使用您的資訊與您聯繫並（在某些情況下）提供您可能感興趣的信息，例如服務產品、管理通知和有關您使用網站的新聞通訊。您接受《用戶協議》及本《隱私條款》即視為您明確同意接收該等資訊。
      </Text>
      <Text mt="10px">
        iv.
        當您點擊網站上顯示的第三方平台或服務提供者的連結時，本公司有權根據以下範圍向第三方平台或服務提供者提供您的個人信息，以方便該第三方提供服務和目的，此類第三方包括：
      </Text>
      <Text mt="10px">
        a.{" "}
        <a href="https://www.jumio.com" style={{ color: "#3370ff" }}>
          Jumio
        </a>{" "}
        人臉辨識服務：出於KYC（了解您的用戶）和AML（反洗錢）目的，本網站的客戶需要進行實名認證，因此訊息使用本服務商提供的驗證服務，即透過網路驗證、簡單人像比對、人、卡、公安三重人像比對、身分證OCR、銀行卡OCR、銀行卡認證、活體偵測及臉部品質分析，用戶身分資訊用於確認使用者身分和資訊的真實性、有效性，以保障我們的業務和使用者交易的安全。本服務涉及使用者個人資訊的揭露包括：使用者個人身分證資料、護照資料、證件資料、駕駛證資訊、銀行卡資料、手機號碼資料、個人肖像資料。
      </Text>
      <Text mt="10px">
        b.{" "}
        <a href="https://complyadvantage.com" style={{ color: "#3370ff" }}>
          Comply Advantage
        </a>{" "}
        姓名篩查服務：為防範和控制洗錢和恐怖主義融資風險，公司將姓名篩查工具外包給ComplyAdvantage。公司的平台透過API
        連接到ComplyAdvantage，可實現自動名稱篩查。本服務涉及用戶個人身分證資訊、護照資訊、證件資訊的揭露。
      </Text>
      <Text mt="10px">
        c.{" "}
        <a href="https://www.yunpian.com" style={{ color: "#3370ff" }}>
          雲片
        </a>{" "}
        簡訊通知服務：為了通知用戶，需要向用戶的手機號碼發送簡訊通知。因此，我們利用服務提供者提供的簡訊通知服務，即透過向用戶的手機號碼發送簡訊的方式，幫助用戶順利完成在本網站的註冊、及時了解交易進度、接收服務資訊等。服務涉及用戶個人資訊的揭露，包括：用戶的手機號碼資訊。
      </Text>
      <Text mt="10px">
        d.{" "}
        <a href="https://www.twilio.com" style={{ color: "#3370ff" }}>
          Twilio
        </a>{" "}
        簡訊通知服務：為了通知用戶，需要向用戶的手機號碼發送簡訊通知。因此，我們利用服務提供者提供的簡訊通知服務，即透過向用戶的手機號碼發送簡訊的方式，幫助用戶順利完成在本網站的註冊、及時了解交易進度、接收服務資訊等。服務涉及用戶個人資訊的揭露，包括：用戶的手機號碼資訊。
      </Text>
      <Text mt="10px">
        v.
        您可以授權本公司協助您修改您在網站上填寫的個人資訊。如果您違反《用戶協議》、《隱私條款》或本公司任何其他規則或適用法律，本公司有權在透過以下方式通知後從本公司資料庫中刪除您的個人資訊、關閉您的使用者帳戶或限制您對本網站的使用電子郵件、電話或簡訊。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        D. 揭露您的訊息
      </Text>
      <Text mt="10px">
        本公司採用標準行業慣例來保護您的個人資訊。但受技術限制和不可抗力的影響，本公司可能無法確保您的個人通訊或其他資訊不會透過本隱私條款未提及的方式被揭露。本公司對此類意外揭露不承擔任何責任。
      </Text>
      <Text mt="10px">
        本公司有權依據適用法律法規的要求、本網站的風險控制政策、及相關協議。若您未能履行《用戶協議》、《隱私條款》或本公司其他規則、法律規定的義務，本公司有權依法揭露您的個人資訊並自行做出決定。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        E. 其他使用者資訊的使用
      </Text>
      <Text mt="10px">
        您不得要求本公司提供其他使用者的個人資訊，除非您已向法院或有權機關提出申請，且法院或有權機關依法要求本公司提供相關司法協助。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        F. 電子郵件
      </Text>
      <Text mt="10px">
        當您使用本公司的服務時，本公司可能會使用您的個人資訊向您的裝置發送電子郵件、新聞或推播通知。如果您不打算接收此類訊息，您可以根據本公司的指示在裝置上取消訂閱。
      </Text>
      <Text mt="10px">
        本公司在必要時（例如因係統維護而暫停某些服務），可以發布有關該服務的公告。您可能無法取消此類與服務相關的非促銷性質的公告。
      </Text>
      <Text mt="10px">
        您不得利用本公司的服務或其他電子郵件轉寄服務發送垃圾郵件或其他可能影響本公司係統運作或違反本公司《用戶協議》或《隱私條款》的內容。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        G.密碼的安全性
      </Text>
      <Text mt="10px">
        您應對與您使用使用者名稱和密碼有關的所有行為負責。因此，本公司建議您不要向任何第三方透露您在網站上的使用者名稱或密碼。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        H. 同意和授權
      </Text>
      <Text mt="10px">
        您接受《用戶協議》和《私隱條款》或使用本軟件及服務，即視為明確同意或授權本公司按照相關國家或司法轄區適用於個人信息和私隱保護（無論表述如何）的法律法規處理用戶的個人信息和私隱數據，包括但不限於：收集、存儲、使用、處理、傳輸、提供、披露、刪除等。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        I.規則修改
      </Text>
      <Text mt="10px">
        本公司可能會根據您的建議和公司的需要，不時修改《隱私條款》，以準確反映公司的資訊收集和揭露規則。本隱私條款的任何修訂應在其發布後或修訂中指定的日期自動生效。您繼續使用本公司服務即視為您明確同意修改後的隱私條款。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        J. 適用法律；爭議解決
      </Text>
      <Text mt="10px">
        本隱私條款的解釋以及因本隱私條款而產生的與本網站有關的任何爭議均適用香港特別行政區法律。因本隱私條款引起的或與之相關的任何爭議、衝突或索賠均應由香港國際仲裁中心（「HKIAC」）根據當時有效的HKIAC
        規則透過仲裁解決。
      </Text>
    </Box>
  );
};

export default TwDoc;
