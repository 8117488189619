import PropTypes from 'prop-types';

import { Button } from "@chakra-ui/react";

const CommonBtnDom = (props) => {
    const { 
        clickEvent, 
        btnText,
        btnW,
        noCanClick,
        marginTop,
        btnBG,
        btnColor,
        btnHoverBG,
    } =props;

    const btnClick = () => {
        clickEvent()
    };

    return <Button
        borderRadius={"20px"}
        w={btnW}
        h="44px"
        fontSize="14px"
        bg={noCanClick ? 'rgba(32, 26, 85, 0.05)' : btnBG}
        color={noCanClick ? '#B3B4C8' : btnColor}
        _hover={{ bg: noCanClick ? '' : btnHoverBG }}
        mt={marginTop}
        onClick={btnClick}
        >
        {btnText}
    </Button>
}

CommonBtnDom.propTypes = {
    clickEvent: PropTypes.func,
    btnText: PropTypes.string,
    btnW: PropTypes.string,
    noCanClick: PropTypes.bool,
    marginTop: PropTypes.string,
    btnBG: PropTypes.string,
    btnColor: PropTypes.string,
    btnHoverBG: PropTypes.string,
};

CommonBtnDom.defaultProps = {
    btnText: '下一步',
    btnW: '100%',
    marginTop: '30px',
    btnBG: 'blue.100',
    btnColor: '#fff',
    btnHoverBG: 'blue.80'
}

export default CommonBtnDom;