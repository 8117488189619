import { getPageQuery } from "@/utils";
import { useDispatch } from "react-redux";
import { updateLanguage } from "@/state/user/reducer";
import { useEffect, useState } from "react";
import PageWrap from "@/components/PageWrap";
import BindPhone from "./BindPhone";
import { Box } from "@chakra-ui/react";
import LoginEmail from "./LoginEmail";
import SendPhoneCode from "./SendPhoneCode";

const Login = () => {
  const urlParams = getPageQuery();
  const language = urlParams.language;
  const dispatch = useDispatch();
  // INIT  VERIFY_PHONE_CODE BIND_PHONE_CODE
  const [loginStep, setLoginStep] = useState("INIT");
  const [phoneInfo, setPhoneInfo] = useState({
    phone_code: "",
    phone_number: "",
  });
  const [emailInfo, setEmailInfo] = useState("");
  const [password, setPassWord] = useState("");

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  useEffect(() => {
    if (language) {
      const maps = {
        cn: "zh",
        hk: "tw",
        en: "en",
      };
      if (maps[language]) {
        dispatch(updateLanguage({ language: maps[language] }));
      }
    }
  }, [language, dispatch]);

  return (
    <PageWrap logged={false}>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
      >
        {/* 邮箱登录 */}
        {loginStep === "INIT" && (
          <LoginEmail
            toNextStep={({ emailInfo, phoneInfo, password }) => {
              setEmailInfo(emailInfo);
              setPhoneInfo(phoneInfo);
              setPassWord(password);
              setLoginStep("VERIFY_PHONE_CODE");
            }}
            toBindPhone={({ emailInfo, phoneInfo, password }) => {
              setEmailInfo(emailInfo);
              setPhoneInfo(phoneInfo);
              setPassWord(password);
              setLoginStep("BIND_PHONE_CODE");
            }}
          />
        )}
        {/* 绑定手机号时去发送验证码 */}
        {loginStep === "VERIFY_PHONE_CODE" && (
          <SendPhoneCode
            phoneInfo={phoneInfo}
            emailInfo={emailInfo}
            password={password}
          />
        )}
        {/* 没有绑定手机号时 去绑定手机号*/}
        {loginStep === "BIND_PHONE_CODE" && (
          <BindPhone
            emailInfo={emailInfo}
            password={password}
            phoneInfo={phoneInfo}
          />
        )}
      </Box>
    </PageWrap>
  );
};

export default Login;
