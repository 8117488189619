// eslint-disable-next-line no-unused-vars
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import {
  updateSelectedAccountInfo,
  updateSelectedCurrentAccountType,
} from "@/state/pay/reducer";
import { useAppSelector } from "@/state/hooks";

import CommonUnitStyle from "@/pages/deposit/component/commonUnitStyle";
import CommonBtnDom from "@/pages/deposit/component/commonBtn";

import { Dec } from "@/helpers/func/decimalCal";
import {
  requestAccountInfo,
  requestTrasnferCreate,
} from "@/pages/withdraw/api";
import { jsUuid } from "@/helpers/func/stringPro";
import { useI18n } from "@/hooks/useI18n";

import { WITHDRAWCHAIN } from "@/pages/withdraw/helpers/constants/pageTypeName";
import {
  EMAIL,
  PHONE,
  UID,
} from "@/pages/withdraw/helpers/constants/accountTypeName";

const InsideWithdrawPage = (props) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const _accontInfo = useAppSelector(
    (state) => state.pay.transfer.inner.accontInfo
  );
  // eslint-disable-next-line no-unused-vars
  const _currentAccountType = useAppSelector(
    (state) => state.pay.transfer.inner.currentAccountType
  );

  const { t } = useI18n();

  const {
    currency,
    balance,
    netAboutInfo: { min_amount, max_amount },
    fee,
    amount,
    address,
    updateAddressEvent,
    updateMountEvent,
    backEvent,
    showLoadingEvent,
    getPayId,
  } = props;

  const accontTypeList = [
    { name: t("TVTktEvtpSXRbnxIB"), value: 1, selected_key: EMAIL },
    { name: t("ffqVyVPsxEFhGDWwjo"), value: 2, selected_key: PHONE },
    { name: "uid", value: 3, selected_key: UID },
  ];

  const [uuid, setUuid] = useState("");

  useEffect(() => {
    setUuid(jsUuid());
  }, []);

  useEffect(() => {
    if (!address) {
      resetAccountInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  // 获取币信账户信息
  useEffect(() => {
    if (_currentAccountType && address) {
      resetAccountInfo();
      getAccountInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_currentAccountType, address]);
  const getAccountInfo = async () => {
    const { code, data, msg } = await requestAccountInfo({
      type: _currentAccountType,
      value: address,
    });
    console.log("获取到的账户信息", code, data, msg);
    if (code === 0 && msg === "OK") {
      dispatch(updateSelectedAccountInfo({ accontInfo: data }));
    } else {
      resetAccountInfo();
    }
  };

  const clickBack = () => {
    backEvent(WITHDRAWCHAIN);
  };

  const getAccountType = (getValue) => {
    dispatch(
      updateSelectedCurrentAccountType({ currentAccountType: getValue })
    );
  };
  const resetAccountInfo = () => {
    dispatch(updateSelectedAccountInfo({ accontInfo: {} }));
  };

  const getChainAddress = (getValue) => {
    updateAddressEvent(getValue);
  };

  const getInputAmount = (getValue) => {
    updateMountEvent(getValue);
  };

  const clickAllbtn = () => {
    let allAmount = Dec(balance, fee || "0", "-");
    if (max_amount && Number(max_amount) < Number(allAmount)) {
      allAmount = max_amount;
    }
    updateMountEvent(allAmount);
  };

  const clickNext = async () => {
    if (canNext()) {
      showLoadingEvent(true);
      const body = {
        request_id: uuid,
        currency: currency,
        amount: amount,
        is_outside: false,
        fee_amount: fee,
        // 内部转账需要
        target_id: _accontInfo?.target_id,
      };
      const { code, data } = await requestTrasnferCreate(body);
      console.log("获取到的接口返回的值", code, data, body);
      if (code === 0) {
        const { pay_id } = data;
        getPayId(pay_id);
      } else {
        showLoadingEvent(false);
        setUuid(jsUuid());
      }
    }
  };
  const canNext = () => {
    return Boolean(
      amount &&
        Number(amount) > 0 &&
        !amountInputError() &&
        _accontInfo?.target_id
    );
  };
  const amountInputError = () => {
    let errorText = "";
    if (amount && Number(amount) > 0) {
      if (min_amount) {
        if (Number(amount) < Number(min_amount)) {
          errorText = `${t("iRiLJudagJVXZozmnz")} ${min_amount}`;
        }
      }
      if (max_amount) {
        if (Number(amount) > Number(max_amount)) {
          errorText = `${t("laRzOEpYMTvnZYRBZMnj")} ${max_amount}`;
        }
      }
      if (balance) {
        if (fee) {
          if (Number(fee) > Number(Dec(balance, amount, "-"))) {
            errorText = t("OKOogywrWeAjtQWF");
          }
        }
        if (Number(amount) > Number(balance)) {
          errorText = t("MOtoFwFvpTtTEDVUD");
        }
      }
    }
    return errorText;
  };

  const accountInputPlaceholder = () => {
    let text = t("vyxYMhwwOspfcdpj");
    if (_currentAccountType === PHONE) {
      text = t("KVLFziUaVDlFbnJzh");
    }
    if (_currentAccountType === UID) {
      text = t("MqMVsYdMMtqujWPKHW");
    }
    return text;
  };
  return (
    <>
      <CommonUnitStyle
        title={t("ZdMOAZGAMXotVGVlKJ")}
        showText={`${currency}-${t("hDPPfPClruPeWrSoayR")}`}
        showTitleRightBtn={true}
        TitleRightBtnText={t("WbPTckCFMKtPHgAlL")}
        rightBtnEvent={clickBack}
      />
      <CommonUnitStyle
        title={t("POqloYgxpcTCqdnchGE")}
        showSelectedInput={true}
        textInputPlaceholder={accountInputPlaceholder()}
        inputValue={address}
        leftSelectedList={accontTypeList}
        leftSelectedValue={_currentAccountType}
        getSelectedInputLeftValue={getAccountType}
        inputOnBlurGetValue={getChainAddress}
        inputBottomIcon={_accontInfo?.icon || ""}
        inputBottomIconDesc={_accontInfo?.name || _accontInfo?.uid || ""}
      />
      <CommonUnitStyle
        title={t("sfsygIDbANBoFslbcsw")}
        leftLineBg="#FFFFFF"
        showNumberInput={true}
        numberInputPlaceholder={t("TCuuXTtXBYeAldQkZp").replace(
          "%S%",
          min_amount || ""
        )}
        inputValue={amount}
        inputContentUnit={currency}
        numberTopDesc={`${balance} ${currency}`}
        numberBottomDesc={fee ? `${fee} ${currency}` : ""}
        numberErrortext={amountInputError()}
        numberInputOnchange={getInputAmount}
        fillInAll={clickAllbtn}
      />
      <CommonBtnDom
        btnText={t("MutPBZAXykjaIurt")}
        noCanClick={!canNext()}
        marginTop="53px"
        clickEvent={clickNext}
      />
    </>
  );
};

InsideWithdrawPage.propTypes = {
  currency: PropTypes.string,
  balance: PropTypes.string,
  netAboutInfo: PropTypes.object,
  fee: PropTypes.string,
  amount: PropTypes.string,
  address: PropTypes.string,
  updateAddressEvent: PropTypes.func,
  updateMountEvent: PropTypes.func,
  backEvent: PropTypes.func,
  showLoadingEvent: PropTypes.func,
  getPayId: PropTypes.func,
};

export default InsideWithdrawPage;
