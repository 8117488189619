import request from "@/hooks/useRequest";
import {
  GETCURRENCYLIST,
  GETFIATDEPOSITCREATE,
  GETFIATDEPOSITLAYOUT,
  GETRECEIPTLAYOUT,
} from "@/helpers/constants/requestApi";

// 代币信息列表
export const getCurrencyInfoList = (params) => {
  return request(GETCURRENCYLIST, { params });
};

// 请求收款信息
export const getReceiptInfo = (params) => {
  return request(GETRECEIPTLAYOUT, { params });
};

// 获取银行充值信息
export const getDepositInfo = (params) => {
  return request(GETFIATDEPOSITLAYOUT, { params });
};

// 创建银行充值凭证
export const getDepositCreate = (body) => {
  return request(GETFIATDEPOSITCREATE, { body });
};
