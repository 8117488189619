// import HomePage from "@/pages/home";
import DepositPage from "@/pages/deposit";
import WithdrawPage from "@/pages/withdraw";
import RegisterPage from "@/pages/register";
import WalletPage from "@/pages/wallet";
import LoginPage from "@/pages/login";
import AmountApply from "@/pages/amountApply";
import ResetAccountInfo from "@/pages/resetAccountInfo";
import AccountManager from "@/pages/accountManager";
import BindGA from "./pages/bindGA";
import HistoryBill from "@/pages/historyBill";
import User from "@/pages/mobileMenu/user";
// import Language from "@/pages/mobileMenu/lan";
import ForgetPage from "@/pages/fogetPwd";
// import CCExchangePage from "@/pages/ccExchange";
import Agreement from "@/pages/agreement";
import Privacypolicy from "@/pages/privacypolicy";
import Home from "@/pages/home";
import Transfer from "@/pages/transfer";
import Kyc from "@/pages/newKyc/kycAccount";
import CompanyKyc from "@/pages/newKyc/createCompanyAccount";
import PersonCertificate from "@/pages/newKyc/person/certificate";
import PersonCertificateBaseInfo from "@/pages/newKyc/person/baseInfo";
import PersonCertificateEvaluation from "@/pages/newKyc/person/evaluation";
import PersonCertificateVerify from "@/pages/newKyc/person/verify";
import OrderStatement from "@/pages/statement";
import WhiteAddress from "@/pages/whiteAddress";
import WhiteAddressAdd from "@/pages/whiteAddress/AddAddress";
import VerifyTxAddress from "@/pages/verifyTxAddress";
import CompanyKycInfo from "@/pages/newKyc/company";
import LegalTender from "@/pages/legalTender";
import LegalTenderAdd from "@/pages/legalTender/legalTenderAdd";
import InfoPerodic from "@/pages/perodic";

import {
  createBrowserRouter,
  // createHashRouter,
  ScrollRestoration,
} from "react-router-dom";

import {
  BIXIN_WALLET,
  BIXIN_WALLET_DEPOSIT,
  BIXIN_WALLET_WITHDRAW,
  BIXIN_REGISTER,
  BIXIN_LOGIN,
  BIXIN_AMOUNTAPPLY,
  BIXIN_RESETACCOUNTINFO,
  BIXIN_ACCOUNTMANAGER,
  BIXIN_KYC,
  BIXIN_GA,
  BIXIN_WALLET_BILL,
  BIXIN_USERCENTER,
  BIXIN_FORGETPWD,
  // BIXIN_CC_EXCHANGE,
  BIXIN_AGREEMENT,
  BIXIN_PRIVACYPLICE,
  BIXIN_TRANSFER,
  BIXIN_COMPANY_KYC,
} from "@/helpers/constants/routeName";

export const AppRoutes = () => {
  const element = [
    {
      path: "/",
      element: <Home />,
    },
    // {
    //   path: BIXIN_CC_EXCHANGE,
    //   element: <CCExchangePage />,
    // },
    {
      path: BIXIN_WALLET_BILL,
      element: <HistoryBill />,
    },
    {
      path: BIXIN_REGISTER,
      element: <RegisterPage />,
    },
    {
      path: BIXIN_LOGIN,
      element: <LoginPage />,
    },
    {
      path: BIXIN_FORGETPWD,
      element: <ForgetPage />,
    },

    {
      path: BIXIN_WALLET_WITHDRAW,
      element: <WithdrawPage />,
    },
    {
      path: BIXIN_WALLET_DEPOSIT,
      element: <DepositPage />,
    },
    {
      path: BIXIN_WALLET,
      element: <WalletPage />,
    },
    {
      path: BIXIN_AMOUNTAPPLY,
      element: <AmountApply />,
    },
    {
      path: BIXIN_RESETACCOUNTINFO,
      element: <ResetAccountInfo />,
    },
    {
      path: BIXIN_ACCOUNTMANAGER,
      element: <AccountManager />,
    },

    {
      path: BIXIN_GA,
      element: <BindGA />,
    },
    {
      path: BIXIN_USERCENTER,
      element: <User />,
    },
    {
      path: BIXIN_AGREEMENT,
      element: <Agreement />,
    },
    {
      path: BIXIN_PRIVACYPLICE,
      element: <Privacypolicy />,
    },
    {
      path: BIXIN_TRANSFER,
      element: <Transfer />,
    },
    {
      path: BIXIN_KYC,
      element: <Kyc />,
    },
    {
      path: BIXIN_COMPANY_KYC,
      element: <CompanyKyc />,
    },
    {
      path: "/trade/personKyc/certificate",
      element: <PersonCertificate />,
    },
    {
      path: "/trade/personKyc/baseInfo",
      element: <PersonCertificateBaseInfo />,
    },
    {
      path: "/trade/personKyc/evaluation",
      element: <PersonCertificateEvaluation />,
    },
    {
      path: "/trade/personKyc/verify",
      element: <PersonCertificateVerify />,
    },
    {
      path: "/trade/OrderStatement",
      element: <OrderStatement />,
    },
    {
      path: "/trade/whiteAddress",
      element: <WhiteAddress />,
    },
    {
      path: "/trade/whiteAddress/add",
      element: <WhiteAddressAdd />,
    },
    {
      path: "/trade/verify/txAddress",
      element: <VerifyTxAddress />,
    },
    {
      path: "/trade/companyKyc/info",
      element: <CompanyKycInfo />,
    },
    {
      path: "/trade/legal/tender",
      element: <LegalTender />,
    },
    {
      path: "/trade/legal/tender/add",
      element: <LegalTenderAdd />,
    },
    {
      path: "/trade/kycInfo/periodic",
      element: <InfoPerodic />,
    },
  ];

  return element;
};
const createScrollRestore = (routes) =>
  routes.map(({ element, ...rest }) => ({
    ...rest,
    element: (
      <>
        <ScrollRestoration />
        {element}
      </>
    ),
  }));

export const router = createBrowserRouter(createScrollRestore(AppRoutes()));
