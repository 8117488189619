import { useState, useEffect, useMemo } from "react";
import { useI18n } from "@/hooks/useI18n";
import { getUserInfo, postUpdateUserInfo } from "./api";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "@/state/user/reducer";
import { useAppSelector } from "@/state/hooks";
import { toastTip } from "@/components/ui/toast";
import { getAreaList } from "./api";

const usePersonalHooks = () => {
  const { t } = useI18n();
  const [checkList, setCheckList] = useState([1, 2]);
  const [userInfo, setUserInfo] = useState({});
  const lang = useAppSelector((state) => state.user.language);
  const storeUserInfo = useAppSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  const [electronicBill, setElectronicBill] = useState(false);
  const [canEditEmailAddress, setCanEditEmailAddress] = useState(false);
  const [countryList, setCountryList] = useState([]);

  const getAllAreaList = async () => {
    const res = await getAreaList();
    if (res.code === 0) {
      const _countryList = [...res.data.areas];
      setCountryList(_countryList);
    }
  };

  const formatAddress = useMemo(() => {
    const item = countryList.filter(
      (d) => d.iso_code2 === userInfo?.address
    )?.[0];
    return lang === "en" ? item?.en_name : item?.zh_name;
  }, [userInfo, countryList, lang]);

  useEffect(() => {
    getUserInfoFn();
    getAllAreaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const memoEmailAddressList = useMemo(() => {
    return [
      {
        text: t("lvvtaxnLzNOtFCZllw"),
        type: 1,
      },
      {
        text: t("XUWTeclWoXtMrQkJzuuq"),
        type: 2,
      },
    ];
  }, [t]);

  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      setUserInfo(res.data);
      const { is_electronic_bill } = res.data;
      setElectronicBill(is_electronic_bill);
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };

  const changeUserInfoProp = (key, e) => {
    const value = e.target.value;
    setUserInfo((prev) => {
      return Object.assign({}, prev, { [key]: value });
    });
  };

  const updateUserInfoProp = async ({ type }) => {
    let params = {};
    if (type === "email_bill") {
      params = {
        is_electronic_bill: electronicBill,
        // 由于后台给的mail_address是一定存在的
        // 即使用户不传也要给默认的
        mail_address: userInfo.mail_address || storeUserInfo.mail_address,
      };
    } else {
      params = {
        [type]: userInfo[type],
      };
    }
    if (Object.keys(params).length > 0) {
      const res = await postUpdateUserInfo(params);
      if (res.code === 0 && res.data.ok) {
        toastTip(t("MThZOMtyoBvwpuodWEa"));
        await getUserInfoFn();
        setCanEditEmailAddress(false);
      }
    }
  };

  return {
    memoEmailAddressList,
    checkList,
    setCheckList,
    userInfo,
    setElectronicBill,
    electronicBill,
    canEditEmailAddress,
    setCanEditEmailAddress,
    changeUserInfoProp,
    updateUserInfoProp,
    countryList,
    formatAddress,
  };
};

export { usePersonalHooks };
