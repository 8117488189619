import { Flex, Box, Text, Image } from "@chakra-ui/react";
import PageWrap from "@/components/PageWrap";
import { useI18n } from "@/hooks/useI18n";
import Progress from "./Progress";
import { useMedia } from "@/hooks/useMedia";
import { useCommonHooks } from "./hook";
import { useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import "./tool-tip.scss";
import { useState, useEffect } from "react";
import { useAppSelector } from "@/state/hooks";

const PersonCertificate = () => {
  useCommonHooks();
  const navigate = useNavigate();
  const { t } = useI18n();
  const { isMobile } = useMedia();
  const [downLink, setDownLink] = useState("");
  const _lang = useAppSelector((state) => state.user.language);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const maps = {
      zh: "https://support.bixin.com/hc/zh-hk/articles/25552474227859-%E5%A6%82%E4%BD%95%E9%80%B2%E8%A1%8C%E5%80%8B%E4%BA%BA%E8%BA%AB%E4%BB%BD%E8%AA%8D%E8%AD%89",
      tw: "https://support.bixin.com/hc/zh-hk/articles/25552474227859-%E5%A6%82%E4%BD%95%E9%80%B2%E8%A1%8C%E5%80%8B%E4%BA%BA%E8%BA%AB%E4%BB%BD%E8%AA%8D%E8%AD%89",
      en: "https://support.bixin.com/hc/en-hk/articles/25552667807507-How-to-Perform-Individual-Identity-Verification",
    };
    if (_lang) {
      setDownLink(maps[_lang]);
    }
  }, [_lang]);

  return (
    <PageWrap>
      <Box
        backgroundColor={"#fff"}
        overflow={"hidden"}
        minHeight={"100vh"}
        pb={"80px"}
      >
        {isMobile && (
          <Box
            mt="30px"
            mb="20px"
            w="112px"
            h="40px"
            bg="rgba(32, 26, 85, 0.05)"
            borderRadius={"0px 32px 32px 0px"}
          >
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              w="112px"
              h="40px"
            >
              <ArrowBackIcon />
              <Text fontSize="14px" onClick={() => navigate("/trade/kyc")}>
                {t("WbPTckCFMKtPHgAlL")}
              </Text>
            </Flex>
          </Box>
        )}

        {!isMobile && (
          <Box position={"absolute"} left={"10%"} top={"100px"}>
            <Box
              w="112px"
              h="40px"
              bg="rgba(32, 26, 85, 0.05)"
              borderRadius={"32px"}
              cursor={"pointer"}
              _hover={{ background: "#E2E8F0" }}
            >
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                w="112px"
                h="40px"
                onClick={() => navigate("/trade/kyc")}
              >
                <ArrowBackIcon />
                <Text fontSize="14px">{t("WbPTckCFMKtPHgAlL")}</Text>
              </Flex>
            </Box>
          </Box>
        )}

        <Flex
          justifyContent={"center"}
          mt={["40px", "100px"]}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Flex
            w={["80%", "696px"]}
            justifyContent={isMobile ? "flex-start" : "center"}
            alignItems={"center"}
          >
            <Text fontSize={"32px"} fontWeight={600}>
              {t("rvLRPuoiEpNqnbxwWj")}
            </Text>
            <div
              className={"person-cerificate-tool-wrap"}
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
            >
              <Image
                className="person-cerificate-tool-image"
                src={"../../../wwwsite/images/kyc/question.svg"}
                style={{ marginLeft: "5px" }}
              ></Image>
              {isOpen && (
                <div className="person-cerificate-tool-tip">
                  <span
                    className="view"
                    onClick={() => window.open(downLink, "_blank")}
                  >
                    {t("YrkgVnAQcYRhGGRex")}
                  </span>
                  <span>{t("WINbdlmnRMSyZUqkxCP")}</span>
                </div>
              )}
            </div>
          </Flex>
          {/* 基本信息 */}
          <Box
            w={["80%", "696px"]}
            padding="40px"
            border="1px solid #E3E4F1"
            borderRadius={"16px"}
            mt={"40px"}
          >
            <Box>
              <Text fontSize={"18px"} color={"#010313"} fontWeight={600}>
                {t("CRMoLKUrfyLkDWFEuSu")}
              </Text>
              <Box>
                <Progress step={"stepOne"} />
              </Box>
            </Box>
          </Box>
          {/* 测评 */}
          <Box
            w={["80%", "696px"]}
            padding="40px"
            border="1px solid #E3E4F1"
            borderRadius={"16px"}
            mt={"40px"}
          >
            <Box>
              <Text fontSize={"18px"} color={"#010313"} fontWeight={600}>
                {t("fQOFawXqhywMOGSe")}
              </Text>
              <Box>
                <Progress step={"stepTwo"} />
              </Box>
            </Box>
          </Box>
          {/* 验证 */}
          <Box
            w={["80%", "696px"]}
            padding="40px"
            border="1px solid #E3E4F1"
            borderRadius={"16px"}
            mt={"40px"}
          >
            <Box>
              <Text fontSize={"18px"} color={"#010313"} fontWeight={600}>
                {t("XjTUjttgmruHnxFXgVf")}
              </Text>
              <Box>
                <Progress step={"stepThree"} />
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </PageWrap>
  );
};

export default PersonCertificate;
