import { useEffect, useState } from "react";
import PageWrap from "@/components/PageWrap";
import { useI18n } from "@/hooks/useI18n";
import { Box, Center, Text, Image, Flex } from "@chakra-ui/react";
import InvestmentRisk from "./InvestmentRisk";
import VirtualAssetsTest from "./VirtualAssetsTest";
import PiEvaluation from "./PiEvaluation";
import { useAppSelector } from "@/state/hooks";
import { useCommonHooks } from "./hooks";

const Evaluation = () => {
  useCommonHooks();
  const { t } = useI18n();
  const [step, setStep] = useState(5);
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);
  const userInfo = useAppSelector((state) => state.user.userInfo);

  useEffect(() => {
    if (step !== 7) {
      let currStepIndex = "";
      if (kycInfo?.length > 0) {
        // 未C3认证情况下 去找到最开始不是APPROVED
        // 这里只看第五步和第六步 进入第七步要从第六步的结果页去进入
        const kycInfoList = kycInfo.slice(4, 6);
        for (let i = 0; i < kycInfoList.length; i++) {
          if (kycInfoList[i].status !== "APPROVED") {
            currStepIndex = i;
            break;
          }
        }
        setStep(kycInfoList[currStepIndex]?.step || 6);
      }
    }
  }, [kycInfo, userInfo]);

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        pt={"60px"}
      >
        <Flex
          w={["80%", "460px"]}
          flexDirection={"column"}
          margin="0 auto"
          alignItems={"center"}
        >
          <Center>
            <Image src="../../wwwsite/images/kyc/evaluation.svg" />
            <Text fontSize={32} fontWeight={700} ml={"10px"}>
              {t("fQOFawXqhywMOGSe")}
            </Text>
          </Center>
          {step === 5 && <InvestmentRisk setStep={setStep} />}
          {step === 6 && <VirtualAssetsTest setStep={setStep} />}
          {step === 7 && <PiEvaluation />}
        </Flex>
      </Box>
    </PageWrap>
  );
};

export default Evaluation;
