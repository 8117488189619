// 中低高风险页面

import { Box, Text, Center, Image, Flex } from "@chakra-ui/react";
import { useHooks } from "./hooks";
import { useI18n } from "@/hooks/useI18n";
import { useEffect, useMemo } from "react";
import BXNSelect from "@/components/ui/WSelect";
import { useAppSelector } from "@/state/hooks";
import { BXInput, BXButton } from "@/styles";
import useConstantsHooks from "../../common/constantsHooks";
import { RISK_LEVEL } from "@/constants/kyc";
import BXLoading from "@/components/ui/pay-loading";
import ConfirmModal from "../../common/ConfirmModal";
import BXUpload from "@/components/ui/BXUpload";
import { toastTip } from "@/components/ui/toast";
import PersonResult from "../../common/PersonResult";

const PersonalInfoChangeInitPage = ({ riskLevel, reviewUUID }) => {
  const { t } = useI18n();
  const _lang = useAppSelector((state) => state.user.language);
  const {
    inputParams,
    inputOnChange,
    editAllow,
    sanctionsCountryList,
    noCompliantCountryList,
    allCountryList,
    areaScanError,
    areaCompliantError,
    otherCountryAreaError,
    setOtherCountryAreaError,
    setAreaScanError,
    setAreaCompliantError,
    setEditAllow,
    setInputParams,
    handleMultipleCheck,
    editTradeInfoAllow,
    setEditTradeInfoAllow,
    handleUploadOtherCountryIdCardFile,
    richApproveList,
    canSubmit,
    handleRichApproveFile,
    handleUoloadEsFile,
    uploadLoading,
    onSubmit,
    memoMustUploadIdFile,
    memoMustUploadResAddressFile,
    setShowTipModal,
    showTipModal,
    pageStatus,
    handleCancel,
    handleSave,
    showOtherCountryBlock,
  } = useHooks({ riskLevel, reviewUUID });

  const {
    memoCardType,
    memoFundSource,
    memoHasOtherCountryCard,
    memoAnticipatedMonthlyTxFrequency,
    memoWealthSource,
    memoAnticipatedMonthlyTxAmount,
    memoOtherCountryCardType,
  } = useConstantsHooks();
  const {
    id_type,
    has_other_country_id_card,
    other_id_type,
    id_country,
    other_id_country,
    id_copy_files,
    clone_address_files,
    residence_country,
    other_id_opposite_files,
    other_id_front_files,
  } = inputParams;

  const selectOtherCountry = (value) => {
    inputOnChange(value.iso_code2, "other_id_country");
    setOtherCountryAreaError(false);
    // 在制裁区
    if (sanctionsCountryList.indexOf(value.iso_code2) > -1) {
      setOtherCountryAreaError(true);
      return;
    }
    // 非合规区
    if (noCompliantCountryList.indexOf(value.iso_code2) > -1) {
      setOtherCountryAreaError(true);
      return;
    }
  };

  const memoCountryList = useMemo(() => {
    if (allCountryList && allCountryList.length > 0) {
      return allCountryList.map((d) => {
        return {
          ...d,
          label: d.zh_name,
          value: d.value,
        };
      });
    }
  }, [allCountryList]);

  //证件发行地国家
  const defaultCountry = useMemo(() => {
    if (memoCountryList?.length > 0) {
      if (id_country) {
        return memoCountryList.filter((d) => d.iso_code2 === id_country)[0];
      }
    }
  }, [id_country, memoCountryList]);

  // 其他证件发行地国家
  const defaultOtherCountry = useMemo(() => {
    if (memoCountryList?.length > 0) {
      if (other_id_country) {
        return memoCountryList.filter(
          (d) => d.iso_code2 === other_id_country
        )[0];
      }
    }
  }, [other_id_country, memoCountryList]);

  // 设置 其他证件发行地国家证件
  const defaultOtherCountryCardType = useMemo(() => {
    if (memoOtherCountryCardType?.length > 0) {
      if (other_id_type) {
        return memoOtherCountryCardType.filter(
          (d) => d.value === other_id_type
        )[0];
      }
    }
  }, [other_id_type]);

  // 常居地
  const memoResidenceCountry = useMemo(() => {
    if (allCountryList?.length > 0) {
      if (id_type === "ID_CARD") {
        const hk_opt = memoCountryList?.filter((d) => d.iso_code2 === "HK")[0];
        if (hk_opt) {
          if (_lang === "zh")
            return `${hk_opt?.zh_name} (+${hk_opt?.dial_code}) `;
          return `${hk_opt?.en_name} (+${hk_opt?.dial_code}) `;
        }
      }

      const _country = other_id_country || id_country;
      if (_country) {
        const item = allCountryList.filter((d) => d.iso_code2 === _country)[0];
        if (item) {
          if (_lang === "zh") return `${item?.zh_name} (+${item?.dial_code}) `;
          return `${item?.en_name} (+${item?.dial_code}) `;
        }
      }
    }
  }, [allCountryList, _lang, id_country, other_id_country, id_type]);

  useEffect(() => {
    if (id_type === "ID_CARD" && memoCountryList?.length > 0) {
      const hk_opt = memoCountryList?.filter((d) => d.iso_code2 === "HK")[0];
      inputOnChange(hk_opt.iso_code2, "id_country");
      inputOnChange("", "has_other_country_id_card");
      inputOnChange("", other_id_country);
      inputOnChange([], "other_id_front_files");
      inputOnChange([], "other_id_opposite_files");
      inputOnChange("", "other_id_type");
      setAreaCompliantError(false);
      setAreaScanError(false);
    }
  }, [id_type, memoCountryList]);

  // 证件发行地国家
  const selectCountry = (value) => {
    inputOnChange(value.iso_code2, "id_country");
    setAreaScanError(false);
    setAreaCompliantError(false);
    // 在制裁区 + 美国、美国本土外小岛
    if (
      sanctionsCountryList.indexOf(value.iso_code2) > -1 ||
      value.iso_code2 === "US" ||
      value.iso_code2 === "UM"
    ) {
      setAreaScanError(true);
      return;
    }

    // 非合规区
    if (noCompliantCountryList.indexOf(value.iso_code2) > -1) {
      setAreaCompliantError(true);
      return;
    } else {
      // 合规地区
      inputOnChange("", "other_id_country");
    }
  };

  const renderItem = (item) => {
    return (
      <Flex alignItems={"center"} key={item.dial_code}>
        <Text fontSize="12px">
          {`${_lang === "zh" ? item.zh_name : item.en_name}  (+${
            item.dial_code
          })`}
        </Text>
      </Flex>
    );
  };

  const renderOtherCardType = (item) => {
    return (
      <Flex alignItems={"center"}>
        <Text fontSize="12px">{item.label}</Text>
      </Flex>
    );
  };

  // 正反面
  const renderOtherCountryCardFile = (type) => {
    const file_type = type === 1 ? "front" : "opposite";

    const file_value =
      file_type === "front"
        ? other_id_front_files?.[0]?.document_name ||
          other_id_front_files?.[0]?.document_path
        : other_id_opposite_files?.[0]?.document_name ||
          other_id_opposite_files?.[0]?.document_path;

    const file_error =
      file_type === "front"
        ? other_id_front_files?.[0]?.document_error
        : other_id_opposite_files?.[0]?.document_error;

    return (
      <>
        <Text mt={"30px"} color={"rgba(1, 3, 19, 1)"} fontSize={"14px"}>
          {file_type === "front"
            ? t("oSDxsXgENbIeNMLv")
            : t("aoAIqdHabiquSjWWz")}
        </Text>
        <BXUpload
          disabled={!editAllow ? true : false}
          fileName={file_value}
          error={file_error}
          handleUploadChange={(e) => {
            if (editAllow) {
              handleUploadOtherCountryIdCardFile(e, file_type);
            }
          }}
          placeholder={
            file_type === "front"
              ? t("idqLvJqjeiEmQbpa")
              : t("FhkGAkzeRaGMUGCREr")
          }
        ></BXUpload>
      </>
    );
  };

  const renderEditStatusFooter = (judkey, status) => {
    return (
      <Flex justifyContent={"flex-end"} alignItems={"center"} mt={"40px"}>
        {!status && (
          <BXButton
            w={["100%", "150px"]}
            onClick={() => {
              if (judkey === "editAllow") {
                setEditAllow(true);
              } else if (judkey === "editTradeInfoAllow") {
                setEditTradeInfoAllow(true);
              }
            }}
          >
            {t("gDgIYTmDidzeeXyOV")}
          </BXButton>
        )}
        {status && (
          <>
            <BXButton
              className="cancel"
              cursor={"pointer"}
              onClick={() => {
                if (judkey === "editAllow") {
                  setEditAllow(false);
                  handleCancel();
                } else if (judkey === "editTradeInfoAllow") {
                  handleCancel();
                  setEditTradeInfoAllow(false);
                }
              }}
            >
              {t("UNForxvjDLCZdGrmWkX")}
            </BXButton>
            <BXButton
              w={["80%", "160px"]}
              ml={"10px"}
              onClick={() => {
                if (judkey === "editAllow") {
                  if (handleSave()) {
                    setEditAllow(false);
                  }
                } else if (judkey === "editTradeInfoAllow") {
                  if (handleSave()) {
                    setEditTradeInfoAllow(false);
                  }
                }
              }}
            >
              {t("GSkKAUsvGPYZMAnFnH")}
            </BXButton>
          </>
        )}
      </Flex>
    );
  };

  return (
    <>
      {pageStatus === "RESULT" && <PersonResult />}
      {pageStatus === "INIT" && (
        <Box w={["80%", "400px"]} margin={"0 auto"} mt={"40px"}>
          {uploadLoading && <BXLoading />}
          <Center>
            <Image src="../../wwwsite/images/kyc/user.svg" />
            <Text fontSize={32} fontWeight={700} ml={"10px"}>
              {t("onOjgIPTpDDXLIwlSWE")}
            </Text>
          </Center>
          {/* 基本信息 */}
          <Box
            mt={"50px"}
            borderBottom={"1px solid rgba(227, 228, 241, 1)"}
            paddingBottom={"20px"}
          >
            {/* 证件类型 */}
            <Flex mt={"30px"} flexDirection={"column"}>
              <Text fontSize="14px" fontWeight={600}>
                {t("VALtZMIyzODbkpPUOsxG")}
              </Text>
              <Flex mt={"10px"}>
                {memoCardType.map((d, i) => {
                  return (
                    <Flex ml={i === 1 && "40px"} key={i}>
                      <Image
                        onClick={() => {
                          if (editAllow) {
                            inputOnChange(d.value, "id_type");
                            if (d.value === "ID_CARD") {
                              inputOnChange("", "other_id_country");
                            }
                          }
                        }}
                        cursor={editAllow ? "pointer" : "not-allowed"}
                        src={`../../wwwsite/images/kyc/radio${
                          inputParams.id_type === d.value ? "_checked" : ""
                        }.svg`}
                      />
                      <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                        {d.label}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
            {/* 证件号码 */}
            <Text color={"#010313"} fontSize={"14px"} mt={"20px"}>
              {t("IzXQAIEgQLMYmZwrzP")}
            </Text>
            <BXInput
              maxLength={100}
              disabled={!editAllow ? true : false}
              value={inputParams.id_number}
              onChange={(e) => inputOnChange(e.target.value, "id_number")}
            />
            {/* 证件发行地 */}
            <Text color={"#010313"} fontSize={"14px"} mt={"20px"}>
              {t("xjuVMsJZTRgjPgAzSV")}
            </Text>
            <Box mt={"15px"}>
              <BXNSelect
                disabled={
                  !editAllow || inputParams.id_type === "ID_CARD" ? true : false
                }
                options={memoCountryList}
                defaultOpt={defaultCountry}
                renderLabel={(item) => renderItem(item)}
                renderItem={(item) => renderItem(item)}
                handleSelect={(item) => {
                  selectCountry(item);
                }}
              ></BXNSelect>
              {areaScanError && (
                <Text color={"#D04457"} fontSize={"14px"} mt={"5px"}>
                  {t("mzWsFtJNWfexuuUgYctv")}
                </Text>
              )}
            </Box>
            {showOtherCountryBlock && (
              <Box
                bg={"rgba(250, 250, 250, 1)"}
                borderRadius={"12px"}
                border={"1px solid rgba(227, 228, 241, 1)"}
                padding={"20px 15px"}
                mt={"10px"}
              >
                <Flex flexDirection={"column"}>
                  <Text fontWeight={400} fontSize={"14px"}>
                    {t("zLnEBdSfUXdqbRqAohY")}
                  </Text>
                  <Flex mt={"10px"}>
                    {memoHasOtherCountryCard.map((d, i) => {
                      return (
                        <Flex ml={i === 1 && "40px"} key={i}>
                          <Image
                            onClick={() => {
                              if (editAllow) {
                                inputOnChange(d, "has_other_country_id_card");
                                setOtherCountryAreaError(false);
                                inputOnChange("", "other_id_country");
                              }
                            }}
                            cursor={editAllow ? "pointer" : "not-allowed"}
                            src={`../../wwwsite/images/kyc/radio${
                              Number(has_other_country_id_card?.value) ===
                              Number(d.value)
                                ? "_checked"
                                : ""
                            }.svg`}
                          />
                          <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                            {d.label}
                          </Text>
                        </Flex>
                      );
                    })}
                  </Flex>
                  {Number(has_other_country_id_card?.value) === 2 && (
                    <Text color={"#D04457"} fontSize={"14px"} mt={"15px"}>
                      {t("mzWsFtJNWfexuuUgYctv")}
                    </Text>
                  )}
                </Flex>
                {Number(has_other_country_id_card?.value) === 1 && (
                  <Flex mt={"20px"} flexDirection={"column"}>
                    <Text fontSize={"14px"} fontWeight={400}>
                      {t("oVvhpgfhTeyLLnTSv")}
                    </Text>
                    <Text
                      fontSize="12px"
                      fontWeight={400}
                      mt={"10px"}
                      color={"rgba(0, 0, 0, 0.49)"}
                    >
                      {t("OEfurFwwcJaTsVxNdol")}
                    </Text>
                    <Flex mt={"30px"} flexDirection={"column"}>
                      <Text fontSize="14px" fontWeight={400}>
                        {t("xjuVMsJZTRgjPgAzSV")}
                      </Text>
                      <Box mt={"15px"}>
                        <BXNSelect
                          style={{ background: "#fff", borderRadius: "12px" }}
                          options={memoCountryList}
                          defaultOpt={defaultOtherCountry}
                          renderLabel={(item) => renderItem(item)}
                          renderItem={(item) => renderItem(item)}
                          disabled={!editAllow ? true : false}
                          handleSelect={(item) => {
                            selectOtherCountry(item);
                          }}
                        ></BXNSelect>
                        {otherCountryAreaError && (
                          <Text color={"#D04457"} fontSize={"14px"} mt={"15px"}>
                            {t("mzWsFtJNWfexuuUgYctv")}
                          </Text>
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                )}

                {!otherCountryAreaError &&
                  Number(has_other_country_id_card?.value) === 1 &&
                  inputParams?.other_id_country && (
                    <>
                      <Flex flexDirection={"column"} mt={"40px"}>
                        <Text fontSize="14px" fontWeight={400}>
                          {t("hoTQEpJFfRRLZGLWiDK")}
                        </Text>
                        <Box mt={"15px"}>
                          {/* 其他国家证件类型 */}
                          <BXNSelect
                            style={{ background: "#fff", borderRadius: "12px" }}
                            options={memoOtherCountryCardType}
                            defaultOpt={defaultOtherCountryCardType}
                            renderLabel={(item) => renderOtherCardType(item)}
                            renderItem={(item) => renderOtherCardType(item)}
                            disabled={!editAllow ? true : false}
                            handleSelect={(item) => {
                              inputOnChange(item.value, "other_id_type");
                              // selectOtherCountry(item);
                              // selectOtherCardType(it)
                            }}
                          ></BXNSelect>
                        </Box>
                      </Flex>
                      <Text
                        mt={"30px"}
                        color={"rgba(1, 3, 19, 1)"}
                        fontSize={"14px"}
                      >
                        {t("wZuMVSBUSlQidCQpjoS")}
                      </Text>
                      {renderOtherCountryCardFile(1)}
                      {renderOtherCountryCardFile(2)}
                    </>
                  )}
              </Box>
            )}
            {/* 更新证件信息 */}
            {(id_copy_files?.[0]?.document_path || editAllow) && (
              <Box mt={"30px"}>
                <Text fontSize={"16px"} color={"#010313"} fontWeight={700}>
                  {t("cOOoOlxMURFANNpFpYx")}
                </Text>
                <Text fontSize={"14px"} color={"#010313"} mt={"15px"}>
                  {
                    memoCardType?.filter(
                      (d) => d.value === inputParams?.id_type
                    )[0]?.label
                  }
                </Text>

                <BXUpload
                  style={{ marginTop: "15px" }}
                  handleUploadChange={(e) => {
                    if (editAllow) {
                      handleUoloadEsFile({
                        e: e,
                        type: "ID_COPY_FILE",
                      });
                    }
                  }}
                  fileName={id_copy_files?.[0]?.document_name}
                  placeholder={memoMustUploadIdFile && t("idqLvJqjeiEmQbpa")}
                  error={id_copy_files?.[0]?.document_error}
                  disabled={!editAllow}
                />
              </Box>
            )}

            {/* 常居地 */}
            <Text fontSize="14px" color="t.100" mt="20px">
              {t("gYpQegnebgwwccEuM")}
            </Text>
            <BXInput maxLength={100} disabled value={memoResidenceCountry} />
            {/* 常居地址 */}
            <Text color={"#010313"} fontSize={"14px"} mt={"20px"}>
              {t("HQSjnUOWYQPuvvTpu")}
            </Text>
            <BXInput
              maxLength={100}
              disabled={!editAllow ? true : false}
              value={inputParams.residence_address}
              onChange={(e) =>
                inputOnChange(e.target.value, "residence_address")
              }
            />

            {(clone_address_files?.[0]?.document_path || editAllow) && (
              <Box mt={"30px"}>
                <Text fontSize={"16px"} color={"#010313"} fontWeight={700}>
                  {t("nAVsbekWuHffflMnfHHy")}
                </Text>
                <BXUpload
                  style={{ marginTop: "15px" }}
                  handleUploadChange={(e) => {
                    if (editAllow) {
                      handleUoloadEsFile({
                        e: e,
                        type: "RESIDENCE_ADDRESS_FILE",
                      });
                    }
                  }}
                  disabled={!editAllow}
                  fileName={clone_address_files?.[0]?.document_name}
                  placeholder={
                    memoMustUploadResAddressFile && t("idqLvJqjeiEmQbpa")
                  }
                  error={clone_address_files?.[0]?.document_error}
                  errorText={t("dLTuZclgDrLDUToEe")}
                />
              </Box>
            )}

            {renderEditStatusFooter("editAllow", editAllow)}
          </Box>
          {/* 交易信息 */}
          <Box
            borderBottom={
              riskLevel === RISK_LEVEL.HIGH
                ? "1px solid rgba(227, 228, 241, 1)"
                : ""
            }
            paddingBottom={"20px"}
          >
            {/* 预估月交易额 */}
            <Flex mt={"30px"} flexDirection={"column"}>
              <Text fontWeight={500}> {t("ZCRfudjxgEkJLycN")}</Text>
              <Flex flexWrap={"wrap"} mt={"10px"}>
                {memoAnticipatedMonthlyTxAmount.map((d, i) => {
                  return (
                    <Flex mr={"20px"} mb={"15px"} key={i}>
                      <Image
                        onClick={() => {
                          if (editTradeInfoAllow) {
                            setInputParams((prev) => {
                              const currObj = prev.monthly_turnover;
                              return Object.assign({}, prev, {
                                monthly_turnover: {
                                  ...currObj,
                                  options: [d],
                                },
                              });
                            });
                          }
                        }}
                        src={`../../wwwsite/images/kyc/radio${
                          inputParams?.monthly_turnover?.options?.findIndex(
                            (x) => x.value === d.value
                          ) > -1
                            ? "_checked"
                            : ""
                        }.svg`}
                        cursor={editTradeInfoAllow ? "pointer" : "not-allowed"}
                      />
                      <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                        {d.label}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
            {/* 预估月均交易频率 */}
            <Flex mt={"30px"} flexDirection={"column"}>
              <Text fontWeight={500}> {t("JJAtBenOLOlFaFaP")}</Text>
              <Flex mt={"10px"} flexDirection={"column"}>
                {memoAnticipatedMonthlyTxFrequency.map((d, i) => {
                  const imgSrc = `../../wwwsite/images/kyc/radio${
                    inputParams?.monthly_frequency?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "_checked"
                      : ""
                  }.svg`;
                  return (
                    <Flex flexDirection={"column"} key={i}>
                      <Flex mb={"10px"}>
                        <Image
                          onClick={() => {
                            if (editTradeInfoAllow) {
                              setInputParams((prev) => {
                                const currObj = prev.monthly_frequency;
                                return Object.assign({}, prev, {
                                  monthly_frequency: {
                                    ...currObj,
                                    options: [d],
                                  },
                                });
                              });
                            }
                          }}
                          cursor={
                            editTradeInfoAllow ? "pointer" : "not-allowed"
                          }
                          src={imgSrc}
                        />
                        <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                          {d.label}
                        </Text>
                      </Flex>
                      {inputParams?.monthly_frequency?.options?.findIndex(
                        (d) => d.value === 1
                      ) > -1 &&
                        i === 0 && (
                          <BXInput
                            placeholder={`${t("WjPizXTxbdPnvLWMql")}  (${t(
                              "idqLvJqjeiEmQbpa"
                            )})`}
                            mt="5px"
                            mb={"15px"}
                            maxLength={100}
                            value={inputParams?.monthly_frequency?.description}
                            onChange={(e) => {
                              if (editTradeInfoAllow) {
                                setInputParams((prev) => {
                                  const currObj = prev.monthly_frequency;
                                  return Object.assign({}, prev, {
                                    monthly_frequency: {
                                      ...currObj,
                                      description: e.target.value,
                                    },
                                  });
                                });
                              }
                            }}
                          />
                        )}
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
            {/* 财富来源 多选 */}
            <Flex mt={"40px"} flexDirection={"column"}>
              <Text fontWeight={500}>{t("lfjjwhqpvFBikfhkifrN")}</Text>
              <Flex flexWrap={"wrap"} mt={"10px"}>
                {memoWealthSource.map((d, i) => {
                  return (
                    <Flex mr={"20px"} mb={"15px"} key={i}>
                      <Image
                        cursor={editTradeInfoAllow ? "pointer" : "not-allowed"}
                        onClick={() => {
                          if (editTradeInfoAllow) {
                            handleMultipleCheck(d, "wealth_source");
                          }
                        }}
                        src={`../../wwwsite/images/kyc/${
                          inputParams?.wealth_source?.options?.findIndex(
                            (x) => x.value === d.value
                          ) > -1
                            ? "checked"
                            : "no_check"
                        }.svg`}
                      />
                      <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                        {d.label}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
              {inputParams?.wealth_source?.options?.findIndex(
                (d) => d.value === 6
              ) > -1 && (
                <BXInput
                  maxLength={100}
                  value={inputParams?.wealth_source?.description}
                  mt="5px"
                  placeholder={`${t("WjPizXTxbdPnvLWMql")}  (${t(
                    "idqLvJqjeiEmQbpa"
                  )})`}
                  onChange={(e) => {
                    if (editTradeInfoAllow) {
                      inputOnChange(e.target.value, "wealth_source");
                    }
                  }}
                />
              )}
            </Flex>
            {/* 资金来源 多选*/}
            <Flex mt={"30px"} flexDirection={"column"}>
              <Text fontWeight={500}>{t("DSFAJhsSefQQEdBBlUyt")}</Text>
              <Flex flexWrap={"wrap"} mt={"10px"}>
                {memoFundSource.map((d, i) => {
                  return (
                    <Flex mr={"20px"} mb={"15px"} key={i}>
                      <Image
                        cursor={editTradeInfoAllow ? "pointer" : "not-allowed"}
                        onClick={() => {
                          if (editTradeInfoAllow) {
                            handleMultipleCheck(d, "fund_source");
                          }
                        }}
                        src={`../../wwwsite/images/kyc/${
                          inputParams?.fund_source?.options?.findIndex(
                            (x) => x.value === d.value
                          ) > -1
                            ? "checked"
                            : "no_check"
                        }.svg`}
                      />
                      <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                        {d.label}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
              {inputParams?.fund_source?.options?.findIndex(
                (d) => d.value === 5
              ) > -1 && (
                <BXInput
                  maxLength={100}
                  mt="5px"
                  placeholder={`${t("WjPizXTxbdPnvLWMql")}  (${t(
                    "idqLvJqjeiEmQbpa"
                  )})`}
                  value={inputParams?.fund_source?.description}
                  onChange={(e) => {
                    if (editTradeInfoAllow) {
                      inputOnChange(e.target.value, "fund_source");
                    }
                  }}
                />
              )}
            </Flex>
            {renderEditStatusFooter("editTradeInfoAllow", editTradeInfoAllow)}
          </Box>

          {/* 财富来源证明 高风险用户才有 */}
          {/* {根据kyc选择渲染列表} */}
          {RISK_LEVEL.HIGH === riskLevel && (
            <Box mt={"20px"}>
              <Text fontSize={"16px"} fontWeight={700} color={"#010313"}>
                {t("wcfFqisAqLRlhbKdJk")}
              </Text>
              <Text color={"#7B7C8F"} fontSize={"14px"} mt={"5px"}>
                {t("wcJAFtgivjbkVSdTC")}
              </Text>
              {richApproveList.map((d, i) => {
                return (
                  <BXUpload
                    key={i}
                    style={{ marginTop: "10px" }}
                    label={t(d.i18nKey)}
                    error={d.error}
                    fileName={d.fileName}
                    handleUploadChange={(e) => {
                      handleRichApproveFile({ e, key: d.key, index: i });
                    }}
                  ></BXUpload>
                );
              })}
            </Box>
          )}

          <ConfirmModal
            show={showTipModal}
            onOk={() => {
              onSubmit();
            }}
            onCancel={() => {
              setShowTipModal(false);
            }}
          />

          <Flex justifyContent={"center"} mt={"50px"} pb={"30px"}>
            <BXButton
              margin={"0 auto"}
              w={["80%", "250px"]}
              onClick={() => {
                // 校验是否都保存
                if (editAllow || editTradeInfoAllow) {
                  toastTip(t("AoqexRnXPjWiaXQQ"), { type: "error" });
                  return;
                }
                if (canSubmit) {
                  setShowTipModal(true);
                }
              }}
              className={!canSubmit ? "disabled" : ""}
            >
              {t("YChcfaxDaLscdSPiRQz")}
            </BXButton>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default PersonalInfoChangeInitPage;
