import { useState, useEffect } from "react";

import PageWrap from "@/components/PageWrap";
import BXLoading from "@/components/ui/pay-loading";
import ComponentBodyUnit from "@/pages/deposit/component/componentBodyUnit";
import { Text, Box, Flex, Image, Button } from "@chakra-ui/react";
import CommonUnitStyle from "@/pages/deposit/component/commonUnitStyle";
import CommonBtnDom from "@/pages/deposit/component/commonBtn";
import ChainWithDrawPage from "@/pages/withdraw/component/chainWithdraw";
import InsideWithdrawPage from "@/pages/withdraw/component/insideWithdraw";
import WithdrawConfirmPage from "@/pages/withdraw/component/confirm";
import WithdrawSafeVerify from "@/pages/withdraw/component/safeVerify";
import WithdrawResultPage from "@/pages/withdraw/component/resultPage";
import BankWithdrawPage from "@/pages/withdraw/component/bankWithdraw";

import { getCurrencyInfoList } from "@/pages/deposit/api";
import {
  getBankWithdrawInfo,
  getChainWithdrawInfo,
  getGasFee,
  getInsideWithdrawInfo,
} from "@/pages/withdraw/api";
import { useI18n } from "@/hooks/useI18n";

import {
  WITHDRAWONE,
  WITHDRAWCHAIN,
  WITHDRAWINSIDE,
  WITHDRAWCONFIRM,
  WITHDRAWVERIFY,
  WITHDRAWRESULTPAGE,
  WITHDRAWBANK,
} from "./helpers/constants/pageTypeName";
import ModalComponent from "@/components/ui/modal";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/state/hooks";

const WithdrawPage = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const userInfo = useAppSelector((state) => state.user.userInfo);

  // 当前显示页面
  const [currentStep, setCurrentStep] = useState(WITHDRAWONE);

  // 提现方式列表
  const [withdrawTypeList, setWithdrawTypeList] = useState([]);

  const [currenyList, setCurrenyList] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState("");
  const [insideSupportCurrencyList, setInsideSupportCurrencyList] = useState([
    "BTC",
    "ETH",
    "USDC",
  ]);

  // 当前选择的提现方式
  const [currentWithdrawType, setCurrentWithdrawType] = useState("");

  const [currentReceiveddress, setCurrentReceiveddress] = useState("");

  // 支持的网络列表
  const [currentNetworkList, setCurrentNetworkList] = useState([]);
  // 获取到的币种余额
  const [currencyBalance, setCurrencyBalance] = useState("");
  // 选择的网络
  const [currentSelectedNetwork, setCurrentSelectedNetwork] = useState("");
  // 当前网络的信息
  const [currentNetInfo, setCurrentNetInfo] = useState({});

  // memo
  const [currentMemo, setCurrentMemo] = useState("");
  // pubkey
  const [currentPubkey, setCurrentPubkey] = useState("");
  // 链号
  const [currentChainNumber, setCurrentChainNumber] = useState("0");

  // 当前gasFee
  const [currentGasFee, setCurrentGasFee] = useState("");

  const [currentAmount, setCurrentAmount] = useState("");

  const [payLoading, setPayLoading] = useState(false);
  const [payId, setPayId] = useState("");
  const [payAmount, setPayAmount] = useState("");
  const [payStatus, setPayStatus] = useState("");
  const [verifyItems, setVerifyItems] = useState([]);
  // withdraw limit
  const [withdrawLimit, setWithdrawLimit] = useState(-1);

  const [currentAccountNo, setCurrentAccountNo] = useState("");

  // 银行转账支持的币种
  const bankSupportCurrencyList = ["HKD", "USD"];
  // 是否是银行转账
  const isBankTransfer = currentWithdrawType === WITHDRAWBANK;
  // 是否认证c3弹窗
  const [showC3Modal, setShowC3Modal] = useState(false);

  // 法币支持的币种
  const fiatCurrencyList = ["HKD", "USD"];

  useEffect(() => {
    InitCurrencyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const InitCurrencyList = async () => {
    var [res, res1] = await Promise.all([
      getCurrencyInfoList({ scene: "withdraw" }),
      getCurrencyInfoList({ scene: "deposit" }),
    ]);
    console.log("代币信息列表返回", res, res1);
    const { code, data, msg } = res;
    if (code === 0 && msg === "OK") {
      let newArr = [];
      data.currency_list.forEach((item) => {
        newArr.push({
          ...item,
          name: item.name,
          selected_key: item.name,
        });
      });
      setCurrenyList(newArr);
    }
    const { code: code1, data: data1, msg: msg1 } = res1;
    if (code1 === 0 && msg1 === "OK") {
      let newArr1 = [];
      data1.currency_list.forEach((item) => {
        newArr1.push(item.name);
      });
      setInsideSupportCurrencyList(newArr1);
    }
  };

  useEffect(() => {
    if (!insideSupportCurrencyList.includes(currentCurrency)) {
      if (currentWithdrawType === WITHDRAWINSIDE) {
        selectedMode(WITHDRAWCHAIN);
      }
    }
    InitWithdrawMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCurrency]);
  // 设置提现方式
  const InitWithdrawMode = () => {
    let newArr = [];
    // newArr = [
    //   {
    //     name: t("BQkFfUTgzkweftGIpOLt"),
    //     selected_key: WITHDRAWCHAIN,
    //   },
    // ];
    if (
      !currentCurrency ||
      insideSupportCurrencyList.includes(currentCurrency)
    ) {
      newArr = [
        {
          name: t("BQkFfUTgzkweftGIpOLt"),
          selected_key: WITHDRAWCHAIN,
        },
        // {
        //   name: t("hDPPfPClruPeWrSoayR"),
        //   selected_key: WITHDRAWINSIDE,
        // },
        {
          name: t("DZjgiswpELVABBqUWCSa"),
          selected_key: WITHDRAWBANK,
        },
      ];
    } else {
      newArr = [
        {
          name: t("BQkFfUTgzkweftGIpOLt"),
          selected_key: WITHDRAWCHAIN,
        },
      ];
    }
    setWithdrawTypeList(newArr);
  };

  useEffect(() => {
    if (currentSelectedNetwork) {
      InitGasFeeInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedNetwork]);
  // 获取手续费
  const InitGasFeeInfo = async () => {
    const { code, data, msg } = await getGasFee({
      currency: currentCurrency,
      network: currentSelectedNetwork,
      is_outside: Boolean(currentWithdrawType === WITHDRAWCHAIN),
      transfer_type: "realtime",
    });
    console.log("手续费", code, data, msg);
    if (code === 0 && msg === "OK") {
      setCurrentGasFee(data.fee);
    }
  };

  // 返回前一页
  const pageLeftTopClickEvent = () => {
    if (currentStep === WITHDRAWCONFIRM) {
      if (currentWithdrawType === WITHDRAWCHAIN) {
        setCurrentStep(WITHDRAWCHAIN);
      } else if (currentWithdrawType === WITHDRAWBANK) {
        setCurrentStep(WITHDRAWBANK);
      } else {
        setCurrentStep(WITHDRAWINSIDE);
      }
    }
  };
  // 选择的币种
  const selectedCurrency = (getKey) => {
    setCurrentCurrency(getKey);
    const withdrawType = bankSupportCurrencyList.includes(getKey)
      ? WITHDRAWBANK
      : "";
    selectedMode(withdrawType);
  };

  // 选择的提现方式
  const selectedMode = (getKey) => {
    setCurrentWithdrawType(getKey);
  };

  const clickBackPrePage = (getValue) => {
    if (getValue === WITHDRAWCHAIN) {
      setCurrentReceiveddress("");
      setCurrentNetworkList([]);
      setCurrencyBalance("");
      setCurrentSelectedNetwork("");
      setCurrentNetInfo({});
      setCurrentMemo("");
      setCurrentPubkey("");
      setCurrentChainNumber("0");
      setCurrentGasFee("");
      setCurrentAmount("");
      setCurrentStep(WITHDRAWONE);
    }
  };

  const updateInputAddress = (getValue) => {
    setCurrentReceiveddress(getValue);
  };

  const updateInputAmount = (getValue) => {
    setCurrentAmount(getValue);
  };

  const updateInputAccountNo = (getValue) => {
    setCurrentAccountNo(getValue);
  };

  // 选择的转账的网络
  const selectedNetworkInfo = (getobject) => {
    const { net, getCurrentNetInfo } = getobject;
    setCurrentGasFee("");
    setCurrentSelectedNetwork(net);
    setCurrentNetInfo(getCurrentNetInfo);
  };

  const clickNext = () => {
    if (canNext()) {
      if (
        userInfo?.kyc_type === "UNAUTHORIZED" &&
        fiatCurrencyList.includes(currentCurrency)
      ) {
        return setShowC3Modal(true);
      }
      if (currentStep === WITHDRAWONE) {
        if (currentWithdrawType === WITHDRAWCHAIN) {
          InitChainWithdrawInfo();
        } else if (currentWithdrawType === WITHDRAWINSIDE) {
          InitInsideWithdrawInfo();
        } else if (currentWithdrawType === WITHDRAWBANK) {
          InitBankWithdrawInfo();
        }
        setCurrentStep(currentWithdrawType);
      }
      if (currentStep === WITHDRAWCONFIRM) {
        if (verifyItems.length > 0) {
          setCurrentStep(WITHDRAWVERIFY);
        } else {
          setCurrentStep(WITHDRAWRESULTPAGE);
        }
      }
      if (currentStep === WITHDRAWVERIFY) {
        setCurrentStep(WITHDRAWRESULTPAGE);
      }
    }
  };
  const canNext = () => {
    if (currentStep === WITHDRAWONE) {
      return Boolean(currentCurrency && currentWithdrawType);
    }
    if (currentStep === WITHDRAWCONFIRM || currentStep === WITHDRAWVERIFY) {
      return Boolean(payId);
    }
  };
  const InitChainWithdrawInfo = async () => {
    const { code, data, msg } = await getChainWithdrawInfo({
      currency: currentCurrency,
    });
    console.log("外币转账页信息", code, data, msg);
    if (code === 0 && msg === "OK") {
      const { currency_network_info, available_balance } = data;
      let newArr = [];
      currency_network_info.forEach((item) => {
        newArr.push({
          ...item,
          name: item.network,
          selected_key: item.network,
        });
      });
      setCurrentNetworkList(newArr);
      setCurrencyBalance(available_balance);
      setWithdrawLimit(data?.available_limit);
    }
  };
  const InitInsideWithdrawInfo = async () => {
    const { code, data, msg } = await getInsideWithdrawInfo({
      currency: currentCurrency,
    });
    console.log("内部转账页信息", code, data, msg);
    if (code === 0 && msg === "OK") {
      const { available_balance } = data;
      setCurrencyBalance(available_balance);
      setCurrentNetInfo(data);
      InitGasFeeInfo();
    }
  };

  const InitBankWithdrawInfo = async () => {
    const { code, data, msg } = await getBankWithdrawInfo({
      currency: currentCurrency,
    });
    console.log("银行转账页信息", code, data, msg);
    if (code === 0 && msg === "OK") {
      const { available_balance } = data;
      setCurrencyBalance(available_balance);
      setCurrentNetInfo(data);
    }
  };

  const changePayLoading = (getBoolValue) => {
    setPayLoading(getBoolValue);
  };
  const changePayId = (getStringValue) => {
    setPayId(getStringValue);
    if ([WITHDRAWCHAIN, WITHDRAWINSIDE, WITHDRAWBANK].includes(currentStep)) {
      setCurrentStep(WITHDRAWCONFIRM);
    }
  };
  const changeVerifyItems = (verifyItems) => {
    setVerifyItems(verifyItems);
  };
  const changePayAmount = (getValue) => {
    setPayAmount(getValue);
  };
  const changeMemo = (getValue) => {
    setCurrentMemo(getValue);
  };
  const changePubkey = (getValue) => {
    setCurrentPubkey(getValue);
  };
  const changeChainNumber = (getValue) => {
    setCurrentChainNumber(getValue);
  };
  const changePayStatus = (getValue) => {
    setPayStatus(getValue);
  };

  const selectList = withdrawTypeList.filter((cur) => {
    if (!currentCurrency) {
      return cur;
    }
    if (bankSupportCurrencyList.includes(currentCurrency)) {
      return cur.selected_key === WITHDRAWBANK;
    }
    return cur.selected_key !== WITHDRAWBANK;
  });

  const decimalObj = currenyList.filter((cur) => cur.name === currentCurrency);
  const currentDecimal = decimalObj.length ? decimalObj[0].digits : "";

  return (
    <PageWrap>
      <div className="withdraw-page">
        <ComponentBodyUnit
          bodyWidth={currentStep === WITHDRAWVERIFY ? "450px" : "350px"}
          btnDesc={currentStep === WITHDRAWCONFIRM && t("WbPTckCFMKtPHgAlL")}
          clickBackEvent={
            currentStep === WITHDRAWCONFIRM && pageLeftTopClickEvent
          }
        >
          {currentStep !== WITHDRAWRESULTPAGE && (
            <Text
              fontSize={["24px", "32px"]}
              fontWeight={"600"}
              textAlign={"center"}
            >
              {currentStep !== WITHDRAWCONFIRM &&
                currentStep !== WITHDRAWVERIFY &&
                !isBankTransfer &&
                t("PczYwMSUlifZlPdkNj")}
              {currentStep !== WITHDRAWCONFIRM &&
                currentStep !== WITHDRAWVERIFY &&
                isBankTransfer &&
                t("nqPKzhAfSmqRRIHii")}
              {currentStep === WITHDRAWCONFIRM &&
                !isBankTransfer &&
                t("XwYKiJGJKAZZZpNp")}
              {currentStep === WITHDRAWCONFIRM &&
                isBankTransfer &&
                t("nqPKzhAfSmqRRIHii")}
              {currentStep === WITHDRAWVERIFY && t("bxtlucAfnIxfwBYAY")}
            </Text>
          )}
          {currentStep === WITHDRAWRESULTPAGE && isBankTransfer && (
            <Text
              fontSize={["24px", "32px"]}
              fontWeight={"600"}
              textAlign={"center"}
            >
              {t("nqPKzhAfSmqRRIHii")}
            </Text>
          )}
          <Box w="100%" mt={["20px", "53px"]}>
            {currentStep === WITHDRAWONE && (
              <>
                <CommonUnitStyle
                  title={t("DVjsSAdKoyNHoIqhumQ")}
                  selectContent={currenyList}
                  selectPlaceholder={t("LFipKWcCwIamrrato")}
                  selectedValue={currentCurrency}
                  selectedEvent={selectedCurrency}
                />
                <CommonUnitStyle
                  title={t("cxKcumOzANqjtQqREyas")}
                  selectContent={selectList}
                  selectPlaceholder={t("cxKcumOzANqjtQqREyas")}
                  leftLineBg="transparent"
                  selectedValue={currentWithdrawType}
                  selectedEvent={selectedMode}
                />
                <CommonBtnDom
                  btnText={t("tOaRBVUJHpnSgDBrGON")}
                  noCanClick={!canNext()}
                  clickEvent={clickNext}
                />
              </>
            )}
            {currentStep === WITHDRAWCHAIN && (
              <ChainWithDrawPage
                currency={currentCurrency}
                address={currentReceiveddress}
                networkList={currentNetworkList}
                currentNetwork={currentSelectedNetwork}
                netAboutInfo={currentNetInfo}
                balance={currencyBalance}
                fee={currentGasFee}
                amount={currentAmount}
                memo={currentMemo}
                pubkey={currentPubkey}
                chainNumber={currentChainNumber}
                selectedNetEvent={selectedNetworkInfo}
                updateAddressEvent={updateInputAddress}
                updateMountEvent={updateInputAmount}
                backEvent={clickBackPrePage}
                showLoadingEvent={changePayLoading}
                getPayId={changePayId}
                getMemoEvent={changeMemo}
                getPubkeyEvent={changePubkey}
                getChainNumberEvent={changeChainNumber}
                withdrawLimit={withdrawLimit}
              />
            )}
            {currentStep === WITHDRAWINSIDE && (
              <InsideWithdrawPage
                currency={currentCurrency}
                balance={currencyBalance}
                netAboutInfo={currentNetInfo}
                fee={currentGasFee}
                amount={currentAmount}
                address={currentReceiveddress}
                updateAddressEvent={updateInputAddress}
                updateMountEvent={updateInputAmount}
                backEvent={clickBackPrePage}
                showLoadingEvent={changePayLoading}
                getPayId={changePayId}
              />
            )}
            {currentStep === WITHDRAWBANK && (
              <BankWithdrawPage
                decimal={currentDecimal}
                currency={currentCurrency}
                amount={currentAmount}
                netAboutInfo={currentNetInfo}
                balance={currencyBalance}
                accountNo={currentAccountNo}
                updateMountEvent={updateInputAmount}
                updateAccountNoEvent={updateInputAccountNo}
                showLoadingEvent={changePayLoading}
                getPayId={changePayId}
              />
            )}
            {/* 确认页面 */}
            {currentStep === WITHDRAWCONFIRM && (
              <WithdrawConfirmPage
                payId={payId}
                withdrawType={currentWithdrawType}
                showLoadingEvent={changePayLoading}
                conformPay={clickNext}
                getPayAmount={changePayAmount}
                getPayStatus={changePayStatus}
                getVerifyItems={changeVerifyItems}
              />
            )}
            {currentStep === WITHDRAWVERIFY && (
              <WithdrawSafeVerify
                payId={payId}
                showLoadingEvent={changePayLoading}
                confirmPay={clickNext}
                getPayStatus={changePayStatus}
              />
            )}
            {/* 结果页，依赖状态，数量，金额 */}
            {currentStep === WITHDRAWRESULTPAGE && (
              <WithdrawResultPage
                withdrawType={currentWithdrawType}
                payamount={payAmount}
                currency={currentCurrency}
                status={payStatus}
              />
            )}
          </Box>
        </ComponentBodyUnit>
        {payLoading && <BXLoading />}
        {/* 未c3弹窗 */}
        <ModalComponent isShow={showC3Modal}>
          <Box p={["50px 30px"]}>
            <Flex flexDirection={"column"} alignItems={"center"}>
              <Image src={"../wwwsite/images/kyc/info-circle.svg"} />
              <Text
                color={"#000"}
                fontSize={"16px"}
                fontWeight={"500"}
                mt={"10px"}
              >
                {t("RZgUzTVRaXNflgjshVf")}
              </Text>
              <Flex mt={"20px"}>
                <Button
                  mr="10px"
                  fontSize="14px"
                  padding="0 30px"
                  w={["40%", "100px"]}
                  bg="rgba(32, 26, 85, 0.05)"
                  color="t.100"
                  fontWeight={"400"}
                  borderRadius={"30px"}
                  h="44px"
                  cursor={"pointer"}
                  onClick={() => setShowC3Modal(false)}
                >
                  {t("UNForxvjDLCZdGrmWkX")}
                </Button>
                <Button
                  fontSize="14px"
                  padding="0 30px"
                  w={["60%", "200px"]}
                  bg="blue.100"
                  color="#fff"
                  fontWeight={"400"}
                  borderRadius={"30px"}
                  _hover={{ bg: "blue.80" }}
                  h="44px"
                  cursor={"pointer"}
                  onClick={() => {
                    navigate("/trade/kyc");
                  }}
                >
                  {t("AQrCocFqoKoYieUnT")}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </ModalComponent>
      </div>
    </PageWrap>
  );
};

export default WithdrawPage;
