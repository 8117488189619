import PageWrap from "@/components/PageWrap";
import { Box, Text, Flex, Image, Input, Button } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/state/hooks";
import useLegalTender from "./hooks";
import BixinPayLoading from "@/components/ui/pay-loading";
import BxSelect from "@/components/ui/select";

const LegalTenderAdd = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const {
    addLoading,
    addParams,
    isShowBankTransferRadio,
    allCountryList,
    areaError,
    addParamsHandler,
    onSubmit,
  } = useLegalTender();
  const _lang = useAppSelector((state) => state.user.language);

  const hasError = areaError?.san || areaError?.noComp;

  const toHelp = () => {
    if (_lang === "en") {
      window.open(
        "https://support.bixin.com/hc/en-hk/articles/29750863955219-Supported-Countries-Regions-for-Bank-Transfers"
      );
    } else {
      window.open(
        "https://support.bixin.com/hc/zh-hk/articles/28728626132115-%E6%94%AF%E6%8C%81%E9%8A%80%E8%A1%8C%E8%BD%89%E8%B3%AC%E7%9A%84%E5%9C%8B%E5%AE%B6-%E5%9C%B0%E5%8D%80"
      );
    }
  };

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        pt={"80px"}
      >
        <Flex
          alignItems={"center"}
          flexDirection={"column"}
          w={["90%", "500px"]}
          justifyContent={"flex-start"}
          margin={"0 auto"}
        >
          <Text fontSize={"24px"} fontWeight={600}>
            {t("fXTeVLzGkVECTSGgtm")}
          </Text>
          <Flex
            w={"100%"}
            mt={"40px"}
            borderRadius={"12px"}
            bgColor={"#EBEDF5"}
            p={"10px"}
            h={"44px"}
            alignItems={"center"}
            fontSize={12}
            fontWeight={400}
          >
            <Image
              src="../../../wwwsite/images/transfer/tip_icon.svg"
              w={"24px"}
              h={"24px"}
            />
            <Text ml={"10px"} color={"#010313"}>
              <span>{t("tglvvZIzjMuxIoXEYs")}</span>
              <span
                color="#333BF5"
                style={{ cursor: "pointer", color: "#333BF5" }}
                onClick={toHelp}
              >
                &nbsp;{t("VXwjemLYMJiNKvXlZ")}
              </span>
            </Text>
          </Flex>
          {/* 国家 */}
          <Box mt={"40px"} width={"100%"}>
            <Text fontSize="14px" color="#010313">
              {t("VXwjemLYMJiNKvXlZ")}
            </Text>
            {allCountryList.length > 0 && (
              <BxSelect
                style={{ height: "100px" }}
                list={allCountryList}
                country={allCountryList}
                initName={addParams.countryArea}
                onGetValue={(val) => addParamsHandler("countryArea", val, 256)}
                hasError={hasError}
                type="phone"
              />
            )}
          </Box>
          {/* 银行名称 */}
          <Box mt={"30px"} width={"100%"}>
            <Text fontSize="14px" color="#010313">
              {t("PlIgEPTbgrrwkSuHc")}
            </Text>
            <Input
              width={"100%"}
              h="44px"
              fontSize="14px"
              color="#010313"
              borderRadius={"12px"}
              placeholder={t("GZOFzxVOsYtRtlONwcv")}
              mt="10px"
              value={addParams.bankName}
              onChange={(e) =>
                addParamsHandler("bankName", e.target.value, 256)
              }
            />
          </Box>
          {/* 收款人名称 */}
          <Box mt={"30px"} width={"100%"}>
            <Text fontSize="14px" color="#010313">
              {t("NqhjvGQWWHnmAzDH")}
            </Text>
            <Input
              width={"100%"}
              h="44px"
              fontSize="14px"
              color="#010313"
              borderRadius={"12px"}
              placeholder={t("YiNdDLTlBGFQiuNECi")}
              mt="10px"
              value={addParams.payeeName}
              onChange={(e) =>
                addParamsHandler("payeeName", e.target.value, 128)
              }
            />
            <Text mt={"5px"} fontSize="14px" color="#333BF5">
              {t("ALJjPGCRLTrnWlbmqO")}
            </Text>
          </Box>
          {/* 账户号码 */}
          <Box mt={"30px"} width={"100%"}>
            <Text fontSize="14px" color="#010313">
              {t("QNEAoNoQfTpYIxJvjdM")}
            </Text>
            <Input
              width={"100%"}
              h="44px"
              fontSize="14px"
              color="#010313"
              borderRadius={"12px"}
              placeholder={t("aFykbSRCZkUrYNzoKtk")}
              mt="10px"
              value={addParams.accountNumber}
              onChange={(e) =>
                addParamsHandler("accountNumber", e.target.value, 128)
              }
            />
          </Box>
          {/* 银行编号 */}
          <Box mt={"30px"} width={"100%"}>
            <Text fontSize="14px" color="#010313">
              {t("MhWCMBfnPEmyPhdBJC")}
            </Text>
            <Input
              width={"100%"}
              h="44px"
              fontSize="14px"
              color="#010313"
              borderRadius={"12px"}
              placeholder={t("TgilgrTuMXgbAQuPf")}
              mt="10px"
              value={addParams.bankCode}
              onChange={(e) =>
                addParamsHandler("bankCode", e.target.value, 128)
              }
            />
          </Box>
          {/* SWIFT代码 */}
          <Box mt={"30px"} width={"100%"}>
            <Text fontSize="14px" color="#010313">
              {t("rcbvwVBVHsJazGKrQv")}
            </Text>
            <Input
              width={"100%"}
              h="44px"
              fontSize="14px"
              color="#010313"
              borderRadius={"12px"}
              placeholder={t("MQaUVFeBMhHfKxmBUV")}
              mt="10px"
              value={addParams.swiftCode}
              onChange={(e) =>
                addParamsHandler("swiftCode", e.target.value, 128)
              }
            />
          </Box>
          {/* 银行地址 */}
          <Box mt={"30px"} width={"100%"}>
            <Text fontSize="14px" color="#010313">
              {t("HsYTxsCouoyySItw")}
            </Text>
            <Input
              width={"100%"}
              h="44px"
              fontSize="14px"
              color="#010313"
              borderRadius={"12px"}
              placeholder={t("HgKGDukwWWdCeZlibVY")}
              mt="10px"
              value={addParams.bankAddress}
              onChange={(e) =>
                addParamsHandler("bankAddress", e.target.value, 256)
              }
            />
          </Box>
          {/* 确认是否转账 */}
          {isShowBankTransferRadio && (
            <Box mt={"30px"} width={"100%"}>
              <Text fontSize="16px" color="#000000" fontWeight={500}>
                {t("znOXnaDhTySukBeCrT")}
              </Text>
              <Box mt={"34px"}>
                <Flex>
                  <Image
                    onClick={(e) => addParamsHandler("isKycTransfer", "yes")}
                    cursor={"pointer"}
                    src={`../../../wwwsite/images/kyc/radio${
                      addParams.isKycTransfer === "yes" ? "_checked" : ""
                    }.svg`}
                  />
                  <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                    {t("NltNaOnxuMeUAbBeZ")}
                  </Text>
                </Flex>
                <Flex mt={"20px"}>
                  <Image
                    onClick={(e) => addParamsHandler("isKycTransfer", "no")}
                    cursor={"pointer"}
                    src={`../../../wwwsite/images/kyc/radio${
                      addParams.isKycTransfer === "no" ? "_checked" : ""
                    }.svg`}
                  />
                  <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                    {t("HmXYHUAhkQnrZSfKu")}
                  </Text>
                </Flex>
              </Box>
              {addParams.isKycTransfer === "no" && (
                <Text
                  mt={"10px"}
                  fontSize="14px"
                  color="#010313"
                  fontWeight={400}
                >
                  {t("ESHCFsWVeQAWSnEKV")}
                </Text>
              )}
            </Box>
          )}
          <Flex justifyContent={"center"} mt="40px" mb={"40px"}>
            <Button
              mr="10px"
              fontSize="14px"
              padding="0 30px"
              w={["100%", "100px"]}
              bg="rgba(32, 26, 85, 0.05)"
              color="t.100"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={"pointer"}
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("WbPTckCFMKtPHgAlL")}
            </Button>
            <Button
              fontSize="14px"
              padding="0 30px"
              w={["100%", "200px"]}
              bg="blue.100"
              color="#fff"
              fontWeight={"400"}
              borderRadius={"30px"}
              _hover={{ bg: "blue.80" }}
              h="44px"
              cursor={"pointer"}
              onClick={onSubmit}
            >
              {t("AQrCocFqoKoYieUnT")}
            </Button>
          </Flex>
        </Flex>
      </Box>
      {addLoading && <BixinPayLoading />}
    </PageWrap>
  );
};
export default LegalTenderAdd;
