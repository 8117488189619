import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme/theme-bx";
import { ClickToComponent } from "click-to-react-component";
import "react-tooltip/dist/react-tooltip.css";
import "./index.scss";

// import VConsole from 'vconsole';
const root = ReactDOM.createRoot(document.getElementById("root"));
// new VConsole();
//React.StrictMode  使用脚手架创建项目后默认会开启严格模式，在严格模式下，React 开发环境下会刻意执行两次渲染，用于突出显示应用程序中潜在问题。
root.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme} cssVarsRoot="#root">
      <ClickToComponent />

      <App />
    </ChakraProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
