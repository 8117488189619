import { Box, Flex, Text } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { Image } from "@chakra-ui/react";

const StepDesc = ({ active }) => {
  const { t } = useI18n();

  const renderStep = (active, current) => {
    if (active > current) {
      return (
        <Image
          borderRadius={"24px"}
          w="24px"
          h="24px"
          src="../wwwsite/images/blue_success_ghost.svg"
        />
      );
    } else if (active === current) {
      return (
        <Box
          w="24px"
          h="24px"
          bg={active === current ? "blue.100" : "#fff"}
          color={"#fff"}
          lineHeight={"22px"}
          textAlign={"center"}
          fontSize={"12px"}
          borderRadius={"24px"}
          border="1px solid #333BF5"
        >
          {current}
        </Box>
      );
    } else {
      return (
        <Box
          w="24px"
          h="24px"
          bg={active === current ? "blue.100" : "#fff"}
          color={"#B3B4C8"}
          lineHeight={"22px"}
          textAlign={"center"}
          fontSize={"12px"}
          borderRadius={"24px"}
          border="1px solid #B3B4C8"
        >
          {current}
        </Box>
      );
    }
  };

  return (
    <Flex
      justifyContent={"flex-start"}
      mt="50px"
      alignItems={"center"}
      mb="50px"
    >
      {renderStep(active, 1)}
      <Text fontSize={"12px"} color={active >= 1 ? "t.100" : "t.50"} ml="5px">
        {t("kuTgfRDYzVZvEHrFtQ")}
      </Text>
      <Box w="24px" h="2px" bg="blue.100" ml="8px" mr="8px"></Box>
      {renderStep(active, 2)}

      <Text fontSize={"12px"} color={active >= 2 ? "t.100" : "t.50"} ml="5px">
        {t("razdiXXHIkQKbPQTZn")}
      </Text>
      <Box w="24px" h="2px" bg="blue.100" ml="8px" mr="8px"></Box>
      {renderStep(active, 3)}
      <Text fontSize={"12px"} color={active >= 3 ? "t.100" : "t.50"} ml="5px">
        {t("BNXHKONNlrkDGKwkXt")}
      </Text>
    </Flex>
  );
};

export default StepDesc;
