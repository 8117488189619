import {
  Box,
  Flex,
  Text,
  Image,
  Button,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Center,
} from "@chakra-ui/react";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronDownIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { useMedia } from "@/hooks/useMedia";
import { useDispatch } from "react-redux";
import { updateLanguage } from "@/state/user/reducer";
import { useI18n } from "@/hooks/useI18n";
import { useState, useEffect } from "react";
import PageWrap from "@/components/PageWrap";
import ModalComponent from "@/components/ui/modal";
import {
  BIXIN_LOGIN,
  BIXIN_REGISTER,
  BIXIN_AGREEMENT,
  BIXIN_PRIVACYPLICE,
  BIXIN_WALLET_BILL,
  BIXIN_WALLET,
  BIXIN_CC_EXCHANGE,
} from "@/helpers/constants/routeName";
import "swiper/css";
import "swiper/css/pagination";

const Home = () => {
  const { isOpen, onClose } = useDisclosure();
  const { isMobile, isPad, isPc, isGiant } = useMedia();
  const { t, locale } = useI18n();
  const [languageName, setLanguageName] = useState("");
  const [isStatementShow, setIsStatementShow] = useState(true);
  const dispatch = useDispatch();
  let local_lang = localStorage.getItem("bx_curLang") || locale;
  document.title = t("pqzdTKxlixAoAQwGy");
  let logoUrl = "";
  let item_bg_img = "";

  useEffect(() => {
    if (locale === "en") {
      setLanguageName("English");
    } else if (locale === "zh") {
      setLanguageName("简体");
    } else {
      setLanguageName("繁體");
    }
    initLang();
  }, [local_lang]);
  if (isMobile) {
    item_bg_img = "../wwwsite/images/website/home_bg_m.png";
  }
  if (isPad) {
    item_bg_img = "../wwwsite/images/website/home_bg_ipad.png";
  }
  if (isPc) {
    item_bg_img = "../wwwsite/images/website/home_bg.png";
  }

  if (local_lang === "en") {
    logoUrl = "../wwwsite/images/website/logo-en.svg";
  }
  if (local_lang === "tw") {
    logoUrl = "../wwwsite/images/website/logo-hk.svg";
  }
  console.log("local_lang===", local_lang);
  if (local_lang === "zh") {
    logoUrl = "../wwwsite/images/website/logo-zh.svg?t=" + Math.random();
  }
  const toRegister = () => {
    window.open(BIXIN_REGISTER, "_blank");
  };
  const toLogin = () => {
    window.open(BIXIN_LOGIN, "_blank");
  };
  const toAgree = () => {
    window.open(BIXIN_AGREEMENT, "_blank");
  };
  const toPrvacy = () => {
    window.open(BIXIN_PRIVACYPLICE, "_blank");
  };
  const toHistory = () => {
    window.open(BIXIN_WALLET_BILL, "_blank");
  };
  const toWallet = () => {
    window.open(BIXIN_WALLET, "_blank");
  };
  const toExchange = () => {
    window.open(BIXIN_CC_EXCHANGE, "_blank");
  };

  const toBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const mobileSlide = () => {
    return (
      <Swiper
        modules={[Pagination]}
        style={{ width: "100%" }}
        spaceBetween={300}
        slidesPerView={2}
        freeMode={true}
        loop={true}
        pagination={{ clickable: true }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <Box
            w={"300px"}
            h="508px"
            bg="#fff"
            borderRadius={"50px"}
            position="relative"
            mb="40px"
            overflow={"hidden"}
          >
            <Text
              color="t.100"
              fontWeight={"bold"}
              fontSize={"24px"}
              mt="70px"
              mr={["30px", "30px", "68px"]}
              textAlign={"right"}
            >
              {t("nwsbWCYbSYugmHDgHTAB")}
            </Text>
            <Image
              src="../wwwsite/images/website/item4_img.png"
              w={["70%", "80%", "281px"]}
              alt="img"
              position="absolute"
              bottom="10"
              left="10"
              // left={["10px", "20px", "30px"]}
            />
            <Text
              textAlign={"right"}
              mr={["30px", "30px", "68px"]}
              mt="10px"
              color="t.80"
            >
              {t("pZxEeOQMfyGXKTBxtyJ")}
              <span style={{ marginLeft: "5px" }}>
                {local_lang === "en" && t("ZGyanZavgObBeqhP")}
              </span>
            </Text>
            <Text
              textAlign={"right"}
              mr={["30px", "30px", "68px"]}
              mt="10px"
              color="t.80"
            >
              {local_lang !== "en" && t("ZGyanZavgObBeqhP")}
            </Text>
            <Text
              textAlign={"right"}
              mr={["30px", "30px", "68px"]}
              mt="10px"
              color="t.80"
            >
              {t("oeMZNyLdkPQdxrobojO")}
            </Text>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            w={"300px"}
            h="508px"
            bg="#fff"
            borderRadius={"50px"}
            position="relative"
            mb="40px"
            overflow={"hidden"}
          >
            <Image
              src="../wwwsite/images/website/item4_img1.png"
              w="352px"
              alt="img"
              position="absolute"
              bottom="40px"
              left="0"
              right="0"
              margin="auto"
            />
            <Text
              color="t.100"
              fontWeight={"bold"}
              fontSize={"24px"}
              mt="70px"
              textAlign={"center"}
            >
              {t("toUaeInrwbWVqvGGGX")}
            </Text>
            <Text textAlign={"center"} mt="10px" color="t.80">
              {t("fTbsMnhpZYjiOkAdex")}
            </Text>
            <Text textAlign={"center"} mt="10px" color="t.80">
              {t("yOZzgRmKudbwNjuurBHz")}
            </Text>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            w={"300px"}
            h="508px"
            bg="#fff"
            borderRadius={"50px"}
            position="relative"
            mb="40px"
            overflow={"hidden"}
          >
            <Text
              color="t.100"
              fontWeight={"bold"}
              fontSize={"24px"}
              mt="70px"
              textAlign={"center"}
            >
              {t("AowuwjwDLrAJJKuVzCA")}
            </Text>
            <Text textAlign={"center"} mt="10px" color="t.80">
              {t("yrlrMPLgOtiEzbmPW")}
            </Text>
            <Image
              src={getItemFourPng(local_lang)}
              w={["260px", "80%", "400px"]}
              alt="img"
              margin="30px auto"
            />
            <Box
              w={["260px", "80%", "400px"]}
              color="#fff"
              h="44px"
              lineHeight={"44px"}
              borderRadius={"30px"}
              bg="#4A9487"
              textAlign={"center"}
              margin="30px auto"
            >
              <Text fontSize="14px">{t("yteHSDpgaHMYFeMBfpWk")}</Text>
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            w={"300px"}
            h="508px"
            bg="#2430D5"
            borderRadius={"50px"}
            position="relative"
            mb="40px"
            overflow={"hidden"}
          >
            <Image
              src="../wwwsite/images/website/item4_img2.png"
              w={["50%", "65%", "253px"]}
              alt="img"
              position="absolute"
              bottom="10px"
              left="0px"
              right="0px"
              margin="auto"
            />
            <Text
              color="#fff"
              fontWeight={"bold"}
              fontSize={"24px"}
              mt="70px"
              textAlign={"center"}
            >
              {t("RtWpXpLfhjeWPtKhcENN")}
            </Text>
            <Text textAlign={"center"} mt="10px" color="#fff">
              {t("tjuWuhQfpwDJLMscdbI")}
            </Text>
          </Box>
        </SwiperSlide>
      </Swiper>
    );
  };
  const initLang = () => {
    // const _lang = localStorage.getItem("bx_curLang");
    // console.log("_lang===", _lang);
    // if (_lang) {
    //   if (_lang === "tw" || _lang === "zh-HK") {
    //     dispatch(updateLanguage({ language: "tw" }));
    //     setLanguageName("繁體");
    //   } else if (_lang === "zh-hans" || _lang === "zh") {
    //     dispatch(updateLanguage({ language: "zh" }));
    //     setLanguageName("简体");
    //   } else {
    //     setLang(_lang);
    //   }
    // } else {
    // dispatch(updateLanguage({ language: "en" }));
    // localStorage.setItem("bx_curLang", "en");
    // }
  };

  const setLang = async (lang) => {
    dispatch(updateLanguage({ language: lang }));
    localStorage.setItem("bx_curLang", lang);
  };

  const toBixinGroup = () => {
    window.open("https://bixingroup.com/", "_blank");
  };
  const socialUrl = (name) => {
    if (name === "twitter") {
      window.open("https://twitter.com/Bixincom", "_blank");
    } else if (name === "facebook") {
      window.open("https://www.facebook.com/Bixincom", "_blank");
    } else if (name === "ins") {
      window.open("https://www.instagram.com/bixin_hk/", "_blank");
    } else if (name === "telegram") {
      window.open("https://t.me/bixinoffcial", "_blank");
    } else if (name === "youtube") {
      window.open("https://www.youtube.com/@bixin5138", "_blank");
    } else if (name === "mail") {
      window.open("mailto://support@bixin.com", "_blank");
    }
  };

  const getItemFourPng = (lang) => {
    const media = isPc ? "pc" : isPad ? "ipad" : isMobile ? "m" : "";
    return `../wwwsite/images/website/item4_${lang}_${media}.png?${Math.random()}`;
  };

  const getItemOnePng = (lang) => {
    const media = isPc ? "pc" : isPad ? "ipad" : isMobile ? "m" : "";
    return `../wwwsite/images/website/item1_${lang}_${media}.png?${Math.random()}`;
  };

  return (
    <div style={{ background: "#f2f2f2" }}>
      <Box
        margin="10px"
        h={["769px", "1062px"]}
        bg="#232325"
        boxShadow={"0px 10px 40px rgba(0, 0, 0, 0.05)"}
        borderRadius={["30px", "50px"]}
        backgroundImage={item_bg_img}
        backgroundRepeat={"no-repeat"}
        backgroundPosition={"center bottom"}
        backgroundSize={"contain"}
        bgSize={"100%"}
        overflow="hidden"
        position="relative"
      >
        <PageWrap></PageWrap>

        <Text
          color="#fff"
          textAlign={"center"}
          fontSize={["36px", "78px", "96px"]}
          fontWeight={"bold"}
          padding={["0", "0 60px", "0 100px"]}
          mt={["122px", "150px", "150px"]}
        >
          {t("jDRHFidXonxhhPKaTl")}
        </Text>
        <Text
          color="#fff"
          textAlign={"center"}
          fontSize={["36px", "78px", "96px"]}
          fontWeight={"bold"}
          padding={["0 38px", "0 60px", "0 100px"]}
          mt={["0px", "-20px", "-30px"]}
        >
          {t("CInUOzoFKTOMvMbix")}
        </Text>
        <Text color="t.50" textAlign={"center"} fontSize={"16px"}>
          {t("pPqFjpMzSnmyNWXdT")}
        </Text>
        <Flex justifyContent={"center"} mt="50px">
          <Button
            w="120px"
            h="44px"
            bg="#4B5DFF"
            color="#fff"
            borderRadius={"30px"}
            _hover={{ bg: "#333BF5" }}
            onClick={localStorage.getItem("token") ? toWallet : toRegister}
          >
            {t("PLhQWivTKyTYZvsNwMxj")}
          </Button>
        </Flex>
        <Center mt="50px">
          <Image
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.bixin.bixin_android",
                "_blank"
              )
            }
            cursor={"pointer"}
            src="../../wwwsite/images/googleplay.svg"
            width={"130px"}
            height={"44px"}
          />
        </Center>

        <Box
          position="absolute"
          bottom={isGiant ? "60px" : ["120px", "151px", "150px"]}
          left="100px"
          right="100px"
        >
          <Flex
            justifyContent={!isMobile ? "space-between" : "center"}
            flexWrap={isMobile && "wrap"}
          >
            {isMobile && (
              <Flex alignItems={"center"}>
                <Text
                  color="t.50"
                  fontSize={"14px"}
                  onClick={toBixinGroup}
                  cursor={"pointer"}
                  mb="46px"
                >
                  {t("VZexjbUhosOUoUTI")}
                  <ArrowForwardIcon />
                </Text>
              </Flex>
            )}
            <Flex justifyContent={"space-between"} w="279px" h="34px">
              <Box
                cursor={"pointer"}
                backgroundImage={"../wwwsite/images/website/icon/tw.png"}
                backgroundRepeat={"no-repeat"}
                backgroundPosition={"center"}
                backgroundSize={"contain"}
                w="25px"
                h="25px"
                _hover={{
                  backgroundImage:
                    "../wwwsite/images/website/icon/tw_hover.png",
                }}
                onClick={() => {
                  socialUrl("twitter");
                }}
              ></Box>
              <Box
                cursor={"pointer"}
                backgroundImage={"../wwwsite/images/website/icon/facebook.png"}
                backgroundRepeat={"no-repeat"}
                backgroundPosition={"center"}
                backgroundSize={"contain"}
                w="25px"
                h="25px"
                _hover={{
                  backgroundImage:
                    "../wwwsite/images/website/icon/facebook_hover.png",
                }}
                onClick={() => {
                  socialUrl("facebook");
                }}
              ></Box>
              <Box
                cursor={"pointer"}
                backgroundImage={"../wwwsite/images/website/icon/ins.png"}
                backgroundRepeat={"no-repeat"}
                backgroundPosition={"center"}
                backgroundSize={"contain"}
                w="25px"
                h="25px"
                _hover={{
                  backgroundImage:
                    "../wwwsite/images/website/icon/ins_hover.png",
                }}
                onClick={() => {
                  socialUrl("ins");
                }}
              ></Box>
              <Box
                cursor={"pointer"}
                backgroundImage={"../wwwsite/images/website/icon/telegram.png"}
                backgroundRepeat={"no-repeat"}
                backgroundPosition={"center"}
                backgroundSize={"contain"}
                w="25px"
                h="25px"
                _hover={{
                  backgroundImage:
                    "../wwwsite/images/website/icon/telegram_hover.png",
                }}
                onClick={() => {
                  socialUrl("telegram");
                }}
              ></Box>
              <Box
                cursor={"pointer"}
                backgroundImage={"../wwwsite/images/website/icon/youtube.png"}
                backgroundRepeat={"no-repeat"}
                backgroundPosition={"center"}
                backgroundSize={"contain"}
                w="25px"
                h="25px"
                _hover={{
                  backgroundImage:
                    "../wwwsite/images/website/icon/youtube_hover.png",
                }}
                onClick={() => {
                  socialUrl("youtube");
                }}
              ></Box>
              <Box
                cursor={"pointer"}
                backgroundImage={"../wwwsite/images/website/icon/mail.png"}
                backgroundRepeat={"no-repeat"}
                backgroundPosition={"center"}
                backgroundSize={"contain"}
                w="25px"
                h="25px"
                _hover={{
                  backgroundImage:
                    "../wwwsite/images/website/icon/mail_hover.png",
                }}
                onClick={() => {
                  socialUrl("mail");
                }}
              ></Box>
            </Flex>
            {!isMobile && (
              <Flex alignItems={"center"}>
                <Text
                  color="t.50"
                  fontSize={"14px"}
                  onClick={toBixinGroup}
                  cursor={"pointer"}
                  _hover={{ color: "#fff" }}
                >
                  {t("VZexjbUhosOUoUTI")}
                  <ArrowForwardIcon />
                </Text>
              </Flex>
            )}
          </Flex>
        </Box>
      </Box>

      <Box
        position={"absolute"}
        left="0"
        top={["800px", "1062px", "1062px"]}
        right="0"
        margin="auto"
        bg="rgb(242, 242, 242)"
      >
        <Box w={["90%", "80%", "1000px"]} margin="-140px auto">
          <Image
            style={{
              position: "relative",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            src={getItemOnePng(local_lang)}
            alt="trade"
          />

          <Flex
            mt="100px"
            justifyContent={!isMobile && "space-between"}
            flexWrap={isMobile && "wrap"}
          >
            <Box
              padding="50px"
              bg="#fff"
              borderRadius={"40px"}
              w={["100%", "50%", "50%"]}
            >
              <img
                src="../wwwsite/images/website/item2_icon.png"
                width="80"
                alt="icon"
              />
              <Text color="t.100" fontWeight={"bold"} fontSize="24px" mt="30px">
                {t("aJpdCHYrgIukuVpHluT")}
              </Text>
              <Text mt="10px" color="t.80" lineHeight={"24px"} fontSize="16px">
                {t("olWnlFpnBjRnPCLuu")}
              </Text>
            </Box>
            <Box
              padding="50px"
              bg="#fff"
              borderRadius={"40px"}
              ml={["0px", "40px", "40px"]}
              w={["100%", "50%", "50%"]}
              mt={["20px", "0", "0"]}
            >
              <img
                src="../wwwsite/images/website/item2_icon1.png"
                width="80"
                alt="icon"
              />
              <Text color="t.100" fontWeight={"bold"} fontSize="24px" mt="30px">
                {t("ApYUyYTgQDVozNVGdoWy")}
              </Text>
              <Text mt="10px" color="t.80" lineHeight={"24px"} fontSize="16px">
                {t("MEuzpQOAySrMUyXj")}
              </Text>
            </Box>
          </Flex>
          <Box bg="#232325" borderRadius={"40px"} padding="50px" mt="20px">
            <img
              src="../wwwsite/images/website/item3_icon.png"
              width="80"
              alt="icon3"
            />
            <Text color="#fff" mt="30px" fontSize={"24px"} fontWeight={"bold"}>
              {t("NkjUESwzghXjElHM")}
            </Text>
            <Text color="t.50" mt="10px" fontSize={"16px"} fontWeight={"400"}>
              {t("GnugFlecXZohsDMgfjv")}
            </Text>
          </Box>

          {!isMobile && (
            <Flex mt="100px" flexWrap="wrap" justifyContent={"space-between"}>
              <Box
                w={["100%", "48%", "480px"]}
                h="508px"
                bg="#fff"
                borderRadius={"50px"}
                position="relative"
                mb="40px"
              >
                <Image
                  src="../wwwsite/images/website/item4_img.png"
                  w={["70%", "80%", "281px"]}
                  alt="img"
                  position="absolute"
                  bottom="0"
                  left={["10px", "20px", "30px"]}
                />
                <Text
                  color="t.100"
                  fontWeight={"bold"}
                  fontSize={"24px"}
                  mt="70px"
                  mr={["30px", "30px", "68px"]}
                  textAlign={"right"}
                >
                  {t("nwsbWCYbSYugmHDgHTAB")}
                </Text>
                <Text
                  textAlign={"right"}
                  mr={["30px", "30px", "68px"]}
                  mt="10px"
                  color="t.80"
                >
                  {t("pZxEeOQMfyGXKTBxtyJ")}
                  {local_lang === "en" && (
                    <span style={{ marginLeft: "5px" }}>
                      {t("ZGyanZavgObBeqhP")}
                    </span>
                  )}
                </Text>
                <Text
                  textAlign={"right"}
                  mr={["30px", "30px", "68px"]}
                  mt="10px"
                  color="t.80"
                >
                  {local_lang !== "en" && t("ZGyanZavgObBeqhP")}
                </Text>
                <Text
                  textAlign={"right"}
                  mr={["30px", "30px", "68px"]}
                  mt="10px"
                  color="t.80"
                >
                  {t("oeMZNyLdkPQdxrobojO")}
                </Text>
              </Box>
              <Box
                w={["100%", "48%", "480px"]}
                h="508px"
                bg="#fff"
                borderRadius={"50px"}
                position="relative"
                mb="40px"
              >
                <Image
                  src="../wwwsite/images/website/item4_img1.png"
                  w="352px"
                  alt="img"
                  position="absolute"
                  bottom="40px"
                  left="0"
                  right="0"
                  margin="auto"
                />
                <Text
                  color="t.100"
                  fontWeight={"bold"}
                  fontSize={"24px"}
                  mt="70px"
                  textAlign={"center"}
                >
                  {t("toUaeInrwbWVqvGGGX")}
                </Text>
                <Text textAlign={"center"} mt="10px" color="t.80">
                  {t("fTbsMnhpZYjiOkAdex")}
                </Text>
                <Text textAlign={"center"} mt="10px" color="t.80">
                  {t("yOZzgRmKudbwNjuurBHz")}
                </Text>
              </Box>
              <Box
                w={["100%", "48%", "480px"]}
                h="508px"
                bg="#fff"
                borderRadius={"50px"}
                position="relative"
                mb="40px"
              >
                <Text
                  color="t.100"
                  fontWeight={"bold"}
                  fontSize={"24px"}
                  mt="50px"
                  textAlign={"center"}
                >
                  {t("AowuwjwDLrAJJKuVzCA")}
                </Text>
                <Text textAlign={"center"} mt="10px" color="t.80">
                  {t("yrlrMPLgOtiEzbmPW")}
                </Text>
                <Image
                  src={getItemFourPng(local_lang)}
                  w={["260px", "80%", "400px"]}
                  alt="img"
                  margin="30px auto"
                />
                <Box
                  w={["260px", "80%", "400px"]}
                  color="#fff"
                  h="44px"
                  lineHeight={"44px"}
                  borderRadius={"30px"}
                  bg="#4A9487"
                  textAlign={"center"}
                  margin="30px auto"
                >
                  <Text fontSize="14px">{t("yteHSDpgaHMYFeMBfpWk")}</Text>
                </Box>
              </Box>
              <Box
                w={["100%", "48%", "480px"]}
                h="508px"
                bg="#2430D5"
                borderRadius={"50px"}
                position="relative"
                mb="40px"
              >
                <Image
                  src="../wwwsite/images/website/item4_img2.png"
                  w={["50%", "65%", "253px"]}
                  alt="img"
                  position="absolute"
                  bottom="10px"
                  left="0px"
                  right="0px"
                  margin="auto"
                />
                <Text
                  color="#fff"
                  fontWeight={"bold"}
                  fontSize={"24px"}
                  mt="70px"
                  textAlign={"center"}
                >
                  {t("RtWpXpLfhjeWPtKhcENN")}
                </Text>
                <Text textAlign={"center"} mt="10px" color="#fff">
                  {t("tjuWuhQfpwDJLMscdbI")}
                </Text>
              </Box>
            </Flex>
          )}
        </Box>
        <Box padding="0 20px" mt="150px">
          {isMobile && mobileSlide()}
        </Box>
        <Box
          bg="#232325"
          margin="10px"
          // h={["657px", "489px", "489px"]}
          borderRadius={["30px", "50px", "50px"]}
          pb="50px"
          mt="96px"
          overflow={"hidden"}
        >
          <Text
            color="#fff"
            fontWeight={"bold"}
            fontSize={"32px"}
            mt={["50px", "60px", "100px"]}
            ml={["20px", "40px", "100px"]}
            onClick={toBottom}
          >
            {t("jeYCQaSvbBRtAklYZ")}
          </Text>

          <Flex
            margin={["20px 20px 0px 20px", "20px 40px", "20px 100px"]}
            flexWrap={isMobile && "wrap"}
            justifyContent={!isMobile && "space-between"}
            pb="40px"
            borderBottom={"1px solid #B3B4C8"}
          >
            <Box w={["100%", "507px", "790px"]}>
              <Text color="t.50">{t("NOhPBjPDsgVbfqTgjIH")}</Text>
              <Text color="t.50" mt="20px">
                {t("gcFEhAlcWmjNfIqry")}
              </Text>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={!isMobile ? "flex-end" : "flex-start"}
            >
              <Image
                mt={!isMobile && "30px"}
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.bixin.bixin_android",
                    "_blank"
                  )
                }
                cursor={"pointer"}
                src="../../wwwsite/images/googleplay.svg"
                width={"130px"}
                height={"44px"}
              />
              <Flex
                justifyContent={"flex-start"}
                mt={
                  local_lang === "en"
                    ? ["20px", "320px", "210px"]
                    : ["20px", "160px", "100px"]
                }
              >
                <Text
                  color="t.50"
                  _hover={{ color: "#fff" }}
                  onClick={toAgree}
                  cursor={"pointer"}
                >
                  {t("ZbQsLkbfHUtbznnzU")}
                </Text>
                <Text
                  color="t.50"
                  ml="30px"
                  _hover={{ color: "#fff" }}
                  onClick={toPrvacy}
                  cursor={"pointer"}
                >
                  {t("TsnjCIOPEKKCCfrIF")}
                </Text>
                <Text color="t.50" ml="30px" _hover={{ color: "#fff" }}>
                  <a
                    href={
                      local_lang === "en"
                        ? "https://support.bixin.com/hc/en-hk"
                        : "https://support.bixin.com/hc/zh-hk"
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("qyKlEsEkyTXybgIHcI")}
                  </a>
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Text
              color="#fff"
              fontWeight={"bold"}
              fontSize={"32px"}
              mt={"40px"}
              ml={["20px", "40px", "100px"]}
              onClick={toBottom}
          >
            {t("MGsQCMXzOIuqcXjBDDTF")}
          </Text>
          <Box
              margin={["20px 20px 0px 20px", "20px 40px", "20px 100px"]}
          >
            <Text color="t.50">{t("xYFrGCCcvvWooWsibQFD")}</Text>
            <Text color="t.50" mt="20px">
              {t("omBdaJrTBmDrBzNYx")}
            </Text>
          </Box>
          <Flex
            flexWrap={isMobile && "wrap"}
            justifyContent={!isMobile && "space-between"}
          >
            <Text
              color="t.50"
              ml={["20px", "40px", "100px"]}
              mt="40px"
              fontSize="14px"
            >
              NewBX Limited © Copyright 2023. All Rights Reserved.
            </Text>
            <Menu maxWidth="150px">
              <MenuButton
                color="t.50"
                fontSize="14px"
                padding="5px 10px"
                ml={["-10px", "40px", "40px"]}
                mr={["20px", "40px", "100px"]}
                mt={["22px", "40px", "40px"]}
                _hover={{
                  display: "block",
                  bg: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "10px",
                  color: "#fff",
                }}
              >
                <Box
                  color="t.50"
                  ml={["20px", "0px", "0px"]}
                  fontSize="14px"
                  cursor={"pointer"}
                >
                  <Flex justifyContent={"flex-start"} alignItems={"center"}>
                    <Image
                      src="../wwwsite/images/website/global.png"
                      w="20px"
                      mr="5px"
                    ></Image>
                    {languageName}
                    <ChevronDownIcon ml="5px" />
                  </Flex>
                </Box>
              </MenuButton>
              <MenuList
                maxWidth="150px"
                minWidth="0px"
                borderRadius={"20px"}
                bg="#DFE4EF"
                h="160px"
              >
                <MenuItem
                  borderRadius={"12px"}
                  w="110px"
                  ml="20px"
                  mr="20px"
                  mt="10px"
                  bg="#DFE4EF"
                  _hover={{ bg: "rgba(32, 26, 85, 0.05)" }}
                  onClick={() => {
                    setLang("tw");
                  }}
                >
                  繁體
                </MenuItem>
                <MenuItem
                  borderRadius={"12px"}
                  w="110px"
                  ml="20px"
                  mr="20px"
                  mt="10px"
                  bg="#DFE4EF"
                  _hover={{ bg: "rgba(32, 26, 85, 0.05)" }}
                  onClick={() => {
                    setLang("zh");
                  }}
                >
                  简体
                </MenuItem>
                <MenuItem
                  borderRadius={"12px"}
                  w="110px"
                  ml="20px"
                  mr="20px"
                  mt="10px"
                  bg="#DFE4EF"
                  _hover={{ bg: "rgba(32, 26, 85, 0.05)" }}
                  onClick={() => {
                    setLang("en");
                  }}
                >
                  English
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>
      </Box>
      <Drawer onClose={onClose} isOpen={isOpen} size={"full"} placement={"top"}>
        <DrawerOverlay />
        <DrawerContent>
          <Box borderRadius={"30px"} margin="5px" bg="#232325" h="100%">
            <Flex
              margin={["60px 20px", "30px", "60px 100px"]}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box w="127px">
                <Image src={logoUrl} w={["80px", "127px", "127px"]}></Image>
              </Box>
              <Box>
                <Flex alignItems={"center"}>
                  <Menu maxWidth="150px">
                    <MenuButton>
                      <Text
                        color="t.50"
                        fontSize="14px"
                        ml="40px"
                        cursor={"pointer"}
                      >
                        {languageName}
                        <ChevronDownIcon ml="5px" />
                      </Text>
                    </MenuButton>
                    <MenuList
                      maxWidth="150px"
                      minWidth="0px"
                      borderRadius={"20px"}
                      bg="#DFE4EF"
                      h="160px"
                    >
                      <MenuItem
                        borderRadius={"12px"}
                        w="110px"
                        ml="20px"
                        mr="20px"
                        mt="10px"
                        bg="#DFE4EF"
                        _hover={{ bg: "rgba(32, 26, 85, 0.05)" }}
                        onClick={() => {
                          setLang("tw");
                        }}
                      >
                        繁體
                      </MenuItem>
                      <MenuItem
                        borderRadius={"12px"}
                        w="110px"
                        ml="20px"
                        mr="20px"
                        mt="10px"
                        bg="#DFE4EF"
                        _hover={{ bg: "rgba(32, 26, 85, 0.05)" }}
                        onClick={() => {
                          setLang("zh");
                        }}
                      >
                        简体
                      </MenuItem>
                      <MenuItem
                        borderRadius={"12px"}
                        w="110px"
                        ml="20px"
                        mr="20px"
                        mt="10px"
                        bg="#DFE4EF"
                        _hover={{ bg: "rgba(32, 26, 85, 0.05)" }}
                        onClick={() => {
                          setLang("en");
                        }}
                      >
                        English
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Image
                    src="../wwwsite/images/website/menu_close.png"
                    w="44px"
                    ml="29px"
                    onClick={onClose}
                  ></Image>
                </Flex>
              </Box>
            </Flex>
            <DrawerBody>
              <Box mt="57px">
                {/* <Flex alignItems={"center"} justifyContent={"center"}>
                    <Text color="t.50" fontSize="14px" mb="46px">
                      {t("VsuTZcfrgRHVvsOOY")}
                      <ArrowForwardIcon />
                    </Text>
                  </Flex> */}
                <Flex alignItems={"center"} justifyContent={"center"}>
                  <Box>
                    {localStorage.getItem("token") ? (
                      <Box
                        border="1px solid rgba(139, 138, 153, 0.3)"
                        borderRadius={"12px"}
                        padding="10px 20px"
                        ml="-40px"
                        mb="47px"
                        cursor={"pointer"}
                      >
                        <Flex alignItems={"center"}>
                          <Image
                            src="../wwwsite/images/website/user.png"
                            w="24px"
                            h="24px"
                          />
                          <Text color="#fff" fontSize={"14px"} ml="5px">
                            个人中心
                          </Text>
                        </Flex>
                      </Box>
                    ) : (
                      <Box
                        border="1px solid rgba(139, 138, 153, 0.3)"
                        borderRadius={"12px"}
                        padding="10px 20px"
                        ml="-20px"
                        mb="47px"
                        cursor={"pointer"}
                        onClick={toLogin}
                      >
                        <Flex alignItems={"center"}>
                          <Image
                            src="../wwwsite/images/website/user.png"
                            w="24px"
                            h="24px"
                          />
                          <Text color="#fff" fontSize={"14px"} ml="5px">
                            {t("rtVWmSuAATyCaNgkRNx")}
                          </Text>
                        </Flex>
                      </Box>
                    )}

                    <Text
                      color="t.50"
                      fontSize={"14px"}
                      onClick={toWallet}
                      cursor={"pointer"}
                      mb="46px"
                    >
                      {t("hQRkbssUXUGGLhtxc")}
                      <ArrowForwardIcon />
                    </Text>
                    <Text
                      color="t.50"
                      fontSize={"14px"}
                      onClick={toExchange}
                      cursor={"pointer"}
                      mb="46px"
                    >
                      {t("eDwDkAgbfaPsnwOb")}
                      <ArrowForwardIcon />
                    </Text>
                    {localStorage.getItem("token") && (
                      <Text
                        color="t.50"
                        fontSize={"14px"}
                        onClick={toHistory}
                        cursor={"pointer"}
                        mb="46px"
                      >
                        {t("AVWJwkijUTbNMShmp")}
                        <ArrowForwardIcon />
                      </Text>
                    )}

                    <Text
                      color="t.50"
                      fontSize={"14px"}
                      onClick={toBixinGroup}
                      cursor={"pointer"}
                      mb="46px"
                    >
                      {t("VZexjbUhosOUoUTI")}
                      <ArrowForwardIcon />
                    </Text>
                  </Box>
                </Flex>
              </Box>
              {!localStorage.getItem("token") && (
                <Box
                  color="rgba(235, 237, 245, 0.8)"
                  position="absolute"
                  bottom="125px"
                  left="0"
                  right="0"
                  margin="auto"
                  cursor={"pointer"}
                  textAlign={"center"}
                  onClick={toRegister}
                  fontSize="14px"
                >
                  {t("wjeaGDSVTuLLjJGehN")}
                </Box>
              )}

              <Flex
                justifyContent={"space-between"}
                w="279px"
                h="34px"
                position="absolute"
                bottom="65px"
                left="0"
                right="0"
                margin="auto"
              >
                <Box
                  cursor={"pointer"}
                  backgroundImage={"../wwwsite/images/website/twitter.png"}
                  backgroundRepeat={"no-repeat"}
                  backgroundPosition={"center"}
                  backgroundSize={"contain"}
                  w="25px"
                  h="25px"
                  _hover={{
                    backgroundImage:
                      "../wwwsite/images/website/twitter_active.png",
                  }}
                  onClick={() => {
                    socialUrl("twitter");
                  }}
                ></Box>
                <Box
                  cursor={"pointer"}
                  backgroundImage={"../wwwsite/images/website/facebook.png"}
                  backgroundRepeat={"no-repeat"}
                  backgroundPosition={"center"}
                  backgroundSize={"contain"}
                  w="25px"
                  h="25px"
                  _hover={{
                    backgroundImage:
                      "../wwwsite/images/website/facebook_active.png",
                  }}
                  onClick={() => {
                    socialUrl("facebook");
                  }}
                ></Box>
                <Box
                  cursor={"pointer"}
                  backgroundImage={"../wwwsite/images/website/ins.png"}
                  backgroundRepeat={"no-repeat"}
                  backgroundPosition={"center"}
                  backgroundSize={"contain"}
                  w="25px"
                  h="25px"
                  _hover={{
                    backgroundImage: "../wwwsite/images/website/ins_active.png",
                  }}
                  onClick={() => {
                    socialUrl("ins");
                  }}
                ></Box>
                <Box
                  cursor={"pointer"}
                  backgroundImage={"../wwwsite/images/website/telegram.png"}
                  backgroundRepeat={"no-repeat"}
                  backgroundPosition={"center"}
                  backgroundSize={"contain"}
                  w="25px"
                  h="25px"
                  _hover={{
                    backgroundImage:
                      "../wwwsite/images/website/telegram_active.png",
                  }}
                  onClick={() => {
                    socialUrl("telegram");
                  }}
                ></Box>
                <Box
                  cursor={"pointer"}
                  backgroundImage={"../wwwsite/images/website/youtube.png"}
                  backgroundRepeat={"no-repeat"}
                  backgroundPosition={"center"}
                  backgroundSize={"contain"}
                  w="25px"
                  h="25px"
                  _hover={{
                    backgroundImage:
                      "../wwwsite/images/website/youtube_active.png",
                  }}
                  onClick={() => {
                    socialUrl("youtube");
                  }}
                ></Box>
                <Box
                  cursor={"pointer"}
                  backgroundImage={"../wwwsite/images/website/mail.png"}
                  backgroundRepeat={"no-repeat"}
                  backgroundPosition={"center"}
                  backgroundSize={"contain"}
                  w="25px"
                  h="25px"
                  _hover={{
                    backgroundImage:
                      "../wwwsite/images/website/mail_active.png",
                  }}
                  onClick={() => {
                    socialUrl("mail");
                  }}
                ></Box>
              </Flex>
            </DrawerBody>
          </Box>
        </DrawerContent>
      </Drawer>
      <ModalComponent
          isShow={isStatementShow}
          className="statement-modal"
          style={{ width: "calc(100% - 40px)", maxWidth: "567px",  "overflow-y": "scroll", minHeight: "511px", margin: '0 20px' }}
      >
        <div className="statement-modal-wrap">
          <div className="statement-modal-nav">
            <Image
                boxSize="24px"
                src="../wwwsite/images/website/statement_im.svg"
            />
            <Text>{t("MGsQCMXzOIuqcXjBDDTF")}</Text>
          </div>
          <div className="statement-modal-content">
            <Text>{t("xYFrGCCcvvWooWsibQFD")}</Text>
            <Text>{t("omBdaJrTBmDrBzNYx")}</Text>
            <Text>
              <span>{t("omTkmNnwlfkOvozU")}</span>
              <a style={{color: '#333BF5'}} target="_blank" href="https://apps.sfc.hk/publicregWeb/corp/BUY737/details">https://apps.sfc.hk/publicregWeb/corp/BUY737/details</a>
            </Text>
          </div>
          <div className="statement-modal-btn">
            <Button
                fontSize="14px"
                padding="0 30px"
                w={["100%", "200px"]}
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                h="44px"
                cursor={"pointer"}
                bg={"blue.100"}
                _hover={{ bg: "blue.80" }}
                onClick={() => setIsStatementShow(false)}
            >
              {t("YxGqBoJIoXruDOmbNu")}
            </Button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};
export default Home;
