import request from "@/hooks/useRequest";
import {
  GETWITHDRAWLAYOUT,
  GETTRANSFERFEE,
  GETINTERNALLAYOUT,
  POSTTRANSFERCREATE,
  POSTACCOUNTSEARCH,
  GETPAYINFO,
  POSTGAVERIFY,
  GETPAYCONFIRM,
  GETUSERINFO,
  GET_WHITELIST_ADDRESS,
  GETFIATWITHDRAWLAYOUT,
  POSTBANKTRANSFERCREATE,
} from "@/helpers/constants/requestApi";
// 获取外部转账信息
export const getChainWithdrawInfo = (params) => {
  return request(GETWITHDRAWLAYOUT, { params });
};

// 获取手续费
export const getGasFee = (params) => {
  return request(GETTRANSFERFEE, { params });
};

// 获取内部转账信息
export const getInsideWithdrawInfo = (params) => {
  return request(GETINTERNALLAYOUT, { params });
};

// 创建转账单
export const requestTrasnferCreate = (body) => {
  return request(POSTTRANSFERCREATE, { body });
};

// 搜索账户信息
export const requestAccountInfo = (body) => {
  return request(POSTACCOUNTSEARCH, { body });
};

// 获取支付信息
export const getPayInfo = (params) => {
  return request(GETPAYINFO, { params });
};

// GA验证
export const requestGoogleAuthVerify = (body) => {
  return request(POSTGAVERIFY, { body });
};

// 确认支付
export const requestPayConfirm = (body, t) => {
  return request(GETPAYCONFIRM, { body }, t);
};

//获取用户信息
export const getUserInfo = (params) => {
  return request(GETUSERINFO, { params });
};

// 白名单地址
export const getWhiteListAddress = () => {
  return request(GET_WHITELIST_ADDRESS, { params: {} });
};

// 获取银行转账信息
export const getBankWithdrawInfo = (params) => {
  return request(GETFIATWITHDRAWLAYOUT, { params });
};

// 创建银行转账单
export const requestBankTransferCreate = (body) => {
  return request(POSTBANKTRANSFERCREATE, { body });
};
