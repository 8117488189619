import request from "@/hooks/useRequest";
import { GETBILLCATEGORYLIST, GETBILLLIST } from "@/helpers/constants/requestApi";


// 账单分类
export const getBillCategoryList = () => {
    return request(GETBILLCATEGORYLIST, {});
};

// 账单列表
export const getBilllist = params => {
    return request(GETBILLLIST, { params });
}