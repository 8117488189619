const BIXIN_WALLET = "/trade/wallet";
const BIXIN_WALLET_DEPOSIT = "/trade/wallet_deposit";
const BIXIN_WALLET_WITHDRAW = "/trade/wallet_withdraw";

const BIXIN_WALLET_INSTANTEX = "/trade/InstantEx";

const BIXIN_WALLET_BILL = "/trade/history";

const BIXIN_CC_EXCHANGE = "/trade/cc";
const BIXIN_EXCHANGE = "/trade/spot/BTC/USDC";

const BIXIN_REGISTER = "/trade/register";
const BIXIN_VERIFY = "/trade/verify";
const BIXIN_LOGIN = "/trade/login";
const BIXIN_AMOUNTAPPLY = "/trade/amountApply";
const BIXIN_RESETACCOUNTINFO = "/trade/resetAccountInfo";
const BIXIN_GA = "/trade/ga";
const BIXIN_ACCOUNTMANAGER = "/trade/accountManager";
const BIXIN_KYC = "/trade/kyc";
const BIXIN_USERCENTER = "/trade/usercenter";
const BIXIN_LANGUAGE = "/trade/language";
const BIXIN_FORGETPWD = "/trade/forgetpwd";
const BIXIN_AGREEMENT = "/trade/agreement";
const BIXIN_PRIVACYPLICE = "/trade/privacypolicy";
const BIXIN_TRANSFER = "/trade/transfer";
const BIXIN_COMPANY_KYC = "/trade/companyKyc";
export {
  BIXIN_WALLET,
  BIXIN_WALLET_DEPOSIT,
  BIXIN_WALLET_WITHDRAW,
  BIXIN_WALLET_INSTANTEX,
  BIXIN_REGISTER,
  BIXIN_VERIFY,
  BIXIN_LOGIN,
  BIXIN_AMOUNTAPPLY,
  BIXIN_RESETACCOUNTINFO,
  BIXIN_ACCOUNTMANAGER,
  BIXIN_KYC,
  BIXIN_GA,
  BIXIN_WALLET_BILL,
  BIXIN_USERCENTER,
  BIXIN_LANGUAGE,
  BIXIN_FORGETPWD,
  BIXIN_CC_EXCHANGE,
  BIXIN_AGREEMENT,
  BIXIN_PRIVACYPLICE,
  BIXIN_TRANSFER,
  BIXIN_EXCHANGE,
  BIXIN_COMPANY_KYC,
};
