import request from "@/hooks/useRequest";
import {
  GETTRANSACCOUNTS,
  POSTSPOTREQUEST,
  GETASSETINFO,
  GETPAYCONFIRM,
  POSTBXSPOTSTRANSFER,
  POSTBXSPOTSTRANSFERCONFIRM,
  GETSPOTSMEAT,
} from "@/helpers/constants/requestApi";

//查询meta信息
export const getMeta =  (params) => {
  return request(GETSPOTSMEAT, { params });
}


 //查询当前交易账户资产
export const getTransferAccounts = (params) => {
  return request(GETTRANSACCOUNTS, { params });
};

// 获取资金账户资产
export const getAssetsInfo = (params) => {
  return request(GETASSETINFO, { params });
};

// /资金账户向交易账户转账
export const postBx2SpotsTransfer = (body) => {
  return request(POSTBXSPOTSTRANSFER, { body });
};


//资金账户二次确认
export const postBx2SpotsTransferBxConfirm = (body) => {
  return request(GETPAYCONFIRM, { body });
};

//SPOTS向其他钱包转账的请求 
export const postSpots2BxTransfer = (body) => {
  return request(POSTSPOTREQUEST, { body });
}

//资金账户向交易账户转账 二次确认
export const postBx2SpotsTransferSpotsConfirm = (body) => {
  return request(POSTBXSPOTSTRANSFERCONFIRM, { body });
}