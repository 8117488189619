import { Box, Flex, Text, Button, Input, Image } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { usePersonalHooks } from "./hooks";

const PersonalInfo = () => {
  const { t } = useI18n();
  const {
    userInfo,
    changeUserInfoProp,
    setElectronicBill,
    electronicBill,
    canEditEmailAddress,
    setCanEditEmailAddress,
    updateUserInfoProp,
  } = usePersonalHooks();

  return (
    <div>
      <Box w={["100%", "600px"]} mt={["40px", "80px"]} mb={["100px", "30px"]}>
        <Text fontWeight="600" color="t.100" fontSize="24px" mb="53px">
          {t("qRoYPOkmAoqOZnZawkD")}
        </Text>
        <Flex justifyContent={"flex-start"} alignItems={"center"}>
          {/* 姓*/}
          <Text fontSize="14px" w={["60px", "80px"]}>
            {t("msvgVjHCdtHxFKkrS")}
          </Text>
          <Input
            value={userInfo.last_name}
            w={["80px", "95px"]}
            bg="rgba(32, 26, 85, 0.05)"
            fontSize="14px"
            readOnly
          />
          <Text fontSize="14px" textAlign={"center"} ml={"20px"} mr={"10px"}>
            {t("ElMEzQxYcCuZMmCmB")}
          </Text>
          <Input
            value={userInfo.middle_name}
            w={["80px", "95px"]}
            bg="rgba(32, 26, 85, 0.05)"
            fontSize="14px"
            readOnly
          />
          {/* 名 */}
          <Text fontSize="14px" textAlign={"center"} ml={"20px"} mr={"10px"}>
            {t("SUObcbdMwQrjwarfaMi")}
          </Text>
          <Input
            value={userInfo.first_name}
            w={["80px", "95px"]}
            bg="rgba(32, 26, 85, 0.05)"
            fontSize="14px"
            readOnly
          />
        </Flex>
        {/* 曾用名 */}
        <Flex justifyContent={"flex-start"} alignItems={"center"} mt="40px">
          <Text fontSize="14px" w="80px">
            {t("HmoCuzIHcMwWJJxszus")}
          </Text>
          <Input
            w={["80%", "400px"]}
            value={userInfo.former_name}
            fontSize="14px"
            color="t.50"
            bg="rgba(32, 26, 85, 0.05)"
            readOnly
          />
        </Flex>
        {/* nickname */}
        <Flex justifyContent={"flex-start"} alignItems={"center"} mt="40px">
          <Text fontSize="14px" w="80px">
            {t("TGFdcFVaVnCAXfpC")}
          </Text>
          <Input
            w={["60%", "400px"]}
            placeholder={t("FhkGAkzeRaGMUGCREr")}
            fontSize="14px"
            color="t.50"
            value={userInfo.nickname}
            onChange={(e) => changeUserInfoProp("nickname", e)}
          />
          <Button
            w="60px"
            h="30px"
            bg="blue.100"
            color="#fff"
            ml={["10px", "70px"]}
            _hover={{ bg: "blue.80" }}
            fontSize="12px"
            borderRadius={"30px"}
            onClick={() => {
              updateUserInfoProp({ type: "nickname" });
            }}
          >
            {t("cFKHegtmXlwojKtJw")}
          </Button>
        </Flex>
        {/* 常居地址 */}
        <Flex justifyContent={"flex-start"} alignItems={"center"} mt="40px">
          <Text fontSize="14px" w="80px">
            {t("HQSjnUOWYQPuvvTpu")}
          </Text>
          <Input
            w={["60%", "400px"]}
            value={userInfo?.address}
            // value={countryList.length > 0 ? countryList.filter(d => d. === userInfo.address)?.[0].}
            fontSize="14px"
            color="t.50"
            bg="rgba(32, 26, 85, 0.05)"
            // onChange={(e) => changeUserInfoProp("address", e)}
            readOnly
          />
        </Flex>

        <Flex justifyContent={"flex-start"} alignItems={"center"} mt="40px">
          <Text fontSize="14px" w="80px">
            {t("lXmoRtYVpvHfxffpUp")}
          </Text>
          <Input
            w={["80%", "400px"]}
            value={userInfo.birthday}
            fontSize="14px"
            color="t.50"
            bg="rgba(32, 26, 85, 0.05)"
            readOnly
          />
        </Flex>
        <Flex justifyContent={"flex-start"} alignItems={"center"} mt="40px">
          <Text fontSize="14px" w="80px">
            {t("AsLSeryFUtTwDklOIhVE")}
          </Text>
          {/* <Select w={["80%","400px"]} value={userInfo.gender} onChange={genderChange} disabled readOnly>
            <option value="">-</option>
            <option value="male">{t("erZFCFRNsVfSKVMRLN")}</option>
            <option value="female">{t("dmeNSGPySLKtEpPGwG")}</option>
          </Select> */}
          <Input
            w={["80%", "400px"]}
            value={userInfo.gender}
            fontSize="14px"
            color="t.50"
            bg="rgba(32, 26, 85, 0.05)"
            readOnly
          />
          {/* <Button
            w="60px"
            h="30px"
            bg="blue.100"
            color="#fff"
            _hover={{ bg: "blue.80" }}
            fontSize="12px"
            ml={["10px","70px"]}
            borderRadius={"30px"}
            onClick={() => {
              updateGender();
            }}
          >
            {t("MZjVFSmKoCrehUMcH")}
          </Button> */}
        </Flex>
        <Flex justifyContent={"flex-start"} alignItems={"center"} mt="40px">
          <Text fontSize="14px" w="80px">
            {t("eQHAVxHydpVhNAygOp")}
          </Text>
          <Input
            w={["60%", "400px"]}
            fontSize="14px"
            readOnly
            color="t.50"
            bg="rgba(32, 26, 85, 0.05)"
            value={userInfo.area}
          />
        </Flex>
        {/* 邮寄地址 */}
        {/* 邮寄地址、电子结算单选择 */}
        {/* (userInfo.kyc_type === "PERSONAL" || userInfo.kyc_type === "PI") && */}
        {(userInfo.kyc_type === "PERSONAL" || userInfo.kyc_type === "PI") && (
          <Flex
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            mt="40px"
          >
            <Text fontSize="14px" w="80px" flexBasis={"80px"}>
              {t("PufPEQRHcFlNqsAG")}
            </Text>
            <Flex flexDirection={"column"} w={["60%", "400px"]}>
              <Input
                w={["100%", "400px"]}
                value={userInfo?.mail_address}
                fontSize="14px"
                color="t.50"
                bg="rgba(32, 26, 85, 0.05)"
                ml={"8px"}
                onChange={(e) => changeUserInfoProp("mail_address", e)}
              />
              <Flex mt={"5px"}>
                <Image
                  width={"14px"}
                  height={"14px"}
                  mt={"2px"}
                  ml={"8px"}
                  onClick={() => {
                    if (canEditEmailAddress) {
                      setElectronicBill(!electronicBill);
                    }
                  }}
                  cursor={canEditEmailAddress ? "pointer" : "not-allowed"}
                  src={`../../wwwsite/images/kyc/${
                    electronicBill ? "checked.svg" : "no_check.svg"
                  }`}
                />
                <Text fontSize={"13px"} ml={"5px"} fontWeight={600}>
                  {t("XUWTeclWoXtMrQkJzuuq")}
                </Text>
              </Flex>
              {!electronicBill && (
                <span
                  className="kyc-address-desc-text"
                  style={{
                    color: "#D04457",
                    fontSize: "13px",
                    marginTop: "3px",
                    marginLeft: "13px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: t("YCHRDIIKsDzJFQJw"),
                  }}
                ></span>
              )}
            </Flex>
            <Button
              w="60px"
              h="30px"
              bg={"blue.100"}
              color="#fff"
              fontSize="12px"
              _hover={{ bg: "blue.80" }}
              borderRadius={"30px"}
              ml={["10px", "70px"]}
              onClick={() => {
                if (!canEditEmailAddress) {
                  setCanEditEmailAddress(true);
                } else {
                  updateUserInfoProp({ type: "email_bill" });
                }
              }}
            >
              {canEditEmailAddress
                ? t("cFKHegtmXlwojKtJw")
                : t("MZjVFSmKoCrehUMcH")}
            </Button>
          </Flex>
        )}
      </Box>
    </div>
  );
};

export default PersonalInfo;
