import request from "@/hooks/useRequest";
import {
  POSTINCREASINGLIMIT,
  GETINCREASINGLIMITPAGE,
  POSTUPLOAD,
} from "@/helpers/constants/requestApi";

//额度提升
export const postIncreasingLimit = (body) => {
  return request(POSTINCREASINGLIMIT, { body });
};

//额度提升页面配置信息
export const getIncreasingLimitPage = (body) => {
  return request(GETINCREASINGLIMITPAGE, { body });
};

//上传文件
export const postUpload = (body) => {
  return request(POSTUPLOAD, { body });
};

// s3 put url
export const getS3PutUrl = (params) => {
  return request("/web-wallet/api/v1/s3/get-private-put-url", { params });
};
