import { useI18n } from "@/hooks/useI18n";
import { Text, Flex, Image, Box, Button, Input } from "@chakra-ui/react";
import BXNSelect from "@/components/ui/WSelect";
import useHooks from "./hooks";
import BXUpload from "@/components/ui/BXUpload";
import { toastTip } from "@/components/ui/toast";
import BXLoading from "@/components/ui/pay-loading";
import { BXInput } from "@/styles";
import CompanyResult from "../../common/CompanyResult";

const HighRisk = ({ reviewUUID, riskLevel }) => {
  const { t } = useI18n();
  const {
    memoOptions,
    delOrAddCompanyApproveList,
    companyRichApproveList,
    peopleRichApproveList,
    delOrAddPeopleRichApproveList,
    // handleSetInputValue,
    // setListoption,

    canSubmit,
    hanleFileUpload,
    UPLOAD_FILE_TYPE,
    handleSubmit,
    uploadLoading,
    setUpdateRichList,
    PAGE_STATUS,
    currentPage,
  } = useHooks({ reviewUUID, riskLevel });

  const renderOption = (item) => {
    return (
      <Flex alignItems={"center"}>
        <Text fontSize="12px">{item.label}</Text>
      </Flex>
    );
  };
  return (
    <>
      {uploadLoading && <BXLoading />}
      {currentPage === PAGE_STATUS.INIT && (
        <Flex
          flexDirection={"column"}
          style={{ backgroundColor: "#fff" }}
          height={"auto"}
          minHeight={"100vh"}
          width={["calc(100% - 50px)", "400px"]}
          overflow={"hidden"}
          margin={"0 auto"}
          mt={"70px"}
          color={"#010313"}
          fontSize={"14px"}
          alignItems={"center"}
        >
          <Image
            src="../../../wwwsite/images/kyc/success.svg"
            alt=""
            w={"40px"}
            h={"40px"}
          />

          <Text fontSize={"24px"} fontWeight={600} mt={"10px"}>
            {t("JJfMolWuWCFgrDrUiz")}
          </Text>
          <Text mt={"10px"}>{t("hubWrmcrofRaRoYLCAS")}</Text>
          <Flex flexDirection={"column"}>
            <Text
              mt={"50px"}
              color={"#010313"}
              fontWeight={700}
              fontSize={"16px"}
            >
              {t("WbJXVvgJiwcWWgRAYq")}
            </Text>
            <Text mt={"10px"} color={"#7B7C8F"}>
              {t("wcJAFtgivjbkVSdTC")}
            </Text>
          </Flex>

          <Box
            mt={"20px"}
            width={["calc(100% - 50px)", "360px"]}
            pb={"20px"}
            borderBottom={"1px solid  rgba(139, 138, 153, 0.1)"}
          >
            {companyRichApproveList.map((d, i) => {
              return (
                <Box key={i} mt={i !== 0 && "20px"}>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mb={"10px"}
                  >
                    <Text fontSize={"12px"} color={"#010313"} fontWeight={400}>
                      {t("VGmDUnWBzMKfFbMKXyl")}
                    </Text>
                    {i !== 0 && (
                      <Text
                        color={"#D04457"}
                        fontSize={"14px"}
                        cursor={"pointer"}
                        onClick={() => delOrAddCompanyApproveList("delete", i)}
                      >
                        {t("eUvzxeVvXrffrpEOrFrP")}
                      </Text>
                    )}
                  </Flex>
                  <BXNSelect
                    style={{ width: "100%" }}
                    options={memoOptions}
                    renderLabel={(item) => renderOption(item)}
                    renderItem={(item) => renderOption(item)}
                    handleSelect={(item) => {
                      setUpdateRichList({
                        type: "COMPANY_RICH",
                        index: i,
                        key: "selected_option",
                        value: item,
                      });
                    }}
                  ></BXNSelect>
                  <BXUpload
                    fileName={d.fileName}
                    error={d.error}
                    handleUploadChange={(e) => {
                      hanleFileUpload({
                        e,
                        type: UPLOAD_FILE_TYPE.COMPANY,
                        index: i,
                      });
                    }}
                  ></BXUpload>
                  {d.selected_option?.value === 6 && (
                    <BXInput
                      placeholder={t("WjPizXTxbdPnvLWMql")}
                      value={d.inputValue}
                      maxLength={100}
                      onChange={(e) =>
                        setUpdateRichList({
                          type: "COMPANY_RICH",
                          index: i,
                          key: "inputValue",
                          value: e.target.value,
                        })
                      }
                    />
                  )}
                </Box>
              );
            })}
            {/* 添加 */}
            <Flex
              mt={"20px"}
              onClick={() => delOrAddCompanyApproveList("add")}
              width={"80px"}
              cursor={"pointer"}
            >
              <Image
                src={`../../wwwsite/images/kyc/add.png`}
                width={"24px"}
                height={"24px"}
              />
              <Text fontSize={"14px"} color={"#333BF5"} cursor={"pointer"}>
                {t("BggwtUciWOTxbdTdDg")}
              </Text>
            </Flex>
          </Box>
          {/* 实益拥有人财富来源证明 */}
          <Box width={["calc(100% - 50px)", "360px"]}>
            <Text
              color={"#010313"}
              fontSize={"16px"}
              fontWeight={700}
              mt={"20px"}
            >
              {t("XjUPUpsmbzqDVlOyOzD")}
            </Text>
            <Text color={"#7B7C8F"} fontSize={"14px"} mt={"10px"}>
              {t("bYjpHFpkXBumbkZHbM")}
            </Text>
            {peopleRichApproveList.map((d, i) => {
              return (
                <Box key={i} mt={"20px"}>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mb={"10px"}
                  >
                    <Text fontSize={"12px"} color={"#010313"} fontWeight={400}>
                      {t("VGmDUnWBzMKfFbMKXyl")}
                    </Text>
                    {i !== 0 && (
                      <Text
                        color={"#D04457"}
                        fontSize={"14px"}
                        cursor={"pointer"}
                        onClick={() =>
                          delOrAddPeopleRichApproveList("delete", i)
                        }
                      >
                        {t("eUvzxeVvXrffrpEOrFrP")}
                      </Text>
                    )}
                  </Flex>
                  <BXNSelect
                    style={{ width: "100%" }}
                    options={memoOptions}
                    renderLabel={(item) => renderOption(item)}
                    renderItem={(item) => renderOption(item)}
                    handleSelect={(item) => {
                      setUpdateRichList({
                        type: "REALLY_OWNER_RICH",
                        index: i,
                        key: "selected_option",
                        value: item,
                      });
                    }}
                  ></BXNSelect>

                  <BXUpload
                    fileName={d.fileName}
                    error={d.error}
                    handleUploadChange={(e) => {
                      hanleFileUpload({
                        e,
                        type: UPLOAD_FILE_TYPE.PEOPLE,
                        index: i,
                      });
                    }}
                  ></BXUpload>
                  {d.selected_option?.value === 6 && (
                    <BXInput
                      placeholder={t("WjPizXTxbdPnvLWMql")}
                      value={d.inputValue}
                      maxLength={100}
                      onChange={(e) =>
                        setUpdateRichList({
                          type: "REALLY_OWNER_RICH",
                          index: i,
                          key: "inputValue",
                          value: e.target.value,
                        })
                      }
                    />
                  )}
                  <Text
                    color={"#rgba(1, 3, 19, 1)"}
                    fontSize={"12px"}
                    mt={"20px"}
                  >
                    {t("vfiTAWyuaCrULibnur")}
                  </Text>

                  <BXInput
                    key={i}
                    placeholder={t("nZkngKdQlMblSkIsJt")}
                    maxLength={100}
                    value={d.ownerInputValue}
                    onChange={(e) => {
                      setUpdateRichList({
                        type: "REALLY_OWNER_RICH",
                        index: i,
                        key: "ownerInputValue",
                        value: e.target.value,
                      });
                    }}
                  />
                </Box>
              );
            })}

            {/* 添加 */}
            <Flex
              mt={"20px"}
              onClick={() => delOrAddPeopleRichApproveList("add")}
              width={"80px"}
              cursor={"pointer"}
            >
              <Image
                src={`../../wwwsite/images/kyc/add.png`}
                width={"24px"}
                height={"24px"}
              />
              <Text fontSize={"14px"} color={"#333BF5"} cursor={"pointer"}>
                {t("BggwtUciWOTxbdTdDg")}
              </Text>
            </Flex>
          </Box>

          {/* 底部按钮 */}
          <Flex justifyContent={"center"} mt="60px" mb={"50px"}>
            <Button
              fontSize="14px"
              padding="0 30px"
              w={["80%", "270px"]}
              color="#fff"
              fontWeight={"400"}
              borderRadius={"30px"}
              _hover={{ bg: canSubmit ? "blue.80" : "t,50" }}
              cursor={canSubmit ? "pointer" : "not-allowed"}
              bg={canSubmit ? "blue.100" : "t.50"}
              h="44px"
              onClick={() => {
                if (canSubmit) {
                  handleSubmit();
                } else {
                  toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
                }
              }}
            >
              {t("YChcfaxDaLscdSPiRQz")}
            </Button>
          </Flex>
        </Flex>
      )}

      {currentPage === PAGE_STATUS.RESULT && <CompanyResult />}
    </>
  );
};

export default HighRisk;
