import {useEffect, useState} from "react";
import {getS3PutUrl} from "@/pages/newKyc/person/verify/api";
import axios from "axios";
import {S3_AWS_KMS_KEY_ID} from "@/config";
import {jsUuid} from "@/helpers/func/stringPro";
import {getDepositCreate} from "@/pages/deposit/api";

const useFiatDepositHooks = () => {
    const [filePath, setFilePath] = useState(""); // 文件地址
    const [fileName, setFileName] = useState(""); // 文件name
    const [uploadLoading, setUploadLoading] = useState(false); // 上传loading
    const [validFile, setValidFile] = useState(false); // 文件类型校验
    const [fileType, setFileType] = useState(""); // 文件类型
    const [viewStatus, setViewStatus] = useState("INIT");
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [errorType, setErrorType] = useState("");
    const [uuid, setUuid] = useState("");

    useEffect(() => {
        setUuid(jsUuid());
    }, []);

    const handleFile = (e) => {
        const file = e.target.files[0];

        if (file) {
            const { size, name } = file;
            const fileNameSuffix = name
                .split(".")
                [name.split(".").length - 1].toLocaleLowerCase();
            if (
                size / 1000000 < 5 &&
                (fileNameSuffix === "pdf" || fileNameSuffix === "jpg")
            ) {
                setFileType(fileNameSuffix);
                setValidFile(true);
                uploadFn(file, fileNameSuffix);
            } else {
                setValidFile(false);
                setFilePath("error");
            }
            setFileName(name);
        }
    };

    const uploadFn = async (file, fileNameSuffix) => {
        setUploadLoading(true);
        const res = await getS3PutUrl({
            prefix: "fiat_info",
            file_type: fileNameSuffix,
        });
        if (res.code === 0) {
            const putUrl = res?.data?.put_url;
            const filePath = res?.data?.file_path;
            axios({
                url: putUrl,
                method: "put",
                headers: {
                    "X-Amz-Server-Side-Encryption": "aws:kms",
                    "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
                },
                data: file,
            }).then((uploadRes) => {
                if (uploadRes.status === 200) {
                    setFilePath(filePath);
                    setUploadLoading(false);
                }
            });
        }
    };

    const onSubmit = async (data) => {
        setErrorType("");
        if (filePath === "error") {
            return;
        }
        if (!filePath) {
            setErrorType("empty_error");
            return;
        }

        if (isSubmiting) return;

        setIsSubmiting(true);
        const body = {
            request_id: uuid,
            bank_account_id: data.accountNo,
            receipt: filePath,
            currency: data.currentCurrency
        };
        try {
            const res = await getDepositCreate(body);
            if (res.code === 0) {
                setViewStatus("PENDING");
                setIsSubmiting(false);
            } else {
                setUuid(jsUuid());
            }
        } catch {
            setIsSubmiting(false);
        }
    };

    return {
        onSubmit,
        handleFile,
        viewStatus,
        validFile,
        uploadLoading,
        fileName,
        filePath,
        errorType,
    };
};

export { useFiatDepositHooks };