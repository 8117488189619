import PageWrap from "@/components/PageWrap";
import { Box, Flex, Text, Center } from "@chakra-ui/react";
import AccountAndSafe from "./accountAndSafe";
import PersonalInfo from "./personalInfo";
import Setting from "./setting";
import { BIXIN_ACCOUNTMANAGER } from "@/helpers/constants/routeName";
import { useI18n } from "@/hooks/useI18n";
import { useState, useEffect, useMemo } from "react";
import { getPageQuery } from "@/utils";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "./api";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "@/state/user/reducer";
import { KYC_TYPE } from "@/constants/kyc";

// 公司信息不在这里展示了
const AccountManager = () => {
  const navigate = useNavigate();
  const urlParams = getPageQuery();
  const { t } = useI18n();
  const current = Number(urlParams.current);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState();

  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      setUserInfo(res.data);
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };

  useEffect(() => {
    getUserInfoFn(getUserInfoFn);
  }, []);

  const menu = useMemo(() => {
    return [
      {
        name: t("POdaGgvMxQRsisLbVcll"),
        id: 1,
        checked: true,
      },
      {
        name: t("qRoYPOkmAoqOZnZawkD"),
        id: 2,
        checked: false,
      },
      {
        name: t("SgWXitfFwQHiWicT"),
        id: 3,
        checked: false,
      },
    ];
  }, [t]);

  // eslint-disable-next-line no-unused-vars
  const [list, setList] = useState(menu);
  useEffect(() => {
    setList(menu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  useEffect(() => {
    changeList(current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const changeList = (id) => {
    const newList = [...menu];
    newList.forEach((item) => {
      if (item.id === id) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    setList(newList);
    navigate(`${BIXIN_ACCOUNTMANAGER}?current=${id}`);
  };
  return (
    <PageWrap>
      <Box
        h="60px"
        background={"#fff"}
        boxShadow={"0px 10px 40px 0px rgba(0, 0, 0, 0.05)"}
      >
        <Center>
          <Flex
            w={["90%", "1200px"]}
            h="60px"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text fontSize="16px" coloe="t.100" fontWeight={"500"}>
              {t("wvzbJUEmNoCiDQLTaWzH")}
            </Text>
            <Flex justifyContent={"flex-start"}>
              {list?.length > 0 &&
                list.map((item) => {
                  if (item.id !== 2) {
                    return (
                      <Text
                        fontSize="14px"
                        color={item.checked ? "t.80" : "t.100"}
                        cursor={"pointer"}
                        _hover={{ color: "t.80" }}
                        key={item.id}
                        ml={item.id > 1 && "30px"}
                        onClick={() => {
                          changeList(item.id);
                        }}
                      >
                        {item.name}
                      </Text>
                    );
                  } else {
                    if (
                      userInfo?.kyc_type !== KYC_TYPE.CORPORATION &&
                      userInfo?.kyc_type !== KYC_TYPE.INSTITUTION
                    ) {
                      return (
                        <Text
                          fontSize="14px"
                          color={item.checked ? "t.80" : "t.100"}
                          cursor={"pointer"}
                          _hover={{ color: "t.80" }}
                          key={item.id}
                          ml={item.id > 1 && "30px"}
                          onClick={() => {
                            changeList(item.id);
                          }}
                        >
                          {item.name}
                        </Text>
                      );
                    }
                  }
                })}
            </Flex>
          </Flex>
        </Center>
      </Box>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        pt={"60px"}
      >
        <Center>
          {current === 1 && <AccountAndSafe />}
          {current === 2 && <PersonalInfo />}
          {current === 3 && <Setting />}
        </Center>
      </Box>
    </PageWrap>
  );
};

export default AccountManager;
