import request from "@/hooks/useRequest";

// 获取kyc个人提交信息
export const getKycPerosonalInfoApi = (body) => {
  return request("/web-wallet/api/v1/kyc/information", { body });
};

// 提交kyc个人信息
export const postKycPerosonalInfoApi = (body) => {
  return request("/web-wallet/api/v1/kyc/information", { body });
};

// s3 put url
export const getS3PutUrlApi = (params) => {
  return request("/web-wallet/api/v1/s3/get-private-put-url", { params });
};
