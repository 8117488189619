import { useMemo, useState } from "react";
import { useI18n } from "@/hooks/useI18n";
import { handleS3UploadFile } from "@/utils/upload";
import { submitCompanyUpdateInfoApi } from "@/api/perodic";

const UPLOAD_FILE_PREFIX = "company_updates";
const MAX_COUNT = 6;
const useHooks = ({ reviewUUID }) => {
  const { t } = useI18n();

  const companyFileObj = {
    fileName: "",
    error: false,
    filePath: "",
    inputValue: "", // 其他说明
    selected_option: "",
  };

  const peopleFileObj = {
    fileName: "",
    error: false,
    filePath: "",
    selected_option: "",
    inputValue: "", // 其他说明
    ownerInputValue: "",
  };

  const UPLOAD_FILE_TYPE = {
    COMPANY: "COMPANY",
    PEOPLE: "PEOPLE",
  };

  const PAGE_STATUS = {
    INIT: "INIT",
    RESULT: "RESULT",
  };

  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading
  const [companyRichApproveList, setCompanyRichApproveList] = useState([
    companyFileObj,
  ]); // 公司财富来源证明
  const [peopleRichApproveList, setPeopleRichApproveList] = useState([
    peopleFileObj,
  ]);
  const [currentPage, setCurrentPage] = useState(PAGE_STATUS.INIT);
  // const [showTipModal, setShowTipModal] = useState(false);

  const memoOptions = useMemo(() => {
    return [
      {
        label: t("trJoqkSnMVAvbRgAY"),
        value: 1,
        key: "business_earnings",
      },
      {
        label: t("olJXBlENebamalqAmC"),
        value: 2,
        key: "funds_from_investors",
      },
      {
        label: t("lnaNMhKSbkHzoYmMHp"),
        value: 3,
        key: "fund_from_shareholders",
      },
      {
        label: t("MFJyykUbSXUkUqHxlJ"),
        value: 4,
        key: "business_loan",
      },
      {
        label: t("UsVahXMYImjwSrxR"),
        value: 5,
        key: "sale_of_property",
      },
      {
        label: t("KSyKpOAjkLSpCGns"),
        value: 6,
        key: "other",
      },
    ];
  }, [t]);

  const delOrAddCompanyApproveList = (type, index) => {
    setCompanyRichApproveList((pre) => {
      const currList = [...companyRichApproveList];
      if (currList.length === MAX_COUNT) return currList;
      if (type === "add") {
        currList.push(companyFileObj);
        return currList;
      } else if (type === "delete") {
        if (pre.length > 1) {
          currList.splice(index, 1);
          return currList;
        }
        return currList;
      }
    });
  };

  const delOrAddPeopleRichApproveList = (type, index) => {
    setPeopleRichApproveList((pre) => {
      const currList = [...peopleRichApproveList];
      if (currList.length === MAX_COUNT) return currList;
      if (type === "add") {
        currList.push(peopleFileObj);
        return currList;
      } else if (type === "delete") {
        if (pre.length > 1) {
          currList.splice(index, 1);
          return currList;
        }
        return currList;
      }
    });
  };

  const canSubmit = useMemo(() => {
    let flag = true;
    if (companyRichApproveList.length > 0 && peopleRichApproveList.length > 0) {
      for (let i = 0; i < companyRichApproveList.length; i++) {
        const curr = companyRichApproveList[i];
        const { fileName, filePath, inputValue, selected_option, error } = curr;
        if (!fileName || !filePath || !selected_option || error) {
          flag = false;
          break;
        }

        if (selected_option?.value === 6) {
          if (!inputValue) {
            flag = false;
            break;
          }
        }
      }

      for (let i = 0; i < peopleRichApproveList.length; i++) {
        const curr = peopleRichApproveList[i];
        const {
          fileName,
          filePath,
          inputValue,
          selected_option,
          ownerInputValue,
          error,
        } = curr;
        if (
          !fileName ||
          !filePath ||
          !selected_option ||
          !ownerInputValue ||
          error
        ) {
          flag = false;
          break;
        }

        if (selected_option?.value === 6) {
          if (!inputValue) {
            flag = false;
            break;
          }
        }
      }
    } else {
      flag = false;
    }

    return flag;
  }, [peopleRichApproveList, companyRichApproveList]);

  // 更新财富证明/实益人财富证明列表
  // type
  // index 更新的列表的索引
  const setUpdateRichList = ({ type, index, key, value }) => {
    if (type === "COMPANY_RICH") {
      setCompanyRichApproveList((prev) => {
        const currList = [...prev];
        currList[index] = Object.assign({}, currList[index], {
          [key]: value,
        });
        return currList;
      });
    } else if (type === "REALLY_OWNER_RICH") {
      setPeopleRichApproveList((prev) => {
        const currList = [...prev];
        currList[index] = Object.assign({}, currList[index], {
          [key]: value,
        });
        return currList;
      });
    }
  };

  const hanleFileUpload = async ({ e, index, type }) => {
    const file = e.target.files[0];
    const validTypes = ["jpg", "pdf"];
    if (file) {
      const { size, name } = file;
      const fileTypeSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();

      if (validTypes.indexOf(fileTypeSuffix) > -1 && size / 1000000 < 5) {
        setUploadLoading(true);
        const uploadRes = await handleS3UploadFile({
          prefix: UPLOAD_FILE_PREFIX,
          file_type: fileTypeSuffix,
          file,
        });
        if (uploadRes.status !== "error") {
          const { filePath } = uploadRes;
          if (type === UPLOAD_FILE_TYPE.COMPANY) {
            setCompanyRichApproveList((prev) => {
              const list = [...prev];
              const curr = list[index];
              curr.error = false;
              curr.fileName = name;
              curr.filePath = filePath;
              return list;
            });
          } else {
            setPeopleRichApproveList((prev) => {
              const list = [...prev];
              const curr = list[index];
              curr.error = false;
              curr.fileName = name;
              curr.filePath = filePath;
              return list;
            });
          }

          setUploadLoading(false);
        }
      } else {
        if (type === UPLOAD_FILE_TYPE.COMPANY) {
          setCompanyRichApproveList((prev) => {
            const list = [...prev];
            const curr = list[index];
            curr.error = true;
            curr.fileName = name;
            return list;
          });
        } else {
          setPeopleRichApproveList((prev) => {
            const list = [...prev];
            const curr = list[index];
            curr.error = true;
            curr.fileName = name;
            return list;
          });
        }
      }
    }
  };

  const handleSubmit = async () => {
    let params = {
      is_updated: true,
      review_uuid: reviewUUID,
    };

    const wealth_source_documents = [];
    for (let i = 0; i < companyRichApproveList.length; i++) {
      const item = companyRichApproveList[i];
      wealth_source_documents.push({
        document: {
          document_type: "source_of_wealth_proof_of_company",
          document_type_comment: item?.selected_option?.key,
          document_path: item?.filePath,
        },
        other_description: item?.inputValue,
      });
    }
    params.wealth_source_documents = wealth_source_documents;

    const ubo_wealth_source = [];
    for (let i = 0; i < peopleRichApproveList.length; i++) {
      const item = peopleRichApproveList[i];
      ubo_wealth_source.push({
        ubo_wealth_source_documents: [
          {
            document: {
              document_type:
                "source_of_wealth_proof_of_ultimate_beneficial_owner",
              document_type_comment: item?.selected_option?.key,
              document_path: item?.filePath,
            },
            other_description: item?.inputValue,
          },
        ],
        ubo_names: item?.ownerInputValue,
      });
    }
    params.ubo_wealth_source = ubo_wealth_source;
    const submitRes = await submitCompanyUpdateInfoApi(params);
    if (submitRes.code === 0 && submitRes?.data?.ok) {
      setCurrentPage(PAGE_STATUS.RESULT);
    }
  };

  return {
    companyRichApproveList,
    memoOptions,
    delOrAddCompanyApproveList,
    peopleRichApproveList,
    delOrAddPeopleRichApproveList,
    canSubmit,
    hanleFileUpload,
    UPLOAD_FILE_TYPE,
    handleSubmit,
    uploadLoading,
    setUpdateRichList,
    PAGE_STATUS,
    currentPage,
  };
};

export default useHooks;
