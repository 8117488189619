import { useNavigate } from "react-router-dom";

import { Text, Button } from "@chakra-ui/react";
import { ArrowBackIcon } from '@chakra-ui/icons';

import { BIXIN_WALLET } from '@/helpers/constants/routeName';

import { useI18n } from "@/hooks/useI18n";

const BackWalletBtn = (props) => {
    const navigate = useNavigate();

    const { t } = useI18n();

    const { btnDesc, routeName, customizeBackEvent  } = props;

    const btnClick  = () => {
        if (customizeBackEvent) {
            customizeBackEvent();
        } else {
            navigate(routeName || BIXIN_WALLET)
        }
    }

    return (
        <Button 
        minW="120px" 
        height="40px"
        bgColor="rgba(32, 26, 85, 0.05)" 
        borderLeftRadius={['0px', '32px']}
        borderRightRadius="32px"
        border
        onClick={btnClick}>
            <ArrowBackIcon mr="10px" />
            <Text
                fontSize={'14px'}
                fontWeight={400}
                color={'#010313'}
            >{btnDesc || t('CKVRBnYMLalEUVhYrqI')}</Text>
        </Button>
    )
}

export default BackWalletBtn;