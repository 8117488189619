// firstPage + secnodPage = 高风险
import { Flex, Image, Box, Text } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import BXNSelect from "@/components/ui/WSelect";
import { BXInput, BXButton } from "@/styles";
import { useMedia } from "@/hooks/useMedia";

const SecondPage = ({
  richApproveList,
  MEMO_RICH_APPROVE_LIST,
  reallyOwnerRichApproveList,
  delOrAddRichApproveList,
  handleUploadFile,
  setUpdateRichList,
  memoCompayRichApproveList,
  memoReallyOwnerichApproveList,
}) => {
  const { t } = useI18n();
  const { isMobile } = useMedia();

  const renderUploadItem = (type, item, i) => {
    return (
      <Box position="relative">
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <BXInput
            w="80px"
            type="file"
            opacity={"0"}
            position={"absolute"}
            right="0"
            zIndex={"2"}
            cursor="pointer"
            fontSize="0"
            onChange={(e) => {
              handleUploadFile({
                uploadType: type,
                event_core: e,
                faIndex: i,
                currIndex: i,
              });
            }}
          />
          <BXInput
            w={["220px", "310px"]}
            className="upload"
            disabled
            _disabled={{
              cursor: "no-drop",
            }}
            value={item?.fileName}
          />
          <BXButton
            w="80px"
            h="30px"
            mt={"10px"}
            fontSize={"12px"}
            borderRadius={"20px"}
            ml={isMobile ? "10px" : ""}
          >
            {t("NqgOQEXQaHsramCnbX")}
          </BXButton>
        </Flex>
        <Text
          mt={"5px"}
          fontSize={"10px"}
          color={item?.error ? "#D04457" : "#7B7C8F"}
        >
          {t("ZAhuzRDlLUTXhKrhhx")}
        </Text>
      </Box>
    );
  };

  return (
    <>
      <Text fontSize={"16px"} fontWeight={700} color={"#010313"} mt={"40px"}>
        {t("WbJXVvgJiwcWWgRAYq")}
      </Text>
      <Text fontSize={"14px"} color={"#7B7C8F"} mt={"10px"} mb={"10px"}>
        {t("EcnKFNIFTwRUshkf")}
      </Text>
      {/* 财富证明 */}
      <Flex flexDirection={"column"}>
        {richApproveList.map((d, i) => {
          return (
            <Flex key={i} flexDirection={"column"}>
              <Flex
                mb={"10px"}
                mt={"10px"}
                fontWeight={500}
                fontSize={"14px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"12px"} color={"#010313"}>
                  {t("VGmDUnWBzMKfFbMKXyl")}
                </Text>
                {i !== 0 && (
                  <Text
                    color={"#D04457"}
                    cursor={"pointer"}
                    onClick={() =>
                      delOrAddRichApproveList("COMPANY_RICH", "delete", i)
                    }
                  >
                    {t("eUvzxeVvXrffrpEOrFrP")}
                  </Text>
                )}
              </Flex>
              <BXNSelect
                key={i}
                options={MEMO_RICH_APPROVE_LIST}
                handleSelect={(item) => {
                  setUpdateRichList({
                    type: "COMPANY_RICH",
                    index: i,
                    key: "selected_option",
                    value: item,
                  });
                }}
                defaultOpt={MEMO_RICH_APPROVE_LIST.find(
                  (opt) => opt.value === d?.selected_option?.value
                )}
              ></BXNSelect>
              {renderUploadItem("COMPANY_RICH", d, i)}
              {/* 选择其他时候有一个说明文字框 */}
              {d.selected_option?.value === 6 && (
                <BXInput
                  placeholder={t("WjPizXTxbdPnvLWMql")}
                  value={d.inputValue}
                  maxLength={100}
                  onChange={(e) =>
                    setUpdateRichList({
                      type: "COMPANY_RICH",
                      index: i,
                      key: "inputValue",
                      value: e.target.value,
                    })
                  }
                />
              )}
            </Flex>
          );
        })}
        <Flex
          mt={"20px"}
          onClick={() => {
            delOrAddRichApproveList("COMPANY_RICH", "add");
          }}
          width={"80px"}
          cursor={"pointer"}
        >
          <Image
            src={`../../wwwsite/images/kyc/add.png`}
            width={"24px"}
            height={"24px"}
          />
          <Text fontSize={"14px"} color={"#333BF5"} cursor={"pointer"}>
            {t("BggwtUciWOTxbdTdDg")}
          </Text>
        </Flex>
      </Flex>
      {/* 拥有人财富来源证明 */}
      <Flex
        flexDirection={"column"}
        mt={"50px"}
        borderTop={"1px solid rgba(227, 228, 241, 1)"}
        paddingTop={"20px"}
      >
        <Text fontSize={"16px"} fontWeight={700} color={"#010313"}>
          {t("XjUPUpsmbzqDVlOyOzD")}
        </Text>
        <Text fontSize={"14px"} color={"#7B7C8F"} mt={"10px"} mb={"10px"}>
          {t("bYjpHFpkXBumbkZHbM")}
        </Text>
        {reallyOwnerRichApproveList.map((d, i) => {
          return (
            <Flex key={i} flexDirection={"column"}>
              <Flex
                mb={"10px"}
                fontWeight={500}
                fontSize={"14px"}
                mt={i === 0 ? "10px" : "30px"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"12px"} color={"#010313"}>
                  {t("VGmDUnWBzMKfFbMKXyl")}
                </Text>
                {i !== 0 && (
                  <Text
                    color={"#D04457"}
                    cursor={"pointer"}
                    onClick={() =>
                      delOrAddRichApproveList("REALLY_OWNER_RICH", "delete", i)
                    }
                  >
                    {t("eUvzxeVvXrffrpEOrFrP")}
                  </Text>
                )}
              </Flex>
              <BXNSelect
                options={MEMO_RICH_APPROVE_LIST}
                handleSelect={(item) => {
                  setUpdateRichList({
                    type: "REALLY_OWNER_RICH",
                    index: i,
                    key: "selected_option",
                    value: item,
                  });
                }}
                defaultOpt={MEMO_RICH_APPROVE_LIST.find(
                  (opt) => opt.value === d?.selected_option?.value
                )}
              ></BXNSelect>
              {renderUploadItem("REALLY_OWNER_RICH", d, i)}
              {/* 选择其他时候有一个说明文字框 */}
              {d.selected_option?.value === 6 && (
                <BXInput
                  placeholder={t("WjPizXTxbdPnvLWMql")}
                  value={d.inputValue}
                  maxLength={100}
                  onChange={(e) =>
                    setUpdateRichList({
                      type: "REALLY_OWNER_RICH",
                      index: i,
                      key: "inputValue",
                      value: e.target.value,
                    })
                  }
                />
              )}
              <Text fontSize={"12px"} color={"#010313"} mt={"10px"}>
                {t("vfiTAWyuaCrULibnur")}
              </Text>
              <BXInput
                placeholder={t("nZkngKdQlMblSkIsJt")}
                mt={"10px"}
                maxLength={100}
                onChange={(e) =>
                  setUpdateRichList({
                    type: "REALLY_OWNER_RICH",
                    index: i,
                    key: "ownerInputValue",
                    value: e.target.value,
                  })
                }
              />
            </Flex>
          );
        })}
        <Flex
          mt={"20px"}
          onClick={() => {
            delOrAddRichApproveList("REALLY_OWNER_RICH", "add");
          }}
          width={"80px"}
          cursor={"pointer"}
        >
          <Image
            src={`../../wwwsite/images/kyc/add.png`}
            width={"24px"}
            height={"24px"}
          />
          <Text fontSize={"14px"} color={"#333BF5"} cursor={"pointer"}>
            {t("BggwtUciWOTxbdTdDg")}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default SecondPage;
