import request from "@/hooks/useRequest";

// 提交
export const postCompanyInfo = (body) => {
  return request("/web-wallet/api/v1/kyc/company", { body });
};

// 公司kyc认证进度
export const getCompanyProcessInfoApi = () => {
  return request("/web-wallet/api/v1/kyc/company/process-info");
};

// s3 put url
export const getS3PutUrl = (params) => {
  return request("/web-wallet/api/v1/s3/get-private-put-url", { params });
};
