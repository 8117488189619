import request from "@/hooks/useRequest";
import {
  GETASSETINFO,
  GETAGREEMENTPRIVACYSHOW,
  POSTAGREEMNETPRIVACYCONFIRM,
  GETTRANSACCOUNTS,
  GETEXCHANGERATE,
  GETMARKETFEX,
  GETUSERINFO,
} from "@/helpers/constants/requestApi";

// 钱包账户信息
export const getAssetInfo = () => {
  return request(GETASSETINFO, {});
};

//交易账户信息
export const getTransAccount = () => {
  return request(GETTRANSACCOUNTS, {});
};

// 展示协议弹窗
export const getIsShowPrivacy = () => {
  return request(GETAGREEMENTPRIVACYSHOW, {});
};

// 确认协议
export const postConfirmPrivacy = () => {
  return request(POSTAGREEMNETPRIVACYCONFIRM, { body: {} });
};

//获取法币汇率
export const getMarketFex = () => {
  return request(GETMARKETFEX, {});
};

//获取交易对汇率
export const getExchangeRate = (params) => {
  return request(GETEXCHANGERATE, { params });
};

//获取用户信息
export const getUserInfo = (params) => {
  return request(GETUSERINFO, { params });
};
