import { useMedia } from "@/hooks/useMedia";
import PageWrap from "@/components/PageWrap";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import AddBtn from "./components/AddBtn";
import BackBtn from "./components/BackBtn";
import AddressList from "./components/AddressList";

const WhiteAddress = () => {
  const { isMobile } = useMedia();
  const { t } = useI18n();
  // const navigate = useNavigate();

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        pt={!isMobile ? "30px" : "0"}
      >
        <Box w={["100%", "1200px"]} margin={"0 auto"}>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            flexDirection={isMobile ? "column" : "row"}
            padding={"0 30px"}
          >
            {!isMobile ? (
              <>
                <BackBtn />
                <Text size={24} fontWeight={600}>
                  {t("wEarLFdfZMShsSfacNU")}
                </Text>
                <AddBtn />
              </>
            ) : (
              <>
                <Flex w={"100%"} justifyContent={"space-between"}>
                  <BackBtn />
                </Flex>
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  w={"100%"}
                >
                  <Text size={24} fontWeight={600}>
                    {t("wEarLFdfZMShsSfacNU")}
                  </Text>
                  <AddBtn />
                </Flex>
              </>
            )}
          </Flex>

          <Box mt={["30px", "85px"]} padding={"0 30px"} paddingBottom={"40px"}>
            <AddressList />
          </Box>
        </Box>
      </Box>
    </PageWrap>
  );
};

export default WhiteAddress;
