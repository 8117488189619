import { Box } from "@chakra-ui/react";
const AlertDom = (props) => {
    const { children } = props;
    return (
        <Box
            position={'fixed'}
            left="0px"
            top="0px"
            right="0px"
            bottom="0px"
            backgroundColor={'rgba(0, 0, 0, 0.6)'}
            zIndex={1000}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Box
                backgroundColor={'#FFFFFF'}
                boxShadow={'0px 20px 40px rgba(0, 0, 0, 0.1)'}
                borderRadius={'20px'}
                w="370px"
            >{children}</Box>
        </Box>
    )
}

export default AlertDom;