import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";
import { useHooks } from "./hooks";
import { Center, Box, Text, Image, Flex } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import BXLoading from "@/components/ui/pay-loading";
import { BXButton } from "@/styles";
import { useEffect, useMemo, useState } from "react";
import CompanyResult from "../../common/CompanyResult";
import ConfirmModal from "../../common/ConfirmModal";
import { RISK_LEVEL } from "@/constants/kyc";
import { toastTip } from "@/components/ui/toast";
// 低中风险用户

const CompanyChange = ({ riskLevel, reviewUUID, canPeriodic }) => {
  const {
    MEMO_SELECT_OPTIONS,
    setUpdateInfoListOption,
    OPTION_TYPE,
    delOrAddMulUploadList,
    updateInfoList,
    delOrAddUpdasteList,
    MEMO_SERVICE_OPTIONS,
    serviceInputValue,
    setServiceInputValue,
    monthTxFrequencyInputValue,
    setMonthTxFrequencyInputValue,
    foundSourceInputValue,
    setFoundSourceInputValue,
    serviceOtherDescInputValue,
    setServiceOtherDescInputValue,
    richApproveList,
    MEMO_RICH_APPROVE_LIST,
    reallyOwnerRichApproveList,
    setReallyOwnerRichApproveList,
    delOrAddRichApproveList,
    handleUploadFile,
    setUpdateRichList,
    uploadLoading,
    firstPageCanSubmit,
    secondPageCanSubmit,
    resetMulUploadList,
    memoUpdateFilterOptions,
    handleSubmit,
    // memoCompayRichApproveList,
    // memoReallyOwnerichApproveList,
    currentPage,
    setCurrentPage,
    PAGE_STATUS,
    showTipModal,
    setShowTipModal,
    resetOtherKeyValue,
  } = useHooks({ riskLevel, reviewUUID });
  const { t } = useI18n();

  useEffect(() => {
    if (!canPeriodic) {
      setCurrentPage(PAGE_STATUS.RESULT);
    }
  }, [canPeriodic]);

  const memoBtnText = useMemo(() => {
    if (riskLevel !== RISK_LEVEL.HIGH && currentPage === PAGE_STATUS.FIRST) {
      return t("YChcfaxDaLscdSPiRQz");
    }
    if (riskLevel === RISK_LEVEL.HIGH) {
      if (currentPage === PAGE_STATUS.FIRST) {
        return t("tOaRBVUJHpnSgDBrGON");
      } else if (currentPage === PAGE_STATUS.SECOND) {
        return t("YChcfaxDaLscdSPiRQz");
      }
    }
  }, [currentPage, riskLevel, t]);

  const btnDisabled = useMemo(() => {
    if (riskLevel !== RISK_LEVEL.HIGH) {
      if (!firstPageCanSubmit) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!firstPageCanSubmit && currentPage === PAGE_STATUS.FIRST) {
        return true;
      } else if (!secondPageCanSubmit && currentPage === PAGE_STATUS.SECOND) {
        return true;
      } else {
        return false;
      }
    }
  }, [firstPageCanSubmit, secondPageCanSubmit, riskLevel, currentPage]);

  return (
    <Box w={["80%", "400px"]} margin={"0 auto"} mt={"40px"} pb={"100px"}>
      {currentPage !== PAGE_STATUS.RESULT && (
        <Center>
          <Image src="../../wwwsite/images/kyc/company.svg" />
          <Text fontSize={32} fontWeight={700} ml={"10px"}>
            {t("onOjgIPTpDDXLIwlSWE")}
          </Text>
        </Center>
      )}
      {currentPage === PAGE_STATUS.RESULT && <CompanyResult />}
      {currentPage === PAGE_STATUS.FIRST && (
        <FirstPage
          handleUploadFile={handleUploadFile}
          MEMO_SELECT_OPTIONS={MEMO_SELECT_OPTIONS}
          setUpdateInfoListOption={setUpdateInfoListOption}
          OPTION_TYPE={OPTION_TYPE}
          delOrAddMulUploadList={delOrAddMulUploadList}
          updateInfoList={updateInfoList}
          memoUpdateFilterOptions={memoUpdateFilterOptions}
          delOrAddUpdasteList={delOrAddUpdasteList}
          MEMO_SERVICE_OPTIONS={MEMO_SERVICE_OPTIONS}
          serviceInputValue={serviceInputValue}
          setServiceInputValue={setServiceInputValue}
          setFoundSourceInputValue={setFoundSourceInputValue}
          monthTxFrequencyInputValue={monthTxFrequencyInputValue}
          setMonthTxFrequencyInputValue={setMonthTxFrequencyInputValue}
          foundSourceInputValue={foundSourceInputValue}
          setServiceOtherDescInputValue={setServiceOtherDescInputValue}
          serviceOtherDescInputValue={serviceOtherDescInputValue}
          resetMulUploadList={resetMulUploadList}
          resetOtherKeyValue={resetOtherKeyValue}
        />
      )}
      {riskLevel === RISK_LEVEL.HIGH && currentPage === PAGE_STATUS.SECOND && (
        <SecondPage
          reallyOwnerRichApproveList={reallyOwnerRichApproveList}
          setReallyOwnerRichApproveList={setReallyOwnerRichApproveList}
          MEMO_RICH_APPROVE_LIST={MEMO_RICH_APPROVE_LIST}
          // memoCompayRichApproveList={memoCompayRichApproveList}
          richApproveList={richApproveList}
          delOrAddRichApproveList={delOrAddRichApproveList}
          handleUploadFile={handleUploadFile}
          setUpdateRichList={setUpdateRichList}
          // memoReallyOwnerichApproveList={memoReallyOwnerichApproveList}
        />
      )}
      {uploadLoading && <BXLoading />}
      <ConfirmModal
        show={showTipModal}
        onCancel={() => setShowTipModal(false)}
        onOk={() => handleSubmit()}
      />
      {currentPage !== PAGE_STATUS.RESULT && (
        <Flex justifyContent={"center"} mt="30px" mb={"50px"}>
          {/* -低风险 则显示提交 */}
          {/* -高风险 在第一页则显示下一步 第二页则是提交*/}
          <BXButton
            className={btnDisabled ? "disabled" : ""}
            w={["100%", "270px"]}
            onClick={() => {
              // 低中风险
              if (riskLevel !== RISK_LEVEL.HIGH) {
                if (firstPageCanSubmit) {
                  setShowTipModal(true);
                } else {
                  toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
                }
              }
              // 高风险
              if (riskLevel === RISK_LEVEL.HIGH) {
                if (currentPage === PAGE_STATUS.FIRST) {
                  if (firstPageCanSubmit) {
                    setCurrentPage(PAGE_STATUS.SECOND);
                  } else {
                    toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
                  }
                }
                if (currentPage === PAGE_STATUS.SECOND) {
                  if (secondPageCanSubmit) {
                    setShowTipModal(true);
                  } else {
                    toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
                  }
                }
              }
            }}
          >
            {memoBtnText}
          </BXButton>
        </Flex>
      )}
    </Box>
  );
};

export default CompanyChange;
