import PropTypes from "prop-types";

import {
  Box,
  Flex,
  Text,
  Select,
  Image,
  Input,
  NumberInput,
  NumberInputField,
  Divider,
  Center,
} from "@chakra-ui/react";
import copyToClipboard from "copy-to-clipboard";
import { toastTip } from "@/components/ui/toast";
import DropDownBox from "@/pages/historyBill/component/dropDownBox";
import { useAppSelector } from "@/state/hooks";
import { useI18n } from "@/hooks/useI18n";

const CommonUnitStyle = (props) => {
  const { t } = useI18n();
  const primary_currency = useAppSelector(
    (state) => state.user.userInfo.primary_currency
  );

  const {
    title,
    leftLineBg,
    leftLineHeight,
    selectContent,
    selectPlaceholder,
    selectedValue,
    showText,
    showTitleRightBtn,
    TitleRightBtnText,
    rightBtnEvent,
    canCopyText,
    selectedEvent,
    showInput,
    textInputPlaceholder,
    textInputOnchange,
    showNumberInput,
    numberTopDesc,
    numberBottomDesc,
    numberInputOnchange,
    numberErrortext,
    numberInputPlaceholder,
    showSelectedInput,
    leftSelectedList,
    leftSelectedValue,
    getSelectedInputLeftValue,
    inputOnBlurGetValue,
    inputBottomIcon,
    inputBottomIconDesc,
    inputContentUnit,
    inputValue,
    showPasteInput,
    fillInAll,
    renderTipBox,
    formatSelect,
    withdrawLimit,
    showCenterAddBtn,
    centerAddBtnText,
    centerAddBtnEvent,
    numberMinWithdrawDesc,
    numberFeeDesc,
    numberFeeEvent,
    leftPointVisible,
    leftLineVisible,
    titleStyle,
    decimal,
  } = props;

  // select选择
  const getFilterValue = (e) => {
    selectedEvent(e.target.value);
  };

  const titleRightClickEvent = () => {
    rightBtnEvent();
  };

  // 复制
  const clickCopy = () => {
    copyToClipboard(canCopyText);
    toastTip(t("gXbirwakdsaUKBglwK"), { type: "success" });
  };

  const getInputValue = (e) => {
    console.log("获取到的输入框的值", e.target.value);
    if (textInputOnchange) textInputOnchange(e.target.value);
  };

  const inputOnBlurEvent = (e) => {
    if (inputOnBlurGetValue) inputOnBlurGetValue(e.target.value);
  };

  const getNumberInputValue = (e) => {
    let getInputValue = e.target.value;
    ///清除“数字”和“.”以外的字符
    getInputValue = getInputValue.replace(/[^\d.]/g, "");
    // 验证第一个字符是数字而不是.
    getInputValue = getInputValue.replace(/^\./g, "");
    // getInputValue = getInputValue.replace(/\.{2,}/g, "");
    const getArr = getInputValue.split(".");
    if (getArr.length > 2) {
      getInputValue = `${getArr[0]}.${getArr[1]}`;
    }
    const filterSpecialByte = ["-", "+"];
    const byteArr = filterSpecialByte.filter((item) => {
      return getInputValue.indexOf(item) !== -1;
    });
    if (byteArr.length > 0) {
      const getSplitArr = getInputValue.split(byteArr[0]);
      if (getSplitArr.length > 1) {
        getInputValue = `${getSplitArr[0]}${getSplitArr[1]}`;
      }
    }
    // 精度
    if (decimal || decimal === 0) {
      if (getInputValue.includes('.')) {
        const valArr = getInputValue.split('.');
        if (valArr[1].length > decimal) {
          getInputValue = `${valArr[0]}.${valArr[1].slice(0, decimal)}`;
        }
        if (decimal === 0) {
          getInputValue = getInputValue.split('.')[0];
        }
      }
    }
    numberInputOnchange(getInputValue);
  };

  const onchangeSelectedInputLeftValue = (value) => {
    getSelectedInputLeftValue(value);
  };

  // 粘贴
  const miliPasteFunc = () => {
    try {
      navigator.clipboard.readText().then((res) => {
        if (textInputOnchange) textInputOnchange(res);
      });
    } catch (e) {
      if (textInputOnchange) textInputOnchange("");
    }
  };

  const clickAll = () => {
    fillInAll();
  };

  const descDom = (title, content, tipFlag = false) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        fontSize="12px"
        fontWeight={400}
      >
        <Flex alignItems={"center"} gap={"5px"}>
          <Text color="#7B7C8F">{title}</Text>
          {tipFlag && (
              <Image
                  src={`../wwwsite/images/kyc/kyc_tip.svg`}
                  width={"14px"}
                  height={"14px"}
                  cursor={"pointer"}
                  onClick={(e) => {
                    e.stopPropagation();
                    numberFeeEvent();
                  }}
              />
          )}
        </Flex>
        <Text color="#010313">{content}</Text>
      </Box>
    );
  };
  const selectedInputMenuDom = () => {
    return (
      <Box w="100px" h="100%">
        <DropDownBox
          list={leftSelectedList}
          initValue={leftSelectedValue}
          iconSize={3}
          filterKey="selected_key"
          noShowBorder={true}
          onselectedInfo={onchangeSelectedInputLeftValue}
        />
      </Box>
    );
  };
  return (
    <>
      <Flex justifyContent={"flex-start"}>
        <Flex flex={1} alignItems="flex-start">
          {leftPointVisible && <Box mt={"7px"} w="8px" h="8px" bg="blue.100" borderRadius={"8px"} />}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
          >
            <Text ml="8px" lineHeight={"20px"} fontSize="16px" fontWeight={500} color="#010313" style={titleStyle}>
              {title}
            </Text>
            {showTitleRightBtn && (
              <Text
                color="#333BF5"
                fontSize="14px"
                fontWeight="400"
                cursor={"pointer"}
                whiteSpace={"nowrap"}
                onClick={titleRightClickEvent}
              >
                {TitleRightBtnText || t("LbGVMqLVHqyddGmXzULS")}
              </Text>
            )}
          </Box>
        </Flex>
      </Flex>
      <Flex justifyContent={"flex-start"}>
        {leftLineVisible && <Box w="2px" h={leftLineHeight} bg={leftLineBg} ml="3px"/>}
        <Box flex={1} ml="8px">
          {showText && (
            <Box
              w="100%"
              h="44px"
              bgColor="rgba(32, 26, 85, 0.05)"
              borderRadius="12px"
              px="20px"
              py="11px"
              mt="5px"
            >
              <Text color="#7B7C8F" fontSize="16px" fontWeight="500">
                {showText}
              </Text>
            </Box>
          )}
          {selectContent && (
            <Select
              w="100%"
              mt="5px"
              placeholder={selectPlaceholder}
              onChange={getFilterValue}
              value={selectedValue}
            >
              {selectContent.map((item, index) => {
                return (
                  <option key={item.name} value={item.selected_key}>
                    {formatSelect ? formatSelect(item) : item.name}
                  </option>
                );
              })}
            </Select>
          )}
          {canCopyText && (
            <Box
              w="100%"
              bgColor="rgba(32, 26, 85, 0.05)"
              borderRadius="12px"
              px="10px"
              py="10px"
              mt="5px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                color="#010313"
                fontSize="12px"
                fontWeight="400"
                wordBreak="break-word"
              >
                {canCopyText}
              </Text>
              <Image
                src="../wwwsite/images/copy.svg"
                alt="Dan Abramov"
                p={"3px"}
                onClick={clickCopy}
                _hover={{ background: "rgba(32, 26, 85, 0.05)" }}
              />
            </Box>
          )}
          {showInput && (
            <Box mt="5px">
              <Input
                placeholder={textInputPlaceholder}
                size="md"
                value={inputValue}
                onChange={getInputValue}
              />
            </Box>
          )}
          {renderTipBox && renderTipBox()}
          {showNumberInput && (
            <>
              <NumberInput
                mt="5px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                border="1px"
                borderColor={numberErrortext ? "#D04457" : "#E3E4F1"}
                borderRadius="12px"
                backgroundColor={numberErrortext ? "#FCEDEF" : ""}
                px="20px"
                py="12px"
                h="44px"
                focusBorderColor={numberErrortext ? "#FCEDEF" : "transparent"}
                value={inputValue}
              >
                <NumberInputField
                  placeholder={numberInputPlaceholder}
                  border="0px"
                  p="0px"
                  h="100%"
                  flex={1}
                  onChange={getNumberInputValue}
                  color={numberErrortext ? "#D04457" : "#010313"}
                />
                <Box>
                  <Center
                    height="22px"
                    fontSize="14px"
                    fontWeight={400}
                    color="#010313"
                    display="flex"
                    alignItems="center"
                  >
                    <Text>{inputContentUnit}</Text>
                    <Divider
                      orientation="vertical"
                      borderColor="#E3E4F1"
                      mx="10px"
                    />
                    <Text
                      color="blue.100"
                      cursor={"pointer"}
                      onClick={clickAll}
                    >
                      {t("SywvArMjCuuGzTEtlJ")}
                    </Text>
                  </Center>
                </Box>
              </NumberInput>
              {numberErrortext && (
                <Box color="#D04457" fontSize="12px" fontWeight={400} mt="10px">
                  {numberErrortext}
                </Box>
              )}
              {/* {t("剩余提现额度")} */}
              {Number(withdrawLimit) > -1 && (
                <Box mt="10px">
                  {descDom(
                    t("BgXkLkPusAcJBiUmSw"),
                    `${primary_currency} ${withdrawLimit}/${t(
                      "YEPMktdEzwhrMMYKefX"
                    )}`
                  )}
                </Box>
              )}
              <Box mt="10px">
                {descDom(t("HYTGqldMNoESWJswz"), numberTopDesc)}
                {numberBottomDesc && (
                  <>
                    <Divider borderColor="#E3E4F1" my="10px" />
                    {descDom(t("dkdmEAWTXMORUhlQaX"), numberBottomDesc)}
                  </>
                )}
              </Box>
              {numberMinWithdrawDesc && (
                  <Box mt="10px">
                    <Divider borderColor="#E3E4F1" my="10px"/>
                    {descDom(t("QIzonbZxYqJjQrPHfJM"), numberMinWithdrawDesc)}
                  </Box>
              )}
              {numberFeeDesc && (
                  <Box mt="10px">
                    <Divider borderColor="#E3E4F1" my="10px"/>
                    {descDom(t("iHptEcwFnEbHVLaQkXyb"), numberFeeDesc, true)}
                  </Box>
              )}
            </>
          )}
          {showSelectedInput && (
            <>
              <Center
                display="flex"
                alignItems="center"
                h="44px"
                border="1px solid #E3E4F1"
                borderRadius="12px"
              >
                {selectedInputMenuDom()}
                <Divider
                  orientation="vertical"
                  borderColor="#E3E4F1"
                  mx="5px"
                  height="12px"
                />
                <Input
                  placeholder={textInputPlaceholder}
                  defaultValue={inputValue}
                  border="0px"
                  // focusBorderColor="#FFFFFF"
                  p="0px"
                  flex={1}
                  onChange={getInputValue}
                  onBlur={inputOnBlurEvent}
                />
              </Center>
              {inputBottomIconDesc && (
                <Box display="flex" alignItems="center">
                  <Image
                    src={inputBottomIcon}
                    alt="header icon"
                    borderRadius="full"
                    boxSize="20px"
                    mr="10px"
                    fallbackSrc="../wwwsite/images/transfer/header_icon.svg"
                  />
                  <Text>{inputBottomIconDesc}</Text>
                </Box>
              )}
            </>
          )}
          {showPasteInput && (
            <Center
              display="flex"
              alignItems="center"
              h="44px"
              px="10px"
              border="1px solid #E3E4F1"
              borderRadius="12px"
            >
              <Input
                placeholder={textInputPlaceholder}
                value={inputValue}
                border="0px"
                focusBorderColor="#FFFFFF"
                p="0px"
                flex={1}
                onChange={getInputValue}
              />
              <Divider
                orientation="vertical"
                borderColor="#E3E4F1"
                mx="5px"
                height="12px"
              />
              <Text
                cursor={"pointer"}
                color="blue.100"
                fontWeight={400}
                fontSize="14px"
                onClick={miliPasteFunc}
              >
                粘贴
              </Text>
            </Center>
          )}
          {showCenterAddBtn && (
              <Center
                  display="flex"
                  alignItems="center"
                  h="44px"
                  px="10px"
                  border="1px solid #E3E4F1"
                  borderRadius="12px"
                  mt="5px"
                  onClick={centerAddBtnEvent}
                  cursor={"pointer"}
              >
                <Image
                    src={`../../wwwsite/images/kyc/add.png`}
                    width={"24px"}
                    height={"24px"}
                />
                <Text ml="10px" color={"#333BF5"} fontSize={14} fontWeight={400}>{centerAddBtnText}</Text>
              </Center>
          )}
        </Box>
      </Flex>
    </>
  );
};

CommonUnitStyle.propTypes = {
  title: PropTypes.string,
  leftLineBg: PropTypes.string,
  leftLineHeight: PropTypes.string,
  selectPlaceholder: PropTypes.string,
  selectedValue: PropTypes.string,
  selectContent: PropTypes.array,
  selectedEvent: PropTypes.func,
  showText: PropTypes.string,
  showTitleRightBtn: PropTypes.bool,
  TitleRightBtnText: PropTypes.string,
  rightBtnEvent: PropTypes.func,
  canCopyText: PropTypes.string,
  showInput: PropTypes.bool,
  textInputPlaceholder: PropTypes.string,
  textInputOnchange: PropTypes.func,
  showNumberInput: PropTypes.bool,
  numberTopDesc: PropTypes.string,
  numberBottomDesc: PropTypes.string,
  numberInputOnchange: PropTypes.func,
  numberErrortext: PropTypes.string,
  numberInputPlaceholder: PropTypes.string,
  showSelectedInput: PropTypes.bool,
  leftSelectedList: PropTypes.array,
  leftSelectedValue: PropTypes.string,
  getSelectedInputLeftValue: PropTypes.func,
  inputOnBlurGetValue: PropTypes.func,
  inputBottomIcon: PropTypes.string,
  inputBottomIconDesc: PropTypes.string,
  // 输入框输入的内容的单位
  inputContentUnit: PropTypes.string,
  // 输入框输入的值
  inputValue: PropTypes.string,
  // 展示粘贴输入框
  showPasteInput: PropTypes.bool,
  fillInAll: PropTypes.func,
  showCenterAddBtn: PropTypes.bool,
  centerAddBtnText: PropTypes.string,
  centerAddBtnEvent: PropTypes.func,
  numberMinWithdrawDesc: PropTypes.string,
  numberFeeDesc: PropTypes.string,
  numberFeeEvent: PropTypes.func,
  leftPointVisible: PropTypes.bool,
  leftLineVisible: PropTypes.bool,
  titleStyle: PropTypes.object,
  decimal: PropTypes.number,
};
CommonUnitStyle.defaultProps = {
  leftLineBg: "#333BF5",
  leftLineHeight: "104px",
  leftPointVisible: true,
  leftLineVisible: true,
  titleStyle: {},
};

export default CommonUnitStyle;
