import { useState, useEffect, useRef } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import "./index.scss";
import { Text } from "@chakra-ui/react";

function BXNSelect({
  options, // [{label: , value, }] 必须要有label、id
  defaultOpt, // 默认的选项
  handleSelect, // select方法
  renderPlaceholder,
  renderItem, // 自定义菜单内部渲染
  renderLabel, // 自定义select label渲染
  style,
  disabled,
}) {
  const modalRef = useRef();
  const [isIn, setIsIn] = useState(false);
  const [activeItem, setActiveItem] = useState();

  useEffect(() => {
    function handler(event) {
      if (!modalRef.current?.contains(event.target)) {
        setIsIn(false);
      }
    }
    document.body.addEventListener("click", handler, true);
    return () => document.body.removeEventListener("click", handler, true);
  }, []);

  useEffect(() => {
    setActiveItem(defaultOpt);
  }, [defaultOpt]);

  return (
    <div
      ref={modalRef}
      className={`bxn-select ${disabled ? "bxn-select-disabled" : ""}`}
      style={style}
    >
      <div
        tr={isIn ? 1 : 2}
        onClick={() => {
          if (!disabled) {
            setIsIn(true);
          }
        }}
        className="select-wrap"
      >
        {!activeItem && renderPlaceholder && renderPlaceholder()}
        {renderLabel && activeItem ? (
          renderLabel(activeItem)
        ) : (
          <Text fontSize={"14px"}>{activeItem?.label}</Text>
        )}
        {!isIn ? <ChevronDownIcon /> : <ChevronUpIcon />}
      </div>

      {isIn && options && options.length > 0 && (
        <div className="select-menu">
          {options.map((d, i) => (
            <div
              className="select-menu-item"
              key={d?.key || i}
              onClick={() => {
                setActiveItem(d);
                handleSelect && handleSelect(d);
                setIsIn(false);
              }}
            >
              {renderItem ? (
                renderItem(d)
              ) : (
                <Text fontSize={"14px"}>{d?.label}</Text>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default BXNSelect;
