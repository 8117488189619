import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useI18n } from "@/hooks/useI18n";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CommonUnitStyle from "@/pages/deposit/component/commonUnitStyle";
import { updateBankInfo } from "@/state/user/reducer";
import CommonBtnDom from "@/pages/deposit/component/commonBtn";
import ModalComponent from "@/components/ui/modal";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Dec } from "@/helpers/func/decimalCal";
import { jsUuid } from "@/helpers/func/stringPro";
import { requestBankTransferCreate } from "@/pages/withdraw/api";

const BankWithdrawPage = (props) => {
  const {
    currency,
    netAboutInfo: { min_amount, fee, receive_accounts_by_bank },
    amount,
    balance,
    accountNo,
    updateMountEvent,
    updateAccountNoEvent,
    showLoadingEvent,
    getPayId,
    decimal,
  } = props;
  const { t } = useI18n();
  const navigator = useNavigate();
  const dispatch = useDispatch();

  const [showTipModal, setShowTipModal] = useState(false);
  const [uuid, setUuid] = useState("");

  const bankList = useMemo(() => {
    return receive_accounts_by_bank || [];
  }, [receive_accounts_by_bank]);

  const bankSelectFlag = bankList.length;

  useEffect(() => {
    setUuid(jsUuid());
  }, []);

  useEffect(() => {
    if (bankList.length > 0) {
      updateAccountNoEvent(bankList[0].uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankList]);

  const addRightBtn = (type) => {
    dispatch(updateBankInfo({ bankInfo: {} }));
    const url =
      type === "add" ? "/trade/legal/tender/add" : "/trade/legal/tender";
    navigator(url);
  };

  const getInputAmount = (getValue) => {
    updateMountEvent(getValue);
  };

  const clickAllBtn = () => {
    let allAmount = Dec(balance, fee || "0", "-");
    updateMountEvent(allAmount);
  };

  const amountInputError = () => {
    let errorText = "";
    if (amount) {
      if (min_amount && Number(amount) < Number(min_amount)) {
        errorText = `${t("iRiLJudagJVXZozmnz")} ${min_amount}`;
      }
      if (balance) {
        if (fee) {
          if (Number(fee) > Number(Dec(balance, amount, "-"))) {
            errorText = t("OKOogywrWeAjtQWF");
          }
        }
        if (Number(amount) > Number(balance)) {
          errorText = t("MOtoFwFvpTtTEDVUD");
        }
      }
    }
    return errorText;
  };

  const clickNext = async () => {
    if (accountNo && amount && !amountInputError()) {
      showLoadingEvent(true);
      const body = {
        request_id: uuid,
        currency: currency,
        amount: amount,
        fee_amount: fee,
        bank_uuid: accountNo,
      };
      const { code, data, msg } = await requestBankTransferCreate(body);
      showLoadingEvent(false);
      if (code === 0 && msg === "OK") {
        const { pay_id } = data;
        getPayId(pay_id);
      } else {
        setUuid(jsUuid());
      }
    }
  };

  const disabledNext = Boolean(!accountNo || !amount || amountInputError());

  const Modal = () => {
    return (
      <ModalComponent isShow={showTipModal} style={{ zIndx: 99 }}>
        <Box px={"33px"} py={"50px"}>
          <Box>
            <Flex justifyContent={"space-between"}>
              <Text color="#010313" fontSize={16} fontWeight={600}>
                {t("iHptEcwFnEbHVLaQkXyb")}
              </Text>
              <Image
                boxSize="24px"
                src="../../wwwsite/images/bill/close.svg"
                cursor={"pointer"}
                onClick={() => setShowTipModal(false)}
              />
            </Flex>
            <Text fontSize={14} color={"#010313"} mt={"20px"}>
              {t("HzbSujYkGuYtObRcrdbh")}
            </Text>
          </Box>
        </Box>
      </ModalComponent>
    );
  };

  return (
    <>
      <CommonUnitStyle
        title={t("ZdMOAZGAMXotVGVlKJ")}
        showText={`${currency}-${t("DZjgiswpELVABBqUWCSa")}`}
      />
      {bankSelectFlag && (
        <CommonUnitStyle
          title={t("XrngZedCHgJUKulaDcT")}
          showTitleRightBtn={true}
          TitleRightBtnText={t("wLQWZKMRgmTJTSudW")}
          rightBtnEvent={() => addRightBtn("list")}
          selectContent={bankList.map((cur) => ({
            ...cur,
            selected_key: cur.uuid,
          }))}
          selectedValue={accountNo}
          selectedEvent={(value) => {
            updateAccountNoEvent(value);
          }}
          formatSelect={(item) => {
            return (
              <>
                <span>{item.name}</span>
                <span>（{item.account_no}）</span>
              </>
            );
          }}
        />
      )}
      {!bankSelectFlag && (
        <CommonUnitStyle
          title={t("XrngZedCHgJUKulaDcT")}
          showCenterAddBtn={true}
          centerAddBtnText={t("SVpHROUKzBJsjSVHMK")}
          centerAddBtnEvent={() => addRightBtn("add")}
        />
      )}
      <CommonUnitStyle
        title={t("NOYdleknddFOfQxlNOI")}
        leftLineBg="transparent"
        showNumberInput={true}
        numberTopDesc={`${balance} ${currency}`}
        numberMinWithdrawDesc={`${min_amount} ${currency}`}
        numberFeeDesc={`${fee} ${currency}`}
        numberFeeEvent={() => setShowTipModal(true)}
        numberInputPlaceholder={t("fWgPNysKDTLrzjgrexk")}
        inputValue={amount}
        inputContentUnit={currency}
        numberInputOnchange={getInputAmount}
        numberErrortext={amountInputError()}
        fillInAll={clickAllBtn}
        decimal={decimal}
      />
      <Text
        pl={"13px"}
        mt={"20px"}
        color={"#000"}
        fontSize={12}
        fontWeight={400}
      >
        {t("uUpDUGTUrKdXVQXkgSP")}
      </Text>
      <CommonBtnDom
        btnText={t("tOaRBVUJHpnSgDBrGON")}
        noCanClick={disabledNext}
        marginTop="40px"
        clickEvent={clickNext}
      />
      <Modal />
    </>
  );
};

BankWithdrawPage.propTypes = {
  currency: PropTypes.string,
  netAboutInfo: PropTypes.object,
  amount: PropTypes.string,
  balance: PropTypes.string,
  accountNo: PropTypes.string,
  updateMountEvent: PropTypes.func,
  updateAccountNoEvent: PropTypes.func,
  showLoadingEvent: PropTypes.func,
  getPayId: PropTypes.func,
};

export default BankWithdrawPage;
