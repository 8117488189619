import PropTypes from "prop-types";

import { Image, Text, useMediaQuery, Box, Flex } from "@chakra-ui/react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import TableUnit from "@/pages/historyBill/component/ui/tableUnit";
import { useState } from "react";
import { useI18n } from "@/hooks/useI18n";
import { toFixed, plus } from "@/utils/bigNumber";
import { useMedia } from "@/hooks/useMedia";
import CurrencyDetail from "../currencyDetail/index";
import { getCurrencyDetail } from "./api";
import { debounce } from "lodash";

const AssetsTable = (props) => {
  const { t } = useI18n();

  const { assetList, transList } = props;
  // console.log("assetList====",assetList)

  // 小于739
  const [isSmaller] = useMediaQuery("(max-width: 739px)");
  const [isActive, setIsActive] = useState(true);
  const { isMobile } = useMedia();
  const [currencyDetail, setCurrencyDetail] = useState();
  const [showDetail, setShowDetail] = useState(false);

  const leftContentDom = (item) => {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Image
          borderRadius="full"
          boxSize="30px"
          src={item.icon}
          fallbackSrc="../wwwsite/images/transfer/header_icon.svg"
          alt="currencyIcon"
          mr="10px"
        />
        <Text>{item.name}</Text>
      </Box>
    );
  };
  const getDecimalPlaces = (num) => {
    const match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
      return 0; // 如果不是有效的数字，小数点位数为0
    }
    return Math.max(
      0,
      (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
    );
  };

  const AmountDom = (item) => {
    const decimal = getDecimalPlaces(item.amount);
    let itemAmount;
    if (decimal > 8) {
      itemAmount = toFixed(item.amount, 8);
    } else {
      itemAmount = item.amount;
    }
    return isSmaller ? (
      <Box>
        <Text>{itemAmount}</Text>
        <Text color={"#7B7C8F"} fontSize="12px" fontWeight={400}>
          ≈ {toFixed(item.fiat_balance, 2)} {item.fiat_currency}
        </Text>
      </Box>
    ) : (
      <Text>{itemAmount}</Text>
    );
  };
  const changeTabs = () => {
    setIsActive(!isActive);
  };

  const getDetail = debounce(async (currency) => {
    const whiteCurrency = ["BTC", "ETH", "USDC"];
    if (whiteCurrency.indexOf(currency) > -1) {
      const res = await getCurrencyDetail({ currency });
      if (res.code === 0) {
        setCurrencyDetail(res.data);
        setShowDetail(true);
      }
    }
  }, 500);

  return (
    <Box w={["100%", "70%"]} mx={"auto"} pb={"74px"} mt={["20x", "50px"]}>
      <Box
        borderWidth={["0px", "1px"]}
        borderColor="#E3E4F1"
        borderRadius="30px"
        overflow={"hidden"}
      >
        <Flex
          h="84px"
          lineHeight="84px"
          cursor={"pointer"}
          borderBottom={"1px solid #E3E4F1"}
        >
          <Box
            fontSize={isMobile ? "16px" : "24px"}
            fontWeight={isActive && "bold"}
            ml="30px"
            color={isActive ? "#3E3D4D" : "#8B8A99"}
            borderBottom={isActive && "3px solid #010313"}
            onClick={changeTabs}
          >
            {t("udmfKDblDABqFbQPK")}
          </Box>
          <Box
            fontSize={isMobile ? "16px" : "24px"}
            fontWeight={!isActive && "bold"}
            ml="30px"
            color={!isActive ? "#3E3D4D" : "#8B8A99"}
            borderBottom={!isActive && "3px solid #010313"}
            onClick={changeTabs}
          >
            {t("AysRHzzIQTbjLUdgdaq")}
          </Box>
        </Flex>
        {isActive ? (
          <Box>
            {!isSmaller && (
              <TableUnit
                py="30px"
                TableStyle={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#7B7C8F",
                }}
                noHover={true}
                tableContentArr={[
                  {
                    name: t("pQEEhEzMgmLpEznsEH"),
                    style: {
                      flex: "15%",
                    },
                  },
                  {
                    name: t("YixzEmijtHztNgwIE"),
                    style: {
                      w: "30%",
                      textAlign: "right",
                    },
                  },
                  {
                    name: t("ARCAktOTCiLqQqaPTv"),
                    style: {
                      w: "25%",
                      textAlign: "right",
                    },
                  },
                  {
                    name: t("HYTGqldMNoESWJswz"),
                    style: {
                      w: "25%",
                      textAlign: "right",
                    },
                  },
                  {
                    name: t("ApeuvISiLLWNGQPrFrP"),
                    icon: <QuestionOutlineIcon />,
                    desc: t("VZMtxSlhbTKcmTYeEsW"),
                    style: {
                      w: "25%",
                      textAlign: "right",
                    },
                  },
                  {
                    name: t("WauyOiSpVDMamrXbLP"),
                    style: {
                      w: "30%",
                      textAlign: "right",
                    },
                  },
                ]}
              />
            )}
            <Box>
              {assetList.length > 0 &&
                assetList.map((item, index) => {
                  const total = plus(item.locked_amount, item.amount);
                  return (
                    <Box key={item.name}>
                      <TableUnit
                        clickEvent={() => getDetail(item.name)}
                        keyId={item.name}
                        TableStyle={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#010313",
                        }}
                        tableContentArr={[
                          {
                            name: leftContentDom(item),
                            style: {
                              flex: "15%",
                            },
                          },
                          !isSmaller && {
                            name: `${item.fiat_price} ${item.fiat_currency}`,
                            style: {
                              w: "30%",
                              textAlign: "right",
                            },
                          },
                          {
                            name: `${
                              getDecimalPlaces(total) > 8
                                ? toFixed(total, 8)
                                : total
                            }`,
                            style: {
                              w: ["60%", "25%"],
                              textAlign: "right",
                            },
                          },
                          {
                            name: AmountDom(item),
                            style: {
                              w: ["60%", "25%"],
                              textAlign: "right",
                            },
                          },
                          !isSmaller && {
                            name: `${
                              getDecimalPlaces(item.locked_amount) > 8
                                ? toFixed(item.locked_amount, 8)
                                : item.locked_amount
                            }`,
                            style: {
                              w: "20%",
                              textAlign: "right",
                            },
                          },
                          !isSmaller && {
                            name: `${item.fiat_balance} ${item.fiat_currency}`,
                            style: {
                              w: "30%",
                              textAlign: "right",
                            },
                          },
                        ]}
                        noShowborder={index === assetList.length - 1 && true}
                      />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        ) : (
          <Box>
            {!isSmaller && (
              <TableUnit
                py="30px"
                TableStyle={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#7B7C8F",
                }}
                noHover={true}
                tableContentArr={[
                  {
                    name: t("pQEEhEzMgmLpEznsEH"),
                    style: {
                      flex: 1,
                    },
                  },
                  {
                    name: t("YixzEmijtHztNgwIE"),
                    style: {
                      w: "20%",
                      textAlign: "right",
                    },
                  },
                  {
                    name: t("ARCAktOTCiLqQqaPTv"),
                    style: {
                      w: "20%",
                      textAlign: "right",
                    },
                  },
                  {
                    name: t("KZPDAndezwXohlXxO"),
                    style: {
                      w: "15%",
                      textAlign: "right",
                    },
                  },
                  {
                    name: t("ApeuvISiLLWNGQPrFrP"),
                    icon: <QuestionOutlineIcon />,
                    desc: t("VpbDUCnJRDAsPzfIkn"),
                    style: {
                      w: "15%",
                      textAlign: "right",
                    },
                  },
                  {
                    name: t("WauyOiSpVDMamrXbLP"),
                    style: {
                      w: "20%",
                      textAlign: "right",
                    },
                  },
                ]}
              />
            )}
            <Box>
              {transList.length > 0 &&
                transList.map((item, index) => {
                  return (
                    <Box key={item.name}>
                      <TableUnit
                        clickEvent={() => getDetail(item.name)}
                        keyId={item.name}
                        TableStyle={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#010313",
                        }}
                        tableContentArr={[
                          {
                            name: leftContentDom(item),
                            style: {
                              flex: 1,
                            },
                          },
                          !isSmaller && {
                            name: `${toFixed(item.fiat_price, 2)} ${
                              item.fiat_currency
                            }`,
                            style: {
                              w: "20%",
                              textAlign: "right",
                            },
                          },
                          {
                            name: AmountDom(item),
                            style: {
                              w: ["60%", "20%"],
                              textAlign: "right",
                            },
                          },
                          !isSmaller && {
                            name: `${
                              getDecimalPlaces(item.availableAmount) > 8
                                ? toFixed(item.availableAmount, 8)
                                : item.availableAmount
                            }`,
                            style: {
                              w: "15%",
                              textAlign: "right",
                            },
                          },
                          !isSmaller && {
                            name: `${
                              getDecimalPlaces(item.frozenAmount) > 8
                                ? toFixed(item.frozenAmount, 8)
                                : item.frozenAmount
                            }`,
                            style: {
                              w: "15%",
                              textAlign: "right",
                            },
                          },
                          !isSmaller && {
                            name: `${toFixed(item.fiat_balance, 2)} ${
                              item.fiat_currency
                            }`,
                            style: {
                              w: "20%",
                              textAlign: "right",
                            },
                          },
                        ]}
                        noShowborder={index === assetList.length - 1 && true}
                      />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        )}
      </Box>
      {showDetail && currencyDetail && (
        <CurrencyDetail
          data={currencyDetail}
          onClose={() => {
            setShowDetail(false);
            setCurrencyDetail("");
          }}
        />
      )}
    </Box>
  );
};

AssetsTable.propTypes = {
  assetList: PropTypes.array,
};

export default AssetsTable;
