/* eslint-disable default-case */
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import {
  BIXIN_RESETACCOUNTINFO,
  BIXIN_GA,
  BIXIN_KYC,
  BIXIN_ACCOUNTMANAGER,
} from "@/helpers/constants/routeName";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/state/hooks";
import { useMedia } from "@/hooks/useMedia";
import { useI18n } from "@/hooks/useI18n";
import { useDispatch } from "react-redux";
import { updateKyc, updateUserInfo } from "@/state/user/reducer";
import { getUserInfo, getProcessInfo } from "./api";
const AccountAndSafe = () => {
  const { t } = useI18n();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const userInfo = useAppSelector((state) => state.user.userInfo);
  const process = useAppSelector((state) => state.user?.kyc?.process);
  const { isMobile } = useMedia();
  const _lang = useAppSelector((state) => state.user.language);
  const _phone = useAppSelector((state) => state.user?.userInfo?.phone);
  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };
  const c3Btn = () => {
    if (userInfo.kyc_type !== "UNAUTHORIZED") {
      return t("ysjFKMhSQlIqXgPlz");
    } else {
      if (process?.length > 0) {
        return t("NrifpOBOTpbiyPCqT");
      }
    }
  };

  const getProcessInfoFn = async () => {
    const res = await getProcessInfo({});
    if (res.code === 0) {
      dispatch(updateKyc({ kyc: res.data }));
    }
  };

  const asData = useMemo(() => {
    return [
      {
        name: t("POqloYgxpcTCqdnchGE"),
        icon: "../wwwsite/images/account-sms.svg",
        desc: userInfo?.email,
        id: Math.random(),
      },
      {
        name: t("txfROteTwdaWdTnlkif"),
        icon: "../wwwsite/images/lock.svg",
        desc: t("qEmYfNcGOwxFPeXxWz"),
        btnName: t("MZjVFSmKoCrehUMcH"),
        id: Math.random(),
      },
      {
        name: t("sjqwCBQBKOIAKZymEnSd"),
        icon: "../wwwsite/images/card.svg",
        desc: t("wAFriagWUVTteXOh"),
        btnName: t("MZjVFSmKoCrehUMcH"),
        id: Math.random(),
      },
      {
        name: t("ffqVyVPsxEFhGDWwjo"),
        icon: "../wwwsite/images/mobile.svg",
        desc: t("ZFmapCdeoYAGjnNG"),
        btnName: userInfo.phone
          ? t("MZjVFSmKoCrehUMcH")
          : t("czWbrtWmnCEbUCzVWzQQ"),
        id: Math.random(),
      },
      {
        name: t("uetkXvxfFuKnhpBFrA"),
        icon: "../wwwsite/images/google.svg",
        desc: t("tLufHBCFhvZchanHd"),
        btnName: userInfo.has_google_auth
          ? t("YATiezBAjBifZRNRVk")
          : t("czWbrtWmnCEbUCzVWzQQ"),
        id: Math.random(),
      },
      {
        name: t("VUMActZjvXkEaSCs"),
        icon: "../wwwsite/images/user2.svg",
        desc: t("wEEzjRnoOftKrGIPbix"),
        btnName: c3Btn(),
        id: Math.random(),
      },
      {
        name: t("wEarLFdfZMShsSfacNU"),
        icon: "../wwwsite/images/whitelist.svg",
        desc: t("eEcajuQQvABwGKndqss"),
        btnName: t("GkvuBozDTWJAXbpwyP"),
        secondBtnName: t("IMxXGyWWPXtfrrwxV"),
        id: Math.random(),
      },
    ];
  }, [t, userInfo]);

  const toDetail = (idx) => {
    switch (idx) {
      case 1:
        navigator(`${BIXIN_RESETACCOUNTINFO}?type=pwd`);
        break;
      case 2:
        navigator(`${BIXIN_RESETACCOUNTINFO}?type=paypwd`);
        break;
      case 3:
        _phone
          ? navigator(`${BIXIN_RESETACCOUNTINFO}?type=phone`)
          : navigator(`${BIXIN_KYC}?currentStep=1`);
        break;
      case 4:
        userInfo.has_google_auth
          ? navigator(`${BIXIN_ACCOUNTMANAGER}?current=1`)
          : navigator(BIXIN_GA);
        break;
      case 5:
        userInfo.kyc_type !== "UNAUTHORIZED"
          ? navigator(`${BIXIN_ACCOUNTMANAGER}?current=1`)
          : navigator(BIXIN_KYC);
        break;
      case 6:
        navigator(`/trade/whiteAddress`);
        break;
      case 7:
        navigator(`/trade/legal/tender`);
        break;
    }
  };
  useEffect(() => {
    getProcessInfoFn();
    getUserInfoFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Box w={["90%", "600px"]} margin={"40px auto 0"} padding={"0 20px 20px"}>
        <Text
          fontWeight="600"
          color="t.100"
          fontSize="24px"
          mb={["20px", "53px"]}
        >
          {t("POdaGgvMxQRsisLbVcll")}
        </Text>
        {asData.map((item, idx) => (
          <Box
            minH={["100px", "117px"]}
            borderBottom="1px solid #E3E4F1"
            key={item.id}
            onClick={() => {
              if (isMobile) {
                if (idx === 4) {
                  if (!userInfo.has_google_auth) {
                    toDetail(idx);
                  }
                } else if (idx === 5) {
                  if (userInfo.kyc_type === "UNAUTHORIZED") {
                    navigator("/trade/kyc");
                  } else {
                    toDetail(idx);
                  }
                } else if (idx === 6) {
                  return false;
                } else {
                  toDetail(idx);
                }
              }
            }}
          >
            <Box pt={["20px", "40px"]}>
              <Flex justifyContent={"space-between"}>
                <Flex
                  justifyContent={"flex-start"}
                  w={["100%", "80%"]}
                  alignItems={"center"}
                >
                  <img
                    src={item.icon}
                    width="24"
                    alt=""
                    style={{ width: "24px", height: "24px" }}
                  />
                  <Box ml="10px">
                    <Text color="t.100" fontSize="14px">
                      {item.name}
                    </Text>
                    <Text color="t.50" fontSize="12px">
                      {item.desc}
                    </Text>
                  </Box>
                </Flex>
                {!isMobile && (
                  <Box>
                    {idx === 4 &&
                      (userInfo.has_google_auth ? (
                        <Button
                          w="60px"
                          h="30px"
                          bg="t.50"
                          color="#fff"
                          _hover={{ bg: "t.50" }}
                          fontSize="12px"
                          borderRadius={"30px"}
                        >
                          {item.btnName}
                        </Button>
                      ) : (
                        <Button
                          w={_lang === "en" ? "100px" : "60px"}
                          h="30px"
                          bg="blue.100"
                          color="#fff"
                          _hover={{ bg: "blue.80" }}
                          fontSize="12px"
                          borderRadius={"30px"}
                          onClick={() => {
                            toDetail(idx);
                          }}
                        >
                          {item.btnName}
                        </Button>
                      ))}
                    {idx === 5 &&
                      (userInfo.kyc_type === "UNAUTHORIZED" ? (
                        <Button
                          h="30px"
                          bg="t.50"
                          color="#fff"
                          _hover={{ bg: "t.50" }}
                          fontSize="12px"
                          borderRadius={"30px"}
                        >
                          {t("TNkXPjSKdzQcBLZPsWj")}
                        </Button>
                      ) : (
                        <>
                          {process?.length > 0 && (
                            <Button
                              w={_lang === "en" ? "100px" : "60px"}
                              h="30px"
                              bg="blue.100"
                              color="#fff"
                              _hover={{ bg: "blue.80" }}
                              fontSize="12px"
                              borderRadius={"30px"}
                              onClick={() => {
                                toDetail(idx);
                              }}
                            >
                              {item.btnName}
                            </Button>
                          )}
                        </>
                      ))}
                    {idx < 4 && idx !== 0 && (
                      <Button
                        w={_lang === "en" ? "100px" : "60px"}
                        h="30px"
                        bg="blue.100"
                        color="#fff"
                        _hover={{ bg: "blue.80" }}
                        fontSize="12px"
                        borderRadius={"30px"}
                        onClick={() => {
                          toDetail(idx);
                        }}
                      >
                        {item.btnName}
                      </Button>
                    )}
                    {idx === 6 && (
                      <Flex gap={"15px"}>
                        <Button
                          w={_lang === "en" ? "100px" : "60px"}
                          h="30px"
                          bg="blue.100"
                          color="#fff"
                          _hover={{ bg: "blue.80" }}
                          fontSize="12px"
                          borderRadius={"30px"}
                          onClick={() => {
                            toDetail(idx);
                          }}
                        >
                          {item.btnName}
                        </Button>
                        <Button
                          w={_lang === "en" ? "100px" : "60px"}
                          h="30px"
                          bg={
                            userInfo.kyc_type === "UNAUTHORIZED"
                              ? "t.50"
                              : "blue.100"
                          }
                          color="#fff"
                          _hover={{
                            bg:
                              userInfo.kyc_type === "UNAUTHORIZED"
                                ? "t.50"
                                : "blue.80",
                          }}
                          fontSize="12px"
                          borderRadius={"30px"}
                          onClick={() => {
                            if (userInfo.kyc_type !== "UNAUTHORIZED") {
                              toDetail(7);
                            }
                          }}
                        >
                          {item.secondBtnName}
                        </Button>
                      </Flex>
                    )}
                  </Box>
                )}
              </Flex>
              {idx === 6 && isMobile && (
                <Flex
                  w={"100%"}
                  mt={"15px"}
                  gap={"15px"}
                  pb={"20px"}
                  justifyContent={"flex-end"}
                >
                  <Button
                    w={_lang === "en" ? "100px" : "60px"}
                    h="30px"
                    bg="blue.100"
                    color="#fff"
                    _hover={{ bg: "blue.80" }}
                    fontSize="12px"
                    borderRadius={"30px"}
                    onClick={() => {
                      toDetail(idx);
                    }}
                  >
                    {item.btnName}
                  </Button>
                  <Button
                    w={_lang === "en" ? "100px" : "60px"}
                    h="30px"
                    bg={
                      userInfo.kyc_type === "UNAUTHORIZED" ? "t.50" : "blue.100"
                    }
                    color="#fff"
                    _hover={{
                      bg:
                        userInfo.kyc_type === "UNAUTHORIZED"
                          ? "t.50"
                          : "blue.80",
                    }}
                    fontSize="12px"
                    borderRadius={"30px"}
                    onClick={() => {
                      if (userInfo.kyc_type !== "UNAUTHORIZED") {
                        toDetail(7);
                      }
                    }}
                  >
                    {item.secondBtnName}
                  </Button>
                </Flex>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default AccountAndSafe;
