import request from "@/hooks/useRequest";

// 获取个人更新资料反显信息接口
export const getPersonalKycUpdateInfoApi = (params) => {
  return request("/web-wallet/api/v1/kyc/personal/updates/info", {
    params,
  });
};

// 提交个人更新接口
export const submitPersonalKycUpdateInfoApi = (params) => {
  return request("/web-wallet/api/v1/kyc/personal/updates/create", {
    body: params,
  });
};

// 是否能审阅
export const getPerodicStatusApi = (params) => {
  return request("/web-wallet/api/v1/kyc/review/info", { params });
};

// 提交公司更新
export const submitCompanyUpdateInfoApi = (params) => {
  return request("/web-wallet/api/v1/kyc/company/updates/create", {
    body: params,
  });
};
