import { Box, Flex, Text } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";

const Progress = (props) => {
  const { step } = props;
  const { t } = useI18n();
  return (
    <Box width={"100%"}>
      <Flex justifyContent={"center"}>
        <Box>
          <Flex justifyContent={"center"} w="80px">
            <Box bg="blue.100" w="8px" h="8px" borderRadius={"8px"}></Box>
          </Flex>
          <Flex justifyContent={"center"} w="80px">
            <Text fontSize="14px" color="t.100" mt="18px">
              {t("ZnbyJyzHTxzgLRDFBmEm")}
            </Text>
          </Flex>
        </Box>
        <Box
          w="60px"
          h="2px"
          bg={step > 1 ? "blue.100" : "#E3E4F1"}
          ml="-30px"
          mt="3px"
        ></Box>
        <Box ml="-30px">
          <Flex justifyContent={"center"} w="80px">
            <Box
              bg={step > 1 ? "blue.100" : "#E3E4F1"}
              w="8px"
              h="8px"
              borderRadius={"8px"}
            ></Box>
          </Flex>
          <Flex justifyContent={"center"} w="80px">
            <Text fontSize="14px" color="t.100" mt="18px">
              {t("RtWpXpLfhjeWPtKhcENN")}
            </Text>
          </Flex>
        </Box>
        <Box
          w="60px"
          h="2px"
          bg={step > 2 ? "blue.100" : "#E3E4F1"}
          ml="-30px"
          mt="3px"
        ></Box>
        <Box ml="-30px">
          <Flex justifyContent={"center"} w="80px">
            <Box
              bg={step > 2 ? "blue.100" : "#E3E4F1"}
              w="8px"
              h="8px"
              borderRadius={"8px"}
            ></Box>
          </Flex>
          <Flex justifyContent={"center"} w="80px">
            <Text fontSize="14px" color="t.100" mt="18px">
              {t("OQGpXlfSrBKFcwjAv")}
            </Text>
          </Flex>
        </Box>
        <Box
          w="60px"
          h="2px"
          bg={step > 3 ? "blue.100" : "#E3E4F1"}
          ml="-30px"
          mt="3px"
        ></Box>
        <Box ml="-30px">
          <Flex justifyContent={"center"} w="120px">
            <Box
              bg={step > 3 ? "blue.100" : "#E3E4F1"}
              w="8px"
              h="8px"
              borderRadius={"8px"}
            ></Box>
          </Flex>
          <Flex justifyContent={"center"} w="120px">
            <Text fontSize="14px" color="t.100" mt="18px">
              {t("pnGXcYRvktMyOUBwX")}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Progress;
