import { Box, Text } from "@chakra-ui/react";

const TWDoc = () => {
  return (
    <Box>
      <Text fontSize="28px" fontWeight={"bold"} mt="30px">
        用戶協議
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        最後更新時間：2024年1月5日
      </Text>
      <Text mt="30px">
        NewBX
        Limited（CE編號: BUY737）（一家在香港註冊成立的公司，註冊地址為香港上環禧利街27號富輝商業中心7樓702室，以下簡稱“公司”）開發和運營的幣信軟體（以下簡稱“本軟體」）及服務。請參閱公司網站bixin.com
        以了解我們的牌照狀態，如果我們的牌照狀態發生變化，我們將通知您。除非另有說明，本文提及的公司還包括NewBX
        Treasures Limited（一家在香港註冊成立的公司，註冊地址為香港上環禧利街27
        號富輝商業中心7 樓702
        室），其由香港公司註冊處核發的信託或公司服務提供者牌照編號為TC009090（「聯絡實體」）。
      </Text>
      <Text mt="30px">
        為了使用本軟體及服務，您（以下簡稱「您」或「使用者」）必須閱讀並同意遵守本公司網站的《使用者協議》（以下簡稱「本協議」）和《隱私條款》。本協議、《隱私條款》、《反洗錢及反代幣洗錢公告》、《風險披露聲明》、《託管安排相關資訊》（以不時修訂、補充或修改版本為準），應構成雙方之間關於使用本軟體和服務的完整協議（“協議”）。本協議與其他協議不一致的，以本協議為準。如果協議的英文版本和中文版本之間存在任何不一致，則以英文版本為準。請您仔細閱讀、充分理解本協議各條款的條款，特別是免除或限制責任的條款，並選擇接受或不接受。您的下載、安裝、使用、申請、取得帳號、登入等行為均視為您已閱讀並同意接受本協議的約束。本公司保留不時對協議條款進行任何修改和補充並在軟體和服務中公佈的權利。協議條款的修改和補充將在發布後立即生效。您繼續存取和使用軟體和服務將被視為受修訂後的協議的約束。否則，您有權透過停止登入、拒絕使用本軟體及服務的方式終止本協議。
      </Text>
      <Text mt="30px">
        您有義務遵守所有適用的法律、法規、規則、指引和其他政府規定，不擾亂數位資產（包括BTC、ETH等與本公司提供服務相關的所有數位資產）的正常交易秩序、交換和託管，且不利用該軟體及服務組織參與洗錢、賭博等犯罪活動。若因違規行為導致任何法律後果，您應自行承擔一切相應的法律責任。若用戶有違法犯罪行為，或本公司有相關證據合理懷疑該用戶可能有違法犯罪行為，或違反本協議約定的，本公司有權暫停或終止該用戶的服務或停止向用戶提供服務，在公司網站和互聯網（如適用）上公佈該用戶的違法行為或違約行為，並向有關當局報告並配合其調查活動，包括但不限於披露所有用戶資訊、交易記錄以及相關部門要求的其他資訊。本公司可實施上述任何及全部行為，由此產生的後果、風險和責任均由使用者承擔。使用者接受本協議即視為使用者瞭解並接受本條款並永久且不可撤銷地授權本公司揭露相關資訊並採取相應措施。
      </Text>
      <Text mt="30px">
        協議內容包括協議正文以及公司已發布或將來可能發布的各類規則、聲明和指示。所有規則、聲明和指示均為協議不可分割的部分，與協議文本具有同等法律效力。
      </Text>
      <Text mt="30px">
        如果您代表某個機構而非您註冊、註冊使用、使用本軟體及服務，您將被視為已獲得充分授權，代表相關機構同意本協議及本協議的條款協議不時修改和補充。
      </Text>
      <Text mt="30px">
        您承諾並同意，如果您的全名、地址以及為提供軟體和服務而向公司提供的任何其他重要資訊發生任何重大變更，請立即通知本公司。否則可能會導致服務終止或暫停。
      </Text>
      <Text mt="30px">
        本公司承諾並同意以書面或透過提請您注意公司網站上發布的更新的方式通知您以下方面的任何重大變更：
      </Text>
      <Text mt="10px">(i) 公司業務的全名及地址；</Text>
      <Text mt="10px">(ii) 公司在證監會的發牌狀況及其CE編號；</Text>
      <Text mt="10px">(iii) 提供給您的軟體和服務的性質；</Text>
      <Text mt="10px">(iv) 您應支付的報酬和任何費用的描述；和</Text>
      <Text mt="10px">(v) 與所提供的軟體和服務相關的任何其他重要資訊。</Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        一、軟體及服務
      </Text>

      <Text mt="10px">
        1.
        本軟體和服務使您能夠透過本公司所經營的平台取得或處置虛擬資產。該公司還可以提供平台外服務，包括場外交易（「OTC」）交易台和詢價（「RFQ」）服務。此外，該公司還提供託管服務，透過我們的關聯實體託管您的資產。
        （詳細服務範圍還 載於公司網站）。
      </Text>
      <Text mt="10px">
        2.為了使用本軟體及服務，您需要透過網站（{" "}
        <a href="https://bixin.com/" style={{ color: "#3370ff" }}>
          bixin.com
        </a>
        ）或公司認可的其他管道下載軟體用戶端。本公司授予您個人的、不可轉讓的、非獨佔的使用許可。您只能出於自己的目的存取和使用軟體和服務。
      </Text>
      <Text mt="10px">
        3.
        軟體和服務可能不時具有不同的功能，每個功能的使用及其限制將受該功能的特定業務規則的約束。本公司有權不時制定、修改這些具體業務規則並予以公佈。您使用或繼續使用此類功能即表示您同意並接受此類規則和修訂。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        二、註冊和帳戶獲取
      </Text>
      <Text mt="10px">
        1、您在使用本軟體及服務前需要註冊幣信帳號。幣信帳戶是用手機號碼和/或郵箱地址（視具體版本而定）註冊的。我們將根據您註冊時提供的手機號碼和/或電子郵件地址為您建立幣信帳戶。如果您之前已使用相同的手機號碼和/或電子郵件地址在公司網站上註冊為用戶，則
        使用手機號碼和/或電子郵件地址註冊的幣信帳戶將自動與您使用手機號碼和/或電子郵件地址在公司網站註冊的帳戶關聯。
      </Text>
      <Text mt="10px">
        2、幣信帳戶的所有權歸公司所有。用戶完成註冊程序後，僅獲得幣信帳戶的使用權，且該使用權僅屬於註冊人本人。幣信帳戶註冊人不得以任何方式向第三方有償或無償提供帳戶使用權，包括但不限於轉讓、贈與、租賃、出借、許可等。幣信帳戶註冊人違反上述約定的，其後果和責任由註冊人自行承擔。造成公司損失的，應向公司全額賠償。本公司有權收回該帳戶的使用權。
      </Text>
      <Text mt="10px">
        3、您應妥善保管您的幣信帳號及密碼。準確輸入這些資訊將是您登入並使用本軟體及服務的關鍵環節。您承認並同意，您帳戶下發生的任何行為將被視為或已獲得您的合法授權，並承擔一切法律後果。如果您發現有人未經授權使用您的帳戶，您應立即更改密碼並向我們回饋。儘管有前述規定，在任何情況下，本公司均不對因您的帳號、密碼、登入資訊錯誤、遺失或其他因您未能確保幣信帳戶資訊安全而造成的損失或損害承擔任何責任。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        三、用戶備註
      </Text>
      <Text mt="10px">
        1、您瞭解並同意，為了保障您的資料儲存安全、分散風險，您授權本公司依照一定的規則和比例（按本公司隱私條款的規定）將您的資料轉移給第三方，並授權本公司將您的資料轉移給第三方。本公司委託第三方辦理相關事宜，在本協議第一項約定的服務範圍內。
      </Text>
      <Text mt="10px">
        2、您瞭解並同意，本公司將採取合理的商業努力來保護您在軟體和服務中儲存的數據，但本公司不向您提供任何形式或性質的保證，包括但不限於以下內容：
      </Text>
      <Text mt="10px">
        (1) 本公司對軟體及服務中相關資料的刪除或儲存不負任何責任；
      </Text>
      <Text mt="10px">
        (2)
        本公司有權根據實際情況確定個人使用者資料在軟體和服務中最長的儲存期限，並為該資料在伺服器上分配最大儲存空間。您可以依照自己的需求備份本軟體及服務中的相關數據，並自行承擔相應的風險；
      </Text>
      <Text mt="10px">
        (3)
        受限於《個人資料（私隱）條例》（香港法例第486章）（「PDPO」）的規定，如果您停止使用本軟件且本服務或服務被終止或取消（包括賬戶永久關閉），本公司可能會從伺服器上永久刪除您的數據；服務停止、終止或取消後，本公司沒有義務向您返還任何資料。
      </Text>
      <Text mt="10px">
        3、基於網路的開放性，您知悉使用者上傳至網路的圖片及其他資料可能會被其他組織或個人複製、轉載、修改或以其他方式使用。您必須充分意識到此類風險存在。您明確同意自行承擔使用該軟體和服務的風險；您使用該軟體和服務所產生的所有後果均由您承擔。
      </Text>
      <Text mt="10px">
        4、當使用者使用本軟體及服務的特定功能時，本公司可能會呼叫第三方系統或透過第三方支援使用者的使用或存取。使用或存取的結果由第三方提供（包括但不限於幣信公眾號提供的服務，或透過開放平台存取的內容等），本公司不做任何承諾或承擔對於提供服務和內容的第三方的安全性、準確性、有效性和其他事項的任何責任以及相關的風險和責任均由使用者承擔。您在使用這些第三方產品或服務時，除遵守本協議條款外，還必須遵守第三方使用者協議。因本軟體使用第三方軟體或技術而產生的任何爭議均由第三方解決，本公司不承擔任何責任。本公司不為第三方軟體或技術提供客戶服務支援。如果您需要支持，請直接聯絡第三方。
      </Text>
      <Text mt="10px">
        5、您瞭解並同意，在任何情況下，本公司均不對網路連線故障、電腦、通訊或其他系統故障、停電、罷工、勞資糾紛、暴動、起義、暴動、火災或水災、風暴、爆炸、不可抗力、戰爭、政府行為、國際和國內法院的命令、第三方不作為或公司無法合理控制的任何原因、軟體和服務無法存取、資訊和資料延遲、停滯、或錯誤、未能提供或延遲提供服務。
      </Text>
      <Text mt="10px">
        6、如因資訊網路連接故障、電腦、通訊、電力或其他系統故障、工作人員故障等原因，導致本公司向用戶重複支付或多付數位資產的，用戶有義務向本公司返還立即支付額外款項或數位資產，無論何種情況，用戶應在收到公司合理通知和提醒之日起10天內完成返還。您也同意
        在上述10
        天到期後，本公司有權自行決定直接從您的帳戶中扣除相同金額的法定或數位資產，或額外付款或數位資產。
      </Text>
      <Text mt="10px">
        7、用戶之間轉移的數位資產如出現記帳錯誤，須由用戶自行解決。本公司不負責協助撤銷或凍結相應操作所涉及的數位資產或採取任何其他措施，也不就此承擔任何責任。
      </Text>
      <Text mt="10px">
        8、如果用戶因網路交易與其他用戶發生糾紛，用戶必須向相關司法、政府和/或行政機關申請向本公司索取相關資訊。本公司不接受其他方式取得申請，也不負責透過其他管道提供相關資訊。
      </Text>
      <Text mt="10px">
        9、您瞭解並同意本公司有權決定將本軟體及服務用於商業目的。受限於《個人資料保護條例》的要求，本公司承諾在推廣過程中按照約定保護您的個人信息，您也可以根據系統設定選擇屏蔽或拒絕。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        四、軟體更新、變更和服務中斷、終止
      </Text>
      <Text mt="10px">
        1、為了改善使用者體驗和完善服務內容，本公司將持續開發新的功能或版本，並不時為您提供軟體更新（這些更新可能採取軟體替換、修改、增強的形式）
        、版本升級等。
      </Text>
      <Text mt="10px">
        2、為了確保軟體和服務的安全性和功能的一致性，本公司保留在不通知您的情況下更新軟體，或不時更改或限制部分軟體功能的權利。本軟體新版發布後，舊版軟體可能無法使用。請隨時查看並下載最新版本。未能使用最新版本下載並使用最新版本的風險將由您自行承擔。如果您繼續使用該軟體和服務，即表示您同意接受更新的軟體和服務。如果您拒絕接受更新後的軟體和服務，您應立即停止登入和使用該軟體和服務；在這種情況下，您仍然需要對您停止使用此類軟體和服務之前發生的行為承擔全部責任。
      </Text>
      <Text mt="10px">
        3、您瞭解並同意，本公司保留因軟體、服務維護、升級或其他原因暫停服務、調整服務功能或終止服務的權利。本公司將提前在公司網站或軟體中進行公告或通知。您同意本公司不會因此而對您承擔任何責任。
      </Text>
      <Text mt="10px">
        4、若本公司終止本軟體及服務，或本公司依據本協議或相關司法管轄區法律、法規的規定終止向使用者提供本軟體及服務，本公司有權終止向使用者提供本軟體及服務。有權按照原路或根據相關司法管轄區的相關法律、規則和/或法規或相關監管機構和/或機構的其他命令返還用戶帳戶中的數位資產。法規的規定
        均已處理。若因使用者原因未能退回或有其他清關障礙的，一切風險、損失及責任均由使用者自行承擔。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        五、使用者行為規範
      </Text>
      <Text mt="10px">（一）遵守當地法律</Text>
      <Text mt="10px">
        使用者有責任遵守相關司法管轄區有關使用軟體和服務的當地法律和其他適用的法律法規。使用者還必須根據相關法律、法規和/或條例考慮各方面的稅務事項，包括但不限於向其適當的稅務機關代扣代繳、徵收、報告和匯款。該軟體和服務的所有用戶保證並聲明其資金來自合法來源，並非源自非法活動；用戶同意本公司將根據有關法律或政府命令要求其提供或以其他方式收集必要的資訊和資料，以核實其資金來源和用途的合法性。該公司與全球執法機構合作，將毫不猶豫地扣押、凍結、吊銷許可證、終止或調查用戶的帳戶和資金。
      </Text>
      <Text mt="10px">（二）資訊內容合法性規範</Text>
      <Text mt="10px">
        資料的頭像、姓名、使用者描述等訊息，或文字、語音、圖片等的發送和回應）的合法性。以及因使用幣信帳號或本軟體及服務而產生的其他內容）在使用本軟體及服務過程中製作、複製、發佈、傳播的內容。用戶承諾不利用幣信帳戶或本軟體及服務進行以下創作、複製、發布、傳播等乾擾本公司正常經營及侵犯其他用戶或第三方合法權利的行為，包括但不限於：
      </Text>
      <Text mt="10px">1、傳播、傳送、傳播、儲存相關法令禁止的內容。</Text>
      <Text mt="10px">
        2、傳播、傳送、傳播、儲存侵害他人名譽權、肖像權、智慧財產權、商業機密等的內容。
      </Text>
      <Text mt="10px">3、 涉及他人隱私、個人資訊或資訊的。</Text>
      <Text mt="10px">
        4、
        發布、傳送、傳送騷擾訊息、廣告訊息、過度行銷訊息和垃圾訊息或包含任何性或性暗示訊息。
      </Text>
      <Text mt="10px">
        5、其他違反法律、法規、政策和公共道德、公共道德或公司正常經營，侵犯其他使用者或第三方合法權益的資訊。
      </Text>
      <Text mt="10px">（三）軟體使用合法性規範</Text>
      <Text mt="10px">
        1、使用者使用本軟體及服務必須尊重並維護公司智慧財產權及資訊的正常、穩定運作。使用者
        承諾不從事任何可能侵犯公司智慧財產權或影響公司正常經營的活動，包括但不限於：
      </Text>
      <Text mt="10px"> (1)刪除軟體的版權資訊；</Text>
      <Text mt="10px">
        (2) 逆向工程、逆向彙編、逆向編譯或以其他方式試圖尋找該軟體的原始碼；
      </Text>
      <Text mt="10px">
        (3)
        對公司自主智慧財產權內容進行使用、出租、出借、複製、模仿、修改、轉載、編譯、出版、建立鏡像網站等；
      </Text>
      <Text mt="10px">
        (4)
        複製、修改、新增、刪除本軟體或服務運作過程中釋放到任意終端記憶體的資料、軟體運作過程中客戶端與伺服器端的互動資料、軟體運作過程中所必需的系統資料。經營本軟體，從事營運或創作任何衍生作品，包括但不限於使用外掛程式、外掛程式或未經授權的第三方工具/服務存取本軟體及相關係統；
      </Text>
      <Text mt="10px">
        (5)
        修改或偽造軟體運作中的指令和數據，增加、刪除、改變軟體的功能或運作效果，或營運、宣傳用於上述目的的軟體和方法，無論這些行為是否屬於商業用途；
      </Text>
      <Text mt="10px">
        (6)
        登入或使用本軟體及服務，或透過非本公司開發、授權的第三方軟體、外掛程式、外掛程式、系統創建、發佈、傳播上述工具；
      </Text>
      <Text mt="10px">
        (7) 自行或授權他人或第三方軟體幹擾本軟體及其元件、模組和資料。
      </Text>
      <Text mt="10px">2、使用者在使用本軟體及服務過程中不得發生下列行為：</Text>

      <Text mt="10px">(1) 提交、發布虛假訊息，或冒充、利用他人名義的；</Text>
      <Text mt="10px">(2) 誘導其他使用者點選連結頁面或分享資訊；</Text>
      <Text mt="10px">(3) 捏造事實、隱瞞真相，誤導、欺騙他人的；</Text>
      <Text mt="10px">
        (4) 侵害他人名譽權、肖像權、智慧財產權、商業機密等合法權利；
      </Text>
      <Text mt="10px">
        (5) 以任何方式或為任何商業目的向第三方提供幣信帳戶或其任何功能；
      </Text>
      <Text mt="10px">(6) 利用幣信帳戶或軟體、服務從事任何違法犯罪活動；</Text>
      <Text mt="10px">
        (7)
        製作、發布與上述行為相關的方法或工具，或運作或傳播此類方法或工具，無論這些行為是否出於商業目的；
      </Text>
      <Text mt="10px">
        (8)
        其他違反法律法規、侵犯其他使用者合法權益、幹擾軟體和服務正常運作的行為。
      </Text>

      <Text mt="10px">（四）違約情況</Text>

      <Text mt="10px">
        1、
        若本公司發現或收到他人舉報或投訴用戶違反本協議的行為，本公司保留隨時刪除或屏蔽相關用戶而不另行通知的權利，包括但不限於根據行為的具體情況，對違規帳戶進行警告和限制。禁止使用部分或全部功能，關閉帳戶，取消處罰，並公佈結果。
      </Text>
      <Text mt="10px">
        2、
        您瞭解並同意，本公司有權根據合理判斷對違反有關法律法規或本協議規定的行為進行處罰，對任何違反法律法規的用戶採取適當的法律行動，並保存相關資訊依照法律、法規的規定向有關部門報告。舉報等所產生的一切法律責任由其自行承擔。
      </Text>
      <Text mt="10px">
        3、
        您瞭解並同意，因您違反本協議或相關服務條款而導致第三方索賠而引起的任何索賠、要求或損失，您應自行承擔責任，並承擔以下責任：本公司遭受的任何及全部損失。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        六、智慧財產
      </Text>

      {/* 30 */}
      <Text mt="10px">
        1.
        軟體和服務中包含的所有智力成果包括但不限於網站標誌、資料庫、網站設計、文字和圖形、軟體、照片、軟體編譯、原始碼、應用程式和應用程式介面的版權，與軟體和服務相關的任何其他版權和其他智慧財產權均歸本公司所有。使用者不得複製、更改、拷貝、模仿、傳播或使用任何前述資料或內容。您不得為任何目的實施、使用、轉讓或授權他人實施上述智慧財產權。任何未經授權使用本軟體及服務的行為均屬違法行為，本公司將追究相關法律責任。
      </Text>
      <Text mt="10px">
        2.軟體及服務名稱所包含的一切權益（包括但不限於商譽、商標、標誌）均歸本公司所有。
      </Text>

      {/* 32 */}
      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        七、隱私條款
      </Text>
      <Text mt="10px">
        1、
        為了提供本軟體及服務，使用者同意本公司登記使用者資料（包括使用者的個人識別資料）、使用者使用本軟體的權限及使用者的IP位址，例如本公司的IP位址。用戶收到服務函、用戶交易資料、用戶交易資料。其他透過合法途徑取得的使用者資訊被記錄、保存和使用。本公司不會向任何無關第三方提供、出售、出租、分享和交易用戶的個人資訊。但為了方便使用本軟體及服務，使用者同意對使用者的身分和資訊進行全面統計。本公司不會洩露任何可能用於識別用戶個人身份的資訊。但本公司不承擔任何分析責任。
        使用者的個人識別資訊來自使用者的使用者名稱或其他可以公開的資訊。
      </Text>
      <Text mt="10px">
        2、
        使用者同意本公司可以揭露或使用使用者的個人資訊來識別及確認使用者的身分、解決爭議，或保證軟體及服務的安全，防止或限制違法犯罪活動。使用者同意，為了保護使用者的生命、財產安全或防止嚴重侵犯他人合法權益或公共利益的需要，本公司可以揭露或使用使用者的個人資訊。使用者同意本公司可以揭露或使用使用者的個人資訊來改善軟體和服務，使軟體和服務更符合使用者的要求，從而使用戶在使用軟體和服務時獲得更好的體驗。使用者同意本公司使用使用者的個人資訊與使用者溝通並向使用者提供使用者可能感興趣的訊息，例如：介紹和推廣服務或優惠的商業簡訊。
      </Text>
      <Text mt="10px">
        3、
        當公司迫於法律強製或根據政府、法院或權利人的要求提供使用者的資訊以查明涉嫌侵權人的身分時，本公司將善意地揭露使用者的資料。當公司需要保護公司、其代理商、客戶、使用者和其他人的權利和財產，包括執行公司的協議、政策和使用條款時，公司將善意地揭露使用者資訊。
      </Text>
      <Text mt="10px">
        4、
        用戶同意，若本公司擬進行企業併購、重組、增資或出售全部或部分股份及/或資產，本公司有權揭露使用者的資料，以協助當事人交易。
      </Text>
      <Text mt="10px">
        5、 本公司不對本公司以外的第三方的隱私權聲明或其他內容負責。
      </Text>
      <Text mt="10px">6、 公司其他隱私條款詳見《隱私條款》。</Text>
      <Text mt="10px">
        7、
        使用者接受本協議及隱私條款或使用本軟體及服務，即視為明確同意或授權本公司依本公司適用的法律法規處理使用者的個人資訊及隱私權資料。有關國家或司法管轄區對個人資訊和隱私的保護（無論如何表述），包括但不限於：收集、儲存、使用、處理、傳輸、提供、揭露、刪除等。
      </Text>

      {/* 39 */}
      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        八、風險提示
      </Text>
      <Text mt="10px">
        1、
        網路是全球公共網路。它是去中心化的，不受任何機構控制。資料透過網路傳輸的方式並不完全確定，可能會受到非法幹擾或入侵。透過網路傳輸的資料可能會被一些未經授權的個人、團體或機構透過某些管道取得或竄改。網路上的資料傳輸可能會因流量過大或其他原因（例如中斷、暫停或資料不完整）而延遲，
        資料錯誤等，可能導致交易出現錯誤、延遲、中斷或暫停。由於用戶本身的疏忽，用戶的身分可能會被洩露、被假冒或洩露，從而對用戶造成損失。使用者的電腦可能會因效能缺陷、品質問題、電腦病毒、硬體故障等原因影響使用者的交易登入、交易時間或交易數據，造成使用者損失。上述風險造成的損失或責任由使用者自行承擔，本公司對此不承擔任何責任。儘管如此，本著對用戶負責的態度，公司將採用先進的網路產品和技術措施，最大限度地保護用戶資料和使用安全。
      </Text>
      <Text mt="10px">
        2、
        數位資產交易存在極高的風險。數位資產市場是新的、未經證實的，並且可能不會成長。目前數位資產主要由投機者使用，零售和商業市場使用相對較少。因此，數位資產價格往往會波動，進而對數位資產投資產生不利影響。數位資產市場沒有漲跌幅限制，交易24小時開放。由於數位資產籌碼數量較少，價格容易受到莊家的控制，可能會出現暴漲或暴跌的情況。
      </Text>
      <Text mt="10px">
        3、
        參與數位資產交易，用戶應控制自身風險，評估數位資產的投資價值和投資風險，並承擔損失全部投資的經濟風險。因國家法律、法規、規範性文件的製定或修改，導致數位資產交易被暫停或禁止，由此造成的經濟損失均由用戶自行承擔。用戶一旦向本公司註冊，即視為已充分了解並了解交易風險，並同意承擔可能發生的風險或損失。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        九、爭議解決
      </Text>
      <Text mt="10px">
        1、
        因本公司提供的軟體及服務所引起的爭議，適用香港特別行政區法律，並由香港國際仲裁中心（「HKIAC」）依《中華人民共和國仲裁法》的規定以仲裁解決。並適用當時有效的HKIAC
        規則。
      </Text>
      <Text mt="10px">
        2、
        本公司不承擔用戶與本公司其他用戶或其他網站或平台用戶之間因數位資產而產生的任何法律關係及法律糾紛，也不承擔任何法律責任
      </Text>

      {/* 44 */}
      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        十、協議的效力及解釋
      </Text>
      <Text mt="10px">
        1、
        本協議自使用者點選本公司註冊頁面同意註冊並完成註冊程序並取得帳號及密碼時生效，對本公司及使用者均具有約束力。
      </Text>
      <Text mt="10px">
        2、
        如果本協議的部分條款被認定為無效或不可執行，則本協議的其他條款仍然有效。
      </Text>
      <Text mt="10px">
        3、
        本公司發布的任何與本軟體和服務的任何功能相關的附加服務條款、使用規則或說明與本協議文本不一致，則該等附加服務條款、使用規則或說明說明應優先適用於該特定功能。
      </Text>
      <Text mt="10px">4、 本協議最終解釋權歸本公司所有。</Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        十一、投訴諮詢
      </Text>
      <Text mt="10px">
        本公司擁有專業的客戶服務團隊，為用戶及時提供故障排除和投訴回饋。如果您在使用本軟體及服務過程中有任何疑問、投訴和諮詢，可以隨時聯絡客服（幣信帳號ID：bixin）。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        十二、適當性
      </Text>
      <Text mt={"10px"}>
        在進行相關活動時，如果公司向您招攬銷售或推薦任何包括虛擬資產在內的產品，該產品必須在考慮您的財務狀況、投資經驗和投資目標後合理適合您。本協議的其他條款或我們可能要求您簽署的任何其他文件以及我們可能要求您做出的任何聲明均不會減損本條款。
      </Text>

      <Text mt="30px" fontWeight={"bold"} fontSize={"16px"} mb={"30px"}>
        十三、定價指南
      </Text>

      <Text mt="10px">
        限額及費用：請參閱公司網站
        <a
          href="https://support.bixin.com/hc/en-hk/articles/16737561979795"
          style={{ color: "#3370ff" }}
        >
          bixin.com
        </a>
      </Text>
    </Box>
  );
};

export default TWDoc;
