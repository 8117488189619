import { Box, Flex, Text, Input, Image, Button } from "@chakra-ui/react";
import { useState, useMemo, useEffect } from "react";
import { useI18n } from "@/hooks/useI18n";
import { useAppSelector } from "@/state/hooks";
import "./personal.scss";
import BXNSelect from "@/components/ui/WSelect";
import { DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ZHlocale from "antd/es/date-picker/locale/zh_CN";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
dayjs.extend(weekday);
dayjs.extend(localeData);

const FirstPage = ({
  inputOnChange,
  memoGender,
  memoCardType,
  inputParams,
  memoTradeTargets,
  memoNeedProdServices,
  handleMultipleCheck,
  toNextPage,
  allCountryList,
  sanctionsCountryList,
  noCompliantCountryList,
  memoHasOtherCountryCard,
  memoOtherCountryCardType,
  areaScanError,
  areaCompliantError,
  setAreaCompliantError,
  setAreaScanError,
  has_other_country_id_card,
  otherCountryAreaError,
  setOtherCountryAreaError,
  handleUploadOtherCountryIdCardFile,
  otherCountryIdCardFrontFileInfo,
  otherCountryIdCardOppositetFileInfo,
}) => {
  const { t } = useI18n();
  const _lang = useAppSelector((state) => state.user.language);
  const navigate = useNavigate();
  const [downLink, setDownLink] = useState("");
  const [cardTypeDisabled, setCardTypeDisabled] = useState(false);
  const { country, id_type, other_country, other_country_card_type } =
    inputParams;

  const memoCountryList = useMemo(() => {
    if (allCountryList && allCountryList.length > 0) {
      return allCountryList.map((d) => {
        return {
          ...d,
          label: d.zh_name,
          value: d.value,
        };
      });
    }
  }, [allCountryList]);

  // 默认和反显 证件发行地
  useEffect(() => {
    if (memoCountryList?.length > 0) {
      const defaultItem = memoCountryList?.filter(
        (d) => d.iso_code2 === "HK"
      )[0];
      if (!country) {
        inputOnChange(defaultItem.iso_code2, "country");
      } else {
        const item = memoCountryList?.filter((d) => d.iso_code2 === country)[0];
        if (
          sanctionsCountryList.indexOf(item.iso_code2) > -1 ||
          item.iso_code2 === "US" ||
          item.iso_code2 === "UM"
        ) {
          setAreaScanError(true);
        }

        // 非合规区
        if (noCompliantCountryList.indexOf(item.iso_code2) > -1) {
          setAreaCompliantError(true);
        }
        inputOnChange(item.iso_code2, "country");
      }
    }
  }, [memoCountryList, country]);

  // 证件发行地国家
  const selectCountry = (value) => {
    inputOnChange(value.iso_code2, "country");
    setAreaScanError(false);
    setAreaCompliantError(false);
    // 在制裁区 + 美国、美国本土外小岛
    if (
      sanctionsCountryList.indexOf(value.iso_code2) > -1 ||
      value.iso_code2 === "US" ||
      value.iso_code2 === "UM"
    ) {
      setAreaScanError(true);
      return;
    }

    // 非合规区
    if (noCompliantCountryList.indexOf(value.iso_code2) > -1) {
      setAreaCompliantError(true);
      return;
    }
  };

  // 默认证件发行地国家 未做选择时 默认是香港
  const defaultCountry = useMemo(() => {
    if (memoCountryList?.length > 0) {
      if (country) {
        return memoCountryList.filter((d) => d.iso_code2 === country)[0];
      } else {
        return memoCountryList?.filter((d) => d.iso_code2 === "HK")[0];
      }
    }
  }, [country, memoCountryList]);

  // 其他证件发行地国家
  const defaultOtherCountry = useMemo(() => {
    if (memoCountryList?.length > 0) {
      if (other_country) {
        return memoCountryList.filter((d) => d.iso_code2 === other_country)[0];
      }
    }
  }, [other_country, memoCountryList]);

  // 默认其他证件发行地国家
  const defaultOtherCountryCardType = useMemo(() => {
    if (memoOtherCountryCardType?.length > 0) {
      if (!other_country_card_type) {
        return memoOtherCountryCardType[0];
      } else {
        return memoOtherCountryCardType.filter(
          (d) => d.value === other_country_card_type
        )[0];
      }
    }
  }, [other_country_card_type, memoOtherCountryCardType]);

  useEffect(() => {
    if (memoOtherCountryCardType?.length > 0) {
      if (!other_country_card_type) {
        inputOnChange(
          memoOtherCountryCardType[0].value,
          "other_country_card_type"
        );
      } else {
        const item = memoOtherCountryCardType.filter(
          (d) => d.value === other_country_card_type
        )[0];
        inputOnChange(item.value, "other_country_card_type");
      }
    }
  }, [other_country_card_type, memoOtherCountryCardType]);

  useEffect(() => {
    const maps = {
      zh: "https://file.bixin.com/kyc/information/D09_Appendix_F_NewBX-Individual-Onboarding_Form_V1.4_S.pdf ",
      tw: "https://file.bixin.com/kyc/information/D09_Appendix_F_NewBX-Individual-Onboarding_Form_V1.4_T.pdf",
      en: "https://file.bixin.com/kyc/information/D09_Appendix_F_NewBX-Individual-Onboarding_Form_V1.4_T.pdf",
    };
    if (_lang) {
      setDownLink(maps[_lang]);
    }
  }, [_lang]);

  useEffect(() => {
    if (id_type === "ID_CARD") {
      const hk_opt = memoCountryList?.filter((d) => d.iso_code2 === "HK")[0];
      inputOnChange(hk_opt.iso_code2, "country");
      setCardTypeDisabled(true);
      setAreaCompliantError(false);
      setAreaScanError(false);
    } else {
      setCardTypeDisabled(false);
    }
  }, [id_type]);

  const showOtherCountryBlock = useMemo(() => {
    if (id_type === "PASSPORT") {
      if (Number(has_other_country_id_card) === 1 || areaCompliantError) {
        return true;
      }
    }
    return false;
  }, [has_other_country_id_card, id_type, areaCompliantError]);

  const selectOtherCountry = (value) => {
    inputOnChange(value.iso_code2, "other_country");
    setOtherCountryAreaError(false);
    // 在制裁区
    if (sanctionsCountryList.indexOf(value.iso_code2) > -1) {
      setOtherCountryAreaError(true);
      // setAreaError(true);
      return;
    }
    // 非合规区
    if (noCompliantCountryList.indexOf(value.iso_code2) > -1) {
      setOtherCountryAreaError(true);
      return;
    }
  };

  const renderItem = (item) => {
    return (
      <Flex alignItems={"center"}>
        <Text fontSize="12px">
          {`${_lang === "zh" ? item.zh_name : item.en_name}  (+${
            item.dial_code
          })`}
        </Text>
      </Flex>
    );
  };

  const renderOtherCardType = (item) => {
    return (
      <Flex alignItems={"center"}>
        <Text fontSize="12px">{item.label}</Text>
      </Flex>
    );
  };

  const renderOtherCountryCardFile = (type) => {
    const file_value =
      type === 1
        ? otherCountryIdCardFrontFileInfo?.fileName ||
          inputParams?.other_country_card_front_file?.[0]?.document_path
        : otherCountryIdCardOppositetFileInfo?.fileName ||
          inputParams?.other_country_card_opposite_file?.[0]?.document_path;

    const file_type = type === 1 ? "front" : "opposite";

    const file_error =
      type === 1
        ? otherCountryIdCardFrontFileInfo?.error
        : otherCountryIdCardOppositetFileInfo?.error;

    return (
      <>
        <Text mt={"30px"} color={"rgba(1, 3, 19, 1)"} fontSize={"14px"}>
          {type === 1 ? t("oSDxsXgENbIeNMLv") : t("aoAIqdHabiquSjWWz")}
        </Text>
        <Text></Text>
        <Box position="relative">
          <Flex
            justifyContent={"space-between"}
            mt="10px"
            alignItems={"center"}
          >
            <Input
              w="80px"
              type="file"
              opacity={"0"}
              position={"absolute"}
              right="0"
              zIndex={"2"}
              cursor="pointer"
              fontSize="0"
              bg={"#fff"}
              onChange={(e) => {
                handleUploadOtherCountryIdCardFile(e, file_type);
              }}
            />
            <Input
              bg={"#fff"}
              w={["220px", "310px"]}
              disabled
              fontSize="12px"
              height={"44px"}
              borderRadius={"12px"}
              placeholder={
                type === 1 ? t("idqLvJqjeiEmQbpa") : t("FhkGAkzeRaGMUGCREr")
              }
              _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
              value={file_value}
            />
            <Button
              w="80px"
              h="30px"
              fontSize="12px"
              color="#fff"
              borderRadius={"20px"}
              bg="blue.100"
              ml={"10px"}
              textAlign={"center"}
              _hover={{ bg: "blue.80" }}
            >
              {t("NqgOQEXQaHsramCnbX")}
            </Button>
          </Flex>
          <Text
            color={file_error ? "red.100" : "rgba(123, 124, 143, 1)"}
            fontSize={"10px"}
            mt={"5px"}
          >
            {t("ZAhuzRDlLUTXhKrhhx")}
          </Text>
        </Box>
      </>
    );
  };

  return (
    <Flex
      width={["95%", "400px"]}
      flexDirection={"column"}
      margin={"0 auto"}
      mt={"50px"}
    >
      <Text fontSize="14px" color="t.100" mt="40px" fontWeight={500}>
        {t("ZnbyJyzHTxzgLRDFBmEm")}
      </Text>
      <Box
        mt={"20px"}
        background={"#EBEDF5"}
        borderRadius={"12px"}
        padding={"10px"}
      >
        {/* 个人资料 */}
        <Text
          className="kyc-personal-tip-text"
          fontSize={"12px"}
          color={"t.100"}
          onClick={(e) => {
            if (e.target.nodeName === "B" && downLink) {
              window.open(downLink, "_blank");
            }
          }}
          dangerouslySetInnerHTML={{ __html: t("FTVpPZybylBmTnwhMhtx") }}
        ></Text>
      </Box>
      {/* 名 姓 */}
      <Flex mt={"40px"}>
        <Flex flexDirection={"column"} flex={"1"}>
          <Text fontSize="14px" fontWeight={400}>
            {t("THMxZAxLcuuIfPErpGb")}
          </Text>
          <Input
            h="44px"
            fontSize="12px"
            color="t.100"
            borderRadius={"12px"}
            placeholder={t("idqLvJqjeiEmQbpa")}
            mt="10px"
            maxLength={100}
            value={inputParams.first_name}
            onChange={(e) => inputOnChange(e.target.value, "first_name")}
          />
        </Flex>
        <Flex flexDirection={"column"} flex={"1"} ml={"20px"}>
          <Text fontSize="14px" fontWeight={400}>
            {t("bTWntlsiiBougPgd")}
          </Text>
          <Input
            h="44px"
            fontSize="12px"
            color="t.100"
            borderRadius={"12px"}
            placeholder={t("idqLvJqjeiEmQbpa")}
            mt="10px"
            maxLength={100}
            onChange={(e) => inputOnChange(e.target.value, "last_name")}
            value={inputParams.last_name}
          />
        </Flex>
      </Flex>
      {/* 曾用名 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("HmoCuzIHcMwWJJxszus")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          placeholder={t("vPVjNqmPYGCrexspl")}
          mt="10px"
          maxLength={100}
          value={inputParams.former_name}
          onChange={(e) => inputOnChange(e.target.value, "former_name")}
        />
      </Flex>

      {/* 性别 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={600}>
          {t("oMgGPGaGAlElFewgOv")}
        </Text>
        <Flex mt={"10px"}>
          {memoGender.map((d, i) => {
            return (
              <Flex ml={i === 1 && "40px"} key={i}>
                <Image
                  onClick={() => inputOnChange(d.value, "gender")}
                  cursor={"pointer"}
                  src={`../../wwwsite/images/kyc/radio${
                    inputParams.gender === d.value ? "_checked" : ""
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Flex>

      {/* 证件类型 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={600}>
          {t("VALtZMIyzODbkpPUOsxG")}
        </Text>
        <Flex mt={"10px"}>
          {memoCardType.map((d, i) => {
            return (
              <Flex ml={i === 1 && "40px"} key={i}>
                <Image
                  onClick={() => inputOnChange(d.value, "id_type")}
                  cursor={"pointer"}
                  src={`../../wwwsite/images/kyc/radio${
                    inputParams.id_type === d.value ? "_checked" : ""
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Flex>

      {/* 证件号码 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("IzXQAIEgQLMYmZwrzP")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          placeholder={t("idqLvJqjeiEmQbpa")}
          mt="10px"
          maxLength={100}
          value={inputParams.id_number}
          onChange={(e) => inputOnChange(e.target.value, "id_number")}
        />
      </Flex>

      {/* 证件发行地 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("xjuVMsJZTRgjPgAzSV")}
        </Text>
        <Box mt={"15px"}>
          <BXNSelect
            disabled={cardTypeDisabled}
            options={memoCountryList}
            defaultOpt={defaultCountry}
            renderLabel={(item) => renderItem(item)}
            renderItem={(item) => renderItem(item)}
            handleSelect={(item) => {
              selectCountry(item);
            }}
          ></BXNSelect>
          {areaScanError && (
            <Text color={"#D04457"} fontSize={"14px"} mt={"5px"}>
              {t("mzWsFtJNWfexuuUgYctv")}
            </Text>
          )}
        </Box>
      </Flex>

      {/* (id_type === "PASSPORT" &&
          Number(inputParams?.has_other_country_id_card?.value) === 1) */}
      {/* 证件类型为护照时，证件发行地为除制裁+美国外的其他非合规地区时 */}
      {showOtherCountryBlock && (
        <Box
          bg={"rgba(250, 250, 250, 1)"}
          borderRadius={"12px"}
          border={"1px solid rgba(227, 228, 241, 1)"}
          padding={"20px 15px"}
          mt={"10px"}
        >
          <Flex flexDirection={"column"}>
            <Text fontWeight={400} fontSize={"14px"}>
              {t("zLnEBdSfUXdqbRqAohY")}
            </Text>
            <Flex mt={"10px"}>
              {memoHasOtherCountryCard.map((d, i) => {
                return (
                  <Flex ml={i === 1 && "40px"} key={i}>
                    <Image
                      onClick={() => {
                        inputOnChange(d, "has_other_country_id_card");
                        setOtherCountryAreaError(false);
                        inputOnChange("", "other_country");
                        // inputOnChange("", "other_country_card_type");
                      }}
                      cursor={"pointer"}
                      src={`../../wwwsite/images/kyc/radio${
                        Number(
                          inputParams?.has_other_country_id_card?.value
                        ) === Number(d.value)
                          ? "_checked"
                          : ""
                      }.svg`}
                    />
                    <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                      {d.label}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
            {Number(inputParams?.has_other_country_id_card?.value) === 2 && (
              <Text color={"#D04457"} fontSize={"14px"} mt={"15px"}>
                {t("mzWsFtJNWfexuuUgYctv")}
              </Text>
            )}
          </Flex>
          {Number(inputParams?.has_other_country_id_card?.value) === 1 && (
            <Flex mt={"20px"} flexDirection={"column"}>
              <Text fontSize={"14px"} fontWeight={400}>
                {t("oVvhpgfhTeyLLnTSv")}
              </Text>
              <Text
                fontSize="12px"
                fontWeight={400}
                mt={"10px"}
                color={"rgba(0, 0, 0, 0.49)"}
              >
                {t("OEfurFwwcJaTsVxNdol")}
              </Text>
              <Flex mt={"30px"} flexDirection={"column"}>
                <Text fontSize="14px" fontWeight={400}>
                  {t("xjuVMsJZTRgjPgAzSV")}
                </Text>
                <Box mt={"15px"}>
                  <BXNSelect
                    style={{ background: "#fff", borderRadius: "12px" }}
                    options={memoCountryList}
                    defaultOpt={defaultOtherCountry}
                    renderLabel={(item) => renderItem(item)}
                    renderItem={(item) => renderItem(item)}
                    handleSelect={(item) => {
                      selectOtherCountry(item);
                    }}
                  ></BXNSelect>
                  {otherCountryAreaError && (
                    <Text color={"#D04457"} fontSize={"14px"} mt={"15px"}>
                      {t("mzWsFtJNWfexuuUgYctv")}
                    </Text>
                  )}
                </Box>
              </Flex>
            </Flex>
          )}

          {!otherCountryAreaError &&
            Number(inputParams?.has_other_country_id_card?.value) === 1 &&
            inputParams?.other_country && (
              <>
                <Flex flexDirection={"column"} mt={"40px"}>
                  <Text fontSize="14px" fontWeight={400}>
                    {t("hoTQEpJFfRRLZGLWiDK")}
                  </Text>
                  <Box mt={"15px"}>
                    {/* 其他国家证件类型 */}
                    <BXNSelect
                      style={{ background: "#fff", borderRadius: "12px" }}
                      options={memoOtherCountryCardType}
                      defaultOpt={defaultOtherCountryCardType}
                      renderLabel={(item) => renderOtherCardType(item)}
                      renderItem={(item) => renderOtherCardType(item)}
                      handleSelect={(item) => {
                        inputOnChange(item.value, "other_country_card_type");
                        // selectOtherCountry(item);
                        // selectOtherCardType(it)
                      }}
                    ></BXNSelect>
                  </Box>
                </Flex>
                <Text mt={"30px"} color={"rgba(1, 3, 19, 1)"} fontSize={"14px"}>
                  {t("wZuMVSBUSlQidCQpjoS")}
                </Text>
                {renderOtherCountryCardFile(1)}
                {renderOtherCountryCardFile(2)}
              </>
            )}
        </Box>
      )}

      {/* 国籍 */}
      {/* <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("WACiElgvWMUDhQMxa")}
        </Text>
        <Box mt={"15px"}>
          <BXNSelect
            options={memoCountryList}
            defaultOpt={defaultNationality}
            renderLabel={(item) => renderItem(item)}
            renderItem={(item) => renderItem(item)}
            handleSelect={(item) => {
              inputOnChange(item.iso_code2, "nationality");
            }}
          ></BXNSelect>
        </Box>
      </Flex> */}

      {/* 出生地 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("USZqODoQgzwaggnbb")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          // color="t.100"
          borderRadius={"12px"}
          placeholder={t("idqLvJqjeiEmQbpa")}
          mt="10px"
          maxLength={100}
          value={inputParams?.birth_place}
          onChange={(e) => inputOnChange(e.target.value, "birth_place")}
        />
      </Flex>
      {/* 出生日期 */}
      <Box>
        <Text fontSize="14px" mt="20px">
          {t("KChGPohSmJORAMDUqSs")}
        </Text>
        <DatePicker
          style={{
            width: "100%",
            color: "#B3B4C8",
            height: "44px",
            marginTop: "10px",
            fontSize: "10px",
            borderRadius: "12px",
            border: "1px solid #E3E4F1",
            backgroundColor: "#fff",
          }}
          className="personal-key-date-pick"
          placeholder={t("idqLvJqjeiEmQbpa")}
          onChange={(date, dateString) => {
            inputOnChange(dateString, "birth_date");
          }}
          value={
            inputParams?.birth_date &&
            dayjs(inputParams?.birth_date, "YYYY-MM-DD")
          }
          // defaultValue={dayjs(inputParams?.birth_date, "YYYY-MM-DD")}
          // value={inputParams?.birth_date}
          locale={_lang === "en" ? "" : ZHlocale}
        />
      </Box>
      {/* 职业 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("oGZZfIIocJcraogK")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          placeholder={t("idqLvJqjeiEmQbpa")}
          value={inputParams.occupation}
          mt="10px"
          maxLength={100}
          onChange={(e) => inputOnChange(e.target.value, "occupation")}
        />
      </Flex>

      {/* 就职行业 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("pAYXQPFeRbuYruZa")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          placeholder={t("idqLvJqjeiEmQbpa")}
          value={inputParams.occupation_industry}
          mt="10px"
          maxLength={100}
          onChange={(e) => inputOnChange(e.target.value, "occupation_industry")}
        />
      </Flex>

      {/* 就职公司名 */}
      <Flex mt={"20px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={400}>
          {t("aaOdxWUHHLynEVSoWTM")}
        </Text>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          placeholder={t("idqLvJqjeiEmQbpa")}
          mt="10px"
          maxLength={100}
          value={inputParams.employer_name}
          onChange={(e) => inputOnChange(e.target.value, "employer_name")}
        />
      </Flex>

      {/* 交易目的 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={600}>
          {t("jSQVlHGDwxAZujRJNiSh")}
        </Text>
        <Flex mt={"10px"}>
          {memoTradeTargets.map((d, i) => {
            return (
              <Flex ml={i > 0 && "40px"} alignItems={"center"} key={i}>
                <Image
                  cursor={"pointer"}
                  onClick={() => handleMultipleCheck(d, "transaction_purpose")}
                  src={`../../wwwsite/images/kyc/${
                    inputParams?.transaction_purpose?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "checked"
                      : "no_check"
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        <Input
          h="44px"
          fontSize="12px"
          color="t.100"
          borderRadius={"12px"}
          placeholder={t("uuluQlsJBdDsslsERZX")}
          mt="20px"
          maxLength={300}
          value={inputParams?.transaction_purpose?.description}
          onChange={(e) => inputOnChange(e.target.value, "transaction_purpose")}
        />
      </Flex>

      {/* 所需产品服务 */}
      <Flex mt={"30px"} flexDirection={"column"}>
        <Text fontSize="14px" fontWeight={600}>
          {t("ACweJQrKoWgpyYspyEl")}
        </Text>
        <Flex mt={"10px"}>
          {memoNeedProdServices.map((d, i) => {
            return (
              <Flex ml={i > 0 && "40px"} alignItems={"center"} key={i}>
                <Image
                  cursor={"pointer"}
                  onClick={() => handleMultipleCheck(d, "products_services")}
                  src={`../../wwwsite/images/kyc/${
                    inputParams?.products_services?.options?.findIndex(
                      (x) => x.value === d.value
                    ) > -1
                      ? "checked"
                      : "no_check"
                  }.svg`}
                />
                <Text fontSize={"14px"} color={"t.100"} ml={"4px"}>
                  {d.label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
        {inputParams?.products_services?.options?.findIndex(
          (d) => d.value === 3
        ) > -1 && (
          <Input
            h="44px"
            fontSize="12px"
            color="t.100"
            borderRadius={"12px"}
            placeholder={`${t("WjPizXTxbdPnvLWMql")}  (${t(
              "idqLvJqjeiEmQbpa"
            )})`}
            mt="20px"
            maxLength={100}
            value={inputParams?.products_services?.description}
            onChange={(e) => inputOnChange(e.target.value, "products_services")}
          />
        )}
      </Flex>

      {/* 分页 */}
      <Flex justifyContent={"center"} mt="30px" mb={"50px"}>
        <Button
          mr="10px"
          fontSize="14px"
          padding="0 30px"
          w={["100%", "100px"]}
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => {
            navigate("/trade/personKyc/certificate");
          }}
        >
          {t("WbPTckCFMKtPHgAlL")}
        </Button>
        <Button
          fontSize="14px"
          padding="0 30px"
          w={["100%", "200px"]}
          bg="blue.100"
          color="#fff"
          fontWeight={"400"}
          borderRadius={"30px"}
          _hover={{ bg: "blue.80" }}
          h="44px"
          cursor={"pointer"}
          onClick={() => toNextPage()}
        >
          {t("tOaRBVUJHpnSgDBrGON")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default FirstPage;
