import BigNumber from 'bignumber.js';

BigNumber.config({
  DECIMAL_PLACES: 20,
  ROUNDING_MODE: BigNumber.ROUND_DOWN, // 向下
  FORMAT: {
    groupSize: 3,
    groupSeparator: ',',
    decimalSeparator: '.',
  },
});


export const toFixed = (value, precision) => {
  return new BigNumber(value).toFixed(precision).toString();
};

/**
 *
 * @param value  需要格式化的字符串
 * @param dp 保留几位小数 默认保留所有小数
 * @param rm 最后一位保留方式，默认为1向下取整
 * @returns
 */
export const toFormat = (value, dp = 13, rm = 1) => {
  return new BigNumber(value).toFormat().toString();
};

export const times = (m, n) => {
  return new BigNumber(m).times(n).toString();
};

export const plus = (value, n) => {
  return new BigNumber(value).plus(n).toString();
};

export const gt = (m, n) => {
  return new BigNumber(m).isGreaterThan(n);
};

export const gte = (m, n) => {
  return new BigNumber(m).isGreaterThanOrEqualTo(n);
};

export const lt = (m, n) => {
  return new BigNumber(m).isLessThan(n);
};

export const lte = (m, n) => {
  return new BigNumber(m).isLessThanOrEqualTo(n);
};

export const eq = (m, n) => {
  return new BigNumber(m).isEqualTo(n);
};

export const div = (m, n) => {
  return new BigNumber(m).div(n).toString();;
};

export const minus = (m, n) => {
  return new BigNumber(m).minus(n);
};
