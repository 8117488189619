// 公司 无变更、有变更 中高风险结果页
import { useI18n } from "@/hooks/useI18n";
import { Text, Flex, Image } from "@chakra-ui/react";
import { submitCompanyUpdateInfoApi } from "@/api/perodic";
import { useEffect } from "react";

const CompanyResult = ({ isUpdate, reviewUUID, canPeriodic }) => {
  const { t } = useI18n();

  const submitNoUpdateInfo = async () => {
    const params = {
      isUpdate: false,
      review_uuid: reviewUUID,
    };

    await submitCompanyUpdateInfoApi(params);
  };

  useEffect(() => {
    if (isUpdate && reviewUUID && canPeriodic) {
      submitNoUpdateInfo();
    }
  }, [isUpdate, reviewUUID, canPeriodic]);

  return (
    <Flex
      flexDirection={"column"}
      style={{ backgroundColor: "#fff" }}
      height={"auto"}
      minHeight={"100vh"}
      width={["calc(100% - 30px)", "400px"]}
      overflow={"hidden"}
      margin={"0 auto"}
      mt={"70px"}
      color={"#010313"}
      fontSize={"14px"}
      alignItems={"center"}
    >
      <Image
        src="../../../wwwsite/images/kyc/success.svg"
        alt=""
        w={"40px"}
        h={"40px"}
      />

      <Text fontSize={"24px"} fontWeight={600} mt={"10px"}>
        {t("cWYINWiSUUdFuDAImP")}
      </Text>
      <Text mt={"10px"}>{t("dIOuyFmCcSAXzBifCgd")}</Text>
      <Flex
        bg={"rgba(130, 129, 140, 0.08)"}
        display={"flex"}
        padding={"10px 15px"}
        borderRadius={"8px"}
        fontSize={"14px"}
        flexDirection={"column"}
        mt={"20px"}
        w={"100%"}
      >
        <Text color={"#010313"}>{t("YvXCqALOsnrEQAHU")}</Text>
        <Text color={"#333BF5"}>support@bixin.com</Text>
      </Flex>
    </Flex>
  );
};

export default CompanyResult;
