import { useI18n } from "@/hooks/useI18n";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const AddBtn = () => {
  const { t } = useI18n();
  const navigate = useNavigate();

  return (
    <Box
      mt="30px"
      mb="20px"
      w="112px"
      h="40px"
      bg="rgba(32, 26, 85, 0.05)"
      borderRadius={"32px"}
      cursor={"pointer"}
      onClick={() => navigate("/trade/wallet")}
    >
      <Flex justifyContent={"center"} alignItems={"center"} w="112px" h="40px">
        <ArrowBackIcon mr={"10px"} />
        <Text fontSize="14px">{t("WbPTckCFMKtPHgAlL")}</Text>
      </Flex>
    </Box>
  );
};

export default AddBtn;
