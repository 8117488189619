import { useNavigate } from "react-router-dom";
import { useI18n } from "@/hooks/useI18n";
import {
  Box,
  Center,
  Text,
  Image,
  Flex,
  Button,
  Input,
} from "@chakra-ui/react";
import { useLiceseHooks } from "./hooks";
import BXLoading from "@/components/ui/pay-loading";

const LicesedPerson = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const {
    onSubmit,
    handleFile,
    viewStatus,
    uploadLoading,
    fileName,
    filePath,
    checked,
    setChcked,
    errorType,
  } = useLiceseHooks();

  const renderPendingView = () => {
    if (checked) {
      return (
        <Box
          w={["100%", "400px"]}
          margin={"0 auto"}
          mt={"60px"}
          borderRadius={"12px"}
          textAlign={"center"}
          fontWeight={"bold"}
        >
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text
              fontSize={"14px"}
              fontWeight={600}
              color={"#010313"}
              mt={"10px"}
            >
              {t("vshrtYQXEVpbXmLZwV")}
            </Text>
          </Flex>
          <Button
            mt="40px"
            fontSize="14px"
            padding="0 30px"
            w="100px"
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => navigate("/trade/personKyc/certificate")}
          >
            {t("FrzWGSiDSDzTfNgZH")}
          </Button>
        </Box>
      );
    }
    return (
      <Box
        w={["100%", "400px"]}
        margin={"0 auto"}
        mt={"60px"}
        borderRadius={"12px"}
        textAlign={"center"}
        fontWeight={"bold"}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src={`../../wwwsite/images/kyc/certificate/pending.svg`}
            width={"40px"}
            height={"40px"}
            alt=""
          ></Image>
          <Text
            fontSize={"24px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {t("KzFzOytWXScqDRKFwg")}
          </Text>
          <Text
            fontSize={"14px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {t("YXrqAGUqprWfOCeiSGX")}
          </Text>
        </Flex>
        <Button
          mt="40px"
          fontSize="14px"
          padding="0 30px"
          w="100px"
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => navigate("/trade/personKyc/certificate")}
        >
          {t("FrzWGSiDSDzTfNgZH")}
        </Button>
      </Box>
    );
  };

  const renderInitView = () => {
    return (
      <>
        <Box
          bg={"#232325"}
          borderRadius={"12px"}
          padding={"10px"}
          color={"#FFF"}
          mt={"30px"}
        >
          {t("ckNnmPAbtNOsWqlpAKy")}
        </Box>
        <Flex flexDirection={"column"} mt={"40px"}>
          <Text fontSize={"16px"} color={"#000"} fontWeight={600}>
            {t("ISJVpXHVtpoxunGygBGu")}
          </Text>
          <Text fontSize={"16px"} color={"#7B7C8F"} mt={"15px"}>
            {t("nmOEDJmAsIcTkCgZMv")}
          </Text>

          <Box position="relative">
            <Flex justifyContent={"space-between"} mt="10px">
              <Input
                w="80px"
                type="file"
                opacity={"0"}
                position={"absolute"}
                right="0"
                zIndex={"2"}
                cursor="pointer"
                fontSize="0"
                onChange={(e) => {
                  handleFile(e);
                }}
              />
              <Input
                w={["220px", "310px"]}
                disabled
                borderRadius={"12px"}
                _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
                value={fileName}
              />
              <Button
                w="80px"
                h="30px"
                fontSize="12px"
                color="#fff"
                borderRadius={"20px"}
                bg="blue.100"
                textAlign={"center"}
                _hover={{ bg: "blue.80" }}
                mt="5px"
              >
                {t("NqgOQEXQaHsramCnbX")}
              </Button>
            </Flex>
            <Text
              fontSize="12px"
              color={filePath === "error" ? "red.100" : "t.80"}
              mt="10px"
            >
              {t("ZAhuzRDlLUTXhKrhhx")}
            </Text>
          </Box>
          <Text
            color={"#333BF5"}
            fontSize={"14px"}
            mt={"40px"}
            paddingRight={"10px"}
            dangerouslySetInnerHTML={{ __html: t("NZjrOkmPAHtiaMLyuVDA") }}
          ></Text>
          {/* checkbox */}
          <Flex mt={"15px"} alignItems={"center"}>
            <Image
              onClick={() => setChcked(!checked)}
              cursor={"pointer"}
              src={
                checked
                  ? "../../wwwsite/images/kyc/checked.svg"
                  : "../../wwwsite/images/kyc/no_check.svg"
              }
            />
            <Text color={"#010313"} fontSize={"14px"} ml={"10px"}>
              {t("XfjZomFsTRpbZJuHjI")}
            </Text>
          </Flex>
        </Flex>
        <Text color={"#D04457"} margin={"40px 0px"} textAlign={"center"}>
          {errorType === "empty_error"
            ? t("lxppTPAJDxxMIGdmiRG")
            : errorType === "only_error"
            ? t("OljlLKYobPynbcStGlN")
            : ""}
        </Text>
        {/* 底部按钮 */}
        <Flex justifyContent={"center"} mt="60px" mb={"50px"}>
          <Button
            mr="10px"
            fontSize="14px"
            padding="0 30px"
            w={["30%", "100px"]}
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              navigate("/trade/personKyc/certificate");
            }}
          >
            {t("FrzWGSiDSDzTfNgZH")}
          </Button>
          <Button
            fontSize="14px"
            padding="0 30px"
            w={["60%", "200px"]}
            bg={"blue.100"}
            _hover={{ bg: "blue.80" }}
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              onSubmit();
            }}
          >
            {t("QFbYEdxUgXzRwcPFQDis")}
          </Button>
        </Flex>
      </>
    );
  };

  return (
    <Box w={"100%"} margin="0 auto">
      <Center>
        <Image src="../../wwwsite/images/kyc/user.svg" />
        <Text fontSize={32} fontWeight={700} ml={"10px"}>
          {t("XjTUjttgmruHnxFXgVf")}
        </Text>
      </Center>
      {viewStatus === "INIT" && renderInitView()}
      {viewStatus === "PENDING" && renderPendingView()}
      {uploadLoading && <BXLoading />}
    </Box>
  );
};

export default LicesedPerson;
