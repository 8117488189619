import PageWrap from "@/components/PageWrap";
import { useEffect, useState } from "react";
import { useAppSelector } from "@/state/hooks";
import { useI18n } from "@/hooks/useI18n";
import { Box, Center, Text, Image, Flex } from "@chakra-ui/react";
import VerifyIdentity from "./VerifyIdentity/index";
import VerifyAddress from "./VerifyAddress";
import VerifyAssets from "./VerifyAssets";
import PersonalInfo from "./PersonalInfo/index";
import { useCommonHooks } from "./verifyHooks";

const BaseInfo = () => {
  const { allCountryList, sanctionsCountryList, noCompliantCountryList } =
    useCommonHooks();
  const [step, setStep] = useState(1);
  const { t } = useI18n();
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);
  const [isMounted, setIsMounted] = useState(true);

  // 一、二步的状态 由三去决定
  useEffect(() => {
    if (kycInfo?.length > 0 && isMounted) {
      setIsMounted(false);
      const firstStep = kycInfo[0];
      const secondStep = kycInfo[1];
      const thirdStep = kycInfo[2];
      if (firstStep.status === "REQUIRED") {
        setStep(1);
      } else if (
        firstStep.status === "PENDING" &&
        secondStep.status === "REQUIRED"
      ) {
        setStep(2);
      } else if (
        firstStep.status !== "REQUIRED" &&
        secondStep.status !== "REQUIRED"
      ) {
        setStep(3);
      } else if (thirdStep.status !== "REQUIRED") {
        setStep(3);
      }
    }
  }, [kycInfo, isMounted]);

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
        pt={"60px"}
      >
        <Flex
          w={["80%", "460px"]}
          flexDirection={"column"}
          margin="0 auto"
          alignItems={"center"}
        >
          <Center>
            <Image src="../../wwwsite/images/kyc/user.svg" />
            <Text fontSize={32} fontWeight={700} ml={"10px"}>
              {t("CRMoLKUrfyLkDWFEuSu")}
            </Text>
          </Center>

          {step === 1 && (
            <PersonalInfo
              setStep={setStep}
              allCountryList={allCountryList}
              sanctionsCountryList={sanctionsCountryList}
              noCompliantCountryList={noCompliantCountryList}
            />
          )}
          {step === 2 && (
            <VerifyIdentity setStep={setStep} allCountryList={allCountryList} />
          )}
          {step === 3 && (
            <VerifyAddress
              setStep={setStep}
              allCountryList={allCountryList}
              sanctionsCountryList={sanctionsCountryList}
              noCompliantCountryList={noCompliantCountryList}
            />
          )}
          {step === 4 && <VerifyAssets />}
        </Flex>
      </Box>
    </PageWrap>
  );
};

export default BaseInfo;
