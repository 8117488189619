import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { useState } from "react";
import { BIXIN_WALLET, BIXIN_KYC } from "@/helpers/constants/routeName";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useI18n } from "@/hooks/useI18n";
import { getUserInfo } from "./api";
import { updateUserInfo } from "@/state/user/reducer";
const Success = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uid, setUid] = useState("");
  const toHome = () => {
    navigate(BIXIN_WALLET);
  };

  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      setUid(res.data.uid);
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };
  const startKyc = () => {
    // localStorage.setItem("bx_registerStep", 1);
    navigate(BIXIN_KYC);
  };
  useEffect(() => {
    getUserInfoFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Flex justifyContent={"center"} mt={["100px", "200px"]}>
        <Box w={["80%", "400px"]}>
          <Flex justifyContent={"center"}>
            <img src="../wwwsite/images/success.svg" alt="success" />
            <Text fontSize="32px" fontWeight={"600"} color="t.100" ml="10px">
              {t("NovuNXyQGDRrjfyMs")}
            </Text>
          </Flex>
          <Flex justifyContent={"center"} mt="50px">
            <Text fontSize="14px">
              {t("kfASiYACukCdCVcFmcsq").replace("%s%", "")}{" "}
            </Text>
            <Text
              fontSize="14px"
              color={"blue.100"}
              fontWeight={"500px"}
              ml="5px"
            >
              {uid}
            </Text>
          </Flex>
          <Text fontSize="14px" mt="6px" textAlign={"center"}>
            {t("RZgUzTVRaXNflgjshVf")}
          </Text>
          {/* <Box
            w={["100%", "400px"]}
            h="260px"
            bg="blue.50"
            border="1px solid #333BF5"
            borderRadius={"12px"}
            mt="20px"
          >
            <Flex justifyContent={"center"} mt="50px">
              <Box w="120px">
                <Flex justifyContent={"flex-start"}>
                  <Flex alignItems={"center"}>
                    <Box
                      w="8px"
                      h="8px"
                      bg="blue.100"
                      borderRadius={"8px"}
                    ></Box>
                    <Text ml="8px" fontSize="14px">
                      {t("OivHVusJobtDPuGMSbDN")}
                    </Text>
                  </Flex>
                </Flex>
                <Flex justifyContent={"flex-start"}>
                  <Box w="2px" h="24px" bg="blue.100" ml="3px"></Box>
                </Flex>
                <Flex justifyContent={"flex-start"}>
                  <Flex alignItems={"center"}>
                    <Box
                      w="8px"
                      h="8px"
                      bg="blue.100"
                      borderRadius={"8px"}
                    ></Box>
                    <Text ml="8px" fontSize="14px">
                      {t("HNryhyKcRNPaLZpBHK")}
                    </Text>
                  </Flex>
                </Flex>
                <Flex justifyContent={"flex-start"}>
                  <Box w="2px" h="24px" bg="blue.100" ml="3px"></Box>
                </Flex>
                <Flex justifyContent={"flex-start"}>
                  <Flex alignItems={"center"}>
                    <Box
                      w="8px"
                      h="8px"
                      bg="blue.100"
                      borderRadius={"8px"}
                    ></Box>
                    <Text ml="8px" fontSize="14px">
                      {t("IDPsMPUXhtCczMWcA")}
                    </Text>
                  </Flex>
                </Flex>
                <Flex justifyContent={"flex-start"}>
                  <Box w="2px" h="24px" bg="blue.100" ml="3px"></Box>
                </Flex>
                <Flex justifyContent={"flex-start"}>
                  <Flex alignItems={"center"}>
                    <Box
                      w="8px"
                      h="8px"
                      bg="blue.100"
                      borderRadius={"8px"}
                    ></Box>
                    <Text ml="8px" fontSize="14px" whiteSpace={"nowrap"}>
                      {t("MnGzdARgdxwWnBQGqasa")}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Box> */}
          <Box>
            <Button
              borderRadius={"20px"}
              w={["100%", "400px"]}
              h="44px"
              fontSize="14px"
              bg="blue.100"
              color="#fff"
              _hover={{ bg: "blue.80" }}
              mt="50px"
              onClick={startKyc}
            >
              {t("dSypETsKWXyxFowLHe")}
            </Button>
            <Flex justifyContent={"center"}>
              <Text
                fontSize="12px"
                color="blue.100"
                mt="20px"
                onClick={toHome}
                cursor={"pointer"}
              >
                {t("CRjZdhorIpDDNDAPls")}
              </Text>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

export default Success;
