import { Box, Flex, Text, Input, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { throttle } from "lodash";
import { useI18n } from "@/hooks/useI18n";
import { emailValid } from "../../utils/regexp";
import { BIXIN_FORGETPWD, BIXIN_REGISTER } from "@/helpers/constants/routeName";
import { postEmailLogin, verifyEmailExistedApi } from "./api";
import { updateLoginMsg } from "@/state/user/reducer";
import { useAppDispatch } from "@/state/hooks";
import { toastTip } from "@/components/ui/toast";
import useVPNCheck from "@/hooks/useVPNCheck";

const LoginEmail = (props) => {
  const { toNextStep, toBindPhone } = props;
  const { t } = useI18n();
  const [email, setEmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [password, setPassWord] = useState("");
  const [nextError, setNextError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const { checkVPN } = useVPNCheck();

  const ipCheck = async () => {
    const isOpenVPN = await checkVPN();
    if (isOpenVPN) {
      toastTip(t("npetoxnFYOYxRjXRN"), { type: "error" });
      setNextError(true);
    } else {
      setNextError(false);
    }
  };

  useEffect(() => {
    ipCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validEmail = (email) => {
    if (!email || !emailValid(email)) {
      setIsInvalidEmail(true);
      return false;
    }
    return true;
  };

  const emailBlur = (event) => {
    const email = event.target.value;
    setEmail(email);
    setIsInvalidEmail(false);
    if (!emailValid(email)) {
      setIsInvalidEmail(true);
    }
    dispatch(
      updateLoginMsg({
        loginMsg: {
          email,
        },
      })
    );
  };

  const passwordChange = (event) => {
    const value = event.target.value;
    if (value) {
      setPassWord(value);
    }
  };

  const toResetPwd = async () => {
    if (validEmail(email)) {
      const res = await emailCheckApi(email);
      if (res) {
        navigator(BIXIN_FORGETPWD);
      }
    }
  };

  const emailCheckApi = async () => {
    const res = await verifyEmailExistedApi({ email: email });
    if (res.code === 0 && res?.data?.is_exist) {
      setIsInvalidEmail(false);
      return true;
    } else {
      toastTip(t("nDIaTJMAdTQRxUKJo"), { type: "error" });
      setIsInvalidEmail(true);
      return false;
    }
  };

  const submitLogin = throttle(async () => {
    if (!validEmail(email)) {
      return;
    }
    if (!password) {
      setPasswordError(true);
      return;
    }
    const canNext = await emailCheckApi(email);
    if (canNext) {
      const res = await postEmailLogin({
        email,
        login_password: password,
      });
      if (res.code === 0) {
        if (res?.data?.phone_code && res?.data?.phone_number) {
          toNextStep({
            phoneInfo: res?.data,
            emailInfo: email,
            password,
          });
        } else {
          toBindPhone({
            phoneInfo: res?.data,
            emailInfo: email,
            password,
          });
        }
      }
    }
  }, 5000);

  const toSignUp = () => {
    navigator(BIXIN_REGISTER);
  };
  return (
    <div>
      <Flex justifyContent={"center"} mt={["100px", "200px"]}>
        <Box w={["80%", "400px"]}>
          <Text fontSize="32px" fontWeight={"600"} color="t.100">
            {t("rtVWmSuAATyCaNgkRNx")}
          </Text>
          {/* 账号 */}
          <Text fontSize="14px" color="t.100" mt="50px">
            {t("TVTktEvtpSXRbnxIB")}
          </Text>
          <Flex mt="5px">
            <Input
              h="44px"
              borderRadius={"12px"}
              placeholder={t("leXQdnNvhrYWetVTXjE")}
              fontSize="12px"
              type="email"
              isInvalid={isInvalidEmail}
              bg={isInvalidEmail ? "red.50" : "none"}
              errorBorderColor="red.100"
              _placeholder={{ color: "t.50" }}
              // onChange={emailChange}
              onBlur={emailBlur}
            />
          </Flex>
          {/* 密码 */}
          <Box mt="20px">
            <Flex justifyContent={"space-between"}>
              <Text fontSize="14px" color="t.100">
                {t("txfROteTwdaWdTnlkif")}
              </Text>
              <Text
                fontSize="14px"
                color="blue.100"
                cursor={"pointer"}
                onClick={toResetPwd}
              >
                {t("iDYPzXzvGbnKWCRr")}
              </Text>
            </Flex>
            <Box mt="5px">
              <Input
                h="44px"
                borderRadius={"12px"}
                type="password"
                onChange={passwordChange}
                isInvalid={passwordError}
                bg={passwordError ? "red.50" : "none"}
                errorBorderColor="red.100"
                _placeholder={{ color: "t.50" }}
                placeholder={t("txfROteTwdaWdTnlkif")}
                fontSize="12px"
              />
            </Box>
          </Box>

          <Box>
            {!nextError ? (
              <Button
                borderRadius={"20px"}
                w={["100%", "400px"]}
                h="44px"
                fontSize="14px"
                bg="blue.100"
                color="#fff"
                _hover={{ bg: "blue.80" }}
                mt="50px"
                onClick={submitLogin}
              >
                {t("tOaRBVUJHpnSgDBrGON")}
              </Button>
            ) : (
              <Button
                borderRadius={"20px"}
                w={["100%", "400px"]}
                _hover={{ bg: "t.50" }}
                disabled="true"
                h="44px"
                cursor={"not-allowed"}
                fontSize="14px"
                bg="t.50"
                color="#fff"
                mt="50px"
              >
                {t("tOaRBVUJHpnSgDBrGON")}
              </Button>
            )}
          </Box>
          <Flex
            justifyContent={"center"}
            mt="30px"
            alignItems={"center"}
            fontSize="12px"
          >
            <Text color="t.100">{t("iizCYlpFHwQBzRyqLM")}</Text>
            <Text color="blue.100" cursor={"pointer"} onClick={toSignUp}>
              {t("FbztGftxEQrszJntb")}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
};

export default LoginEmail;
