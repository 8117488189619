import request from "@/hooks/useRequest";

// 地区list
export const getAreaListApi = (params) => {
  return request("/web-wallet/api/v1/kyc/area-list", { params });
};

// s3 put url
export const getS3PutUrlApi = (params) => {
  return request("/web-wallet/api/v1/s3/get-private-put-url", { params });
};

export const getUserInfoApi = () => {
  return request("/web-wallet/api/v1/account/user/info");
};
