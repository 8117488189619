import { useState, useEffect } from "react";
import { getQuestionnaire, postQuestionAnswer } from "./api";
import { cloneDeep } from "lodash";
import { useI18n } from "@/hooks/useI18n";
import { useAppSelector } from "@/state/hooks";
import { getUserInfo, getProcessInfo } from "./api";
import { useDispatch } from "react-redux";
import { updateKycInfo } from "@/state/kyc";
import { updateUserInfo } from "@/state/user/reducer";

const useCommonHooks = () => {
  const dispatch = useDispatch();

  const fetchGetProcessInfo = async () => {
    const res = await getProcessInfo({});
    if (res.code === 0) {
      dispatch(updateKycInfo(res.data.process));
    }
  };

  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      dispatch(updateUserInfo({ userInfo: res.data }));
    }
  };

  useEffect(() => {
    fetchGetProcessInfo();
    getUserInfoFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const useInvestmentRiskHooks = () => {
  const [isDisabled, setDisabled] = useState(true);
  const [dataSource, setDataSource] = useState({});
  const [viewStatus, setViewStatus] = useState("INIT"); // INIT RESULT
  const [resultInfo, setResultInfo] = useState({
    // is_pass: true,
    // right_count: 2,
    // total_count: 10,
  });
  const { t } = useI18n();

  const levelMaps = {
    1: t("JkoaUyCjjTHSNUEy"),
    2: t("LtnRgezlrxMzWvZBxRJ"),
    3: t("jXvqhJJMhyxRdxCxv"),
    4: t("YZKOONeeIQMFqysxrZ"),
    5: t("nmicLlJCgyNNjjzKJUt"),
  };

  const fetchQues = async () => {
    const res = await getQuestionnaire("RISK");
    if (res.code === 0) {
      const questions = res.data.questions.map((d) => {
        return {
          ...d,
          options: d.options.map((dd) => {
            return {
              ...dd,
              checked: false,
            };
          }),
        };
      });
      res.data.questions = questions;
      setDataSource(res.data);
    }
  };

  useEffect(() => {
    fetchQues();
  }, []);

  useEffect(() => {
    if (dataSource) {
      const questions = dataSource.questions;
      try {
        questions.forEach((d, i) => {
          // 每道题至少有一个true
          if (!d.options.some((d) => d.checked)) {
            throw new Error("break");
          }
        });
        setDisabled(false);
      } catch (e) {
        setDisabled(true);
      }
    }
  }, [dataSource]);

  const handleSelect = (i, ii) => {
    const cloneQues = dataSource.questions;
    const item = cloneQues[i];
    item.options.forEach((ques, index) => {
      if (index === ii) {
        ques.checked = !ques.checked;
      } else {
        ques.checked = false;
      }
    });
    dataSource.questions = [...cloneQues];
    setDataSource(cloneDeep(dataSource));
  };

  const onSubmit = async () => {
    const answers = [];
    dataSource.questions.forEach((d) => {
      const options = d.options;
      options.forEach((dd) => {
        if (dd.checked) {
          answers.push({
            option_id: dd.id,
            question_id: d.id,
          });
        }
      });
    });

    const data = {
      questionnaire_id: dataSource.id,
      answers,
    };
    const res = await postQuestionAnswer(data);
    if (res.code === 0) {
      setViewStatus("RESULT");
      setResultInfo(res.data);
    }
  };

  const reTry = () => {
    fetchQues();
    setResultInfo({});
    setViewStatus("INIT");
    setDisabled(true);
  };

  return {
    isDisabled,
    dataSource,
    onSubmit,
    handleSelect,
    levelMaps,
    viewStatus,
    resultInfo,
    reTry,
  };
};

const useVitualAssetsTestHooks = () => {
  const [isDisabled, setDisabled] = useState(true);
  const [dataSource, setDataSource] = useState({});
  const [showTipModal, setShowTipModal] = useState(false);
  const [viewStatus, setViewStatus] = useState("INIT"); // INIT RESULT
  const [resultInfo, setResultInfo] = useState({});
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);

  useEffect(() => {
    if (kycInfo?.length > 0) {
      const currProcess = kycInfo[5];
      if (currProcess.status === "APPROVED") {
        setViewStatus("RESULT");
        if (currProcess.reason.includes("/")) {
          const arr = currProcess.reason.split("/");
          setResultInfo({
            is_pass: true,
            right_count: arr[0],
            total_question: arr[1],
          });
        } else {
          setResultInfo({
            is_pass: false,
            right_count: "",
            total_question: "",
          });
        }
      } else {
        setViewStatus("INIT");
      }
    }
  }, [kycInfo]);

  useEffect(() => {
    if (dataSource) {
      const questions = dataSource.questions;
      try {
        questions.forEach((d, i) => {
          // 每道题至少有一个true
          if (!d.options.some((d) => d.checked)) {
            throw new Error("break");
          }
        });
        setDisabled(false);
      } catch (e) {
        setDisabled(true);
      }
    }
  }, [dataSource]);

  const fetchQues = async () => {
    const res = await getQuestionnaire("ASSET_KNOWLEDGE");
    if (res.code === 0) {
      const questions = res.data.questions.map((d) => {
        return {
          ...d,
          options: d.options.map((dd) => {
            return {
              ...dd,
              checked: false,
            };
          }),
        };
      });
      res.data.questions = questions;
      setDataSource(res.data);
    }
  };

  useEffect(() => {
    fetchQues();
  }, []);

  const handleSelect = (i, ii) => {
    const cloneQues = dataSource.questions;
    const item = cloneQues[i];
    item.options.forEach((ques, index) => {
      if (index === ii) {
        ques.checked = !ques.checked;
      } else {
        ques.checked = false;
      }
    });
    dataSource.questions = [...cloneQues];
    setDataSource(cloneDeep(dataSource));
  };

  const onSubmit = async () => {
    const answers = [];
    dataSource.questions.forEach((d) => {
      const options = d.options;
      options.forEach((dd) => {
        if (dd.checked) {
          answers.push({
            option_id: dd.id,
            question_id: d.id,
          });
        }
      });
    });

    const data = {
      questionnaire_id: dataSource.id,
      answers,
    };
    const res = await postQuestionAnswer(data);
    if (res.code === 0) {
      setResultInfo(res.data);
      setViewStatus("RESULT");
    }
  };

  const reTry = () => {
    fetchQues();
    setResultInfo({});
    setViewStatus("INIT");
    setDisabled(true);
  };

  return {
    dataSource,
    isDisabled,
    handleSelect,
    onSubmit,
    resultInfo,
    setResultInfo,
    viewStatus,
    setViewStatus,
    setShowTipModal,
    showTipModal,
    reTry,
  };
};

export { useInvestmentRiskHooks, useVitualAssetsTestHooks, useCommonHooks };
