import { Box, Text } from "@chakra-ui/react";

const ZhDoc = () => {
  return (
    <Box>
      <Text fontSize="28px" fontWeight={"bold"} mt="30px">
        隐私条款
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        最后更新时间：2024年1月5日
      </Text>
      <Text mt="30px">
        以下条款和条件构成由NewBX Limited（以下简称“公司”）运营的www.bixin.com
        （连同其iOS、Android或其他系统的应用程序，以下简称“网站”或“币信”）的隐私条款。除非另有说明，本文中提及的公司还应包括公司的全资子公司
        NewBX Treasures
        Limited。在您成为币信用户（以下简称“您”或“用户”）之前，请仔细阅读本隐私条款并确认同意。公司非常重视用户的私人信息，然而，作为正常服务运营的一部分，公司可能会收集、使用和（在某些情况下）向第三方披露您的个人信息。本隐私条款作为公司用户协议的一部分，将在您注册成为币信用户后立即生效，对您和公司均具有约束力。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        A. 用户身份限制
      </Text>
      <Text mt={"10px"}>
        任何具有完全行为能力的个人或实体（“人”）都有资格在币信注册并使用本公司提供的服务。任何代表某个实体注册、登录和使用本网站的用户均声明并保证他/她代表该实体行事并获得充分授权。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        B.信息
      </Text>
      <Text mt={"10px"}>
        公司收集您的个人信息的主要目的是为用户提供流畅、有效和量身定制的服务体验。本公司仅收集其认为为实现前述目的所必需的您的信息。您充分理解并同意，本公司有权通过包括但不限于以下方式收集您的个人信息：除您自愿提供的信息外，从公共或私人来源额外收集信息、通过电话或视频等方式验证信息真实性、以便更好地了解您，为您提供量身定制的服务，解决纠纷，保障您在网站的投资安全。
      </Text>
      <Text mt={"10px"}>公司可能收集您的个人信息，包括但不限于：</Text>
      <Text mt={"10px"}>
        i.
        您在注册过程中提供的个人信息，包括但不限于护照、身份证、驾照以及照片、用户名、密码等信息；
      </Text>
      <Text mt={"10px"}>
        ii. 您提供或披露的个人信息通过本公司向第三方提供；
      </Text>
      <Text mt={"10px"}>
        iii.您登录和使用网站的时间、时长、用户记录、系统日志和行为数据；
      </Text>
      <Text mt={"10px"}>
        iv.您用于登录和使用本网站的计算机和/或移动设备的品牌、型号、IP地址和软件版本；
      </Text>{" "}
      <Text mt={"10px"}>
        v.
        本网站通过cookies或其他方式自动收集的其他个人信息或隐私。您可能更改浏览器设置拒绝cookies，这可能导致本公司服务体验不完整；
      </Text>
      <Text mt={"10px"}>
        vi.当您通过具有定位功能的移动设备登录或使用本公司服务时，本公司可能会通过GPS或WiFi收集您的地理位置。您可以在移动设备的设置中关闭该功能，但此后您的用户体验可能会受到影响；
      </Text>
      <Text mt={"10px"}>
        vii.当您通过第三方平台上的链接登录本网站时，本公司可能会收集您存储在该第三方平台上的个人信息；
      </Text>
      <Text mt={"10px"}>
        viii. 您在使用网站功能时向本公司提供或披露的其他信息。
      </Text>
      <Text mt={"10px"}>
        本公司将根据您在网站上的行为自动追踪上述个人信息。公司将使用上述信息对用户的人口统计、兴趣和行为进行内部研究，以更好地了解您并为您和其他用户提供更好的服务。
      </Text>
      <Text mt={"10px"}>
        如果您向本公司提供个人通讯信息（例如短信、电子邮件或信件），或者其他用户或第三方向本公司发送与您在网站上的活动或登录有关的通讯信息，本公司有权收集您的专属个人资料中的此类数据。
      </Text>
      <Text mt={"10px"}>
        用户可以在网站的“个人信息”部分查阅和修改本公司收集的个人资料。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        C. 您信息的使用
      </Text>
      <Text mt="10px">
        公司非常重视用户的隐私信息。公司将采用安全技术和程序来存储和保护用户的私人信息，以防止未经授权的访问、使用、复制或披露。本公司不会出于任何营利目的而恶意披露您的个人信息。尽管如此，您同意并授权本公司在以下范围内使用或披露您的个人信息：
      </Text>
      <Text mt="10px">
        i.
        本公司有权使用您的个人信息来解决或调解纠纷，以保证您的投资安全和履行本公司的用户协议。公司可能会调查多个用户以识别问题或解决争议，特别是它可能会调查您的信息以识别使用不同用户名或别名的用户。为了保护公司免受欺诈、非法或其他犯罪活动的侵害，公司有权通过手动或自动程序评估您的个人信息。
      </Text>
      <Text mt="10px">
        ii.在符合《个人资料（私隐）条例》（香港法例第486章）的规定下，本公司有权使用您的个人资料以改善其营销及推广、分析其用途、完善其内容及推广形式，以及改善其营销及推广活动。
        其内容、设计和服务更好地满足用户。
      </Text>
      <Text mt="10px">
        iii.公司有权使用您的信息与您联系并（在某些情况下）提供您可能感兴趣的信息，例如服务产品、管理通知和有关您使用网站的新闻通讯。您接受《用户协议》和本《隐私条款》即视为您明确同意接收该等信息
      </Text>
      <Text mt="10px">
        iv.当您点击网站上显示的第三方平台或服务提供商的链接时，本公司有权根据以下范围向第三方平台或服务提供商提供您的个人信息，以方便该第三方提供服务和目的，此类第三方包括：
      </Text>
      <Text mt="10px">
        a.{" "}
        <a href="https://www.jumio.com" style={{ color: "#3370ff" }}>
          Jumio
        </a>{" "}
        人脸识别服务：出于KYC（了解您的用户）和AML（反洗钱）目的，本网站的客户需要进行实名认证，因此信息使用本服务商提供的验证服务，即通过网络验证、简单人像比对、人、卡、公安三重人像比对、身份证OCR、银行卡OCR、银行卡认证、活体检测及人脸质量分析，用户身份信息用于确认用户身份和信息的真实性、有效性，以保障我们的业务和用户交易的安全。本服务涉及用户个人信息的披露包括：用户个人身份证信息、护照信息、证件信息、驾驶证信息、银行卡信息、手机号码信息、个人肖像信息。
      </Text>
      <Text mt="10px">
        b.{" "}
        <a href="https://complyadvantage.com" style={{ color: "#3370ff" }}>
          Comply Advantage
        </a>{" "}
        姓名筛查服务：为防范和控制洗钱和恐怖融资风险，公司将姓名筛查工具外包给ComplyAdvantage。公司的平台通过
        API 连接到
        ComplyAdvantage，可实现自动名称筛查。该服务涉及用户个人身份证信息、护照信息、证件信息的披露。
      </Text>
      <Text mt="10px">
        c.{" "}
        <a href="https://www.yunpian.com" style={{ color: "#3370ff" }}>
          云片
        </a>{" "}
        短信通知服务：为了通知用户，需要向用户的手机号码发送短信通知。因此，我们利用服务提供商提供的短信通知服务，即通过向用户的手机号码发送短信的方式，帮助用户顺利完成在本网站的注册、及时了解交易进展、接收服务信息等。服务涉及用户个人信息的披露，包括：用户的手机号码信息。
      </Text>
      <Text mt="10px">
        d.{" "}
        <a href="https://www.twilio.com" style={{ color: "#3370ff" }}>
          Twilio
        </a>{" "}
        短信通知服务：为了通知用户，需要向用户的手机号码发送短信通知。因此，我们利用服务提供商提供的短信通知服务，即通过向用户的手机号码发送短信的方式，帮助用户顺利完成在本网站的注册、及时了解交易进展、接收服务信息等。服务涉及用户个人信息的披露，包括：用户的手机号码信息。
      </Text>
      <Text mt="10px">
        v.
        您可以授权本公司协助您修改您在网站上填写的个人信息。如果您违反《用户协议》、《隐私条款》或本公司任何其他规则或适用法律，本公司有权在通过以下方式通知后从本公司数据库中删除您的个人信息、关闭您的用户帐户或限制您对本网站的使用电子邮件、电话或短信。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        D. 披露您的信息
      </Text>
      <Text mt="10px">
        公司采用标准行业惯例来保护您的个人信息。但受技术限制和不可抗力的影响，本公司可能无法确保您的个人通讯或其他信息不通过本隐私条款未提及的方式被披露。本公司对此类意外披露不承担任何责任。
      </Text>
      <Text mt="10px">
        本公司有权根据适用法律法规的要求、本网站的风险控制政策、及相关协议。如果您未能履行《用户协议》、《隐私条款》或本公司其他规则、法律规定的义务，本公司有权依法披露您的个人信息并自行作出决定。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        E. 其他用户信息的使用
      </Text>
      <Text mt="10px">
        您不得要求本公司提供其他用户的个人信息，除非您已向法院或有权机关提出申请，且法院或有权机关依法要求本公司提供相关司法协助。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        F.电子邮件
      </Text>
      <Text mt="10px">
        当您使用本公司的服务时，本公司可能会使用您的个人信息向您的设备发送电子邮件、新闻或推送通知。如果您不打算接收此类信息，您可以根据公司的指示在设备上取消订阅。
      </Text>
      <Text mt="10px">
        本公司在必要时（例如因系统维护而暂停某些服务），可以发布有关该服务的公告。您可能无法取消此类与服务有关的非促销性质的公告。
      </Text>
      <Text mt="10px">
        您不得利用本公司的服务或其他电子邮件转发服务发送垃圾邮件或其他可能影响本公司系统运行或违反本公司《用户协议》或《隐私条款》的内容。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        G.密码的安全性
      </Text>
      <Text mt="10px">
        您应对与您使用用户名和密码有关的所有行为负责。因此，本公司建议您不要向任何第三方透露您在网站上的用户名或密码。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        H. 同意和授权
      </Text>
      <Text mt="10px">
        您接受《用户协议》和《隐私条款》或使用本软件及服务，即视为明确同意或授权本公司按照相关国家或司法辖区适用于个人信息和隐私保护（无论表述如何）的法律法规处理用户的个人信息和隐私数据，包括但不限于：收集、存储、使用、处理、传输、提供、披露、删除等。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        I. 规则修改
      </Text>
      <Text mt="10px">
        公司可能会根据您的建议和公司的需要，不时修改《隐私条款》，以准确反映公司的信息收集和披露规则。本隐私条款的任何修订均应在其发布后或修订中指定的日期自动生效。您继续使用本公司服务即视为您明确同意修改后的隐私条款。
      </Text>
      <Text fontSize="16px" fontWeight={"bold"} mt="10px">
        J. 适用法律；争议解决
      </Text>
      <Text mt="10px">
        本隐私条款的解释以及因本隐私条款而产生的与本网站有关的任何争议均适用香港特别行政区法律。因本隐私条款引起的或与之相关的任何争议、冲突或索赔均应由香港国际仲裁中心（“HKIAC”）根据当时有效的
        HKIAC 规则通过仲裁解决。
      </Text>
    </Box>
  );
};

export default ZhDoc;
