import request from "@/hooks/useRequest";
import { WHITELIST_VASP_LIST } from "@/helpers/constants/requestApi";

export const getWhiteListVaspList = () => {
  return request(WHITELIST_VASP_LIST, { params: {} });
};

// tx地址验证
export const verifyTxCreate = (body) => {
  return request("/web-wallet/api/v1/whitelist/hosted/tx/create", { body });
};

// s3 put url
export const getS3PutUrl = (params) => {
  return request("/web-wallet/api/v1/s3/get-private-put-url", { params });
};
