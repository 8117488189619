export const WHITELIST_STEPS = { ADD: "add", VERIFY: "verify" };
export const WHITELIST_CATEGORY = { HOSTED: "HOSTED", UNHOSTED: "UNHOSTED" };
export const WHITELIST_AUTH_TYPE = { SIGN: "sign", TRANSFER: "transfer" };
export const WHITELIST_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  REVIEW: "REVIEW",
};

export const ACCOUNT_TYPE = {
  PERSONAL: "PERSONAL",
  COMPANY: "COMPANY",
};
