import { Flex, Text, Image, Button } from "@chakra-ui/react";
import "./pwd.scss";
import { useI18n } from "@/hooks/useI18n";
// import { useMemo, useState } from "react";

const StatusCard = ({ onClose, onOk, status, onRetry }) => {
  const { t } = useI18n();
  // const [pwd, setPwd] = useState("");

  // const canSubmit = useMemo(() => {
  //   if (pwd) return true;
  //   return false;
  // }, [pwd]);

  return (
    <div className="verify-status-mask-xyz">
      <Flex
        flexDirection={"column"}
        className="verify-status-modal-xyz"
        alignItems={"center"}
      >
        {status === "wait_sign" && (
          <>
            {/* // 等待签名 */}
            <Image
              className="verify-status-modal-xyz-loading"
              _hover={{ cursor: "pointer" }}
              height={"25px"}
              width={"25px"}
              src="../../wwwsite/images/whitelist/loading.svg"
              onClick={() => {
                onClose && onClose();
              }}
            />
            <Text
              fontSize={"16px"}
              color={"#010313"}
              mt={"10px"}
              fontWeight={600}
            >
              {t("HjfxCIPqXHVRBXeofOe")}
            </Text>
          </>
        )}

        {status === "sign_error" && (
          <>
            {/* // 签名失败 */}
            <Image
              height={"40px"}
              width={"40px"}
              src="../../wwwsite/images/warning.svg"
              onClick={() => {
                onClose && onClose();
              }}
            />
            <Text
              fontSize={"24px"}
              color={"#010313"}
              fontWeight={600}
              mt={"10px"}
            >
              {t("yKqxJEvOxokRNAPXYDZC")}
            </Text>

            <Flex justifyContent={"center"} width={"100%"} mt={"40px"}>
              <Button
                mr="10px"
                fontSize="14px"
                padding="0 30px"
                w={["100%", "100px"]}
                bg="rgba(32, 26, 85, 0.05)"
                color="t.100"
                fontWeight={"400"}
                borderRadius={"30px"}
                h="44px"
                cursor={"pointer"}
                onClick={() => {
                  onClose && onClose();
                }}
              >
                {t("UNForxvjDLCZdGrmWkX")}
              </Button>
              <Button
                fontSize="14px"
                padding="0 30px"
                w={["100%", "200px"]}
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                h="44px"
                cursor={"pointer"}
                bg={"blue.100"}
                _hover={{ bg: "blue.80" }}
                onClick={() => {
                  onRetry && onRetry();
                }}
              >
                {t("leIEPguvvNFtRsXGFI")}
              </Button>
            </Flex>
          </>
        )}

        {status === "sign_success" && (
          <>
            {/* // 签名成功 */}
            <Image
              height={"40px"}
              width={"40px"}
              src="../../wwwsite/images/blue_success.svg"
            />
            <Text
              fontSize={"24px"}
              color={"#010313"}
              fontWeight={600}
              mt={"20px"}
            >
              {t("MgdMcyuZEloUdpqcv")}
            </Text>

            <Flex justifyContent={"center"} width={"100%"} mt={"30px"}>
              <Button
                mr="10px"
                fontSize="14px"
                padding="0 30px"
                w={["100%", "100px"]}
                bg="rgba(32, 26, 85, 0.05)"
                color="t.100"
                fontWeight={"400"}
                borderRadius={"30px"}
                h="44px"
                cursor={"pointer"}
                onClick={() => {
                  onClose && onClose();
                  // setShowDeleteAlert(false);
                }}
              >
                {t("UNForxvjDLCZdGrmWkX")}
              </Button>
              <Button
                fontSize="14px"
                padding="0 30px"
                w={["100%", "200px"]}
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                h="44px"
                cursor={"pointer"}
                bg={"blue.100"}
                _hover={{ bg: "blue.80" }}
                onClick={() => {
                  onOk && onOk();
                  // deleteWhiteList();
                }}
              >
                {t("YChcfaxDaLscdSPiRQz")}
              </Button>
            </Flex>
          </>
        )}

        {/* <Flex justifyContent={"space-between"}>
          <Image
            _hover={{ cursor: "pointer" }}
            boxSize="24px"
            src="../../wwwsite/images/close.svg"
            onClick={() => {
              onClose && onClose();
            }}
          />
        </Flex> */}
        {/* <Box mt={"30px"}>
          <Text fontSize={"14px"} color={"#010313"}>
            {t("irXsKkZagNKDlvCyhKYK")}
          </Text>
          <Input
            value={pwd}
            type="password"
            color={"#7B7C8F"}
            fontSize={"20px"}
            borderRadius={"12px"}
            padding={"0px 20px"}
            mt={"10px"}
            borderColor={"#E3E4F1"}
            onChange={(e) => {
              setPwd(e.target.value);
            }}
          />
        </Box>
        <Button
          mt={"40px"}
          h="44px"
          color="#fff"
          fontSize="14px"
          borderRadius={"30px"}
          bg={canSubmit ? "blue.100" : "t.50"}
          _hover={{ bg: canSubmit ? "blue.80" : "t.50" }}
          onClick={() => {
            onOk && onOk({ pwd });
          }}
        >
          {t("AQrCocFqoKoYieUnT")}
        </Button> */}
      </Flex>
    </div>
  );
};

export default StatusCard;
