import { useEffect, useMemo, useState } from "react";
import { useI18n } from "@/hooks/useI18n";
import {
  WHITELIST_AUTH_TYPE,
  WHITELIST_CATEGORY,
  WHITELIST_STEPS,
} from "../consts";

const useAdd = () => {
  const [addressType, setAddressType] = useState(WHITELIST_CATEGORY.UNHOSTED); // 地址类型 钱包/交易所
  const [addressName, setAddressName] = useState(); // 地址名称
  const [currency, setCurrency] = useState(); // 代币
  const [network, setNetwork] = useState(""); // 代币网络
  const [address, setAddress] = useState(); // 钱包地址
  const [whitelistUuid, setWhitelistUuid] = useState();
  const [whitelistVaspDid, setWhitelistVaspDid] = useState("");
  const [usedKeyList, setUsedKeyList] = useState([1, 2]); // 用途
  const { t } = useI18n();
  const [step, setStep] = useState(WHITELIST_STEPS.ADD);
  const [verifyType, setVerifyType] = useState(WHITELIST_AUTH_TYPE.SIGN);

  useEffect(() => {
    if (addressType === WHITELIST_CATEGORY.UNHOSTED) {
      setUsedKeyList([1, 2]);
    } else {
      setUsedKeyList([2]);
    }
  }, [addressType]);

  const showUsedError = useMemo(() => {
    if (
      usedKeyList.length === 0 &&
      addressType === WHITELIST_CATEGORY.UNHOSTED
    ) {
      return true;
    }
    return false;
  }, [addressType, usedKeyList]);

  const currencyOptions = useMemo(() => {
    return [
      {
        iconUrl: "https://prod.bixin.info/currency/icon/BTC_1.png",
        label: "BTC",
        value: "BTC",
      },
      {
        iconUrl: "https://prod.bixin.info/currency/icon/ETH_1.png",
        label: "ETH",
        value: "ETH",
      },
      {
        iconUrl: "https://prod.bixin.info/currency/icon/USDC_1.png",
        label: "USDC",
        value: "USDC",
      },
    ];
  }, []);

  const netWorkOptions = useMemo(() => {
    if (!currency) return [];
    return currency === "BTC"
      ? [
          {
            label: "BTC",
            value: "BTC",
          },
        ]
      : [
          {
            label: "ERC20",
            value: "ETH",
          },
        ];
  }, [currency]);

  const addressTypeOptions = useMemo(() => {
    return [
      {
        text: t("mJjedBCPidtXUCYHus"),
        id: WHITELIST_CATEGORY.UNHOSTED,
      },
      {
        text: t("IIbmROzHEYCycsCyk"),
        id: WHITELIST_CATEGORY.HOSTED,
      },
    ];
  }, [t]);

  const verifyMethods = useMemo(() => {
    return [
      {
        text: t("LESgEuOUJYjZQYLUlO"),
        id: WHITELIST_AUTH_TYPE.SIGN,
      },
      {
        text: t("hsgIRJDqXwkPnsmgktga"),
        id: WHITELIST_AUTH_TYPE.TRANSFER,
      },
    ];
  }, [t]);

  const canToNext = useMemo(() => {
    if (!addressName || !currency || !network || !address || showUsedError)
      return false;
    return true;
  }, [addressName, currency, network, address, showUsedError]);

  const memoWhiteUsed = useMemo(() => {
    if (addressType === WHITELIST_CATEGORY.UNHOSTED) {
      return [
        { value: 1, text: t("gTYMvovCwafJTrjdlKg") },
        { value: 2, text: t("PczYwMSUlifZlPdkNj") },
      ];
    }
    // cex 只有提现
    return [{ value: 2, text: t("PczYwMSUlifZlPdkNj") }];
  }, [t, addressType]);

  const handleWhiteUsed = (value) => {
    if (addressType === WHITELIST_CATEGORY.UNHOSTED) {
      const list = [...usedKeyList];
      const findIndex = list.indexOf(value);

      if (findIndex >= 0) {
        list.splice(findIndex, 1);
        setUsedKeyList([...list]);
      } else {
        list.push(value);
        setUsedKeyList([...list]);
      }
    }
  };

  // useEffect(() => {
  //   setAddressName('')
  //   setCurrency('')
  //   setNetwork('')
  //   setAddress('')
  // }, [addressType])

  return {
    addressType,
    setAddressType,
    addressName,
    setAddressName,
    currency,
    setCurrency,
    network,
    setNetwork,
    address,
    setAddress,
    currencyOptions,
    netWorkOptions,
    addressTypeOptions,
    canToNext,
    setStep,
    step,
    verifyMethods,
    verifyType,
    setVerifyType,
    whitelistUuid,
    setWhitelistUuid,
    whitelistVaspDid,
    setWhitelistVaspDid,
    memoWhiteUsed,
    handleWhiteUsed,
    usedKeyList,
    showUsedError,
    setUsedKeyList,
  };
};

export { useAdd };
