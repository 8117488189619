import { Box, Text, Flex } from "@chakra-ui/react";
import { BXInput, BXButton } from "@/styles";
import { useI18n } from "@/hooks/useI18n";

const BXUpload = ({
  label,
  error,
  errorText,
  handleUploadChange,
  fileName,
  placeholder,
  disabled = false,
  style = {},
}) => {
  const { t } = useI18n();
  return (
    <Box position="relative" style={style}>
      <Text fontWeight={500} fontSize={"14px"}>
        {label}
      </Text>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <BXInput
          disabled={disabled}
          w="80px"
          type="file"
          opacity={"0"}
          position={"absolute"}
          right="0"
          zIndex={"2"}
          cursor="pointer"
          fontSize="0"
          bg={"#fff"}
          border={"none"}
          _disabled={{ display: "none" }}
          onChange={(e) => {
            if (!disabled) {
              handleUploadChange(e);
            }
          }}
        />
        <BXInput
          disabled={disabled}
          bg={"#fff"}
          w={["220px", "310px"]}
          value={fileName}
          onChange={() => {}}
          placeholder={placeholder}
        />
        <BXButton
          w="80px"
          h="30px"
          ml={"10px"}
          mt={"10px"}
          className={disabled ? "disabled" : ""}
        >
          {t("NqgOQEXQaHsramCnbX")}
        </BXButton>
      </Flex>
      <Text
        color={error ? "red.100" : "rgba(123, 124, 143, 1)"}
        fontSize={"10px"}
        mt={"5px"}
      >
        {errorText || t("ZAhuzRDlLUTXhKrhhx")}
      </Text>
    </Box>
  );
};

export default BXUpload;
