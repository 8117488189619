import { useState, useEffect } from "react";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  Image,
} from "@chakra-ui/react";

import { useI18n } from "@/hooks/useI18n";

const DropDownBox = (props) => {
  const { t } = useI18n();

  const {
    // 图标大小
    iconSize,
    // 展示图标
    menuShowIcon,
    // 不展示边框
    noShowBorder,
    // 默认文案
    palaceholder,
    // 筛选列表
    list,
    // 默认值
    initValue,
    // 筛选列表对应的key
    filterKey,
    // 需要翻译
    nameNeedT,
    // 选择事件
    onselectedInfo,
  } = props;

  // 选中的选项
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (initValue && list.length > 0) {
      initNameHandle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue, list]);
  const initNameHandle = () => {
    const initData = list.filter((item) => item.selected_key === initValue);
    setSelected(initData[0]);
  };

  const selectItems = (item) => {
    setSelected(item);
    if (!onselectedInfo) return;
    if (filterKey) {
      onselectedInfo(item[filterKey]);
    } else {
      onselectedInfo(item);
    }
  };

  const IconDom = (isOpen) => {
    return (
      <Image
        borderRadius="full"
        boxSize={iconSize || "24px"}
        src={`../wwwsite/images/bill/${
          isOpen ? "up_arrow" : "down_arrow1"
        }.svg`}
        mr="10px"
      />
    );
  };
  const translateDom = (getValue) => {
    return nameNeedT ? t(getValue) : getValue;
  };
  return (
    <>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              rightIcon={IconDom(isOpen)}
              backgroundColor="#FFFFFF"
              border={`${noShowBorder ? "0" : "1"}px solid #E3E4F1`}
              borderRadius={"12px"}
              p="0px"
              w="100%"
              h="100%"
              isActive={isOpen}
            >
              <Text
                textAlign={"left"}
                pl="10px"
                fontSize={"14px"}
                display={"flex"}
                alignItems={"center"}
              >
                {menuShowIcon && (
                  <Image
                    src={selected?.icon}
                    alt="header icon"
                    borderRadius="full"
                    boxSize="20px"
                    mr="10px"
                    fallbackSrc="../wwwsite/images/transfer/header_icon.svg"
                  />
                )}
                {translateDom(selected?.name) || palaceholder}
              </Text>
            </MenuButton>
            <MenuList p="4px" backgroundColor="#FFFFFF" borderRadius={"12px"}>
              {list.length > 0 &&
                list.map((item, index) => {
                  return (
                    <MenuItem
                      key={item.selected_key}
                      onClick={() => {
                        selectItems(item);
                      }}
                      _hover={{
                        backgroundColor: "rgba(32, 26, 85, 0.05)",
                        borderRadius: "10px",
                      }}
                      backgroundColor={
                        initValue === item.selected_key &&
                        "rgba(32, 26, 85, 0.05)"
                      }
                      borderRadius={initValue === item.selected_key && "10px"}
                      mt={index !== 0 && "5px"}
                    >
                      {menuShowIcon && (
                        <Image
                          src={item?.icon}
                          alt="header icon"
                          borderRadius="full"
                          boxSize="20px"
                          mr="10px"
                          fallbackSrc="../wwwsite/images/transfer/header_icon.svg"
                        />
                      )}
                      {translateDom(item.name)}
                    </MenuItem>
                  );
                })}
            </MenuList>
          </>
        )}
      </Menu>
    </>
  );
};

export default DropDownBox;
