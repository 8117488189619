export const POSTEMAILCHECK = "/web-wallet/api/v1/register/email/check"; //邮箱验证
export const POSTEMAILCODEGET = "/web-wallet/api/v1/email/code/get"; //发送邮箱
export const POSTEMAILCODEVALIDATION =
  "/web-wallet/api/v1/email/code/validation"; //邮箱验证
export const POSTUSERCREATE = "/web-wallet/api/v1/register/user/create"; //注册
export const POSTLOGIN = "/web-wallet/api/v1/login/user"; //登录
export const POSTSIGNOUT = "/web-wallet/api/v1/login/sign_out"; //退出登录
export const GETIPCHECK = "/web-wallet/api/v1/ip/check"; //ip检查
export const GETIP = "/web-wallet/api/v1/ip/get"; // 获取ip

export const GETASSETINFO = "/web-wallet/api/v1/asset/page"; // 获取账户信息
export const GETCURRENCYLIST = "/web-wallet/api/v1/currency/list"; // 获取代币信息列表
export const GETRECEIPTLAYOUT = "/web-wallet/api/v1/transfer/receipt-layout"; // 获取收款信息
export const GETWITHDRAWLAYOUT = "/web-wallet/api/v1/transfer/withdraw-layout"; // 获取转账信息
export const GETTRANSFERFEE = "/web-wallet/api/v1/transfer/fee"; // 获取手续费
export const GETINTERNALLAYOUT = "/web-wallet/api/v1/transfer/internal-layout"; // 获取内部转账信息
export const POSTTRANSFERCREATE = "/web-wallet/api/v1/transfer/create"; // 创建转账单
export const GETFIATWITHDRAWLAYOUT =
  "/web-wallet/api/v1/transfer/fiat/withdraw-layout"; // 获取银行转账信息
export const POSTBANKTRANSFERCREATE = "/web-wallet/api/v1/transfer/fiat/create"; // 创建银行转账单
export const GETFIATDEPOSITLAYOUT =
  "/web-wallet/api/v1/transfer/fiat/deposit-layout"; // 创建银行转账单
export const GETFIATDEPOSITCREATE =
  "/web-wallet/api/v1/transfer/create-deposit-receipt"; // 创建银行转账单

export const POSTACCOUNTSEARCH = "/web-wallet/api/v1/account/search"; // 搜索账户信息

export const GETPAYINFO = "/pay/api/v1/order-info"; // 获取支付信息
export const GETPAYCONFIRM = "/pay/api/v1/confirm"; // 确认支付

export const GETAREALIST = "/web-wallet/api/v1/kyc/area-list"; //获取国籍/地区列表
export const POSTUPLOAD = "/web-wallet/api/v1/kyc/file/upload"; //上传kyc文件，地址证明文件，提升额度证明文件
export const POSTIDENTIFICATION = "/web-wallet/api/v1/kyc/identification"; //kyc身份证明信息提交
export const POSTSENDCODE = "/web-wallet/api/v1/phone/send-code"; //kyc手机号-发送验证码
export const POSTVERIFY = "/web-wallet/api/v1/kyc/phone/verify"; //kyc手机号-验证（绑定）
export const GETPROCESSINFO = "/web-wallet/api/v1/kyc/process-info"; //获取kyc进程信息
export const POSTPROOFOFADDRESS = "/web-wallet/api/v1/kyc/proof-of-address"; //kyc地址证明信息提交
export const GETSUMMARYINFO = "/web-wallet/api/v1/kyc/summary-info"; //获取用户kyc概要信息
export const POSTINCREASINGLIMIT =
  "/web-wallet/api/v1/kyc/transaction/increasing-limit"; //申请交易额度提升
export const GETINCREASINGLIMITPAGE =
  "/web-wallet/api/v1/kyc/transaction/increasing-limit/page"; //申请交易额度提升

export const POSTEMAILVERIFY = "/web-wallet/api/v1/account/email/verify"; //验证邮箱code码
export const POSTGABIND = "/web-wallet/api/v1/account/google_auth/bind"; //ga绑定
export const POSTGACLOSE = "/web-wallet/api/v1/account/google_auth/close"; //ga验证
export const POSTGARESET = "/web-wallet/api/v1/account/google_auth/reset"; //ga重置
export const POSTGASECRET = "/web-wallet/api/v1/account/google_auth/secret"; //生成ga秘钥
export const POSTGAVERIFY = "/web-wallet/api/v1/account/google_auth/verify"; //ga验证
export const POSTLOGINPASSWORDRESET =
  "/web-wallet/api/v1/account/login_password/reset"; //重置登录密码
export const POSTPAYMENTPASSWORDRESET =
  "/web-wallet/api/v1/account/payment_password/reset"; //重置支付密码
export const POSTPAYMENTPASSWORDVERIFY =
  "/web-wallet/api/v1/account/payment_password/verify"; //验证支付密码
export const POSTRESETPHONE = "/web-wallet/api/v1/account/phone/reset"; //重置手机号
export const POSTVERIFYPHONE = "/web-wallet/api/v1/account/phone/verify"; //验证手机号
export const POSTAUTHPHONE = "/web-wallet/api/v1/phone/auth/validation"; //修改手机号验证手机
export const POSTVALIDATIONITEMS =
  "/web-wallet/api/v1/account/validation/items"; //获取需要校验的安全要素项
export const GETUSERINFO = "/web-wallet/api/v1/account/user/info"; //获取用户信息
export const POSTUPDATEUSERINFO = "/web-wallet/api/v1/account/user/update"; //修改用户信息
export const POSTSEARCHUSERINFO = "/web-wallet/api/v1/account/serach"; //搜索账户信息
export const POSTGEEVALIDATE = "/web-wallet/api/v1/geetest/validate"; //极验验证
export const GETBILLCATEGORYLIST = "/web-wallet/api/v1/bill/category-list"; // 账单分类
export const GETBILLLIST = "/web-wallet/api/v1/bill/list"; // 账单列表

export const GETEXCHANGESUPPORTEDSYMBOLS =
  "/web-wallet/api/v1/exchange/get-supported-symbols"; // 获取支持的交易对
export const GETCANSELECTCURRENCINFO =
  "/web-wallet/api/v1/exchange/get-can-select-currencies-info"; // 获取可选择的币种列表信息
export const GETEXCHANGEORDERBOOK =
  "/web-wallet/api/v1/exchange/get-order-book"; // 获取OrderBook
export const GETEXCHANGESYMBOLINFO =
  "/web-wallet/api/v1/exchange/get-symbol-info"; // 获取交易对信息
export const GETUSERREMAININGTRADEAMOUNT =
  "/web-wallet/api/v1/exchange/get-user-remaining-trade-amount"; // 获取用户剩余额度
export const POSTEXCHANGEPRECREATEORDER =
  "/web-wallet/api/v1/exchange/pre-create-order"; // 预创建订单
export const POST_EXCHANGE_CREATE_PAY_ORDER =
  "/web-wallet/api/v1/exchange/create-pay-order"; // 创建支付订单
export const POSTEXCHANGECREATEORDER =
  "/web-wallet/api/v1/exchange/create-order"; // 创建订单
export const POSTGETJUMIOURL = "/web-wallet/api/v1/kyc/jumio/initiate"; //获取jumio地址

export const GETAGREEMENTPRIVACYSHOW =
  "/web-wallet/api/v1/login/agreement-privacy"; // 展示协议弹窗
export const POSTAGREEMNETPRIVACYCONFIRM =
  "/web-wallet/api/v1/login/agreement-privacy/confirm"; // 确认协议

export const GETTRANSACCOUNTS = "/v2/web/spots/accounts"; //查询当前交易账户资产
export const POSTSPOTREQUEST = "/v2/web/spots/transfer/out/request"; // 发起一个从SPOTS向其他钱包转账的请求 //交易账户向资金账户转账
export const POSTBXSPOTSTRANSFER = "/v2/web/assets/bx/transfer/in"; //资金账户向交易账户转账
export const POSTBXSPOTSTRANSFERCONFIRM =
  "/v2/web/assets/bx/transfer/in_confirm"; //资金账户向交易账户转账 二次确认
export const GETEXCHANGERATE = "/v2/web/market/exchange-rate"; // 获取交易对汇率
export const GETMARKETFEX = "/v2/web/market/fex"; //获取法币汇率
export const GETSPOTSMEAT = "/v2/web/market/meta"; // 查询meta数据

//白名单
export const CREATE_WHITELIST_ADDRESS = "/web-wallet/api/v1/whitelist/create"; // 创建白名单地址
export const RENEW_WHITELIST_ADDRESS = "/web-wallet/api/v1/whitelist/renew"; // 创建白名单地址
export const DELETE_WHITELIST_ADDRESS = "/web-wallet/api/v1/whitelist/delete"; // 删除白名单地址
export const GET_WHITELIST_ADDRESS = "/web-wallet/api/v1/whitelist/list"; // 获取白名单列表
export const GET_WHITELIST_ADDRESS_UUID = "/web-wallet/api/v1/whitelist/get"; // 获取白名单
export const PERSONAL_SIGN_MSG = "/web-wallet/api/v1/whitelist/sign/message"; // 生成签名验证明文消息
export const WHITELIST_VASP_LIST = "/web-wallet/api/v1/vasp/list"; // 交易所列表

// 银行账号管理
export const BANK_ACCOUNT_CREATE =
  "/web-wallet/api/v1/whitelist/fiat/bank-account/create"; // 银行账号新增
export const BANK_ACCOUNT_DELETE =
  "/web-wallet/api/v1/whitelist/fiat/bank-account/delete"; // 银行账号删除
export const BANK_ACCOUNT_LIST =
  "/web-wallet/api/v1/whitelist/fiat/bank-account/list"; // 银行账号列表
export const BANK_ACCOUNT_UPDATE =
  "/web-wallet/api/v1/whitelist/fiat/bank-account/update"; // 银行账号编辑
export const BANK_ACCOUNT_GET =
  "/web-wallet/api/v1/whitelist/fiat/bank-account/get"; // 银行账号获取

export const KYC_INFO = "/web-wallet/api/v1/kyc/info"; // 获取用户kyc信息
