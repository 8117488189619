import PageWrap from "@/components/PageWrap";
import Start from "./Start";
import VerifyEmail from "./VerifyEmail";
import SetPayPwd from "./SetPayPwd";
import Success from "./SuccessStep";
import { getPageQuery } from "@/utils";
import { useDispatch } from "react-redux";
import { updateLanguage } from "@/state/user/reducer";
import { useEffect, useState } from "react";
import BindPhone from "./BindPhone";
import { Box } from "@chakra-ui/react";

const Register = () => {
  const urlParams = getPageQuery();
  const dispatch = useDispatch();
  const language = urlParams.language;
  const [registerStep, setRegisterStep] = useState("INIT");
  // INIT 1 2 3 SUCCESS

  useEffect(() => {
    if (language) {
      const maps = {
        cn: "zh",
        hk: "tw",
        en: "en",
      };
      if (maps[language]) {
        dispatch(updateLanguage({ language: maps[language] }));
      }
    }
  }, [language, dispatch]);

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
      >
        {registerStep === "INIT" && <Start setRegisterStep={setRegisterStep} />}
        {registerStep === "1" && (
          <VerifyEmail setRegisterStep={setRegisterStep} />
        )}
        {registerStep === "2" && (
          <BindPhone setRegisterStep={setRegisterStep} />
        )}
        {registerStep === "3" && (
          <SetPayPwd setRegisterStep={setRegisterStep} />
        )}
        {registerStep === "SUCCESS" && (
          <Success setRegisterStep={setRegisterStep} />
        )}
      </Box>
    </PageWrap>
  );
};

export default Register;
