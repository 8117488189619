import { useState, useEffect, useMemo } from "react";
import { useI18n } from "@/hooks/useI18n";
import { getAreaListApi } from "@/api/common";
import { handleS3UploadFile } from "@/utils/upload";
import {
  getPersonalKycUpdateInfoApi,
  submitPersonalKycUpdateInfoApi,
} from "@/api/perodic";
import { RISK_LEVEL } from "@/constants/kyc";
import { toastTip } from "@/components/ui/toast";
import useConstantsHooks from "../../common/constantsHooks";
import { cloneDeep, unionBy, intersectionBy } from "lodash";
const UPLOAD_FILE_PREFIX = "personal_updates";

const useHooks = ({ riskLevel, reviewUUID }) => {
  const { t } = useI18n();
  const { memoWealthSource, memoOtherCountryCardType } = useConstantsHooks();
  const [inputParams, setInputParams] = useState({
    id_country: "", // 证件发行地
    id_number: "", // 证件号码,
    id_type: "", // 证件类型
    has_other_country_id_card: "", // 是否有其他国家有效身份证
    other_id_country: "", // 其他国家
    other_id_type: "", //// 其他国家/地区 证件类型
    other_id_front_files: [], // 其他国家/地区 正面照
    other_id_opposite_files: [], // 其他国家/地区 反面照
    residence_address: "", // 常居地址
    residence_country: "", // 常居地 国家
    monthly_frequency: "",
    monthly_turnover: "",
    fund_source: "",
    wealth_source: "",
    id_copy_files: [],
    address_files: [],
    clone_address_files: [],
  });
  const [originPersonalInfo, setOriginPersonalInfo] = useState({}); // 原始数据
  const [saveInfo, setSaveInfo] = useState({});
  const [editAllow, setEditAllow] = useState(false);
  const [editTradeInfoAllow, setEditTradeInfoAllow] = useState(false);
  const [allCountryList, setAllCountryList] = useState([]); // 所有地区
  const [sanctionsCountryList, setSanctionsCountryList] = useState([]); // 制裁地区
  const [noCompliantCountryList, setNoCompliantCountryList] = useState([]); // 非合规
  const [areaScanError, setAreaScanError] = useState(false); // 证件发行地 制裁区错误
  const [areaCompliantError, setAreaCompliantError] = useState(false); // 证件发行地非合规错误
  const [otherCountryAreaError, setOtherCountryAreaError] = useState(false); // 其他国家 不为合规区 错误
  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading
  const [richApproveList, setRichApproveList] = useState([]); // 高风险财富证明
  const [showTipModal, setShowTipModal] = useState(false);
  const [pageStatus, setPageStatus] = useState("INIT");

  const getAllAreaList = async () => {
    const res = await getAreaListApi();
    if (res.code === 0) {
      const countryList = [...res.data.areas];
      countryList.forEach((item, idx) => {
        item.selectName = item.iso_code2;
        item.value = idx;
      });
      setAllCountryList(countryList);
      setSanctionsCountryList(res.data.sanction_areas);
      setNoCompliantCountryList(res.data.non_compliant_areas);
    }
  };

  useEffect(() => {
    if (inputParams.wealth_source) {
      const wealth_source = inputParams.wealth_source;
      let list = [];
      for (let i = 0; i < wealth_source?.options?.length; i++) {
        let item = wealth_source.options[i];
        // 找到匹配的
        const _filterItem = memoWealthSource.filter(
          (d) => d.value === item.value
        )[0];
        list.push(
          Object.assign({}, item, {
            error: false,
            fileName: "",
            filePath: "",
            key: _filterItem?.key,
            i18nKey: _filterItem?.i18nKey,
            fileTypeSuffix: "",
          })
        );
      }
      setRichApproveList((prev) => {
        // 先把之前的prev和最新的list 去重 因为prev可能有已经传了的
        // 再和list去做交集 因为有可能是选中后去删除
        return intersectionBy(unionBy([...prev, ...list], "key"), list, "key");
      });
    }
  }, [inputParams.wealth_source]);

  // useEffect(() => {
  //   if (inputParams.other_id_type) {
  //     inputOnChange(
  //       memoOtherCountryCardType.filter(
  //         (d) => d.value === inputParams.other_id_type
  //       )[0],
  //       "other_id_type"
  //     );
  //   } else {
  //     inputOnChange(memoOtherCountryCardType[0], "other_id_type");
  //   }
  // }, [inputParams.other_id_type]);

  const getPersonalKycUpdateInfo = async () => {
    const res = await getPersonalKycUpdateInfoApi({ review_uuid: reviewUUID });
    if (res.code === 0) {
      const { data } = res;
      const { other_id_type } = data;
      if (other_id_type) {
        data.has_other_country_id_card = { value: 1 };
      }
      setInputParams(Object.assign({}, inputParams, data));
      setOriginPersonalInfo(Object.assign({}, inputParams, data));
      setSaveInfo(Object.assign({}, inputParams, data));
    }
  };

  useEffect(() => {
    getAllAreaList();
  }, []);

  useEffect(() => {
    if (reviewUUID) {
      getPersonalKycUpdateInfo();
    }
  }, [reviewUUID]);

  const inputOnChange = (value, key) => {
    setInputParams((prev) => {
      const SEPECIAL_KEYS = ["wealth_source", "fund_source"];
      if (SEPECIAL_KEYS.indexOf(key) < 0) {
        const obj = { [key]: value };
        return Object.assign({}, prev, obj);
      } else {
        // 增加description
        const newObj = prev[key];
        newObj.description = value;
        return Object.assign({}, prev, {
          [key]: newObj,
        });
      }
    });
  };

  // 上传其他地区国家的正反面证件
  const uploadOtherCountryIdCardFileFn = async (
    file,
    fileTypeSuffix,
    type,
    fileName
  ) => {
    setUploadLoading(true);
    const uploadRes = await handleS3UploadFile({
      prefix: UPLOAD_FILE_PREFIX,
      file_type: fileTypeSuffix,
      file,
    });
    if (uploadRes.status !== "error") {
      setUploadLoading(false);
      const { filePath } = uploadRes;
      if (type === "front") {
        inputOnChange(
          [
            {
              document_path: filePath,
              document_type: fileTypeSuffix,
              document_error: false,
              document_type_comment: "other_id_front",
              document_name: fileName,
            },
          ],
          "other_id_front_files"
        );
      } else {
        inputOnChange(
          [
            {
              document_path: filePath,
              document_type: fileTypeSuffix,
              document_error: false,
              document_type_comment: "other_id_back",
              document_name: fileName,
            },
          ],
          "other_id_opposite_files"
        );
      }
    } else {
      setUploadLoading(false);
    }
  };

  const handleUploadOtherCountryIdCardFile = async (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const { size, name } = file;
      const fileTypeSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (
        (fileTypeSuffix === "pdf" || fileTypeSuffix === "jpg") &&
        size / 1000000 < 5
      ) {
        uploadOtherCountryIdCardFileFn(file, fileTypeSuffix, type, name);
      } else {
        if (type === "front") {
          inputOnChange(
            [
              {
                document_path: "",
                document_type: "",
                document_error: true,
                document_type_comment: "other_id_front",
                document_name: name,
              },
            ],
            "other_id_front_files"
          );
        } else {
          inputOnChange(
            [
              {
                document_path: "",
                document_type: "",
                document_error: true,
                document_type_comment: "other_id_back",
                document_name: name,
              },
            ],
            "other_id_opposite_files"
          );
        }
      }
    }
  };

  // 上传财富证明三个文件
  const handleRichApproveFile = async ({ e, key, index }) => {
    const file = e.target.files[0];
    if (file) {
      const { size, name } = file;
      const fileTypeSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (
        (fileTypeSuffix === "pdf" || fileTypeSuffix === "jpg") &&
        size / 1000000 < 5
      ) {
        setUploadLoading(true);
        const uploadRes = await handleS3UploadFile({
          prefix: UPLOAD_FILE_PREFIX,
          file_type: fileTypeSuffix,
          file,
        });
        if (uploadRes.status !== "error") {
          const { filePath } = uploadRes;
          setRichApproveList((prev) => {
            const _list = [...prev];
            const _item = _list[index];
            _item.fileName = name;
            _item.filePath = filePath;
            _item.error = false;
            _item.fileTypeSuffix = fileTypeSuffix;
            return _list;
          });
          setUploadLoading(false);
        }
      } else {
        setRichApproveList((prev) => {
          const _list = [...prev];
          const _item = _list[index];
          _item.fileName = name;
          _item.error = true;
          return _list;
        });
      }
    }
  };

  // 上传地址证明 或证明副本文件
  const handleUoloadEsFile = async ({ e, type }) => {
    const file = e.target.files[0];
    if (file) {
      const { size, name } = file;
      const fileTypeSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (
        (fileTypeSuffix === "pdf" || fileTypeSuffix === "jpg") &&
        size / 1000000 < 5
      ) {
        setUploadLoading(true);
        const uploadRes = await handleS3UploadFile({
          prefix: UPLOAD_FILE_PREFIX,
          file_type: fileTypeSuffix,
          file,
        });
        if (uploadRes.status !== "error") {
          const { filePath } = uploadRes;
          if (type === "ID_COPY_FILE") {
            inputOnChange(
              [
                {
                  document_path: filePath,
                  document_type: fileTypeSuffix,
                  document_error: false,
                  document_type_comment: "id_copy_files",
                  document_name: name,
                },
              ],
              "id_copy_files"
            );
          } else {
            inputOnChange(
              [
                {
                  document_path: filePath,
                  document_type: fileTypeSuffix,
                  document_error: false,
                  document_type_comment: "clone_address_files",
                  document_name: name,
                },
              ],
              "clone_address_files"
            );
          }
          setUploadLoading(false);
        }
      } else {
        if (type === "ID_COPY_FILE") {
          inputOnChange(
            [
              {
                document_path: "",
                document_type: "",
                document_error: true,
                document_type_comment: "id_copy_files",
                document_name: name,
              },
            ],
            "id_copy_files"
          );
        } else {
          inputOnChange(
            [
              {
                document_path: "",
                document_type: "",
                document_error: true,
                document_type_comment: "clone_address_files",
                document_name: name,
              },
            ],
            "clone_address_files"
          );
        }
      }
    }
  };

  // 多选
  const handleMultipleCheck = (mulValue, key) => {
    setInputParams((prev) => {
      const { value } = mulValue;
      const currObj = inputParams[key];
      if (!currObj?.options) {
        return Object.assign({}, prev, {
          [key]: {
            ...currObj,
            options: [mulValue],
          },
        });
      } else {
        const opts = [...currObj?.options];
        const findIndex = opts.findIndex((d) => d.value === value);
        if (findIndex > -1) {
          // 存在则反选
          opts.splice(findIndex, 1);
        } else {
          // 不存在则加入
          opts.push(mulValue);
        }
        return Object.assign({}, prev, {
          [key]: {
            ...currObj,
            options: opts,
          },
        });
      }
    });
  };

  // 取消
  const handleCancel = () => {
    setInputParams(Object.assign({}, inputParams, saveInfo));
  };

  const showOtherCountryBlock = useMemo(() => {
    if (areaScanError) {
      return false;
    }
    if (inputParams.id_type === "PASSPORT") {
      if (areaCompliantError || inputParams.other_id_country) {
        return true;
      }
    }
    return false;
  }, [inputParams, areaCompliantError, areaScanError]);

  // 保存
  const handleSave = () => {
    const _params = cloneDeep(inputParams);
    const {
      other_id_front_files,
      other_id_opposite_files,
      id_number,
      residence_address,
      has_other_country_id_card,
      other_id_country,
      id_copy_files,
      clone_address_files,
    } = _params;

    if (!id_number) {
      _params.id_number = originPersonalInfo.id_number;
    }

    if (!residence_address) {
      _params.residence_address = originPersonalInfo.residence_address;
    }

    // id number 不相符 时需要上传 核证的证件副本
    if (originPersonalInfo?.id_number !== id_number) {
      if (!id_copy_files?.[0]?.document_path) {
        toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
        return;
      }
    }
    if (id_copy_files[0]?.document_error) {
      return;
    }
    // 常居地址 不相符时 需要上传 住址证明
    if (originPersonalInfo?.residence_address !== residence_address) {
      if (!clone_address_files?.[0]?.document_path) {
        toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
        return;
      }
    }

    if (areaScanError) {
      toastTip(t("mzWsFtJNWfexuuUgYctv"), { type: "error" });
      return false;
    }
    // 其他国家信息
    if (showOtherCountryBlock) {
      if (!has_other_country_id_card) {
        toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
        return false;
      }
      if (Number(has_other_country_id_card.value) === 1) {
        if (otherCountryAreaError) {
          toastTip(t("mzWsFtJNWfexuuUgYctv"), { type: "error" });
          return false;
        }
        if (!other_id_country || !other_id_front_files?.[0]?.document_path) {
          toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
          return false;
        }
      } else if (Number(has_other_country_id_card.value) === 2) {
        toastTip(t("mzWsFtJNWfexuuUgYctv"), { type: "error" });
        return false;
      }
    }

    if (other_id_front_files?.[0]?.document_error) {
      return false;
    }
    if (other_id_opposite_files?.[0]?.document_error) {
      return false;
    }

    // 交易信息
    const wealth_source_options = inputParams["wealth_source"]?.options || [];
    const wealth_source_desc = inputParams["wealth_source"]?.description;
    const fund_source_options = inputParams["fund_source"]?.options || [];
    const fund_source_desc = inputParams["fund_source"]?.description;
    const monthly_frequency_options =
      inputParams["monthly_frequency"]?.options || [];
    const monthly_frequency_desc =
      inputParams["monthly_frequency"]?.description;
    if (
      wealth_source_options.length === 0 ||
      (wealth_source_options.findIndex((d) => d.value === 6) > -1 &&
        !wealth_source_desc)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }
    if (
      fund_source_options.length === 0 ||
      (fund_source_options.findIndex((d) => d.value === 5) > -1 &&
        !fund_source_desc)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }
    if (
      monthly_frequency_options.length === 0 ||
      (monthly_frequency_options.findIndex((d) => d.value === 1) > -1 &&
        !monthly_frequency_desc)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }

    setInputParams(Object.assign({}, inputParams, _params));
    setSaveInfo(Object.assign({}, originPersonalInfo, _params));

    return true;
  };

  const canSubmit = useMemo(() => {
    if (originPersonalInfo?.id_country) {
      const keys = Object.keys(originPersonalInfo);
      let flag = false;
      let baseChange = false;
      // 都一样就不让提交
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (
          JSON.stringify(originPersonalInfo[key]) !==
          JSON.stringify(inputParams[key])
        ) {
          baseChange = true;
          flag = true;
          break;
        }
      }
      // 如果是高风险 richApproveList校验是否都有filePath
      if (riskLevel === RISK_LEVEL.HIGH) {
        if (!baseChange) {
          flag = false;
          return;
        }
        if (richApproveList.length === 0) {
          flag = false;
        } else {
          flag = richApproveList.every((d) => d.filePath);
        }
      }

      return flag;
    }
  }, [originPersonalInfo, inputParams, riskLevel, richApproveList]);

  const memoMustUploadIdFile = useMemo(() => {
    if (originPersonalInfo?.id_number !== inputParams.id_number) {
      return true;
    }
    return false;
  }, [originPersonalInfo, inputParams]);

  const memoMustUploadResAddressFile = useMemo(() => {
    if (
      originPersonalInfo?.residence_address !== inputParams.residence_address
    ) {
      return true;
    }
    return false;
  }, [originPersonalInfo, inputParams]);

  const onSubmitCheck = async (riskLevel) => {
    const {
      id_number,
      id_copy_files,
      residence_address,
      wealth_source_files,
      clone_address_files,
      id_type,
    } = inputParams;

    if (!id_number || !residence_address) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return;
    }

    // id number 不相符 时需要上传 核证的证件副本
    if (originPersonalInfo?.id_number !== id_number) {
      if (!id_copy_files?.[0]?.document_path) {
        toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
        return;
      }
    }
    // 常居地址 不相符时 需要上传 住址证明
    if (originPersonalInfo?.residence_address !== residence_address) {
      if (!clone_address_files?.[0]?.document_path) {
        toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
        return;
      }
    }

    // 护照 + 制裁区
    if (id_type === "PASSPORT" && areaScanError) {
      toastTip(t("mzWsFtJNWfexuuUgYctv"), { type: "error" });
      return;
    }
    // 护照 + 非合规区 + 没有其他其他国家有效身份证
    if (
      id_type === "PASSPORT" &&
      areaCompliantError &&
      (Number(inputParams?.has_other_country_id_card?.value) === 2 ||
        !inputParams?.has_other_country_id_card)
    ) {
      toastTip(t("mzWsFtJNWfexuuUgYctv"), { type: "error" });
      return;
    }
    // 护照 + 非合规区 + 有其他其他国家有效身份证 + 没有选其他国家
    if (
      id_type === "PASSPORT" &&
      areaCompliantError &&
      Number(inputParams?.has_other_country_id_card?.value) === 1 &&
      !inputParams?.other_id_country
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return;
    }
    // 护照 + 非合规区 + 有其他其他国家有效身份证 + 其他国家地区在制裁区、非合规区
    if (
      id_type === "PASSPORT" &&
      areaCompliantError &&
      Number(inputParams?.has_other_country_id_card?.value) === 1 &&
      inputParams.other_id_country &&
      otherCountryAreaError
    ) {
      toastTip(t("mzWsFtJNWfexuuUgYctv") + "5", { type: "error" });
      return;
    }

    // 护照 + 非合规区 + 有其他其他国家有效身份证 + 其他国家地区在合规区 + （!没有选证件类型 ｜｜ ！ 没有上传正面文件信息 || !没有选证件类型）
    if (
      id_type === "PASSPORT" &&
      areaCompliantError &&
      Number(inputParams?.has_other_country_id_card?.value) === 1 &&
      !otherCountryAreaError &&
      (!inputParams.other_id_type ||
        !inputParams?.other_id_front_files?.[0]?.document_path)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return;
    }
    // 校验交易信息
    const wealth_source_options = inputParams["wealth_source"]?.options || [];
    const wealth_source_desc = inputParams["wealth_source"]?.description;
    const fund_source_options = inputParams["fund_source"]?.options || [];
    const fund_source_desc = inputParams["fund_source"]?.description;
    const monthly_turnover_options =
      inputParams["monthly_turnover"]?.options || [];
    const monthly_frequency_options =
      inputParams["monthly_frequency"]?.options || [];
    const monthly_frequency_desc =
      inputParams["monthly_frequency"]?.description;
    if (
      wealth_source_options.length === 0 ||
      (wealth_source_options.findIndex((d) => d.value === 6) > -1 &&
        !wealth_source_desc)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }
    if (
      fund_source_options.length === 0 ||
      (fund_source_options.findIndex((d) => d.value === 5) > -1 &&
        !fund_source_desc)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }
    if (monthly_turnover_options.length === 0) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }
    if (
      monthly_frequency_options.length === 0 ||
      (monthly_frequency_options.findIndex((d) => d.value === 1) > -1 &&
        !monthly_frequency_desc)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }
    // 高风险还需要检测 wealth_source_files list
    if (riskLevel === RISK_LEVEL.HIGH) {
      if (richApproveList?.length === 0) {
        toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
        return;
      } else {
        let next = true;
        for (let i = 0; i < richApproveList.length; i++) {
          if (!richApproveList[i]?.filePath) {
            next = false;
            break;
          }
        }
        if (!next) {
          toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
          return;
        }
      }
    }
    setShowTipModal(true);
  };

  const onSubmit = async () => {
    const params = cloneDeep(inputParams);
    params.review_uuid = reviewUUID;
    params.is_updated = true;

    if (riskLevel === RISK_LEVEL.HIGH) {
      params.wealth_source_files = richApproveList.map((d) => {
        return {
          document_path: d.filePath,
          document_type_comment: d.key,
          document_type: d.fileTypeSuffix,
        };
      });
    }
    if (params.clone_address_files[0]?.document_path) {
      params.address_files = params.clone_address_files;
    }

    if (
      (params.id_type === "PASSPORT" && !params.other_id_country) ||
      params.id_type === "ID_CARD"
    ) {
      params.residence_country = params.id_country;
    } else {
      params.residence_country = params.other_id_country;
    }

    delete params.has_other_country_id_card;
    delete params.clone_address_files;
    // 如果用户从非合规 => 合规地区
    // 删除所有other字段
    if (
      params.id_type === "ID_CARD" ||
      (params.id_type === "PASSPORT" && !params.other_id_country)
    ) {
      delete params.other_id_front_files;
      delete params.other_id_opposite_files;
      delete params.other_id_type;
      delete params.other_id_country;
    }

    const submitRes = await submitPersonalKycUpdateInfoApi(params);
    if (submitRes.code === 0 && submitRes?.data?.ok) {
      setPageStatus("RESULT");
    }
  };

  return {
    inputParams,
    inputOnChange,
    editAllow,
    allCountryList,
    sanctionsCountryList,
    noCompliantCountryList,
    areaScanError,
    areaCompliantError,
    otherCountryAreaError,
    setAreaScanError,
    setAreaCompliantError,
    setEditAllow,
    uploadOtherCountryIdCardFileFn,
    handleUploadOtherCountryIdCardFile,
    uploadLoading,
    setInputParams,
    handleMultipleCheck,
    editTradeInfoAllow,
    setEditTradeInfoAllow,
    richApproveList,
    canSubmit,
    handleRichApproveFile,
    setOtherCountryAreaError,
    handleUoloadEsFile,
    originPersonalInfo,
    onSubmitCheck,
    onSubmit,
    memoMustUploadIdFile,
    memoMustUploadResAddressFile,
    setShowTipModal,
    showTipModal,
    pageStatus,
    handleCancel,
    handleSave,
    showOtherCountryBlock,
  };
};

export { useHooks };
