import PageWrap from "@/components/PageWrap";
import GaStepOne from "./gaStepOne";
import GaStepTwo from "./gaStepTwo";
import GaStepThree from "./gaStepThree";
import { useState } from "react";
import { Box } from "@chakra-ui/react";
// import ResetPwd from "./resetPwd";
// import {  useAppSelector } from '@/state/hooks'
const BindGA = () => {
  const [step, setStep] = useState(1);
  const getStep = (value) => {
    setStep(value);
  };

  return (
    <PageWrap>
      <Box
        style={{ backgroundColor: "#fff" }}
        height={"auto"}
        minHeight={"100vh"}
        overflow={"hidden"}
      >
        {step === 1 && <GaStepOne getStep={getStep} />}
        {step === 2 && <GaStepTwo getStep={getStep} />}
        {step === 3 && <GaStepThree getStep={getStep} />}
      </Box>
    </PageWrap>
  );
};

export default BindGA;
