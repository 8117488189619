import request from "@/hooks/useRequest";
import { GETUSERINFO, GETPROCESSINFO } from "@/helpers/constants/requestApi";

//获取用户信息
export const getUserInfo = (params) => {
  return request(GETUSERINFO, { params });
};

//获取kyc进程信息
export const getProcessInfo = (params) => {
  return request(GETPROCESSINFO, { params });
};
