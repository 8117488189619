// 提现第一步
const WITHDRAWONE = 'withDraw_one';
// 链上提现
const WITHDRAWCHAIN = 'transfer_outside';
// 内部提现
const WITHDRAWINSIDE = 'transfer_inner';
// 银行转账
const WITHDRAWBANK = 'transfer_bank';
// 确认信息页
const WITHDRAWCONFIRM = 'confirm';
// 安全验证
const WITHDRAWVERIFY = 'verify';
// 结果页
const WITHDRAWRESULTPAGE = 'resultPage';

export { 
    WITHDRAWONE, 
    WITHDRAWCHAIN, 
    WITHDRAWINSIDE, 
    WITHDRAWCONFIRM,
    WITHDRAWVERIFY,
    WITHDRAWRESULTPAGE,
    WITHDRAWBANK,
}