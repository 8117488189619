import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateVerifyStep } from "@/state/pay/reducer";

import CommonBtnDom from "@/pages/deposit/component/commonBtn";
import AlertDom from "@/components/ui/alert";
import { Box, Image, Text } from "@chakra-ui/react";

import { getPayInfo, requestPayConfirm } from "@/pages/withdraw/api";
import { requestCreateOrder } from "@/pages/ccExchange/api/index";
import { useI18n } from "@/hooks/useI18n";

import {
  WITHDRAWBANK,
  WITHDRAWCHAIN,
  WITHDRAWRESULTPAGE,
} from "@/pages/withdraw/helpers/constants/pageTypeName";
import {
  MEMOFORMAT,
  PUBKEYFORMAT,
} from "@/pages/withdraw/helpers/constants/formatName";
import { GOOGLEAUTH } from "@/pages/withdraw/helpers/constants/safeVerifyName";
import { BIXIN_GA } from "@/helpers/constants/routeName";
import { PAYSUCCESS } from "@/pages/withdraw/helpers/constants/payStatusName";
import copyToClipboard from "copy-to-clipboard";
import {toastTip} from "@/components/ui/toast";
import {Dec} from "@/helpers/func/decimalCal";

const WithdrawConfirmPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useI18n();

  const {
    payId,
    requestId,
    currentMapp,
    showLoadingEvent,
    conformPay,
    getPayAmount,
    getPayCurrency,
    getPayStatus,
    getVerifyItems,
    withdrawType,
  } = props;

  const [payInfo, setPayInfo] = useState({});
  const [payView, setPayView] = useState({});
  const [needGa, setNeedGa] = useState(false);
  const [showGaAlert, setShowGaAlert] = useState(false);
  const [verifyItems, setVerifyItems] = useState([]);

  const isBankWithDrawFlag = withdrawType === WITHDRAWBANK;

  useEffect(() => {
    initPayInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payId]);
  const initPayInfo = async () => {
    const { code, data } = await getPayInfo({
      transaction_id: payId,
    });
    console.log("获取到的支付信息", code, data, payId);
    if (code === 0) {
      const need_verify_step_items = [];
      if (data?.risk?.verify_items?.step1) {
        need_verify_step_items.push(data?.risk?.verify_items?.step1);
      }
      if (data?.risk?.verify_items?.step2) {
        need_verify_step_items.push(data?.risk?.verify_items?.step2);
      }
      setPayInfo(data?.order);
      setReduxVerifyStep(need_verify_step_items);
      setVerifyItems(need_verify_step_items);
      getVerifyItems(need_verify_step_items);
      setPayView(data?.view);
      getPayAmount && getPayAmount(data?.order?.amount);
      getPayCurrency && getPayCurrency(data?.order?.amount);
      if (data?.risk?.verify_items?.missing_verify_items.includes(GOOGLEAUTH)) {
        setNeedGa(true);
        setShowGaAlert(true);
      }
    }
    showLoadingEvent(false);
  };

  const setReduxVerifyStep = (getValue) => {
    dispatch(updateVerifyStep({ verifyStep: getValue }));
  };

  const clickPay = () => {
    console.log("verifyItems", verifyItems);
    if (needGa) {
      setShowGaAlert(true);
    } else {
      if (verifyItems.length === 0) {
        // if (currentMapp) {
        noPasswordPay();
        // }
      } else {
        conformPay();
      }
    }
  };
  const noPasswordPay = async () => {
    showLoadingEvent(true);
    const { code, data, msg } = await requestPayConfirm({
      transaction_id: payId,
    });
    console.log("免密支付-确认支付结果", code, data, msg);
    if (code === 0) {
      if (data.status === PAYSUCCESS) {
        if (currentMapp) {
          confirmMappStatus();
        } else {
          getPayStatus(PAYSUCCESS);
          conformPay(WITHDRAWRESULTPAGE);
        }
      }
      showLoadingEvent(false);
    } else {
      showLoadingEvent(false);
    }
  };
  const confirmMappStatus = async () => {
    const { code, data, msg } = await requestCreateOrder({
      uuid: requestId,
    });
    console.log("确认小程序支付状态-结果", code, data, msg);
    if (code === 0) {
      if (data.ok) {
        getPayStatus(PAYSUCCESS);
        conformPay(WITHDRAWRESULTPAGE);
      }
    }
    showLoadingEvent(false);
  };

  const noDataSHowDom = (value) => {
    return value || "-";
  };
  const orderNote = (value) => {
    return value
      ? value === WITHDRAWCHAIN
        ? t("COydGeEPULYaNAmN")
        : t("pFhbWEovZnvIiVEEJnme")
      : "-";
  };
  const orderAmountDom = () => {
    return `${noDataSHowDom(payInfo?.amount)} ${noDataSHowDom(
      payInfo?.currency
    )}`;
  };

  const orderDetailsDom = () => {
    if(isBankWithDrawFlag) {
      return (
          <div className="withdraw-confirm-page_info-list">
            <Text color={"#000"} fontWeight={600} fontSize={14}>{t("LoBOFhdSzMFjdqZgDE")}</Text>
            <Box
                mt={"10px"}
                p={"15px"}
                borderRadius={"12px"}
                bgColor={"#F5F5F7"}
            >
              {unitDom({
                title: t("PlIgEPTbgrrwkSuHc"),
                value: payInfo?.attach && noDataSHowDom(JSON.parse(payInfo?.attach).bank_name),
                addClassName: "bank-transfer-tr",
              })}
              {unitDom({
                title: t("NqhjvGQWWHnmAzDH"),
                value: payInfo?.attach && noDataSHowDom(JSON.parse(payInfo?.attach).account_name),
                addClassName: "bank-transfer-tr",
                isCopy: true,
              })}
              {unitDom({
                title: t("QNEAoNoQfTpYIxJvjdM"),
                value: payInfo?.attach && noDataSHowDom(JSON.parse(payInfo?.attach).account_no),
                addClassName: "bank-transfer-tr",
                isCopy: true,
              })}
            </Box>
            <Box
                mt={"20px"}
            >
              {unitDom({
                title: t("NOYdleknddFOfQxlNOI"),
                value: payInfo?.attach && noDataSHowDom(`${Dec(payInfo?.amount, JSON.parse(payInfo?.attach).fee, "-")} ${payInfo?.currency}`),
                addClassName: "withdraw-amount-tr",
              })}
              {unitDom({
                title: t("iHptEcwFnEbHVLaQkXyb"),
                value: payInfo?.attach && noDataSHowDom(`${JSON.parse(payInfo?.attach).fee} ${payInfo?.currency}`),
                addClassName: "withdraw-fee-tr",
              })}
            </Box>
          </div>
      );
    }
    return (
        <div className="withdraw-confirm-page_info-list">
          {unitDom({
            title: t("swejTyFcYnjuUgsfQW"),
            value: noDataSHowDom(payInfo?.currency),
            addClassName: "",
          })}
          {unitDom({
            title: t("siEUrpaJmBIICzsaBnr"),
            value: noDataSHowDom(payInfo?.receiver?.name || payInfo?.address),
            addClassName: "withdraw-margin-top-25",
          })}
          {payView?.format === MEMOFORMAT &&
          unitDom({
            title: t("nzLBdKgvNCXQTgYI"),
            value: payInfo?.address_extra?.memo,
            addClassName: "withdraw-margin-top-20",
          })}
          {payView?.format === PUBKEYFORMAT && (
              <>
                {unitDom({
                  title: "Pubkey",
                  value: payInfo?.address_extra?.pubkey,
                  addClassName: "withdraw-margin-top-20",
                })}
                {unitDom({
                  title: t("lrCgiXeJcIwkuDsyc"),
                  value: payInfo?.address_extra?.chain,
                  addClassName: "withdraw-margin-top-20",
                })}
              </>
          )}
          {unitDom({
            title: t("tFaBUAWNRhJqtjIha"),
            value:
                payInfo?.attach &&
                orderNote(JSON.parse(payInfo?.attach).order_desc),
            addClassName: "withdraw-margin-top-20",
          })}
          {unitDom({
            title: t("AIwKneHGNfSJmdLnG"),
            value: orderAmountDom(),
            addClassName: "withdraw-margin-top-20",
          })}
        </div>
    );
  };

  const clickCopy = text => {
    copyToClipboard(text);
    toastTip(t('gXbirwakdsaUKBglwK'), { type: "success" });
  };

  const unitDom = (getObject) => {
    return (
      <div
        className={`withdraw-confirm-page_info-list_unit ${getObject.addClassName}`}
      >
        <span>{getObject.title}</span>
        <div className="unit-value-box">
          <span className="unit-value-style">{getObject.value}</span>
          {getObject?.isCopy && (
              <Image cursor={"pointer"} src='../wwwsite/images/copy.svg' alt='Dan Abramov'
                     onClick={() => clickCopy(getObject.value)}/>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="withdraw-confirm-page">
      <div className="withdraw-confirm-page_total">
        <span>{orderAmountDom()}</span>
      </div>
      {orderDetailsDom()}
      {isBankWithDrawFlag && <Text textAlign={"center"} mt={"50px"} color={"#D04457"} fontSize={12} fontWeight={500}>{t("mHVIpiFPJQPNPFYAeeu")}</Text>}
      <CommonBtnDom
        btnText={t(isBankWithDrawFlag ? "AQrCocFqoKoYieUnT" : "wMGJlEJaupHayuzRwi")}
        marginTop={isBankWithDrawFlag ? "20px" : "55px"}
        clickEvent={clickPay}
      />
      {showGaAlert && (
        <AlertDom>
          <Box px="30px" textAlign={"center"}>
            <Image
              src="../wwwsite/images/transfer/tip_icon.svg"
              boxSize={"24px"}
              mx={"auto"}
              mt="70px"
            />
            <Text
              fontWeight={500}
              fontSize={"16px"}
              color={"#000000"}
              w="48%"
              mx={"auto"}
              mt="10px"
            >
              {t("GvnMmZJtuApdXwWhsFd")}
            </Text>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mt="46px"
              pb="50px"
            >
              <CommonBtnDom
                btnText={t("UNForxvjDLCZdGrmWkX")}
                marginTop="0px"
                btnW="40%"
                btnBG={"rgba(32, 26, 85, 0.05)"}
                btnHoverBG={"rgba(32, 26, 85, 0.05)"}
                btnColor={"#010313"}
                clickEvent={() => {
                  setShowGaAlert(false);
                }}
              />
              <CommonBtnDom
                btnText={t("IcahwJmNfgdvVpPz")}
                marginTop="0px"
                btnW="50%"
                clickEvent={() => {
                  navigate(BIXIN_GA);
                }}
              />
            </Box>
          </Box>
        </AlertDom>
      )}
    </div>
  );
};

export default WithdrawConfirmPage;
