import { useMemo } from "react";
import Progress from "./Progress";
import { Flex, Box, Text, Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useI18n } from "@/hooks/useI18n";
import { useVitualAssetsTestHooks } from "./hooks";
import ModalComponent from "@/components/ui/modal";
import "./assets.scss";

const VirtualAssetsTest = ({ setStep }) => {
  const navigate = useNavigate();
  const { t } = useI18n();

  const {
    isDisabled,
    dataSource,
    handleSelect,
    onSubmit,
    resultInfo,
    viewStatus,
    showTipModal,
    setShowTipModal,
    reTry,
  } = useVitualAssetsTestHooks();

  const MemeQues = useMemo(() => {
    if (dataSource.questions?.length > 0) {
      return dataSource.questions.map((d, i) => {
        return (
          <Box key={i}>
            <Text
              fontSize={"14px"}
              fontWeight={400}
              color={"#010313"}
              mt={"30px"}
            >
              {d.content}
            </Text>
            {d.options.map((dd, ii) => {
              return (
                <Flex key={ii} alignItems={"flex-start"} mt={"10px"}>
                  <Image
                    mt={"4px"}
                    onClick={() => handleSelect(i, ii)}
                    cursor={"pointer"}
                    src={`../../wwwsite/images/kyc/${
                      dd.checked ? "checked.svg" : "no_check.svg"
                    }`}
                  ></Image>
                  <Text ml={"10px"} fontSize={"14px"}>
                    {dd.content}
                  </Text>
                </Flex>
              );
            })}
          </Box>
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const renderInitView = () => {
    return (
      <Flex direction={"column"} alignItems={"center"} pt={"50px"} pb={"50px"}>
        <Text color={"#010313"} fontSize={"24px"} fontWeight={600}>
          {dataSource?.title}
        </Text>

        <Text color={"#010313"} fontSize={"14px"} mt={"20px"}>
          {dataSource?.description}
        </Text>
        {dataSource?.title && (
          <>
            <Text
              color={"#010313"}
              fontSize={"14px"}
              mt={"20px"}
              className="kyc-virtual-asset-text"
              dangerouslySetInnerHTML={{ __html: t("EqbYvDbdIrmgXbrPPAO") }}
            ></Text>
            <Text
              lineHeight={4}
              color={"#010313"}
              fontSize={"14px"}
              width={"100%"}
              dangerouslySetInnerHTML={{ __html: t("pyuZUzzDPovDumukD") }}
            ></Text>
          </>
        )}

        <Box mt={"10px"}>{MemeQues}</Box>
        <Flex justifyContent={"center"} mt="40px">
          <Button
            ml="10px"
            fontSize="14px"
            padding="0 30px"
            w="100px"
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              navigate("/trade/personKyc/certificate");
            }}
          >
            {t("WbPTckCFMKtPHgAlL")}
          </Button>
          <Button
            ml="10px"
            fontSize="14px"
            padding="0 30px"
            w="200px"
            bg={isDisabled ? "t.50" : "blue.100"}
            _hover={{ bg: !isDisabled && "blue.80" }}
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            disabled={isDisabled}
            cursor={"pointer"}
            onClick={() => {
              if (!isDisabled) {
                onSubmit();
              }
            }}
          >
            {t("YChcfaxDaLscdSPiRQz")}
          </Button>
        </Flex>
      </Flex>
    );
  };

  const renderRusult = () => {
    const { is_pass, right_count, total_question } = resultInfo;
    // 未通过
    if (!is_pass) {
      return (
        <Flex flexDirection={"column"} alignItems={"center"} mt={"60px"}>
          <Image src="../../wwwsite/images/kyc/certificate/denied.svg" />
          <Text
            color={"#010313"}
            fontSize={"24px"}
            fontWeight={600}
            mt={"10px"}
          >
            {t("EHBFEqMJtpPqVznA")}
          </Text>
          <Box
            borderRadius={"12px"}
            padding={"10px 15px"}
            fontSize={"14px"}
            bg={"#FCEDEF"}
            color={"#D04457"}
            mt={"50px"}
            fontWeight={600}
          >
            {t("IHceeEHwOOHjpdHosyOe")}:{right_count}/{total_question}
          </Box>
          {/* 底部按钮 */}
          <Flex justifyContent={"center"} mt="60px" mb={"50px"}>
            <Button
              mr="10px"
              fontSize="14px"
              padding="0 30px"
              w={["30%", "100px"]}
              bg="rgba(32, 26, 85, 0.05)"
              color="t.100"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={"pointer"}
              onClick={() => {
                navigate("/trade/personKyc/certificate");
              }}
            >
              {t("FrzWGSiDSDzTfNgZH")}
            </Button>
            <Button
              fontSize="14px"
              padding="0 30px"
              w={["60%", "200px"]}
              bg="blue.100"
              color="#fff"
              fontWeight={"400"}
              borderRadius={"30px"}
              _hover={{ bg: "blue.80" }}
              h="44px"
              cursor={"pointer"}
              onClick={() => reTry()}
            >
              {t("leIEPguvvNFtRsXGFI")}
            </Button>
          </Flex>
        </Flex>
      );
    } else {
      // 通过
      return (
        <Flex flexDirection={"column"} alignItems={"center"} mt={"60px"}>
          <Image
            src="../../wwwsite/images/kyc/certificate/done.svg"
            w={"40px"}
            height={"40px"}
          />
          <Text
            color={"#010313"}
            fontSize={"24px"}
            fontWeight={600}
            mt={"10px"}
          >
            {t("kINzgREzBDTsegQCvHQ")}
          </Text>
          <Box
            borderRadius={"12px"}
            padding={"10px 15px"}
            fontSize={"14px"}
            bg={"#EDEFFC"}
            color={"#333BF5"}
            mt={"20px"}
            fontWeight={600}
          >
            {t("IHceeEHwOOHjpdHosyOe")}:{right_count}/{total_question}
          </Box>
          <Flex alignItems={"center"} mt={"40px"}>
            <Text
              fontSize={"14px"}
              className="kyc-assets-verify-passed"
              onClick={(e) => {
                if (e.target.nodeName === "B") {
                  setStep(7);
                }
              }}
              dangerouslySetInnerHTML={{ __html: t("aETDJvQumPoQnxdEPkQR") }}
            ></Text>
            <Image
              cursor={"pointer"}
              src="../../wwwsite/images/kyc/gray-tip.svg"
              onClick={(e) => {
                e.stopPropagation();
                setShowTipModal(true);
              }}
            />
          </Flex>
          {/* 底部按钮 */}
          <Button
            mr="10px"
            mt={"60px"}
            mb={"50px"}
            fontSize="14px"
            padding="0 30px"
            w={["30%", "100px"]}
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              navigate("/trade/personKyc/certificate");
            }}
          >
            {t("FrzWGSiDSDzTfNgZH")}
          </Button>
        </Flex>
      );
    }
  };

  return (
    <Flex justifyContent={"center"} width={"100%"}>
      <Box mt={"30px"} width={"100%"}>
        <Progress step="2"></Progress>
        {viewStatus === "RESULT" && resultInfo && renderRusult()}
        {viewStatus === "INIT" && dataSource && renderInitView()}
      </Box>
      <ModalComponent isShow={showTipModal}>
        <Box px={"33px"} py={"50px"}>
          <Box>
            <Flex justifyContent={"space-between"}>
              <Text color="#010313" fontSize={16} fontWeight={600}>
                {t("GCxKMtkKAZPoOHsZIDh")}
              </Text>
              <Image
                boxSize="24px"
                src="../../wwwsite/images/bill/close.svg"
                cursor={"pointer"}
                onClick={() => setShowTipModal(false)}
              />
            </Flex>
            <Text fontSize={14} color={"#010313"} mt={"20px"}>
              {t("FaQaEHvLdVELQbCZrFN")}
            </Text>
          </Box>
        </Box>
      </ModalComponent>
    </Flex>
  );
};

export default VirtualAssetsTest;
