import { AppProvider } from "./AppProvider";
import { RouterProvider } from "react-router-dom";
import { router } from "./AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Suspense } from "react";

export const App = () => {
  return (
    <AppProvider>
      <Suspense>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <RouterProvider router={router} />
        {/* {ipBan && ipCheckDone && <IPBanComponent />}
        {!ipBan && ipCheckDone && <RouterProvider router={router} />} */}
      </Suspense>
    </AppProvider>
  );
};

export default App;
