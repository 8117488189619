import { useRef, useState, useEffect } from "react";
import { useAppSelector } from "@/state/hooks";

import MiliCodeInput from "@/components/ui/mili-codeinput";
import { Box, Text, Input, Button } from "@chakra-ui/react";
import CommonBtnDom from "@/pages/deposit/component/commonBtn";
import Countdown from "@/components/countdonw";

import { postPaymentPwdVerify } from "@/pages/bindGA/api";
import {
  requestGoogleAuthVerify,
  requestPayConfirm,
} from "@/pages/withdraw/api";
import { postEmailVerify } from "@/pages/resetAccountInfo/api";
import { postEmailCodeGetFunc } from "@/pages/login/api";
import { requestCreateOrder } from "@/pages/ccExchange/api/index";
import { useI18n } from "@/hooks/useI18n";
import { geetestFlow } from "@/pages/resetAccountInfo/api";

import {
  PAYMENTPASSWORD,
  GOOGLEAUTH,
  VERIFYEMAIL,
} from "@/pages/withdraw/helpers/constants/safeVerifyName";
import { PAY } from "@/pages/withdraw/helpers/constants/businessName";
import { WITHDRAWVERIFY } from "@/pages/withdraw/helpers/constants/pageTypeName";
import {
  PAYSUCCESS,
  ADMINREQUIRED,
} from "@/pages/withdraw/helpers/constants/payStatusName";
import { getUserInfo } from "@/pages/withdraw/api";
const WithdrawSafeVerify = (props) => {
  const _verifyStep = useAppSelector((state) => state.pay.verifyStep);

  const { t } = useI18n();

  const {
    payId,
    requestId,
    currentMapp,
    showLoadingEvent,
    confirmPay,
    getPayStatus,
  } = props;

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [GA, setGA] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [reSend, setReSend] = useState(true);
  const [emailCode, setEmailCode] = useState("");

  const passWordInputRef = useRef(null);

  const passwordInput = (val) => {
    setPassword(val);
  };

  const GAInput = (val) => {
    setGA(val);
  };

  const getUserInfoFn = async () => {
    const res = await getUserInfo({});
    if (res.code === 0) {
      setEmail(res.data.email);
    }
  };

  useEffect(() => {
    getUserInfoFn();
  }, []);

  const clickSendEmail = async () => {
    if (reSend) {
      setReSend(true);
      const geetestFlowParams = {
        email: email,
        request_id: payId,
      };
      geetestFlow(geetestFlowParams, async () => {
        const { code, data, msg } = await postEmailCodeGetFunc({
          email: email,
          scene: PAY,
          request_id: String(payId),
        });
        console.log("发送邮箱", code, data, msg);
        if (code === 0 && msg === "OK") {
          if (data.ok) {
            setReSend(false);
          } else {
            setReSend(true);
          }
        } else {
          setReSend(true);
        }
      });
    }
  };

  const emailInput = (e) => {
    setEmailCode(e.target.value);
  };

  const handleTimeout = () => {
    //时间到
    setReSend(true);
  };

  const clickSafeCheck = async () => {
    if (canClick()) {
      showLoadingEvent(true);
      console.log("安全校验", payId, password, GA, PAY);
      const {
        code: pscode,
        data: psdata,
        msg: psmsg,
      } = await postPaymentPwdVerify({
        request_id: payId,
        business: PAY,
        payment_password: password,
      });
      console.log("密码验证", pscode, psdata, psmsg);
      if (pscode === 0 && psmsg === "OK") {
        if (psdata.ok) {
          if (_verifyStep[1] && _verifyStep[1] === GOOGLEAUTH) {
            const {
              code: gacode,
              data: gadata,
              msg: gamsg,
            } = await requestGoogleAuthVerify({
              request_id: payId,
              business: PAY,
              code: GA,
            });
            console.log("谷歌验证", gacode, gadata, gamsg);
            if (gacode === 0 && gamsg === "OK") {
              if (gadata.ok) {
                getPayConfirmResult();
              }
            } else {
              showLoadingEvent(false);
            }
          } else if (_verifyStep[1] && _verifyStep[1] === VERIFYEMAIL) {
            const {
              code: mcode,
              data: mdata,
              msg: mmsg,
            } = await postEmailVerify({
              request_id: payId,
              business: PAY,
              code: emailCode,
            });
            console.log("邮箱验证", mcode, mdata, mmsg);
            if (mcode === 0 && mmsg === "OK") {
              if (mdata.ok) {
                getPayConfirmResult();
              }
            } else {
              showLoadingEvent(false);
            }
          } else {
            getPayConfirmResult();
          }
        } else {
          showLoadingEvent(false);
        }
      } else {
        showLoadingEvent(false);
      }
    }
  };
  const canClick = () => {
    if (_verifyStep.length === 1) {
      return Boolean(payId && password.length === 6);
    } else {
      if (_verifyStep[1] && _verifyStep[1] === GOOGLEAUTH) {
        return Boolean(payId && password.length === 6 && GA.length === 6);
      } else {
        return Boolean(
          payId && password.length === 6 && emailCode.length === 6
        );
      }
    }
  };
  const getPayConfirmResult = async () => {
    const { code, data, msg } = await requestPayConfirm({
      transaction_id: payId,
    });
    console.log("确认支付结果", code, data, msg);
    if (code === 0 && msg === "OK") {
      if (data.status === PAYSUCCESS || data.status === ADMINREQUIRED) {
        if (currentMapp) {
          confirmMappStatus();
        } else {
          getPayStatus(data.status);
          confirmPay(WITHDRAWVERIFY);
        }
      }
    }
    if (!currentMapp) {
      showLoadingEvent(false);
    }
  };
  const confirmMappStatus = async () => {
    const { code, data, msg } = await requestCreateOrder({
      uuid: requestId,
    });
    console.log("确认小程序支付状态-结果", code, data, msg);
    if (code === 0) {
      if (data.ok) {
        getPayStatus(PAYSUCCESS);
        confirmPay();
      }
    }
    showLoadingEvent(false);
  };

  const titleDom = (title) => {
    return (
      <Box display="flex" alignItems="center">
        <Box w="8px" h="8px" bg="blue.100" borderRadius={"8px"} mr="8px"></Box>
        <Text flex={1} fontSize="16px" fontWeight={500} color="#010313">
          {title}
        </Text>
      </Box>
    );
  };
  return (
    <div className="withdraw-safe-verify-page">
      {_verifyStep[0] && _verifyStep[0] === PAYMENTPASSWORD && (
        <div className="withdraw-safe-verify-page_unit">
          {titleDom(t("KKbVbDLheqewlSvBE"))}
          <Box display="flex" alignItems="center" mt="7px">
            <Box
              w="8px"
              h="8px"
              bg="#ffffff"
              borderRadius={"8px"}
              mr="8px"
            ></Box>
            <MiliCodeInput
              ref={passWordInputRef}
              onChange={passwordInput}
              type="password"
              codeboxItemADDClassName="unit-input-box-style"
            />
          </Box>
        </div>
      )}
      {_verifyStep[1] && _verifyStep[1] === GOOGLEAUTH && (
        <div className="withdraw-safe-verify-page_unit verify-margin-top-100">
          {titleDom(t("wINMfXpJczhYZOnUXWp"))}
          <Box display="flex" alignItems="center" mt="7px">
            <Box
              w="8px"
              h="8px"
              bg="#ffffff"
              borderRadius={"8px"}
              mr="8px"
            ></Box>
            <MiliCodeInput
              ref={passWordInputRef}
              onChange={GAInput}
              type="password"
              codeboxItemADDClassName="unit-input-box-style"
            />
          </Box>
        </div>
      )}
      {_verifyStep[1] && _verifyStep[1] === VERIFYEMAIL && (
        <div className="withdraw-safe-verify-page_unit verify-margin-top-100">
          {titleDom(t("XGLKbSKPnzgwwZwEHu"))}
          <Box display="flex" alignItems="center" mt="20px">
            <Box
              w="8px"
              h="8px"
              bg="#ffffff"
              borderRadius={"8px"}
              mr="8px"
            ></Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              h="44px"
              flex={1}
            >
              <Input
                placeholder={t("XGLKbSKPnzgwwZwEHu")}
                onChange={emailInput}
              ></Input>
              <Button
                w={["130px", "150px"]}
                borderRadius="30px"
                backgroundColor={reSend ? "blue.100" : "#B3B4C8"}
                _hover={{ bg: reSend ? "blue.80" : "#B3B4C8" }}
                color="#FFFFFF"
                fontWeight={400}
                fontSize="14px"
                ml="10px"
                onClick={clickSendEmail}
              >
                {reSend ? (
                  <>{t("DPFrIOWnjfRpVQHkhKJ")}</>
                ) : (
                  <>
                    {t("fxHeYqwgtTcKAdCKPyVe")}(
                    {<Countdown onTimeout={handleTimeout} secondsInit="60" />})
                  </>
                )}
              </Button>
            </Box>
          </Box>
        </div>
      )}
      <Box w={["100%", "70%"]} m={[0, "auto"]}>
        <CommonBtnDom
          btnText={t("MutPBZAXykjaIurt")}
          marginTop="55px"
          noCanClick={!canClick()}
          clickEvent={clickSafeCheck}
        />
      </Box>
      <Text
        fontSize="12px"
        fontWeight={400}
        color="#7B7C8F"
        mt="20px"
        textAlign="center"
      >
        {t("QEaaELvQUvbfoIpje")}
      </Text>
    </div>
  );
};

export default WithdrawSafeVerify;
