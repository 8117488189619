import { useNavigate } from "react-router-dom";
import { useI18n } from "@/hooks/useI18n";
import {
  Box,
  Text,
  Image,
  ListItem,
  UnorderedList,
  Flex,
  Input,
  Button,
} from "@chakra-ui/react";
import copyToClipboard from "copy-to-clipboard";
import BXLoading from "@/components/ui/pay-loading";
import { toastTip } from "@/components/ui/toast";
import { useFiatDepositHooks } from "./hooks";
import CommonUnitStyle from "@/pages/deposit/component/commonUnitStyle";
import { updateBankInfo } from "@/state/user/reducer";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getDepositInfo } from "@/pages/deposit/api";
import { BIXIN_WALLET } from "@/helpers/constants/routeName";

const FiatDepositDetail = (props) => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentCurrency } = props;
  const {
    onSubmit,
    handleFile,
    viewStatus,
    uploadLoading,
    fileName,
    filePath,
    errorType,
  } = useFiatDepositHooks();
  const [depositData, setDepositData] = useState({
    receive_accounts_by_bank: [],
    min_amount: "",
  });

  const [accountNo, setAccountNo] = useState("");

  const bankList = depositData?.receive_accounts_by_bank || [];

  const bankSelectFlag = Boolean(bankList?.length);

  const bankInfo = [
    {
      label: "PlIgEPTbgrrwkSuHc",
      value: "ZA Bank Limited",
      id: 1,
      fontWeight: 600,
    },
    {
      label: "NqhjvGQWWHnmAzDH",
      value: "NewBX Treasures Limited",
      id: 2,
      fontWeight: 600,
    },
    {
      label: "QNEAoNoQfTpYIxJvjdM",
      value: "868016175887",
      id: 3,
      fontWeight: 600,
    },
    {
      label: "MhWCMBfnPEmyPhdBJC",
      value: "387",
      id: 4,
      fontWeight: 600,
    },
    {
      label: "MmdyZCvMXusVhfjsV",
      value: "747",
      id: 5,
      fontWeight: 600,
    },
    {
      label: "rcbvwVBVHsJazGKrQv",
      value: "AABLHKHH",
      id: 6,
      fontWeight: 600,
    },
    {
      label: "HsYTxsCouoyySItw",
      value: "Core F, Cyberport 3, 100 Cyberport Road, Hong Kong",
      id: 7,
      fontWeight: 400,
    },
  ];

  const titleStyle = {
    fontWeight: 400,
    fontSize: 14,
  };

  const btnFlag = !accountNo || !fileName || errorType;

  useEffect(() => {
    getDepositData();
  }, []);

  useEffect(() => {
    if (bankList?.length > 0) {
      setAccountNo(bankList?.[0].uuid);
    }
  }, [bankList]);

  const getDepositData = async () => {
    const { code, data, msg } = await getDepositInfo({
      currency: currentCurrency,
    });
    if (code === 0 && msg === "OK") {
      setDepositData(data);
    }
  };

  const clickCopy = (item) => {
    copyToClipboard(item.value);
    toastTip(t("gXbirwakdsaUKBglwK"), { type: "success" });
  };

  const addRightBtn = (type) => {
    dispatch(updateBankInfo({ bankInfo: {} }));
    const url =
      type === "add" ? "/trade/legal/tender/add" : "/trade/legal/tender";
    navigate(url);
  };

  const renderInitView = () => {
    return (
      <Box mt={"40px"}>
        <Flex flexDirection={"column"}>
          <Text fontSize={"16px"} color={"#000"} fontWeight={500}>
            {t("xWhLpCcRjLgSplng")}
          </Text>
          <Text fontSize={"14px"} color={"#010313"} mt={"5px"} fontWeight={400}>
            {t("nedDtIXJkIrvdDqe")}
          </Text>
          <Box position="relative">
            <Flex justifyContent={"space-between"} mt="20px">
              <Input
                w="80px"
                type="file"
                opacity={"0"}
                position={"absolute"}
                right="0"
                zIndex={"2"}
                cursor="pointer"
                fontSize="0"
                onChange={(e) => handleFile(e)}
              />
              <Input
                w={["220px", "310px"]}
                disabled
                borderRadius={"12px"}
                _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
                value={fileName}
              />
              <Button
                w="80px"
                h="30px"
                fontSize="12px"
                color="#fff"
                borderRadius={"20px"}
                bg="blue.100"
                textAlign={"center"}
                _hover={{ bg: "blue.80" }}
                mt="5px"
              >
                {t("NqgOQEXQaHsramCnbX")}
              </Button>
            </Flex>
            <Text
              fontSize="12px"
              color={filePath === "error" ? "red.100" : "t.80"}
              mt="10px"
            >
              {t("ZAhuzRDlLUTXhKrhhx")}
            </Text>
          </Box>
        </Flex>
        <Text color={"#D04457"} mt="20px" textAlign={"center"}>
          {errorType === "empty_error" ? t("wZZutIhpaOoppafvh") : ""}
        </Text>
        {/* 底部按钮 */}
        <Flex justifyContent={"center"} mt="40px">
          <Button
            fontSize="14px"
            padding="0 30px"
            w={["60%", "310px"]}
            bg={btnFlag ? "blue.50" : "blue.100"}
            _hover={{ bg: btnFlag ? "" : "blue.80" }}
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              if (btnFlag) {
                return false;
              }
              onSubmit({
                accountNo,
                currentCurrency,
              });
            }}
          >
            {t("QFbYEdxUgXzRwcPFQDis")}
          </Button>
        </Flex>
      </Box>
    );
  };

  const renderPendingView = () => {
    return (
      <Box
        w={["100%", "400px"]}
        margin={"0 auto"}
        mt={"60px"}
        borderRadius={"12px"}
        textAlign={"center"}
        fontWeight={"bold"}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src={`../../wwwsite/images/kyc/certificate/pending.svg`}
            width={"40px"}
            height={"40px"}
            alt=""
          />
          <Text
            fontSize={"24px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {t("KzFzOytWXScqDRKFwg")}
          </Text>
          <Text
            fontSize={"14px"}
            fontWeight={400}
            color={"#010313"}
            mt={"10px"}
          >
            {t("YXrqAGUqprWfOCeiSGX")}
          </Text>
        </Flex>
        <Button
          mt="40px"
          fontSize="14px"
          padding="0 30px"
          w="100px"
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => navigate(BIXIN_WALLET)}
        >
          {t("FrzWGSiDSDzTfNgZH")}
        </Button>
      </Box>
    );
  };

  return (
    <Box w={"100%"} margin="0 auto">
      {viewStatus === "INIT" && (
        <>
          {bankSelectFlag && (
            <CommonUnitStyle
              title={t("cCtaFICzFmzJltYPL")}
              showTitleRightBtn={true}
              TitleRightBtnText={t("wLQWZKMRgmTJTSudW")}
              leftPointVisible={false}
              leftLineVisible={false}
              titleStyle={titleStyle}
              rightBtnEvent={() => addRightBtn("list")}
              selectContent={bankList?.map((cur) => ({
                ...cur,
                selected_key: cur.uuid,
              }))}
              selectedValue={accountNo}
              selectedEvent={(value) => setAccountNo(value)}
              formatSelect={(item) => {
                return (
                  <>
                    <span>{item.name}</span>
                    <span>（{item.account_no}）</span>
                  </>
                );
              }}
            />
          )}
          {!bankSelectFlag && (
            <CommonUnitStyle
              title={t("cCtaFICzFmzJltYPL")}
              showCenterAddBtn={true}
              centerAddBtnText={t("SVpHROUKzBJsjSVHMK")}
              leftPointVisible={false}
              leftLineVisible={false}
              centerAddBtnEvent={() => addRightBtn("add")}
              titleStyle={titleStyle}
            />
          )}
          <Text
            ml={"8px"}
            mt={"40px"}
            fontWeight={500}
            fontSize={16}
            color="#010313"
          >
            {t("UMTZPjOpYZuhdhDbBVO").replace("$s", currentCurrency)}
          </Text>
          <Box
            fontSize={12}
            fontWeight={400}
            mt={"10px"}
            p={"15px"}
            borderRadius={"12px"}
            border={"1px solid #E3E4F1"}
          >
            <UnorderedList
              mt={"10px"}
              gap={"10px"}
              display={"flex"}
              flexDirection={"column"}
            >
              <ListItem
                style={{ listStyle: "unset" }}
                color="#010313"
                fontWeight={400}
              >
                {t("lsdjJQPYPEOcAzVOth")}
              </ListItem>
              <ListItem
                style={{ listStyle: "unset" }}
                color="#010313"
                fontWeight={400}
              >
                {t("rhXOjsQATKgJNizniW")}
              </ListItem>
              <ListItem
                style={{ listStyle: "unset" }}
                color="#010313"
                fontWeight={400}
              >
                {t("pKuaKUWJHuajbExMUs")}
              </ListItem>
              <ListItem
                style={{ listStyle: "unset" }}
                color="#010313"
                fontWeight={400}
              >
                {t("nkcDnQvbZpDfYMepAc")}
              </ListItem>
            </UnorderedList>
          </Box>
          <Box
            mt={"20px"}
            bg="#F5F5F7"
            p={"15px"}
            borderRadius={"12px"}
            color="#010313"
          >
            {bankInfo.map((cur) => {
              return (
                <Flex
                  alignItems={cur.id === 7 ? "flex-start" : "center"}
                  justifyContent={"space-between"}
                  key={cur.id}
                  p={"15px"}
                >
                  <Text maxWidth={"108px"} fontSize={14} fontWeight={400}>
                    {t(cur.label)}
                  </Text>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                  >
                    <Text
                      maxWidth={"205px"}
                      textAlign={"right"}
                      fontWeight={cur.fontWeight}
                    >
                      {cur.value}
                    </Text>
                    <Image
                      cursor={"pointer"}
                      src="../../wwwsite/images/copy.svg"
                      alt={cur.value}
                      onClick={() => clickCopy(cur)}
                    />
                  </Flex>
                </Flex>
              );
            })}
          </Box>
          <Text mt={"10px"} fontSize={12} fontWeight={400} color={"#010313"}>
            {t("iBQIPIJCNFHBCeRIvm")}&nbsp;{depositData?.min_amount}{" "}
            {currentCurrency}
          </Text>
        </>
      )}
      {viewStatus === "INIT" && renderInitView()}
      {viewStatus === "PENDING" && renderPendingView()}
      {uploadLoading && <BXLoading />}
    </Box>
  );
};

export default FiatDepositDetail;
