import { useEffect, useMemo, useState } from "react";
import { useI18n } from "@/hooks/useI18n";
import { toastTip } from "@/components/ui/toast";
import {
  getKycPerosonalInfoApi,
  postKycPerosonalInfoApi,
  getS3PutUrlApi,
} from "./api";
import mockJson from "./mock.json";
import { useAppSelector } from "@/state/hooks";
import { S3_AWS_KMS_KEY_ID } from "@/config";
import axios from "axios";
import { cloneDeep } from "lodash";

const usePersonalHooks = ({ sanctionsCountryList, noCompliantCountryList }) => {
  const { t } = useI18n();

  const [inputParams, setInputParams] = useState({
    first_name: "", // 名
    last_name: "", // 姓
    former_name: "", // 曾用名
    gender: "",
    id_type: "", // 证件类型
    id_number: "",
    birth_place: "", // 出生地
    // nationality: "", // 国籍
    country: "", // 证件发行地
    birth_date: "", // 出生日期
    occupation: "", // 职业
    occupation_industry: "", // 就职行业
    employer_name: "", // 就职公司名
    transaction_purpose: {}, // 交易目的
    products_services: {}, //所需产品服务
    wealth_source: {}, //财富来源
    net_worth: {}, // 净值
    fund_source: {}, // 资金来源
    annual_income: {}, // 年收入
    monthly_turnover: {}, // 月交易额
    monthly_frequency: {}, // 月交易频次
    pep: {}, // 政治人物
    pep_relation: {}, // 政治人物关系
    documents: [], // pdf上传
    has_other_country_id_card: "", // 是否有其他国家有效身份证
    other_country: "", // 其他国家
    other_country_card_type: "", // 其他国家/地区 证件类型
    other_country_card_front_file: [], // 其他国家/地区 正面照
    other_country_card_opposite_file: [], // 其他国家/地区 反面照

    // ...mockJson,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const kycInfo = useAppSelector((state) => state.kyc.kycInfo);
  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading
  const [crsFileInfo, setCrsFileInfo] = useState({});
  const [areaScanError, setAreaScanError] = useState(false); // 证件发行地 制裁区错误
  const [areaCompliantError, setAreaCompliantError] = useState(false); // 证件发行地非合规错误
  const [otherCountryAreaError, setOtherCountryAreaError] = useState(false); // 其他国家 不为合规区 错误
  const [otherCountryIdCardFrontFileInfo, setOtherIdFrontFileInfo] = useState(
    {}
  ); //  正面file
  const [otherCountryIdCardOppositetFileInfo, setOtherIdOppositetFileInfo] =
    useState({}); //  反面file
  // 获取 要在APPROVED后才可以
  const getKycPerosonalInfo = async () => {
    const res = await getKycPerosonalInfoApi();
    if (res.code === 0) {
      setInputParams(Object.assign({}, inputParams, { ...res.data }));
    }
  };

  // 提交
  const onSumbit = async (setStep) => {
    if (canSubmit()) {
      const _params = cloneDeep(inputParams);
      // 如果是香港永久性居民身份证, 将other字段 删除
      // 如果是护照类型 且 是合规地区 同样将other..字段删除
      if (_params.id_type === "ID_CARD") {
        delete _params.has_other_country_id_card;
        delete _params.other_country;
        delete _params.other_country_card_type;
        delete _params.other_country_card_front_file;
        delete _params.other_country_card_opposite_file;
      } else if (
        _params.id_type === "PASSPORT" &&
        sanctionsCountryList?.indexOf(_params.country) < 0 &&
        noCompliantCountryList?.indexOf(_params.country) < 0
      ) {
        delete _params.has_other_country_id_card;
        delete _params.other_country;
        delete _params.other_country_card_type;
        delete _params.other_country_card_front_file;
        delete _params.other_country_card_opposite_file;
      }

      const res = await postKycPerosonalInfoApi(_params);
      if (res.code === 0) {
        // 提交成功
        toastTip(t("nodIQIicrpcVpCqYey"), { type: "success" });
        setStep(2);
      }
    }
  };

  useEffect(() => {
    if (kycInfo?.length > 0 && kycInfo[0].status !== "REQUIRED") {
      getKycPerosonalInfo();
    }
  }, [kycInfo]);

  const memoGender = useMemo(() => {
    return [
      {
        label: t("iqCHKgJKHatuZHiXEFm"),
        value: "Male",
      },
      {
        label: t("dHIzNCfEthjGsjpMsfJN"),
        value: "Female",
      },
    ];
  }, [t]);

  // 证件类型
  const memoCardType = useMemo(() => {
    return [
      {
        label: t("MTiCVGmtBjEPWzsw"),
        value: "ID_CARD",
      },
      {
        label: t("bRDTmhggLlBbrbdtfhM"),
        value: "PASSPORT",
      },
    ];
  }, [t]);

  // 其他国家/地区身份证件
  const memoHasOtherCountryCard = useMemo(() => {
    return [
      {
        label: t("NltNaOnxuMeUAbBeZ"),
        value: 1,
      },
      {
        label: t("HmXYHUAhkQnrZSfKu"),
        value: 2,
      },
    ];
  }, [t]);

  // 其他国家/地区的证件类型
  const memoOtherCountryCardType = useMemo(() => {
    return [
      {
        label: t("TeRrrjzubYVYnBhsdpD"),
        value: "FOREIGN_RESIDENT_ID_CARD",
      },
      {
        label: t("xwQqYnxnxmeJPqRlQ"),
        value: "VISA",
      },
    ];
  }, [t]);

  // 交易目的
  const memoTradeTargets = useMemo(() => {
    return [
      {
        label: t("JLIvWEpTgjlkpsZBM"),
        value: 1,
      },
      {
        label: t("UfkUhznLfeqzZyTdAebx"),
        value: 2,
      },
      {
        label: t("FxzVGfjClbeUZeEero"),
        value: 3,
      },
    ];
  }, [t]);

  const memoNeedProdServices = useMemo(() => {
    return [
      {
        label: t("ytFXzDnzPIGUhOOClJ"),
        value: 1,
      },
      // {
      //   label: t("dWHEObNDvkCjTnlvH"),
      //   value: 2,
      // },
      {
        label: t("FxzVGfjClbeUZeEero"),
        value: 3,
      },
    ];
  }, [t]);

  // PEP
  const memoPEP = useMemo(() => {
    return [
      {
        label: t("NltNaOnxuMeUAbBeZ"),
        value: 1,
      },
      {
        label: t("HmXYHUAhkQnrZSfKu"),
        value: 2,
      },
    ];
  }, [t]);

  const memoPEPContact = useMemo(() => {
    return [
      {
        label: t("NltNaOnxuMeUAbBeZ"),
        value: 1,
      },
      {
        label: t("HmXYHUAhkQnrZSfKu"),
        value: 2,
      },
    ];
  }, [t]);
  // 财富来源
  const memoWealthSource = useMemo(() => {
    return [
      {
        label: t("gGrAIKLFJuNxbjhnZz"),
        value: 1,
      },
      {
        label: t("trJoqkSnMVAvbRgAY"),
        value: 2,
      },
      {
        label: t("CyNhoDlSuMvcxydtF"),
        value: 3,
      },
      {
        label: t("dpvvLNDzKxLZIuixW"),
        value: 4,
      },
      {
        label: t("cYSzpXPrPUQxaAGTihK"),
        value: 5,
      },
      {
        label: t("KSyKpOAjkLSpCGns"),
        value: 6,
      },
    ];
  }, [t]);

  // 预估净值
  const memoEstimatedNetWorth = useMemo(() => {
    return [
      {
        label: t("UqNGOztCBezyeAgI"),
        value: 1,
      },
      {
        label: t("PVcSgRlruKdhLYAvMUG"),
        value: 2,
      },
      {
        label: t("BIVIQEHEPCzGuQpNKSR"),
        value: 3,
      },
      {
        label: t("EqpPTPZsKjTArTFVgWp"),
        value: 4,
      },
      {
        label: t("oiSDCKooPzMznfGteAG"),
        value: 5,
      },
    ];
  }, [t]);

  // 资金来源
  const memoFundSource = useMemo(() => {
    return [
      {
        label: t("aaKDUKbsmRiynWiqlS"),
        value: 1,
      },
      {
        label: t("JLIvWEpTgjlkpsZBM"),
        value: 2,
      },
      {
        label: t("eDeADqoJRnEUGotYxzY"),
        value: 3,
      },
      {
        label: t("CDwWwMbhHZXYRMZHKnpO"),
        value: 4,
      },
      {
        label: t("KSyKpOAjkLSpCGns"),
        value: 5,
      },
    ];
  }, [t]);

  //  年收入
  const memoAnnualIncome = useMemo(() => {
    return [
      {
        label: t("qqXTaCjhvvMrmeJpPG"),
        value: 1,
      },
      {
        label: t("OUDeIeQIjCliBQIHe"),
        value: 2,
      },
      {
        label: t("qipDilqNVyHfNJVyF"),
        value: 3,
      },
      {
        label: t("CuzRRZhhkOmuQgeVt"),
        value: 4,
      },
      {
        label: t("VoqaSnQNOfXstSBxca"),
        value: 5,
      },
    ];
  }, [t]);

  // 预估月均交易额
  const memoAnticipatedMonthlyTxAmount = useMemo(() => {
    return [
      {
        label: t("tdxlCkYgrpQOxYYJZiF"),
        value: 1,
      },
      {
        label: t("RZiGRHwDUpYgJcRLRnxa"),
        value: 2,
      },
      {
        label: t("aXbxDDurboZtvnPno"),
        value: 3,
      },
      {
        label: t("OUDeIeQIjCliBQIHe"),
        value: 4,
      },
      {
        label: t("AbrPzuZLAZwUuIBnbx"),
        value: 5,
      },
      {
        label: t("pucEyENFLLeSzsJUK"),
        value: 6,
      },
    ];
  }, [t]);

  // 预估月均交易频率
  const memoAnticipatedMonthlyTxFrequency = useMemo(() => {
    return [
      {
        label: t("YWCMpmGuparutCzjXpp"),
        value: 1,
      },
      {
        label: t("aCYhhkYhhIrzUQvFB"),
        value: 2,
      },
      {
        label: t("sVTrHBZPprcyXLgcE"),
        value: 3,
      },
      {
        label: t("oGHfMwDNMhRcKszx"),
        value: 4,
      },
    ];
  }, [t]);

  // 单选
  const handleSignleCheck = (key, value) => {};

  // 多选
  const handleMultipleCheck = (mulValue, key) => {
    setInputParams((prev) => {
      const { value } = mulValue;
      const currObj = inputParams[key];
      if (!currObj?.options) {
        return Object.assign({}, prev, {
          [key]: {
            ...currObj,
            options: [mulValue],
          },
        });
      } else {
        const opts = [...currObj?.options];
        const findIndex = opts.findIndex((d) => d.value === value);
        if (findIndex > -1) {
          // 存在则反选
          opts.splice(findIndex, 1);
        } else {
          // 不存在则加入
          opts.push(mulValue);
        }
        return Object.assign({}, prev, {
          [key]: {
            ...currObj,
            options: opts,
          },
        });
      }
    });
  };

  const inputOnChange = (value, key) => {
    setInputParams((prev) => {
      const SEPECIAL_KEYS = [
        "transaction_purpose",
        "products_services",
        "wealth_source",
        "fund_source",
      ];
      if (SEPECIAL_KEYS.indexOf(key) < 0) {
        const obj = { [key]: value };
        return Object.assign({}, prev, obj);
      } else {
        // 增加description
        const newObj = prev[key];
        newObj.description = value;
        return Object.assign({}, prev, {
          [key]: newObj,
        });
      }
    });
  };

  // 判断能不能去下一步
  const toNextPage = () => {
    const FIRST_PAGE_KEYS = [
      "first_name", // 名
      "last_name", // 姓
      "gender",
      "id_type",
      "id_number",
      "country",
      // "nationality",
      "birth_place",
      "birth_date",
      "occupation",
      "occupation_industry",
      "employer_name",
    ];

    // 护照 + 制裁区
    if (inputParams?.id_type === "PASSPORT" && areaScanError) {
      toastTip(t("mzWsFtJNWfexuuUgYctv"), { type: "error" });
      return;
    }

    // 护照 + 非合规区 + 没有其他其他国家有效身份证
    if (
      inputParams?.id_type === "PASSPORT" &&
      areaCompliantError &&
      (Number(inputParams?.has_other_country_id_card?.value) === 2 ||
        !inputParams?.has_other_country_id_card)
    ) {
      toastTip(t("mzWsFtJNWfexuuUgYctv"), { type: "error" });
      return;
    }

    // 护照 + 非合规区 + 有其他其他国家有效身份证 + 没有选其他国家
    if (
      inputParams?.id_type === "PASSPORT" &&
      areaCompliantError &&
      Number(inputParams?.has_other_country_id_card?.value) === 1 &&
      !inputParams?.other_country
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return;
    }

    // 护照 + 非合规区 + 有其他其他国家有效身份证 + 其他国家地区在制裁区、非合规区
    if (
      inputParams?.id_type === "PASSPORT" &&
      areaCompliantError &&
      Number(inputParams?.has_other_country_id_card?.value) === 1 &&
      inputParams.other_country &&
      otherCountryAreaError
    ) {
      toastTip(t("mzWsFtJNWfexuuUgYctv"), { type: "error" });
      return;
    }

    // 护照 + 非合规区 + 有其他其他国家有效身份证 + 其他国家地区在合规区 + （!没有选证件类型 ｜｜ ！ 没有上传正面文件信息）
    if (
      inputParams?.id_type === "PASSPORT" &&
      areaCompliantError &&
      Number(inputParams?.has_other_country_id_card?.value) === 1 &&
      !otherCountryAreaError &&
      (!inputParams?.other_country_card_type ||
        !inputParams?.other_country_card_front_file?.[0]?.document_path)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return;
    }

    if (FIRST_PAGE_KEYS.some((d) => !inputParams[d])) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return;
    }

    const transaction_purpose_options =
      inputParams["transaction_purpose"]?.options || [];
    if (transaction_purpose_options.length === 0) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return;
    }

    const products_services_options =
      inputParams["products_services"]?.options || [];
    const products_services_desc =
      inputParams["products_services"]?.description;

    if (
      products_services_options.length === 0 ||
      (products_services_options.findIndex((d) => d.value === 3) > -1 &&
        !products_services_desc)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return;
    }

    setCurrentPage(2);
  };

  // 判断能否提交
  const canSubmit = () => {
    const wealth_source_options = inputParams["wealth_source"]?.options || [];
    const wealth_source_desc = inputParams["wealth_source"]?.description;
    const net_worth_options = inputParams["net_worth"]?.options || [];

    const fund_source_options = inputParams["fund_source"]?.options || [];
    const fund_source_desc = inputParams["fund_source"]?.description;

    const annual_income_options = inputParams["annual_income"]?.options || [];

    const monthly_turnover_options =
      inputParams["monthly_turnover"]?.options || [];

    const monthly_frequency_options =
      inputParams["monthly_frequency"]?.options || [];
    const monthly_frequency_desc =
      inputParams["monthly_frequency"]?.description;

    const pep_options = inputParams["pep"]?.options || [];
    const pep_position = inputParams["pep"]?.position;
    const pep_position_time = inputParams["pep"]?.position_time;

    const pep_relation_options = inputParams["pep_relation"]?.options || [];
    const pep_relation_position = inputParams["pep_relation"]?.position;
    const pep_relation_position_time =
      inputParams["pep_relation"]?.position_time;
    const pep_relation_desc = inputParams["pep_relation"]?.description;

    const documents = inputParams["documents"];

    if (
      wealth_source_options.length === 0 ||
      (wealth_source_options.findIndex((d) => d.value === 6) > -1 &&
        !wealth_source_desc)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }

    if (net_worth_options.length === 0) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }

    if (
      fund_source_options.length === 0 ||
      (fund_source_options.findIndex((d) => d.value === 5) > -1 &&
        !fund_source_desc)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }

    if (annual_income_options.length === 0) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }

    if (monthly_turnover_options.length === 0) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }

    if (
      monthly_frequency_options.length === 0 ||
      (monthly_frequency_options.findIndex((d) => d.value === 1) > -1 &&
        !monthly_frequency_desc)
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }

    if (
      pep_options.length === 0 ||
      (pep_options.findIndex((d) => d.value === 1) > -1 &&
        (!pep_position || !pep_position_time))
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }

    if (
      pep_relation_options.length === 0 ||
      (pep_relation_options.findIndex((d) => d.value === 1) > -1 &&
        (!pep_relation_position ||
          !pep_relation_position_time ||
          !pep_relation_desc))
    ) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }

    if (documents.length === 0) {
      toastTip(t("QZCxVURndUpletXFScR"), { type: "error" });
      return false;
    }

    return true;
  };

  const uploadAssetsFn = async (file, fileTypeSuffix) => {
    setUploadLoading(true);
    const res = await getS3PutUrlApi({
      prefix: "information",
      file_type: fileTypeSuffix,
    });
    if (res.code === 0) {
      const putUrl = res?.data?.put_url;
      const filePath = res?.data?.file_path;
      axios({
        url: putUrl,
        method: "put",
        headers: {
          "X-Amz-Server-Side-Encryption": "aws:kms",
          "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
        },
        data: file,
      }).then((uploadRes) => {
        if (uploadRes.status === 200) {
          setUploadLoading(false);
          setCrsFileInfo((prev) => {
            return Object.assign({}, prev, {
              filePath: filePath,
            });
          });
          setInputParams((prev) => {
            // const currObj = prev.pep;
            return Object.assign({}, prev, {
              documents: [
                {
                  document_type: "pdf",
                  document_type_comment: "fatca_crs",
                  document_path: filePath,
                },
              ],
              // pep: {
              //   ...currObj,
              //   position: e.target.value,
              // },
            });
          });
        }
      });
    }
  };

  const handleUpLoadCRSFile = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { size, name } = file;
      const fileTypeSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (fileTypeSuffix === "pdf" && size / 1000000 < 15) {
        uploadAssetsFn(file, fileTypeSuffix);
        setCrsFileInfo({
          fileName: name,
        });
      } else {
        setCrsFileInfo({ error: true, fileName: name });
      }
    }
  };

  const uploadOtherCountryIdCardFileFn = async (file, fileTypeSuffix, type) => {
    setUploadLoading(true);
    const res = await getS3PutUrlApi({
      prefix: "information",
      file_type: fileTypeSuffix,
    });
    if (res.code === 0) {
      const putUrl = res?.data?.put_url;
      const filePath = res?.data?.file_path;
      axios({
        url: putUrl,
        method: "put",
        headers: {
          "X-Amz-Server-Side-Encryption": "aws:kms",
          "X-Amz-Server-Side-Encryption-Aws-Kms-Key-Id": S3_AWS_KMS_KEY_ID,
        },
        data: file,
      }).then((uploadRes) => {
        if (uploadRes.status === 200) {
          setUploadLoading(false);
          if (type === "front") {
            setOtherIdFrontFileInfo((prev) => {
              return Object.assign({}, prev, {
                filePath: filePath,
              });
            });
            setInputParams((prev) => {
              return Object.assign({}, prev, {
                other_country_card_front_file: [
                  {
                    document_type: fileTypeSuffix,
                    document_type_comment: "other_id_front",
                    document_path: filePath,
                  },
                ],
              });
            });
          } else {
            setOtherIdOppositetFileInfo((prev) => {
              return Object.assign({}, prev, {
                filePath: filePath,
              });
            });
            setInputParams((prev) => {
              return Object.assign({}, prev, {
                other_country_card_opposite_file: [
                  {
                    document_type: fileTypeSuffix,
                    document_type_comment: "other_id_back",
                    document_path: filePath,
                  },
                ],
              });
            });
          }
        }
      });
    }
  };

  const handleUploadOtherCountryIdCardFile = async (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const { size, name } = file;
      const fileTypeSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (
        (fileTypeSuffix === "pdf" || fileTypeSuffix === "jpg") &&
        size / 1000000 < 5
      ) {
        uploadOtherCountryIdCardFileFn(file, fileTypeSuffix, type);
        if (type === "front") {
          setOtherIdFrontFileInfo({
            fileName: name,
          });
        } else {
          setOtherIdOppositetFileInfo({
            fileName: name,
          });
        }
      } else {
        if (type === "front") {
          setOtherIdFrontFileInfo({ error: true, fileName: name });
        } else {
          setOtherIdOppositetFileInfo({ error: true, fileName: name });
        }
      }
    }
  };

  return {
    memoGender,
    memoCardType,
    inputParams,
    setInputParams,
    memoTradeTargets,
    memoNeedProdServices,
    memoPEP,
    memoWealthSource,
    memoEstimatedNetWorth,
    memoFundSource,
    memoAnnualIncome,
    memoAnticipatedMonthlyTxFrequency,
    memoPEPContact,
    handleSignleCheck,
    handleMultipleCheck,
    currentPage,
    setCurrentPage,
    inputOnChange,
    toNextPage,
    onSumbit,
    memoAnticipatedMonthlyTxAmount,
    handleUpLoadCRSFile,
    crsFileInfo,
    uploadLoading,
    memoHasOtherCountryCard,
    memoOtherCountryCardType,
    areaScanError,
    areaCompliantError,
    setAreaCompliantError,
    setAreaScanError,
    otherCountryAreaError,
    setOtherCountryAreaError,
    handleUploadOtherCountryIdCardFile,
    otherCountryIdCardFrontFileInfo,
    otherCountryIdCardOppositetFileInfo,
  };
};

export default usePersonalHooks;
