import ModalComponent from "@/components/ui/modal";
import { Flex, Text, Box } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { BXButton } from "@/styles";

const ConfirmModal = ({ show, onOk, onCancel }) => {
  const { t } = useI18n();

  return (
    <ModalComponent isShow={show} style={{ zIndx: 99 }}>
      <Box px={"33px"} py={"50px"}>
        <Flex justifyContent={"center"}>
          <Text color="#010313" fontSize={16} fontWeight={600}>
            {t("npqmwWmfuEsNrfQJt")}
          </Text>
        </Flex>
        <Flex mt={"20px"} justifyContent={"space-between"}>
          <BXButton
            className="cancel"
            w={"160px"}
            flex={1}
            onClick={() => {
              onCancel && onCancel();
            }}
          >
            {t("UNForxvjDLCZdGrmWkX")}
          </BXButton>
          <BXButton
            ml={"10px"}
            w={"200px"}
            style={{ flex: 1.8 }}
            onClick={() => {
              onOk && onOk();
            }}
          >
            {t("AQrCocFqoKoYieUnT")}
          </BXButton>
        </Flex>
      </Box>
    </ModalComponent>
  );
};

export default ConfirmModal;
