// 数据分割
const dataDivision = (dataValue, decollator = ',') => {
    const spacingNumber = 3;
    if (dataValue.indexOf('.') !== -1) {
        const getArr = dataValue.split('.');
        let frontValue = getArr[0];
        let behind = getArr[1];
        if (frontValue.length > 3) {
        frontValue = splitStr(frontValue, decollator, spacingNumber);
        }
        if (behind.length > 3) {
        behind = splitStr(behind, decollator, spacingNumber);
        }
        return `${frontValue}.${behind}`;
    } else {
        let getStr = dataValue;
        if (getStr.length > 3) {
        getStr = splitStr(getStr, decollator, spacingNumber);
        }
        return getStr;
    }
};

/**
 * 批量插入字符
 * @param {*} stringValue
 * @param {*} splitStr
 * @param {*} spaceNumber
 * @returns
 */
const splitStr = (stringValue, splitStr, spaceNumber) => {
    const getValue = stringValue;
    let beginValue = 0;
    for (let j = 0; j < getValue.length; j++) {
        if (j !== 0 && j % spaceNumber === 0) {
        if (Number(j) !== Number(spaceNumber)) {
            beginValue = beginValue + 1;
        }
        stringValue = insertStr(stringValue, j + beginValue, splitStr);
        }
    }
    return stringValue;
};

/**
 * 在字符串特定位置插入值
 * @param {*} soure 原字符
 * @param {*} start 将插入字符的位置
 * @param {*} newStr 要插入的字符
 * @returns
 */
const insertStr = (soure, start, newStr) => {
    return soure.slice(0, start) + newStr + soure.slice(start);
};

const jsUuid = () => {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 32; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23];
    var uuid = s.join("");
    return uuid;
}

export { dataDivision, jsUuid }