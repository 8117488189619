import { useI18n } from "@/hooks/useI18n";
import { Text, Flex, Image } from "@chakra-ui/react";
import useHooks from "./hooks";
import BXLoading from "@/components/ui/pay-loading";
import { BXButton } from "@/styles";
import PersonalResult from "../../common/PersonResult";
import { useEffect } from "react";
import BXUpload from "@/components/ui/BXUpload";
import { RISK_LEVEL } from "@/constants/kyc";

const PersonalInfoNoChange = ({ canPeriodic, reviewUUID, riskLevel }) => {
  const { t } = useI18n();

  const {
    richSourceList,
    hanleFileUpload,
    uploadLoading,
    canSubmit,
    onSubmit,
    pageStatus,
    PAGE_STATUS,
  } = useHooks({ reviewUUID });

  return (
    <>
      {pageStatus === PAGE_STATUS.INIT &&
        riskLevel === RISK_LEVEL.HIGH &&
        canPeriodic && (
          <>
            <Flex
              flexDirection={"column"}
              style={{ backgroundColor: "#fff" }}
              height={"auto"}
              minHeight={"100vh"}
              width={["calc(100% - 50px)", "400px"]}
              overflow={"hidden"}
              margin={"0 auto"}
              mt={"70px"}
              color={"#010313"}
              fontSize={"14px"}
              alignItems={"center"}
            >
              <Image
                src="../../../wwwsite/images/kyc/success.svg"
                alt=""
                w={"40px"}
                h={"40px"}
              />

              <Text fontSize={"24px"} fontWeight={600} mt={"10px"}>
                {t("JJfMolWuWCFgrDrUiz")}
              </Text>
              <Text mt={"10px"}>{t("plHTmWdGncJPnVBJuI")}</Text>
              <Flex w={"100%"} flexDirection={"column"} mt={"30px"}>
                <Text size={"16px"} fontWeight={700}>
                  {t("wcfFqisAqLRlhbKdJk")}
                </Text>
                <Text
                  size={"14px"}
                  fontWeight={700}
                  mt={"10px"}
                  color={"#7B7C8F"}
                >
                  {t("wcJAFtgivjbkVSdTC")}
                </Text>
                {richSourceList.map((d, i) => {
                  return (
                    <BXUpload
                      key={i}
                      label={d.label}
                      handleUploadChange={(e) => hanleFileUpload(e, i)}
                      error={d.error}
                      fileName={d.fileName}
                      style={{ marginTop: i === 0 ? "20px" : "30px" }}
                    ></BXUpload>
                  );
                })}

                <BXButton
                  w={["100%", "200px"]}
                  cursor={"pointer"}
                  margin={"0 auto"}
                  mt={"40px"}
                  className={!canSubmit ? "disabled" : ""}
                  onClick={() => {
                    if (canSubmit) {
                      onSubmit();
                    }
                  }}
                >
                  {t("QFbYEdxUgXzRwcPFQDis")}
                </BXButton>
              </Flex>
            </Flex>
            {uploadLoading && <BXLoading />}
          </>
        )}
      {pageStatus === PAGE_STATUS.RESULT &&
        riskLevel === RISK_LEVEL.HIGH &&
        canPeriodic && <PersonalResult isUpdate={false} />}
      {(riskLevel !== RISK_LEVEL.HIGH || !canPeriodic) && (
        <PersonalResult
          isUpdate={canPeriodic ? true : false}
          reviewUUID={reviewUUID}
          canPeriodic={canPeriodic}
        />
      )}
    </>
  );
};

export default PersonalInfoNoChange;
