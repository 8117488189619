import { useMedia } from "@/hooks/useMedia";
import PageWrap from "@/components/PageWrap";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import AddBtn from "./components/AddBtn";
import BackBtn from "./components/BackBtn";
import LegalTenderList from "./legalTenderList";

const LegalTender = () => {
    const { isMobile } = useMedia();
    const { t } = useI18n();

    return (
        <PageWrap>
            <Box
                style={{ backgroundColor: "#fff" }}
                height={"auto"}
                minHeight={"100vh"}
                overflow={"hidden"}
                p={isMobile ? '0 30px' : '30px'}
            >
                <Box w={["100%", "1200px"]} margin={"0 auto"}>
                    <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        flexDirection={isMobile ? "column" : "row"}
                    >
                        {!isMobile ? (
                            <>
                                <BackBtn />
                                <Text fontSize={24} fontWeight={600}>
                                    {t("tibiqQyzvztLFMGQ")}
                                </Text>
                                <AddBtn />
                            </>
                        ) : (
                            <>
                                <Flex w={"100%"} justifyContent={"space-between"}>
                                    <BackBtn />
                                </Flex>
                                <Flex
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    w={"100%"}
                                >
                                    <Text fontSize={24} fontWeight={600}>
                                        {t("tibiqQyzvztLFMGQ")}
                                    </Text>
                                    <AddBtn />
                                </Flex>
                            </>
                        )}
                    </Flex>
                    <Box mt={["30px", "80px"]} paddingBottom={"40px"}>
                        <LegalTenderList />
                    </Box>
                </Box>
            </Box>
        </PageWrap>
    );
};

export default LegalTender;
