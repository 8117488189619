import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useI18n = () => {
  const { t, i18n } = useTranslation();
  const overrideT = useCallback(
    (key, ...options) => {
      let _key = `${key}.message`;
      if (_key === t(_key, ...options)) {
        return key;
      }
      return t(_key, ...options);
    },
    [t]
  );
  const changeLang = useCallback(
    (lang) => {
      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  return {
    changeLang,
    locale: i18n.language,
    t: overrideT,
  };
};
