import ChangePage from "./ChangePage";
import PersonResult from "../../common/PersonResult";

const PersonalInfoChangePage = ({ riskLevel, canPeriodic, reviewUUID }) => {
  return (
    <>
      {canPeriodic && (
        <ChangePage riskLevel={riskLevel} reviewUUID={reviewUUID} />
      )}
      {!canPeriodic && <PersonResult />}
    </>
  );
};

export default PersonalInfoChangePage;
