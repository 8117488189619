import { Box, Flex, Text, Input, Button } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BIXIN_ACCOUNTMANAGER } from "@/helpers/constants/routeName";
import { postResetPayPwd } from "./api";
import { throttle } from "lodash";
import { toastTip } from "@/components/ui/toast";
import { useI18n } from "@/hooks/useI18n";
const ResetPwd = () => {
  const { t } = useI18n();
  const [payPwd, setPayPwd] = useState("");
  const [rePayPwd, setRePayPwd] = useState("");
  const [isInvalidPwd, setIsInvalidPwd] = useState(false);
  const [isInvalidRePwd, setIsInvalidRePwd] = useState(false);
  const [nextError, setNextError] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line no-useless-escape
  const payPwdRule = /^\d{6}$/;

  const pwdChange = (event) => {
    const pwd = event.target.value;
    if (payPwdRule.test(pwd)) {
      setIsInvalidPwd(false);
      setPayPwd(pwd);
    } else {
      setIsInvalidPwd(true);
      setPayPwd("");
    }
  };

  const rePwdChange = (event) => {
    const rePwd = event.target.value;
    if (rePwd === payPwd) {
      setPayPwd(rePwd);
      setRePayPwd(rePwd);
      setIsInvalidRePwd(false);
    } else {
      setIsInvalidRePwd(true);
      setRePayPwd("");
    }
  };
  const submitResetPwd = throttle(async () => {
    //调接口重新设置密码
    if (!payPwd) {
      return setIsInvalidPwd(true);
    }
    if (!rePayPwd) {
      return setIsInvalidRePwd(true);
    }
    if (payPwd !== rePayPwd) {
      return setIsInvalidRePwd(true);
    }
    setNextError(true);
    const res = await postResetPayPwd({
      payment_password:payPwd
    });

    if (res.code === 0) {
      setNextError(false);
      toastTip(t("MThZOMtyoBvwpuodWEa"));
      setTimeout(()=>{ navigate(`${BIXIN_ACCOUNTMANAGER}?current=1`)},2000)
    } else {
      setNextError(false);
    }
  }, 5000);

  return (
    <div>
      <Flex justifyContent={"center"} mt={["100px","200px"]}>
        <Box w={["80%", "400px"]}>
          <Text fontSize="32px" fontWeight={"600"} color="t.100">
            {t("sTMySyQXFMSuchwUXiJ")}
          </Text>
          <Box mt="20px">
            <Text fontSize={"14px"}>{t("sjqwCBQBKOIAKZymEnSd")}</Text>
            <Input
              placeholder={t('cTlSqXgGENkCAQQJCHx')}
              mt="5px"
              fontSize="12px"
              h="44px"
              _placeholder={{ color: "t.50" }}
              type="password"
              isInvalid={isInvalidPwd}
              bg={isInvalidPwd ? "red.50" : "none"}
              errorBorderColor="red.100"
              onBlur={pwdChange}
            />
          </Box>
          {isInvalidPwd && (
            <Text color="red.100" fontSize="12px" mt="5px">
               {t("SMEPVJNtMGWMrEceo")}
            </Text>
          )}
          <Box mt="20px">
            <Text fontSize={"14px"}> {t("lKLpdmEuElAKKvltT")}</Text>
            <Input
              placeholder={t('eChfcikkuQFNcZULZuP')}
              mt="5px"
              fontSize="12px"
              h="44px"
              type="password"
              isInvalid={isInvalidRePwd}
              bg={isInvalidRePwd ? "red.50" : "none"}
              errorBorderColor="red.100"
              _placeholder={{ color: "t.50" }}
              onBlur={rePwdChange}
            />
          </Box>
          {isInvalidRePwd && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {t("jgXHbpNLgJMKuuZER")}
            </Text>
          )}
          <Box mt="50px">
            {!nextError ? (
              <Button
                borderRadius={"20px"}
                w={["100%", "400px"]}
                h="44px"
                fontSize="14px"
                bg="blue.100"
                color="#fff"
                _hover={{ bg: "blue.80" }}
                onClick={submitResetPwd}
              >
                {t("WzcUSFqDMdwkVtGoj")}
              </Button>
            ) : (
              <Button
                borderRadius={"20px"}
                w={["100%", "400px"]}
                h="44px"
                fontSize="14px"
                bg="t.50"
                color="#fff"
                _hover={{ bg: "t.50" }}
              >
              {t("WzcUSFqDMdwkVtGoj")}
              </Button>
            )}
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

export default ResetPwd;
