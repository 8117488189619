import { Decimal } from "decimal.js";
Decimal.set({ toExpNeg: -13 });

// decimal input
const Dec = (val1, val2, symbol) => {
    let result = "";
    switch (symbol) {
      case "+":
        result = new Decimal(val1).add(new Decimal(val2));
        break;
      case "-":
        result = new Decimal(val1).sub(new Decimal(val2));
        break;
      case "*":
        result = new Decimal(val1).mul(new Decimal(val2));
        break;
      case "/":
        result = new Decimal(val1).div(new Decimal(val2));
        break;
      default:
        break;
    }
    return result.toString();
}

/**
 * 向下取值
 * @param {stirng} str 
 * @param {string} digit 
 */
const decimalFloor = (str, digit) => {
  let temp_digit = Decimal.pow(10, digit);
  let res = new Decimal(str).mul(temp_digit).floor().div(temp_digit).toString();
  return res;
}

export { Dec, decimalFloor }