import React from "react";
import { updateLanguage } from "@/state/user/reducer";
import { useDispatch } from "react-redux";
import { PRO_ENV } from "@/config";
// import { BxHeaderComponent } from "./demo";
const BxHeaderComponent = await import(
  `bx-header-component/dist/${
    PRO_ENV === "DEV" ? "dev.js" : PRO_ENV === "DEV2" ? "dev2.js" : "prod.js"
  }`
).then((res) => {
  return res.BxHeaderComponent;
});

const PageWrap = ({ children, logged = true }) => {
  const dispatch = useDispatch();

  const changeLang = (lang) => {
    dispatch(updateLanguage({ language: lang || "en" }));
  };

  return (
    <div>
      {/* <Header changeLanguage={changeLang} logged={logged} /> */}
      <BxHeaderComponent appChangeLanguage={changeLang} />
      {children}
    </div>
  );
};
export default React.memo(PageWrap);
