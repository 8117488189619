import Progress from "../Progress";
import { Box, Flex, Text, Button, Input, Image } from "@chakra-ui/react";
import { useMemo } from "react";
import { useI18n } from "@/hooks/useI18n";
import { useAppSelector } from "@/state/hooks";
import { useNavigate } from "react-router-dom";
import BXLoading from "@/components/ui/pay-loading";
import { useVerifyAddress } from "../verifyHooks";
import ModalComponent from "@/components/ui/modal";
import "./address.scss";
import { useMedia } from "@/hooks/useMedia";
import Preview from "./Preview";

const VerifyAddress = ({
  setStep,
  allCountryList,
  noCompliantCountryList,
  sanctionsCountryList,
}) => {
  const { t } = useI18n();
  const navigate = useNavigate();
  // const countryRef = useRef();
  // const reAddressCode = useAppSelector(
  //   (state) => state.user.userInfo.tmp_residence_address
  // );
  const _lang = useAppSelector((state) => state.user.language);
  const { isMobile } = useMedia();
  // const [reAddressDisabled, setReAddressDisabled] = useState(false);

  const {
    memoEmailAddressList,
    checkList,
    setCheckList,
    emailAddress,
    setEmailAddress,
    setReAddress,
    reAddress,
    errorMap,
    uploadLoading,
    handleFile,
    onSubmit,
    fileName,
    validFileType,
    viewStatus,
    showTipModal,
    setShowModalTip,
    country,
    canSubmit,
    setViewStatus,
    otherCountry,
  } = useVerifyAddress();

  const memoCountry = useMemo(() => {
    if (allCountryList?.length > 0) {
    }
    if (otherCountry) {
      const item = allCountryList.filter(
        (d) => d.iso_code2 === otherCountry
      )[0];
      if (item) {
        if (_lang === "zh") return `${item?.zh_name} (+${item?.dial_code}) `;
        return `${item?.en_name} (+${item?.dial_code}) `;
      }
    } else if (country && !otherCountry) {
      const item = allCountryList.filter((d) => d.iso_code2 === country)[0];
      if (item) {
        if (_lang === "zh") return `${item?.zh_name} (+${item?.dial_code}) `;
        return `${item?.en_name} (+${item?.dial_code}) `;
      }
    }
  }, [allCountryList, _lang, country, otherCountry]);

  // useEffect(() => {
  //   if (idType === "ID_CARD" && allCountryList?.length > 0) {
  //     setReAddress(t("hYBHkTzZmueqvwJb"));
  //     setReAddressDisabled(true);
  //   }
  // }, [allCountryList, idType, t]);

  const toVerifyAssets = (e) => {
    if (e.target.nodeName === "B") {
      setStep(4);
    }
  };

  const renderResultView = () => {
    return (
      <Box
        w={["100%", "400px"]}
        margin={"0 auto"}
        mt={"60px"}
        borderRadius={"12px"}
        textAlign={"center"}
        fontWeight={"bold"}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src={`../../wwwsite/images/kyc/certificate/${
              viewStatus === "PENDING" ? "pending" : "done"
            }.svg`}
            width={"40px"}
            height={"40px"}
            alt=""
          ></Image>
          <Text
            fontSize={"24px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {viewStatus === "PENDING"
              ? t("KzFzOytWXScqDRKFwg")
              : t("zZXyblLWbONMNIjPmBN")}
          </Text>
          {viewStatus === "PENDING" && (
            <Text
              fontSize={"14px"}
              fontWeight={600}
              color={"#010313"}
              mt={"10px"}
            >
              {t("YXrqAGUqprWfOCeiSGX")}
            </Text>
          )}
        </Flex>
        <Box
          mt={"40px"}
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text
            dangerouslySetInnerHTML={{ __html: t("aETDJvQumPoQnxdEPkQR") }}
            className="know-more-tip"
            style={{ display: "block" }}
            onClick={(e) => toVerifyAssets(e)}
          ></Text>
          <Image
            display={"block"}
            cursor={"pointer"}
            position={"relative"}
            left={_lang === "en" && !isMobile ? "-30px" : "0px"}
            src={"../../wwwsite/images/kyc/gray-tip.svg"}
            onClick={() => setShowModalTip(true)}
          />
        </Box>
        <Button
          mt="40px"
          fontSize="14px"
          padding="0 30px"
          w="100px"
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => navigate("/trade/personKyc/certificate")}
        >
          {t("FrzWGSiDSDzTfNgZH")}
        </Button>
      </Box>
    );
  };

  const renderInitView = () => {
    return (
      <>
        <Box>
          <Text fontSize="14px" color="t.100" mt="70px">
            {t("gYpQegnebgwwccEuM")}
          </Text>
          <Input
            h="44px"
            fontSize="12px"
            color="t.100"
            borderRadius={"12px"}
            mt="10px"
            value={memoCountry}
            disabled
            _disabled={{
              opacity: "1",
              color: "#010313",
              bgColor: "rgba(32, 26, 85, 0.05)",
              border: "1px solid #E3E4F1",
            }}
          />
        </Box>
        {/* 常居地址 */}
        <Box>
          <Text fontSize="14px" color="t.100" mt="20px" borderRadius={"12px"}>
            {t("HQSjnUOWYQPuvvTpu")}
          </Text>
          <Input
            placeholder={t("idqLvJqjeiEmQbpa")}
            fontSize="12px"
            borderRadius={"12px"}
            mt="10px"
            h="44px"
            value={reAddress}
            isInvalid={errorMap?.reAddress}
            bg={errorMap?.reAddress ? "red.50" : "none"}
            errorBorderColor="red.100"
            // disabled={reAddressDisabled}
            onChange={(e) => {
              setReAddress(e.target.value);
              if (checkList.indexOf(1) > -1) {
                setEmailAddress(e.target.value);
              }
            }}
          />
          {errorMap?.reAddress && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {t("EkbLQeqmPtcNzItJWQO")}
            </Text>
          )}
          {/* 邮寄地址、电子结算单选择 */}
          <Box mt="20px">
            <Text fontSize="16px" fontWeight={"500"}>
              {t("PufPEQRHcFlNqsAG")}
            </Text>
            <Flex flexDirection={"column"}>
              {memoEmailAddressList.map((d) => {
                return (
                  <Flex key={d.type} mt={"10px"} alignItems={"flex-start"}>
                    <Image
                      width={"18px"}
                      height={"18px"}
                      mt={"3px"}
                      onClick={() =>
                        setCheckList((prev) => {
                          const arr = [...prev];
                          const hasIndex = prev.indexOf(d.type);
                          if (hasIndex > -1) {
                            arr.splice(hasIndex, 1);
                            return arr;
                          } else {
                            const arr = [...prev];
                            arr.push(d.type);
                            return arr;
                          }
                        })
                      }
                      cursor={"pointer"}
                      src={`../../wwwsite/images/kyc/${
                        checkList.indexOf(d.type) > -1
                          ? "checked.svg"
                          : "no_check.svg"
                      }`}
                    />
                    {d.type === 1 && (
                      <Text fontSize={"16px"} fontWeight={500} ml={"5px"}>
                        {d.text}
                      </Text>
                    )}
                    {d.type === 2 && (
                      <Flex direction={"column"} alignItems={"flex-start"}>
                        <Text fontSize={"16px"} fontWeight={500} ml={"5px"}>
                          {d.text}
                        </Text>
                        {checkList.indexOf(d.type) === -1 && (
                          <span
                            className="kyc-address-desc-text"
                            style={{
                              color: "#D04457",
                              fontSize: "14px",
                              marginTop: "3px",
                              marginLeft: "5px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: t("YCHRDIIKsDzJFQJw"),
                            }}
                          ></span>
                        )}
                      </Flex>
                    )}
                  </Flex>
                );
              })}
            </Flex>
          </Box>
        </Box>
        {/* 邮寄地址 */}
        {checkList.indexOf(1) === -1 && (
          <Box>
            <Text fontSize="14px" color="t.100" mt="20px" borderRadius={"12px"}>
              {t("PufPEQRHcFlNqsAG")}
            </Text>
            <Input
              placeholder={t("idqLvJqjeiEmQbpa")}
              fontSize="12px"
              borderRadius={"12px"}
              mt="10px"
              h="44px"
              value={emailAddress}
              isInvalid={errorMap?.emailAddress}
              bg={errorMap?.emailAddress ? "red.50" : "none"}
              errorBorderColor="red.100"
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
            />
            {errorMap?.emailAddress && (
              <Text color="red.100" fontSize="12px" mt="5px">
                {t("EvOtSTBQrIYspwjQ")}
              </Text>
            )}
          </Box>
        )}
        <Text fontSize="14px" mt="30px">
          {t("NoqAHzFdypmIlmXmyM")}
        </Text>
        <Box position="relative">
          <Flex justifyContent={"space-between"} mt="10px">
            <Input
              w="80px"
              type="file"
              opacity={"0"}
              position={"absolute"}
              right="0"
              zIndex={"2"}
              cursor="pointer"
              fontSize="0"
              onChange={(e) => {
                handleFile(e);
              }}
            />
            <Input
              w={["220px", "310px"]}
              disabled
              borderRadius={"12px"}
              _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
              value={fileName}
            />
            <Button
              w="80px"
              h="30px"
              fontSize="12px"
              color="#fff"
              borderRadius={"20px"}
              bg="blue.100"
              textAlign={"center"}
              _hover={{ bg: "blue.80" }}
              mt="5px"
            >
              {t("NqgOQEXQaHsramCnbX")}
            </Button>
          </Flex>
          <Text
            fontSize="12px"
            color={validFileType ? "t.80" : "red.100"}
            mt="10px"
          >
            {t("dLTuZclgDrLDUToEe")}
          </Text>
        </Box>
        <Flex justifyContent={"center"} mt="60px" mb={"60px"}>
          <Button
            mr="10px"
            fontSize="14px"
            padding="0 30px"
            w="100px"
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => setStep(2)}
          >
            {t("FrzWGSiDSDzTfNgZH")}
          </Button>
          <Button
            mr="10px"
            fontSize="14px"
            padding="0 30px"
            w="100px"
            bg="#fff"
            border={"1px solid #333BF5"}
            fontWeight={"400"}
            color={"#333BF5"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            _hover={{
              bg: "#fff",
            }}
            onClick={() => setViewStatus("PREVIEW")}
          >
            {t("SFeLOdYxYJLeOgLtZI")}
          </Button>
          <Button
            fontSize="14px"
            padding="0 30px"
            w="200px"
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            onClick={() => {
              if (canSubmit) {
                onSubmit();
              }
            }}
            _hover={{ bg: canSubmit ? "blue.80" : "t,50" }}
            cursor={canSubmit ? "pointer" : "not-allowed"}
            bg={canSubmit ? "blue.100" : "t.50"}
          >
            {t("YChcfaxDaLscdSPiRQz")}
          </Button>
        </Flex>
      </>
    );
  };

  return (
    <div>
      <Flex justifyContent={"center"}>
        <Box w={["80%", "400px"]} mt={"30px"}>
          <Progress step="3" />
          {(viewStatus === "PENDING" || viewStatus === "APPROVED") &&
            renderResultView()}
          {viewStatus === "INIT" && renderInitView()}
          {viewStatus === "PREVIEW" && (
            <Preview
              reAddress={reAddress}
              emailAddress={emailAddress}
              fileName={fileName}
              checkList={checkList}
              memoEmailAddressList={memoEmailAddressList}
              setViewStatus={setViewStatus}
              noCompliantCountryList={noCompliantCountryList}
              sanctionsCountryList={sanctionsCountryList}
            />
          )}
        </Box>
      </Flex>
      {uploadLoading && <BXLoading />}
      <ModalComponent isShow={showTipModal}>
        <Box px={"33px"} py={"50px"}>
          <Box>
            <Flex justifyContent={"space-between"}>
              <Text color="#010313" fontSize={16} fontWeight={600}>
                {t("GCxKMtkKAZPoOHsZIDh")}
              </Text>
              <Image
                boxSize="24px"
                src="../../wwwsite/images/bill/close.svg"
                cursor={"pointer"}
                onClick={() => setShowModalTip(false)}
              />
            </Flex>
            <Text fontSize={14} color={"#010313"} mt={"20px"}>
              {t("FaQaEHvLdVELQbCZrFN")}
            </Text>
          </Box>
        </Box>
      </ModalComponent>
    </div>
  );
};

export default VerifyAddress;
