import request from "@/hooks/useRequest";
import {POSTPAYMENTPASSWORDVERIFY,POSTGASECRET,POSTVALIDATIONITEMS,POSTGABIND} from "@/helpers/constants/requestApi";

//邮箱验证
export const postPaymentPwdVerify = (body) => {
  return request(POSTPAYMENTPASSWORDVERIFY, { body });
};

//生成ga秘钥
export const postCreateGaSecret = (body) => {
  return request(POSTGASECRET,{body});
}

//需要的安全要素
export const postValidationItemFunc = (body) => {
  return request(POSTVALIDATIONITEMS,{body});
}

// //绑定ga
export const postGaBind = (body) => {
  return request(POSTGABIND,{body});
}