import PropTypes from "prop-types";

import CommonUnitStyle from "@/pages/deposit/component/commonUnitStyle";
import QRCode from "qrcode.react";
import { Box, Text, Link, Image, Tooltip, Flex } from "@chakra-ui/react";
import CopyTextStyle from "@/pages/deposit/component/copyTextStyle";
import "./index.scss";
import { useI18n } from "@/hooks/useI18n";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/state/hooks";

const AddressDetail = (props) => {
  const { t } = useI18n();
  const navigate = useNavigate();

  const primary_currency = useAppSelector(
    (state) => state.user.userInfo.primary_currency
  );

  const {
    currentCurrency,
    networkName,
    address,
    clickResetEdit,
    depositeAvailable,
    getAddressInfo: { warning, address_extra },
  } = props;

  return (
    <>
      <CommonUnitStyle
        title={t("UbeafDPBCDfpRnQyWUjq")}
        showText={`${currentCurrency}-${networkName}`}
        showTitleRightBtn={true}
        rightBtnEvent={() => clickResetEdit()}
      />
      <CommonUnitStyle
        title={t("KveBVvOTiGXRKmAUQZz")}
        canCopyText={address}
        leftLineBg="#FFFFFF"
        leftLineHeight="70px"
      />
      <Box
        padding={"10px"}
        width={"100%"}
        borderRadius={"12px"}
        backgroundColor={"#FCEDEF"}
        alignItems={"center"}
        display={"flex"}
        margin={"10px 0px"}
      >
        <Image
          src="../wwwsite/images/warning.svg"
          width={"18px"}
          height={"18px"}
        />
        <Text
          className="deposite-tip-text"
          fontSize={"12px"}
          color={"#D04457"}
          ml={"10px"}
          onClick={(e) => {
            if (e.target.nodeName === "B") {
              navigate("/trade/whiteAddress");
            }
            if (e.target.nodeName === "SPAN") {
              navigate(`/trade/verify/txAddress`);
            }
          }}
          dangerouslySetInnerHTML={{ __html: t("uJpZAZRdzpyAaUkEqwSx") }}
        ></Text>
      </Box>
      <Box
        w="180px"
        h="180px"
        border="1px"
        borderColor="#232325"
        p="10px"
        borderRadius="12px"
        mx={"auto"}
      >
        <QRCode value={address} size={160} id="qrCode" />
      </Box>
      <Box textAlign="center">
        <Text color="text.80" fontSize="12px" fontWeight={400} mt="10px">
          {t("LQNXJVNXqgqLimcQn").replace("%S%", currentCurrency)}
        </Text>
      </Box>
      {/* 剩余充值额度 */}
      {Number(depositeAvailable) > -1 && (
        <Flex justifyContent={"center"} width={"100%"} mt={"15px"}>
          <Text color="rgba(208, 68, 87, 1)" fontSize="12px" fontWeight={400}>
            {t("bnpgtsIPDfFydCQlTH")}
          </Text>
          <Text
            color="rgba(208, 68, 87, 1)"
            fontSize="12px"
            fontWeight={600}
            ml={"10px"}
          >
            {`${primary_currency} ${depositeAvailable}/${t(
              "YEPMktdEzwhrMMYKefX"
            )}`}
          </Text>
        </Flex>
      )}

      {warning?.text && (
        <Box
          backgroundColor="red.50"
          borderRadius="12px"
          p="10px"
          mt="10px"
          fontSize="12px"
          fontWeight={400}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text color="red.100" flex={1}>
            {warning?.text}
          </Text>
          <Link color="blue.100" href={warning?.url} isExternal>
            {t("XYHinpRQuEPFjXMzLP")}
          </Link>
        </Box>
      )}
      {address_extra?.memo && (
        <Box>
          <CopyTextStyle canCopyText={address_extra?.memo}>
            <Box display="flex">
              <Box
                display="flex"
                fontSize="12px"
                fontWeight={400}
                color="text.100"
                mr="10px"
              >
                <Text>{t("nzLBdKgvNCXQTgYI")}：</Text>
                <Text>{address_extra?.memo}</Text>
              </Box>
              <Tooltip
                hasArrow
                label={t("LWInyMcYkagvzvaBfUHZ")}
                bg="#010313"
                width="150px"
              >
                <Image
                  src="../wwwsite/images/transfer/helpState.svg"
                  alt="Dan Abramov"
                ></Image>
              </Tooltip>
            </Box>
          </CopyTextStyle>
        </Box>
      )}
    </>
  );
};

AddressDetail.propTypes = {
  currentCurrency: PropTypes.string,
  networkName: PropTypes.string,
  address: PropTypes.string,
  getAddressInfo: PropTypes.object,
  clickResetEdit: PropTypes.func,
};

export default AddressDetail;
