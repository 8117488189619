import { useMedia } from "@/hooks/useMedia";

const ModalComponent = (props) => {
  const { isShow, children, style = {} } = props;
  const { isMobile } = useMedia();
  return (
    isShow && (
      <div
        className="modal-component-style"
        style={{
          justifyContent: "center",
          background: isMobile ? "rgba(0, 0, 0, 0.6)" : "",
        }}
      >
        <div
          className="modal-component-style_content"
          style={Object.assign({}, style)}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default ModalComponent;
