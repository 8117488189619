import { toastTip } from "@/components/ui/toast";
import { useI18n } from "@/hooks/useI18n";
import { Text, Flex, Image, Button, Box } from "@chakra-ui/react";
import { useMemo, useState } from "react";

const Start = ({ setOptionValue, canPeriodic }) => {
  const { t } = useI18n();
  const [selected, setSelected] = useState("");

  const options = useMemo(() => {
    return [
      {
        text: t("OzYooSlNbfNTxRxNhe"),
        value: 1,
      },
      {
        text: t("JQpSpVQqatEXOdgFvjh"),
        value: 2,
      },
    ];
  }, [t]);

  const canSumbit = useMemo(() => {
    if (selected) return true;
    return false;
  }, [selected]);

  return (
    <Flex
      flexDirection={"column"}
      style={{ backgroundColor: "#fff" }}
      height={"auto"}
      minHeight={"100vh"}
      width={["calc(100% - 50px)", "400px"]}
      overflow={"hidden"}
      margin={"0 auto"}
      mt={"70px"}
      color={"#010313"}
    >
      <Text
        color={"#232325"}
        fontSize={"32px"}
        fontWeight={600}
        margin={"0 auto"}
      >
        {t("uhoCqEIxFLCGpwTslFY")}
      </Text>
      <Box h={"50px"}></Box>
      {options.map((d, i) => {
        return (
          <Flex
            key={i}
            color={"#010313"}
            fontSize={"16px"}
            mt={i === 1 && "20px"}
          >
            <Image
              cursor={"pointer"}
              onClick={() => setSelected(d.value)}
              mr={"5px"}
              src={
                selected == d.value
                  ? "../../wwwsite/images/kyc/checked.svg"
                  : "../../wwwsite/images/kyc/no_check.svg"
              }
            />
            <Text>{d.text}</Text>
          </Flex>
        );
      })}

      <Button
        fontSize="14px"
        padding="0 30px"
        w={"100%"}
        bg={canSumbit ? "blue.100" : "t.50"}
        _hover={canSumbit ? "blue.80" : "t.50"}
        color="#fff"
        fontWeight={"400"}
        borderRadius={"30px"}
        h="44px"
        cursor={"pointer"}
        margin={"0 auto"}
        mt={"40px"}
        onClick={() => {
          if (canPeriodic) {
            setOptionValue(selected);
          } else {
            toastTip(t("WelPCqxRFgXNzpkZJdsh"), { type: "error" });
          }
          setOptionValue(selected);
        }}
      >
        {t("AQrCocFqoKoYieUnT")}
      </Button>
    </Flex>
  );
};

export default Start;
