import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  Select,
  Image,
} from "@chakra-ui/react";
import Progress from "./Progress";
import { useNavigate } from "react-router-dom";
import { useI18n } from "@/hooks/useI18n";
import BXLoading from "@/components/ui/pay-loading";
import { useAssetsHooks } from "./verifyHooks";
import { useMedia } from "@/hooks/useMedia";

const VerifyAssets = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { isMobile } = useMedia();

  const {
    upLoadList,
    handleAssetsFile,
    memoFileType,
    fileTypeChange,
    onSubmit,
    uploadLoading,
    delOrAddFileItem,
    viewStaus,
    canSubmit,
  } = useAssetsHooks();

  const renderPendingView = () => {
    return (
      <Box
        w={["100%", "400px"]}
        margin={"0 auto"}
        mt={"60px"}
        borderRadius={"12px"}
        textAlign={"center"}
        fontWeight={"bold"}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            src={`../../wwwsite/images/kyc/certificate/pending.svg`}
            width={"40px"}
            height={"40px"}
            alt=""
          ></Image>
          <Text
            fontSize={"24px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {t("KzFzOytWXScqDRKFwg")}
          </Text>

          <Text
            fontSize={"14px"}
            fontWeight={600}
            color={"#010313"}
            mt={"10px"}
          >
            {t("YXrqAGUqprWfOCeiSGX")}
          </Text>
        </Flex>
        <Button
          mt="40px"
          fontSize="14px"
          padding="0 30px"
          w="100px"
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => navigate("/trade/personKyc/certificate")}
        >
          {t("FrzWGSiDSDzTfNgZH")}
        </Button>
      </Box>
    );
  };

  const renderInitView = () => {
    return (
      <>
        {/* <Flex alignItems={"center"} mt={"40px"}>
          <Text color={"#000"} fontSize={"16px"} fontWeight={500}>
            {t("ihVcXhmbhwIbsPsTbF")}
          </Text>
          <Flex
            ml={"30px"}
            border={"1px solid rgba(32, 26, 85, 0.05)"}
            borderRadius={"8px"}
            padding={"5px 10px"}
            alignItems={"center"}
          >
            <Image src="../../wwwsite/images/kyc/certificate/pdf.svg" />
            <a href={downLink} target="_blank" rel="noreferrer">
              <Box
                bg={"#EDEFFC"}
                borderRadius={"8px"}
                ml={"10px"}
                cursor={"pointer"}
                padding={"3px 10px"}
              >
                <Text color={"#333BF5"} fontSize={"12px"}>
                  {t("ctaxFEnYnZIrAtOaYc")}
                </Text>
              </Box>
            </a>
          </Flex>
        </Flex>
        <Box position="relative">
          <Flex
            justifyContent={"space-between"}
            mt="10px"
            alignItems={"center"}
          >
            <Input
              w="80px"
              type="file"
              opacity={"0"}
              position={"absolute"}
              right="0"
              zIndex={"2"}
              cursor="pointer"
              fontSize="0"
              onChange={(e) => {
                handleAssetsFile("crs", e);
              }}
            />
            <Input
              w={["220px", "310px"]}
              disabled
              height={"44px"}
              borderRadius={"12px"}
              _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
              value={crsFileInfo?.fileName}
            />
            <Button
              w="80px"
              h="30px"
              fontSize="12px"
              color="#fff"
              borderRadius={"20px"}
              bg="blue.100"
              textAlign={"center"}
              _hover={{ bg: "blue.80" }}
              ml={isMobile ? "10px" : ""}
            >
              {t("NqgOQEXQaHsramCnbX")}
            </Button>
          </Flex>
          <Text
            color={crsFileInfo?.error ? "red.100" : "#7B7C8F"}
            fontSize={"12px"}
            mt={"5px"}
          >
            {t("tMlPwcyXKotAImFCGfQ")}
          </Text>
        </Box> */}
        {/* 资产证明 */}
        <Box mt={"20px"}>
          <Text color={"#000"} fontSize={"16px"} fontWeight={500}>
            {t("yzrKsfyEambKetSgWv")}
          </Text>
          {upLoadList.map((d, i) => {
            return (
              <Box key={i}>
                <Flex mt={"20px"} justifyContent={"space-between"}>
                  <Text fontSize={"14px"} color={"#7B7C8F"}>
                    {t("VGmDUnWBzMKfFbMKXyl")}
                  </Text>
                  {i !== 0 && (
                    <Text
                      color={"#D04457"}
                      fontSize={"14px"}
                      cursor={"pointer"}
                      onClick={() => delOrAddFileItem("delete", i)}
                    >
                      {t("eUvzxeVvXrffrpEOrFrP")}
                    </Text>
                  )}
                </Flex>
                <Select
                  mt={"10px"}
                  w={["80%", "400px"]}
                  fontSize="14px"
                  onChange={(e) => fileTypeChange(i, e)}
                >
                  {memoFileType?.length > 0 &&
                    memoFileType.map((item, idx) => (
                      <option key={idx} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                </Select>
                {/* {Number(d.fileTypeValue) === 2 && (
                  <Text fontSize={"14px"} mt={"5px"} color="red.100">
                    {t("sMMYOeSUIvHuRdqiC")}
                  </Text>
                )} */}

                {/* {Number(d.fileTypeValue) === 3 && (
                  <Text fontSize={"14px"} mt={"5px"} color="red.100">
                    {t("XzXwpEBzHGPNkUqaw")}
                  </Text>
                )} */}

                {/* 上传 */}
                <Box position="relative">
                  <Flex
                    justifyContent={"space-between"}
                    mt="10px"
                    alignItems={"center"}
                  >
                    <Input
                      w="80px"
                      type="file"
                      opacity={"0"}
                      position={"absolute"}
                      right="0"
                      zIndex={"2"}
                      cursor="pointer"
                      fontSize="0"
                      onChange={(e) => {
                        handleAssetsFile("assets", e, i);
                      }}
                    />
                    <Input
                      w={["220px", "310px"]}
                      disabled
                      height={"44px"}
                      borderRadius={"12px"}
                      _disabled={{ borderColor: "bd.50", cursor: "no-drop" }}
                      value={d?.fileName}
                    />
                    <Button
                      w="80px"
                      h="30px"
                      fontSize="12px"
                      color="#fff"
                      borderRadius={"20px"}
                      bg="blue.100"
                      textAlign={"center"}
                      ml={isMobile ? "10px" : ""}
                    >
                      {t("NqgOQEXQaHsramCnbX")}
                    </Button>
                  </Flex>
                </Box>
                <Text
                  mt={"5px"}
                  fontSize={"12px"}
                  color={d?.error ? "#D04457" : "#7B7C8F"}
                >
                  {t("ZAhuzRDlLUTXhKrhhx")}
                </Text>
              </Box>
            );
          })}
          <Flex
            mt={"20px"}
            onClick={() => delOrAddFileItem("add")}
            width={"80px"}
            cursor={"pointer"}
          >
            <Image
              src={`../../wwwsite/images/kyc/add.png`}
              width={"24px"}
              height={"24px"}
            />
            <Text fontSize={"14px"} color={"#333BF5"} cursor={"pointer"}>
              {t("BggwtUciWOTxbdTdDg")}
            </Text>
          </Flex>
        </Box>
        {/* 底部按钮 */}
        <Flex justifyContent={"center"} mt="60px" mb={"50px"}>
          <Button
            mr="10px"
            fontSize="14px"
            padding="0 30px"
            w={["30%", "100px"]}
            bg="rgba(32, 26, 85, 0.05)"
            color="t.100"
            fontWeight={"400"}
            borderRadius={"30px"}
            h="44px"
            cursor={"pointer"}
            onClick={() => {
              navigate("/trade/personKyc/certificate");
            }}
          >
            {t("UNForxvjDLCZdGrmWkX")}
          </Button>
          <Button
            fontSize="14px"
            padding="0 30px"
            w={["60%", "200px"]}
            color="#fff"
            fontWeight={"400"}
            borderRadius={"30px"}
            _hover={{ bg: canSubmit ? "blue.80" : "t,50" }}
            cursor={canSubmit ? "pointer" : "not-allowed"}
            bg={canSubmit ? "blue.100" : "t.50"}
            h="44px"
            onClick={() => {
              if (canSubmit) {
                onSubmit();
              }
            }}
          >
            {t("YChcfaxDaLscdSPiRQz")}
          </Button>
        </Flex>
      </>
    );
  };

  return (
    <>
      <Flex justifyContent={"center"}>
        <Box w={["80%", "400px"]} mt={"30px"}>
          <Progress step={4} />
          {viewStaus === "PENDING" && renderPendingView()}
          {viewStaus === "INIT" && renderInitView()}
        </Box>
      </Flex>
      {uploadLoading && <BXLoading />}
    </>
  );
};

export default VerifyAssets;
