import { chakra, Input, Button } from "@chakra-ui/react";

const BXInput = chakra(Input, {
  baseStyle: {
    height: "44px",
    fontSize: "12px",
    borderRadius: "12px",
    mt: "10px",
    color: "rgba(1, 3, 19, 1)",
    _disabled: {
      // opacity: "1",
      // color: "#010313",
      // bgColor: "rgba(32, 26, 85, 0.05)",
      // border: "1px solid #E3E4F1",
    },
    "&.upload": {
      fontSize: "14px",
      color: "#010313",
      border: "1px solid #E3E4F1",
      _disabled: {
        bgColor: "#fff",
        opacity: 1,
        color: "#010313",
        // bgColor: "rgba(32, 26, 85, 0.05)",
      },
    },
  },
});

const BXButton = chakra(Button, {
  baseStyle: {
    fontSize: "14px",
    bg: "blue.100",
    color: "#fff",
    fontWeight: 400,
    borderRadius: "30px",
    padding: "0 30px",
    h: "44px",
    _hover: {
      bg: "blue.80",
      cursor: "pointer",
    },
    "&.cancel": {
      color: "t.100",
      bg: "rgba(32, 26, 85, 0.05)",
      _hover: {
        bg: "#E2E8F0",
      },
    },
    "&.disabled": {
      bg: "#B3B4C8",
      _hover: {
        cursor: "not-allowed",
      },
    },
  },
});

export { BXInput, BXButton };
