import { handleS3UploadFile } from "@/utils/upload";
import { useEffect, useMemo, useState } from "react";
import useConstantsHooks from "../../common/constantsHooks";
import {
  getPersonalKycUpdateInfoApi,
  submitPersonalKycUpdateInfoApi,
} from "@/api/perodic";
import { cloneDeep } from "lodash";

const UPLOAD_FILE_PREFIX = "personal_updates";

const PAGE_STATUS = {
  INIT: "INIT",
  RESULT: "RESULT",
};

const useHooks = ({ reviewUUID }) => {
  const { memoWealthSource } = useConstantsHooks();

  const [pageStatus, setPageStatus] = useState(PAGE_STATUS.INIT);

  const [richSourceList, setRichSourceList] = useState([]);
  const [inputParams, setInputParams] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false); // 上传loading

  const getPersonalKycUpdateInfo = async () => {
    const res = await getPersonalKycUpdateInfoApi({ review_uuid: reviewUUID });
    if (res.code === 0) {
      setInputParams(res.data);
      const { wealth_source } = res.data;
      let list = [];
      for (let i = 0; i < wealth_source?.options?.length; i++) {
        let item = wealth_source.options[i];
        list.push(
          Object.assign({}, item, {
            error: false,
            fileName: "",
            filePath: "",
            key: memoWealthSource.filter((d) => d.value === item.value)[0]?.key,
          })
        );
      }
      setRichSourceList(list);
    }
  };

  useEffect(() => {
    getPersonalKycUpdateInfo();
  }, []);

  const handleFileFn = async ({ file, fileTypeSuffix, index }) => {
    setUploadLoading(true);
    const uploadRes = await handleS3UploadFile({
      prefix: UPLOAD_FILE_PREFIX,
      file_type: fileTypeSuffix,
      file,
    });
    if (uploadRes.status !== "error") {
      setUploadLoading(false);
      const { filePath } = uploadRes;
      setRichSourceList((prev) => {
        const list = [...prev];
        const curr = list?.[index];
        curr.filePath = filePath;
        curr.error = false;
        return list;
      });
    } else {
      setUploadLoading(false);
    }
  };

  // 上传
  const hanleFileUpload = async (e, index) => {
    const file = e.target.files[0];
    const validTypes = ["jpg", "pdf"];
    if (file) {
      const { size, name } = file;
      const fileTypeSuffix = name
        .split(".")
        [name.split(".").length - 1].toLocaleLowerCase();
      if (validTypes.indexOf(fileTypeSuffix) > -1 && size / 1000000 < 5) {
        setRichSourceList((prev) => {
          const list = [...prev];
          const curr = list?.[index];
          curr.fileName = name;
          return list;
        });
        handleFileFn({ file, fileTypeSuffix, index });
      } else {
        setRichSourceList((prev) => {
          const list = [...prev];
          const curr = list?.[index];
          curr.error = true;
          return list;
        });
      }
    }
  };

  const canSubmit = useMemo(() => {
    let flag = true;
    if (richSourceList?.length > 0) {
      for (let i = 0; i < richSourceList.length; i++) {
        const curr = richSourceList[i];
        const { fileName, filePath, error } = curr;
        if (!fileName || !filePath || error) {
          flag = false;
          break;
        }
      }
    } else {
      flag = false;
    }
    return flag;
  }, [richSourceList]);

  const onSubmit = async () => {
    let params = cloneDeep(inputParams);

    const wealth_source_documents = [];
    for (let i = 0; i < richSourceList.length; i++) {
      const item = richSourceList[i];
      wealth_source_documents.push({
        document_type: "proof_of_source_of_wealth",
        document_type_comment: item?.key,
        document_path: item?.filePath,
      });
    }

    params = Object.assign({}, params, {
      review_uuid: reviewUUID,
      is_updated: true,
      wealth_source_files: wealth_source_documents,
    });

    const submitRes = await submitPersonalKycUpdateInfoApi(params);
    if (submitRes.code === 0 && submitRes?.data?.ok) {
      setPageStatus("RESULT");
    }
  };

  return {
    richSourceList,
    hanleFileUpload,
    uploadLoading,
    canSubmit,
    onSubmit,
    pageStatus,
    setPageStatus,
    PAGE_STATUS,
  };
};

export default useHooks;
