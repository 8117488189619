import { useNavigate } from "react-router-dom";

import { 
    Box, 
    Flex, 
} from "@chakra-ui/react";
import BackWalletBtn from '@/pages/historyBill/component/backWalletBtn';

import { BIXIN_WALLET } from '@/helpers/constants/routeName';

const ComponentBodyUnit = (props) => {
    const navigate = useNavigate();

    const { bodyWidth, children, clickBackEvent, btnDesc } = props;


    const clickBackWallet = () => {
        if (clickBackEvent) {
            clickBackEvent()
        } else {
            navigate(BIXIN_WALLET);
        }
    }

    return (
    <Box mt="40px" px={['0px', '22%']}>
        <BackWalletBtn 
            btnDesc={btnDesc}
            customizeBackEvent={clickBackWallet} 
        />
        <Flex justifyContent={"center"} mt={['30px', "40px"]}>
            <Box w={['100%',bodyWidth || '350px']} px={['30px', '0px']}>{children}</Box>
        </Flex>
    </Box>)
}

export default ComponentBodyUnit;