import { Box, Text, Flex, Image } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getMonthStatement } from "./api";
import ModalComponent from "@/components/ui/modal";
import { useI18n } from "@/hooks/useI18n";
import { toastTip } from "@/components/ui/toast";

const MonthOrder = () => {
  const [monthList, setMonthList] = useState([]);
  const [showDownModal, setShowDownModal] = useState(false);
  const [downLinkUrl, setDownLinkUrl] = useState("");
  const { t } = useI18n();
  const [date, setDate] = useState("");

  useEffect(() => {
    //倒退12个月
    const list = new Array(12).fill("-").map((d, i) => {
      const curr = dayjs().subtract(i, "month");
      return {
        year: curr.year(),
        month:
          curr.month() + 1 >= 10 ? curr.month() + 1 : `0${curr.month() + 1}`,
      };
    });
    const results = [];
    let obj = {};
    let currYear = "";
    for (let i = 0; i < list.length; i++) {
      if (i === 0) {
        currYear = list[0].year;
        obj = {
          year: list[0].year,
          months: [list[0].month],
        };
      } else {
        if (currYear === list[i].year) {
          obj.months.push(list[i].month);
        } else {
          results.push(obj);
          currYear = list[i].year;
          obj = {
            year: list[i].year,
            months: [list[i].month],
          };
        }
      }
    }
    results.push(obj);
    setMonthList(results);
  }, []);

  const handleGetStatement = async (date) => {
    setDate(date);
    const res = await getMonthStatement(date);
    if (res.code === 0) {
      setShowDownModal(true);
      setDownLinkUrl(res.data.url);
    } else if (res.code === 404) {
      toastTip(t("HYklWTwhtonjbiQRYCz"), { type: "error" });
    }
  };

  return (
    <>
      <Box padding={"30px"}>
        {monthList &&
          monthList.length > 0 &&
          monthList.map((d, i) => {
            return (
              <Box key={i}>
                <Text
                  mt={i !== 0 && "30px"}
                  fontSize={"14px"}
                  fontWeight={600}
                  color={"#010313"}
                >
                  {d.year}
                </Text>
                <Flex flexWrap={"wrap"} justifyContent={"flex-start"}>
                  {d.months.map((dd, ii) => {
                    return (
                      <Box
                        key={ii}
                        boxSize={"borderBox"}
                        mt={"20px"}
                        onClick={() => handleGetStatement(`${d.year}-${dd}`)}
                        mr={[
                          ii > 0 && (ii + 1) % 3 === 0 ? "0px" : "5%",
                          ii > 0 && (ii + 1) % 6 === 0 ? "0px" : "20px",
                        ]}
                        borderRadius={"12px"}
                        height={"44px"}
                        padding={"0px 10px"}
                        w={["30%", "calc((100% - 100px) / 6)"]}
                        textAlign={"center"}
                        lineHeight={"44px"}
                        cursor={"pointer"}
                        color={"#010313"}
                        fontSize={"14px"}
                        border={"1px solid #E3E4F1"}
                        _hover={{
                          backgroundColor: "#EDEFFC",
                          color: "#333BF5",
                        }}
                      >
                        {dd}
                      </Box>
                    );
                  })}
                </Flex>
              </Box>
            );
          })}
      </Box>
      <ModalComponent isShow={showDownModal} style={{ borderRadius: "6px" }}>
        <Box px={"33px"} py={"50px"}>
          <Box>
            <Flex justifyContent={"flex-end"}>
              <Image
                boxSize="36px"
                src="../wwwsite/images/bill/close.svg"
                cursor={"pointer"}
                onClick={() => {
                  setDownLinkUrl("");
                  setShowDownModal(false);
                }}
              />
            </Flex>
            <Box
              mt={"20px"}
              borderRadius={"12px"}
              height={"44px"}
              placeSelf={"flex-start"}
              padding={"0px 15px"}
              textAlign={"center"}
              lineHeight={"44px"}
              cursor={"pointer"}
              color={"#010313"}
              fontSize={"14px"}
              border={"1px solid #E3E4F1"}
              onClick={() => {
                window.open(downLinkUrl, "_target");
                setShowDownModal(false);
              }}
              _hover={{
                backgroundColor: "#EDEFFC",
                color: "#333BF5",
              }}
            >
              {date && `${date.replace(/-/g, "/")} ${t("lzbnQluepXRurDUf")}`}
            </Box>
          </Box>
        </Box>

        {/* </a> */}
      </ModalComponent>
    </>
  );
};

export default MonthOrder;
