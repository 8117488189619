import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postPaymentPwdVerify, postValidationItemFunc } from "./api";
import { useAppSelector } from "@/state/hooks";
import MiliCodeInput from "@/components/ui/mili-codeinput/mili-codeinput.component";
import { throttle } from "lodash";
import { useI18n } from "@/hooks/useI18n";
const GaStepOne = (props) => {
  const { t } = useI18n();
  const { getStep } = props;
  const [payPwd, setPayPwd] = useState("");
  const [nextError, setNextError] = useState(false);
  const [payPwdError, setPayPwdError] = useState(false);
  const userInfo = useAppSelector((state) => state.user.userInfo);
  const navigate = useNavigate();
  const passWordInputRef = useRef(null);
  const passwordInput = (val) => {
    if (val) {
      setPayPwd(val);
    } else {
      setPayPwd("");
    }
  };
  // eslint-disable-next-line no-unused-vars
  const postValidationItem = async () => {
    //scene => CREATE ｜ UPDATE ｜DELETE
    //securityElem => GOOGLE_AUTH
    const res = await postValidationItemFunc({
      securityElem: "GOOGLE_AUTH",
      email: userInfo.user.email,
      phone: "",
      scene: "CREATE",
    });
    if (res.code === 0) {
    }
  };
  useEffect(() => {}, []);
  const verifyPayPwd = throttle(async () => {
    if (payPwd) {
      setNextError(true);
      const res = await postPaymentPwdVerify({
        paymentPassword: payPwd,
      });
      if (res.code === 0) {
        setNextError(false);
        getStep(2);
      } else {
        setNextError(false);
      }
    } else {
      setPayPwdError(true);
    }
  }, 5000);

  return (
    <div>
      <Flex justifyContent={"center"} mt="200px">
        <Box w={["80%", "400px"]}>
          <Flex justifyContent={"center"}>
            <img src="../wwwsite/images/GoogleAuth.svg" alt="ga" />
            <Text fontSize="32px" fontWeight={"600"} color="t.100" ml="10px">
              {t("nSdDtaHzknwZAXJHzrGP")}
            </Text>
          </Flex>
          <Text
            mt="100px"
            fontSize="16px"
            color="t.100"
            mb="10px"
            fontWeight={"500"}
          >
            {t("KKbVbDLheqewlSvBE")}
          </Text>
          <MiliCodeInput
            ref={passWordInputRef}
            onChange={passwordInput}
            type="password"
            codeboxItemADDClassName="changeBackGround"
          />
          {payPwdError && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {t("LuigfaXzmPRCftdFOV")}
            </Text>
          )}
          <Flex justifyContent={"center"} mt="100px">
            {!nextError ? (
              <Button
                ml="10px"
                fontSize="14px"
                padding="0 30px"
                w="200px"
                bg="blue.100"
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                _hover={{ bg: "blue.80" }}
                h="44px"
                cursor={"pointer"}
                onClick={verifyPayPwd}
              >
                {t("tOaRBVUJHpnSgDBrGON")}
              </Button>
            ) : (
              <Button
                ml="10px"
                fontSize="14px"
                padding="0 30px"
                w="200px"
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                bg="t.50"
                _hover={{ bg: "t.50" }}
                h="44px"
                cursor={"pointer"}
              >
                {t("tOaRBVUJHpnSgDBrGON")}
              </Button>
            )}

            <Button
              ml="10px"
              fontSize="14px"
              padding="0 30px"
              w="100px"
              bg="rgba(32, 26, 85, 0.05)"
              color="t.100"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={"pointer"}
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("WbPTckCFMKtPHgAlL")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
};

export default GaStepOne;
