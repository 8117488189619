import { Box, Flex, Text, Button, Input, Image } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import AddressTypeItem from "./components/AddressTypeItem";
import { useNavigate } from "react-router-dom";
import BXNSelect from "@/components/ui/WSelect";
import { useEffect } from "react";
import { getWhiteListAddressWithUUId } from "../api";
import { WHITELIST_CATEGORY, WHITELIST_STEPS } from "../consts";
import { getPageQuery } from "@/utils";
const Add = ({
  addressType,
  setAddressType,
  addressName,
  setAddressName,
  currency,
  setCurrency,
  network,
  setNetwork,
  address,
  setAddress,
  currencyOptions,
  netWorkOptions,
  addressTypeOptions,
  canToNext,
  setStep,
  setWhitelistUuid,
  setWhitelistVaspDid,
  memoWhiteUsed,
  handleWhiteUsed,
  usedKeyList,
  showUsedError,
  setUsedKeyList,
}) => {
  const urlParams = getPageQuery();
  const uuid = urlParams.uuid;
  const { t } = useI18n();
  const navigate = useNavigate();

  // 编辑时回显
  useEffect(() => {
    if (uuid) {
      getWhiteListAddressWithUUId(uuid).then((res) => {
        console.log("getWhiteListAddress", res);
        if (res.data?.uuid) {
          const item = res.data;
          setAddressType(item.category);
          setAddressName(item.name);
          setCurrency(item.currency);
          setNetwork(item.network);
          setAddress(item.address);
          setWhitelistVaspDid(item.vasp_did);
          const arr = [];
          if (item.is_deposit) {
            arr.push(1);
          }
          if (item.is_withdraw) {
            arr.push(2);
          }
          setUsedKeyList(arr);
        }
      });
    }
  }, [
    uuid,
    setAddressType,
    setAddressName,
    setCurrency,
    setNetwork,
    setAddress,
    setWhitelistVaspDid,
    setUsedKeyList,
  ]);

  const renderItem = ({ iconUrl, label }) => {
    return (
      <Flex alignItems={"center"}>
        <Image
          src={iconUrl}
          width={"20px"}
          height={"20px"}
          mr={"10px"}
          borderRadius={"10px"}
        />
        <Text fontSize="14px">{label}</Text>
      </Flex>
    );
  };
  const nextStep = async () => {
    setStep(WHITELIST_STEPS.VERIFY);
  };
  return (
    <Flex
      alignItems={"center"}
      flexDirection={"column"}
      w={["90%", "500px"]}
      justifyContent={"flex-start"}
      margin={"0 auto"}
    >
      <Text fontSize={"24px"} fontWeight={600}>
        {t("szgmmxxDYVvTLNzPkk")}
      </Text>
      {/* 地址类型 */}
      <Box mt={"20px"} width={"100%"}>
        <Text fontSize="14px">{t("iuOLeRpygvfPFDnf")}</Text>
        <Flex mt={"10px"}>
          {addressTypeOptions.map((d) => {
            return (
              <AddressTypeItem
                onClick={() => setAddressType(d.id)}
                style={{
                  flex: 1,
                  marginLeft: d.id === WHITELIST_CATEGORY.HOSTED && "10px",
                }}
                isActive={d.id === addressType}
                text={d.text}
                key={d.id}
              />
            );
          })}
        </Flex>
      </Box>
      {/* 地址名称 */}
      <Box mt={"20px"} width={"100%"}>
        <Text fontSize="14px">{t("mrdEVkqkBIuEBVzzL")}</Text>
        <Input
          width={"100%"}
          h="44px"
          fontSize="14px"
          color="#010313"
          borderRadius={"12px"}
          placeholder={t("MjEyxNUcFsFMIjsOIDpg")}
          mt="10px"
          value={addressName}
          onChange={(e) => setAddressName(e.target.value)}
        />
      </Box>
      {/* 资产 */}
      <Box mt={"30px"} width={"100%"}>
        <Text fontSize="14px">{t("hQRkbssUXUGGLhtxc")}</Text>
        <BXNSelect
          renderPlaceholder={() => {
            return (
              <Text color="t.50" fontSize="14px">
                {t("LFipKWcCwIamrrato")}
              </Text>
            );
          }}
          style={{ marginTop: "10px" }}
          options={currencyOptions}
          defaultOpt={currencyOptions.find((opt) => opt.value === currency)}
          handleSelect={({ value }) => {
            setCurrency(value);
          }}
          renderLabel={(item) => renderItem(item)}
          renderItem={(item) => renderItem(item)}
        />
      </Box>
      {/* 网络 */}
      <Box mt={"30px"} width={"100%"}>
        <Text fontSize="14px">{t("dGRvusBQRnbHUIYQRit")}</Text>
        <BXNSelect
          renderPlaceholder={() => {
            return (
              <Text color="t.50" fontSize="14px">
                {t("bpvYuERgedcoMzzLNl")}
              </Text>
            );
          }}
          style={{ marginTop: "10px" }}
          options={netWorkOptions}
          defaultOpt={netWorkOptions.find((opt) => opt.value === network)}
          handleSelect={({ value }) => {
            setNetwork(value);
          }}
        />
      </Box>
      {/* 地址 */}
      <Box mt={"30px"} width={"100%"}>
        <Text fontSize="14px">{t("AocjyYzlYgRsGzlavU")}</Text>
        <Input
          width={"100%"}
          h="44px"
          fontSize="14px"
          color="#010313"
          borderRadius={"12px"}
          placeholder={t("rEpbPaWEEsiOSTjOiSGL")}
          mt="10px"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </Box>
      {/* 用途 */}
      <Box mt={"30px"} width={"100%"}>
        <Text fontSize="14px">{t("rKSrOqgimgcTrDutYUA")}</Text>
        {/* 非托管钱包 */}
        {addressType === WHITELIST_CATEGORY.UNHOSTED && (
          <Flex mt={"22px"}>
            {memoWhiteUsed.map((d, i) => {
              return (
                <Flex
                  key={d.value}
                  onClick={() => handleWhiteUsed(d.value)}
                  ml={i === 1 && "30px"}
                  cursor={"pointer"}
                >
                  {i === 0 && (
                    <Image
                      src={
                        usedKeyList.indexOf(1) > -1
                          ? "../../wwwsite/images/kyc/checked.svg"
                          : "../../wwwsite/images/kyc/no_check.svg"
                      }
                    />
                  )}
                  {i === 1 && (
                    <Image
                      src={
                        usedKeyList.indexOf(2) > -1
                          ? "../../wwwsite/images/kyc/checked.svg"
                          : "../../wwwsite/images/kyc/no_check.svg"
                      }
                    />
                  )}
                  <Text
                    fontSize={"13px"}
                    color={"t.100"}
                    fontWeight={400}
                    ml={"5px"}
                  >
                    {d.text}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        )}
        {/* 非托管钱包 && 选择为空 */}
        {showUsedError && addressType === WHITELIST_CATEGORY.UNHOSTED && (
          <Text color={"red.100"} fontSize={"12px"} mt={"10px"}>
            {t("xrfefvhsEfCoJokIdeW")}
          </Text>
        )}
        {/* 交易所 */}
        {addressType === WHITELIST_CATEGORY.HOSTED && (
          <Flex mt={"22px"} cursor={"not-allowed"}>
            <Image
              src={
                usedKeyList.indexOf(2) > -1
                  ? "../../wwwsite/images/kyc/checked.svg"
                  : "../../wwwsite/images/kyc/no_check.svg"
              }
            />
            <Text fontSize={"13px"} color={"t.100"} fontWeight={400} ml={"5px"}>
              {memoWhiteUsed?.[0].text}
            </Text>
          </Flex>
        )}

        {/* <Input
          width={"100%"}
          h="44px"
          fontSize="14px"
          color="#010313"
          borderRadius={"12px"}
          placeholder={t("rEpbPaWEEsiOSTjOiSGL")}
          mt="10px"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        /> */}
      </Box>
      {/* 按钮 */}
      <Flex justifyContent={"center"} mt="40px" mb={"50px"}>
        <Button
          mr="10px"
          fontSize="14px"
          padding="0 30px"
          w={["100%", "100px"]}
          bg="rgba(32, 26, 85, 0.05)"
          color="t.100"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          onClick={() => navigate("/trade/whiteAddress")}
        >
          {t("FrzWGSiDSDzTfNgZH")}
        </Button>
        <Button
          fontSize="14px"
          padding="0 30px"
          w={["100%", "200px"]}
          color="#fff"
          fontWeight={"400"}
          borderRadius={"30px"}
          h="44px"
          cursor={"pointer"}
          bg={canToNext ? "blue.100" : "t.50"}
          _hover={{ bg: canToNext ? "blue.80" : "t.50" }}
          onClick={() => {
            if (canToNext) {
              nextStep();
            }
          }}
        >
          {t("tOaRBVUJHpnSgDBrGON")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Add;
