import { Box } from "@chakra-ui/react";
import PageWrap from "@/components/PageWrap";
import { useDispatch } from "react-redux";
import { updateLanguage } from "@/state/user/reducer";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import EnDoc from "./EnDoc";
import ZhDoc from "./ZhDoc";
import TwDoc from "./TwDoc";
import { useAppSelector } from "@/state/hooks";

const Privacypolicy = () => {
  const dispatch = useDispatch();
  const [_lang, setLang] = useState("en");
  const [searchParams] = useSearchParams();
  const pathLanguage = searchParams.get("lang");
  const _appLang = useAppSelector((state) => state.user.language);
  const maps = {
    zh: "zh",
    "zh-HK": "tw",
    en: "en",
    tw: "tw",
  };

  useEffect(() => {
    if (pathLanguage) {
      dispatch(updateLanguage({ language: maps[pathLanguage] }));
      setLang(maps[pathLanguage]);
    } else if (_appLang) {
      setLang(maps[_appLang]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathLanguage, _appLang, dispatch]);

  return (
    <PageWrap logged={false}>
      <Box w={["90%", "800px"]} margin="50px auto">
        {_lang === "tw" && <TwDoc />}
        {_lang === "zh" && <ZhDoc />}
        {_lang === "en" && <EnDoc />}
      </Box>
    </PageWrap>
  );
};

export default Privacypolicy;
