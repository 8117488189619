import { Box, Text } from "@chakra-ui/react";

const LabelText = ({ children }) => {
  return (
    <Box
      border={"1px solid #E3E4F1"}
      bg={"rgba(32, 26, 85, 0.05)"}
      width={"100%"}
      padding={"10px 20px"}
      height={"44px"}
      borderRadius={"12px"}
      display={"flex"}
      alignItems={"center"}
    >
      <Text fontSize={"14px"} color={"#010313"}>
        {children}
      </Text>
    </Box>
  );
};

export default LabelText;
