import qs from "qs";
import axios from "axios";
import { toastTip } from "@/components/ui/toast";
import { BIXIN_LOGIN } from "@/helpers/constants/routeName";
import { EXCHANGE_TRADE_DOMAIN, SITE_SERVER_API } from "@/config/index";
import { jsUuid } from "@/helpers/func/stringPro";
const initalConfig = {
  method: "GET",
  params: null,
  body: null,
  headers: {},
  cache: "no-cache",
  credentials: "same-origin",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (requestUrl, options) => {
  // const hostBaseURL = window.location.href.includes("beta")
  //   ? process.env.REACT_APP_HOSTBASE_URL_HK
  //   : process.env.REACT_APP_HOSTBASE_URL;
  const hostBaseURL = SITE_SERVER_API;
  const exchangeURL = EXCHANGE_TRADE_DOMAIN;
  //  process.env.REACT_APP_HOSTBASE_EXCHANGE_URL;
  // console.log("hostBaseURL===",hostBaseURL)
  // console.log("exchangeURL===",exchangeURL)
  if (typeof hostBaseURL === "undefined") {
    throw new Error(`hostBaseURL must be a defined environment variable`);
  }
  if (typeof exchangeURL === "undefined") {
    throw new Error(`exchangeURL must be a defined environment variable`);
  }
  let url = requestUrl;
  // console.log("process.env.BASE_URL", hostBaseURL);

  url = url.includes("v1") ? hostBaseURL + url : exchangeURL + url;

  const tempConfig = { ...initalConfig, ...options };
  const { headers, needToast = true } = tempConfig;
  let { params, method, body } = tempConfig;

  if (params && JSON.stringify(params) !== "{}") {
    params = qs.stringify(params, { arrayFormat: "repeat" });
    url += `?${params}`;
  }

  if (body) {
    if (method === "delete") {
      method = "DElETE";
    } else {
      method = "POST";
    }

    headers["Content-Type"] = "application/json";
    body = url.includes("upload") ? body : JSON.stringify(body);
  }

  let authToken = localStorage.getItem("token");
  // let authToken = 'e9384d61b7114f3a800d1b222ac047a9';

  let bx_curLang = window.localStorage.getItem("bx_curLang");
  if (bx_curLang === "zh") {
    bx_curLang = "zh-hans";
  } else if (bx_curLang === "tw") {
    bx_curLang = "zh-HK";
  }
  let _lang = bx_curLang || "en";
  // console.log("是否上传",url.includes("upload"))
  let user_uuid = sessionStorage.getItem("bx_user_uuid");

  if (!user_uuid) {
    user_uuid = jsUuid();
    sessionStorage.setItem("bx_user_uuid", user_uuid);
  }
  const config = {
    method: method.toUpperCase(),
    headers: {
      "Content-Type": url.includes("upload")
        ? "multipart/form-data"
        : "application/json",
      "X-ACCESS-TOKEN": `${authToken}`,
      "Accept-Language": _lang || "en",
      "X-USER-UUID": user_uuid,
    },
    url,
  };

  if (url.includes("v1")) {
    config.headers = Object.assign({}, config.headers, {
      "BX-UPSTREAM-HOST": "wallet-web",
    });
  }
  if (url.includes("v2/web")) {
    config.headers = Object.assign({}, config.headers, {
      "BX-UPSTREAM-HOST": "exchange-api",
    });
  }

  if (/^(POST|PUT|PATCH|DElETE)$/iu.test(method) && body) config.data = body;

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        // console.log("res===", res);
        const code = res.data.code;
        if (code === 0) {
          resolve(res.data);
        } else {
          if (res.data.reason === "AUTHENTICATION_FAILED") {
            toastTip(res.data.message, {
              type: "error",
            });
            resolve(res.data);
            // 审阅
            if (window.location.href.indexOf("/kycInfo/periodic") > -1) {
              window.location.href =
                window.location.origin +
                BIXIN_LOGIN +
                `?redirect_url=${encodeURIComponent(window.location.href)}`;
            } else {
              window.location.href = window.location.origin + BIXIN_LOGIN;
            }
          } else {
            if (
              res.request.responseURL !==
                `${hostBaseURL}/web-wallet/api/v1/register/email/check` &&
              needToast
            ) {
              toastTip(res.data.message, {
                type: "error",
              });
              resolve(res.data);
            } else {
              resolve(res.data);
            }
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
