import request from "@/hooks/useRequest";
import {
  POSTEMAILCHECK,
  POSTEMAILCODEGET,
  POSTEMAILCODEVALIDATION,
  POSTUSERCREATE,
  GETIPCHECK,
  GETUSERINFO,
  GETAREALIST,
} from "@/helpers/constants/requestApi";

//邮箱验证
export const postEmailCheckFunc = (body) => {
  return request(POSTEMAILCHECK, { body });
};

//发送邮件
export const postEmailCodeGetFunc = (body) => {
  return request(POSTEMAILCODEGET, { body });
};

//验证邮件
export const postEmailCodeEvalidationFunc = (body) => {
  return request(POSTEMAILCODEVALIDATION, { body });
};

//注册
export const postUserCreateFunc = (body) => {
  return request(POSTUSERCREATE, { body });
};

//ip检查
export const getIpCheckFunc = (params) => {
  return request(GETIPCHECK, { params });
};
//获取用户信息
export const getUserInfo = (params) => {
  return request(GETUSERINFO, { params });
};
//获取国家和区号
export const getAreaList = (params) => {
  return request(GETAREALIST, { params });
};

// 发送手机验证码
export const postSendCode = (body) => {
  return request("/web-wallet/api/v1/phone/send-code", { body });
};

// 验证手机号验证码
export const verifyPhoneCode = (body) => {
  return request("/web-wallet/api/v1/phone/auth/validation", {
    body,
  });
};
