import { Box, Flex, Text, Input, Button } from "@chakra-ui/react";
import Countdown from "@/components/countdonw";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { throttle } from "lodash";
import { useI18n } from "@/hooks/useI18n";
import { BIXIN_WALLET } from "@/helpers/constants/routeName";
import { sendPhoneCodeApi, verifyPhoneCode, postLoginFunc } from "./api";
import { toastTip } from "@/components/ui/toast";
import { useAppDispatch } from "@/state/hooks";
import { updateUserInfo } from "@/state/user/reducer";
import { geetestFlow } from "@/pages/resetAccountInfo/api";
import { jsUuid } from "@/helpers/func/stringPro";
import { verifyCodeVaild } from "@/utils/regexp";

const SendPhoneCode = (props) => {
  const { phoneInfo, emailInfo, password } = props;
  const { t } = useI18n();
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const [verifyCode, setVerifyCode] = useState("");
  const [reSend, setReSend] = useState(false);
  const [codeError, setCodeError] = useState(false);

  const verifyCodeChange = (e) => {
    const value = e.target.value;
    if (codeError) {
      setCodeError(false);
    }
    setVerifyCode(value);
  };

  const canSubmit = useMemo(() => {
    if (verifyCodeVaild(verifyCode)) return true;
    return false;
  }, [verifyCode]);

  // 发送验证码
  const handleSend = async () => {
    const geetestFlowParams = {
      phone_code: phoneInfo.phone_code,
      phone_number: phoneInfo.phone_number,
      phone: phoneInfo.phone_number,
      request_id: `CREATE_PHONE:${jsUuid()}`,
    };
    geetestFlow(geetestFlowParams, async () => {
      const res = await sendPhoneCodeApi({
        requestId: "LOGIN",
        phoneCode: phoneInfo.phone_code,
        phoneNumber: phoneInfo.phone_number,
      });
      if (res.code === 0 && res?.data?.ok) {
        setReSend(false);
      } else {
        setReSend(true);
      }
    });
  };

  const submitLogin = throttle(async () => {
    if (!verifyCodeVaild(verifyCode)) return;
    // 验证手机号
    const res = await verifyPhoneCode({
      requestId: "LOGIN",
      phoneCode: phoneInfo?.phone_code,
      phoneNumber: phoneInfo?.phone_number,
      code: verifyCode,
    });

    if (res.code === 0 && res?.data?.ok) {
      const loginRes = await postLoginFunc({
        email: emailInfo,
        loginPassword: password,
        phone_code: phoneInfo?.phone_code,
        phone_number: phoneInfo?.phone_number,
      });

      if (loginRes.code !== 0) {
        toastTip(loginRes?.message, { type: "error" });
      } else if (loginRes.code === 0) {
        localStorage.setItem("token", loginRes.data.token);
        dispatch(updateUserInfo({ userInfo: loginRes.data.user }));
        if (window.location.search.indexOf("?redirect_url") > -1) {
          console.log("[[ window.location.search]]", window.location.search);
          window.location.href = decodeURIComponent(
            window.location.search
          ).replace("?redirect_url=", "");
        } else {
          navigator(BIXIN_WALLET);
        }
      }
    }
  }, 2000);

  const renderCountDown = () => {
    return (
      <Countdown
        secondsInit="60"
        onTimeout={() => {
          setReSend(true);
        }}
      />
    );
  };

  return (
    <div>
      <Flex justifyContent={"center"} mt={["100px", "200px"]}>
        <Box w={["80%", "400px"]}>
          <Text fontSize="32px" fontWeight={"600"} color="t.100">
            {t("rtVWmSuAATyCaNgkRNx")}
          </Text>
          <Text fontSize="16px" color="t.100" mt="50px" fontWeight={700}>
            {t("vHRZRztWvqyjjHCxW").replace(
              "%S%",
              phoneInfo?.phone_number.slice(-4)
            )}
          </Text>
          <Text fontSize="14px" color="t.100" mt="20px">
            {t("ltXrglJnxfNqZDSStFq")}
          </Text>
          <Flex mt="5px" justifyContent={"space-between"}>
            <Input
              h="44px"
              borderRadius={"12px"}
              placeholder={t("cTlSqXgGENkCAQQJCHx")}
              fontSize="12px"
              type="email"
              w={["80%", "240px"]}
              _placeholder={{ color: "t.50" }}
              onChange={verifyCodeChange}
              isInvalid={codeError}
              bg={codeError ? "red.50" : "none"}
              errorBorderColor="red.100"
            />

            <Button
              ml="10px"
              fontSize={"12px"}
              padding="0 30px"
              w={"150px"}
              bg={reSend ? "blue.100" : "t.50"}
              _hover={{ bg: reSend ? "blue.80" : "" }}
              color="#fff"
              fontWeight={"400"}
              borderRadius={"30px"}
              h="44px"
              cursor={reSend ? "pointer" : "not-allowed"}
              onClick={() => {
                if (reSend) {
                  handleSend();
                }
              }}
            >
              {t("fxHeYqwgtTcKAdCKPyVe")}
              {!reSend && <>({renderCountDown()})</>}
            </Button>
          </Flex>

          <Flex
            justifyContent={"center"}
            mt="30px"
            alignItems={"center"}
            fontSize="12px"
          >
            <Button
              borderRadius={"20px"}
              w="100%"
              h="44px"
              fontSize="14px"
              color="#fff"
              mt="50px"
              onClick={submitLogin}
              _hover={{ bg: canSubmit ? "blue.80" : "t,50" }}
              cursor={canSubmit ? "pointer" : "not-allowed"}
              bg={canSubmit ? "blue.100" : "t.50"}
            >
              {t("YChcfaxDaLscdSPiRQz")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
};

export default SendPhoneCode;
