import { Box, Flex, Text, Image, Input, Button } from "@chakra-ui/react";
import "./pwd.scss";
import { useI18n } from "@/hooks/useI18n";
import { useMemo, useState } from "react";

const PasswordVerify = ({ onClose, onOk }) => {
  const { t } = useI18n();
  const [pwd, setPwd] = useState("");

  const canSubmit = useMemo(() => {
    if (pwd) return true;
    return false;
  }, [pwd]);

  return (
    <div className="pwd-verify-mask-xyz">
      <Flex flexDirection={"column"} className="pwd-verify-modal-xyz">
        <Flex justifyContent={"space-between"}>
          <Text fontSize={"16px"} color={"#010313"} fontWeight={500}>
            {t("HQLJuhRwbVdfCwEp")}
          </Text>
          <Image
            _hover={{ cursor: "pointer" }}
            boxSize="24px"
            src="../../wwwsite/images/close.svg"
            onClick={() => {
              onClose && onClose();
            }}
          />
        </Flex>
        <Box mt={"30px"}>
          <Text fontSize={"14px"} color={"#010313"}>
            {t("irXsKkZagNKDlvCyhKYK")}
          </Text>
          <Input
            value={pwd}
            type="password"
            color={"#7B7C8F"}
            fontSize={"20px"}
            borderRadius={"12px"}
            padding={"0px 20px"}
            mt={"10px"}
            borderColor={"#E3E4F1"}
            onChange={(e) => {
              setPwd(e.target.value);
            }}
          />
        </Box>
        <Button
          mt={"40px"}
          h="44px"
          color="#fff"
          fontSize="14px"
          borderRadius={"30px"}
          bg={canSubmit ? "blue.100" : "t.50"}
          _hover={{ bg: canSubmit ? "blue.80" : "t.50" }}
          onClick={() => {
            if (canSubmit) {
              onOk && onOk({ pwd });
            }
          }}
        >
          {t("AQrCocFqoKoYieUnT")}
        </Button>
      </Flex>
    </div>
  );
};

export default PasswordVerify;
