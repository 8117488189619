import {useEffect, useMemo, useState} from "react";
import {useI18n} from "@/hooks/useI18n";
import {Box, Button, Flex, Image, Text} from "@chakra-ui/react";
import {LEGAL_TENDER_STATUS} from "../consts";
import {useNavigate} from "react-router-dom";
import {useMedia} from "@/hooks/useMedia";
import AlertDom from "@/components/ui/alert";
import {getBankAccountDelete, getBankAccountList} from "../api";
import {updateBankInfo} from "@/state/user/reducer";
import {useDispatch} from "react-redux";

import "./list.scss";

const LegalTenderList = () => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const { isMobile } = useMedia();
    const [list, setList] = useState([]);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [deleteUUId, setDeleteUUId] = useState("");

    const memoTexts = useMemo(() => {
        return [
            t("XYVTanLxxisBNzaOFx"),
            t("wQEsyNYfqlFrkqUvaDx"),
            t("rbefbFgPVyawmaktVYF"),
        ];
    }, [t]);

    useEffect(() => {
        getBankAccountListData();
    }, []);

    const getBankAccountListData = async () => {
        const res = await getBankAccountList();
        if(res.code === 0 && res.msg === "OK") {
            setList(res.data.bank_accounts);
        }
    };

    const getStatusLabel = (item) => {
        let statusObj = {};
        switch (item.status) {
            case LEGAL_TENDER_STATUS.PENDING:
                statusObj = {
                    color: "#4B5DFF",
                    text: t("hfBBkSOAXqfEsLVFs"),
                    bgColor: "rgba(51, 59, 245, 0.10)",
                };
                break;
            case LEGAL_TENDER_STATUS.VERIFIED:
                statusObj = {
                    color: "#4FBE45",
                    text: t("knoGdfgXpoVeAMqHCXu"),
                    bgColor: "#F0FCED",
                };
                break;
            case LEGAL_TENDER_STATUS.FAILED:
                statusObj = {
                    color: "#D04457",
                    text: t("SBLHeXGlsYVIdiBNRe"),
                    bgColor: "#FCEDEF",
                };
                break;
            default:
                break;
        }
        return statusObj;
    };

    const editList = (item) => {
        dispatch(updateBankInfo({bankInfo: {}}));
        navigator(`/trade/legal/tender/add?uuid=${item.uuid}`);
    };

    const deleteLegalTenderFunc = async () => {
        if(!deleteUUId) {
           return false;
        }
        const params = {
            uuid: deleteUUId,
        };
        const res = await getBankAccountDelete(params);
        if(res.code === 0 && res.msg === "OK") {
            getBankAccountListData();
            setShowDeleteAlert(false);
        }
    };

    return (
        <div className="legal-tender-list" style={{ minHeight: list.length === 0 ? "200px" : "0px" }}>
            <div className="header">
                {memoTexts.map((d, i) => {
                    return (
                        <Text fontSize={"14px"} color={"#7B7C8F"} key={i} fontWeight={400}>
                            {d}
                        </Text>
                    );
                })}
            </div>
            <div className="body">
                {list.map(item => {
                    const failStatus = item.status === LEGAL_TENDER_STATUS.FAILED;
                    const pendingStatus = item.status === LEGAL_TENDER_STATUS.PENDING;
                    return (
                        <Flex
                            fontSize={"14px"}
                            color={"#010313"}
                            // borderBottom={ index !== "1px solid rgba(139, 138, 153, 0.10)"}
                            _hover={{
                                cursor: "pointer",
                                backgroundColor: "rgba(32, 26, 85, 0.05) !important",
                            }}
                            key={item.uuid}
                            className="row"
                        >
                            <Text color={"#010313"} fontSize={"14px"}>
                                {`${item.bank_name}（${item.account_no.slice(-4)}）`}
                            </Text>
                            <Box className="status">
                                <Box
                                    color={getStatusLabel(item).color}
                                    fontSize={"12px"}
                                    fontWeight={500}
                                    background={getStatusLabel(item).bgColor}
                                    borderRadius={"6px"}
                                    padding={"5px 10px"}
                                >
                                    {getStatusLabel(item).text}
                                </Box>
                            </Box>
                            <Flex
                                gap={"15px"}
                                backgroundColor={isMobile ? "#fff" : ""}
                                className="last"
                            >
                                <Image
                                    cursor={failStatus ? "pointer" : "no-drop"}
                                    src={`../../wwwsite/images/whitelist/${failStatus ? 'edit2' : 'edit'}.svg`}
                                    w={"24px"}
                                    h={"24px"}
                                    onClick={() => {
                                        if (!failStatus) {
                                            return false;
                                        }
                                        editList(item);
                                    }}
                                />
                                <Image
                                    cursor={pendingStatus ? "no-drop" : 'pointer'}
                                    src={`../../wwwsite/images/whitelist/${pendingStatus ? 'trash' : 'trash2'}.svg`}
                                    w={"24px"}
                                    h={"24px"}
                                    onClick={() => {
                                        if (pendingStatus) {
                                            return false;
                                        }
                                        setShowDeleteAlert(true);
                                        setDeleteUUId(item.uuid);
                                    }}
                                />
                            </Flex>
                        </Flex>
                    );
                })}
                {!list.length && (
                    <Text color={"#010313"} fontSize={"14px"} className="no-data">
                        {t("bdAMbKWoEuDxlGOg")}
                    </Text>
                )}
            </div>
            {showDeleteAlert && (
                <AlertDom>
                    <Flex
                        px="30px"
                        textAlign={"center"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        padding={"50px 0"}
                        gap={"30px"}
                    >
                        <Text
                            fontWeight={500}
                            fontSize={"16px"}
                            color={"#000000"}
                            w="48%"
                            mx={"auto"}
                            mt="10px"
                        >
                            {t("UHAKvmcFPrannQlFz")}
                        </Text>
                        <Flex justifyContent={"center"}>
                            <Button
                                mr="10px"
                                fontSize="14px"
                                padding="0 30px"
                                w={["100%", "100px"]}
                                bg="rgba(32, 26, 85, 0.05)"
                                color="t.100"
                                fontWeight={"400"}
                                borderRadius={"30px"}
                                h="44px"
                                cursor={"pointer"}
                                onClick={() => {
                                    setShowDeleteAlert(false);
                                }}
                            >
                                {t("UNForxvjDLCZdGrmWkX")}
                            </Button>
                            <Button
                                fontSize="14px"
                                padding="0 30px"
                                w={["100%", "200px"]}
                                color="#fff"
                                fontWeight={"400"}
                                borderRadius={"30px"}
                                h="44px"
                                cursor={"pointer"}
                                bg={"blue.100"}
                                _hover={{ bg: "blue.80" }}
                                onClick={deleteLegalTenderFunc}
                            >
                                {t("AQrCocFqoKoYieUnT")}
                            </Button>
                        </Flex>
                    </Flex>
                </AlertDom>
            )}
        </div>
    );
};

export default LegalTenderList;