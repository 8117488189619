const { useState } = require("react");

const usePerodicHooks = () => {
  const [optionValue, setOptionValue] = useState("");
  return {
    optionValue,
    setOptionValue,
  };
};

export { usePerodicHooks };
