import { Box, Flex, Text, Input, Button } from "@chakra-ui/react";
import BxSelect from "@/components/ui/select";
import Countdown from "@/components/countdonw";
import MiliCodeInput from "@/components/ui/mili-codeinput/mili-codeinput.component";
import { useState, useRef, useMemo } from "react";
import { postSendCode, verifyPhoneCode } from "./api";
import { throttle } from "lodash";
import { jsUuid } from "@/helpers/func/stringPro";
import { useI18n } from "@/hooks/useI18n";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { geetestFlow } from "@/pages/resetAccountInfo/api";
import { useCommonHooks } from "./hooks";
import { updateRegisterPhone } from "@/state/user/reducer";
import StepDesc from "./components/StepDesc";
import { toastTip } from "@/components/ui/toast";
import { verifyCodeVaild } from "@/utils/regexp";

const INIT_PHONE_CODE = "852";

const BindPhone = ({ setRegisterStep }) => {
  const { t } = useI18n();
  const [reSend, setReSend] = useState(false);
  const [showCheckCode, setShowCheckCode] = useState(false);
  const [phoneInfo, setPhoneInfo] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [code, setCode] = useState("");
  const [areaError, setAreaError] = useState({});
  const codeInputRef = useRef(null);
  const dispatch = useAppDispatch();
  const _lang = useAppSelector((state) => state.user.language);

  const { allCountryList, sanctionsCountryList, noCompliantCountryList } =
    useCommonHooks();

  const canSendCode = useMemo(() => {
    if (areaError?.san) {
      return false;
    }
    return true;
  }, [areaError]);

  const getSelectCountry = (value) => {
    setAreaError({});
    // 在制裁区
    if (sanctionsCountryList.indexOf(value.iso_code2) > -1) {
      setAreaError({ san: true });
      return;
    }
    // 非合规区
    if (noCompliantCountryList.indexOf(value.iso_code2) > -1) {
      setPhoneInfo(value);
      setAreaError({ noComp: true });
      return;
    }
    setPhoneInfo(value);
  };

  const changePhone = (event) => {
    const phone = event.target.value;
    if (phone) {
      setPhoneNumber(phone);
      setPhoneError(false);
    } else {
      setPhoneError(true);
    }
  };

  const sendMsgFunc = throttle(async () => {
    if (phoneNumber) {
      setReSend(false);
      const geetestFlowParams = {
        phone_code: phoneInfo.dial_code || INIT_PHONE_CODE,
        phone_number: phoneNumber,
        request_id: `REGISTER_BIND_PHONE:${jsUuid()}`,
      };

      geetestFlow(geetestFlowParams, async () => {
        const sendMsgRes = await postSendCode({
          requestId: "REGISTER",
          phoneCode: phoneInfo.dial_code || INIT_PHONE_CODE,
          phoneNumber: phoneNumber,
        });
        if (sendMsgRes.code === 0) {
          setReSend(true);
          setShowCheckCode(true);
        } else {
          setReSend(false);
          setShowCheckCode(false);
          if (
            sendMsgRes.reason === "INVALID_PHONE" ||
            sendMsgRes.reason === "PHONE_HAS_BEEN_OCCUPIED"
          ) {
            toastTip(sendMsgRes.message, { type: "error" });
          }
        }
      });
      // if (checkPhoneRes.code === 0) {
      //   if (!checkPhoneRes?.data?.is_exist) {
      //     setReSend(false);
      //     const geetestFlowParams = {
      //       phone_code: phoneInfo.dial_code || INIT_PHONE_CODE,
      //       phone_number: phoneNumber,
      //       request_id: `REGISTER_BIND_PHONE:${jsUuid()}`,
      //     };

      //     console.log("[[geetestFlowParams]]", geetestFlowParams);
      //     geetestFlow(geetestFlowParams, async () => {
      //       const sendMsg = await postSendCode({
      //         requestId: "REGISTER",
      //         phoneCode: phoneInfo.dial_code || INIT_PHONE_CODE,
      //         phoneNumber: phoneNumber,
      //       });
      //       if (sendMsg.code === 0) {
      //         setReSend(true);
      //         setShowCheckCode(true);
      //       } else {
      //         setReSend(false);
      //         setShowCheckCode(false);
      //       }
      //     });
      //   } else {
      //     toastTip(t("wfRBtzUHBPZjVDxUc"), { type: "error" });
      //   }
      // }
    } else {
      setPhoneError(true);
    }
  }, 5000);

  const verifyCodeInputChange = (val) => {
    if (val) {
      setCode(val);
      setCodeError(false);
    } else {
      setCodeError(true);
    }
  };

  const verifyPhoneCodeThrottle = throttle(async () => {
    if (areaError?.san) {
      return true;
    }
    if (!phoneNumber) {
      return setPhoneError(true);
    }
    if (!code || !verifyCodeVaild(code)) {
      setShowCheckCode(true);
      return setCodeError(true);
    }

    const res = await verifyPhoneCode({
      requestId: "REGISTER",
      phoneCode: phoneInfo?.dial_code || INIT_PHONE_CODE,
      phoneNumber: phoneNumber,
      code: code,
    });
    if (res.code === 0) {
      dispatch(
        updateRegisterPhone({
          phoneCode: phoneInfo?.dial_code || INIT_PHONE_CODE,
          phoneNumber: phoneNumber,
        })
      );
      setRegisterStep("3");
    }
  });

  return (
    <Flex justifyContent={"center"} mt={["100px", "200px"]} mb={"50px"}>
      <Box w={["80%", "400px"]}>
        <Text fontSize="32px" fontWeight={"600"} color="t.100">
          {t("wjeaGDSVTuLLjJGehN")}
        </Text>
        <StepDesc active={2} />

        <Text mt={"50px"} fontWeight={500} color={"#010313"} mb={"20px"}>
          {t("razdiXXHIkQKbPQTZn")}
        </Text>

        <Box>
          {allCountryList.length > 0 && (
            <BxSelect
              style={{ height: "100px" }}
              list={allCountryList}
              country={allCountryList}
              initName={INIT_PHONE_CODE}
              onGetValue={getSelectCountry}
              type="phone"
              hasError={areaError?.san || areaError?.noComp ? true : false}
            ></BxSelect>
          )}
          {areaError && (
            <Text color={"#D04457"} fontSize={"12px"}>
              {areaError?.san
                ? t("mzWsFtJNWfexuuUgYctv")
                : areaError?.noComp
                ? t("CtiOEevtmgSCxLlIqb")
                : ""}
            </Text>
          )}

          <Flex justifyContent={"flex-start"} mt="20px">
            <Input
              placeholder={t("GBvqxVjZVWwZtAPZgH")}
              type="number"
              fontSize="12px"
              h="44px"
              borderRadius={"12px"}
              isInvalid={phoneError}
              bg={phoneError ? "red.50" : "none"}
              errorBorderColor="red.100"
              onBlur={changePhone}
            />
            {!reSend ? (
              <Button
                ml="10px"
                fontSize={_lang === "en" ? "12px" : "14px"}
                padding="0 30px"
                w={_lang === "en" ? "250px" : "150px"}
                bg={canSendCode ? "blue.100" : "t.50"}
                _hover={{ bg: canSendCode ? "blue.80" : "" }}
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                h="44px"
                cursor={canSendCode ? "pointer" : "not-allowed"}
                onClick={() => {
                  if (canSendCode) {
                    sendMsgFunc();
                  }
                }}
              >
                {t("BQbWzLQtSHQVHdIDAgJ")}
              </Button>
            ) : (
              <Button
                ml="10px"
                fontSize={_lang === "en" ? "12px" : "14px"}
                padding="0 30px"
                w={_lang === "en" ? "250px" : "150px"}
                bg="t.50"
                color="#fff"
                fontWeight={"400"}
                borderRadius={"30px"}
                _hover={{ bg: "t.50" }}
                disabled="true"
                h="44px"
                cursor={"not-allowed"}
              >
                {t("fxHeYqwgtTcKAdCKPyVe")}(
                {
                  <Countdown
                    onTimeout={() => setReSend(false)}
                    secondsInit="60"
                  />
                }
                )
              </Button>
            )}
          </Flex>
          {phoneError && (
            <Text color="red.100" fontSize="12px" mt="5px">
              {t("KVLFziUaVDlFbnJzh")}
            </Text>
          )}
        </Box>
        {showCheckCode && (
          <Box>
            <Text fontSize="14px" color="t.100" mt="40px">
              {t("OXrwZMwkWAjoSDDALzow")}
            </Text>
            <MiliCodeInput
              ref={codeInputRef}
              onChange={verifyCodeInputChange}
              type="text"
              codeboxItemADDClassName="changeBackGround"
            />
            {codeError && (
              <Text color="red.100" fontSize="12px" mt="5px">
                {t("LuigfaXzmPRCftdFOV")}
              </Text>
            )}
          </Box>
        )}
        <Button
          ml="10px"
          fontSize="14px"
          padding="0 30px"
          w="100%"
          mt={"40px"}
          bg="blue.100"
          color="#fff"
          fontWeight={"400"}
          borderRadius={"30px"}
          _hover={{ bg: "blue.80" }}
          h="44px"
          cursor={"pointer"}
          onClick={verifyPhoneCodeThrottle}
        >
          {t("tOaRBVUJHpnSgDBrGON")}
        </Button>
      </Box>
    </Flex>
  );
};

export default BindPhone;
