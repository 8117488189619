import { Box, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useAppSelector } from "@/state/hooks";

const BxSelect = forwardRef((props, ref) => {
  const [showPanner, setShowPanner] = useState(false);
  const { list, onGetValue, initName, type, country, isDisabled, hasError } =
    props;
  const [selected, setSelected] = useState(initName);
  const countryOut = type === "phone" && country;
  const _lang = useAppSelector((state) => state.user.language);
  const selectRef = useRef();

  const selectItems = (item) => {
    setSelected(item);
    setShowPanner(false);
    onGetValue && onGetValue(item);
  };

  useImperativeHandle(ref, () => ({
    selectItems,
    // handleSetValue,
    // ref: refInput.current,
    // onVisibleChange,
    // activeMenuValue: activeMenuValue,
  }));

  const initNameHandle = () => {
    const initData =
      type === "phone"
        ? list.filter((item) => item.dial_code === initName)
        : list.filter((item) => item.selectName === initName);
    setSelected(initData[0]);
  };

  useEffect(() => {
    setSelected(initName);
    initNameHandle();
  }, [initName])

  useEffect(() => {
    document.addEventListener("click", (e) => {
      e.stopPropagation();
      if (
        e.target?.className &&
        e.target.tagName !== "polyline" &&
        e.target.tagName !== "svg"
      ) {
        if (e.target?.className?.indexOf("panner") === -1) {
          setShowPanner(false);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectClickHandle = () => {
    if (props?.isDisabled) {
      return;
    }
    if (showPanner) {
      setShowPanner(false);
    } else {
      setShowPanner(true);
    }
  };

  return (
    <Box mt="10px" width={"100%"} position={"relative"}>
      <Box
        border={hasError ? "1px solid #D04457" : "1px solid #E3E4F1"}
        bg={
          isDisabled ? "rgba(32, 26, 85, 0.05)" : hasError ? "#FCEDEF" : "#fff"
        }
        borderRadius={"12px"}
        padding="10px"
        cursor={isDisabled ? "not-allowed" : "pointer"}
        onClick={selectClickHandle}
        className="panner"
        ref={selectRef}
      >
        <Flex
          justify={"space-between"}
          alignItems={"center"}
          className="panner"
        >
          {selected && (
            <Text>
              {type === "phone"
                ? `${
                    _lang === "en" ? selected?.en_name : selected?.zh_name
                  } (+${selected?.dial_code})`
                : selected?.selectName}
            </Text>
          )}

          {showPanner ? (
            <ChevronUpIcon boxSize={5} />
          ) : (
            <ChevronDownIcon boxSize={5} />
          )}
        </Flex>
      </Box>
      {showPanner && (
        <Box
          backgroundColor={"#fff"}
          borderRadius={"12px"}
          border="1px solid #E3E4F1"
          mt="2px"
          className="panner"
          pt="10px"
          pb="10px"
          width={"100%"}
          height={"200px"}
          overflowY={"scroll"}
          zIndex={999}
          position={"absolute"}
        >
          {type !== "phone"
            ? list.map((item) => (
                <Text
                  bg={"none"}
                  ml="5px"
                  mr="5px"
                  h="36px"
                  mb={["5px", "0px"]}
                  lineHeight={"36px"}
                  padding="0 10px"
                  borderRadius={"12px"}
                  fontSize="14px"
                  cursor={"pointer"}
                  _hover={{ bg: "rgba(32, 26, 85, 0.05)" }}
                  key={item.value}
                  onClick={() => {
                    selectItems(item);
                  }}
                >
                  {item.selectName}
                </Text>
              ))
            : countryOut.map((countryOutItem, idx) => (
                <Text
                  bg={["none", "none"]}
                  ml="5px"
                  mr="5px"
                  h="36px"
                  lineHeight={"36px"}
                  padding="0 10px"
                  borderRadius={"12px"}
                  mb={["5px", "0px"]}
                  fontSize="14px"
                  cursor={"pointer"}
                  _hover={{ bg: "rgba(32, 26, 85, 0.05)" }}
                  key={idx}
                  onClick={() => {
                    selectItems(countryOutItem);
                  }}
                >
                  {_lang === "en"
                    ? countryOutItem?.en_name
                    : countryOutItem?.zh_name}
                  (+{countryOutItem?.dial_code})
                </Text>
              ))}
        </Box>
      )}
    </Box>
  );
});

export default BxSelect;
