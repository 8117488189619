/**
 * 获取到数组中对象的值对应的对象
 * @param {*} arr 数组对象
 * @param {*} key 对象的索引
 * @param {*} value 对象的值
 * @returns 
 */
const getArrInTheObjectAboutKey = (arr, key, value) => {
    const newArr = arr.filter(item=> {
        return item[key] === value
    })
    if (newArr.length > 0) {
        return newArr[0]
    } else {
        return {}
    }
}

/**
 * 获取「公共筛选组件」需要的数组 - 组件名称为「DropDownBox」
 * @param {*} arr 原数组
 * @param {*} namekey 名称对应的对象索引
 * @param {*} selectedKey key对应的对象索引
 */
const getFilterComponentNeedArr = (arr, namekey, selectedKey) => {
    let newArr = [];
    arr.forEach(item => {
        if (namekey && selectedKey) {
            newArr.push({
                ...item,
                name: item[namekey],
                selected_key: item[selectedKey],
            })
        } else {
            newArr.push({
                name: item,
                selected_key: item,
            })
        }
    });
    return newArr;
}

/**
 * 根据第二个数组，获取第一个数组中与第二个数组有交集的信息
 * @param {*} oneArr 
 * @param {*} twoArr 
 * @param {*} OneKey 
 * @param {*} twoKey 
 */
const getArraryIntersection = (oneArr, twoArr, oneKey, twoKey) => {
    let getNewArr = []
    twoArr.forEach(item => {
        const getTwoKey = twoKey ? item[twoKey] : item
        const filterArr = oneArr.filter(item => {
            const getOneKey = oneKey ? item[oneKey] : item
            return getOneKey === getTwoKey;
        })
        getNewArr = getNewArr.concat(filterArr);
    })
    return getNewArr;
}

export { getArrInTheObjectAboutKey, getFilterComponentNeedArr, getArraryIntersection };
