import { Text, Button, Image } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { useNavigate } from "react-router-dom";

const AddBtn = () => {
  const { t } = useI18n();
  const navigator = useNavigate();

  return (
    <Button
      h="44px"
      bg="blue.100"
      color="#fff"
      _hover={{ bg: "blue.80" }}
      fontSize="12px"
      width={"140px"}
      borderRadius={"30px"}
      onClick={() => navigator("/trade/whiteAddress/add")}
    >
      <Image
        boxSize="12px"
        src="../wwwsite/images/add.svg"
        cursor={"pointer"}
        mr={"10px"}
      />
      <Text color={"#fff"} fontSize={"14px"}>
        {t("szgmmxxDYVvTLNzPkk")}
      </Text>
    </Button>
  );
};

export default AddBtn;
