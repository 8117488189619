import { useState, useEffect } from "react";
import BackWalletBtn from "@/pages/historyBill/component/backWalletBtn";
import { Box, Text } from "@chakra-ui/react";
import BillList from "@/pages/historyBill/component/billList";
import BillDetail from "@/pages/historyBill/component/detail";
import DropDownBox from "@/pages/historyBill/component/dropDownBox";
import BXLoading from "@/components/ui/pay-loading";
import { getBillCategoryList, getBilllist } from "@/pages/historyBill/api";
import { getTime } from "@/pages/historyBill/helpers/func/billShowData";
import { useMedia } from "@/hooks/useMedia";
import { useI18n } from "@/hooks/useI18n";
import PageWrap from "@/components/PageWrap";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const HistoryBill = () => {
  const { t } = useI18n();
  // 小于739
  const { isMobile } = useMedia();
  const navigator = useNavigate();
  const [payLoading, setPayLoading] = useState(false);
  const [categoryList, setCategoryList] = useState("");
  const [billList, setBillList] = useState([]);
  const [category, setCategory] = useState("all");
  const [page, setPage] = useState(1);
  const [size] = useState(20);
  const [totalPage, setTotalPage] = useState(1);

  const [detail, setDetail] = useState({});
  const [showDetailAlert, setShowDetailAlert] = useState(false);

  useEffect(() => {
    initBillCategoryList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initBillCategoryList = async () => {
    const { code, data } = await getBillCategoryList();
    if (code === 0) {
      let newArr = [];
      data.category_list.forEach((item) => {
        newArr.push({
          name: item,
          selected_key: item,
        });
      });
      setCategoryList(newArr);
    }
  };

  const initBillList = async () => {
    setPayLoading(true);
    const { code, data, msg } = await getBilllist({
      category: category,
      page: page,
      size: size,
      year_month: getTime(new Date(), "YYYY-MM"),
    });
    console.log("账单列表", code, data, msg);
    if (code === 0 && msg === "OK") {
      setBillList(data.bill_list);
      setTotalPage(Math.ceil(data.total / size));
    }
    setPayLoading(false);
  };

  useEffect(() => {
    initBillList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, page]);

  const updateDetail = (getObject) => {
    setDetail(getObject);
    setShowDetailAlert(true);
  };

  const clickCloseAlert = () => {
    setDetail({});
    setShowDetailAlert(false);
  };

  const headerTitleDom = () => {
    return (
      <Text fontSize={["24px", "32px"]} fontWeight={600} color="#000000">
        {t("cQiCyvfOMMDJEIXyc")}
      </Text>
    );
  };
  const headerFilterDom = () => {
    return (
      <Flex>
        <Box
          fontSize={"14px"}
          color={"#010313"}
          fontWeight={"400"}
          minW={["100px", "140px"]}
          h="44px"
          bg={"#fff"}
          cursor={"pointer"}
          border={"1px solid #E3E4F1"}
          lineHeight={"44px"}
          textAlign={"center"}
          mr={"20px"}
          borderRadius={"12px"}
          onClick={() => navigator("/trade/OrderStatement")}
        >
          {t("ZbUUOchcpSOpwpIk")}
        </Box>
        <Box minW={["100px", "140px"]} h="44px">
          <DropDownBox
            list={categoryList}
            initValue={category}
            nameNeedT={true}
            onselectedInfo={(getValue) => {
              console.log("获取到的筛选的值", getValue);
              setPage(1);
              setCategory(getValue.selected_key);
            }}
          />
        </Box>
      </Flex>
    );
  };
  const headerDom = () => {
    return isMobile ? (
      <Box pt={"40px"} pb={"10px"}>
        <BackWalletBtn />
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          px={"30px"}
          mt={"27px"}
        >
          {headerTitleDom()}
          {headerFilterDom()}
        </Box>
      </Box>
    ) : (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pt={"40px"}
        pb={"50px"}
      >
        <BackWalletBtn />
        {headerTitleDom()}
        {headerFilterDom()}
      </Box>
    );
  };

  return (
    <div className="history-bill-page">
      <PageWrap>
        <Box
          w={["100%", "60%"]}
          h={`calc(100% - ${isMobile ? "120px" : "60px"})`}
          mx={[0, "auto"]}
        >
          {headerDom()}
          <Box w={"100%"} h={`calc(100% - ${isMobile ? "161px" : "138px"})`}>
            <BillList
              billList={billList}
              currentPage={page}
              currentTotalPage={totalPage}
              getDetailEvent={updateDetail}
              turnPageEvent={(getValue) => {
                setPage(getValue);
              }}
            />
          </Box>
        </Box>
        <BillDetail
          showAlert={showDetailAlert}
          getInfo={detail}
          closeEvent={clickCloseAlert}
        />
        {payLoading && <BXLoading />}
      </PageWrap>
    </div>
  );
};

export default HistoryBill;
