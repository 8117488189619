import request from "@/hooks/useRequest";
import {GETUSERINFO, GETPROCESSINFO, KYC_INFO} from "@/helpers/constants/requestApi";
// 提交验证
export const sumbitAtuh = (body) => {
  return request("/web-wallet/api/v1/kyc/authentication/create", {
    body,
  });
};

//获取用户信息
export const getUserInfo = (params) => {
  return request(GETUSERINFO, { params });
};

//获取kyc进程信息
export const getProcessInfo = (params) => {
  return request(GETPROCESSINFO, { params });
};

// s3 put url
export const getS3PutUrl = (params) => {
  return request("/web-wallet/api/v1/s3/get-private-put-url", { params });
};

// 获取用户kyc信息
export const getKycInfo = () => {
  return request(KYC_INFO, { params: {} });
};