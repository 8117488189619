import { Flex, Box, Text, Image, Button } from "@chakra-ui/react";
import { useI18n } from "@/hooks/useI18n";
import { useMedia } from "@/hooks/useMedia";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ModalComponent from "@/components/ui/modal";
import { useCertificate } from "./hook";

const Progress = ({ step }) => {
  const { t } = useI18n();
  const { isMobile } = useMedia();
  const navigate = useNavigate();
  const [showTipModal, setShowTipModal] = useState(false);
  const {
    baseBtnInfo,
    verifyBtnInfo,
    evaBtnInfo,
    processData,
    verifyType,
    getTextColor,
    getVerifyType,
  } = useCertificate();

  const Line = ({ status }) => {
    let bg = status === "APPROVED" ? "#333BF5" : "#E3E4F1";
    return (
      <Box
        width={isMobile ? "2px" : "34px"}
        height={isMobile ? "30px" : "2px"}
        ml={isMobile ? "12px" : "6px"}
        mr={isMobile ? "0px" : "6px"}
        mt={isMobile ? "4px" : "0px"}
        mb={isMobile ? "4px" : "0px"}
        backgroundColor={bg}
      />
    );
  };

  const renderImage = ({ status, imgName, isActive }) => {
    if (status === "PENDING") {
      return (
        <Image
          src="../../wwwsite/images/kyc/certificate/pending.svg"
          width={"24px"}
          height={"24px"}
        />
      );
    }
    if (status === "APPROVED") {
      return (
        <Image
          src="../../wwwsite/images/kyc/certificate/done.svg"
          width={"24px"}
          height={"24px"}
        />
      );
    }
    if (status === "DENIED") {
      return (
        <Image
          src="../../wwwsite/images/kyc/certificate/denied.svg"
          width={"24px"}
          height={"24px"}
        />
      );
    }
    return (
      <Image
        src={`../../wwwsite/images/kyc/certificate/${imgName}${
          // isActive ? "_active" : ""
          isActive ? "" : ""
        }.svg`}
        width={"24px"}
        height={"24px"}
      />
    );
  };

  const Modal = () => {
    return (
      <ModalComponent isShow={showTipModal} style={{ zIndx: 99 }}>
        <Box px={"33px"} py={"50px"}>
          <Box>
            <Flex justifyContent={"space-between"}>
              <Text color="#010313" fontSize={16} fontWeight={600}>
                {t("GCxKMtkKAZPoOHsZIDh")}
              </Text>
              <Image
                boxSize="24px"
                src="../../wwwsite/images/bill/close.svg"
                cursor={"pointer"}
                onClick={() => setShowTipModal(false)}
              />
            </Flex>
            <Text fontSize={14} color={"#010313"} mt={"20px"}>
              {t("FaQaEHvLdVELQbCZrFN")}
            </Text>
          </Box>
        </Box>
      </ModalComponent>
    );
  };

  const toStep = (key) => {
    if (key === "step1") {
      if (baseBtnInfo?.isActive) {
        navigate("/trade/personKyc/baseInfo");
      }
    } else if (key === "step2") {
      if (evaBtnInfo?.isActive) {
        navigate("/trade/personKyc/evaluation");
      }
    } else if (key === "step3") {
      if (verifyBtnInfo?.isActive && verifyType) {
        navigate(`/trade/personKyc/verify?type=${verifyType}`);
      }
    }
  };

  if (step === "stepOne") {
    return (
      <>
        <Box mt={"32px"}>
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            alignItems={isMobile ? "flex-start" : "center"}
          >
            <Flex alignItems={isMobile ? "flex-start" : "center"}>
              {renderImage({
                status: processData?.[0]?.status,
                imgName: processData?.[0]?.name,
                isActive: processData[0]?.imgActive,
              })}
              <Text
                fontSize={"14px"}
                ml={"8px"}
                color={processData?.[0]?.textColor}
              >
                {t("ZnbyJyzHTxzgLRDFBmEm")}
              </Text>
            </Flex>
            <Line status={processData?.[0]?.status} />
            <Flex alignItems={isMobile ? "flex-start" : "center"}>
              {renderImage({
                status: processData?.[1]?.status,
                imgName: processData?.[1]?.name,
                isActive: processData[1]?.imgActive,
              })}
              <Text
                fontSize={"14px"}
                ml={"8px"}
                color={processData?.[1]?.textColor}
              >
                {t("RtWpXpLfhjeWPtKhcENN")}
              </Text>
            </Flex>
            <Line status={processData?.[1]?.status} />
            <Flex alignItems={isMobile ? "flex-start" : "center"}>
              {renderImage({
                status: processData?.[2]?.status,
                imgName: processData?.[2]?.name,
                isActive: processData[2]?.imgActive,
              })}
              <Text
                fontSize={"14px"}
                ml={"8px"}
                color={processData?.[2]?.textColor}
              >
                {t("GJOdwcCnvAbhzEkImJ")}
              </Text>
            </Flex>
            <Line status={processData?.[2]?.status} />
            <Flex alignItems={isMobile ? "flex-start" : "center"}>
              {renderImage({
                status: processData?.[3]?.status,
                imgName: processData?.[3]?.name,
                isActive: processData[3]?.imgActive,
              })}
              <Text
                fontSize={"14px"}
                width={"160px"}
                ml={"8px"}
                color={processData?.[3]?.textColor}
              >
                {t("pnGXcYRvktMyOUBwX")}
                <Image
                  style={{ display: "inline-block" }}
                  cursor={"pointer"}
                  src={"../../../wwwsite/images/kyc/gray-tip.svg"}
                  onClick={() => setShowTipModal(true)}
                />
              </Text>
            </Flex>
          </Flex>
          {/* 当step3是PENDING是 文案为审核中  step3是APPROVED时 文案为审核通过 step4为提交时， 按钮置灰 */}
          <Button
            h="44px"
            mt={"20px"}
            color="#fff"
            bg={baseBtnInfo?.isActive ? "blue.100" : "t.50"}
            _hover={baseBtnInfo?.isActive ? "blue.80" : "t.50"}
            fontSize="14px"
            w={"160px"}
            borderRadius={"30px"}
            onClick={() => {
              toStep("step1");
            }}
          >
            {t(baseBtnInfo?.text)}
          </Button>
        </Box>
        <Modal />
      </>
    );
  }

  if (step === "stepTwo") {
    return (
      <>
        <Box mt={"32px"}>
          <Flex
            flexDirection={isMobile ? "column" : "row"}
            alignItems={isMobile ? "flex-start" : "center"}
          >
            <Flex alignItems={isMobile ? "flex-start" : "center"}>
              {renderImage({
                status: processData?.[4]?.status,
                imgName: processData?.[4]?.name,
                isActive: processData[4]?.imgActive,
              })}
              <Text
                fontSize={"14px"}
                ml={"8px"}
                color={processData[4]?.textColor}
              >
                {t("KIIMnxhTdJBhZfaEMP")}
              </Text>
            </Flex>
            <Line status={processData?.[4]?.status} />
            <Flex alignItems={isMobile ? "flex-start" : "center"}>
              {renderImage({
                status: processData?.[5]?.status,
                imgName: processData?.[5]?.name,
                isActive: processData[5]?.imgActive,
              })}
              <Text
                fontSize={"14px"}
                ml={"8px"}
                color={processData[5]?.textColor}
              >
                {t("hAacNWPacMHaAAaOw")}
              </Text>
            </Flex>
            <Line status={processData?.[5]?.status} />
            <Flex alignItems={isMobile ? "flex-start" : "center"}>
              {renderImage({
                status: processData?.[6]?.status,
                imgName: processData?.[6]?.name,
                isActive: processData[6]?.imgActive,
              })}
              <Text
                fontSize={"14px"}
                ml={"8px"}
                color={processData?.[6]?.textColor}
              >
                {t("aCtajuQWKCQNkeKgtwLb")}
                <Image
                  style={{ display: "inline-block" }}
                  cursor={"pointer"}
                  src={"../../../wwwsite/images/kyc/gray-tip.svg"}
                  onClick={() => setShowTipModal(true)}
                />
              </Text>
            </Flex>
          </Flex>
          <Button
            h="44px"
            mt={"20px"}
            bg={evaBtnInfo?.isActive ? "blue.100" : "t.50"}
            color="#fff"
            _hover={evaBtnInfo?.isActive ? "blue.80" : "t.50"}
            fontSize="14px"
            w={"160px"}
            borderRadius={"30px"}
            onClick={() => {
              toStep("step2");
            }}
          >
            {t(evaBtnInfo?.text)}
          </Button>
        </Box>
        <Modal />
      </>
    );
  }

  if (step === "stepThree") {
    return (
      <Box mt={"32px"}>
        <Box>
          <Flex alignItems={isMobile ? "flex-start" : "center"}>
            <Image
              src={
                verifyType === "bank"
                  ? "../../wwwsite/images/checked.svg"
                  : "../../wwwsite/images/no-checked.svg"
              }
              width={"24px"}
              height={"24px"}
              style={{
                marginRight: "10px",
                cursor: verifyBtnInfo?.isActive ? "pointer" : "no-drop",
              }}
              onClick={() => getVerifyType("bank")}
            />
            {renderImage({
              status:
                verifyType === "bank" ? processData?.[7]?.status : "REQUIRED",
              imgName:
                verifyType === "bank"
                  ? "certificate_bank_active"
                  : "certificate_bank",
              isActive: processData[7]?.imgActive,
            })}
            <Text
              fontSize={"14px"}
              ml={"8px"}
              color={
                verifyType && verifyType === "bank"
                  ? processData[7]?.textColor
                  : getTextColor("gray")
              }
            >
              {t("LJPMiQOhqtJqlBRBCHX")}
            </Text>
          </Flex>
          {verifyType === "bank" && (
            <Text
              fontSize={"14px"}
              mt={"10px"}
              ml={"8px"}
              color={getTextColor("black")}
            >
              {t("FRjQUDLIESvWvPOQY")}
            </Text>
          )}
        </Box>
        {/*<Line status={processData?.[7]?.status} />*/}
        <Box mt={"30px"}>
          <Flex alignItems={isMobile ? "flex-start" : "center"}>
            <Image
              src={
                verifyType === "other"
                  ? "../../wwwsite/images/checked.svg"
                  : "../../wwwsite/images/no-checked.svg"
              }
              width={"24px"}
              height={"24px"}
              style={{
                marginRight: "10px",
                cursor: verifyBtnInfo?.isActive ? "pointer" : "no-drop",
              }}
              onClick={() => getVerifyType("other")}
            />
            {renderImage({
              status:
                verifyType === "other" ? processData?.[7]?.status : "REQUIRED",
              imgName:
                verifyType === "other"
                  ? `${processData?.[7]?.name}_active`
                  : processData?.[7]?.name,
              isActive: processData[7]?.imgActive,
            })}
            <Text
              fontSize={"14px"}
              ml={"8px"}
              color={
                verifyType && verifyType === "other"
                  ? processData[7]?.textColor
                  : getTextColor("gray")
              }
            >
              {t("lvouKixXkMbDqOnnr")}
            </Text>
          </Flex>
          {verifyType === "other" && (
            <Text
              fontSize={"14px"}
              mt={"10px"}
              ml={"8px"}
              color={getTextColor("black")}
            >
              {t("JNPXLHStukWLfpLRU")}
            </Text>
          )}
        </Box>
        <Button
          h="44px"
          mt={"30px"}
          color="#fff"
          bg={verifyBtnInfo?.isActive ? "blue.100" : "t.50"}
          _hover={verifyBtnInfo?.isActive ? "blue.80" : "t.50"}
          fontSize="14px"
          w={"160px"}
          borderRadius={"30px"}
          onClick={() => {
            toStep("step3");
          }}
        >
          {t(verifyBtnInfo?.text)}
        </Button>
      </Box>
    );
  }
};

export default Progress;
