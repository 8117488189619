import { useMediaQuery } from "@chakra-ui/react";

export const useMedia = () => {
  const [isMobile,isPad, isPc,isGiant] = useMediaQuery(["(max-width: 600px)","(min-width: 601px) and (max-width: 1079px)", "(min-width: 1080px)","(min-width: 1800px)"]);

  return {
    isMobile,
    isPc,
    isPad,
    isGiant,
  }
}
